/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUpdateOrganizationDto,
    CreateUpdateOrganizationDtoFromJSON,
    CreateUpdateOrganizationDtoFromJSONTyped,
    CreateUpdateOrganizationDtoToJSON,
    CreateUpdateTechAssessmentDto,
    CreateUpdateTechAssessmentDtoFromJSON,
    CreateUpdateTechAssessmentDtoFromJSONTyped,
    CreateUpdateTechAssessmentDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUpdateTechAssessmentWithOrganizationDto
 */
export interface CreateUpdateTechAssessmentWithOrganizationDto {
    /**
     * 
     * @type {CreateUpdateTechAssessmentDto}
     * @memberof CreateUpdateTechAssessmentWithOrganizationDto
     */
    techAssessment: CreateUpdateTechAssessmentDto;
    /**
     * 
     * @type {CreateUpdateOrganizationDto}
     * @memberof CreateUpdateTechAssessmentWithOrganizationDto
     */
    organization: CreateUpdateOrganizationDto;
}

export function CreateUpdateTechAssessmentWithOrganizationDtoFromJSON(json: any): CreateUpdateTechAssessmentWithOrganizationDto {
    return CreateUpdateTechAssessmentWithOrganizationDtoFromJSONTyped(json, false);
}

export function CreateUpdateTechAssessmentWithOrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateTechAssessmentWithOrganizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'techAssessment': CreateUpdateTechAssessmentDtoFromJSON(json['techAssessment']),
        'organization': CreateUpdateOrganizationDtoFromJSON(json['organization']),
    };
}

export function CreateUpdateTechAssessmentWithOrganizationDtoToJSON(value?: CreateUpdateTechAssessmentWithOrganizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'techAssessment': CreateUpdateTechAssessmentDtoToJSON(value.techAssessment),
        'organization': CreateUpdateOrganizationDtoToJSON(value.organization),
    };
}


