import React from 'react';
import { Table } from 'react-bootstrap';

import momencik from '../../../common/momencik';
import { GetSampleDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { ReportAlert } from '../Reports/ReportAlert';
import nkitwDesc from '../../../common/nkitwDesc';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: GetSampleDto[];
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const SampleExamsList: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = Object.keys(data).length > 0;

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={5}>{reportName}</th>
            </tr>
            <tr>
              <th>Organ pobierający próbkę</th>
              <th>Data pobrania</th>
              <th>Numer badania próbek</th>
              <th>Typ wyrobu</th>
              <th>Data zlecenia badania</th>
            </tr>
          </thead>

          {hasData && (
            <tbody>
              {data.map(sample => (
                <tr key={sample.id}>
                  <td>
                    {
                      sample.sampleExam.sampleCollect.examOrganizationalUnit
                        ?.name
                    }
                  </td>
                  <td>{momencik(sample.sampleExam.collectDate)}</td>
                  <td>{sample.sampleExam.sysNumber}</td>
                  <td>{nkitwDesc(sample.sampleExam.productType)}</td>
                  <td>{momencik(sample.sampleCommissionDate)}</td>
                </tr>
              ))}
              <tr></tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};
