import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import { GetOrganizationDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

import { OrganizationSelector } from './OrganizationSelector';

export const OrganizationModalSelector = (props: {
  value: GetOrganizationDto;
  initiallyShow?: boolean;
  onSelect: (organization: OrganizationContext.Organization) => void;
  onClose?: () => void;
}) => {
  const [showModal, setShowModal] = useState(props.initiallyShow || false);
  const [selected, setSelected] = useState<OrganizationContext.Organization>(
    props.value || OrganizationContext.newOrganization(),
  );
  const [showValidation, setShowValidation] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const validation = (status: boolean) => {
    setIsValid(status);
    if (status) {
      setShowValidation(false);
    }
  };

  const select = () => {
    if (!isValid) {
      setShowValidation(true);
      return;
    }
    props.onSelect(selected);
    setShowModal(false);
  };

  const close = () => {
    setShowModal(false);
    props.onClose && props.onClose();
  };

  useEffect(
    () => setSelected(props.value || OrganizationContext.newOrganization()),
    [props.value, showModal],
  );
  useEffect(
    () => setShowModal(props.initiallyShow || false),
    [props.initiallyShow],
  );

  return (
    <>
      <Button variant="outline-primary" onClick={() => setShowModal(true)}>
        {selected.name || 'Wskaż'}
      </Button>
      <Modal
        show={showModal}
        onHide={close}
        centered={true}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Wybór podmiotu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrganizationSelector
            value={selected}
            showValidationErrors={showValidation}
            onSelect={setSelected}
            onValidation={validation}
            onClear={() => {
              setSelected(props.value || OrganizationContext.newOrganization());
              setIsValid(false);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={close}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={select}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
