/*eslint max-lines-per-function: ["error", 200]*/
import React from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import FormRow from '../../../app/components/FormRow';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import DictionarySelector from '../Selects/DictionarySelector';
import { Option } from '../Selects/Selector';

interface PolishOrganizationProps {
  readOnly?: boolean;
  editMode?: boolean;
  presentedOrganization: OrganizationContext.Organization;
  activityType?: string;
  showNames: boolean;
  nameLabel: string;
  nipLabel: string;
  propertyChange: (obj: Partial<OrganizationContext.Organization>) => void;
  presentedToSelected: () => void;
  selectedChange: (obj: Partial<OrganizationContext.Organization>) => void;
  selectOrganization: (
    target: keyof OrganizationContext.Organization,
  ) => (option: Option) => void;
  setPost: (postcode: string) => void;
  byNameProvider: (text: string) => Promise<Option[]>;
  byNipProvider: (text: string) => Promise<Option[]>;
  countriesProvider: (text: string) => Promise<Option[]>;
  postcodesProvider: (text: string) => Promise<Option[]>;
  placesProvider: (text: string) => Promise<Option[]>;
  findByNip: (text: string) => void;
}

export const PolishOrganization = (props: PolishOrganizationProps) => {
  const emptyIfNull = (val: string) => val || '';

  const findOrganizationByNip = () =>
    props.findByNip(props.presentedOrganization.nip);

  return (
    <Form className="d-grid gap-3">
      <FormRow controlId="name" label={props.nameLabel}>
        {props.editMode ? (
          <Form.Control
            type="text"
            readOnly={props.readOnly}
            value={emptyIfNull(props.presentedOrganization.name)}
            onChange={e => props.propertyChange({ name: e.target.value })}
            onBlur={props.presentedToSelected}
          />
        ) : (
          <CreatableClearableSelector
            value={props.presentedOrganization.name}
            onChange={props.selectOrganization('name')}
            readOnly={props.readOnly}
            provider={props.byNameProvider}
          />
        )}
      </FormRow>
      <FormRow controlId="activityType" label="Typ działalności">
        <DictionarySelector
          readOnly={props.readOnly}
          dictionary="podmiot - typ działalności"
          value={props.activityType}
          clearable
          onChange={option => {
            props.selectedChange({
              activityTypeId: option.value,
              activityTypeShort: option.badge,
            });
          }}
        />
      </FormRow>
      {props.showNames && (
        <>
          <FormRow controlId="firstName" label="Imię">
            <Form.Control
              type="text"
              readOnly={props.readOnly}
              value={emptyIfNull(props.presentedOrganization.firstName)}
              onChange={e =>
                props.propertyChange({ firstName: e.target.value })
              }
              onBlur={props.presentedToSelected}
            />
          </FormRow>
          <FormRow controlId="lastName" label="Nazwisko">
            <Form.Control
              type="text"
              readOnly={props.readOnly}
              value={emptyIfNull(props.presentedOrganization.lastName)}
              onChange={e => props.propertyChange({ lastName: e.target.value })}
              onBlur={props.presentedToSelected}
            />
          </FormRow>
        </>
      )}
      <FormRow controlId="nip" label={props.nipLabel}>
        <div className="d-flex">
          <div className="flex-grow-1">
            {props.editMode ? (
              <Form.Control
                type="text"
                readOnly={props.readOnly}
                value={emptyIfNull(props.presentedOrganization.nip)}
                onChange={e => props.propertyChange({ nip: e.target.value })}
                onBlur={props.presentedToSelected}
              />
            ) : (
              <CreatableClearableSelector
                value={props.presentedOrganization.nip}
                onChange={props.selectOrganization('nip')}
                readOnly={props.readOnly}
                provider={props.byNipProvider}
                isValidNewOption={(inputValue, value, options) => {
                  if (!inputValue || inputValue.length < 10) {
                    return true;
                  }
                  if (options.find(opt => opt.label?.startsWith(inputValue))) {
                    return false;
                  }
                  return true;
                }}
              />
            )}
          </div>
          <div className="flex-grow-0">
            <Button
              disabled={props.readOnly}
              className="py-2 ms-1"
              variant="outline-primary"
              onClick={findOrganizationByNip}
            >
              <Icon.Search /> GUS
            </Button>
          </div>
        </div>
      </FormRow>
      <FormRow controlId="postcode" label="Poczta">
        <CreatableClearableSelector
          value={props.presentedOrganization.postcode}
          readOnly={props.readOnly}
          onChange={option => props.setPost(option.label)}
          provider={props.postcodesProvider}
        />
      </FormRow>
      <FormRow controlId="place" label="Miejscowość">
        <CreatableClearableSelector
          value={props.presentedOrganization.place}
          readOnly={props.readOnly}
          onChange={option => props.selectedChange({ place: option.label })}
          provider={props.placesProvider}
        />
      </FormRow>
      <FormRow controlId="street" label="Ulica">
        <Form.Control
          type="text"
          readOnly={props.readOnly}
          value={emptyIfNull(props.presentedOrganization.street)}
          onChange={e => props.propertyChange({ street: e.target.value })}
          onBlur={props.presentedToSelected}
        />
      </FormRow>
      <FormRow controlId="numbers" label="Numer">
        <Form.Control
          type="text"
          readOnly={props.readOnly}
          value={emptyIfNull(props.presentedOrganization.numbers)}
          onChange={e => props.propertyChange({ numbers: e.target.value })}
          onBlur={props.presentedToSelected}
        />
      </FormRow>
      <FormRow controlId="flat" label="Mieszkanie">
        <Form.Control
          type="text"
          readOnly={props.readOnly}
          value={emptyIfNull(props.presentedOrganization.flat)}
          onChange={e => props.propertyChange({ flat: e.target.value })}
          onBlur={props.presentedToSelected}
        />
      </FormRow>
      <FormRow controlId="addressEx" label="Uwagi dodatkowe">
        <Form.Control
          type="text"
          readOnly={props.readOnly}
          value={emptyIfNull(props.presentedOrganization.addressEx)}
          onChange={e => props.propertyChange({ addressEx: e.target.value })}
          onBlur={props.presentedToSelected}
        />
      </FormRow>
    </Form>
  );
};
