import React from 'react';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

export const PakLink: React.FC<{ pakId: string }> = props => {
  const nav = useNavigation();

  return (
    <span
      className="link-primary"
      role="button"
      tabIndex={0}
      onClick={() => nav.pak(props.pakId)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          nav.pak(props.pakId);
        }
      }}
    >
      <Icon.BoxArrowRight />
    </span>
  );
};
