/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSampleDto,
    GetSampleDtoFromJSON,
    GetSampleDtoFromJSONTyped,
    GetSampleDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetExamResultDto
 */
export interface GetExamResultDto {
    /**
     * 
     * @type {string}
     * @memberof GetExamResultDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetExamResultDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamResultDto
     */
    negative: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamResultDto
     */
    approved: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetExamResultDto
     */
    publicationDate?: Date;
    /**
     * 
     * @type {GetSampleDto}
     * @memberof GetExamResultDto
     */
    sample: GetSampleDto;
    /**
     * 
     * @type {number}
     * @memberof GetExamResultDto
     */
    yearOfCommission: number;
    /**
     * 
     * @type {number}
     * @memberof GetExamResultDto
     */
    yearOrderNo: number;
    /**
     * 
     * @type {Date}
     * @memberof GetExamResultDto
     */
    approvedDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetExamResultDto
     */
    verified: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetExamResultDto
     */
    withdrawalDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetExamResultDto
     */
    unpublishableDate?: Date;
}

export function GetExamResultDtoFromJSON(json: any): GetExamResultDto {
    return GetExamResultDtoFromJSONTyped(json, false);
}

export function GetExamResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExamResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': json['description'],
        'negative': json['negative'],
        'approved': json['approved'],
        'publicationDate': !exists(json, 'publicationDate') ? undefined : (new Date(json['publicationDate'])),
        'sample': GetSampleDtoFromJSON(json['sample']),
        'yearOfCommission': json['yearOfCommission'],
        'yearOrderNo': json['yearOrderNo'],
        'approvedDate': !exists(json, 'approvedDate') ? undefined : (new Date(json['approvedDate'])),
        'verified': json['verified'],
        'withdrawalDate': !exists(json, 'withdrawalDate') ? undefined : (new Date(json['withdrawalDate'])),
        'unpublishableDate': !exists(json, 'unpublishableDate') ? undefined : (new Date(json['unpublishableDate'])),
    };
}

export function GetExamResultDtoToJSON(value?: GetExamResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'description': value.description,
        'negative': value.negative,
        'approved': value.approved,
        'publicationDate': value.publicationDate === undefined ? undefined : (value.publicationDate.toISOString()),
        'sample': GetSampleDtoToJSON(value.sample),
        'yearOfCommission': value.yearOfCommission,
        'yearOrderNo': value.yearOrderNo,
        'approvedDate': value.approvedDate === undefined ? undefined : (value.approvedDate.toISOString()),
        'verified': value.verified,
        'withdrawalDate': value.withdrawalDate === undefined ? undefined : (value.withdrawalDate.toISOString()),
        'unpublishableDate': value.unpublishableDate === undefined ? undefined : (value.unpublishableDate.toISOString()),
    };
}


