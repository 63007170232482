import React from 'react';

import { GetPakExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/';
import { Grid } from '../../../common/components/Grid/Grid';
import { useNavigation } from '../../../common/navigation';
import momencik from '../../../common/momencik';
import { PartiesGridColumn } from '../PartyToProceedings/PartiesGridColumn';
import nkitwDesc from '../../../common/nkitwDesc';

import { PakLink } from './PakLink';
import { PakType } from './PakType';

export const MyPaksFinished: React.FC<{
  data: GetPakExtendedDto[];
  showFilter?: boolean;
}> = props => {
  const nav = useNavigation();
  const columns = [
    { header: '', property: 'type', noSorting: true },
    { header: 'Data wszczęcia', property: 'startDate' },
    { header: 'Data zakończenia', property: 'endDate' },
    { header: 'Typ wyrobu', property: 'productType' },
    { header: 'Strony', property: 'parties' },
    { header: 'Numer akt', property: 'actNumber' },
    { header: '', property: 'link', className: 'text-center', noSorting: true },
  ];

  const data = props.data.map(pe => {
    const pak = pe.pak;
    return {
      key: pak.id,
      values: {
        type: <PakType pak={pak} />,
        startDate: momencik(pak.startDate),
        endDate: momencik(pak.endDate),
        productType: pak.productType ? nkitwDesc(pak.productType) : '',
        parties: <PartiesGridColumn parties={pe.parties} />,
        link: <PakLink pakId={pak.id} />,
        actNumber: pak.actNumber,
      },
      orderByValues: {
        startDate: momencik(pak.startDate, ''),
        endDate: momencik(pak.endDate, ''),
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={props.showFilter}
      initialOrderColumn="startDate"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={nav.pak}
      emptyGridMessage="Aktualnie w tej jednostce organizacyjnej nie toczą się żadne postępowania administracyjne w sprawie nałożenia kary"
    />
  );
};
