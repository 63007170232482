import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Skeleton from 'react-loading-skeleton';
import Button from 'react-bootstrap/Button';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetPakExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/';
import { MyPaks } from '../../components/Paks/MyPaks';
import { MyPaksFinished } from '../../components/Paks/MyPaksFinished';
import { PakSearch } from '../../components/Paks/PakSearch';
import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';

export const PaksView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const [paks, setPaks] = useState<GetPakExtendedDto[]>();
  const [paksFinished, setPaksFinished] = useState<GetPakExtendedDto[]>();
  const nav = useNavigation();

  useEffect(() => {
    let mounted = true;
    const safe = (set: () => void) => {
      if (mounted) {
        set();
      }
    };
    snrwb.paks.getPendingForUser().then(data => safe(() => setPaks(data)));
    snrwb.paks
      .getFinishedForUser()
      .then(data => safe(() => setPaksFinished(data)));

    return () => {
      mounted = false;
    };
  }, [snrwb.paks]);

  return (
    <Card>
      <TabsHistory activeKey="my" id="paks" className="mb-3">
        <Tab
          eventKey="my"
          title="Aktualne postępowania w sprawie nałożenia kary"
          className="mx-3"
        >
          {paks ? (
            <>
              <MyPaks data={paks} />
              <Alert variant="dark">
                W celu utworzenia nowego postępowania należy odszukać wyrób w{' '}
                <Button variant="outline-dark" onClick={nav.myInspections}>
                  Kontrolach
                </Button>
              </Alert>
            </>
          ) : (
            <Skeleton count={10} />
          )}
        </Tab>
        <Tab
          eventKey="myFinished"
          title="Moje zakończone postępowania"
          className="mx-3"
        >
          {paksFinished ? (
            <>
              <MyPaksFinished data={paksFinished} />
            </>
          ) : (
            <Skeleton count={10} />
          )}
        </Tab>
        <Tab eventKey="search" title="Wyszukiwanie" className="mx-3">
          <PakSearch
            getData={snrwb.paks.getAllByPortion}
            getCount={snrwb.paks.getAllCount}
          />
        </Tab>
      </TabsHistory>
    </Card>
  );
};
