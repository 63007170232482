import React from 'react';

import { useNavigation } from '../../../common/navigation';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';

export const ReportsPakView: React.FC = () => {
  const nav = useNavigation();

  return (
    <>
      <ReportCardMenu
        title="Administracyjne kary pieniężne"
        text="(21a) Liczba przesłanek ukarania w wydanych decyzjach"
        footer="Opinie celne"
        onClick={nav.report21a}
      />
      <ReportCardMenu
        title="Administracyjne kary pieniężne"
        text="(21b) Liczba zakończonych postępowań w sprawie kar pieniężnych"
        footer="Opinie celne"
        onClick={nav.report21b}
      />
    </>
  );
};
