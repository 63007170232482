import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { GetAttachmentDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';

export const TechAssessmentsAttachmentLink = (props: {
  attachment: GetAttachmentDto;
}) => {
  const snrwb = useSnrwbCore();

  return (
    <OverlayTrigger overlay={<Tooltip>Otwórz załącznik w nowym oknie</Tooltip>}>
      <Button
        onClick={e => {
          e.stopPropagation();
          snrwb.attachments.saveFileInBrowser(props.attachment, 'open');
        }}
        variant="link"
        className="px-0 text-start"
      >
        {props.attachment.name}
      </Button>
    </OverlayTrigger>
  );
};
