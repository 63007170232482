import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GetInspectionPlanGroupDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

import { InspectionPlanGroupLink } from './InspectionPlanGroupLink';

interface InspectionPlanGroupListProps {
  inspectionPlanGroups: GetInspectionPlanGroupDto[];
  readonly: boolean;
  onClick: (id: string) => Promise<void>;
}

export const InspectionPlanGroupList: React.FC<InspectionPlanGroupListProps> =
  props => {
    const columns = [
      {
        header: 'Grupa wyrobów',
        property: 'productGroup',
        className: '',
        noSorting: false,
      },
      {
        header: 'Liczba planowanych kontroli',
        property: 'numberOfInspections',
      },
      {
        header: 'W tym kontroli wyrobów CPR',
        property: 'numberOfInspectionsCpr',
      },
    ];

    if (!props.readonly) {
      columns.push({
        header: '',
        property: 'editLink',
        className: 'text-center',
        noSorting: true,
      });
    }

    const data = props.inspectionPlanGroups.map(group => {
      return {
        key: group.id,
        values: {
          productGroup: group.productGroup.value,
          numberOfInspections: group.numberOfInspections,
          numberOfInspectionsCpr: group.numberOfInspectionsCpr,
          editLink: (
            <InspectionPlanGroupLink
              inspectionPlanGroupId={group.id}
              action={props.onClick}
            />
          ),
        },
      };
    });

    return (
      <>
        <Grid
          data={data}
          columns={columns}
          showFilter={false}
          onRowClick={!props.readonly ? props.onClick : undefined}
          emptyGridMessage="Brak zdefiniowanych grup wyrobów"
        />
      </>
    );
  };
