import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateCustomsFeedbackDto,
  CreateUpdateCustomsFeedbackDtoFromJSON,
  CreateUpdateOrganizationDto,
  GetCustomsFeedbackDto,
} from '../autogenerated/snrwbApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { CustomsFeedbackSchema } from '../validation/schemas';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { customsFeedbackForGrid } from '../../../snrwb/components/CustomsFeedbacks/CustomsFeedbacks';
import momencik from '../../momencik';

export type CustomsFeedbackDto =
  | GetCustomsFeedbackDto
  | CreateUpdateCustomsFeedbackDto;

export interface CustomsFeedbackContextInterface {
  getById: (id: string) => Promise<GetCustomsFeedbackDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getByOrganization: (id: string) => Promise<GetCustomsFeedbackDto[]>;
  create: (
    dto: CreateUpdateCustomsFeedbackDto,
  ) => Promise<GetCustomsFeedbackDto>;
  createWithOrganization: (
    customsFeedback: CreateUpdateCustomsFeedbackDto,
    organization: CreateUpdateOrganizationDto,
  ) => Promise<GetCustomsFeedbackDto>;
  update: (id: string, dto: CreateUpdateCustomsFeedbackDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export const CustomsFeedbackContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.customsFeedbackControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.customsFeedbackControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(customsFeedbackForGrid);
    },
    getAllCount: (filterText?: string) =>
      api.customsFeedbackControllerGetAllCount(filterText || ''),

    getByOrganization: (id: string) =>
      api
        .customsFeedbackControllerGetByOrganization(id)
        .then(cusFeed =>
          cusFeed.sort((a, b) => a.name?.localeCompare(b.name, 'pl')),
        ),
    create: (customsFeedback: CreateUpdateCustomsFeedbackDto) =>
      api.customsFeedbackControllerCreate(customsFeedback),
    createWithOrganization: (
      customsFeedback: CreateUpdateCustomsFeedbackDto,
      organization: CreateUpdateOrganizationDto,
    ) =>
      api.customsFeedbackControllerCreateWithOrganization({
        customsFeedback: customsFeedback,
        organization: organization,
      }),
    update: (id: string, customsFeedback: CreateUpdateCustomsFeedbackDto) =>
      api.customsFeedbackControllerUpdate(id, customsFeedback),
    delete: (id: string) => api.customsFeedbackControllerDelete(id),
  };
};

export const validate = (customsFeedback: CreateUpdateCustomsFeedbackDto) =>
  validateAgainst(CustomsFeedbackSchema, customsFeedback);

export const newCustomsFeedback = () => {
  return CreateUpdateCustomsFeedbackDtoFromJSON({});
};

export const convert = (customsFeedback: GetCustomsFeedbackDto) => {
  if (!customsFeedback) {
    return newCustomsFeedback();
  }
  const converted = CreateUpdateCustomsFeedbackDtoFromJSON(customsFeedback);
  converted.organizationalUnitId = customsFeedback.organizationalUnit?.id;
  converted.producerId = customsFeedback.producer?.id;

  return converted;
};

const add = (
  form: { name: string; value: React.ReactNode }[],
  object: unknown,
  label: string,
  value: () => React.ReactNode,
) => {
  if (object !== undefined) {
    form.push({ name: label, value: value() });
  }
};

export const forPresentation = (feedback: GetCustomsFeedbackDto) => {
  const form: { name: string; value: React.ReactNode }[] = [];

  add(form, feedback.producer, 'Producent', () => feedback.producer.name);
  add(form, feedback.receiptDate, 'Data wplywu', () =>
    momencik(feedback.receiptDate),
  );
  add(form, feedback.name, 'Nazwa i typ wyrobu', () => feedback.name);
  add(form, feedback.usage, 'Zastosowanie', () => feedback.usage);
  add(form, feedback.importer, 'Importer', () => feedback.importer);
  add(form, feedback.constructionProduct, 'Wyrób budowlany', () =>
    feedback.constructionProduct ? 'Tak' : 'Nie',
  );
  add(form, feedback.number, 'Numer opinii', () => feedback.number);
  add(form, feedback.releaseDate, 'Data wydania', () =>
    momencik(feedback.releaseDate),
  );
  add(
    form,
    feedback.customsAuthority,
    'Organ celny',
    () => feedback.customsAuthority,
  );
  add(form, feedback.positive, 'Opinia pozytywna', () => feedback.positive);

  return form;
};
