/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetObjectHistoryDto
 */
export interface GetObjectHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof GetObjectHistoryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetObjectHistoryDto
     */
    objectType: string;
    /**
     * 
     * @type {string}
     * @memberof GetObjectHistoryDto
     */
    objectId: string;
    /**
     * 
     * @type {string}
     * @memberof GetObjectHistoryDto
     */
    eventJson: string;
}

export function GetObjectHistoryDtoFromJSON(json: any): GetObjectHistoryDto {
    return GetObjectHistoryDtoFromJSONTyped(json, false);
}

export function GetObjectHistoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetObjectHistoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'objectType': json['objectType'],
        'objectId': json['objectId'],
        'eventJson': json['eventJson'],
    };
}

export function GetObjectHistoryDtoToJSON(value?: GetObjectHistoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'objectType': value.objectType,
        'objectId': value.objectId,
        'eventJson': value.eventJson,
    };
}


