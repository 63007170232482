import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';

import {
  CreateUpdateSampleCollectDto,
  CreateUpdateSampleCollectDtoFromJSON,
  GetInspectedProductDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNavigation } from '../../../common/navigation';
import * as SampleCollectContext from '../../../common/snrwbCore/contexts/SampleCollectContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import { SampleExamModalSelector } from '../SampleExams/SampleExamModalSelector';
import { SampleCollectForInspectedProduct } from '../SampleCollects/SampleCollectForInspectedProduct';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

export const InspectedProductInExam: React.FC<{
  inspectedProduct: GetInspectedProductDto;
  readonly: boolean;
  createExam: (
    dto: CreateUpdateSampleCollectDto,
    inspectedProductId: string,
  ) => void;
  setExam: (sampleExamId: string) => void;
  clearExam: () => void;
}> = props => {
  const nav = useNavigation();
  const presentation = props.inspectedProduct.sampleExam
    ? SampleCollectContext.detailsPresent(
        props.inspectedProduct.sampleExam.sampleCollect,
      )
    : [];
  const emptySampleCollect = CreateUpdateSampleCollectDtoFromJSON({
    organizationId: props.inspectedProduct.inspection.metric.organization.id,
    collectPlace: props.inspectedProduct.inspection.metric.inspectionPlace,
    collectBasisId: SampleCollectContext.duringInspectionBasisId(),
    collectBasisShort: '25.1',
  });

  return (
    <>
      {!props.inspectedProduct.sampleExam ? (
        <Alert variant="warning">
          <Alert.Heading>
            Ten wyrób nie jest częścią badania próbek.
          </Alert.Heading>
          <hr />
          {!props.readonly && (
            <div className="d-flex justify-content-end">
              <SampleExamModalSelector
                buttonText="Wskaż istniejące"
                buttonVariant="outline-secondary"
                buttonClassName="me-1"
                productType={props.inspectedProduct.productType}
                onSelect={sampleExamId => props.setExam(sampleExamId)}
              />
              <SampleCollectForInspectedProduct
                initialValues={emptySampleCollect}
                buttonVariant="outline-primary"
                organization={
                  props.inspectedProduct.inspection.metric.organization
                }
                onOK={dto => props.createExam(dto, props.inspectedProduct.id)}
              />
            </div>
          )}
        </Alert>
      ) : (
        <>
          <ItemPresentation item={presentation} className="pt-3" />
          <div className="d-flex justify-content-end">
            {!props.readonly && (
              <ConfirmationButton
                variant="outline-danger"
                className="mx-1"
                roleAnyOf={[Role.InspectionsEdit]}
                confirmation="Czy na pewno usunąć powiązanie wyrobu z badaniem?"
                onOK={props.clearExam}
              >
                Niewłaściwe badanie
              </ConfirmationButton>
            )}
            <Button
              variant="outline-primary"
              onClick={() =>
                nav.sampleExam(props.inspectedProduct.sampleExam.id)
              }
            >
              <Icon.Search /> Badanie nr{' '}
              {props.inspectedProduct.sampleExam.sysNumber}
            </Button>
          </div>
        </>
      )}
    </>
  );
};
