import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { FilterYear } from '../../../components/Reports/FilterYear';
import { FilterReportYearData } from '../../../components/Reports/Filter';
import { ReportInspectionPlanForOrganizationType } from '../../../../common/types/ReportTypes';
import { ReportInspectionPlanByOrganization } from '../../../components/ReportInspectionPlanByOrganization/ReportInspectionPlanByOrganization';

export const ReportInspectionPlanByOrganizationView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<
    FilterReportYearData | undefined
  >({});
  const [data, setData] = useState<ReportInspectionPlanForOrganizationType>();

  const onSearch = (filter?: FilterReportYearData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports
      .reportInspectionPlanByOrganization(filter)
      .then(dataReports => {
        setData(dataReports);
      });
  };

  return (
    <>
      <FilterYear
        onSearch={onSearch}
        reportName="Wykonanie planu - kontrole planowe u podmiotów"
      />
      <ReportInspectionPlanByOrganization
        data={data}
        reportName="Wykonanie planu w zakresie liczby przeprowadzonych kontroli"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
