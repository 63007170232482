/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JudgmentSampleDetails
 */
export interface JudgmentSampleDetails {
    /**
     * 
     * @type {string}
     * @memberof JudgmentSampleDetails
     */
    posId: string;
    /**
     * 
     * @type {string}
     * @memberof JudgmentSampleDetails
     */
    pakId: string;
    /**
     * 
     * @type {string}
     * @memberof JudgmentSampleDetails
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof JudgmentSampleDetails
     */
    organizationalUnit: string;
    /**
     * 
     * @type {Date}
     * @memberof JudgmentSampleDetails
     */
    issueDate: Date;
    /**
     * 
     * @type {string}
     * @memberof JudgmentSampleDetails
     */
    sign: string;
    /**
     * 
     * @type {boolean}
     * @memberof JudgmentSampleDetails
     */
    appeal: boolean;
    /**
     * 
     * @type {string}
     * @memberof JudgmentSampleDetails
     */
    appealResult: string;
}

export function JudgmentSampleDetailsFromJSON(json: any): JudgmentSampleDetails {
    return JudgmentSampleDetailsFromJSONTyped(json, false);
}

export function JudgmentSampleDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JudgmentSampleDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'posId': json['posId'],
        'pakId': json['pakId'],
        'type': json['type'],
        'organizationalUnit': json['organizationalUnit'],
        'issueDate': (new Date(json['issueDate'])),
        'sign': json['sign'],
        'appeal': json['appeal'],
        'appealResult': json['appealResult'],
    };
}

export function JudgmentSampleDetailsToJSON(value?: JudgmentSampleDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'posId': value.posId,
        'pakId': value.pakId,
        'type': value.type,
        'organizationalUnit': value.organizationalUnit,
        'issueDate': (value.issueDate.toISOString()),
        'sign': value.sign,
        'appeal': value.appeal,
        'appealResult': value.appealResult,
    };
}


