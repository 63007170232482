import React from 'react';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { GetPakDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ProductTypeNotApproved from '../ProductTypes/ProductTypeNotApproved';

export const PakType: React.FC<{ pak: GetPakDto }> = props => {
  if (!props.pak) {
    return <></>;
  }
  return (
    <>
      {!props.pak.productType.approved && (
        <ProductTypeNotApproved className="me-1" />
      )}
      {!props.pak.approved && props.pak.endDate && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              Postępowanie ma już datę końca, ale nie zostało zakończone
            </Tooltip>
          }
        >
          <Badge bg="danger">!</Badge>
        </OverlayTrigger>
      )}
      {props.pak.approved && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Postępowanie zakończone</Tooltip>}
        >
          <Badge bg="success">✓</Badge>
        </OverlayTrigger>
      )}
    </>
  );
};
