import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import * as Icons from 'react-bootstrap-icons';

import {
  CreateUpdateOrganizationDto,
  CreateUpdatePartyToProceedingDto,
  GetPakDto,
  GetPartyToProceedingDto,
  GetProceedingDto,
  ValidationStatus,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

import { PartyToProceeding } from './PartyToProceeding';

export const PartiesToProceeding = (props: {
  readonly: boolean;
  proceeding?: GetProceedingDto;
  pak?: GetPakDto;
  parties: GetPartyToProceedingDto[];
  addParty: (dto: CreateUpdatePartyToProceedingDto) => void;
  addPartyWithOrganization: (
    orgDto: CreateUpdateOrganizationDto,
    dto: CreateUpdatePartyToProceedingDto,
  ) => void;
  validateNewParty: (
    dto: CreateUpdatePartyToProceedingDto,
  ) => Promise<ValidationStatus>;
  deleteParty: (id: string) => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalModel, setModalModel] = useState<GetPartyToProceedingDto>();
  const noId = undefined as unknown as string;

  const approved =
    (props.proceeding && props.proceeding.approved) ||
    (props.pak && props.pak.approved);

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between">
        <span className="align-self-center">Strony</span>
        <div>
          {!approved && !props.readonly && (
            <Button
              variant="outline-primary"
              onClick={() => {
                setModalModel(undefined);
                setShowModal(true);
              }}
            >
              Dodaj stronę postępowania
            </Button>
          )}
          <PartyToProceeding
            show={showModal}
            value={modalModel}
            onHide={() => setShowModal(false)}
            validateNew={(organization, type) =>
              props.validateNewParty({
                organizationTypeId: type,
                organizationId: 'id' in organization ? organization.id : noId,
                proceedingId: props.proceeding?.id as unknown as string,
                pakId: props.pak?.id as unknown as string,
              })
            }
            onAdd={(organization, type) => {
              if ('id' in organization) {
                props.addParty({
                  organizationId: organization.id,
                  organizationTypeId: type,
                  proceedingId: props.proceeding?.id as unknown as string,
                  pakId: props.pak?.id as unknown as string,
                });
              } else {
                props.addPartyWithOrganization(organization, {
                  organizationId: noId,
                  organizationTypeId: type,
                  proceedingId: props.proceeding?.id as unknown as string,
                  pakId: props.pak?.id as unknown as string,
                });
              }
              setShowModal(false);
            }}
          />
        </div>
      </Card.Header>
      <Card.Body>
        {props.parties.length === 0 ? (
          <>
            <Alert variant="warning">
              W postępowaniu aktualnie nie ma żadnej strony.
            </Alert>
          </>
        ) : (
          <Table striped>
            <tbody>
              {props.parties.map(party => (
                <tr key={party.id} className="align-middle">
                  <td className="col-5 col-form-label">
                    {party.organizationType.value}
                  </td>
                  <td className="col-5">{party.organization.name}</td>
                  <td>
                    {!props.readonly && (
                      <ConfirmationButton
                        className="float-end"
                        roleAnyOf={[Role.ProceedingsEdit, Role.PakEdit]}
                        confirmation="Czy na pewno usunąć stronę postępowania?"
                        onOK={() => props.deleteParty(party.id)}
                        variant="outline-danger"
                      >
                        <Icons.Trash />
                      </ConfirmationButton>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card.Body>
    </Card>
  );
};
