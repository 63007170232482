import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  GetProductTypeDto,
  TimelineProcessType,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { Timeline } from '../../components/ProductTypes/Timeline';
import nkitwDesc from '../../../common/nkitwDesc';

interface ParamTypes {
  id: string;
}

export const TimelineView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const notifications = useNotifications();
  const { id } = useParams<ParamTypes>();
  const mountedRef = useRef(false);
  const [processes, setProcesses] = useState<TimelineProcessType[]>([]);
  const [productType, setProductType] = useState<GetProductTypeDto | undefined>(
    undefined,
  );

  const safeSet = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    if (!mountedRef.current) {
      return;
    }
    snrwb.reports
      .getTimelineByProductType(id)
      .then(org => {
        safeSet(() => setProcesses(org));
      })
      .catch(() => notifications.badLink());
    snrwb.productTypes.getById(id).then(pr => {
      safeSet(() => setProductType(pr));
    });
  }, [snrwb, id, notifications]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [id, snrwb.reports, refresh]);

  return (
    <Card>
      <Card.Header>Oś czasu typu wyrobu {nkitwDesc(productType)}</Card.Header>
      <Card.Body>
        <Card>
          <Timeline processes={processes} />
        </Card>
      </Card.Body>
    </Card>
  );
};
