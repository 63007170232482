import { MemoSubjectType } from '../validation/schemas';

export interface User {
  username: string;
  sub: string;
}

export enum DataChangeScope {
  inspection = 'inspection',
  sampleExam = 'sampleExam',
  sampleCollect = 'sampleCollect',
  proceeding = 'proceeding',
  pak = 'pak',
  kwz = 'kwz',
  productType = 'productType',
}

export interface DataKey {
  scope: DataChangeScope;
  id: string;
}

export const keyId = (key: DataKey) => `${key.scope}/${key.id}`;

export interface KnownClient {
  id: string;
  user: User;
  currentObjects: Map<string, number>; // keyId and register count
  memosEmployeeId?: string;
  memosOrganizationalUnitId?: string;
}

export enum Message {
  concurrency = 'concurrency',
  objectInEditMode = 'objectInEditMode',
  objectOutEditMode = 'objectOutEditMode',
  dataChanged = 'dataChanged',
  subscribeMemos = 'subscribeMemos',
  unsubscribeMemos = 'unsubscribeMemos',
  threadChanged = 'threadChanged',
}

export interface ConcurrencyArgs {
  key: DataKey;
  user: User;
}

export interface DataChangedArgs {
  key: DataKey;
  user: User;
}

export interface ClientToServerArgs {
  token: string;
}

export interface ObjectEditModeArgs extends ClientToServerArgs {
  key: DataKey;
}

export interface ThreadChangedArgs {
  threadId: string;
  subjectType?: MemoSubjectType;
  subjectId?: string;
}

export interface SubscribeMemosArgs extends ClientToServerArgs {
  organizationalUnitId: string;
}
