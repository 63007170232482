/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetLastApprovalDateDto
 */
export interface GetLastApprovalDateDto {
    /**
     * 
     * @type {Date}
     * @memberof GetLastApprovalDateDto
     */
    date: Date | null;
}

export function GetLastApprovalDateDtoFromJSON(json: any): GetLastApprovalDateDto {
    return GetLastApprovalDateDtoFromJSONTyped(json, false);
}

export function GetLastApprovalDateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLastApprovalDateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (json['date'] === null ? null : new Date(json['date'])),
    };
}

export function GetLastApprovalDateDtoToJSON(value?: GetLastApprovalDateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date === null ? null : value.date.toISOString()),
    };
}


