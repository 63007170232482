import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useNavigation } from '../../../common/navigation';
import {
  CreateUpdateInspectorDto,
  GetInspectorDto,
  GetSampleCollectDtoFromJSON,
  GetSampleDto,
  GetSampleExamDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as SampleCollectContext from '../../../common/snrwbCore/contexts/SampleCollectContext';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import { SampleCollect } from '../../components/SampleCollects/SampleCollect';
import { useCookies } from '../../../common/hooks/useCookies';
import { useAuth } from '../../../common/hooks/useAuth';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { AssociateDocuments } from '../../components/AssociatedDocuments/AssociateDocumentsTypes';

export const SampleCollectView: React.FC = () => {
  const auth = useAuth();
  const snrwb = useSnrwbCore();
  const nav = useNavigation();
  const cookies = useCookies();
  const notifications = useNotifications();
  const { id, tab } = useParams<{ id: string; tab?: string }>();
  const mountedRef = useRef(false);
  const [sampleCollect, setSampleCollect] = useState(
    GetSampleCollectDtoFromJSON({}),
  );
  const [sampleExams, setSampleExams] = useState<GetSampleExamDto[]>([]);
  const [samples, setSamples] = useState<GetSampleDto[]>([]);
  const [attachments, setAttachments] = useState(
    AttachmentContext.forAssociatedDocuments(snrwb, notifications, []),
  );
  const [inspectors, setInspectors] = useState(
    new Map<
      string,
      AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>
    >(),
  );

  const safe = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    SampleCollectContext.forSampleCollectView(snrwb, notifications, id, refresh)
      .then(model => {
        safe(() => model.samples && setSamples(model.samples));
        safe(() => model.sampleExams && setSampleExams(model.sampleExams));
        safe(() => model.inspectors && setInspectors(model.inspectors));
        safe(() => model.attachments && setAttachments(model.attachments));
        safe(
          () => model.sampleCollect && setSampleCollect(model.sampleCollect),
        );
      })
      .catch(() => {
        notifications.badLink(
          'Brak pobrania próbek w bazie danych. Mogło zostać przed chwilą usunięte.',
          '/badania',
        );
      });
  }, [snrwb, notifications, id]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [id, snrwb.inspections, refresh]);

  const unitsMatch =
    sampleCollect.examOrganizationalUnit?.id ===
      cookies.organizationalUnit?.id ||
    samples.find(
      s => s.operateOrganizationalUnit.id === cookies.organizationalUnit?.id,
    ) !== undefined;

  return (
    <SampleCollect
      editAuthorized={auth.check(Role.ExamsEdit) && unitsMatch}
      validOrganizationalUnit={unitsMatch}
      sampleCollect={sampleCollect}
      sampleExams={sampleExams}
      attachments={attachments}
      inspectors={inspectors}
      defaultActiveKey={tab}
      api={SampleCollectContext.handlersForSampleCollectView(
        snrwb,
        notifications,
        sampleCollect,
        refresh,
        nav.mySampleCollects,
      )}
    />
  );
};
