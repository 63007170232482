import React from 'react';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';

import {
  GetPakDto,
  GetInspectionDto,
  GetProceedingDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { useNavigation } from '../../../common/navigation';
import { PakViewApi } from '../../../common/snrwbCore/contexts/PakContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

export const PakButtons = (props: {
  value: GetPakDto;
  relatedInspections?: GetInspectionDto[];
  relatedProceedings?: GetProceedingDto[];
  editAuthorized: boolean;
  api: PakViewApi;
}) => {
  const nav = useNavigation();

  return (
    <div className="d-flex justify-content-between">
      <div>
        {props.relatedInspections &&
          props.relatedInspections.map(inspection => (
            <Button
              key={inspection.id}
              variant="outline-secondary"
              className="mx-1"
              onClick={() => nav.inspection(inspection.id)}
            >
              Kontrola nr {inspection.metric.fileNumber || '-'}
            </Button>
          ))}
        {props.value.sample && (
          <Button
            variant="outline-secondary"
            className="mx-1"
            onClick={() => nav.sampleExam(props.value.sample.sampleExam.id)}
          >
            <Icon.Search /> Badanie nr{' '}
            {props.value.sample.sampleExam.sysNumber || '-'}
          </Button>
        )}
        {props.relatedProceedings &&
          props.relatedProceedings.map(proceeding => (
            <Button
              key={proceeding.id}
              variant="outline-secondary"
              onClick={() => nav.proceeding(proceeding.id)}
            >
              Postępowanie nr {proceeding.actNumber || '-'}
            </Button>
          ))}
      </div>
      {props.editAuthorized && !props.value.approved && (
        <div>
          <ConfirmationButton
            variant="outline-danger"
            roleAnyOf={[Role.PakRevoke]}
            confirmation="Czy na pewno usunąć postępowanie w sprawie nałożenia kary?"
            onOK={props.api.deletePak}
            className="mx-1"
          >
            Usuń PAK
          </ConfirmationButton>
        </div>
      )}
    </div>
  );
};
