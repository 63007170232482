/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SourceIdWithDateDto,
    SourceIdWithDateDtoFromJSON,
    SourceIdWithDateDtoFromJSONTyped,
    SourceIdWithDateDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPakIdsWithAnotherSourceDto
 */
export interface GetPakIdsWithAnotherSourceDto {
    /**
     * 
     * @type {Array<SourceIdWithDateDto>}
     * @memberof GetPakIdsWithAnotherSourceDto
     */
    allSources: Array<SourceIdWithDateDto>;
}

export function GetPakIdsWithAnotherSourceDtoFromJSON(json: any): GetPakIdsWithAnotherSourceDto {
    return GetPakIdsWithAnotherSourceDtoFromJSONTyped(json, false);
}

export function GetPakIdsWithAnotherSourceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPakIdsWithAnotherSourceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allSources': ((json['allSources'] as Array<any>).map(SourceIdWithDateDtoFromJSON)),
    };
}

export function GetPakIdsWithAnotherSourceDtoToJSON(value?: GetPakIdsWithAnotherSourceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allSources': ((value.allSources as Array<any>).map(SourceIdWithDateDtoToJSON)),
    };
}


