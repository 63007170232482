import React from 'react';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  CreateUpdateOrganizationDto,
  GetCertificateDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  CertificateDto,
  convert,
} from '../../../common/snrwbCore/contexts/CertificateContext';
import { Organization } from '../../../common/snrwbCore/contexts/OrganizationContext';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';

import { OrgCertAction } from './OrgCert/OrgCertAction';

interface CreateUpdateCertificateProps {
  organization?: Organization;
  certificate?: GetCertificateDto;
  onAddNew?: (producerId: string) => void;
  onChange?: () => void;
  onClose?: () => void;
}

export const CreateUpdateCertificate: React.FC<CreateUpdateCertificateProps> =
  props => {
    const snrwb = useSnrwbCore();
    const notifications = useNotifications();

    const addCertificate = async (
      organization: Organization,
      certificate: CertificateDto,
      id?: string,
    ) => {
      let createCertificate: () => Promise<GetCertificateDto | void>;
      const cuCertificate =
        'id' in certificate ? convert(certificate) : certificate;
      if ('id' in organization) {
        cuCertificate.producerId = organization.id;
        createCertificate = async () =>
          id
            ? await snrwb.certificates.update(id, cuCertificate)
            : await snrwb.certificates.create(cuCertificate);
      } else {
        createCertificate = () =>
          snrwb.certificates.createWithOrganization(
            cuCertificate,
            organization as CreateUpdateOrganizationDto,
          );
      }

      try {
        const result = await createCertificate();
        if ((result as GetCertificateDto)?.producer) {
          cuCertificate.producerId = (result as GetCertificateDto).producer.id;
        }
        if (id) {
          props.onChange && props.onChange();
        } else if (cuCertificate.producerId) {
          props.onAddNew && props.onAddNew(cuCertificate.producerId);
        }

        return { saved: true, errors: [] };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    };

    const deleteCertificate = () => {
      if (props.certificate) {
        notifications.onPromise(
          snrwb.certificates.delete(props.certificate.id),
          props.onChange,
        );
      }
    };

    return (
      <OrgCertAction
        organization={props.organization}
        certificate={props.certificate}
        onCompleted={addCertificate}
        onDelete={props.certificate && deleteCertificate}
        onClose={props.onClose}
      />
    );
  };
