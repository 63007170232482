/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyDto
 */
export interface CompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    regon?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    nip?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    voivodeship?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    place?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    street?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    numbers?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    flat?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    postPlace?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    activityTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyDto
     */
    activityTypeShort?: string;
}

export function CompanyDtoFromJSON(json: any): CompanyDto {
    return CompanyDtoFromJSONTyped(json, false);
}

export function CompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regon': !exists(json, 'regon') ? undefined : json['regon'],
        'nip': !exists(json, 'nip') ? undefined : json['nip'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'voivodeship': !exists(json, 'voivodeship') ? undefined : json['voivodeship'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'postcode': !exists(json, 'postcode') ? undefined : json['postcode'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'numbers': !exists(json, 'numbers') ? undefined : json['numbers'],
        'flat': !exists(json, 'flat') ? undefined : json['flat'],
        'postPlace': !exists(json, 'postPlace') ? undefined : json['postPlace'],
        'activityTypeId': !exists(json, 'activityTypeId') ? undefined : json['activityTypeId'],
        'activityTypeShort': !exists(json, 'activityTypeShort') ? undefined : json['activityTypeShort'],
    };
}

export function CompanyDtoToJSON(value?: CompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regon': value.regon,
        'nip': value.nip,
        'name': value.name,
        'voivodeship': value.voivodeship,
        'place': value.place,
        'postcode': value.postcode,
        'street': value.street,
        'numbers': value.numbers,
        'flat': value.flat,
        'postPlace': value.postPlace,
        'activityTypeId': value.activityTypeId,
        'activityTypeShort': value.activityTypeShort,
    };
}


