import { GetOrganizationalUnitDto } from '../autogenerated/snrwbApiClient/models/GetOrganizationalUnitDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateOrganizationalUnitDto,
  CreateUpdateOrganizationalUnitDtoFromJSON,
} from '../autogenerated/snrwbApiClient/models';
import { OrganizationalUnitSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export enum OrganizationalUnitsGetOptions {
  all,
  notified,
  notNotified,
  assessmentUnit,
}

export interface OrganizationalUnitContextInterface {
  getById: (id: string) => Promise<GetOrganizationalUnitDto>;
  getMany: (
    options: OrganizationalUnitsGetOptions,
  ) => Promise<GetOrganizationalUnitDto[]>;
  update: (id: string, dto: CreateUpdateOrganizationalUnitDto) => Promise<void>;
  create: (
    dto: CreateUpdateOrganizationalUnitDto,
  ) => Promise<GetOrganizationalUnitDto>;
  changeToInactive: (id: string) => Promise<void>;
}

export const OrganizationalUnitContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.organizationalUnitControllerGet(id),
    getMany: (options: OrganizationalUnitsGetOptions) =>
      api.organizationalUnitControllerGetMany(options),
    update: (id: string, dto: CreateUpdateOrganizationalUnitDto) =>
      api.organizationalUnitControllerUpdate(id, dto),
    create: (dto: CreateUpdateOrganizationalUnitDto) =>
      api.organizationalUnitControllerCreate(dto),
    changeToInactive: (id: string) =>
      api.organizationalUnitControllerChangeToInactive(id),
  };
};

export const newOrganizationalUnit = (parentId?: string) => {
  return CreateUpdateOrganizationalUnitDtoFromJSON({
    parentId: parentId,
    notified: false,
    nationalTechnicalAssessmentUnit: false,
  });
};

export const validate = (dictionary: CreateUpdateOrganizationalUnitDto) =>
  validateAgainst(OrganizationalUnitSchema, dictionary);

export const convert = (organizationalUnit: GetOrganizationalUnitDto) => {
  if (!organizationalUnit) {
    return newOrganizationalUnit();
  }
  const converted =
    CreateUpdateOrganizationalUnitDtoFromJSON(organizationalUnit);
  converted.name = organizationalUnit.name;
  converted.shortname = organizationalUnit.shortname;

  return converted;
};
