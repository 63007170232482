import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import {
  Organization,
  newOrganization,
  stringify,
} from '../../../common/snrwbCore/contexts/OrganizationContext';
import { OrganizationSelector } from '../Organizations/OrganizationSelector';
import { CreateUpdateInspectionMetricDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/CreateUpdateInspectionMetricDto';
import { newInspectionMetric } from '../../../common/snrwbCore/contexts/InspectionMetricContext';
import { AddInspectionFunc } from '../../../common/snrwbCore/contexts/InspectionContext';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useAuth } from '../../../common/hooks/useAuth';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

import { InspectionMetric } from './InspectionMetric';

interface AddInspectionProps {
  onCompleted: AddInspectionFunc;
  className?: string;
  inspectionPlace?: string;
  inspectionType?: string;
  organization?: Organization;
  buttonText?: string;
  hideClearOrganizationButton?: boolean;
}

export const AddInspection: React.FC<AddInspectionProps> = props => {
  const [show, setShow] = useState(false);
  const [organizationValid, setOrganizationValid] = useState(
    props.organization ? true : false,
  );
  const [metricValid, setMetricValid] = useState(false);
  const [showOrganizationErrors, setShowOrganizationErrors] = useState(false);
  const [showMetricErrors, setShowMetricErrors] = useState(false);
  const [organization, setOrganization] = useState<Organization>(
    props.organization || newOrganization(),
  );
  const [metric, setMetric] = useState(newInspectionMetric());
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const auth = useAuth();
  const notifications = useNotifications();

  const handleClose = () => setShow(false);

  const handleShow = () => {
    if (!auth.check(Role.InspectionsEdit)) {
      notifications.unauthorized();
      return;
    }
    setShow(true);
  };

  const createInspection = () => {
    if (!organizationValid) {
      setShowOrganizationErrors(true);
      return;
    }
    if (!metricValid) {
      setShowMetricErrors(true);
      return;
    }

    props.onCompleted(organization, metric).then(saveStatus => {
      if (!saveStatus.saved) {
        setSaveErrors(saveStatus.errors);
        setShowSaveErrors(true);
        return;
      }

      setShow(false);
      if (saveStatus.action) {
        saveStatus.action();
      }
    });
  };

  const onChangeMetric = (
    metric: CreateUpdateInspectionMetricDto,
    valid: boolean,
  ) => {
    setMetric(metric);
    setMetricValid(valid);
  };

  useEffect(() => {
    if (organizationValid) {
      setShowOrganizationErrors(false);
    }
    if (metricValid) {
      setShowMetricErrors(false);
    }
  }, [organizationValid, metricValid]);

  useEffect(() => {
    setOrganizationValid(props.organization ? true : false);
    setMetricValid(false);
    setShowOrganizationErrors(false);
    setShowMetricErrors(false);
    setOrganization(props.organization || newOrganization());
    setMetric(newInspectionMetric());
    setShowSaveErrors(false);
    setSaveErrors([]);
  }, [show, props.organization]);

  return (
    <>
      <Button
        variant="outline-primary"
        onClick={handleShow}
        className={props.className}
      >
        {props.buttonText || 'Nowa kontrola'}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        centered={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Tworzenie nowej kontroli</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="col-lg-6 col-12 mb-1">
              <Card>
                <Card.Header>Podmiot</Card.Header>
                <Card.Body>
                  <OrganizationSelector
                    onSelect={setOrganization}
                    onValidation={setOrganizationValid}
                    value={organization}
                    hideClearButton={props.hideClearOrganizationButton}
                    readOnly={props.organization ? true : false}
                    showValidationErrors={showOrganizationErrors}
                    onClear={() => {
                      setOrganization(props.organization || newOrganization());
                      setOrganizationValid(false);
                    }}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col className="col-lg-6 col-12 mb-1">
              <Card>
                <Card.Header>Metryka</Card.Header>
                <Card.Body aria-disabled="true">
                  <InspectionMetric
                    savingMode="outer"
                    inspectionPlace={props.inspectionPlace}
                    inspectionType={props.inspectionType}
                    showValidationErrors={showMetricErrors}
                    getAddress={() => stringify(organization)}
                    onChange={onChangeMetric}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={createInspection}>
            Utwórz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
