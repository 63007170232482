import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetOrganizationDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow from '../../../app/components/FormRow';
import { useNavigation } from '../../../common/navigation';
import AlertModal from '../../../app/components/AlertModal';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';

interface ParamTypes {
  orgDeleteId: string;
  orgStayId: string;
}

export const MerginOrganizationsView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const nav = useNavigation();
  const notifications = useNotifications();
  const [showProblem, setShowProblem] = useState(false);
  const { orgDeleteId, orgStayId } = useParams<ParamTypes>();
  const mountedRef = useRef(false);
  const [organizationDelete, setOrganizationDelete] = useState<
    GetOrganizationDto | undefined
  >(undefined);
  const [organizationStay, setOrganizationStay] = useState<
    GetOrganizationDto | undefined
  >(undefined);

  const safeSet = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    if (!mountedRef.current) {
      return;
    }
    snrwb.organizations
      .getById(orgDeleteId)
      .then(org => {
        safeSet(() => setOrganizationDelete(org));
      })
      .catch(() => notifications.badLink());
    snrwb.organizations
      .getById(orgStayId)
      .then(org => {
        safeSet(() => setOrganizationStay(org));
      })
      .catch(() => notifications.badLink());
  }, [snrwb, orgDeleteId, orgStayId, notifications]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [refresh]);

  const mergeOrganizations = async () => {
    if (organizationDelete && organizationStay) {
      await snrwb.organizations
        .mergeOrganizations(organizationDelete.id, organizationStay.id)
        .then(() => {
          setShowProblem(true);
        });
    }
  };

  return (
    <Card>
      <Card.Header>Sklejanie podmiotów</Card.Header>
      <Card.Body>
        <>
          <Card>
            <Card.Header>Podmiot główny</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="organizationalUnit" label="Nazwa">
                  <Form.Control
                    type="text"
                    value={organizationStay?.name || ''}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="organizationalUnitNIP" label="NIP">
                  <Form.Control
                    type="text"
                    value={organizationStay?.nip || ''}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="organizationalUnitAddress" label="Adres">
                  <Form.Control
                    type="text"
                    value={
                      (organizationStay &&
                        OrganizationContext.address(organizationStay)) ||
                      ''
                    }
                    readOnly={true}
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <br />
          <br />
          <Card>
            <Card.Header>Podmiot zastępowany</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="organizationalUnitDel" label="Nazwa">
                  <Form.Control
                    type="text"
                    value={organizationDelete?.name || ''}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="organizationalUnitDelNIP" label="NIP">
                  <Form.Control
                    type="text"
                    value={organizationDelete?.nip || ''}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="organizationalUnitDelAddress" label="Adres">
                  <Form.Control
                    type="text"
                    value={
                      (organizationDelete &&
                        OrganizationContext.address(organizationDelete)) ||
                      ''
                    }
                    readOnly={true}
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <br />
          <br />
          {organizationStay?.id !== organizationDelete?.id && (
            <ConfirmationButton
              variant="outline-primary"
              onOK={mergeOrganizations}
              className="mx-2"
              roleAnyOf={[Role.OrganizationsMerging]}
              confirmation="Uwaga: operacja jest nieodwracalna. Czy na pewno wykonać sklejanie?"
            >
              Sklej podmioty
            </ConfirmationButton>
          )}
          <Button
            variant="outline-secondary"
            onClick={() =>
              organizationStay && nav.organization(organizationStay.id)
            }
          >
            Rezygnuj
          </Button>
          <AlertModal
            show={showProblem}
            onHide={() => {
              setShowProblem(false);
              organizationStay && nav.organization(organizationStay.id);
            }}
            variant="info"
          >
            Podmioty zostały sklejone.
          </AlertModal>
        </>
      </Card.Body>
    </Card>
  );
};
