import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as ProtocolDuringInspectionContext from '../../../common/snrwbCore/contexts/ProtocolDuringInspectionContext';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import { CreateUpdateProtocolDuringInspectionDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow, {
  FormRow48,
  FormRow48R,
} from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AddAttachmentMemoized } from '../Attachments/AddAttachment';
import { AttachmentMemoized } from '../Attachments/Attachment';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import TextOrControl from '../../../app/components/TextOrControl';
import momencik from '../../../common/momencik';
import RichTextEdit from '../../../app/components/RichTextEdit';
import RichTextSingleLine from '../../../app/components/RichTextSingleLine';

const ProtocolData = (props: {
  readonly: boolean;
  protocol: CreateUpdateProtocolDuringInspectionDto;
  type: string;
  attachments: AttachmentContext.MemoizedAttachment[];
  setPresented: (obj: Partial<CreateUpdateProtocolDuringInspectionDto>) => void;
  setProperty: (obj: Partial<CreateUpdateProtocolDuringInspectionDto>) => void;
  presentedToSelected: () => void;
  changeAndBlur: (
    obj: Partial<CreateUpdateProtocolDuringInspectionDto>,
  ) => void;
  changeValue?: boolean;
}) => {
  const attachmentCreator = () => {
    const att = AttachmentContext.newAttachment();
    att.enObjectType = AttachmentContext.ObjectType.ProtocolDuringInspection;
    return att;
  };

  return (
    <>
      <Form className="d-grid gap-3">
        <Row>
          <Col md={9}>
            <FormRow48 controlId="number" label="Numer">
              <TextOrControl
                readonly={props.readonly}
                value={props.protocol.number}
              >
                <Form.Control
                  type="text"
                  value={props.protocol.number || ''}
                  onChange={e => props.setPresented({ number: e.target.value })}
                  onBlur={props.presentedToSelected}
                />
              </TextOrControl>
            </FormRow48>
          </Col>
          <Col md={3}>
            <FormRow48R controlId="date" label="Data">
              <TextOrControl
                readonly={props.readonly}
                value={momencik(props.protocol.date)}
              >
                <DatePicker
                  value={props.protocol.date}
                  onChange={date => props.setProperty({ date: date })}
                />
              </TextOrControl>
            </FormRow48R>
          </Col>
        </Row>
        {props.type === 'inwentaryzacja' && (
          <FormRow controlId="quantity" label="Ilość wyrobu">
            <TextOrControl
              readonly={props.readonly}
              value={props.protocol.quantity}
            >
              <RichTextSingleLine
                value={props.protocol.quantity || ''}
                onChange={v => props.changeAndBlur({ quantity: v })}
                onlyIndexes={true}
                changeValue={props.changeValue}
              />
            </TextOrControl>
          </FormRow>
        )}
        <FormRow
          controlId="description"
          label={ProtocolDuringInspectionContext.labelForDescription(
            props.type,
          )}
        >
          <TextOrControl
            readonly={props.readonly}
            value={props.protocol.description}
          >
            <RichTextEdit
              className="narrow"
              value={props.protocol.description}
              onChange={value => props.setProperty({ description: value })}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="witnesses" label="W obecności">
          <TextOrControl
            readonly={props.readonly}
            value={props.protocol.witnesses}
          >
            <DynamicTextarea
              value={props.protocol.witnesses || ''}
              onChange={e => props.setPresented({ witnesses: e.target.value })}
              onBlur={props.presentedToSelected}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="repairMethod" label="Omówienie poprawek">
          <TextOrControl
            readonly={props.readonly}
            value={props.protocol.repairMethod}
          >
            <DynamicTextarea
              value={props.protocol.repairMethod || ''}
              onChange={e =>
                props.setPresented({ repairMethod: e.target.value })
              }
              onBlur={props.presentedToSelected}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="attachments" label="Załączniki">
          <Row>
            <Col md={10}>
              <TextOrControl
                readonly={props.readonly}
                value={props.protocol.attachments}
              >
                <DynamicTextarea
                  value={props.protocol.attachments || ''}
                  onChange={e =>
                    props.setPresented({ attachments: e.target.value })
                  }
                  onBlur={props.presentedToSelected}
                />
              </TextOrControl>
            </Col>
            <Col md={2}>
              <AssociatedDocuments
                name="Pliki"
                align="end"
                readonly={props.readonly}
                createModalType={AddAttachmentMemoized}
                presentModalType={AttachmentMemoized}
                docs={AttachmentContext.forModal(
                  props.attachments,
                  attachmentCreator,
                )}
              />
            </Col>
          </Row>
        </FormRow>
      </Form>
    </>
  );
};

const ProtocolSigning = (props: {
  readonly: boolean;
  protocol: CreateUpdateProtocolDuringInspectionDto;
  setPresented: (obj: Partial<CreateUpdateProtocolDuringInspectionDto>) => void;
  setProperty: (obj: Partial<CreateUpdateProtocolDuringInspectionDto>) => void;
  presentedToSelected: () => void;
}) => {
  return (
    <Form className="d-grid gap-3 mt-3">
      <Row>
        <Col sm={6}>
          <FormRow
            controlId="signatureDate"
            label="Data podpisania"
            labelSize={6}
            valueSize={6}
          >
            <TextOrControl
              readonly={props.readonly}
              value={momencik(props.protocol.signatureDate)}
            >
              <DatePicker
                value={props.protocol.signatureDate}
                onChange={date => props.setProperty({ signatureDate: date })}
              />
            </TextOrControl>
          </FormRow>
        </Col>
        <Col sm={6}>
          <FormRow
            controlId="placeOfSignature"
            label="Miejsce podpisania"
            labelSize={6}
            valueSize={6}
          >
            <TextOrControl
              readonly={props.readonly}
              value={props.protocol.placeOfSignature}
            >
              <Form.Control
                type="text"
                value={props.protocol.placeOfSignature || ''}
                onChange={e =>
                  props.setPresented({ placeOfSignature: e.target.value })
                }
                onBlur={props.presentedToSelected}
              />
            </TextOrControl>
          </FormRow>
        </Col>
      </Row>
      <FormRow controlId="refusalReason" label="Powód odmowy">
        <TextOrControl
          readonly={props.readonly}
          value={props.protocol.refusalReason}
        >
          <Form.Control
            type="text"
            value={props.protocol.refusalReason || ''}
            onChange={e =>
              props.setPresented({ refusalReason: e.target.value })
            }
            onBlur={props.presentedToSelected}
          />
        </TextOrControl>
      </FormRow>
    </Form>
  );
};

export const ProtocolDuringInspectionEdit = (props: {
  readonly: boolean;
  value: CreateUpdateProtocolDuringInspectionDto;
  type: string;
  attachments: AttachmentContext.MemoizedAttachment[];
  onChange: (dto: CreateUpdateProtocolDuringInspectionDto) => void;
}) => {
  const [protocolDuringInspection, setProtocolDuringInspection] =
    useState<CreateUpdateProtocolDuringInspectionDto>(
      ProtocolDuringInspectionContext.newProtocol(),
    );
  const [
    presentedProtocolDuringInspection,
    setPresentedProtocolDuringInspection,
  ] = useState<CreateUpdateProtocolDuringInspectionDto>(
    protocolDuringInspection,
  );

  const setProperty = (
    obj: Partial<CreateUpdateProtocolDuringInspectionDto>,
  ) => {
    const dto = { ...protocolDuringInspection, ...obj };
    if (momencik(dto.date) === momencik(dto.signatureDate)) {
      dto.date = dto.signatureDate;
    }
    setProtocolDuringInspection(dto);
    props.onChange(dto);
  };
  const setPresented = (
    obj: Partial<CreateUpdateProtocolDuringInspectionDto>,
  ) => {
    setPresentedProtocolDuringInspection({
      ...presentedProtocolDuringInspection,
      ...obj,
    });
  };

  const changeAndBlur = (
    obj: Partial<CreateUpdateProtocolDuringInspectionDto>,
  ) => {
    setPresentedProtocolDuringInspection({
      ...presentedProtocolDuringInspection,
      ...obj,
    });
    setProtocolDuringInspection({
      ...presentedProtocolDuringInspection,
      ...obj,
    });
    props.onChange({
      ...presentedProtocolDuringInspection,
      ...obj,
    });
  };

  const presentedToSelected = () => {
    setProtocolDuringInspection(presentedProtocolDuringInspection);
    props.onChange(presentedProtocolDuringInspection);
  };

  useEffect(() => {
    if (props.value) {
      setPresentedProtocolDuringInspection(props.value);
      setProtocolDuringInspection(props.value);
    }
  }, [props.value]);

  return (
    <>
      <ProtocolData
        readonly={props.readonly}
        protocol={presentedProtocolDuringInspection}
        type={props.type}
        attachments={props.attachments}
        setPresented={setPresented}
        setProperty={setProperty}
        presentedToSelected={presentedToSelected}
        changeAndBlur={changeAndBlur}
        changeValue={true}
      />
      <ProtocolSigning
        readonly={props.readonly}
        protocol={presentedProtocolDuringInspection}
        setPresented={setPresented}
        setProperty={setProperty}
        presentedToSelected={presentedToSelected}
      />
    </>
  );
};
