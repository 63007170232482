import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { Selector } from '../Selects/Selector';

export const EssentialFeatureOverwriteModal = (props: {
  variant: 'feature' | 'functionalProperty' | 'examMethod';
  show: boolean;
  currValue: string;
  onHide: () => void;
  onOverwrite: (
    property: 'feature' | 'functionalProperty' | 'examMethod',
    newValue: string,
  ) => void;
}) => {
  const snrwb = useSnrwbCore();
  const [newValue, setNewValue] = useState<string>('');

  const overwrite = async (oldValue?: string, newValue?: string) => {
    if (newValue && oldValue) {
      switch (props.variant) {
        case 'feature':
          await snrwb.essentialFeatures.overwriteFeatures(oldValue, newValue);
          break;
        case 'functionalProperty':
          await snrwb.essentialFeatures.overwriteFunctionalProperties(
            oldValue,
            newValue,
          );
          break;
        case 'examMethod':
          await snrwb.essentialFeatures.overwriteExamMethods(
            oldValue,
            newValue,
          );
          break;
      }

      props.onOverwrite(props.variant, newValue);
    }
    props.onHide();
  };
  return (
    <>
      <Modal
        show={props.show}
        centered={true}
        size="lg"
        backdrop="static"
        backdropClassName="modal-on-modal-backdrop"
        className="modal-on-modal"
      >
        <Modal.Header>
          <Modal.Title>{`Zastąp wartość "${props.currValue}"`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.variant === 'feature' && (
            <Selector
              clearable
              provider={snrwb.essentialFeatures.getFeaturesByPattern}
              onChange={option => setNewValue(option.value)}
            />
          )}
          {props.variant === 'functionalProperty' && (
            <Selector
              clearable
              provider={
                snrwb.essentialFeatures.getFunctionalPropertiesByPattern
              }
              onChange={option => setNewValue(option.value)}
            />
          )}
          {props.variant === 'examMethod' && (
            <Selector
              clearable
              provider={snrwb.essentialFeatures.getExamMethodsByPattern}
              onChange={option => setNewValue(option.value)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {newValue && (
            <ConfirmationButton
              variant="outline-primary"
              onOK={() => overwrite(props.currValue, newValue)}
              className="mx-2"
              roleAnyOf={[Role.SamplesMerging]}
              confirmation={`Uwaga! Zastąpione zostaną WSZYSTKIE wystąpienia wartości ${props.currValue} wartością ${newValue}. Operacja jest nieodwracalna. Czy na pewno wykonać zastąpienie?`}
            >
              Zastąp
            </ConfirmationButton>
          )}
          <Button variant="outline-secondary" onClick={props.onHide}>
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
