import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import {
  GetSampleExamDtoFromJSON,
  TimelineProcessType,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as SampleExamContext from '../../../common/snrwbCore/contexts/SampleExamContext';
import oneliner from '../../../common/oneliner';
import nkitwDesc from '../../../common/nkitwDesc';
import { Timeline } from '../../components/ProductTypes/Timeline';

interface ParamTypes {
  id: string;
}

export const SampleExamTimelineView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const notifications = useNotifications();
  const { id } = useParams<ParamTypes>();

  const mountedRef = useRef(false);
  const [sampleExam, setSampleExam] = useState(GetSampleExamDtoFromJSON({}));
  const [processes, setProcesses] = useState<TimelineProcessType[]>([]);

  const safe = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(
    (modelGenerator?: SampleExamContext.ModelGeneratorFunc) => {
      const generator = modelGenerator || SampleExamContext.forSampleExamView;
      generator(snrwb, notifications, id, refresh)
        .then(model => {
          safe(() => model.exam && setSampleExam(model.exam));
        })
        .catch(() => {
          notifications.badLink(
            'Brak badania próbek w bazie danych. Mogło zostać przed chwilą usunięte.',
            '/badania',
          );
        });

      snrwb.reports
        .getTimelineBySampleExam(id)
        .then(org => {
          safe(() => setProcesses(org));
        })
        .catch(() => notifications.badLink());
    },
    [snrwb, notifications, id],
  );

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [id, snrwb.inspections, refresh]);

  const [header, setHeader] = useState('Oś czasu');
  useEffect(() => {
    const productDescription =
      sampleExam &&
      sampleExam.productType &&
      oneliner(nkitwDesc(sampleExam.productType), 60);
    setHeader(
      `Oś czasu: Badanie próbki dla ${productDescription} nr ${sampleExam.sysNumber}`,
    );
  }, [sampleExam]);

  return (
    <Card>
      <Card.Header>{header}</Card.Header>
      <Card.Body>
        <Card>
          <Timeline processes={processes} hideEvents />
        </Card>
      </Card.Body>
    </Card>
  );
};
