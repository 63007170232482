import React from 'react';
import { Table } from 'react-bootstrap';

import { ReportCostSampleExamsType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';
import { ReportLegend } from '../Reports/ReportLegend';
import { Abbreviation } from '../../../common/abbreviationLegend';

type Props = {
  data: ReportCostSampleExamsType;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
  legend?: Abbreviation[];
};

export const ReportCostsSampleExams: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
  legend,
}) => {
  const cols = ['25.1', '16.2a', 'B', 'Pp', 'Controls'];
  const hasData = Object.keys(data.details).length > 0;

  const getSumCpr = (key: string) =>
    cols
      .reduce(
        (acc, oCol) => acc + (data?.details?.[key]?.[oCol]?.sumCpr || 0),
        0,
      )
      .toFixed(2);

  const getSumWithoutCpr = (key: string) =>
    cols
      .reduce(
        (acc, oCol) => acc + (data?.details?.[key]?.[oCol]?.sumWithoutCpr || 0),
        0,
      )
      .toFixed(2);

  const getSumAllCpr = () =>
    cols
      .reduce((acc, oCol) => acc + (data?.sum?.[oCol]?.sumCpr || 0), 0)
      .toFixed(2);

  const getSumAllWithoutCpr = () =>
    cols
      .reduce((acc, oCol) => acc + (data?.sum?.[oCol]?.sumWithoutCpr || 0), 0)
      .toFixed(2);

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter} legend={legend}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th rowSpan={3}>Organ</th>
              <th colSpan={12}>{reportName}</th>
              <th rowSpan={2} colSpan={2}>
                Łączna wysokość opłat stanowiących równowartość kosztów
                przeprowadzonych badań nałożonych na podstawie art. 26 ust.4
              </th>
            </tr>
            <tr>
              <th colSpan={2} className="costs-column">
                25.1
              </th>
              <th colSpan={2} className="costs-column">
                16.2a
              </th>
              <th colSpan={2}>B</th>
              <th colSpan={2}>Pp</th>
              <th colSpan={2}>próbki kontrolne zlecone w RS</th>
              <th colSpan={2}>Razem koszty badań</th>
            </tr>
            <tr>
              <th>CPR</th>
              <th>PW</th>
              <th>CPR</th>
              <th>PW</th>
              <th>CPR</th>
              <th>PW</th>
              <th>CPR</th>
              <th>PW</th>
              <th>CPR</th>
              <th>PW</th>
              <th>CPR</th>
              <th>PW</th>
              <th>CPR</th>
              <th>PW</th>
            </tr>
          </thead>

          {hasData && (
            <tbody>
              {Object.keys(data.details)
                .sort()
                .map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    {cols.map((c, idx) => (
                      <React.Fragment key={idx}>
                        <td className="costs-column">
                          {(data?.details?.[key]?.[c]?.sumCpr || 0).toFixed(2)}
                        </td>
                        <td className="costs-column">
                          {(
                            data?.details?.[key]?.[c]?.sumWithoutCpr || 0
                          ).toFixed(2)}
                        </td>
                      </React.Fragment>
                    ))}
                    <td className="costs-column">{getSumCpr(key)}</td>
                    <td className="costs-column">{getSumWithoutCpr(key)}</td>
                    <td className="costs-column">
                      {(
                        data?.details?.[key]?.['art. 26 ust.4']?.sumCpr || 0
                      ).toFixed(2)}
                    </td>
                    <td className="costs-column">
                      {(
                        data?.details?.[key]?.['art. 26 ust.4']
                          ?.sumWithoutCpr || 0
                      ).toFixed(2)}
                    </td>
                  </tr>
                ))}
              <tr>
                <td>Suma:</td>
                {cols.map((c, idx) => (
                  <React.Fragment key={idx}>
                    <td className="costs-column">
                      {' '}
                      {(data.sum?.[c]?.sumCpr || 0).toFixed(2)}
                    </td>
                    <td className="costs-column">
                      {' '}
                      {(data.sum?.[c]?.sumWithoutCpr || 0).toFixed(2)}
                    </td>
                  </React.Fragment>
                ))}
                <td className="costs-column"> {getSumAllCpr()}</td>
                <td className="costs-column"> {getSumAllWithoutCpr()}</td>
                <td className="costs-column">
                  {' '}
                  {(data.sum?.['art. 26 ust.4']?.sumCpr || 0).toFixed(2)}
                </td>
                <td className="costs-column">
                  {' '}
                  {(data.sum?.['art. 26 ust.4']?.sumWithoutCpr || 0).toFixed(2)}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
      {legend && <ReportLegend legend={legend} />}
    </>
  );
};
