/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InspectionPlanForInspectedProductDetails,
    InspectionPlanForInspectedProductDetailsFromJSON,
    InspectionPlanForInspectedProductDetailsFromJSONTyped,
    InspectionPlanForInspectedProductDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportInspectionPlanForInspectedProductType
 */
export interface ReportInspectionPlanForInspectedProductType {
    /**
     * 
     * @type {object}
     * @memberof ReportInspectionPlanForInspectedProductType
     */
    details: object;
    /**
     * 
     * @type {InspectionPlanForInspectedProductDetails}
     * @memberof ReportInspectionPlanForInspectedProductType
     */
    sum: InspectionPlanForInspectedProductDetails;
}

export function ReportInspectionPlanForInspectedProductTypeFromJSON(json: any): ReportInspectionPlanForInspectedProductType {
    return ReportInspectionPlanForInspectedProductTypeFromJSONTyped(json, false);
}

export function ReportInspectionPlanForInspectedProductTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportInspectionPlanForInspectedProductType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': json['details'],
        'sum': InspectionPlanForInspectedProductDetailsFromJSON(json['sum']),
    };
}

export function ReportInspectionPlanForInspectedProductTypeToJSON(value?: ReportInspectionPlanForInspectedProductType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': value.details,
        'sum': InspectionPlanForInspectedProductDetailsToJSON(value.sum),
    };
}


