import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { ReportProductsByMisstatement } from '../../../../common/types/ReportTypes';
import { ReportInspectedProductsByMisstatement } from '../../../components/ReportInspectedProducts/ReportInspectedProductsByMisstatement';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import abbreviationLegend from '../../../../common/abbreviationLegend';

type Props = {
  group: 'I' | 'II' | 'III';
};

export const ReportInspectedProductsByMisstatementView: React.FC<Props> = ({
  group,
}) => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportProductsByMisstatement>({
    details: {},
    sum: {},
  });

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports
      .reportProductsByMisstatement(true, filter)
      .then(dataReports => {
        setData(dataReports);
      });
  };
  const reportCols =
    group === 'I'
      ? ['BO', 'ON', 'NO', 'BI', 'NI']
      : group === 'II'
      ? ['BZ', 'ND', 'BS', 'BWU']
      : ['BT', 'NZKP', 'NCZKP', 'NCSWU', 'IN'];

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName={`Rodzaje stwierdzonych nieprawidłowości - grupa ${group}`}
      />
      <ReportInspectedProductsByMisstatement
        data={data}
        reportName={`Rodzaje stwierdzonych nieprawidłowości - grupa ${group}`}
        reportCols={reportCols}
        filterApplied={filterApplied}
        usedFilter={usedFilter}
        legend={abbreviationLegend('inspectedProductsByMisstatement', [
          ...reportCols,
          'N',
          'NCPR',
        ])}
      />
    </>
  );
};
