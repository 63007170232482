import React from 'react';
import { Row, Card } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';

export const ReportsAnalysisAbnormalitiesView: React.FC = () => {
  const nav = useNavigation();

  return (
    <Card>
      <Card.Header>Analiza nieprawidłowości</Card.Header>
      <Card.Body>
        <Row xs={1} md={3} className="g-4">
          <ReportCardMenu
            title="Negatywne wyniki badań"
            text="Wykonanie analizy w zakresie negatywnych wyników badań"
            footer="Analiza nieprawidłowści"
            onClick={nav.report25}
          />
          <ReportCardMenu
            title="Nieprawidłowości formalne"
            text="Wykonanie analizy w zakresie nieprawidłowości formalnych"
            footer="Analiza nieprawidłowści"
            onClick={nav.reportFormalIrregularities}
          />
          <ReportCardMenu
            title="Przechowywanie próbki kontrolnej"
            text="Wykonanie analizy w zakresie próbek kontrolnych"
            footer="Próbki kontrolne"
            onClick={nav.report24}
          />
          <ReportCardMenu
            title="Kary pieniężne"
            text="Nałożone kary pieniężne"
            footer="Analiza nieprawidłowści"
            onClick={nav.report23}
          />
        </Row>
      </Card.Body>
    </Card>
  );
};
