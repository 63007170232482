import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  CreateUpdateAttachmentDto,
  GetAttachmentDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  handlersForKwzDetailsView,
  kwzData,
} from '../../../common/snrwbCore/contexts/KwzContext';
import * as KwzContext from '../../../common/snrwbCore/contexts/KwzContext';
import { KwzDetails } from '../../components/Kwzs/KwzDetails';
import { CreateUpdateKwzDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/CreateUpdateKwzDto';
import { AssociateDocuments } from '../../components/AssociatedDocuments/AssociateDocumentsTypes';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import { SnrwbCoreContextType } from '../../../common/snrwbCore';
import { NotificationsProviderType } from '../../../common/notifications';
import { useCookies } from '../../../common/hooks/useCookies';
import { useAuth } from '../../../common/hooks/useAuth';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useNavigation } from '../../../common/navigation';
import { GetKwzWithPartiesDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetKwzWithPartiesDto';

const getAssociateAttachment = async (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  idKwz: string,
  refresh?: (() => void) | undefined,
) => {
  const dict = await snrwb.dictionaries.getByDictionaryType('załącznik');
  const attachmentType = dict.find(atd => atd.value === 'Decyzja');

  const attFAD = AttachmentContext.forAssociatedDocuments;
  const atts = await snrwb.attachments.getAllForObject(
    AttachmentContext.ObjectType.Kwz,
    idKwz,
  );
  const attachments = attFAD(snrwb, notifications, atts, refresh);

  attachments.new = () => {
    const attachment = AttachmentContext.newAttachment();
    attachment.enObjectType = AttachmentContext.ObjectType.Kwz;
    attachment.objectId = idKwz;
    if (attachmentType) {
      attachment.typeId = attachmentType.id;
    }
    return attachment;
  };
  return attachments;
};

export const KwzDetailsView: React.FC = () => {
  const nav = useNavigation();
  const auth = useAuth();
  const snrwb = useSnrwbCore();
  const notifications = useNotifications();
  const cookies = useCookies();
  const { id } = useParams<{ id: string }>();
  const mountedRef = useRef(false);
  const [kwzCU, setKwzCU] = useState<CreateUpdateKwzDto>();
  const [kwz, setKwz] = useState<GetKwzWithPartiesDto>();
  const [oldKwzCU, setOldKwzCU] = useState(kwzCU);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const [attachment, setAttachment] = useState<
    AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>
  >(AttachmentContext.forAssociatedDocuments(snrwb, notifications, []));

  const safe = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    snrwb.kwzs
      .getByIdWithParties(id)
      .then(kwz =>
        safe(() => {
          setKwz(kwz);
          setOldKwzCU(kwzData(kwz));
          setKwzCU(kwzData(kwz));
          getAssociateAttachment(snrwb, notifications, kwz.id, refresh).then(
            att => setAttachment(att),
          );
        }),
      )
      .catch(() =>
        notifications.badLink(
          'Brak KWZ w bazie danych. Karta mogła zostać przed chwilą usunięta.',
          '/kwz',
        ),
      );
  }, [snrwb, id, notifications]);

  const api = handlersForKwzDetailsView(
    snrwb,
    notifications,
    refresh,
    nav.myKwzs,
  );

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    setValidationErrors([]);
    return () => {
      mountedRef.current = false;
    };
  }, [id, snrwb.kwzs, refresh]);

  const saveIfChanged = (newKwz: CreateUpdateKwzDto) => {
    if (_.isEqual(oldKwzCU, newKwz)) {
      return;
    }
    api.editKwz(id, newKwz);
    setOldKwzCU(newKwz);
  };

  const propertyChange = (obj: Partial<CreateUpdateKwzDto>, save = true) => {
    if (kwzCU) {
      const dto = { ...kwzCU, ...obj };
      setKwzCU(dto);
      if (save) {
        saveIfChanged(dto);
      }
    }
  };

  const onApprove = () => {
    if (kwzCU) {
      KwzContext.validateOnApprove(kwzCU).then(status => {
        setValidationErrors(status.errors);
        if (status.valid) {
          api.approveKwz(id);
        }
      });
    }
  };

  const onApproveValidation = async () => {
    if (kwzCU) {
      const status = await KwzContext.validateOnApprove(kwzCU);
      setValidationErrors(status.errors);
      return status.valid;
    } else {
      return true;
    }
  };

  const onClose = () => {
    setValidationErrors([]);
  };

  const unitsMatch = () => {
    return kwz?.organizationalUnit?.id === cookies.organizationalUnit?.id;
  };

  return (
    <KwzDetails
      validationErrors={validationErrors}
      api={api}
      onClose={onClose}
      onApprove={onApprove}
      onApproveValidation={onApproveValidation}
      relatedProceeding={() => {
        if (kwz?.judgment?.proceeding) {
          nav.proceeding(kwz.judgment.proceeding.id);
        }
      }}
      editAuthorized={auth.check(Role.KwzEdit) && unitsMatch()}
      publishAuthorized={auth.check(Role.KwzPublishing)}
      kwz={kwz}
      kwzCU={kwzCU}
      propertyChange={propertyChange}
      attachment={attachment}
    />
  );
};
