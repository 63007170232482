import React from 'react';

import {
  CreateUpdateAttachmentDto,
  GetAttachmentDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import RichTextEdit from '../../../app/components/RichTextEdit';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { AddAttachment } from '../Attachments/AddAttachment';
import { Attachment } from '../Attachments/Attachment';

export const SampleCollectAttachments: React.FC<{
  examApproved: boolean;
  editAuthorized: boolean;
  summary: string;
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  onSummaryChange: (value: string) => void;
}> = props => {
  return (
    <>
      <RichTextEdit
        value={props.summary}
        onChange={props.onSummaryChange}
        readonly={props.examApproved || !props.editAuthorized}
      />
      <div className="d-flex justify-content-end m-3">
        <AssociatedDocuments
          name="Załączniki"
          readonly={props.examApproved || !props.editAuthorized}
          createModalType={AddAttachment}
          presentModalType={Attachment}
          docs={props.attachments}
        />
      </div>
    </>
  );
};
