import React, { useState, useEffect } from 'react';
import { Alert, Form } from 'react-bootstrap';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import FormRow from '../../../app/components/FormRow';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import { Option } from '../Selects/Selector';

import { Organization } from './Organization';

interface OrganizationCheckOrFormProps {
  value: OrganizationContext.Organization;
  checkNow: number;
  readOnly?: boolean;
  editMode?: boolean;
  showValidationErrors: boolean;
  onSelect: (organization: OrganizationContext.Organization) => void;
  onChange?: (organization: OrganizationContext.Organization) => void;
  onValidation: (status: boolean) => void;
  onDecision?: (newOrganization: boolean) => void;
}

export const OrganizationCheckOrForm: React.FC<OrganizationCheckOrFormProps> =
  props => {
    const snrwbCore = useSnrwbCore();
    const [checkNow, setCheckNow] = useState(true);

    useEffect(() => {
      if (props.value.name || props.value.nip) {
        setCheckNow(false);
        return;
      }
      setCheckNow(true);
    }, [props.checkNow, props.value]);

    const select = (property: 'name' | 'nip', chosen: Option) => {
      if (chosen.new && !chosen.label) {
        return;
      }
      setCheckNow(false);
      if (chosen.new) {
        const object = { ...props.value };
        object[property] = chosen.label;
        props.onSelect(object);
        if (chosen.label && props.onDecision) {
          props.onDecision(true);
        }
      } else {
        snrwbCore.organizations.getById(chosen.value).then(organization => {
          props.onSelect(organization);
          props.onDecision && props.onDecision(false);
        });
      }
    };

    return checkNow ? (
      <>
        <Form className="d-grid gap-3 mb-3">
          <FormRow controlId="lookForByName" label="Nazwa">
            <CreatableClearableSelector
              onChange={chosen => select('name', chosen)}
              provider={snrwbCore.organizations.getOptionsByName}
            />
          </FormRow>
          <FormRow controlId="lookForByNIP" label="NIP">
            <CreatableClearableSelector
              onChange={chosen => select('nip', chosen)}
              isValidNewOption={(inputValue, value, options) => {
                if (!inputValue || inputValue.length < 10) {
                  return true;
                }
                if (options.find(opt => opt.label?.startsWith(inputValue))) {
                  return false;
                }
                return true;
              }}
              provider={snrwbCore.organizations.getOptionsByNip}
            />
          </FormRow>
        </Form>
        {props.showValidationErrors && (
          <Alert variant="danger">
            Należy wskazać podmiot lub utworzyć nowy.
          </Alert>
        )}
      </>
    ) : (
      <Organization
        readOnly={props.readOnly}
        value={props.value}
        showValidationErrors={props.showValidationErrors}
        onSelect={props.onSelect}
        onValidation={props.onValidation}
        onDecision={props.onDecision}
      />
    );
  };
