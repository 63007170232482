import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import {
  GetSampleExamDto,
  CreateUpdateSampleExamDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow from '../../../app/components/FormRow';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import * as SampleExamContext from '../../../common/snrwbCore/contexts/SampleExamContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import DictionaryMultiSelector from '../Selects/DictionaryMultiSelector';

export const SampleExamRegulations: React.FC<{
  editAuthorized: boolean;
  value: GetSampleExamDto;
  onChange: (inspection: CreateUpdateSampleExamDto) => void;
}> = props => {
  const [sampleExam, setSampleExam] = useState(
    SampleExamContext.toCuDto(props.value),
  );
  const [presentedSampleExam, setPresentedSampleExam] = useState(sampleExam);

  useEffect(() => {
    setSampleExam(SampleExamContext.toCuDto(props.value));
  }, [props.value]);
  useEffect(() => {
    setPresentedSampleExam(sampleExam);
  }, [sampleExam]);

  if (props.value.approved || !props.editAuthorized) {
    const presentation = SampleExamContext.regulationsPresent(props.value);
    return <ItemPresentation item={presentation} className="pt-3" />;
  }

  const setProperty = (obj: Partial<GetSampleExamDto>) => {
    setPresentedSampleExam({ ...presentedSampleExam, ...obj });
  };
  const saveIfChanged = (obj: CreateUpdateSampleExamDto) => {
    if (_.isEqual(sampleExam, obj)) {
      return;
    }
    props.onChange(obj);
  };
  const sampleExamChange = (obj: Partial<CreateUpdateSampleExamDto>) => {
    const dto = { ...sampleExam, ...obj };
    setSampleExam(dto);
    saveIfChanged(dto);
  };
  const presentedToSampleExam = () => {
    setSampleExam(presentedSampleExam);
    saveIfChanged(presentedSampleExam);
  };

  return (
    <Form className="d-grid gap-3 mt-2">
      <FormRow controlId="regulationIds" label="Przepisy">
        <DictionaryMultiSelector
          dictionaryName="badanie próbek - przepis"
          value={presentedSampleExam.regulationIds}
          onChange={ids => sampleExamChange({ regulationIds: ids })}
        />
      </FormRow>
      <FormRow controlId="regulations" label="">
        <DynamicTextarea
          value={presentedSampleExam.regulations || ''}
          onChange={e => setProperty({ regulations: e.target.value })}
          onBlur={presentedToSampleExam}
        />
      </FormRow>
    </Form>
  );
};
