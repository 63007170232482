import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as rstm from 'react-simple-tree-menu';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetDictionaryTypeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import {
  newDictionary,
  convert,
} from '../../../common/snrwbCore/contexts/DictionaryContext';
import * as DictionaryContext from '../../../common/snrwbCore/contexts/DictionaryContext';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';

interface DictionaryDeleteModalProps {
  item: rstm.TreeMenuItem;
  onAction: (id?: string) => void;
  show?: boolean;
  onClose?: () => void;
}

export const DictionaryDeleteModal: React.FC<DictionaryDeleteModalProps> = ({
  item,
  onAction,
  show,
  onClose,
}) => {
  const snrwbApi = useSnrwbCore();
  const notifications = useNotifications();

  const [dictionary, setDictionary] = useState(newDictionary());
  const [dictionaryType, setDictionaryType] = useState<GetDictionaryTypeDto>();

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    snrwbApi.dictionaries
      .getById(item.id)
      .then(dict => {
        setDictionary(convert(dict));
        return dict;
      })
      .then(dict => {
        if (dict?.dictionaryTypeId) {
          snrwbApi.dictionariesTypes
            .getById(dict?.dictionaryTypeId)
            .then(dicT => {
              setDictionaryType(dicT);
            });
        }
      });
  }, [dictionary.parentId, item.dictionaryTypeId, item.id, snrwbApi]);

  const handleSave = async () => {
    DictionaryContext.validate(dictionary).then(async status => {
      setValidationErrors(status.errors);
      if (status.valid) {
        setShowValidationErrors(false);
        try {
          await snrwbApi.dictionaries.changeToInactive(item.id);
          notifications.saveCompleted();
          onAction();
        } catch (response) {
          setValidationErrors(await responseErrors(response as Response));
          setShowValidationErrors(true);
        }
      } else {
        setShowValidationErrors(true);
      }
    });
  };

  const title = 'Usuwanie wartości słownika ' + dictionaryType?.name;
  const actionButtonLabel = 'Usuń';

  return (
    <Modal onHide={onClose} show={show} keyboard={true} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>Czy na pewno usunąć wartość: {dictionary.value}?</Modal.Body>
      <ValidationAlert
        show={showValidationErrors}
        errors={validationErrors}
        className="mt-3"
      />
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Zamknij
        </Button>
        <Button
          variant="primary"
          onClick={e => {
            e.stopPropagation();
            handleSave();
          }}
        >
          {actionButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
