import React, { useState, useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';

import { useAuth } from './hooks/useAuth';
import { useCookies } from './hooks/useCookies';
import { useNotifications } from './hooks/useNotifications';
import { useSnrwbCore } from './hooks/useSnrwbCore';
import {
  ConcurrencyArgs,
  DataChangedArgs,
  DataKey,
  Message,
  ThreadChangedArgs,
} from './snrwbCore/sockets/socket.datatypes';

export interface SocketsProviderType {
  objectInEditMode: (
    key: DataKey,
    onOtherUser: (args: ConcurrencyArgs) => void,
  ) => void;
  objectOutEditMode: (
    key: DataKey,
    onOtherUser: (args: ConcurrencyArgs) => void,
  ) => void;
  onDataChanged: (handler: (args: DataChangedArgs) => void) => void;
  removeOnDataChanged: (handler: (args: DataChangedArgs) => void) => void;
  subscribeMemos: () => void;
  unsubscribeMemos: () => void;
  onThreadChanged: (handler: (args: ThreadChangedArgs) => void) => void;
  removeOnThreadChanged: (handler: (args: ThreadChangedArgs) => void) => void;
}

const emptyHandler = () => {
  // typing purpose
};

const socketsContext: SocketsProviderType = {
  objectInEditMode: emptyHandler,
  objectOutEditMode: emptyHandler,
  onDataChanged: emptyHandler,
  removeOnDataChanged: emptyHandler,
  subscribeMemos: emptyHandler,
  unsubscribeMemos: emptyHandler,
  onThreadChanged: emptyHandler,
  removeOnThreadChanged: emptyHandler,
};

export const SocketsContext = React.createContext(socketsContext);

export const SocketsProvider: React.FC = ({ children }) => {
  const auth = useAuth();
  const notifications = useNotifications();
  const snrwb = useSnrwbCore();
  const cookies = useCookies();
  const [socket, setSocket] = useState<Socket>();
  const connectedRef = useRef(true);
  const noInternetHelper =
    process.env.REACT_APP_NO_INTERNET_HELPER?.toLocaleLowerCase() === 'on';

  useEffect(() => {
    if (!process.env.REACT_APP_SNRWBAPI_URL) {
      throw new Error('Unknown snrwb-core endpoint');
    }

    const url = new URL(process.env.REACT_APP_SNRWBAPI_URL);
    const socket = io(url.origin, {
      path: (url.pathname === '/api' ? '/ws' : '') + '/socket.io/',
    });

    socket.auth = { token: localStorage.getItem('mzt-token') };
    socket.on('disconnect', () => {
      if (noInternetHelper) {
        connectedRef.current = false;
        notifications.offline();
      }
    });
    socket.on('connect', () => {
      if (noInternetHelper && !connectedRef.current) {
        notifications.online();
      }
    });

    // uncomment to diagnose connection issues
    // socket.on('connect_error', err => {
    //   console.error(`Web sockets connection problems`);
    //   console.error(err);
    // });

    setSocket(socket);
  }, [notifications, snrwb, noInternetHelper]);

  const getToken = async () => {
    await auth.ensureTokenIsValid();
    return localStorage.getItem('mzt-token') || '';
  };

  return (
    <SocketsContext.Provider
      value={
        socket
          ? {
              objectInEditMode: (
                key: DataKey,
                handler: (data: ConcurrencyArgs) => void,
              ) => {
                if (!key?.id) {
                  return;
                }
                getToken().then(token => {
                  socket.on(Message.concurrency, handler);
                  socket.emit(Message.objectInEditMode, { key, token });
                });
              },
              objectOutEditMode: (
                key: DataKey,
                handler: (data: ConcurrencyArgs) => void,
              ) => {
                getToken().then(token => {
                  socket.emit(Message.objectOutEditMode, { key, token });
                  socket.removeListener(Message.concurrency, handler);
                });
              },
              onDataChanged: (handler: (args: DataChangedArgs) => void) => {
                socket.on(Message.dataChanged, handler);
              },
              removeOnDataChanged: (
                handler: (args: DataChangedArgs) => void,
              ) => {
                socket.removeListener(Message.dataChanged, handler);
              },
              subscribeMemos: () => {
                getToken().then(token => {
                  socket.emit(Message.subscribeMemos, {
                    token,
                    organizationalUnitId: cookies.organizationalUnit?.id,
                  });
                });
              },
              unsubscribeMemos: () => {
                getToken().then(token => {
                  socket.emit(Message.unsubscribeMemos, { token });
                });
              },
              onThreadChanged: (handler: (args: ThreadChangedArgs) => void) => {
                socket.on(Message.threadChanged, handler);
              },
              removeOnThreadChanged: (
                handler: (args: ThreadChangedArgs) => void,
              ) => {
                socket.removeListener(Message.threadChanged, handler);
              },
            }
          : socketsContext
      }
    >
      {children}
    </SocketsContext.Provider>
  );
};
