import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';

import { GetOrganizationDto } from '../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as OrganizationContext from '../../common/snrwbCore/contexts/OrganizationContext';
import { OrganizationModalSelector } from '../../snrwb/components/Organizations/OrganizationModalSelector';

import DatePicker from './DatePicker';
import DynamicTextarea from './DynamicTextarea';

export type KnownTypes =
  | 'string'
  | 'dynamic'
  | 'Date'
  | 'n/a'
  | 'supplier'
  | 'number';
export type ValueType =
  | string
  | Date
  | boolean
  | OrganizationContext.Organization
  | null
  | number;

export interface Column {
  label: string;
  type: KnownTypes;
  value: ValueType;
  key: string;
  onClickNotNA?: () => void;
}

export default (props: {
  className?: string;
  variant?: string;
  columns: Column[];
  onChange: (partial: Record<string, ValueType>) => void;
  onBlur?: () => void;
}) => {
  const [activeColumnLabel, setActiveColumnLabel] = useState<
    string | undefined
  >();
  const [currentValue, setCurrentValue] = useState<ValueType>(null);
  const chosen = props.columns.find(c => c.label === activeColumnLabel);

  const changeColumn = (label: string) => {
    setActiveColumnLabel(label);

    const column = props.columns.find(c => c.label === label);
    if (column) {
      if (column.type === 'n/a') {
        props.onChange(partialObject(true, label));
      } else {
        column.onClickNotNA && column.onClickNotNA();
        setCurrentValue(null);
      }
    }
  };

  const partialObject = (value: ValueType, label?: string) => {
    const partial: Record<string, ValueType> = {};
    for (const column of props.columns) {
      partial[column.key] =
        (label || activeColumnLabel) === column.label ? value : null;
    }
    return partial;
  };

  const setActiveColumn = useCallback(() => {
    for (const column of props.columns) {
      if (column.value) {
        setActiveColumnLabel(column.label);
        setCurrentValue(column.value);
        return true;
      }
    }
    return false;
  }, [props.columns]);

  useEffect(() => {
    if (props.columns.length === 0) {
      throw new Error('Columns array must not be empty');
    }

    if (setActiveColumn()) {
      return;
    }

    setActiveColumnLabel(props.columns[0].label);
    setCurrentValue(props.columns[0].value);
  }, [props.columns, setActiveColumn]);

  if (!chosen) {
    return <></>;
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isValidKey = /^\d$/.test(event.key);

    if (!isValidKey) {
      event.preventDefault();
    }
  };

  return (
    <div className={props.className}>
      <div className="d-flex">
        <DropdownButton
          variant={props.variant}
          title={activeColumnLabel}
          className="me-2"
        >
          {props.columns.map(column => (
            <Dropdown.Item
              key={column.label}
              onClick={() => changeColumn(column.label)}
              onBlur={props.onBlur}
            >
              {column.label}
            </Dropdown.Item>
          ))}
        </DropdownButton>
        {chosen.type === 'Date' && (
          <DatePicker
            value={currentValue as Date}
            onChange={date => props.onChange(partialObject(date))}
          />
        )}
        {chosen.type === 'string' && (
          <Form.Control
            type="text"
            value={(currentValue as string) || ''}
            onChange={e => setCurrentValue(e.target.value)}
            onBlur={() => {
              props.onChange(partialObject(currentValue));
            }}
          />
        )}
        {chosen.type === 'supplier' && (
          <OrganizationModalSelector
            initiallyShow={currentValue === null}
            value={currentValue as GetOrganizationDto}
            onSelect={organization =>
              props.onChange(partialObject(organization))
            }
            onClose={setActiveColumn}
          />
        )}
        {chosen.type === 'number' && (
          <Form.Control
            type="number"
            value={(currentValue as number) || ''}
            min="1990"
            max="2100"
            onKeyPress={handleKeyPress}
            onChange={e =>
              props.onChange(partialObject(parseInt(e.target.value)))
            }
            onBlur={props.onBlur}
          />
        )}
        {chosen.type === 'dynamic' && (
          <DynamicTextarea
            value={(currentValue as string) || ''}
            onChange={e => props.onChange(partialObject(e.target.value))}
            onBlur={props.onBlur}
          />
        )}
      </div>
    </div>
  );
};
