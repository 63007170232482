import React from 'react';
import { Card } from 'react-bootstrap';

import { Abbreviation } from '../../../common/abbreviationLegend';

type Props = {
  legend: Abbreviation[];
};

export const ReportLegend: React.FC<Props> = ({ legend }) => {
  return (
    <Card className="mt-3">
      <Card.Header>Legenda</Card.Header>
      <Card.Body>
        {legend &&
          legend.map((item: { key: string; value: string }, index: number) => (
            <div key={index}>
              {item.key}: {item.value}
            </div>
          ))}
      </Card.Body>
    </Card>
  );
};
