/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUpdateOrganizationDto,
    CreateUpdateOrganizationDtoFromJSON,
    CreateUpdateOrganizationDtoFromJSONTyped,
    CreateUpdateOrganizationDtoToJSON,
    CreateUpdateProductTypeDto,
    CreateUpdateProductTypeDtoFromJSON,
    CreateUpdateProductTypeDtoFromJSONTyped,
    CreateUpdateProductTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateWithNewProducerAndProductTypeParams
 */
export interface CreateWithNewProducerAndProductTypeParams {
    /**
     * 
     * @type {string}
     * @memberof CreateWithNewProducerAndProductTypeParams
     */
    inspectionId: string;
    /**
     * 
     * @type {CreateUpdateOrganizationDto}
     * @memberof CreateWithNewProducerAndProductTypeParams
     */
    producer: CreateUpdateOrganizationDto;
    /**
     * 
     * @type {CreateUpdateProductTypeDto}
     * @memberof CreateWithNewProducerAndProductTypeParams
     */
    productType: CreateUpdateProductTypeDto;
}

export function CreateWithNewProducerAndProductTypeParamsFromJSON(json: any): CreateWithNewProducerAndProductTypeParams {
    return CreateWithNewProducerAndProductTypeParamsFromJSONTyped(json, false);
}

export function CreateWithNewProducerAndProductTypeParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWithNewProducerAndProductTypeParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionId': json['inspectionId'],
        'producer': CreateUpdateOrganizationDtoFromJSON(json['producer']),
        'productType': CreateUpdateProductTypeDtoFromJSON(json['productType']),
    };
}

export function CreateWithNewProducerAndProductTypeParamsToJSON(value?: CreateWithNewProducerAndProductTypeParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionId': value.inspectionId,
        'producer': CreateUpdateOrganizationDtoToJSON(value.producer),
        'productType': CreateUpdateProductTypeDtoToJSON(value.productType),
    };
}


