/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAttachmentDto,
    GetAttachmentDtoFromJSON,
    GetAttachmentDtoFromJSONTyped,
    GetAttachmentDtoToJSON,
    GetSampleDto,
    GetSampleDtoFromJSON,
    GetSampleDtoFromJSONTyped,
    GetSampleDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSampleExtendedDto
 */
export interface GetSampleExtendedDto {
    /**
     * 
     * @type {GetSampleDto}
     * @memberof GetSampleExtendedDto
     */
    sample: GetSampleDto;
    /**
     * 
     * @type {object}
     * @memberof GetSampleExtendedDto
     */
    proceeding: object;
    /**
     * 
     * @type {object}
     * @memberof GetSampleExtendedDto
     */
    result: object;
    /**
     * 
     * @type {Array<GetAttachmentDto>}
     * @memberof GetSampleExtendedDto
     */
    attachments: Array<GetAttachmentDto>;
    /**
     * 
     * @type {string}
     * @memberof GetSampleExtendedDto
     */
    attachmentType: string;
}

export function GetSampleExtendedDtoFromJSON(json: any): GetSampleExtendedDto {
    return GetSampleExtendedDtoFromJSONTyped(json, false);
}

export function GetSampleExtendedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSampleExtendedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sample': GetSampleDtoFromJSON(json['sample']),
        'proceeding': json['proceeding'],
        'result': json['result'],
        'attachments': ((json['attachments'] as Array<any>).map(GetAttachmentDtoFromJSON)),
        'attachmentType': json['attachmentType'],
    };
}

export function GetSampleExtendedDtoToJSON(value?: GetSampleExtendedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sample': GetSampleDtoToJSON(value.sample),
        'proceeding': value.proceeding,
        'result': value.result,
        'attachments': ((value.attachments as Array<any>).map(GetAttachmentDtoToJSON)),
        'attachmentType': value.attachmentType,
    };
}


