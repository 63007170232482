import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { GridColumn, GridRecord } from './GridDataTypes';
import { GridLocationState } from './GridLocationState';

interface GridInnerTableProps {
  columns: GridColumn[];
  pageData: GridRecord[];
  orderBy?: string;
  orderDirection: 'ASC' | 'DESC';
  summaryData?: GridRecord;
  setOrderBy: (column: string) => void;
  setOrderDirection: (direction: 'ASC' | 'DESC') => void;
  onRowClick?: (key: string, newWindow?: boolean) => void;
}

export const GridInnerTable: React.FC<GridInnerTableProps> = props => {
  const history = useHistory<GridLocationState & unknown>();

  const row = (record: GridRecord) => {
    return props.columns.map(column => (
      <td key={record.key + column.property} className={column.className}>
        {record.values[column.property]}
      </td>
    ));
  };

  const sortColumn = (property: string, noSorting?: boolean) => {
    if (noSorting) {
      return;
    }
    props.setOrderBy(property);
    props.setOrderDirection(props.orderDirection === 'ASC' ? 'DESC' : 'ASC');
    history.push(history.location.pathname, {
      ...history.location.state,
      sort: property,
      sortDirection: props.orderDirection === 'ASC' ? 'DESC' : 'ASC',
    });
  };

  const handleMouseDown = (event: React.MouseEvent, key: string) => {
    if (event.button === 1) {
      event.preventDefault();
      event.stopPropagation();

      props.onRowClick && props.onRowClick(key, true);
    }
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {props.columns.map(column => (
            <th
              key={column.property}
              onClick={() => sortColumn(column.property, column.noSorting)}
              className={column.className}
            >
              {column.header}
              {column.property === props.orderBy &&
                (props.orderDirection === 'ASC' ? (
                  <Icon.ArrowUpShort />
                ) : (
                  <Icon.ArrowDownShort />
                ))}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.pageData.map(r => (
          <tr
            key={r.key}
            onClick={() => props.onRowClick && props.onRowClick(r.key)}
            onMouseDown={event => handleMouseDown(event, r.key)}
            className={props.onRowClick ? 'grid-clickable-row' : ''}
          >
            {row(r)}
          </tr>
        ))}
      </tbody>
      <tfoot>
        {props.summaryData && <tr key="summary">{row(props.summaryData)}</tr>}
      </tfoot>
    </Table>
  );
};
