import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import DictionarySelector from '../Selects/DictionarySelector';
import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import { CreateUpdateInspectionPlanGroupDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/CreateUpdateInspectionPlanGroupDto';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

interface AddEditInspectionPlanGroupProps {
  id?: string;
  addNew: boolean;
  inspectionPlanGroup: CreateUpdateInspectionPlanGroupDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    inspectionPlanGroup: CreateUpdateInspectionPlanGroupDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  onDelete: (id: string) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const AddEditInspectionPlanGroup: React.FC<AddEditInspectionPlanGroupProps> =
  props => {
    const [show, setShow] = useState(props.show);
    const [inspectionPlanGroup, setInspectionPlanGroup] = useState(
      props.inspectionPlanGroup,
    );
    const [showSaveErrors, setShowSaveErrors] = useState(false);
    const [saveErrors, setSaveErrors] = useState<string[]>([]);

    useEffect(() => {
      setShow(props.show);
      setSaveErrors([]);
      setShowSaveErrors(false);
      setInspectionPlanGroup(props.inspectionPlanGroup);
    }, [props.show, show, props.inspectionPlanGroup]);

    const createInspectionPlanGroup = async () => {
      props.onCompleted(inspectionPlanGroup, props.id).then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        }
      });
    };

    const deleteInspectionPlanGroup = () => {
      if (props.id) {
        props.onDelete(props.id).then(saveStatus => {
          if (!saveStatus.saved) {
            setSaveErrors(saveStatus.errors);
            setShowSaveErrors(true);
            return;
          }
        });
      }
    };

    const propertyChange = (
      obj: Partial<CreateUpdateInspectionPlanGroupDto>,
    ) => {
      setInspectionPlanGroup({ ...inspectionPlanGroup, ...obj });
    };

    const isProperNumber = (value: number) => {
      return value === 0 || !(value === null || isNaN(value));
    };

    return (
      <>
        <Modal
          onHide={props.handleClose}
          show={show}
          centered={true}
          keyboard={true}
          backdrop="static"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {props.id ? 'Edycja grupy' : 'Dodawanie nowej grupy'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card className="mb-2">
              <Card.Header>Grupa wyrobu budowlanego</Card.Header>
              <Card.Body>
                <Form className="d-grid gap-3">
                  <FormRow controlId="organization" label="Grupa wyrobu">
                    <DictionarySelector
                      dictionary="typ wyrobu - grupa"
                      maxDepth={1}
                      value={inspectionPlanGroup.productGroupId}
                      onChange={option =>
                        setInspectionPlanGroup({
                          ...inspectionPlanGroup,
                          productGroupId: option.value,
                        })
                      }
                    />
                  </FormRow>
                  <FormRow
                    controlId="numberOfInspections"
                    label="Liczba planowanych kontroli"
                  >
                    <Form.Control
                      type="text"
                      value={
                        isProperNumber(inspectionPlanGroup?.numberOfInspections)
                          ? inspectionPlanGroup?.numberOfInspections
                          : ''
                      }
                      onChange={e =>
                        propertyChange({
                          numberOfInspections: parseInt(e.target.value),
                        })
                      }
                    />
                  </FormRow>
                  <FormRow
                    controlId="numberOfInspectionsCpr"
                    label="W tym kontroli CPR"
                  >
                    <Form.Control
                      type="text"
                      value={
                        isProperNumber(
                          inspectionPlanGroup?.numberOfInspectionsCpr,
                        )
                          ? inspectionPlanGroup?.numberOfInspectionsCpr
                          : ''
                      }
                      onChange={e =>
                        propertyChange({
                          numberOfInspectionsCpr: parseInt(e.target.value),
                        })
                      }
                    />
                  </FormRow>
                </Form>
              </Card.Body>
            </Card>
            <ValidationAlert
              show={showSaveErrors}
              errors={saveErrors}
              className="mt-3"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={props.handleClose}>
              Zamknij
            </Button>
            {!props.addNew && (
              <ConfirmationButton
                className="mx-3"
                roleAnyOf={[Role.InspectionsPlansRevoke]}
                confirmation="Czy na pewno usunąć grupę wyrobów?"
                variant="outline-danger"
                onOK={deleteInspectionPlanGroup}
              >
                Usuń
              </ConfirmationButton>
            )}
            <Button
              variant="outline-primary"
              onClick={createInspectionPlanGroup}
            >
              Zapisz
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
