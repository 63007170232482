import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { ReportByCorrectiveAction } from '../../../components/ReportByCorrectiveAction/ReportByCorrectiveAction';
import { ReportCorrectiveActionsType } from '../../../../common/types/ReportTypes';

export const ReportCorrectiveActionView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportCorrectiveActionsType>();

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportCorrectiveAction(false, filter).then(dataReports => {
      setData(dataReports);
    });
  };

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName="Działania naprawcze, wykonanie nakazów"
      />
      <ReportByCorrectiveAction
        data={data}
        reportName="Podejmowane działania naprawcze"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
