import {
  GetInspectorDto,
  CreateUpdateInspectorDto,
  CreateUpdateInspectorDtoFromJSON,
  ValidationStatus,
} from '../autogenerated/snrwbApiClient';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import { validateAgainst, toResult } from '../validation/validateAgainst';
import { InspectorSchema } from '../validation/schemas';
import { AssociateDocumentsWithFilter } from '../../../snrwb/components/AssociatedDocuments/AssociateDocumentsTypes';
import { SnrwbCoreContextType } from '..';
import { NotificationsProviderType } from '../../notifications';
import momencik from '../../momencik';

export interface InspectorContextInterface {
  getById: (id: string) => Promise<GetInspectorDto>;
  update: (id: string, dto: CreateUpdateInspectorDto) => Promise<void>;
  create: (dto: CreateUpdateInspectorDto) => Promise<GetInspectorDto>;
  createVoid: (dto: CreateUpdateInspectorDto) => Promise<void>;
  validateCreate: (dto: CreateUpdateInspectorDto) => Promise<ValidationStatus>;
  delete: (id: string) => Promise<void>;
  getByInspection: (inspectionId: string) => Promise<GetInspectorDto[]>;
}

export const InspectorContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.inspectorControllerGet(id),
    update: (id: string, dto: CreateUpdateInspectorDto) =>
      api.inspectorControllerUpdate(id, dto),
    create: (dto: CreateUpdateInspectorDto) =>
      api.inspectorControllerCreate(dto),
    createVoid: async (dto: CreateUpdateInspectorDto) => {
      await api.inspectorControllerCreate(dto);
    },
    validateCreate: (dto: CreateUpdateInspectorDto) =>
      api.inspectorControllerValidateCreate(dto),
    delete: (id: string) => api.inspectorControllerDelete(id),
    getByInspection: (inspectionId: string) =>
      api.inspectorControllerGetByInspection(inspectionId),
  };
};

export const newInspector = () => {
  return CreateUpdateInspectorDtoFromJSON({});
};

export const creatorForInspection = (inspectionId: string) => () => {
  return CreateUpdateInspectorDtoFromJSON({ inspectionId });
};

export const creatorForSampleExam = (sampleExamId: string) => () => {
  return CreateUpdateInspectorDtoFromJSON({ sampleExamId });
};

export const toPresent = (inspector: GetInspectorDto) => {
  const presentation = [
    {
      name: 'Pracownik',
      value: inspector.employee.firstName + ' ' + inspector.employee.lastName,
    },
    {
      name: 'Data upoważ.',
      value: momencik(inspector.authorizationDate),
    },
  ];

  if (inspector.authorization) {
    presentation.push({
      name: 'Upoważnienie',
      value: inspector.authorization,
    });
  }

  return presentation;
};

export const validate = (dto: CreateUpdateInspectorDto) => {
  return validateAgainst(InspectorSchema, dto);
};

export const forAssociatedDocuments = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  inspectors: GetInspectorDto[],
  creator?: () => CreateUpdateInspectorDto,
  action?: () => void,
) => {
  const label = (inspector: GetInspectorDto) => {
    let loginInfo = '';

    loginInfo = snrwb.employees.getLabelForDuplicates(inspector.employee);

    const itemLabel =
      `${inspector.employee.firstName} ${inspector.employee.lastName}` +
      `${loginInfo}, ` +
      `upoważnienie z ${momencik(inspector.authorizationDate)}`;
    return itemLabel;
  };

  const validateMethod = async (createUpdateDto: CreateUpdateInspectorDto) => {
    const clientSide = await validate(createUpdateDto);
    if (!clientSide.valid) {
      return clientSide;
    }
    const serverSide = await snrwb.inspectors.validateCreate(createUpdateDto);
    return toResult(serverSide);
  };

  const addMethod = (createUpdateDto: CreateUpdateInspectorDto) =>
    notifications.onPromise(
      snrwb.inspectors.createVoid(createUpdateDto),
      action,
    );

  const deleteMethod = (id: string) =>
    notifications.onPromise(snrwb.inspectors.delete(id), action);

  const updateMethod = (
    id: string,
    createUpdateDto: CreateUpdateInspectorDto,
  ) =>
    notifications.onPromise(
      snrwb.inspectors.update(id, createUpdateDto),
      action,
    );

  return AssociateDocumentsWithFilter({
    documents: inspectors,
    new: creator || newInspector,
    labelGenerator: label,
    add: addMethod,
    validate: validateMethod,
    update: updateMethod,
    delete: deleteMethod,
  });
};
