import React from 'react';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  CreateUpdateOrganizationDto,
  GetTechAssessmentDto,
  GetAttachmentDto,
  CreateUpdateAttachmentDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  TechAssessmentDto,
  convert,
} from '../../../common/snrwbCore/contexts/TechAssessmentContext';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { Organization } from '../../../common/snrwbCore/contexts/OrganizationContext';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';

import { TechAssessmentAction } from './TechAssessmentAction';

interface CreateUpdateTechAssessmentProps {
  organization?: Organization;
  techAssessment?: GetTechAssessmentDto;
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  onAddNew?: (producerId: string) => void;
  onChange?: () => void;
  onClose?: () => void;
  onUpdateAttachments?: () => void;
}

export const CreateUpdateTechAssessment: React.FC<CreateUpdateTechAssessmentProps> =
  props => {
    const snrwb = useSnrwbCore();
    const notifications = useNotifications();

    const addTechAssessment = async (
      organization: Organization,
      techAssessment: TechAssessmentDto,
      id?: string,
    ) => {
      let createTechAssessment: () => Promise<GetTechAssessmentDto | void>;
      const cuTechAssessment =
        'id' in techAssessment ? convert(techAssessment) : techAssessment;
      if ('id' in organization) {
        cuTechAssessment.producerId = organization.id;
        createTechAssessment = async () =>
          id
            ? await snrwb.techAssessments.update(id, cuTechAssessment)
            : await snrwb.techAssessments.create(cuTechAssessment);
      } else {
        createTechAssessment = () =>
          snrwb.techAssessments.createWithOrganization(
            cuTechAssessment,
            organization as CreateUpdateOrganizationDto,
          );
      }

      try {
        const result = await createTechAssessment();
        if ((result as GetTechAssessmentDto)?.producer) {
          cuTechAssessment.producerId = (
            result as GetTechAssessmentDto
          ).producer.id;
        }
        if (id) {
          props.onChange && props.onChange();
        } else if (cuTechAssessment.producerId) {
          props.onAddNew && props.onAddNew(cuTechAssessment.producerId);
        }

        return { saved: true, errors: [] };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    };

    const deleteTechAssessment = () => {
      if (props.techAssessment) {
        notifications.onPromise(
          snrwb.techAssessments.delete(props.techAssessment.id),
          props.onChange,
        );
      }
    };

    return (
      <TechAssessmentAction
        organization={props.organization}
        techAssessment={props.techAssessment}
        attachments={props.attachments}
        onCompleted={addTechAssessment}
        onDelete={props.techAssessment && deleteTechAssessment}
        onClose={props.onClose}
        onUpdateAttachments={props.onUpdateAttachments}
      />
    );
  };
