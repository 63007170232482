import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { GetOrganizationalUnitDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetOrganizationalUnitDto';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import DatePicker from '../../../app/components/DatePicker';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';

export type FilterYearReportData = {
  newYear?: number;
  organizationalUnitId?: string;
};

interface FilterYearProps {
  initialValues?: FilterYearReportData;
  onSearch: (filter?: FilterYearReportData) => void;
  reportName: string;
}

export const FilterYear: React.FC<FilterYearProps> = props => {
  const snrwbApi = useSnrwbCore();

  const [organizationalUnits, setOrganizationalUnits] = useState<
    GetOrganizationalUnitDto[]
  >([]);

  const [showNewAlert, setShowNewAlert] = useState(false);
  const [formData, setFormData] = useState<FilterYearReportData>(
    props.initialValues || {
      newYear: new Date().getFullYear(),
    },
  );

  useEffect(() => {
    snrwbApi.organizationalUnits
      .getMany(OrganizationalUnitsGetOptions.notNotified)
      .then(orgUnit => {
        setOrganizationalUnits(orgUnit);
      });
  }, [snrwbApi]);

  const handleClear = () => {
    setFormData({
      newYear: new Date().getFullYear(),
      organizationalUnitId: '',
    });
    setShowNewAlert(false);
  };
  const handleSearch = () => {
    if (!formData.newYear) {
      setShowNewAlert(true);
      return;
    }
    setShowNewAlert(false);
    if (formData.organizationalUnitId === '') {
      formData.organizationalUnitId = undefined;
    }
    props.onSearch(formData);
  };

  const propertyChange = (obj: Partial<FilterYearReportData>) => {
    setFormData({ ...formData, ...obj });
  };

  return (
    <Card className="mb-2">
      <Card.Header>{props.reportName}</Card.Header>
      <Card.Body>
        <Form className="d-grid gap-3">
          <Row>
            <Form.Group as={Row} controlId="dateFrom">
              <Form.Label column sm="3">
                Rok
              </Form.Label>
              <Col sm="9">
                <DatePicker
                  value={
                    formData.newYear
                      ? new Date(formData.newYear, 0, 1, 0, 0, 0, 0)
                      : undefined
                  }
                  picker="year"
                  midnight={true}
                  onChange={date =>
                    propertyChange({
                      newYear: date?.getFullYear() || undefined,
                    })
                  }
                />
              </Col>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Row} controlId="organ">
              <Form.Label column sm="3">
                Organ
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  className="form-select"
                  as="select"
                  value={formData.organizationalUnitId}
                  onChange={e =>
                    propertyChange({ organizationalUnitId: e.target.value })
                  }
                >
                  <option value={''}>Wszystkie</option>
                  {organizationalUnits.map((o, idx) => (
                    <option value={o.id} key={idx}>
                      {o.shortname}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </Row>
        </Form>

        {showNewAlert && (
          <Alert variant="danger" className="mt-3">
            Proszę podać rok.
          </Alert>
        )}

        <div className="d-flex justify-content-end mt-3">
          <Button className="me-2" variant="outline-dark" onClick={handleClear}>
            Wyczyść
          </Button>
          <Button variant="outline-primary" onClick={handleSearch}>
            Szukaj
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
