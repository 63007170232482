/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoFromJSONTyped,
    GetOrganizationDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetInspectionMetricDto
 */
export interface GetInspectionMetricDto {
    /**
     * 
     * @type {string}
     * @memberof GetInspectionMetricDto
     */
    id: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetInspectionMetricDto
     */
    inspectionType: GetDictionaryDto;
    /**
     * 
     * @type {Date}
     * @memberof GetInspectionMetricDto
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionMetricDto
     */
    fileNumber: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetInspectionMetricDto
     */
    organizationType: GetDictionaryDto;
    /**
     * 
     * @type {GetOrganizationDto}
     * @memberof GetInspectionMetricDto
     */
    organization: GetOrganizationDto;
    /**
     * 
     * @type {string}
     * @memberof GetInspectionMetricDto
     */
    inspectionPlace: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetInspectionMetricDto
     */
    approved: boolean;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetInspectionMetricDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
}

export function GetInspectionMetricDtoFromJSON(json: any): GetInspectionMetricDto {
    return GetInspectionMetricDtoFromJSONTyped(json, false);
}

export function GetInspectionMetricDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInspectionMetricDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'inspectionType': GetDictionaryDtoFromJSON(json['inspectionType']),
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'fileNumber': json['fileNumber'],
        'organizationType': GetDictionaryDtoFromJSON(json['organizationType']),
        'organization': GetOrganizationDtoFromJSON(json['organization']),
        'inspectionPlace': json['inspectionPlace'],
        'approved': json['approved'],
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
    };
}

export function GetInspectionMetricDtoToJSON(value?: GetInspectionMetricDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'inspectionType': GetDictionaryDtoToJSON(value.inspectionType),
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'fileNumber': value.fileNumber,
        'organizationType': GetDictionaryDtoToJSON(value.organizationType),
        'organization': GetOrganizationDtoToJSON(value.organization),
        'inspectionPlace': value.inspectionPlace,
        'approved': value.approved,
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
    };
}


