import React from 'react';
import { Table } from 'react-bootstrap';

import { ReportPakAmountType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: ReportPakAmountType | undefined;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportPakAmount: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = data ? Object.keys(data.details).length > 0 : false;

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={17}>{reportName}</th>
            </tr>
            <tr>
              <th>Organ</th>
              <th>Liczba zakończonych postępowań w sprawie kar pieniężnych</th>
              <th>Łączna kwota kar nałożonych</th>
              <th>Liczba odwołań od decyzji w sprawie kary</th>
              <th>Uiszczone należności</th>
            </tr>
          </thead>
          {hasData && data && (
            <tbody>
              {Object.keys(data.details)
                .sort()
                .map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    <td className="numbers-column">
                      {data?.details?.[key]?.count || 0}
                    </td>
                    <td className="costs-column">
                      {data?.details?.[key]?.sumImposedCashPenalties || 0}
                    </td>
                    <td className="numbers-column">
                      {data?.details?.[key]?.countOfCancellations || 0}
                    </td>
                    <td className="costs-column">
                      {data?.details?.[key]?.sumPaid || 0}
                    </td>
                  </tr>
                ))}
              <tr>
                <td>Suma</td>
                <td className="numbers-column">{data?.sum?.count || 0}</td>
                <td className="costs-column">
                  {data?.sum?.sumImposedCashPenalties || 0}
                </td>
                <td className="numbers-column">
                  {data?.sum?.countOfCancellations || 0}
                </td>
                <td className="costs-column">{data?.sum?.sumPaid || 0}</td>
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};
