import React from 'react';

import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateProtocolDuringInspectionDto,
  CreateUpdateProtocolDuringInspectionDtoFromJSON,
  GetProtocolDuringInspectionDto,
  GetProtocolDuringInspectionDtoFromJSON,
} from '../autogenerated/snrwbApiClient/models';
import { validateAgainst } from '../validation/validateAgainst';
import {
  ProtocolDuringInspectionSchema,
  ProtocolDuringInspectionSigningSchema,
} from '../validation/schemas';
import { AssociateDocumentsWithFilter } from '../../../snrwb/components/AssociatedDocuments/AssociateDocumentsTypes';
import { SnrwbCoreContextType } from '..';
import { NotificationsProviderType } from '../../notifications';
import momencik from '../../momencik';
import { GetAttachmentDto } from '../autogenerated/snrwbApiClient';
import TextOrControl from '../../../app/components/TextOrControl';

import * as AttachmentContext from './AttachmentContext';

export type ProtocolWithAttachments = {
  id: string;
  protocol: GetProtocolDuringInspectionDto;
  attachments: AttachmentContext.MemoizedAttachment[];
};

export type CreateUpdateProtocolWithAttachments = {
  cuDto: CreateUpdateProtocolDuringInspectionDto;
  attachments: AttachmentContext.MemoizedAttachment[];
};

export interface ProtocolDuringInspectionContextInterface {
  getById: (id: string) => Promise<GetProtocolDuringInspectionDto>;
  update: (
    id: string,
    dto: CreateUpdateProtocolDuringInspectionDto,
  ) => Promise<void>;
  create: (
    dto: CreateUpdateProtocolDuringInspectionDto,
  ) => Promise<GetProtocolDuringInspectionDto>;
  createVoid: (dto: CreateUpdateProtocolDuringInspectionDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
  getByInspectedProduct: (
    inspectedProductId: string,
  ) => Promise<GetProtocolDuringInspectionDto[]>;
  getAllForCollection: (
    protocols: GetProtocolDuringInspectionDto[],
    protocolsAttachments: GetAttachmentDto[],
  ) => ProtocolWithAttachments[];
  approve: (id: string) => Promise<void>;
  revertApprove: (id: string) => Promise<void>;
}

export const ProtocolDuringInspectionContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.protocolDuringInspectionControllerGet(id),
    create: (dto: CreateUpdateProtocolDuringInspectionDto) =>
      api.protocolDuringInspectionControllerCreate(dto),
    createVoid: async (dto: CreateUpdateProtocolDuringInspectionDto) => {
      await api.protocolDuringInspectionControllerCreate(dto);
    },
    update: (id: string, dto: CreateUpdateProtocolDuringInspectionDto) =>
      api.protocolDuringInspectionControllerUpdate(id, dto),
    delete: (id: string) => api.protocolDuringInspectionControllerDelete(id),
    getByInspectedProduct: (inspectedProductId: string) =>
      api.protocolDuringInspectionControllerGetByInspectedProduct(
        inspectedProductId,
      ),
    getAllForCollection: (
      protocols: GetProtocolDuringInspectionDto[],
      protocolsAttachments: GetAttachmentDto[],
    ) => {
      const protocolsDuringInspections = [];
      for (const p of protocols) {
        const atts = protocolsAttachments.filter(att => att.objectId === p.id);
        protocolsDuringInspections.push({
          id: p.id,
          protocol: p,
          attachments: atts.map(att => {
            return { id: att.id, existing: att };
          }),
        });
      }
      return protocolsDuringInspections;
    },
    approve: (id: string) => api.protocolDuringInspectionControllerApprove(id),
    revertApprove: (id: string) =>
      api.protocolDuringInspectionControllerRevertApprove(id),
  };
};

export const newProtocol = () => {
  return CreateUpdateProtocolDuringInspectionDtoFromJSON({
    approved: false,
  });
};

export const getDto = (
  id: string,
  typeName: string,
  cuDto: CreateUpdateProtocolDuringInspectionDto,
) => {
  const getDto = GetProtocolDuringInspectionDtoFromJSON({
    ...cuDto,
    id,
    type: {
      id: cuDto.typeId,
      value: typeName,
    },
  });
  return {
    id,
    protocol: getDto,
    attachments: [],
  } as ProtocolWithAttachments;
};

export const labelForDescription = (typeName: string) => {
  return typeName === 'oględziny'
    ? 'Ustalenia oględzin'
    : 'Uwagi kontrolowanego';
};

export const toPresent = (protocol: GetProtocolDuringInspectionDto) => {
  const presentation: { name: string; value: React.ReactNode }[] = [
    {
      name: 'Data',
      value: momencik(protocol.date),
    },
  ];
  if (protocol.type.value === 'inwentaryzacja') {
    presentation.push({
      name: 'Ilość wyrobu',
      value: protocol.quantity,
    });
  }
  if (protocol.description) {
    presentation.push({
      name: labelForDescription(protocol.type.value),
      value: TextOrControl({ readonly: true, value: protocol.description }),
    });
  }
  if (protocol.witnesses) {
    presentation.push({
      name: 'W obecności',
      value: protocol.witnesses,
    });
  }
  if (protocol.repairMethod) {
    presentation.push({
      name: 'Omówienie poprawek',
      value: protocol.repairMethod,
    });
  }
  if (protocol.signatureDate) {
    presentation.push({
      name: 'Data podpisania',
      value: momencik(protocol.signatureDate),
    });
  }
  if (protocol.placeOfSignature) {
    presentation.push({
      name: 'Miejsce podpisania',
      value: protocol.placeOfSignature,
    });
  }
  if (protocol.refusalReason) {
    presentation.push({
      name: 'Powód odmowy podpisania',
      value: protocol.refusalReason,
    });
  }

  return presentation;
};

export const validate = (dto: CreateUpdateProtocolDuringInspectionDto) =>
  validateAgainst(ProtocolDuringInspectionSchema, dto);

export const validateSinging = (dto: CreateUpdateProtocolDuringInspectionDto) =>
  validateAgainst(ProtocolDuringInspectionSigningSchema, dto);

export const forAssociatedDocuments = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  protocols: ProtocolWithAttachments[],
  action?: () => void,
) => {
  const label = (dto: ProtocolWithAttachments) => {
    const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1);
    return `${capitalize(dto.protocol.type.value)} ${
      dto.protocol.number
    } z ${momencik(dto.protocol.date)}`;
  };

  const saveNewAttachments = async (
    proId: string,
    attachments: AttachmentContext.MemoizedAttachment[],
  ) => {
    for (const attachment of attachments) {
      if (!attachment.newOne) {
        continue;
      }
      const cuDto = attachment.newOne;
      cuDto.enObjectType =
        AttachmentContext.ObjectType.ProtocolDuringInspection;
      cuDto.objectId = proId;
      await snrwb.attachments.create(cuDto);
    }
  };

  const deleteAttachments = async (
    proId: string,
    attachments: AttachmentContext.MemoizedAttachment[],
  ) => {
    const nowInDb = await snrwb.attachments.getAllForObject(
      AttachmentContext.ObjectType.ProtocolDuringInspection,
      proId,
    );
    for (const attachment of nowInDb) {
      if (
        attachments.findIndex(att => att.existing?.id === attachment.id) === -1
      ) {
        await snrwb.attachments.delete(attachment.id);
      }
    }
  };

  const newMethod = () => {
    const maximum = (
      arr: Array<string | Date | undefined>,
      initial: string | Date,
    ) => {
      const value = arr.reduce(
        (prev, curr) => ((prev || initial) > (curr || initial) ? prev : curr),
        initial,
      );
      if (value === initial) {
        return undefined;
      }
      if (
        value instanceof Date &&
        initial instanceof Date &&
        value.getTime() === initial.getTime()
      ) {
        return undefined;
      }
      return value;
    };

    const dto = newProtocol();
    dto.date = maximum(
      protocols.map(p => new Date(p.protocol.date as unknown as string)),
      new Date(0),
    ) as Date;
    dto.witnesses = maximum(
      protocols.map(p => p.protocol.witnesses),
      '',
    ) as string;
    dto.signatureDate = maximum(
      protocols.map(
        p => new Date(p.protocol.signatureDate as unknown as string),
      ),
      new Date(0),
    ) as Date;
    dto.placeOfSignature = maximum(
      protocols.map(p => p.protocol.placeOfSignature),
      '',
    ) as string;

    return {
      cuDto: dto,
      attachments: [] as AttachmentContext.MemoizedAttachment[],
    };
  };

  const validateMethod = (pwa: CreateUpdateProtocolWithAttachments) =>
    validate(pwa.cuDto);

  const addMethod = (pwa: CreateUpdateProtocolWithAttachments) => {
    const save = async () => {
      if (pwa.cuDto.description) {
        pwa.cuDto.description = pwa.cuDto.description.replaceAll('>\n', '>');
      }

      const dto = await snrwb.protocolsDuringInspection.create(pwa.cuDto);
      await saveNewAttachments(dto.id, pwa.attachments);
    };
    notifications.onPromise(save(), action);
  };

  const updateMethod = (
    id: string,
    pwa: CreateUpdateProtocolWithAttachments,
  ) => {
    const update = async () => {
      if (pwa.cuDto.description) {
        pwa.cuDto.description = pwa.cuDto.description.replaceAll('>\n', '>');
      }

      await snrwb.protocolsDuringInspection.update(id, pwa.cuDto);
      await deleteAttachments(id, pwa.attachments);
      await saveNewAttachments(id, pwa.attachments);
    };
    notifications.onPromise(update(), action);
  };

  const deleteMethod = (id: string) =>
    notifications.onPromise(snrwb.protocolsDuringInspection.delete(id), action);

  const getMethod = (pwa: ProtocolWithAttachments) => {
    if (pwa.protocol.approved) {
      snrwb.printouts.protocolDuringInspection(pwa.protocol);
    } else {
      snrwb.printouts.protocolDuringInspectionOnTheFly(pwa.protocol);
    }
  };

  for (const protocol of protocols) {
    for (const attachment of protocol.attachments) {
      attachment.getInjected = snrwb.attachments.saveFileInBrowser;
    }
  }

  const approveMethod = (id: string) =>
    notifications.onPromise(
      snrwb.protocolsDuringInspection.approve(id),
      action,
    );

  const revertApproveMethod = (id: string) =>
    notifications.onPromise(
      snrwb.protocolsDuringInspection.revertApprove(id),
      action,
    );

  return AssociateDocumentsWithFilter({
    documents: protocols,
    labelGenerator: label,
    validate: validateMethod,
    new: newMethod,
    add: addMethod,
    update: updateMethod,
    delete: deleteMethod,
    get: getMethod,
    approve: approveMethod,
    revertApprove: revertApproveMethod,
  });
};
