import React from 'react';
import Badge from 'react-bootstrap/Badge';

import { GetInspectionPlanDtoStatusEnum } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

export const InspectionPlanStatusInfo: React.FC<{
  status: GetInspectionPlanDtoStatusEnum;
}> = props => {
  const variant =
    props.status === GetInspectionPlanDtoStatusEnum.ZatwierdzonyPrzezGINB
      ? 'success'
      : props.status === GetInspectionPlanDtoStatusEnum.ZatwierdzonyPrzezWINB
      ? 'warning'
      : 'danger';
  return (
    <Badge
      bg={variant}
      text={
        props.status === GetInspectionPlanDtoStatusEnum.ZatwierdzonyPrzezWINB
          ? 'dark'
          : 'light'
      }
    >
      {props.status}
    </Badge>
  );
};
