/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JudgmentDetails
 */
export interface JudgmentDetails {
    /**
     * 
     * @type {string}
     * @memberof JudgmentDetails
     */
    judgmentType: string;
    /**
     * 
     * @type {string}
     * @memberof JudgmentDetails
     */
    judgmentOrganizationalUnit: string;
    /**
     * 
     * @type {Date}
     * @memberof JudgmentDetails
     */
    judgmentPublishDate: Date;
    /**
     * 
     * @type {string}
     * @memberof JudgmentDetails
     */
    judgmentSign: string;
    /**
     * 
     * @type {boolean}
     * @memberof JudgmentDetails
     */
    judgmentAppeal: boolean;
    /**
     * 
     * @type {string}
     * @memberof JudgmentDetails
     */
    judgmentAppealResult: string;
    /**
     * 
     * @type {string}
     * @memberof JudgmentDetails
     */
    judgementPakId: string;
}

export function JudgmentDetailsFromJSON(json: any): JudgmentDetails {
    return JudgmentDetailsFromJSONTyped(json, false);
}

export function JudgmentDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JudgmentDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'judgmentType': json['judgmentType'],
        'judgmentOrganizationalUnit': json['judgmentOrganizationalUnit'],
        'judgmentPublishDate': (new Date(json['judgmentPublishDate'])),
        'judgmentSign': json['judgmentSign'],
        'judgmentAppeal': json['judgmentAppeal'],
        'judgmentAppealResult': json['judgmentAppealResult'],
        'judgementPakId': json['judgementPakId'],
    };
}

export function JudgmentDetailsToJSON(value?: JudgmentDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'judgmentType': value.judgmentType,
        'judgmentOrganizationalUnit': value.judgmentOrganizationalUnit,
        'judgmentPublishDate': (value.judgmentPublishDate.toISOString()),
        'judgmentSign': value.judgmentSign,
        'judgmentAppeal': value.judgmentAppeal,
        'judgmentAppealResult': value.judgmentAppealResult,
        'judgementPakId': value.judgementPakId,
    };
}


