import React, { useState } from 'react';
import TreeMenu from 'react-simple-tree-menu';
import { ListGroup } from 'react-bootstrap';
import * as rstm from 'react-simple-tree-menu';

import { TreeRowContent } from './TreeRowContent';

interface TreeProps {
  treeData: rstm.TreeNodeInArray[];
  variant: string;
  onUpdate?: () => void;
}

export const Tree: React.FC<TreeProps> = ({ treeData, variant, onUpdate }) => {
  const [selectedKey, setSelectedKey] = useState<string | undefined>();

  return (
    <div id="treeWrapper">
      {
        <TreeMenu data={treeData} activeKey={selectedKey}>
          {({ items }) => (
            <ListGroup>
              {items.map(item => (
                <ListGroup.Item
                  className="pt-0 pb-0"
                  as="a"
                  key={item.key}
                  action
                  active={item.active}
                  onClick={() => setSelectedKey(item.key)}
                >
                  <TreeRowContent
                    item={item}
                    variantTree={variant}
                    onUpdate={onUpdate}
                  />
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </TreeMenu>
      }
    </div>
  );
};
