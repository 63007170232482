import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as rstm from 'react-simple-tree-menu';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { CreateUpdateOrganizationalUnitDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import {
  newOrganizationalUnit,
  convert,
} from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';
import * as OrganizationalUnitContext from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';

import { OrganizationalUnitModalBody } from './OrganizationalUnitModalBody';

interface OrganizationalUnitModalProps {
  variant: 'delete' | 'edit' | 'add';
  item: rstm.TreeMenuItem;
  onAction: (newValue?: string) => void;
  show?: boolean;
  onClose?: () => void;
}

export const OrganizationalUnitModal: React.FC<OrganizationalUnitModalProps> =
  ({ variant, item, onAction, show, onClose }) => {
    const snrwbApi = useSnrwbCore();
    const notifications = useNotifications();

    const [organizationalUnit, setOrganizationalUnit] = useState(
      newOrganizationalUnit(),
    );
    const [showValidationErrors, setShowValidationErrors] = useState(false);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);

    useEffect(() => {
      if (variant !== 'add') {
        snrwbApi.organizationalUnits.getById(item.id).then(orgUnit => {
          setOrganizationalUnit(convert(orgUnit));
          return orgUnit;
        });
      } else {
        setOrganizationalUnit(newOrganizationalUnit(item.id));
      }
    }, [item.organizationalUnitTypeId, item.id, snrwbApi, variant]);

    const propertyChange = (
      obj: Partial<CreateUpdateOrganizationalUnitDto>,
    ) => {
      setOrganizationalUnit({ ...organizationalUnit, ...obj });
    };

    const handleSave = async () => {
      OrganizationalUnitContext.validate(organizationalUnit).then(
        async status => {
          setValidationErrors(status.errors);
          if (status.valid) {
            setShowValidationErrors(false);
            try {
              if (variant === 'edit') {
                notifications.onPromise(
                  snrwbApi.organizationalUnits.update(
                    item.id,
                    organizationalUnit,
                  ),
                  onAction,
                );
              }
              if (variant === 'add') {
                const newOrgUnit = await snrwbApi.organizationalUnits.create(
                  organizationalUnit,
                );
                notifications.saveCompleted();
                onAction(newOrgUnit.id);
              }
              if (variant === 'delete') {
                await snrwbApi.organizationalUnits.changeToInactive(item.id);
                notifications.saveCompleted();
                onAction();
              }
            } catch (response) {
              setValidationErrors(await responseErrors(response as Response));
              setShowValidationErrors(true);
            }
          } else {
            setShowValidationErrors(true);
          }
        },
      );
    };

    const title =
      variant === 'delete'
        ? 'Usuwanie organu'
        : variant === 'add'
        ? item.id
          ? 'Dodawanie organu'
          : 'Nowa jednostka'
        : 'Edycja organu';
    const actionButtonLabel =
      variant === 'delete' ? 'Usuń' : variant === 'add' ? 'Dodaj' : 'Zapisz';

    return (
      <Modal
        onHide={onClose}
        centered={true}
        show={show}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        {variant === 'delete' && (
          <Modal.Body>
            Czy na pewno usunąć {organizationalUnit.name}?
          </Modal.Body>
        )}
        {(variant === 'edit' || variant === 'add') && (
          <OrganizationalUnitModalBody
            organizationalUnit={organizationalUnit}
            propertyChange={propertyChange}
          />
        )}
        <ValidationAlert
          show={showValidationErrors}
          errors={validationErrors}
          className="mt-3"
        />
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={onClose}>
            Zamknij
          </Button>
          <Button
            variant="outline-primary"
            onClick={e => {
              e.stopPropagation();
              handleSave();
            }}
          >
            {actionButtonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
