import React from 'react';
import TextareaAutosize, * as reactTextareaAutosize from 'react-textarea-autosize';

export default (props: reactTextareaAutosize.TextareaAutosizeProps) => {
  return (
    <TextareaAutosize
      {...props}
      className={
        'form-control' + (props.className ? ' ' + props.className : '')
      }
    />
  );
};
