import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import * as Icon from 'react-bootstrap-icons';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { GetSampleExamDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { SampleExamApi } from '../../../common/snrwbCore/contexts/SampleExamContext';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useNavigation } from '../../../common/navigation';

export const SampleExamButtons = (props: {
  sampleExam: GetSampleExamDto;
  editAuthorized: boolean;
  api: SampleExamApi;
}) => {
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [alertHeading, setAlertHeading] = useState<string>();
  const [confirmation, setConfirmation] = useState<string>();
  const nav = useNavigation();

  const mayBeDeleted = async () => {
    if (!props.sampleExam.id) {
      return false;
    }

    const status = await props.api.mayDelete(props.sampleExam.id);
    if (status.state === 'ok') {
      setShowErrors(false);
      return true;
    }

    setErrors(status.message ? [status.message] : status.errors);
    setShowErrors(true);
    setAlertHeading('Nie można usunąć tego badania!');
    return false;
  };

  const mayBeFinished = async () => {
    if (!props.sampleExam.id) {
      return false;
    }

    if (props.sampleExam.completionDate) {
      return true;
    }

    const status = await props.api.mayFinish(props.sampleExam.id);
    if (status.state === 'ok') {
      setConfirmation(undefined);
      setShowErrors(false);
      return true;
    }
    if (status.state === 'confirm') {
      setConfirmation(
        status.message + ' Czy na pewno zamknąć obsługę tego badania?',
      );
      setShowErrors(false);
      return true;
    }

    setErrors(status.message ? [status.message] : status.errors);
    setShowErrors(true);
    setAlertHeading('Nie można zakończyć obsługi tego badania!');
    return false;
  };

  useEffect(() => {
    setShowErrors(false);
    setErrors([]);
    setAlertHeading(undefined);
    setConfirmation(undefined);
  }, [props.sampleExam]);

  return (
    <>
      <ValidationAlert
        show={showErrors}
        errors={errors}
        modal={true}
        onHide={() => setShowErrors(false)}
        heading={alertHeading}
      />
      <div className="d-flex justify-content-end m-3">
        {props.editAuthorized &&
          (props.sampleExam.approved ? (
            <ConfirmationButton
              onOK={props.api.finish}
              onValidation={mayBeFinished}
              variant="outline-success"
              confirmation={
                props.sampleExam.completionDate
                  ? 'Czy na pewno przywrócić badanie do obsługi?'
                  : confirmation || 'Czy na pewno zamknąć obsługę badania?'
              }
            >
              {props.sampleExam.completionDate
                ? 'Przywróć badanie do obsługi'
                : 'Zakończ obsługę'}
            </ConfirmationButton>
          ) : (
            <ConfirmationButton
              onOK={props.api.delete}
              onValidation={mayBeDeleted}
              variant="outline-danger"
              confirmation="Czy na pewno usunąć badanie?"
              roleAnyOf={[Role.ExamsEdit]}
            >
              Usuń
            </ConfirmationButton>
          ))}
        <Button
          className="ms-1"
          variant="outline-primary"
          onClick={() => nav.timelineSampleExam(props.sampleExam.id)}
        >
          Oś czasu
        </Button>
        <Button
          className="ms-1"
          variant="outline-success"
          onClick={() => nav.sampleCollect(props.sampleExam.sampleCollect.id)}
        >
          <Icon.CardChecklist /> Pobranie
        </Button>
        {props.sampleExam?.approved || !props.editAuthorized ? (
          <Button
            onClick={props.api.sampleExamProtocol}
            variant="outline-primary"
            className="ms-1"
          >
            Protokół pobrania
          </Button>
        ) : (
          <ConfirmationButton
            onOK={props.api.sampleExamProtocol}
            variant="outline-primary"
            confirmation="Protokół pobrania nie jest jeszcze podpisany, czy na pewno generować wydruk?"
            className="ms-1"
          >
            Protokół pobrania
          </ConfirmationButton>
        )}
      </div>
    </>
  );
};
