import React from 'react';
import Form from 'react-bootstrap/Form';
import * as FormControl from 'react-bootstrap/FormControl';

export default (
  props: FormControl.FormControlProps & { allowNegative?: boolean },
) => (
  <Form.Control
    {...props}
    type="number"
    step="0.01"
    onKeyPress={e => {
      if (!'01234567890,.'.includes(e.key)) {
        if (e.key !== '-' || !props.allowNegative) {
          e.preventDefault();
        }
      }
    }}
    onChange={e => {
      if (e.target.value.includes('.')) {
        const i = e.target.value.indexOf('.') + 1;
        if (e.target.value.length - i > 2) {
          return;
        }
      }
      if (props.onChange) {
        props.onChange(e);
      }
    }}
  />
);
