import React from 'react';

export interface NotificationsProviderType {
  onPromise: (
    promise: Promise<void>,
    action?: () => void,
    actionIfFailed?: () => void,
  ) => void;
  saveCompleted: () => void;
  unauthorized: () => void;
  badLink: (reason?: string, redirectLocation?: string) => void;
  conflict: (text: React.ReactNode) => void;
  online: () => void;
  offline: () => void;
}

const emptyHandler = () => {
  // implemented elsewhere
};

const emptyState = {
  onPromise: emptyHandler,
  saveCompleted: emptyHandler,
  unauthorized: emptyHandler,
  badLink: emptyHandler,
  conflict: emptyHandler,
  online: emptyHandler,
  offline: emptyHandler,
};

export const NotificationsContext =
  React.createContext<NotificationsProviderType>(emptyState);

export const NotificationsProvider: React.FC = ({ children }) => {
  return (
    <NotificationsContext.Provider value={emptyState}>
      {children}
    </NotificationsContext.Provider>
  );
};
