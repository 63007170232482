/*eslint complexity: ["error", 12]*/
import React from 'react';
import { Table } from 'react-bootstrap';

import { ReportInspectedProductsBySignType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: ReportInspectedProductsBySignType;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportInspectedProductsBySign: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = Object.keys(data.details).length > 0;

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={21}>{reportName}</th>
            </tr>
            <tr>
              <th rowSpan={3}>Organ</th>
              <th colSpan={6}>Wyrób podlegający przepisom CPR</th>
              <th colSpan={8}>
                Wyrób wprowadzony do obrotu w systemie krajowym
              </th>
              <th colSpan={2} rowSpan={2}>
                Wyrób udostępniony na zasadzie wzajemnego uznawania
              </th>
            </tr>
            <tr>
              <th colSpan={2}>podlegający oznakowaniu CE</th>
              <th colSpan={2}>
                niepodlegający obowiązkowi sporządzenia DWU (art.5 CPR)
              </th>
              <th colSpan={2}>wszystkie podlegające przepisom CPR</th>
              <th colSpan={2}>podlegający oznakowaniu znakiem budowlanym</th>
              <th colSpan={2}>regionalny wyrób budowlany</th>
              <th colSpan={2}>
                jednostkowego zastosowania (art. 10 ustawy owb)
              </th>
              <th colSpan={2}>wszystkie w systemie krajowym</th>
            </tr>
            <tr>
              <th rowSpan={1}>ogółem</th>
              <th rowSpan={1}>niezgodne</th>
              <th rowSpan={1}>ogółem</th>
              <th rowSpan={1}>niezgodne</th>
              <th rowSpan={1}>ogółem</th>
              <th rowSpan={1}>niezgodne</th>
              <th rowSpan={1}>ogółem</th>
              <th rowSpan={1}>niezgodne</th>
              <th rowSpan={1}>ogółem</th>
              <th rowSpan={1}>niezgodne</th>
              <th rowSpan={1}>ogółem</th>
              <th rowSpan={1}>niezgodne</th>
              <th rowSpan={1}>ogółem</th>
              <th rowSpan={1}>niezgodne</th>
              <th rowSpan={1}>ogółem</th>
              <th rowSpan={1}>niezgodne</th>
            </tr>
          </thead>
          {hasData && (
            <tbody>
              {Object.keys(data.details)
                .sort()
                .map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    <GetCountType data={data} orU={key} shortname={'pCE'} />
                    <GetCountType data={data} orU={key} shortname={'bDWU'} />
                    <td className="numbers-column">
                      {(data.details[key]['pCE']?.['O'] || 0) +
                        (data.details[key]['bDWU']?.['O'] || 0)}
                    </td>
                    <td className="numbers-column">
                      {(data.details[key]['pCE']?.['N'] || 0) +
                        (data.details[key]['bDWU']?.['N'] || 0)}
                    </td>
                    <GetCountType data={data} orU={key} shortname={'pB'} />
                    <GetCountType data={data} orU={key} shortname={'R'} />
                    <GetCountType data={data} orU={key} shortname={'JZ'} />
                    <td className="numbers-column">
                      {(data.details[key]['pB']?.['O'] || 0) +
                        (data.details[key]['R']?.['O'] || 0) +
                        (data.details[key]['JZ']?.['O'] || 0)}
                    </td>
                    <td className="numbers-column">
                      {(data.details[key]['pB']?.['N'] || 0) +
                        (data.details[key]['R']?.['N'] || 0) +
                        (data.details[key]['JZ']?.['N'] || 0)}
                    </td>
                    <GetCountType data={data} orU={key} shortname={'WU'} />
                  </tr>
                ))}
              <tr>
                <td>Suma:</td>
                <SumN data={data} rn={'pCE'} />
                <SumN data={data} rn={'bDWU'} />
                <td className="numbers-column">
                  {(data.sum['pCE']?.['O'] || 0) +
                    (data.sum['bDWU']?.['O'] || 0)}
                </td>
                <td className="numbers-column">
                  {(data.sum['pCE']?.['N'] || 0) +
                    (data.sum['bDWU']?.['N'] || 0)}
                </td>
                <SumN data={data} rn={'pB'} />
                <SumN data={data} rn={'R'} />
                <SumN data={data} rn={'JZ'} />
                <td className="numbers-column">
                  {(data.sum['pB']?.['O'] || 0) +
                    (data.sum['R']?.['O'] || 0) +
                    (data.sum['JZ']?.['O'] || 0)}
                </td>
                <td className="numbers-column">
                  {(data.sum['pB']?.['N'] || 0) +
                    (data.sum['R']?.['N'] || 0) +
                    (data.sum['JZ']?.['N'] || 0)}
                </td>
                <SumN data={data} rn={'WU'} />
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

const SumN: React.FC<{
  data: ReportInspectedProductsBySignType;
  rn: string;
}> = ({ data, rn }) => {
  return (
    <>
      <td className="numbers-column">{data.sum[rn]?.['O'] || 0}</td>
      <td className="numbers-column">{data.sum[rn]?.['N'] || 0}</td>
    </>
  );
};

type GetCountProps = {
  data: ReportInspectedProductsBySignType;
  orU: string;
  shortname: string;
};
const GetCountType: React.FC<GetCountProps> = ({ data, orU, shortname }) => {
  const countN = data.details[orU][shortname]?.['N'] || 0;
  const countO = data.details[orU][shortname]?.['O'] || 0;
  return (
    <>
      <td className="numbers-column">{countO}</td>
      <td className="numbers-column">{countN}</td>
    </>
  );
};
