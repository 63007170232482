import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import * as rstm from 'react-simple-tree-menu';

import FormRow from '../../../app/components/FormRow';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  CreateUpdateDictionaryDto,
  GetDictionaryTypeDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import {
  newDictionary,
  convert,
} from '../../../common/snrwbCore/contexts/DictionaryContext';
import * as DictionaryContext from '../../../common/snrwbCore/contexts/DictionaryContext';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';

interface DictionaryModalProps {
  variant: 'edit' | 'add';
  item: rstm.TreeMenuItem;
  onAction: (id?: string) => void;
  show?: boolean;
  onClose?: () => void;
}

export const DictionaryModal: React.FC<DictionaryModalProps> = ({
  variant,
  item,
  onAction,
  show,
  onClose,
}) => {
  const snrwbApi = useSnrwbCore();
  const notifications = useNotifications();

  const [dictionary, setDictionary] = useState(newDictionary());
  const [dictionaryType, setDictionaryType] = useState<GetDictionaryTypeDto>();

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    if (variant !== 'add') {
      snrwbApi.dictionaries
        .getById(item.id)
        .then(dict => {
          setDictionary(convert(dict));
          return dict;
        })
        .then(dict => {
          if (dict?.dictionaryTypeId) {
            snrwbApi.dictionariesTypes
              .getById(dict?.dictionaryTypeId)
              .then(dicT => {
                setDictionaryType(dicT);
              });
          }
        });
    } else {
      setDictionary(newDictionary(item.id, item.dictionaryTypeId));
      snrwbApi.dictionariesTypes.getById(item.dictionaryTypeId).then(dicT => {
        setDictionaryType(dicT);
      });
    }
  }, [dictionary.parentId, item.dictionaryTypeId, item.id, snrwbApi, variant]);

  const propertyChange = (obj: Partial<CreateUpdateDictionaryDto>) => {
    setDictionary({ ...dictionary, ...obj });
  };

  const handleSave = async () => {
    DictionaryContext.validate(dictionary).then(async status => {
      setValidationErrors(status.errors);
      if (status.valid) {
        setShowValidationErrors(false);
        try {
          if (variant === 'edit') {
            notifications.onPromise(
              snrwbApi.dictionaries.update(item.id, dictionary),
              onAction,
            );
          } else {
            const newDictionary = await snrwbApi.dictionaries.create(
              dictionary,
            );
            notifications.saveCompleted();
            onAction(newDictionary.id);
          }
        } catch (response) {
          setValidationErrors(await responseErrors(response as Response));
          setShowValidationErrors(true);
        }
      } else {
        setShowValidationErrors(true);
      }
    });
  };

  const title =
    variant === 'add'
      ? 'Dodawanie wartości słownika ' + dictionaryType?.name
      : 'Edycja słownika ' + dictionaryType?.name;
  const actionButtonLabel = variant === 'add' ? 'Dodaj' : 'Zapisz';

  return (
    <Modal
      onHide={onClose}
      centered={true}
      show={show}
      size="lg"
      keyboard={true}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-2">
          <Card.Header>Wartość słownikowa</Card.Header>
          <Card.Body>
            <Form className="d-grid gap-3">
              <FormRow controlId="inspectionPlace" label="Wartość">
                <Form.Control
                  type="text"
                  value={dictionary?.value || ''}
                  onChange={e =>
                    propertyChange({
                      value: e.target.value === '' ? null : e.target.value,
                    })
                  }
                />
              </FormRow>
              <FormRow controlId="inspectionPlace" label="Symbol">
                <Form.Control
                  type="text"
                  value={dictionary?.shortname || ''}
                  onChange={e =>
                    propertyChange({
                      shortname: e.target.value === '' ? null : e.target.value,
                    })
                  }
                />
              </FormRow>
            </Form>
          </Card.Body>
        </Card>
      </Modal.Body>
      <ValidationAlert
        show={showValidationErrors}
        errors={validationErrors}
        className="mt-3"
      />
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Zamknij
        </Button>
        <Button
          variant="primary"
          onClick={e => {
            e.stopPropagation();
            handleSave();
          }}
        >
          {actionButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
