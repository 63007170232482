/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCustomsFeedbacksDetails
 */
export interface ReportCustomsFeedbacksDetails {
    /**
     * 
     * @type {number}
     * @memberof ReportCustomsFeedbacksDetails
     */
    applicationCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCustomsFeedbacksDetails
     */
    applicationConstrProductCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCustomsFeedbacksDetails
     */
    releasedCount: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCustomsFeedbacksDetails
     */
    releasedNegativeCount: number;
}

export function ReportCustomsFeedbacksDetailsFromJSON(json: any): ReportCustomsFeedbacksDetails {
    return ReportCustomsFeedbacksDetailsFromJSONTyped(json, false);
}

export function ReportCustomsFeedbacksDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCustomsFeedbacksDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'applicationCount': json['applicationCount'],
        'applicationConstrProductCount': json['applicationConstrProductCount'],
        'releasedCount': json['releasedCount'],
        'releasedNegativeCount': json['releasedNegativeCount'],
    };
}

export function ReportCustomsFeedbacksDetailsToJSON(value?: ReportCustomsFeedbacksDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'applicationCount': value.applicationCount,
        'applicationConstrProductCount': value.applicationConstrProductCount,
        'releasedCount': value.releasedCount,
        'releasedNegativeCount': value.releasedNegativeCount,
    };
}


