import React from 'react';
import { Card, Tab, Row } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';
import { TabsHistory } from '../../../app/components/TabsHistory';

import { ReportsInspectionPlanView } from './ReportsInspectionPlanView';
import { ReportsOthersView } from './ReportsOthersView';
import { ReportsPakView } from './ReportsPakView';
import { ReportsProceedingView } from './ReportsProceedingView';
import { ReportsSampleView } from './ReportsSampleView';

export const ReportsView: React.FC = () => {
  const nav = useNavigation();

  return (
    <Card>
      <TabsHistory activeKey="byInspections" id="reports" className="mb-3">
        <Tab eventKey="byInspections" title="Kontrole">
          <Row xs={1} md={3} className="g-4">
            <ReportCardMenu
              title="Przeprowadzone kontrole"
              text="(1) Liczba przeprowadzonych kontroli wg organu"
              footer="Kontrole"
              onClick={nav.report1}
            />
            <ReportCardMenu
              title="Kontrole u podmiotów"
              text="(5) Kontrole u podmiotów"
              footer="Kontrole"
              onClick={nav.report5}
            />
            <ReportCardMenu
              title="Przeprowadzone kontrole CPR"
              text="(3) Liczba przeprowadzonych kontroli, które obejmowały wyroby
              305/2011"
              footer="Kontrole"
              onClick={nav.report3}
              cpr={true}
            />
            <ReportCardMenu
              title="Kontrole CPR u podmiotów"
              text="(8) Kontrole CPR u podmiotów (producentów, importerów,
                sprzedawców i innych podmiotów)"
              footer="Kontrole"
              onClick={nav.report8}
              cpr={true}
            />
          </Row>
        </Tab>
        <Tab eventKey="byInspectedProduct" title="Wyroby">
          <Row xs={1} md={3} className="g-4">
            <ReportCardMenu
              title="Skontrolowane wyroby"
              text="(2) Liczba skontrolowanych wyrobów wg organu"
              footer="Kontrolowane wyroby"
              onClick={nav.report2}
            />
            <ReportCardMenu
              title="Wyroby skontrolowane u podmiotów"
              text="(6) Wyroby skontrolowane u podmiotów"
              footer="Kontrolowane wyroby"
              onClick={nav.report6}
            />
            <ReportCardMenu
              title="Skontrolowane wyroby wg oznakowania"
              text="(11) Skontrolowane wyroby wg oznakowania"
              footer="Kontrolowane wyroby"
              onClick={nav.report11}
            />
            <ReportCardMenu
              title="Skontrolowane wyroby CPR"
              text="(4) Liczba skontrolowanych wyrobów 305/2011"
              footer="Kontrolowane wyroby"
              onClick={nav.report4}
              cpr={true}
            />
            <ReportCardMenu
              title="Skontrolowane wyroby CPR u podmiotów"
              text="(9) Skontrolowane wyroby CPR u podmiotów (producentów,
                importerów, sprzedawców i innych podmiotów)"
              footer="Kontrolowane wyroby"
              onClick={nav.report9}
              cpr={true}
            />
          </Row>
        </Tab>
        <Tab eventKey="byFinding" title="Ustalenia kontroli">
          <Row xs={1} md={3} className="g-4">
            <ReportCardMenu
              title="Zakres przeprowadzonych kontroli"
              text="(12) Zakres przeprowadzonych kontroli"
              footer="Kontrolowane wyroby"
              onClick={nav.report12}
            />
            <ReportCardMenu
              title="Skontrolowane niezgodne wyroby u podmiotów"
              text="(7) Skontrolowane niezgodne wyroby u podmiotów (producentów,
                importerów, sprzedawców i innych podmiotów)"
              footer="Kontrolowane wyroby"
              onClick={nav.report7}
            />
            <ReportCardMenu
              title="Skontrolowane niezgodne wyroby CPR u podmiotów"
              text="(10) Skontrolowane niezgodne wyroby CPR u podmiotów
              (producentów, importerów, sprzedawców i innych podmiotów"
              footer="Kontrolowane wyroby"
              onClick={nav.report10}
              cpr={true}
            />
            <ReportCardMenu
              title="Stwierdzone nieprawidłowości - grupa I"
              text="(13 I) Rodzaje stwierdzonych nieprawidłowości - grupa I"
              footer="Kontrolowane wyroby"
              onClick={nav.report13I}
            />
            <ReportCardMenu
              title="Stwierdzone nieprawidłowości - grupa II"
              text="(13 II) Rodzaje stwierdzonych nieprawidłowości - grupa II"
              footer="Kontrolowane wyroby"
              onClick={nav.report13II}
            />
            <ReportCardMenu
              title="Stwierdzone nieprawidłowości - grupa III"
              text="(13 III) Rodzaje stwierdzonych nieprawidłowości - grupa III"
              footer="Kontrolowane wyroby"
              onClick={nav.report13III}
            />
            <ReportCardMenu
              title="Nieprawidłowości w grupach wyrobów"
              text="(20) Nieprawidłowości w grupach wyrobów"
              footer="Kontrolowane wyroby, Badania próbek"
              onClick={nav.report20}
            />
          </Row>
        </Tab>
        <Tab eventKey="bySamples" title="Próbki">
          <Row xs={1} md={3} className="g-4">
            <ReportsSampleView />
          </Row>
        </Tab>
        <Tab eventKey="byProceeding" title="Postępowania">
          <Row xs={1} md={3} className="g-4">
            <ReportsProceedingView />
          </Row>
        </Tab>
        <Tab eventKey="byPAK" title="Kary pieniężne">
          <Row xs={1} md={3} className="g-4">
            <ReportsPakView />
          </Row>
        </Tab>
        <Tab eventKey="byInspectionPlan" title="Plan kontroli">
          <Row xs={1} md={3} className="g-4">
            <ReportsInspectionPlanView />
          </Row>
        </Tab>
        <Tab eventKey="others" title="Inne">
          <Row xs={1} md={3} className="g-4">
            <ReportsOthersView />
          </Row>
        </Tab>
      </TabsHistory>
    </Card>
  );
};
