/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoFromJSONTyped,
    GetOrganizationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetProductTypeDto
 */
export interface GetProductTypeDto {
    /**
     * 
     * @type {string}
     * @memberof GetProductTypeDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof GetProductTypeDto
     */
    sysnumber: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductTypeDto
     */
    nkitw: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductTypeDto
     */
    cpr: boolean;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetProductTypeDto
     */
    sign: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetProductTypeDto
     */
    signId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductTypeDto
     */
    constructionProduct: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductTypeDto
     */
    approved: boolean;
    /**
     * 
     * @type {GetOrganizationDto}
     * @memberof GetProductTypeDto
     */
    organization: GetOrganizationDto;
    /**
     * 
     * @type {string}
     * @memberof GetProductTypeDto
     */
    description: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetProductTypeDto
     */
    group: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetProductTypeDto
     */
    groupId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetProductTypeDto
     */
    displayDesc: boolean;
}

export function GetProductTypeDtoFromJSON(json: any): GetProductTypeDto {
    return GetProductTypeDtoFromJSONTyped(json, false);
}

export function GetProductTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sysnumber': json['sysnumber'],
        'nkitw': json['nkitw'],
        'cpr': json['cpr'],
        'sign': GetDictionaryDtoFromJSON(json['sign']),
        'signId': json['signId'],
        'constructionProduct': json['constructionProduct'],
        'approved': json['approved'],
        'organization': GetOrganizationDtoFromJSON(json['organization']),
        'description': json['description'],
        'group': GetDictionaryDtoFromJSON(json['group']),
        'groupId': json['groupId'],
        'displayDesc': json['displayDesc'],
    };
}

export function GetProductTypeDtoToJSON(value?: GetProductTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sysnumber': value.sysnumber,
        'nkitw': value.nkitw,
        'cpr': value.cpr,
        'sign': GetDictionaryDtoToJSON(value.sign),
        'signId': value.signId,
        'constructionProduct': value.constructionProduct,
        'approved': value.approved,
        'organization': GetOrganizationDtoToJSON(value.organization),
        'description': value.description,
        'group': GetDictionaryDtoToJSON(value.group),
        'groupId': value.groupId,
        'displayDesc': value.displayDesc,
    };
}


