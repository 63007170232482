import React from 'react';
import Keycloak from 'keycloak-js';
import { Mutex } from 'async-mutex';

import { Role } from '../common/snrwbCore/authorization/snrwbAuthorizationRoles';

import { onLogout as removeCookiesOnLogout } from './cookies';

interface User {
  name: string;
  email: string;
  sub: string;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthProviderState {
  currentUser: User | undefined;
  roles: string[];
  mutex: Mutex | undefined;
  accountUrl: string | undefined;
  check: (role: Role) => boolean;
  ensureTokenIsValid: () => Promise<void>;
  logout: () => void;
}

const emptyState = {
  currentUser: undefined,
  roles: [],
  mutex: undefined,
  accountUrl: undefined,
  check: () => false,
  ensureTokenIsValid: async () => {
    false;
  },
  logout: () => false,
};

export const AuthContext = React.createContext<AuthProviderState>(emptyState);

export class AuthProvider extends React.Component<
  AuthProviderProps,
  AuthProviderState
> {
  constructor(props: AuthProviderProps) {
    super(props);
    this.state = emptyState;
  }

  async tokenRefresh(kc: Keycloak.KeycloakInstance, seconds: number) {
    await this.state.mutex?.runExclusive(async () => {
      const refreshed = await kc.updateToken(seconds).catch(() => {
        return false;
      });

      if (refreshed && kc.token) {
        localStorage.setItem('mzt-token', kc.token);
        localStorage.setItem('mzt-refresh-token', kc.refreshToken || '');
      }
    });
  }

  componentDidMount() {
    const token = localStorage.getItem('mzt-token') || undefined;
    const refreshToken = localStorage.getItem('mzt-refresh-token') || undefined;
    const kc = Keycloak('/keycloak.json');

    kc.init({ onLoad: 'login-required', token, refreshToken }).then(
      async authenticated => {
        if (!authenticated) {
          return;
        }

        const state: AuthProviderState = emptyState;
        await this.tokenRefresh(kc, 5);
        const user = await kc.loadUserInfo().catch(() => kc.logout());

        state.currentUser = user as User;

        if (kc.token) {
          localStorage.setItem('mzt-token', kc.token);
          localStorage.setItem('mzt-refresh-token', kc.refreshToken || '');
        }
        if (kc.tokenParsed?.realm_access) {
          state.roles = kc.tokenParsed?.realm_access?.roles || [];
        }
        state.check = (role: Role) =>
          state.roles.includes(role.replace('realm:', ''));
        state.ensureTokenIsValid = async () => {
          await this.tokenRefresh(kc, 5);
        };
        state.accountUrl = kc.createAccountUrl();
        state.logout = () => {
          removeCookiesOnLogout();
          localStorage.clear();
          sessionStorage.clear();
          kc.logout();
        };
        state.mutex = new Mutex();

        this.setState(state);
      },
    );
    kc.onTokenExpired = () => {
      this.tokenRefresh(kc, 30);
    };
    kc.onAuthRefreshError = () => {
      setTimeout(() => this.tokenRefresh(kc, 5), 10000);
    };
  }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
