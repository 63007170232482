import { ContentBlock, ContentState, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';

const exportOptions = (contentState: ContentState) => ({
  inlineStyles: {
    SUPERSCRIPT: { element: 'sup' },
    SUBSCRIPT: { element: 'sub' },
  },
  blockRenderers: {
    atomic: (block: ContentBlock) => {
      const entityKey = block.getEntityAt(0);
      if (!entityKey) {
        return '';
      }
      const entity = contentState.getEntity(entityKey);
      if (!entity || entity.getType() !== 'IMAGE') {
        return '';
      }
      const data = entity.getData();
      let width = `${data.width}`;
      if (width && !width.endsWith('%')) {
        width += '%';
      }
      return `<figure><img src="${data.src}" width="${width}" /></figure>`;
    },
  },
});

const importOptions = {
  elementStyles: {
    sup: 'SUPERSCRIPT',
    sub: 'SUBSCRIPT',
  },
};

export const normalizedHtml = (html: string) => {
  if (!html) {
    return { text: html, content: stateFromHTML('') };
  }

  // width for images need to be transformed for our resize plugin to work
  const regex = /(width="\d+%")/;
  let text = html;
  for (const match of regex.exec(text) || []) {
    text = text.replaceAll(match, match.replace('%', ''));
  }

  // weird HTML-injection problem
  text = text.replaceAll('&lt;', '<').replaceAll('&gt;', '>');

  const content = stateFromHTML(text, importOptions);
  return { text: stateToHTML(content, exportOptions(content)), content };
};

export const callOnChange = (
  editorState: EditorState,
  saveHtml: string,
  onChange: (value: string) => void,
) => {
  const untagIfEmpty = (html: string) => {
    if (!html || html === '<p><br></p>') {
      return '';
    }
    return html;
  };

  const content = editorState.getCurrentContent();
  const html = stateToHTML(content, exportOptions(content));
  const norm = normalizedHtml(saveHtml);
  if (untagIfEmpty(html) !== untagIfEmpty(norm.text)) {
    onChange(html);
  }
  return html;
};
