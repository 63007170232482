import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import * as PDIContext from '../../../common/snrwbCore/contexts/ProtocolDuringInspectionContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import DictionarySelector from '../Selects/DictionarySelector';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

export const ProtocolDuringInspectionHeader = (props: {
  document: PDIContext.ProtocolWithAttachments;
  readonly: boolean;
  onChangeType: (typeId: string, typeName: string) => void;
  onGet: () => void;
  onApprove: () => void;
  onRevertApprove: (id: string) => void;
}) => {
  const newProtocol = !props.document;
  const approvedProtocol = props.document?.protocol?.approved;
  const [type, setType] = useState<string>();
  const header = newProtocol
    ? 'Protokół'
    : `Protokół '${props.document.protocol.type.value}' nr ${
        props.document.protocol.number
      } ${approvedProtocol ? 'podpisany' : 'niepodpisany'}`;

  return (
    <Alert variant={approvedProtocol ? 'success' : 'warning'}>
      <Alert.Heading className="mb-0">
        <Row>
          <Col className="align-self-center">{header}</Col>
          {newProtocol && (
            <>
              <Col md={9}>
                <DictionarySelector
                  readOnly={props.readonly}
                  value={type}
                  dictionary="protokoły w trakcie kontroli - typ"
                  onChange={option => {
                    setType(option.value);
                    props.onChangeType(option.value, option.label);
                  }}
                />
              </Col>
            </>
          )}
        </Row>
        <hr />
        <div className="d-flex justify-content-end">
          {!props.readonly && approvedProtocol && (
            <ConfirmationButton
              variant="outline-success"
              roleAnyOf={[Role.InspectionsEdit]}
              onOK={() => props.onRevertApprove(props.document.protocol.id)}
              className="mx-1"
              confirmation="Czy na pewno cofnąć podpisanie protokołu?"
            >
              Cofnij
            </ConfirmationButton>
          )}
          <Button
            variant="outline-primary"
            onClick={() => props.onGet()}
            className="float-end"
          >
            Pobierz
          </Button>
          {!props.readonly && !newProtocol && !approvedProtocol && (
            <ConfirmationButton
              variant="outline-success"
              onOK={props.onApprove}
              className="mx-1"
              confirmation="Czy na pewno protokół został podpisany?"
            >
              Podpisz
            </ConfirmationButton>
          )}
        </div>
      </Alert.Heading>
    </Alert>
  );
};
