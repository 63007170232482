/*eslint complexity: ["error", 20]*/
/*eslint max-lines-per-function: ["error", 300]*/
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateEssentialFeatureDto,
  GetEssentialFeatureDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import BooleanDropdown from '../../../app/components/BooleanDropdown';
import TextOrControl from '../../../app/components/TextOrControl';
import RichTextSingleLine from '../../../app/components/RichTextSingleLine';

import { EssentialFeatureOverwriteModal } from './EssentialFeatureOverwiteModal';

const OverwriteButton = (props: {
  tooltipText: string;
  variant: 'feature' | 'functionalProperty' | 'examMethod';
  disabled: boolean;
  openOverwriteModal: () => void;
}) => {
  return (
    <OverlayTrigger overlay={<Tooltip>{props.tooltipText}</Tooltip>}>
      <Button
        variant="outline-primary"
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          props.openOverwriteModal();
        }}
        disabled={props.disabled}
      >
        <Icon.Diagram2 />
      </Button>
    </OverlayTrigger>
  );
};

// eslint-disable-next-line complexity
export const EssentialFeature = ({
  value,
  feature,
  examApproved,
  sampleIsPublished,
  resultSampleExists,
  controlSampleExists,
  controlSampleIsPublished,
  resultControlSampleExists,
  editAuthorized,
  publishAuthorized,
  finishAndResultExists,
  propertyChange,
  onUpdateAfterOverwrite,
}: {
  value?: GetEssentialFeatureDto;
  feature: CreateUpdateEssentialFeatureDto;
  examApproved: boolean;
  sampleIsPublished: boolean;
  resultSampleExists: boolean;
  controlSampleExists: boolean;
  controlSampleIsPublished: boolean;
  resultControlSampleExists: boolean;
  editAuthorized: boolean;
  publishAuthorized: boolean;
  finishAndResultExists: boolean;
  propertyChange: (obj: Partial<CreateUpdateEssentialFeatureDto>) => void;
  onUpdateAfterOverwrite: (
    obj: Partial<CreateUpdateEssentialFeatureDto>,
  ) => void;
}) => {
  const snrwb = useSnrwbCore();
  useEffect(() => {
    console.log(value);
  }, [value]);

  const anythingPublished =
    sampleIsPublished || (controlSampleExists && controlSampleIsPublished);

  const readonlyAuthorized = !(
    (!examApproved && editAuthorized) ||
    (!anythingPublished && publishAuthorized && finishAndResultExists)
  );
  const publishReadonly =
    anythingPublished ||
    (publishAuthorized &&
      !editAuthorized &&
      !resultSampleExists &&
      !resultControlSampleExists);
  const samplePublishReadonly =
    sampleIsPublished ||
    (publishAuthorized && !editAuthorized && !resultSampleExists);
  const controlSamplePublishReadonly =
    !controlSampleExists ||
    controlSampleIsPublished ||
    (publishAuthorized && !editAuthorized && !resultControlSampleExists);

  const [overwriteModal, setOverwriteModal] = useState(false);
  type overwriteModalType = 'feature' | 'functionalProperty' | 'examMethod';
  const [overwriteModalVariant, setOverwriteModalVariant] =
    useState<overwriteModalType>('feature');
  const [currValue, setCurrValue] = useState<string>('');
  const openOverwriteModal = (variant: overwriteModalType) => {
    setOverwriteModal(true);
    setOverwriteModalVariant(variant);
    setCurrValue(feature[variant]);
  };

  const updateAfterOverwrite = (
    property: 'feature' | 'functionalProperty' | 'examMethod',
    newValue: string,
  ) => {
    onUpdateAfterOverwrite({ [property]: newValue });
  };

  return (
    <>
      <Form className="d-grid gap-3">
        <Row>
          <Col md={value ? 9 : 12}>
            <FormRow
              controlId="type"
              label="Zasadnicza charakterystyka"
              labelSize={value ? 4 : 3}
              valueSize={value ? 8 : 9}
            >
              <TextOrControl
                readonly={readonlyAuthorized}
                value={feature.feature}
              >
                <CreatableClearableSelector
                  value={feature.feature}
                  onChange={option => propertyChange({ feature: option.label })}
                  provider={snrwb.essentialFeatures.getFeaturesByPattern}
                />
              </TextOrControl>
            </FormRow>
          </Col>
          {value && (
            <Col
              md={3}
              className="d-flex align-items-center justify-content-end"
            >
              <OverwriteButton
                tooltipText="Zastępowanie wybranej zasadniczej charakterystyki"
                variant="feature"
                disabled={!feature.feature}
                openOverwriteModal={() => openOverwriteModal('feature')}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={value ? 9 : 12}>
            <FormRow
              controlId="type"
              label="Właściwość użytkowa"
              labelSize={value ? 4 : 3}
              valueSize={value ? 8 : 9}
            >
              <TextOrControl
                readonly={readonlyAuthorized}
                value={feature.functionalProperty}
              >
                <CreatableClearableSelector
                  value={feature.functionalProperty}
                  readOnly={readonlyAuthorized}
                  onChange={option =>
                    propertyChange({ functionalProperty: option.label })
                  }
                  provider={
                    snrwb.essentialFeatures.getFunctionalPropertiesByPattern
                  }
                />
              </TextOrControl>
            </FormRow>
          </Col>
          {value && (
            <Col
              md={3}
              className="d-flex align-items-center justify-content-end"
            >
              <OverwriteButton
                tooltipText="Zastępowanie wybranej właściwości użytkowej"
                variant="functionalProperty"
                disabled={!feature.functionalProperty}
                openOverwriteModal={() =>
                  openOverwriteModal('functionalProperty')
                }
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col md={value ? 9 : 12}>
            <FormRow
              controlId="type"
              label="Metoda badania"
              labelSize={value ? 4 : 3}
              valueSize={value ? 8 : 9}
            >
              <TextOrControl
                readonly={readonlyAuthorized}
                value={feature.examMethod}
              >
                <CreatableClearableSelector
                  value={feature.examMethod}
                  onChange={option =>
                    propertyChange({ examMethod: option.label })
                  }
                  provider={snrwb.essentialFeatures.getExamMethodsByPattern}
                />
              </TextOrControl>
            </FormRow>
          </Col>
          {value && (
            <Col
              md={3}
              className="d-flex align-items-center justify-content-end"
            >
              <OverwriteButton
                tooltipText="Zastępowanie wybranej metody badania"
                variant="examMethod"
                disabled={!feature.examMethod}
                openOverwriteModal={() => openOverwriteModal('examMethod')}
              />
            </Col>
          )}
        </Row>
        <FormRow controlId="expectedResult" label="Deklarowana wartość">
          <TextOrControl
            readonly={readonlyAuthorized}
            value={feature.expectedResult}
          >
            <RichTextSingleLine
              value={feature.expectedResult}
              onChange={value => propertyChange({ expectedResult: value })}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="publish" label="Publikować wynik">
          <BooleanDropdown
            canBeUndefined
            readOnly={publishReadonly}
            value={feature.publish}
            onChange={value => propertyChange({ publish: value })}
          />
        </FormRow>
        <FormRow controlId="result" label="Wynik próbki">
          <TextOrControl
            readonly={samplePublishReadonly}
            value={feature.result}
          >
            <RichTextSingleLine
              value={feature.result || ''}
              onChange={value => propertyChange({ result: value })}
            />
          </TextOrControl>
        </FormRow>
        <FormRow
          controlId="resultUncertainty"
          label="Niepewność wyniku badania próbki"
        >
          <TextOrControl
            readonly={samplePublishReadonly}
            value={feature.result}
          >
            <RichTextSingleLine
              value={feature.resultUncertainty || ''}
              onChange={value => propertyChange({ resultUncertainty: value })}
            />
          </TextOrControl>
        </FormRow>
        <FormRow controlId="fullfilled" label="Próbka spełnia charakterystykę">
          <BooleanDropdown
            canBeUndefined
            readOnly={samplePublishReadonly}
            value={feature.fullfilled}
            onChange={value => propertyChange({ fullfilled: value })}
          />
        </FormRow>
        <FormRow controlId="controlResult" label="Wynik próbki kontrolnej">
          <TextOrControl
            readonly={controlSamplePublishReadonly}
            value={feature.controlResult}
          >
            <RichTextSingleLine
              value={feature.controlResult || ''}
              onChange={value => propertyChange({ controlResult: value })}
            />
          </TextOrControl>
        </FormRow>
        <FormRow
          controlId="controlResultUncertainty"
          label="Niepewność wyniku badania próbki kontrolnej"
        >
          <TextOrControl
            readonly={controlSamplePublishReadonly}
            value={feature.controlResult}
          >
            <RichTextSingleLine
              value={feature.controlResultUncertainty || ''}
              onChange={value =>
                propertyChange({ controlResultUncertainty: value })
              }
            />
          </TextOrControl>
        </FormRow>
        <FormRow
          controlId="controlFullfilled"
          label="Próbka kontrolna spełnia charakterystykę"
        >
          <BooleanDropdown
            canBeUndefined
            readOnly={controlSamplePublishReadonly}
            value={feature.controlFullfilled}
            onChange={value => propertyChange({ controlFullfilled: value })}
          />
        </FormRow>
      </Form>
      <EssentialFeatureOverwriteModal
        currValue={currValue}
        variant={overwriteModalVariant}
        show={overwriteModal}
        onHide={() => setOverwriteModal(false)}
        onOverwrite={updateAfterOverwrite}
      />
    </>
  );
};
