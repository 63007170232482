import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { TimelineProcessType } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { Timeline } from '../../components/ProductTypes/Timeline';
import { GetKwzDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

interface ParamTypes {
  id: string;
}

export const KwzTimelineView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const notifications = useNotifications();
  const { id } = useParams<ParamTypes>();

  const mountedRef = useRef(false);
  const [kwz, setKwz] = useState<GetKwzDto>();
  const [processes, setProcesses] = useState<TimelineProcessType[]>([]);
  const [header, setHeader] = useState<string>(
    'Oś czasu zakwestionowanego wyrobu',
  );

  const safe = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    snrwb.kwzs
      .getByIdWithParties(id)
      .then(kwz => {
        safe(() => setKwz(kwz));
      })
      .catch(() => notifications.badLink());

    snrwb.reports
      .getKwzHistoryTimeline(id)
      .then(events => {
        safe(() => setProcesses(events));
      })
      .catch(() => notifications.badLink());
  }, [snrwb, notifications, id]);

  useEffect(() => {
    if (kwz) {
      const productTypeName = kwz.judgment?.proceeding?.inspectedProduct
        ?.productType.displayDesc
        ? kwz.judgment?.proceeding?.inspectedProduct?.productType.nkitw &&
          kwz.judgment?.proceeding?.inspectedProduct?.productType.description
          ? `${kwz.judgment?.proceeding?.inspectedProduct?.productType.nkitw} (${kwz.judgment?.proceeding?.inspectedProduct?.productType.description})`
          : kwz.judgment?.proceeding?.inspectedProduct?.productType.nkitw
          ? kwz.judgment?.proceeding?.inspectedProduct?.productType.nkitw
          : kwz.judgment?.proceeding?.inspectedProduct?.productType.description
        : kwz.judgment?.proceeding?.inspectedProduct?.productType.nkitw;
      let headerString = `Oś czasu zakwestionowanego wyrobu: ${productTypeName}`;
      if (kwz.publicationNumber) {
        headerString += ` (nr karty ${kwz.publicationNumber})`;
      }
      setHeader(headerString);
    }
  }, [kwz]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [id, snrwb.inspections, refresh]);

  return (
    <Card>
      <Card.Header>{header}</Card.Header>
      <Card.Body>
        <Card>
          <Timeline processes={processes} hideEvents />
        </Card>
      </Card.Body>
    </Card>
  );
};
