import React from 'react';
import * as Icon from 'react-bootstrap-icons';

interface DeleteInspectionPlanGroupLinkProps {
  inspectionPlanGroupId: string;
  action: (id: string) => Promise<void>;
}

export const InspectionPlanGroupLink: React.FC<DeleteInspectionPlanGroupLinkProps> =
  props => {
    return (
      <span
        className="link-primary"
        role="button"
        tabIndex={0}
        onClick={() => props.action(props.inspectionPlanGroupId)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            props.action(props.inspectionPlanGroupId);
          }
        }}
      >
        <Icon.BoxArrowRight />
      </span>
    );
  };
