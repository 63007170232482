import React from 'react';

import { GetOrganizationDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetOrganizationDto';
import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridColumn,
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import { useNavigation } from '../../../common/navigation';

import { MergingOrganization } from './MergingOrganization';

interface OrganizationsSearchProps {
  getData: GridGetterFunction;
  getCount: GridCountFunction;
  withMerging?: boolean;
  organizationHeadId?: string;
}

export const organizationForGrid = (
  organization: GetOrganizationDto & { headId?: string },
) => {
  return {
    key: organization.id,
    values: {
      'organization.name': organization.name,
      'organization.nip': organization.nip,
      'organization.country': organization.country,
      'organization.place': organization.place,
      'organization.street': organization.street,
      'organization.numbers': organization.numbers,
      'organization.flat': organization.flat,
      'organization.addressEx': organization.addressEx,
      merging: (
        <MergingOrganization
          organizationId={organization.id}
          organizationHeadId={organization.headId}
        />
      ),
    },
  };
};

export const OrganizationsSearch: React.FC<OrganizationsSearchProps> =
  props => {
    const nav = useNavigation();
    const columns: GridColumn[] = [
      { header: 'Nazwa', property: 'organization.name' },
      { header: 'NIP', property: 'organization.nip' },
      { header: 'Państwo', property: 'organization.country' },
      { header: 'Miejscowość', property: 'organization.place' },
      { header: 'Ulica', property: 'organization.street' },
      { header: 'Numer', property: 'organization.numbers' },
      { header: 'Mieszkanie', property: 'organization.flat' },
      { header: 'Adres / Uwagi', property: 'organization.addressEx' },
    ];
    if (props.withMerging) {
      columns.push({
        header: '',
        property: 'merging',
        className: 'text-center',
        noSorting: true,
      });
    }

    return (
      <Grid
        getData={props.getData}
        getDataCount={props.getCount}
        columns={columns}
        showFilter={true}
        initialOrderColumn="organization.name"
        availablePageSizes={[10, 20, 50]}
        onRowClick={nav.organization}
        emptyGridMessage="Brak podmiotów w systemie"
        additionalId={props.organizationHeadId}
      />
    );
  };
