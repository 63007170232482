import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { ReportCustomsFeedback } from '../../../components/ReportsCustomsFeedback/ReportCustomsFeedback';
import { ReportCustomsFeedbacksType } from '../../../../common/snrwbCore/autogenerated/snrwbApiClient';

export const ReportCustomsFeedbackView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportCustomsFeedbacksType>();

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportCustomsFeedback(false, filter).then(dataReports => {
      setData(dataReports);
    });
  };

  return (
    <>
      <Filter onSearch={onSearch} reportName="Współpraca z organami celnymi" />
      <ReportCustomsFeedback
        data={data}
        reportName="Współpraca z organami celnymi"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
