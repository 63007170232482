import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';

import {
  Organization,
  newOrganization,
} from '../../../common/snrwbCore/contexts/OrganizationContext';
import { OrganizationSelector } from '../Organizations/OrganizationSelector';

interface OrgFormProps {
  showErrors: boolean;
  organization?: Organization;
  onChange: (organization: Organization) => void;
  onValidation: (valid: boolean) => void;
  onClear: () => void;
}

export const OrgFormCard: React.FC<OrgFormProps> = props => {
  const [organization, setOrganization] = useState<Organization>(
    props.organization ? props.organization : newOrganization(),
  );

  useEffect(() => {
    setOrganization(
      props.organization ? props.organization : newOrganization(),
    );
  }, [props.organization]);

  return (
    <Card>
      <Card.Header>Podmiot</Card.Header>
      <Card.Body>
        <OrganizationSelector
          onSelect={props.onChange}
          onValidation={props.onValidation}
          onClear={props.onClear}
          value={organization}
          readOnly={false}
          showValidationErrors={props.showErrors}
        />
      </Card.Body>
    </Card>
  );
};
