/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateBpNotificationMemoDto
 */
export interface CreateBpNotificationMemoDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBpNotificationMemoDto
     */
    subjectId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBpNotificationMemoDto
     */
    sampleId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBpNotificationMemoDto
     */
    enSubjectType: CreateBpNotificationMemoDtoEnSubjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateBpNotificationMemoDto
     */
    content: string;
}

export function CreateBpNotificationMemoDtoFromJSON(json: any): CreateBpNotificationMemoDto {
    return CreateBpNotificationMemoDtoFromJSONTyped(json, false);
}

export function CreateBpNotificationMemoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBpNotificationMemoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjectId': json['subjectId'],
        'sampleId': json['sampleId'],
        'enSubjectType': json['enSubjectType'],
        'content': json['content'],
    };
}

export function CreateBpNotificationMemoDtoToJSON(value?: CreateBpNotificationMemoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subjectId': value.subjectId,
        'sampleId': value.sampleId,
        'enSubjectType': value.enSubjectType,
        'content': value.content,
    };
}

/**
* @export
* @enum {string}
*/
export enum CreateBpNotificationMemoDtoEnSubjectTypeEnum {
    Kontrola = 'kontrola',
    PobranieProbek = 'pobranie_probek',
    BadanieProbek = 'badanie_probek',
    Postepowanie = 'postepowanie',
    Pak = 'pak',
    Kwz = 'kwz'
}


