/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import Alert from 'react-bootstrap/Alert';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { GetInspectionPlanDtoStatusEnum } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

export const InspectionPlanStatusAlert: React.FC<{
  statusAfterReject?: GetInspectionPlanDtoStatusEnum;
  statusAfterAccept?: GetInspectionPlanDtoStatusEnum;
  title: string;
  variant: string;
  showButtonReject: boolean;
  showButtonAccept: boolean;
  handleInspectionPlanStatus: (status: GetInspectionPlanDtoStatusEnum) => void;
}> = props => {
  return (
    <Alert variant={props.variant}>
      <Alert.Heading>{props.title}</Alert.Heading>
      <hr />
      <div className="d-flex justify-content-end">
        {props.statusAfterReject && props.showButtonReject && (
          <ConfirmationButton
            variant="outline-success"
            roleAnyOf={[Role.InspectionsPlansRevoke]}
            onOK={() =>
              props.handleInspectionPlanStatus(props.statusAfterReject!)
            }
            confirmation="Czy na pewno cofnąć zatwierdzenie planu?"
          >
            Cofnij
          </ConfirmationButton>
        )}
        {props.statusAfterAccept && props.showButtonAccept && (
          <ConfirmationButton
            variant="outline-primary"
            roleAnyOf={[Role.InspectionsPlansEdit]}
            onOK={() =>
              props.handleInspectionPlanStatus(props.statusAfterAccept!)
            }
            confirmation="Czy na pewno zatwierdzić plan?"
          >
            Zatwierdź
          </ConfirmationButton>
        )}
      </div>
    </Alert>
  );
};
