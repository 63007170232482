/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateCorrectiveActionDto
 */
export interface CreateUpdateCorrectiveActionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCorrectiveActionDto
     */
    typeId: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateCorrectiveActionDto
     */
    date?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateCorrectiveActionDto
     */
    effective: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCorrectiveActionDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateCorrectiveActionDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCorrectiveActionDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateCorrectiveActionDto
     */
    proceedingId: string;
}

export function CreateUpdateCorrectiveActionDtoFromJSON(json: any): CreateUpdateCorrectiveActionDto {
    return CreateUpdateCorrectiveActionDtoFromJSONTyped(json, false);
}

export function CreateUpdateCorrectiveActionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateCorrectiveActionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeId': json['typeId'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'effective': json['effective'],
        'description': json['description'],
        'approved': json['approved'],
        'inspectedProductId': json['inspectedProductId'],
        'proceedingId': json['proceedingId'],
    };
}

export function CreateUpdateCorrectiveActionDtoToJSON(value?: CreateUpdateCorrectiveActionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'typeId': value.typeId,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'effective': value.effective,
        'description': value.description,
        'approved': value.approved,
        'inspectedProductId': value.inspectedProductId,
        'proceedingId': value.proceedingId,
    };
}


