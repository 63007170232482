import React from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icons from 'react-bootstrap-icons';

import { useCookies } from '../../../common/hooks/useCookies';

export const UserMemosHeader = (props: {
  onboarding: boolean;
  showNew: boolean;
  onNewMemo: () => void;
  onCloseMemo: () => void;
}) => {
  const cookies = useCookies();

  return (
    <Modal.Header closeButton>
      {props.onboarding ? (
        <Modal.Title>
          W systemie nie ma jeszcze wiadomości, które możemy tutaj pokazać
        </Modal.Title>
      ) : props.showNew ? (
        <Button variant="outline-secondary" onClick={props.onCloseMemo}>
          <Icons.ChevronLeft />
        </Button>
      ) : (
        <div className="flex-fill d-flex justify-content-between">
          <Button variant="outline-primary" onClick={props.onNewMemo}>
            Nowa
          </Button>
          <span className="mh-100 p-1">
            <OverlayTrigger
              overlay={
                <Tooltip>
                  {cookies.soundEnabled ? 'Wyłącz' : 'Włącz'} dźwięk
                  przychodzącej wiadomości
                </Tooltip>
              }
            >
              {cookies.soundEnabled ? (
                <Icons.VolumeUpFill
                  size={20}
                  onClick={() => cookies.setSoundEnabled(false)}
                />
              ) : (
                <Icons.VolumeMuteFill
                  size={20}
                  onClick={() => cookies.setSoundEnabled(true)}
                />
              )}
            </OverlayTrigger>
          </span>
        </div>
      )}
    </Modal.Header>
  );
};
