import React from 'react';
import Form from 'react-bootstrap/Form';

interface GridPaginationProps {
  availableValues: number[];
  currentSize: number;
  setValue: (size: number) => void;
}

export const GridSizing: React.FC<GridPaginationProps> = props => {
  return (
    <Form.Group className="grid-page-size mx-1">
      <Form.Control
        as={Form.Select}
        value={props.currentSize}
        onChange={e => {
          props.setValue(+e.target.value);
        }}
      >
        {props.availableValues.map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};
