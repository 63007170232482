import React, { useState } from 'react';
import { Button, Form, Card } from 'react-bootstrap';
import * as Icons from 'react-bootstrap-icons';

import DynamicTextarea from '../../../app/components/DynamicTextarea';
import FormRow from '../../../app/components/FormRow';
import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreatePrivateMemoDto,
  SendPrivateMemoToManyDto,
  CreatePrivateMemoDtoFromJSON,
  GetOrganizationalUnitDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { Option } from '../Selects/Selector';
import EmployeeSelector from '../Selects/EmployeeSelector';
import * as MemoContext from '../../../common/snrwbCore/contexts/MemoContext';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';

export const NewPrivateMemo = (props: {
  onSend: (memo: CreatePrivateMemoDto) => void;
  onSendToMany: (memos: SendPrivateMemoToManyDto) => void;
}) => {
  const snrwb = useSnrwbCore();
  const [memo, setMemo] = useState<CreatePrivateMemoDto>(
    CreatePrivateMemoDtoFromJSON({}),
  );
  const [content, setContent] = useState('');
  const [organizationalUnit, setOrganizationalUnit] =
    useState<GetOrganizationalUnitDto>();
  const [sendToAll, setSendToAll] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const handleEmployeeSelection = (option: Option) => {
    setMemo({ ...memo, recipientId: option.value });
    setOrganizationalUnit(option.organizationalUnit);
  };

  const validateAndSend = () => {
    MemoContext.validatePrivateMemo(memo).then(status => {
      if (status.valid) {
        if (sendToAll && organizationalUnit) {
          snrwb.employees
            .getAllByOrganizationalUnit(organizationalUnit.id)
            .then(employees => {
              const recipientIds = [
                memo.recipientId,
                ...employees
                  .map(employee => employee.id)
                  .filter(id => id !== memo.recipientId),
              ];
              props.onSendToMany({
                content,
                recipientIds,
              });
            });
        } else {
          props.onSend(memo);
        }
        return;
      }

      setShowErrors(true);
      setErrors(status.errors);
    });
  };

  return (
    <Card className="flex-fill">
      <Card.Header>Utwórz nową wiadomość do wskazanego użytkownika</Card.Header>
      <Card.Body>
        <div className="d-flex flex-column flex-fill">
          <Form className="d-grid gap-3 mb-3">
            <FormRow controlId="employee" label="Do">
              <EmployeeSelector
                unrestricted
                onChange={handleEmployeeSelection}
              />
            </FormRow>
            <FormRow controlId="memo" label="Treść">
              <DynamicTextarea
                placeholder="Podaj treść"
                className="max-rows-5"
                minRows={3}
                onChange={e => setContent(e.target.value)}
                onBlur={() => setMemo({ ...memo, content })}
              />
            </FormRow>
          </Form>

          <ValidationAlert show={showErrors} errors={errors} />

          <div className="mt-3 d-flex justify-content-between align-items-center">
            {organizationalUnit && (
              <div>
                <Form.Check id="send-to-all" type="checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    checked={sendToAll}
                    onChange={e => setSendToAll(e.target.checked)}
                  />
                  <Form.Check.Label>
                    <span className="me-1">
                      Wyślij wiadomość do wszystkich użytkowników powiązanych z
                      organem:
                    </span>
                    <OrganizationalUnitBadge
                      organizationalUnit={organizationalUnit}
                    />
                  </Form.Check.Label>
                </Form.Check>
              </div>
            )}
            <div className="ms-auto">
              <Button variant="outline-primary" onClick={validateAndSend}>
                <Icons.SendFill />
              </Button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};
