import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as PDIContext from '../../../common/snrwbCore/contexts/ProtocolDuringInspectionContext';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import { CreateUpdateProtocolDuringInspectionDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ItemPresentation from '../../../app/components/ItemPresentation';
import FormRow from '../../../app/components/FormRow';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AddAttachmentMemoized } from '../Attachments/AddAttachment';
import { AttachmentMemoized } from '../Attachments/Attachment';

export const ProtocolDuringInspectionApproved = (props: {
  document: PDIContext.ProtocolWithAttachments;
  pdi: CreateUpdateProtocolDuringInspectionDto;
}) => {
  const presentation = PDIContext.toPresent(props.document.protocol);

  let i = 0;
  const newLines = (value: string) => {
    if (!value) {
      return value;
    }
    return value
      .toString()
      .split('\n')
      .map(s => <div key={++i}>{s || '\u00A0'}</div>);
  };

  return (
    <>
      <ItemPresentation item={presentation} />
      <Form className="d-grid gap-3">
        <FormRow controlId="attachments" label="Załączniki">
          <Row>
            <Col md={10}>{newLines(props.pdi.attachments)}</Col>
            <Col md={2}>
              <AssociatedDocuments
                name="Pliki"
                readonly={true}
                align="end"
                createModalType={AddAttachmentMemoized}
                presentModalType={AttachmentMemoized}
                docs={AttachmentContext.forModal(
                  props.document.attachments,
                  AttachmentContext.newAttachment,
                )}
              />
            </Col>
          </Row>
        </FormRow>
      </Form>
    </>
  );
};
