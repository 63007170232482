import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import {
  GetInspectedProductDto,
  GetPartyToProceedingDto,
  GetProceedingDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { useNavigation } from '../../../common/navigation';
import { ProceedingViewApi } from '../../../common/snrwbCore/contexts/ProceedingContext';
import { AddInspection } from '../Inspections/AddInspection';

export const ProceedingInspections = (props: {
  value: GetProceedingDto;
  editAuthorized: boolean;
  inspectedProduct: GetInspectedProductDto;
  checkingProduct?: GetInspectedProductDto;
  parties: GetPartyToProceedingDto[];
  api: ProceedingViewApi;
}) => {
  const nav = useNavigation();
  const organization =
    props.parties.length === 1 ? props.parties[0].organization : undefined;

  return (
    <Alert variant="primary">
      <Alert.Heading>
        <div className="d-flex justify-content-between">
          <div>
            Postępowanie jest wynikiem kontroli nr{' '}
            {props.inspectedProduct.inspection.metric.fileNumber}
          </div>
          <div>
            <Button
              variant="outline-primary"
              onClick={() =>
                nav.inspection(props.inspectedProduct.inspection.id)
              }
            >
              Pokaż
            </Button>
          </div>
        </div>
      </Alert.Heading>
      <hr />
      {props.checkingProduct ? (
        <Button
          variant="outline-secondary"
          onClick={() =>
            props.checkingProduct
              ? nav.inspection(props.checkingProduct.inspection.id)
              : undefined
          }
        >
          Kontrola sprawdzająca nr{' '}
          {props.checkingProduct.inspection.metric.fileNumber}
        </Button>
      ) : (
        props.editAuthorized && (
          <AddInspection
            buttonText="Utwórz kontrolę sprawdzającą"
            organization={organization}
            inspectionType="R"
            hideClearOrganizationButton={organization !== undefined}
            onCompleted={(organization, metric) =>
              props.api.addInspection(
                organization,
                metric,
                undefined,
                props.value.id,
              )
            }
          />
        )
      )}
    </Alert>
  );
};
