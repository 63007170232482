/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectionPlanProgressDataForChartType
 */
export interface InspectionPlanProgressDataForChartType {
    /**
     * 
     * @type {Array<string>}
     * @memberof InspectionPlanProgressDataForChartType
     */
    labels: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InspectionPlanProgressDataForChartType
     */
    planned: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InspectionPlanProgressDataForChartType
     */
    completed: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InspectionPlanProgressDataForChartType
     */
    percentages: Array<string>;
}

export function InspectionPlanProgressDataForChartTypeFromJSON(json: any): InspectionPlanProgressDataForChartType {
    return InspectionPlanProgressDataForChartTypeFromJSONTyped(json, false);
}

export function InspectionPlanProgressDataForChartTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectionPlanProgressDataForChartType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'labels': json['labels'],
        'planned': json['planned'],
        'completed': json['completed'],
        'percentages': json['percentages'],
    };
}

export function InspectionPlanProgressDataForChartTypeToJSON(value?: InspectionPlanProgressDataForChartType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'labels': value.labels,
        'planned': value.planned,
        'completed': value.completed,
        'percentages': value.percentages,
    };
}


