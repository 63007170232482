import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';

export const MergingOrganization: React.FC<{
  organizationId: string;
  organizationHeadId?: string;
}> = props => {
  const nav = useNavigation();

  const mergeOrganization = async () => {
    nav.merginOrganizations(
      props.organizationId,
      undefined,
      props.organizationHeadId,
    );
  };

  return (
    <>
      {props.organizationId !== props.organizationHeadId && (
        <OverlayTrigger
          overlay={<Tooltip>{'Zastępowanie wybranej organizacji'}</Tooltip>}
        >
          <Button
            variant="link-primary"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              mergeOrganization();
            }}
          >
            <Icon.Diagram2 />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};
