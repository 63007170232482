import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';

import ItemPresentation from '../../../../app/components/ItemPresentation';
import { CreateUpdateCustomsFeedbackDto } from '../../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  CustomsFeedbackDto,
  convert,
  newCustomsFeedback,
  forPresentation,
} from '../../../../common/snrwbCore/contexts/CustomsFeedbackContext';

import { CustomsFeedback } from './CustomsFeedback';

interface CustFeedFormCardProps {
  showErrors: boolean;
  editAuthorized: boolean;
  customsFeedback?: CustomsFeedbackDto;
  producer?: string;
  errors: string[];
  onChange: (
    customsFeedback: CustomsFeedbackDto | CreateUpdateCustomsFeedbackDto,
  ) => void;
}

export const CustFeedFormCard: React.FC<CustFeedFormCardProps> = props => {
  const [customsFeedback, setCustomsFeedback] = useState(
    props.customsFeedback ? props.customsFeedback : newCustomsFeedback(),
  );

  const onChangeCustomsFeedback = (customsFeedback: CustomsFeedbackDto) => {
    setCustomsFeedback(customsFeedback);
    props.onChange(customsFeedback);
  };

  useEffect(() => {
    setCustomsFeedback(
      props.customsFeedback ? props.customsFeedback : newCustomsFeedback(),
    );
  }, [props.customsFeedback]);

  if (
    !props.editAuthorized &&
    props.customsFeedback &&
    'id' in props.customsFeedback
  ) {
    return (
      <>
        <ItemPresentation item={forPresentation(props.customsFeedback)} />
        <Alert variant="warning" className="mt-3">
          Edycja jest zablokowana z powodu braku uprawnień.
        </Alert>
      </>
    );
  }

  return (
    <CustomsFeedback
      onChange={onChangeCustomsFeedback}
      showValidationErrors={props.showErrors}
      validationErrors={props.errors}
      producer={props.producer}
      value={
        'id' in customsFeedback ? convert(customsFeedback) : customsFeedback
      }
    />
  );
};
