import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';
import { Alert } from 'react-bootstrap';

import {
  GetSampleCollectDto,
  CreateUpdateSampleCollectDto,
  CreateUpdateOrganizationDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow from '../../../app/components/FormRow';
import DictionarySelector from '../Selects/DictionarySelector';
import { OrganizationModalSelector } from '../Organizations/OrganizationModalSelector';
import * as SampleCollectContext from '../../../common/snrwbCore/contexts/SampleCollectContext';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import FormSkeleton from '../../../app/components/FormSkeleton';
import OrganizationalUnitSelector from '../Selects/OrganizationalUnitSelector';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';
import DatePicker from '../../../app/components/DatePicker';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';

import SampleCollectPlaces from './SampleCollectPlaces';

export const SampleCollectDetails: React.FC<{
  editAuthorized: boolean;
  approvedExams: boolean;
  value: GetSampleCollectDto;
  organizationLabel?: string;
  onChange: (sampleCollect: CreateUpdateSampleCollectDto) => void;
  onNewOrganization: (organization: CreateUpdateOrganizationDto) => void;
}> = props => {
  const [sampleCollect, setSampleCollect] = useState(
    SampleCollectContext.toCuDto(props.value),
  );
  const [presentedSampleCollect, setPresentedSampleCollect] =
    useState(sampleCollect);

  const [showActionDate, setShowActionDate] = useState(true);
  const snrwb = useSnrwbCore();

  useEffect(() => {
    snrwb.sampleCollects.setDuringInspectionDto().then(() => {
      const cuDto = SampleCollectContext.toCuDto(props.value);
      setSampleCollect(cuDto);
      setShowActionDate(cuDto.collectBasisShort !== '25.1');
    });
  }, [props.value, snrwb.sampleCollects]);

  useEffect(() => {
    setPresentedSampleCollect(sampleCollect);
  }, [sampleCollect]);

  if (!props.value.id) {
    return <FormSkeleton />;
  } else if (
    !props.editAuthorized ||
    props.value.approved ||
    props.approvedExams
  ) {
    const presentation = SampleCollectContext.detailsPresent(props.value);
    return (
      <>
        {props.editAuthorized && props.approvedExams && (
          <Alert variant="warning" className="mb-0">
            Edycja zablokowana, ponieważ co najmniej jedno badanie jest już
            podpisane.
          </Alert>
        )}
        <ItemPresentation item={presentation} className="pt-3" />
      </>
    );
  }

  const setProperty = (obj: Partial<GetSampleCollectDto>) => {
    setPresentedSampleCollect({ ...presentedSampleCollect, ...obj });
  };
  const saveIfChanged = (obj: CreateUpdateSampleCollectDto) => {
    if (_.isEqual(sampleCollect, obj)) {
      return;
    }
    props.onChange(obj);
  };
  const sampleCollectChange = (obj: Partial<CreateUpdateSampleCollectDto>) => {
    let dto = { ...sampleCollect, ...obj };

    const showActionDateTemp =
      dto?.collectBasisId !== SampleCollectContext.duringInspectionBasisId();

    setShowActionDate(showActionDateTemp);
    if (showActionDateTemp && dto.collectBasisShort !== '') {
      dto = {
        ...dto,
        ...{ collectBasisShort: '', actionDate: new Date() },
      };
    }
    if (!showActionDateTemp && dto.collectBasisShort !== '25.1') {
      dto = {
        ...dto,
        ...{ collectBasisShort: '25.1', actionDate: null, signatureDate: null },
      };
    }

    setSampleCollect(dto);
    saveIfChanged(dto);
  };
  const presentedToSampleCollect = () => {
    setSampleCollect(presentedSampleCollect);
    saveIfChanged(presentedSampleCollect);
  };

  const organizationSelected = (
    organization: OrganizationContext.Organization,
  ) => {
    if ('id' in organization) {
      sampleCollectChange({ organizationId: organization.id });
    } else {
      props.onNewOrganization(organization);
    }
  };

  const emptyIfNull = (value: string) => value || '';

  return (
    <Form className="d-grid gap-3">
      <FormRow controlId="fileNumber" label="Numer akt pobrania">
        <Form.Control
          type="text"
          value={emptyIfNull(presentedSampleCollect.fileNumber)}
          onChange={e => setProperty({ fileNumber: e.target.value })}
          onBlur={presentedToSampleCollect}
        />
      </FormRow>
      <FormRow
        controlId="organization"
        label={props.organizationLabel || 'Podmiot'}
      >
        <OrganizationModalSelector
          value={props.value.organization}
          onSelect={organizationSelected}
        />
      </FormRow>
      <FormRow controlId="collectPlace" label="Nazwa i adres miejsca pobrania">
        <div className="d-flex justify-content-between">
          <DynamicTextarea
            value={emptyIfNull(presentedSampleCollect.collectPlace)}
            onChange={e => setProperty({ collectPlace: e.target.value })}
            onBlur={presentedToSampleCollect}
          />
          <SampleCollectPlaces
            className="ms-1"
            organization={props.value.organization}
            onSelect={place => sampleCollectChange({ collectPlace: place })}
          />
        </div>
      </FormRow>
      <FormRow controlId="collectBasisId" label="Podstawa pobrania">
        <DictionarySelector
          dictionary="badanie próbek - podstawa pobrania"
          value={presentedSampleCollect.collectBasisId}
          onChange={option =>
            sampleCollectChange({ collectBasisId: option.value })
          }
        />
      </FormRow>
      <FormRow controlId="examOrganizationalUnitId" label="Organ">
        <OrganizationalUnitSelector
          option={OrganizationalUnitsGetOptions.notNotified}
          value={presentedSampleCollect.examOrganizationalUnitId}
          onChange={option =>
            sampleCollectChange({ examOrganizationalUnitId: option.value })
          }
        />
      </FormRow>
      {showActionDate && (
        <FormRow controlId="actionDate" label="Data rozpoczęcia czynności">
          <DatePicker
            value={presentedSampleCollect.actionDate}
            onChange={date => sampleCollectChange({ actionDate: date })}
          />
        </FormRow>
      )}
    </Form>
  );
};
