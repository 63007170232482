/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoFromJSONTyped,
    GetOrganizationDtoToJSON,
    GetPakDto,
    GetPakDtoFromJSON,
    GetPakDtoFromJSONTyped,
    GetPakDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPakExtendedDto
 */
export interface GetPakExtendedDto {
    /**
     * 
     * @type {GetPakDto}
     * @memberof GetPakExtendedDto
     */
    pak: GetPakDto;
    /**
     * 
     * @type {Array<GetOrganizationDto>}
     * @memberof GetPakExtendedDto
     */
    parties: Array<GetOrganizationDto>;
}

export function GetPakExtendedDtoFromJSON(json: any): GetPakExtendedDto {
    return GetPakExtendedDtoFromJSONTyped(json, false);
}

export function GetPakExtendedDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPakExtendedDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pak': GetPakDtoFromJSON(json['pak']),
        'parties': ((json['parties'] as Array<any>).map(GetOrganizationDtoFromJSON)),
    };
}

export function GetPakExtendedDtoToJSON(value?: GetPakExtendedDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pak': GetPakDtoToJSON(value.pak),
        'parties': ((value.parties as Array<any>).map(GetOrganizationDtoToJSON)),
    };
}


