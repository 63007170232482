import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import * as PDIContext from '../../../common/snrwbCore/contexts/ProtocolDuringInspectionContext';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateProtocolDuringInspectionDtoFromJSON as fromJSON } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { MemoizedAttachment } from '../../../common/snrwbCore/contexts/AttachmentContext';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

import { ProtocolDuringInspectionApproved } from './ProtocolDuringInspectionApproved';
import { ProtocolDuringInspectionEdit } from './ProtocolDuringInspectionEdit';
import { ProtocolDuringInspectionHeader } from './ProtocolDuringInspectionHeader';

interface ProtocolDuringInspectionProps {
  show: boolean;
  readonly: boolean;
  document: PDIContext.ProtocolWithAttachments;
  data: string;
  onCreate: (dto: PDIContext.CreateUpdateProtocolWithAttachments) => void;
  onUpdate: (
    id: string,
    dto: PDIContext.CreateUpdateProtocolWithAttachments,
  ) => void;
  onGet: (doc: PDIContext.ProtocolWithAttachments) => void;
  onDelete: (id: string) => void;
  onApprove: (id: string) => void;
  onRevertApprove: (id: string) => void;
  onClose: () => void;
  newAssociate: () => PDIContext.CreateUpdateProtocolWithAttachments;
}

export const ProtocolDuringInspection = (
  props: ProtocolDuringInspectionProps,
) => {
  const [pdi, setPdi] = useState(PDIContext.newProtocol());
  const [type, setType] = useState('inwentaryzacja');
  const [attachments, setAttachments] = useState<MemoizedAttachment[]>([]);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const addNew = props.document === undefined;
  const protocol = props.document?.protocol;
  const deleteProtocol = () => props.onDelete(protocol.id);

  const databaseSave = (approved?: boolean) => {
    const wrapped = { cuDto: pdi, attachments: attachments };
    wrapped.cuDto.approved =
      addNew && approved ? true : (undefined as unknown as boolean);
    addNew ? props.onCreate(wrapped) : props.onUpdate(protocol.id, wrapped);
  };

  const save = () => {
    PDIContext.validate(pdi).then(result => {
      if (result.valid) {
        databaseSave();
        props.onClose();
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  const sign = () => {
    PDIContext.validateSinging({ ...pdi, approved: true }).then(result => {
      if (result.valid) {
        if (!protocol) {
          databaseSave(true);
        } else {
          databaseSave();
          props.onApprove(protocol.id);
        }
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  const checkAttachments = async () => {
    if (attachments.length > 0) {
      setShowSaveErrors(true);
      setSaveErrors(['Przed usunięciem protokołu proszę usunąć załączniki.']);
      return false;
    }
    return true;
  };

  useEffect(() => {
    const cuDto = protocol
      ? fromJSON({ ...protocol, typeId: protocol.type.id })
      : props.newAssociate
      ? props.newAssociate().cuDto
      : PDIContext.newProtocol();

    const type = props.document?.protocol?.type?.value || 'inwentaryzacja';
    if (!protocol && type === 'inwentaryzacja') {
      const data = JSON.parse(props.data);
      cuDto.quantity = data.quantity;
    }

    setPdi(cuDto);
    setAttachments([]);
    setSaveErrors([]);
    setShowSaveErrors(false);
    setType(type);

    props.document?.attachments && setAttachments(props.document?.attachments);
  }, [props, protocol]);

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      size="xl"
      backdrop="static"
    >
      <Modal.Header className="modal-header-with-alert">
        <ProtocolDuringInspectionHeader
          document={props.document}
          readonly={props.readonly}
          onChangeType={(typeId, typeName) => {
            setPdi({ ...pdi, typeId });
            setType(typeName);
          }}
          onGet={() => {
            const getDto = PDIContext.getDto(props.document?.id, type, pdi);
            if (props.newAssociate) {
              getDto.protocol.inspectedProductId =
                props.newAssociate().cuDto.inspectedProductId;
            }
            props.onGet(getDto);
          }}
          onApprove={sign}
          onRevertApprove={props.onRevertApprove}
        />
      </Modal.Header>
      <Modal.Body>
        {protocol?.approved ? (
          <ProtocolDuringInspectionApproved
            document={props.document}
            pdi={pdi}
          />
        ) : (
          <ProtocolDuringInspectionEdit
            readonly={props.readonly}
            value={pdi}
            type={type}
            attachments={attachments}
            onChange={setPdi}
          />
        )}
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        {!props.readonly && (
          <>
            {!addNew && (
              <ConfirmationButton
                variant="outline-danger"
                roleAnyOf={[Role.InspectionsEdit]}
                onValidation={checkAttachments}
                onOK={deleteProtocol}
                confirmation="Czy na pewno usunąć protokół?"
              >
                Usuń
              </ConfirmationButton>
            )}
            {addNew && (
              <ConfirmationButton
                variant="outline-warning"
                onOK={sign}
                confirmation="Czy na pewno protokół został podpisany?"
              >
                Zapisz i podpisz
              </ConfirmationButton>
            )}
            <Button variant="outline-primary" onClick={save}>
              Zapisz
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
