import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  CreateUpdateAttachmentDto,
  CreateUpdateCorrectiveActionDto,
  CreateUpdateJudgmentDto,
  GetAttachmentDto,
  GetCorrectiveActionDto,
  GetJudgmentDto,
  GetProceedingDto,
  GetInspectedProductDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AddAttachment } from '../Attachments/AddAttachment';
import { Attachment } from '../Attachments/Attachment';
import { CorrectiveAction } from '../CorrectiveActions/CorrectiveAction';
import { Judgment } from '../Judgments/Judgment';
import { ProceedingViewApi } from '../../../common/snrwbCore/contexts/ProceedingContext';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

export const ProceedingAssociates: React.FC<{
  value: GetProceedingDto;
  bannerId?: string;
  editAuthorized: boolean;
  inspectedProduct: GetInspectedProductDto;
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  correctiveActions: AssociateDocuments<
    GetCorrectiveActionDto,
    CreateUpdateCorrectiveActionDto
  >;
  decisions: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  repairs: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  codex: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  api: ProceedingViewApi;
  onBannerClosed?: () => void;
}> = props => {
  const readonly = props.value.approved || !props.editAuthorized;

  return (
    <Row className="mb-3">
      <Col className="col-xxl-20p col-xl-4">
        <AssociatedDocuments
          name="Działania naprawcze"
          oneDocumentOnly={true}
          readonly={!props.editAuthorized}
          createModalType={CorrectiveAction}
          presentModalType={CorrectiveAction}
          docs={props.correctiveActions}
          initialBannerId={props.bannerId}
          onBannerClosed={props.onBannerClosed}
          data={JSON.stringify({
            firstLevelShortname: 'postępowanie',
            deleteRole: Role.ProceedingsEdit,
          })}
        />
      </Col>
      <Col className="col-xxl-20p col-xl-4">
        <AssociatedDocuments
          name="Załączniki"
          readonly={readonly}
          createModalType={AddAttachment}
          presentModalType={Attachment}
          docs={props.attachments}
          initialBannerId={props.bannerId}
          onBannerClosed={props.onBannerClosed}
        />
      </Col>
      <Col className="col-xxl-20p col-xl-4">
        <AssociatedDocuments
          name="Decyzje"
          readonly={readonly}
          createModalType={Judgment}
          presentModalType={Judgment}
          docs={props.decisions}
          initialBannerId={props.bannerId}
          onBannerClosed={props.onBannerClosed}
          data={JSON.stringify({
            firstLevelShortname: 'decyzja',
            showAdditionalOrders: true,
            showAppeal: props.editAuthorized,
            deleteRole: Role.ProceedingsEdit,
          })}
        />
      </Col>
      <Col className="col-xxl-20p col-xl-4">
        <AssociatedDocuments
          name="Post. naprawcze"
          readonly={readonly}
          createModalType={Judgment}
          presentModalType={Judgment}
          docs={props.repairs}
          initialBannerId={props.bannerId}
          onBannerClosed={props.onBannerClosed}
          data={JSON.stringify({
            firstLevelShortname: 'naprawcze',
            showAppeal: props.editAuthorized,
            deleteRole: Role.ProceedingsEdit,
          })}
        />
      </Col>
      <Col className="col-xxl-20p col-xl-4">
        <AssociatedDocuments
          name="KPA"
          readonly={readonly}
          createModalType={Judgment}
          presentModalType={Judgment}
          docs={props.codex}
          initialBannerId={props.bannerId}
          onBannerClosed={props.onBannerClosed}
          data={JSON.stringify({
            firstLevelShortname: 'kodeks',
            showAppeal: props.editAuthorized,
            deleteRole: Role.ProceedingsEdit,
          })}
        />
      </Col>
    </Row>
  );
};
