/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetLinkedExamToSampleDto
 */
export interface GetLinkedExamToSampleDto {
    /**
     * 
     * @type {string}
     * @memberof GetLinkedExamToSampleDto
     */
    examId: string;
    /**
     * 
     * @type {string}
     * @memberof GetLinkedExamToSampleDto
     */
    inspectionId: string;
    /**
     * 
     * @type {string}
     * @memberof GetLinkedExamToSampleDto
     */
    label: string;
}

export function GetLinkedExamToSampleDtoFromJSON(json: any): GetLinkedExamToSampleDto {
    return GetLinkedExamToSampleDtoFromJSONTyped(json, false);
}

export function GetLinkedExamToSampleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLinkedExamToSampleDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'examId': json['examId'],
        'inspectionId': json['inspectionId'],
        'label': json['label'],
    };
}

export function GetLinkedExamToSampleDtoToJSON(value?: GetLinkedExamToSampleDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'examId': value.examId,
        'inspectionId': value.inspectionId,
        'label': value.label,
    };
}


