import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  CreateUpdateInspectorDto,
  GetInspectorDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { AddInspector } from '../Inspectors/AddInspector';
import { Inspector } from '../Inspectors/Inspector';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';

export const Inspectors: React.FC<{
  inspectors: AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>;
  readonly?: boolean;
}> = props => {
  const snrwb = useSnrwbCore();

  const names = props.inspectors.documents
    .map(inspector => {
      const inspectorNames =
        inspector.employee.firstName + ' ' + inspector.employee.lastName;

      let duplicateEmployeeLabel = '';

      duplicateEmployeeLabel = snrwb.employees.getLabelForDuplicates(
        inspector.employee,
      );

      return `${inspectorNames}${duplicateEmployeeLabel}`;
    })
    .join(', ');
  return (
    <Row>
      <Col className="col-form-label fw-normal">{names}</Col>
      <Col md={3} className="float-end">
        <AssociatedDocuments
          name={props.readonly ? 'Szczegóły' : 'Edycja'}
          readonly={props.readonly}
          createModalType={AddInspector}
          presentModalType={Inspector}
          docs={props.inspectors}
        />
      </Col>
    </Row>
  );
};
