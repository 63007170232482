import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Skeleton from 'react-loading-skeleton';
import * as Icon from 'react-bootstrap-icons';

import * as SampleCollectContext from '../../../common/snrwbCore/contexts/SampleCollectContext';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  GetSampleCollectDto,
  GetSampleExamDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import oneliner from '../../../common/oneliner';
import { useNavigation } from '../../../common/navigation';
import { SubjectMemos } from '../Memos/SubjectMemos';

export const SampleCollectHeader = (props: {
  editAuthorized: boolean;
  sampleCollect: GetSampleCollectDto;
  sampleExams: GetSampleExamDto[];
}) => {
  const [header, setHeader] = useState('Pobranie próbek');
  const nav = useNavigation();
  const snrwb = useSnrwbCore();
  const [showSign, setShowSign] = useState(true);

  useEffect(() => {
    snrwb.sampleCollects.setDuringInspectionDto().then(() => {
      setShowSign(
        props.sampleCollect?.collectBasis?.id !==
          SampleCollectContext.duringInspectionBasisId(),
      );
    });
  }, [props.sampleCollect?.collectBasis, snrwb.sampleCollects]);

  useEffect(() => {
    const organization =
      props.sampleCollect &&
      props.sampleCollect.organization &&
      oneliner(props.sampleCollect.organization.name, 60);
    const exams = ((count: number) => {
      if (count === 1) {
        return 'badanie';
      }
      if (count % 100 >= 12 && count % 100 <= 14) {
        return 'badań';
      }
      if (count % 10 >= 2 && count % 10 <= 4) {
        return 'badania';
      }

      return 'badań';
    })(props.sampleExams.length);

    setHeader(
      `Pobranie próbek ${
        props.sampleCollect.fileNumber || ''
      } u ${organization}` + ` (${props.sampleExams.length} ${exams})`,
    );
  }, [props.sampleCollect, props.sampleExams]);

  if (!props.sampleCollect.id) {
    return <Skeleton className="mb-3" />;
  }

  return (
    <div className="d-flex mb-2">
      <Alert variant="success" className="pt-2 pb-2 mb-0 me-1 flex-fill">
        <Icon.CardChecklist /> {header}
      </Alert>
      {showSign && (
        <Alert
          variant={props.sampleCollect?.approved ? 'success' : 'danger'}
          className="pt-2 pb-2 mb-0"
        >
          {props.sampleCollect?.approved
            ? 'Podpisany protokół'
            : 'Protokół z czynności nie został jeszcze podpisany'}
        </Alert>
      )}
      <div className="ms-1">
        <SubjectMemos subject={props.sampleCollect} />
      </div>
      {props.sampleExams.length === 1 && (
        <Button
          className="ms-1"
          variant="outline-primary"
          onClick={() => nav.sampleExam(props.sampleExams[0].id)}
        >
          <Icon.Search /> Badanie {props.sampleExams[0].sysNumber}
        </Button>
      )}
    </div>
  );
};
