import React from 'react';

import { ReportJudgmentsCountType } from '../../../common/types/ReportTypes';

import { JudgDetails } from './JudgDetails';

export const ReportJudgmentsKindBody: React.FC<{
  data: ReportJudgmentsCountType;
}> = ({ data }) => {
  const artPenalty = [
    'art. 36a',
    'art. 36b',
    'art. 36c',
    'art. 36d',
    'art. 36e',
    'art. 36f',
    'art. 36g',
    'art. 36h',
    'art. 36i',
  ];

  const artDecision = [
    'art. 30 ust. 1 pkt 2 I',
    'art. 30 ust. 1 pkt 2 II',
    'art. 30 ust. 1 pkt 3',
    'art. 30 ust. 2',
    'art. 31 ust.1 pkt 2',
    'art. 31 ust. 1 pkt 3',
    'art. 31 ust. 2',
    'art. 32 pkt 1',
    'art. 32 pkt 2',
    'art. 32 pkt 3',
    'art. 32a pkt 1',
    'art. 32a pkt 2',
  ];

  return (
    <>
      <JudgDetails data={data} type={['art. 30 ust.1 pkt 1']}>
        zakaz dalszego przekazywania przez sprzedawcę i wyznaczenie terminu
        usunięcia nieprawidłowości
      </JudgDetails>
      <JudgDetails data={data} type={['art. 31 ust.1 pkt 1']}>
        nakaz wstrzymania wprowadzania do obrotu i wyznaczenie terminu usunięcia
        nieprawidłowości (po kontroli u producenta/importera)
      </JudgDetails>
      <JudgDetails
        data={data}
        type={['art. 30 ust.1 pkt 1', 'art. 31 ust.1 pkt 1']}
      >
        <b>Ogółem postanowienia naprawcze</b>
      </JudgDetails>
      <JudgDetails data={data} type={['inne', '']}>
        inne postanowienia (na podst. Kpa)
      </JudgDetails>
      <JudgDetails
        data={data}
        type={['art. 30 ust.1 pkt 1', 'art. 31 ust.1 pkt 1', 'inne']}
      >
        <b>Ogółem postanowienia</b>
      </JudgDetails>
      <JudgDetails
        data={data}
        type={['art. 30 ust. 1 pkt 2 I']}
        par={'art. 30 ust. 1 pkt 2'}
      >
        zakaz dalszego przekazywania przez sprzedawcę
      </JudgDetails>
      <JudgDetails
        data={data}
        type={['art. 30 ust. 1 pkt 2 II']}
        par={'art. 30 ust. 1 pkt 2'}
      >
        nakaz wycofania z obrotu przez producenta lub importera
      </JudgDetails>
      <JudgDetails data={data} type={['art. 30 ust. 1 pkt 3']}>
        nakaz wycofania przez sprzedawcę, jeżeli producent ma siedzibę poza RP
      </JudgDetails>
      <JudgDetails data={data} type={['art. 30 ust. 2']}>
        zakaz obrotu określoną partią wyrobu
      </JudgDetails>
      <JudgDetails
        data={data}
        type={[
          'art. 30 ust. 1 pkt 2 I',
          'art. 30 ust. 1 pkt 2 II',
          'art. 30 ust. 1 pkt 3',
          'art. 30 ust. 2',
        ]}
      >
        <b>Ogółem decyzje po kontroli u sprzedawcy</b>
      </JudgDetails>
      <JudgDetails data={data} type={['art. 31 ust.1 pkt 2']}>
        nakaz wycofania z obrotu wyrobu/określonej partii
      </JudgDetails>
      <JudgDetails data={data} type={['art. 31 ust. 1 pkt 3']}>
        nakaz ograniczenia udostępniania wyrobu/określonej partii
      </JudgDetails>
      <JudgDetails data={data} type={['art. 31 ust. 2']}>
        nakaz wycofania z obrotu
      </JudgDetails>
      <JudgDetails
        data={data}
        type={['art. 31 ust.1 pkt 2', 'art. 31 ust. 1 pkt 3', 'art. 31 ust. 2']}
      >
        <b>Ogółem decyzje po kontroli u producenta/importera</b>
      </JudgDetails>
      <JudgDetails
        data={data}
        type={[
          'art. 30 ust. 1 pkt 2 I',
          'art. 30 ust. 1 pkt 2 II',
          'art. 30 ust. 1 pkt 3',
          'art. 30 ust. 2',
          'art. 31 ust.1 pkt 2',
          'art. 31 ust. 1 pkt 3',
          'art. 31 ust. 2',
        ]}
      >
        <b>Ogółem decyzje nakazowe</b>
      </JudgDetails>
      <JudgDetails data={data} type={['art. 32 pkt 1']}>
        umorzenie postępowania w sytuacji, gdy wyrób spełnia wymagania
      </JudgDetails>
      <JudgDetails data={data} type={['art. 32 pkt 2']}>
        umorzenie postępowania w sytuacji, gdy niezgodność wyrobu została
        usunięta albo wyrób został wycofany z obrotu
      </JudgDetails>
      <JudgDetails data={data} type={['art. 32 pkt 3']}>
        umorzenie postępowania z innych przyczyn
      </JudgDetails>
      <JudgDetails
        data={data}
        type={['art. 32 pkt 1', 'art. 32 pkt 2', 'art. 32 pkt 3']}
      >
        <b>Ogółem umorzenia</b>
      </JudgDetails>
      <JudgDetails data={data} type={['art. 32a pkt 1']}>
        stwierdzenie usunięcia niezgodności
      </JudgDetails>
      <JudgDetails data={data} type={['art. 32a pkt 2']}>
        stwierdzenie wycofania wyrobu z obrotu
      </JudgDetails>
      <JudgDetails data={data} type={artPenalty}>
        <b>Decyzje o nałożeniu kary pieniężnej</b>
      </JudgDetails>
      <JudgDetails data={data} type={['kpa', '-']}>
        <b>Decyzje na podstawie Kodeksu postępowania administracyjnego</b>
      </JudgDetails>
      <JudgDetails data={data} type={['kpa', ...artDecision, ...artPenalty]}>
        <b>Ogółem decyzje</b>
      </JudgDetails>
      <JudgDetails
        data={data}
        type={[
          'art. 30 ust.1 pkt 1',
          'art. 31 ust.1 pkt 1',
          'inne',
          'kpa',
          ...artDecision,
          ...artPenalty,
        ]}
      >
        <b>Ogółem orzeczenia</b>
      </JudgDetails>
    </>
  );
};
