import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import {
  FilterExtended,
  FilterReportDataExtended,
} from '../../../components/Reports/FilterExtended';
import { ReportByFormalIrregulationsType } from '../../../components/ReportByFormalIrregulationsType/ReportByFormalIrregulationsType';
import { FormalIrregulationsType } from '../../../../common/snrwbCore/autogenerated/snrwbApiClient';

export const ReportFormalIrregularitiesView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<
    FilterReportDataExtended | undefined
  >({});

  const [data, setData] = useState<FormalIrregulationsType[]>();

  const onSearch = (filter?: FilterReportDataExtended) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportFormalIrregulations(filter).then(dataReports => {
      setData(dataReports);
    });
  };

  return (
    <>
      <FilterExtended
        dateFromName="Data rozpoczęcia od"
        dateToName="Data rozpoczęcia do"
        onSearch={onSearch}
        reportName="Nieprawidłowości formalne"
        productDescription="Wyrób"
        filterList={[
          'dateFrom',
          'dateTo',
          'organizationalUnit',
          'productDescription',
          'product',
          'dateToProtocol',
        ]}
      />
      <ReportByFormalIrregulationsType
        data={data || []}
        dataCSV={data || []}
        reportName="Nieprawidłowości formalne"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
