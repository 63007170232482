/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUpdateInspectionMetricDto,
    CreateUpdateInspectionMetricDtoFromJSON,
    CreateUpdateInspectionMetricDtoFromJSONTyped,
    CreateUpdateInspectionMetricDtoToJSON,
    CreateUpdateOrganizationDto,
    CreateUpdateOrganizationDtoFromJSON,
    CreateUpdateOrganizationDtoFromJSONTyped,
    CreateUpdateOrganizationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateInspectionBasedOnMetricWithOrganization
 */
export interface CreateInspectionBasedOnMetricWithOrganization {
    /**
     * 
     * @type {CreateUpdateInspectionMetricDto}
     * @memberof CreateInspectionBasedOnMetricWithOrganization
     */
    metric: CreateUpdateInspectionMetricDto;
    /**
     * 
     * @type {CreateUpdateOrganizationDto}
     * @memberof CreateInspectionBasedOnMetricWithOrganization
     */
    organization: CreateUpdateOrganizationDto;
    /**
     * 
     * @type {string}
     * @memberof CreateInspectionBasedOnMetricWithOrganization
     */
    sampleExamId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInspectionBasedOnMetricWithOrganization
     */
    proceedingId?: string;
}

export function CreateInspectionBasedOnMetricWithOrganizationFromJSON(json: any): CreateInspectionBasedOnMetricWithOrganization {
    return CreateInspectionBasedOnMetricWithOrganizationFromJSONTyped(json, false);
}

export function CreateInspectionBasedOnMetricWithOrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInspectionBasedOnMetricWithOrganization {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metric': CreateUpdateInspectionMetricDtoFromJSON(json['metric']),
        'organization': CreateUpdateOrganizationDtoFromJSON(json['organization']),
        'sampleExamId': !exists(json, 'sampleExamId') ? undefined : json['sampleExamId'],
        'proceedingId': !exists(json, 'proceedingId') ? undefined : json['proceedingId'],
    };
}

export function CreateInspectionBasedOnMetricWithOrganizationToJSON(value?: CreateInspectionBasedOnMetricWithOrganization | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metric': CreateUpdateInspectionMetricDtoToJSON(value.metric),
        'organization': CreateUpdateOrganizationDtoToJSON(value.organization),
        'sampleExamId': value.sampleExamId,
        'proceedingId': value.proceedingId,
    };
}


