import React from 'react';
import * as XLSX from 'xlsx';

import { useAuth } from '../../../../common/hooks/useAuth';
import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import {
  FilterDatesProductType,
  FilterDatesProductTypeReportData,
} from '../../../components/Reports/FilterDatesProductType';
import momencik from '../../../../common/momencik';

export const ReportInspectedProductsByTypeView: React.FC = () => {
  const auth = useAuth();
  const snrwbApi = useSnrwbCore();

  /*eslint complexity: ["error", 23]*/
  const stringifyFilterData = async (
    filterData: FilterDatesProductTypeReportData | undefined,
  ): Promise<string> => {
    let resultText = '';

    if (filterData) {
      if ('dateFrom' in filterData && filterData.dateFrom) {
        resultText += `data od: ${momencik(filterData.dateFrom) || '-'}, `;
      }
      if ('dateTo' in filterData && filterData.dateTo) {
        resultText += `data do: ${momencik(filterData.dateTo) || '-'}, `;
      }
    }

    const organizationalName =
      (filterData?.organizationalUnitId &&
        (
          await snrwbApi.organizationalUnits.getById(
            filterData?.organizationalUnitId,
          )
        )?.name) ||
      'wszystkie';

    const producentName =
      filterData?.producerId &&
      (await snrwbApi.organizations.getById(filterData?.producerId))?.name;

    const groupName =
      filterData?.groupProductTypeId &&
      (await snrwbApi.dictionaries.getById(filterData?.groupProductTypeId))
        ?.value;

    const signName =
      filterData?.signId &&
      (await snrwbApi.dictionaries.getById(filterData.signId))?.value;
    const nkitwName = filterData?.nkitw;

    resultText += `organ: ${organizationalName}`;
    nkitwName && (resultText += `, NKITW/TW/Opis: ${nkitwName}`);
    producentName && (resultText += `, producent: ${producentName}`);
    groupName && (resultText += `, grupa: ${groupName}`);
    signName && (resultText += `, oznaczenie: ${signName}`);

    return resultText;
  };

  const onSearch = (filter?: FilterDatesProductTypeReportData) => {
    snrwbApi.reports.reportProductsByType(filter).then(async dataReports => {
      const resultArray = dataReports.data.reduce(
        (acc, r) => {
          Object.keys(r)
            .filter(k => k.indexOf('_data_') > 0)
            .forEach(el => {
              if (r[el]) {
                const newDate = new Date(r[el]);
                newDate.setHours(0, 0, 0, 0);
                r[el] = newDate;
              }
            });

          acc.data.push(Object.values(r));
          return acc;
        },
        { data: [] as unknown[] },
      );

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.aoa_to_sheet(resultArray.data, {
        cellDates: true,
      });

      const lastLine = [
        [],
        [],
        [
          `Drukowane w SNRWB dnia ${momencik(new Date(), 'LLLL')} przez ${
            auth.currentUser?.name || ''
          }`,
        ],
        [`Filtr: ${await stringifyFilterData(filter)}`],
      ];

      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

      XLSX.utils.sheet_add_aoa(worksheet, lastLine, { origin: -1 });

      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const dataBlob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveAs(dataBlob, `${dataReports.reportName || 'report'}.xlsx`);
    });
  };

  return (
    <>
      <FilterDatesProductType
        onSearch={onSearch}
        reportName="Analiza typów wyrobu"
      />
    </>
  );
};
