import React from 'react';
import Form from 'react-bootstrap/Form';

import { Selector, Option } from '../Selects/Selector';
import FormRow from '../../../app/components/FormRow';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { Organization } from '../../../common/snrwbCore/contexts/OrganizationContext';
import * as ProductTypeContext from '../../../common/snrwbCore/contexts/ProductTypeContext';

interface ProductTypeLinkControlProps {
  productType: ProductTypeContext.ProductType;
  producer: Organization;
  sampleCollectId?: string;
  showValidationErrors: boolean;
  onSelect: (productId: string) => void;
  onValidation: (status: boolean) => void;
}

export const ProductTypeLinkControl: React.FC<ProductTypeLinkControlProps> =
  props => {
    const snrwb = useSnrwbCore();

    const emptyIfNull = (val: string) => val || '';

    const selectProductType = (chosen: Option) => {
      props.onSelect(chosen.value);
    };

    const getOptions = (text: string) => {
      const sampleCollectId = props.sampleCollectId || '';
      const productTypeId =
        'id' in props.productType ? props.productType.id : '';

      return snrwb.productTypes.getOptionsByExamAndType(
        sampleCollectId,
        productTypeId,
        text,
      );
    };

    return (
      <>
        <Form className="d-grid gap-3">
          <FormRow controlId="nkitw" label="Kontrolowany wyrób">
            {'id' in props.productType && (
              <Selector
                uniqueKey={
                  'id' in props.producer ? props.producer.id : 'new-producer'
                }
                onChange={selectProductType}
                readOnly={false}
                creatable={false}
                clearable={'id' in props.producer ? false : true}
                isValidNewOption={input => input !== '' && input !== undefined}
                value={emptyIfNull('')}
                provider={getOptions}
              />
            )}
          </FormRow>
        </Form>
      </>
    );
  };
