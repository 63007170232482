import React from 'react';

export const DocumentationPdfView: React.FC = () => {
  return (
    <>
      <object
        data="/docs/instrukcja.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
        aria-labelledby="Dokumentacja serwisu w pliku PDF"
        title="Dokumentacja serwisu"
      >
        <p>
          Twoja przeglądarka nie wspiera wyświetlania PDF-ów.
          <a href="/docs/instrukcja.pdf">Pobierz dokumentację</a>
        </p>
      </object>
    </>
  );
};
