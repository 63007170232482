import React from 'react';
import { Badge } from 'react-bootstrap';

import { Grid } from '../../../common/components/Grid/Grid';
import { GetSampleCollectExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { SampleExamLink } from '../SampleExams/SampleExamLink';
import momencik from '../../../common/momencik';

import { SampleCollectType } from './SampleCollectType';
import { SampleCollectProducts } from './SampleCollectProducts';

export const SampleCollectsList = (props: {
  sampleCollects: GetSampleCollectExtendedDto[];
  showFilter?: boolean;
  emptyGridMessage: string;
  onSelect: (sampleExamId: string) => void;
  duringInspectionBasisId: string;
}) => {
  const columns = [
    { header: '', property: 'type', noSorting: true },
    { header: 'Numer akt', property: 'fileNumber' },
    { header: 'Podstawa', property: 'collectBasis.shortname', noSorting: true },
    { header: 'Podmiot', property: 'organizationName' },
    { header: 'Wyroby', property: 'products', noSorting: true },
    { header: 'Data rozpoczęcia', property: 'actionDate' },
    {
      header: '',
      property: 'link',
      classNasme: 'text-center',
      noSorting: true,
    },
  ];

  const data = props.sampleCollects.map(sampleCollect => {
    return {
      key: sampleCollect.id,
      values: {
        type: (
          <SampleCollectType
            sampleCollect={sampleCollect}
            duringInspectionBasisId={props.duringInspectionBasisId}
          />
        ),
        fileNumber: sampleCollect.fileNumber,
        'collectBasis.shortname': (
          <Badge bg="warning" text="dark">
            {sampleCollect?.collectBasis?.shortname}
          </Badge>
        ),
        organizationName: sampleCollect.organization?.name,
        products: <SampleCollectProducts sampleCollect={sampleCollect} />,
        actionDate: momencik(sampleCollect.actionDate),
        link: <SampleExamLink sampleExamId={sampleCollect.id} />,
      },
      orderByValues: {
        actionDate: momencik(sampleCollect.actionDate, ''),
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={props.showFilter || false}
      initialOrderColumn="actionDate"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onSelect}
      emptyGridMessage={props.emptyGridMessage}
    />
  );
};
