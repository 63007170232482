import React from 'react';
import { Table } from 'react-bootstrap';

import { ReportInspectionPlanForInspectedProductType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportYearData } from '../Reports/Filter';

type Props = {
  data: ReportInspectionPlanForInspectedProductType | undefined;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportYearData;
};

export const ReportInspectionPlanByInspectedProduct: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = !data ? false : Object.keys(data?.details).length > 0;

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th rowSpan={3}>Organ</th>
              <th colSpan={7}>{reportName}</th>
            </tr>
            <tr>
              <th colSpan={2}>
                wyroby objęte przeprowadzonymi kontrolami planowymi
              </th>
              <th colSpan={2}>wykonanie planu ogółem</th>
              <th colSpan={2}>wykonanie planu - wyroby CPR</th>
            </tr>
            <tr>
              <th>ogółem</th>
              <th>w tym CPR</th>
              <th>liczba wyrobów wg planu</th>
              <th>% wykonania planu</th>
              <th>liczba wyrobów wg planu CPR</th>
              <th>% wykonania planu</th>
            </tr>
          </thead>
          {hasData && (
            <tbody>
              {data &&
                hasData &&
                Object.keys(data?.details)
                  .sort((a, b) => a.localeCompare(b, 'pl'))
                  .map((key, idx) => (
                    <tr key={idx}>
                      <td>{key}</td>
                      <td className="numbers-column">
                        {data?.details?.[key]?.inspProdPlannedCount}
                      </td>
                      <td className="numbers-column">
                        {data?.details?.[key]?.inspProdPlannedCPRCount}
                      </td>
                      <td className="numbers-column">
                        {data?.details?.[key]?.inspPlanProductCount}
                      </td>
                      <td className="percentage-column">
                        {data?.details?.[key]?.inspPlanProductCount > 0
                          ? (
                              (data?.details?.[key]?.inspProdPlannedCount /
                                data?.details?.[key]?.inspPlanProductCount) *
                              100
                            ).toFixed(2) + '%'
                          : ''}
                      </td>
                      <td className="numbers-column">
                        {data?.details?.[key]?.inspPlanProductCPRCount}
                      </td>
                      <td className="percentage-column">
                        {data?.details?.[key]?.inspPlanProductCPRCount > 0
                          ? (
                              (data?.details?.[key]?.inspProdPlannedCPRCount /
                                data?.details?.[key]?.inspPlanProductCPRCount) *
                              100
                            ).toFixed(2) + '%'
                          : ''}
                      </td>
                    </tr>
                  ))}
              <tr>
                <td>Suma:</td>
                <td className="numbers-column">
                  {data?.sum?.inspProdPlannedCount}
                </td>
                <td className="numbers-column">
                  {data?.sum?.inspProdPlannedCPRCount}
                </td>
                <td className="numbers-column">
                  {data?.sum?.inspPlanProductCount}
                </td>
                <td className="percentage-column">
                  {data?.sum && data?.sum?.inspPlanProductCount > 0
                    ? (
                        (data?.sum?.inspProdPlannedCount /
                          data?.sum?.inspPlanProductCount) *
                        100
                      ).toFixed(2) + '%'
                    : ''}
                </td>
                <td className="numbers-column">
                  {data?.sum?.inspPlanProductCPRCount}
                </td>
                <td className="percentage-column">
                  {data?.sum && data?.sum?.inspPlanProductCPRCount > 0
                    ? (
                        (data?.sum?.inspProdPlannedCPRCount /
                          data?.sum?.inspPlanProductCPRCount) *
                        100
                      ).toFixed(2) + '%'
                    : ''}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};
