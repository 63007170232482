import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card, Col, Row, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  GetTechAssessmentDto,
  GetAttachmentDto,
  CreateUpdateAttachmentDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  TechAssessments,
  TechAssessmentsVariant,
} from '../../components/TechAssessments/TechAssessments';
import { CreateUpdateTechAssessment } from '../../components/TechAssessments/CreateUpdateTechAssessment';
import { AssociateDocuments } from '../../components/AssociatedDocuments/AssociateDocumentsTypes';
import { useNotifications } from '../../../common/hooks/useNotifications';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import { SnrwbCoreContextType } from '../../../common/snrwbCore';
import { NotificationsProviderType } from '../../../common/notifications';
import { useCookies } from '../../../common/hooks/useCookies';

export const TechAssessmentsView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const notifications = useNotifications();
  const cookies = useCookies();
  const ac = AttachmentContext.forAssociatedDocuments;
  const [techAssessment, setTechAssessment] = useState<GetTechAssessmentDto>();
  const [attachments, setAttachments] = useState<
    AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>
  >(ac(snrwb, notifications, []));
  const [gridKey, setGridKey] = useState(Date.now());
  const mountedRef = useRef(false);

  const getAssociateAttachment = useCallback(
    async (
      snrwb: SnrwbCoreContextType,
      notifications: NotificationsProviderType,
      assessmentId: string,
      refresh?: (() => void) | undefined,
    ) => {
      const atts = await snrwb.attachments.getAllForObject(
        AttachmentContext.ObjectType.TechAssessment,
        assessmentId,
      );
      const attachments = ac(snrwb, notifications, atts, refresh);

      attachments.new = () => {
        const attachment = AttachmentContext.newAttachment();
        attachment.enObjectType = AttachmentContext.ObjectType.TechAssessment;
        attachment.objectId = assessmentId;
        return attachment;
      };
      return attachments;
    },
    [ac],
  );

  const handleClick = (key: string) => {
    snrwb.techAssessments.getById(key).then(assessment => {
      getAssociateAttachment(snrwb, notifications, assessment.id).then(
        attachments => {
          if (mountedRef.current) {
            setTechAssessment(assessment);
            setAttachments(attachments);
          }
        },
      );
    });
  };

  const [attachmentsUpdated, setAttachmentsUpdated] = useState(false);
  const refreshAttachments = useCallback(
    (id: string | undefined) => {
      if (id) {
        snrwb.techAssessments.getById(id).then(assessment => {
          getAssociateAttachment(snrwb, notifications, assessment.id).then(
            attachments => {
              if (mountedRef.current) {
                setAttachments(attachments);
              }
            },
          );
        });
      }
    },
    [getAssociateAttachment, notifications, snrwb],
  );
  useEffect(() => {
    if (attachmentsUpdated) {
      refreshAttachments(techAssessment?.id);
      setAttachmentsUpdated(false);
    }
  }, [
    attachmentsUpdated,
    refreshAttachments,
    setAttachmentsUpdated,
    techAssessment?.id,
  ]);

  const refresh = () => {
    setTechAssessment(undefined);
    setAttachments(ac(snrwb, notifications, []));
    setGridKey(Date.now());
  };

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  return (
    <Card>
      <Tabs defaultActiveKey="main" id="techAssessments">
        <Tab eventKey="main" title="Oceny techniczne" className="m-3">
          <TechAssessments
            key={gridKey}
            getData={snrwb.techAssessments.getAllByPortion}
            getCount={snrwb.techAssessments.getAllCount}
            variant={
              cookies.organizationalUnit?.nationalTechnicalAssessmentUnit
                ? TechAssessmentsVariant.forNationalTechnicalAssessmentUnit
                : TechAssessmentsVariant.full
            }
            attachments={attachments}
            onClick={handleClick}
          />
          <Row xs={1} md={4} className="g-4">
            <Col>
              <CreateUpdateTechAssessment
                organization={techAssessment?.producer}
                techAssessment={techAssessment}
                attachments={attachments}
                onAddNew={refresh}
                onChange={refresh}
                onClose={refresh}
                onUpdateAttachments={() => setAttachmentsUpdated(true)}
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Card>
  );
};
