import { GetCertificateDto } from '../autogenerated/snrwbApiClient/models/GetCertificateDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateCertificateDto,
  CreateUpdateCertificateDtoFromJSON,
  CreateUpdateOrganizationDto,
} from '../autogenerated/snrwbApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { CertificateSchema } from '../validation/schemas';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { certificateForGrid } from '../../../snrwb/components/Certificates/Certificates';
import momencik from '../../momencik';
import DictionaryStatic from '../../../snrwb/components/Dictionary/DictionaryStatic';
import { SnrwbCoreContextType } from '..';
import OrganizationalUnitBadge from '../../../snrwb/components/OrganizationalUnit/OrganizationalUnitBadge';

export type CertificateDto = GetCertificateDto | CreateUpdateCertificateDto;

export interface CertificateContextInterface {
  getById: (id: string) => Promise<GetCertificateDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getByOrganization: (id: string) => Promise<GetCertificateDto[]>;
  create: (dto: CreateUpdateCertificateDto) => Promise<GetCertificateDto>;
  createWithOrganization: (
    certificate: CreateUpdateCertificateDto,
    organization: CreateUpdateOrganizationDto,
  ) => Promise<GetCertificateDto>;
  update: (id: string, dto: CreateUpdateCertificateDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export const CertificateContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.certificateControllerGet(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.certificateControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(certificateForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.certificateControllerGetAllCount(filterText || ''),

    getByOrganization: (id: string) =>
      api
        .certificateControllerGetByOrganization(id)
        .then(cert =>
          cert.sort((a, b) => a.number?.localeCompare(b.number, 'pl')),
        ),
    create: (certificate: CreateUpdateCertificateDto) =>
      api.certificateControllerCreate(certificate),
    createWithOrganization: (
      certificate: CreateUpdateCertificateDto,
      organization: CreateUpdateOrganizationDto,
    ) =>
      api.certificateControllerCreateWithOrganization({
        certificate: certificate,
        organization: organization,
      }),
    update: (id: string, certificate: CreateUpdateCertificateDto) =>
      api.certificateControllerUpdate(id, certificate),
    delete: (id: string) => api.certificateControllerDelete(id),
  };
};

export const validate = (certificate: CreateUpdateCertificateDto) =>
  validateAgainst(CertificateSchema, certificate);

export const newCertificate = () => {
  return CreateUpdateCertificateDtoFromJSON({});
};

export const convert = (certificate: GetCertificateDto) => {
  if (!certificate) {
    return newCertificate();
  }
  const converted = CreateUpdateCertificateDtoFromJSON(certificate);
  converted.organizationalUnitId = certificate.organizationalUnit?.id;
  converted.producerId = certificate.producer?.id;
  converted.groupProductTypeId = certificate.groupProductType?.id;
  converted.informationId = certificate.information?.id;

  return converted;
};

export const forPresentation = (certificate: GetCertificateDto) => {
  const form = [];
  if (certificate.producer) {
    form.push({
      name: 'Producent',
      value: certificate.producer.name,
    });
  }
  if (certificate.date) {
    form.push({
      name: 'Data',
      value: momencik(certificate.date),
    });
  }
  if (certificate.number) {
    form.push({
      name: 'Numer',
      value: certificate.number,
    });
  }
  if (certificate.information) {
    form.push({
      name: 'Typ informacji',
      value: DictionaryStatic({ value: certificate.information }),
    });
  }
  if (certificate.groupProductType) {
    form.push({
      name: 'Grupa wyrobu',
      value: DictionaryStatic({ value: certificate.groupProductType }),
    });
  }
  if (certificate.organizationalUnit) {
    form.push({
      name: 'Jednostka notyfikowana',
      value: OrganizationalUnitBadge({
        organizationalUnit: certificate.organizationalUnit,
      }),
    });
  }
  if (certificate.note) {
    form.push({
      name: 'Uwagi',
      value: certificate.note,
    });
  }

  return form;
};

const addDictionaryHistoryEntry = async (
  snrwb: SnrwbCoreContextType,
  name: string,
  dictionaryId: string,
  prevDictionaryId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: any[],
) => {
  const dict = DictionaryStatic({
    value: await snrwb.dictionaries.getById(dictionaryId),
  });

  let prevDict = undefined;

  if (prevDictionaryId) {
    prevDict = DictionaryStatic({
      value: await snrwb.dictionaries.getById(prevDictionaryId),
    });
  }
  form.push({
    name: name,
    value: dict,
    prevValue: prevDict,
    equal: dictionaryId === prevDictionaryId,
  });
};

export const forPresentationHistory = async (
  snrwb: SnrwbCoreContextType,
  certificate: CreateUpdateCertificateDto,
  prevCertificate: CreateUpdateCertificateDto,
) => {
  const form = [];

  if (certificate.date) {
    form.push({
      name: 'Data',
      value: momencik(certificate.date),
      prevValue: momencik(prevCertificate?.date),
      equal: certificate.date === prevCertificate?.date,
    });
  }
  form.push({
    name: 'Numer',
    value: certificate.number,
    prevValue: prevCertificate?.number,
    equal: certificate.number === prevCertificate?.number,
  });
  await addDictionaryHistoryEntry(
    snrwb,
    'Typ informacji',
    certificate?.informationId,
    prevCertificate?.informationId,
    form,
  );

  await addDictionaryHistoryEntry(
    snrwb,
    'Grupa wyrobu',
    certificate?.groupProductTypeId,
    prevCertificate?.groupProductTypeId,
    form,
  );

  form.push({
    name: 'Jednostka notyfikowana',
    value: OrganizationalUnitBadge({
      organizationalUnit: await snrwb.organizationalUnits.getById(
        certificate.organizationalUnitId,
      ),
    }),
    prevValue: prevCertificate
      ? OrganizationalUnitBadge({
          organizationalUnit: await snrwb.organizationalUnits.getById(
            prevCertificate.organizationalUnitId,
          ),
        })
      : undefined,
    equal:
      certificate.organizationalUnitId ===
      prevCertificate?.organizationalUnitId,
  });

  form.push({
    name: 'Uwagi',
    value: certificate.note,
    prevValue: prevCertificate?.note,
    equal: certificate.note === prevCertificate?.note,
  });

  return form;
};
