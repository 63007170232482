import React from 'react';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { GetProductTypeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

import ProductTypeNotApproved from './ProductTypeNotApproved';

export const ProductTypeType: React.FC<{ productType: GetProductTypeDto }> =
  props => {
    if (!props.productType) {
      return <></>;
    }
    return (
      <div className="d-flex">
        {!props.productType.approved && (
          <ProductTypeNotApproved className="me-1 align-self-center" />
        )}
        {props.productType.cpr && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>Typ wyrobu CPR</Tooltip>}
          >
            <Badge bg="warning" text="dark">
              CPR
            </Badge>
          </OverlayTrigger>
        )}
      </div>
    );
  };
