import React from 'react';
import { Table } from 'react-bootstrap';

import {
  OrderedSampleExamsDetails,
  OrderedSampleExamsKinds,
  ReportOrderedSampleExamsType,
} from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';
import { ReportLegend } from '../Reports/ReportLegend';
import { Abbreviation } from '../../../common/abbreviationLegend';

type Props = {
  data: ReportOrderedSampleExamsType;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
  legend?: Abbreviation[];
};

const getSum = (
  data: ReportOrderedSampleExamsType,
  mode: string,
  type: string,
  kind: keyof OrderedSampleExamsDetails,
) => data.sum?.[mode]?.[type]?.[kind] || 0;

export const ReportBySampleExam: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
  legend,
}) => {
  const hasData = Object.keys(data.details).length > 0;

  const getDetail = (
    key: string,
    mode: string,
    type: string,
    kind: keyof OrderedSampleExamsDetails,
  ) => data.details?.[key]?.[mode]?.[type]?.[kind] || 0;

  const sumOrgUnit = (key: string, kind: keyof OrderedSampleExamsDetails) =>
    getDetail(key, '25.1', '-', kind) +
    getDetail(key, '16.2a', 'S', kind) +
    getDetail(key, '16.2a', 'B', kind) +
    getDetail(key, 'Pp', '-', kind);

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter} legend={legend}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={17}>{reportName}</th>
            </tr>
            <tr>
              <th rowSpan={3}>Organ</th>
              <th colSpan={3} rowSpan={2}>
                25.1
              </th>
              <th colSpan={7}>16.2a</th>
              <th colSpan={3} rowSpan={2}>
                Pp
              </th>
              <th colSpan={3} rowSpan={2}>
                Suma
              </th>
            </tr>
            <tr>
              <th colSpan={3}>S</th>
              <th colSpan={3}>B</th>
              <th rowSpan={2}>Suma</th>
            </tr>
            <tr>
              <th>P</th>
              <th>N</th>
              <th>Suma</th>
              <th>P</th>
              <th>N</th>
              <th>Suma</th>
              <th>P</th>
              <th>N</th>
              <th>Suma</th>

              <th>P</th>
              <th>N</th>
              <th>Suma</th>
              <th>P</th>
              <th>N</th>
              <th>Suma</th>
            </tr>
          </thead>

          {hasData && (
            <tbody>
              {Object.keys(data.details)
                .sort()
                .map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    <PNDetails orgUnit={key} data={data} mode="25.1" type="-" />
                    <PNDetails
                      orgUnit={key}
                      data={data}
                      mode="16.2a"
                      type="S"
                    />
                    <PNDetails
                      orgUnit={key}
                      data={data}
                      mode="16.2a"
                      type="B"
                    />
                    <td className="numbers-column">
                      {getDetail(key, '16.2a', 'S', 'count') +
                        getDetail(key, '16.2a', 'B', 'count')}
                    </td>
                    <PNDetails orgUnit={key} data={data} mode="Pp" type="-" />
                    <td className="numbers-column">
                      {sumOrgUnit(key, 'positive')}
                    </td>
                    <td className="numbers-column">
                      {sumOrgUnit(key, 'negative')}
                    </td>
                    <td className="numbers-column">
                      {sumOrgUnit(key, 'count')}
                    </td>
                  </tr>
                ))}
              <tr>
                <td>Suma:</td>
                <PNSum data={data} mode="25.1" type="-" />
                <PNSum data={data} mode="16.2a" type="S" />
                <PNSum data={data} mode="16.2a" type="B" />
                <td className="numbers-column">
                  {getSum(data, '16.2a', 'S', 'count') +
                    getSum(data, '16.2a', 'B', 'count')}
                </td>
                <PNSum data={data} mode="Pp" type="-" />
                <TotalSum data={data} />
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
      {legend && <ReportLegend legend={legend} />}
    </>
  );
};

const TotalSum: React.FC<{
  data: ReportOrderedSampleExamsType;
}> = ({ data }) => {
  return (
    <>
      {OrderedSampleExamsKinds.map((kind, idx) => (
        <td key={idx} className="numbers-column">
          {getSum(data, '25.1', '-', kind) +
            getSum(data, '16.2a', 'S', kind) +
            getSum(data, '16.2a', 'B', kind) +
            getSum(data, 'Pp', '-', kind)}
        </td>
      ))}
    </>
  );
};

const PNDetails: React.FC<{
  orgUnit: string;
  data: ReportOrderedSampleExamsType;
  mode: string;
  type: string;
}> = ({ orgUnit, data, mode, type }) => {
  return <TdByKind data={data.details?.[orgUnit]?.[mode]?.[type]} />;
};

const PNSum: React.FC<{
  data: ReportOrderedSampleExamsType;
  mode: string;
  type: string;
}> = ({ data, mode, type }) => {
  return <TdByKind data={data.sum?.[mode]?.[type]} />;
};

const TdByKind: React.FC<{
  data?: OrderedSampleExamsDetails | null;
}> = ({ data }) => {
  return (
    <>
      {OrderedSampleExamsKinds.map((kind, idx) => (
        <td key={idx} className="numbers-column">
          {data?.[kind] || 0}
        </td>
      ))}
    </>
  );
};
