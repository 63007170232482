/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOrganizationalUnitDto
 */
export interface GetOrganizationalUnitDto {
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationalUnitDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationalUnitDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationalUnitDto
     */
    unit: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationalUnitDto
     */
    shortname: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationalUnitDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationalUnitDto
     */
    city: string;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetOrganizationalUnitDto
     */
    parent: GetOrganizationalUnitDto;
    /**
     * 
     * @type {string}
     * @memberof GetOrganizationalUnitDto
     */
    parentId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrganizationalUnitDto
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrganizationalUnitDto
     */
    notified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetOrganizationalUnitDto
     */
    nationalTechnicalAssessmentUnit: boolean;
}

export function GetOrganizationalUnitDtoFromJSON(json: any): GetOrganizationalUnitDto {
    return GetOrganizationalUnitDtoFromJSONTyped(json, false);
}

export function GetOrganizationalUnitDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrganizationalUnitDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'unit': json['unit'],
        'shortname': json['shortname'],
        'address': json['address'],
        'city': json['city'],
        'parent': GetOrganizationalUnitDtoFromJSON(json['parent']),
        'parentId': json['parentId'],
        'active': json['active'],
        'notified': json['notified'],
        'nationalTechnicalAssessmentUnit': json['nationalTechnicalAssessmentUnit'],
    };
}

export function GetOrganizationalUnitDtoToJSON(value?: GetOrganizationalUnitDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'unit': value.unit,
        'shortname': value.shortname,
        'address': value.address,
        'city': value.city,
        'parent': GetOrganizationalUnitDtoToJSON(value.parent),
        'parentId': value.parentId,
        'active': value.active,
        'notified': value.notified,
        'nationalTechnicalAssessmentUnit': value.nationalTechnicalAssessmentUnit,
    };
}


