import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Skeleton from 'react-loading-skeleton';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetInspectionExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/';
import { handlerForAddInspection } from '../../../common/snrwbCore/contexts/InspectionContext';
import { MyInspections } from '../../components/Inspections/MyInspections';
import { InspectionsSearch } from '../../components/Inspections/InspectionsSearch';
import { AddInspection } from '../../components/Inspections/AddInspection';
import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';

export const InspectionsView: React.FC = () => {
  const nav = useNavigation();
  const snrwb = useSnrwbCore();
  const [inspections, setInspections] = useState<GetInspectionExtendedDto[]>(
    [],
  );
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let mounted = true;
    const safe = (set: () => void) => {
      if (mounted) {
        set();
        setReady(true);
      }
    };
    snrwb.inspections
      .getPendingForUser()
      .then(data => safe(() => setInspections(data)));

    return () => {
      mounted = false;
    };
  }, [snrwb.inspections]);

  return (
    <Card>
      <TabsHistory activeKey="my" id="inspections" className="mb-3">
        <Tab eventKey="my" title="Moje kontrole" className="mx-3 mb-3">
          {ready ? (
            <>
              <MyInspections data={inspections} />
              <AddInspection
                onCompleted={handlerForAddInspection(snrwb, nav.inspection)}
              />
            </>
          ) : (
            <Skeleton count={10} />
          )}
        </Tab>
        <Tab eventKey="signed" title="Moje podpisane" className="mx-3">
          <InspectionsSearch
            getData={snrwb.inspections.getSignedByPortion}
            getCount={snrwb.inspections.getSignedCount}
            hideOrganizationalUnitColumn
          />
        </Tab>
        <Tab eventKey="search" title="Wyszukiwanie" className="mx-3">
          <InspectionsSearch
            getData={snrwb.inspections.getAllByPortion}
            getCount={snrwb.inspections.getAllCount}
            defaultAdditionalFilters={{
              organizationalUnit: '',
              organization: '',
              inspectionPlace: '',
              actionDateFrom: null,
              actionDateTo: null,
              fileNumber: '',
            }}
          />
        </Tab>
      </TabsHistory>
    </Card>
  );
};
