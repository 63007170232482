import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';

import {
  GetProceedingDto,
  ValidationStatus,
  GetJudgmentDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { SubjectMemos } from '../Memos/SubjectMemos';

export const ProceedingApprovement: React.FC<{
  editAuthorized: boolean;
  validOrganizationalUnit: boolean;
  value: GetProceedingDto;
  decisions: GetJudgmentDto[];
  onApprove: () => void;
  onRevertApprove: () => void;
  mayBeApproved: () => Promise<ValidationStatus>;
}> = props => {
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  let variant = 'warning';
  let heading = 'Postępowanie w toku';
  let action = props.onApprove;
  let confirmation = 'Czy na pewno zakończyć postępowanie?';
  let buttonVariant = 'outline-primary';
  let buttonText = 'Zakończ';
  let noAuthorization = '';

  if (props.value.approved) {
    variant = 'success';
    heading = 'Postępowanie zakończone';
    action = props.onRevertApprove;
    confirmation = 'Czy na pewno ponownie otworzyć postępowanie?';
    buttonVariant = 'outline-danger';
    buttonText = 'Otwórz';
  }

  if (!props.editAuthorized) {
    if (props.validOrganizationalUnit) {
      noAuthorization = 'Brak uprawnień do edycji';
    } else {
      noAuthorization = 'Brak uprawnień do edycji - niewłaściwy organ';
    }
  }

  const validation = async () => {
    if (props.value.approved) {
      return true;
    }
    const status = await props.mayBeApproved();
    if (status.state !== 'ok') {
      setErrors(status.errors);
      setShowErrors(true);
      return false;
    }
    return true;
  };

  return (
    <>
      <Alert variant={variant}>
        <Alert.Heading className="d-flex justify-content-between">
          <span>{heading}</span>
          <div className="ms-1">
            <SubjectMemos subject={props.value} />
          </div>
        </Alert.Heading>
        {noAuthorization}
        {props.editAuthorized && (
          <>
            <hr />
            <div className="d-flex justify-content-end">
              <ConfirmationButton
                variant={buttonVariant}
                onValidation={validation}
                onOK={action}
                confirmation={confirmation}
              >
                {buttonText}
              </ConfirmationButton>
            </div>
          </>
        )}
      </Alert>
      <ValidationAlert
        show={showErrors}
        errors={errors}
        modal={true}
        onHide={() => setShowErrors(false)}
      />
    </>
  );
};
