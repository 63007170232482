import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { ReportPakJudgmentType } from '../../../../common/types/ReportTypes';
import { ReportPak } from '../../../components/ReportPak/ReportPak';
import { GetDictionaryDto } from '../../../../common/snrwbCore/autogenerated/snrwbApiClient';

export const ReportPakView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportPakJudgmentType>({
    details: {},
    sum: {},
  });
  const [judgmentTypes, setJudgmentTypes] = useState<GetDictionaryDto[]>([]);

  const onSearch = async (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportPakByJudgment(false, filter).then(dataReports => {
      setData(dataReports);
    });
    const judgmentTypes =
      snrwbApi.dictionaries.getByDictionaryType('orzeczenie - typ');

    const parentDictionary = (
      await snrwbApi.dictionaries
        .getByDictionaryType('orzeczenie - typ')
        .then(o => o.filter(jt => jt.shortname === 'kara')[0])
    ).id;

    setJudgmentTypes(
      await judgmentTypes.then(o =>
        o
          .filter(p => p.parentId === parentDictionary)
          .sort((a, b) =>
            (a.shortname || a.value).localeCompare(
              b.shortname || b.value,
              'pl',
            ),
          ),
      ),
    );
  };

  return (
    <>
      <Filter onSearch={onSearch} reportName="Administracyjne kary pieniężne" />
      <ReportPak
        data={data}
        judgmentTypes={judgmentTypes}
        reportName="Liczba przesłanek ukarania w wydanych decyzjach"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
