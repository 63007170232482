import { AtomicBlockUtils, EditorState, DraftHandleValue } from 'draft-js';

export const ACCEPTED_MIMES_TYPES = ['image/png', 'image/jpeg', 'image/gif'];

export const readImageAsDataUrl = (
  image: Blob,
  callback: (base64: string | ArrayBuffer | null) => void,
) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    callback(reader.result);
  };
  reader.readAsDataURL(image);
};

export const insertImageIntoEditor = (
  editorState: EditorState,
  base64: string | ArrayBuffer | null,
) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    'IMAGE',
    'IMMUTABLE',
    { src: base64, width: 50 },
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });
  return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
};

export const handlePastedFiles = (
  editorState: EditorState,
  files: Blob[],
  setEditorState: (state: EditorState) => void,
) => {
  if (!files || !files.length) {
    return 'not-handled' as DraftHandleValue;
  }

  const file = files[0];
  if (!ACCEPTED_MIMES_TYPES.includes(file.type)) {
    return 'not-handled';
  }
  readImageAsDataUrl(file, base64 => {
    const withAtomic = insertImageIntoEditor(editorState, base64);
    setEditorState(withAtomic);
  });

  return 'handled' as DraftHandleValue;
};
