import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from 'lodash';

import {
  GetPakDto,
  CreateUpdatePakDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow, {
  FormRow66,
  FormRow84R,
} from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import * as PakContext from '../../../common/snrwbCore/contexts/PakContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import FormSkeleton from '../../../app/components/FormSkeleton';
import CurrencyControl from '../../../app/components/CurrencyControl';
import momencik from '../../../common/momencik';

export const PakDetails: React.FC<{
  value: GetPakDto;
  editAuthorized: boolean;
  onChange: (dto: CreateUpdatePakDto) => void;
}> = props => {
  const [pak, setPak] = useState(PakContext.toCuDto(props.value));
  const [presentedPak, setPresentedSampleExam] = useState(pak);

  useEffect(() => {
    setPak(PakContext.toCuDto(props.value));
  }, [props.value]);
  useEffect(() => {
    setPresentedSampleExam(pak);
  }, [pak]);

  if (!props.value.id) {
    return <FormSkeleton />;
  }

  const editPaidAmount = props.editAuthorized;
  const presentation = PakContext.detailsPresent(props.value, !editPaidAmount);

  const setProperty = (obj: Partial<GetPakDto>) => {
    setPresentedSampleExam({ ...presentedPak, ...obj });
  };
  const saveIfChanged = (obj: CreateUpdatePakDto) => {
    if (_.isEqual(pak, obj)) {
      return;
    }
    props.onChange(obj);
  };
  const pakChange = (obj: Partial<CreateUpdatePakDto>) => {
    const dto = { ...pak, ...obj };
    if (momencik(dto.startDate) === momencik(dto.endDate)) {
      dto.startDate = dto.endDate;
    }
    setPak(dto);
    saveIfChanged(dto);
  };
  const presentedToPak = () => {
    setPak(presentedPak);
    saveIfChanged(presentedPak);
  };
  const nullIfEmpty = (value: string) => value || (null as unknown as string);

  return props.value.approved || !props.editAuthorized ? (
    <>
      <ItemPresentation item={presentation} className="pt-3" />
      {editPaidAmount && (
        <Form className="d-grid gap-3 mb-3">
          <FormRow controlId="paidAmount" label="Zapłacona należność">
            <CurrencyControl
              value={presentedPak.paidAmount || ''}
              onChange={e =>
                setProperty({ paidAmount: nullIfEmpty(e.target.value) })
              }
              onBlur={presentedToPak}
            />
          </FormRow>
        </Form>
      )}
    </>
  ) : (
    <>
      <Form className="d-grid gap-3 mb-3">
        <Row>
          <Col md={6}>
            <FormRow66 controlId="startDate" label="Data wszczęcia">
              <DatePicker
                value={presentedPak.startDate}
                onChange={date => pakChange({ startDate: date })}
              />
            </FormRow66>
          </Col>
          <Col md={6}>
            <FormRow84R controlId="endDate" label="Data zakończenia">
              <DatePicker
                value={presentedPak.endDate}
                onChange={date => pakChange({ endDate: date })}
              />
            </FormRow84R>
          </Col>
        </Row>
        <FormRow controlId="actNumber" label="Numer akt">
          <Form.Control
            type="text"
            value={presentedPak.actNumber || ''}
            onChange={e => setProperty({ actNumber: e.target.value })}
            onBlur={presentedToPak}
          />
        </FormRow>
        <FormRow controlId="quantity" label="Ilość wyrobu">
          <Form.Control
            type="text"
            value={presentedPak.quantity || ''}
            onChange={e => setProperty({ quantity: e.target.value })}
            onBlur={presentedToPak}
          />
        </FormRow>
        <FormRow controlId="penaltyAmount" label="Wysokość kary">
          <CurrencyControl
            value={presentedPak.penaltyAmount || ''}
            onChange={e =>
              setProperty({
                penaltyAmount: nullIfEmpty(e.target.value),
              })
            }
            onBlur={presentedToPak}
          />
        </FormRow>
        <FormRow controlId="paidAmount" label="Zapłacona należność">
          <CurrencyControl
            value={presentedPak.paidAmount || ''}
            onChange={e =>
              setProperty({ paidAmount: nullIfEmpty(e.target.value) })
            }
            onBlur={presentedToPak}
          />
        </FormRow>
      </Form>
    </>
  );
};
