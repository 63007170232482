import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import momencik from '../../../common/momencik';
import { GetCustomsFeedbackDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';

import { CustomFeedbacksOrganizationLink } from './CustomFeedbacksOrganizationLink';

interface CustomsFeedbacksProps {
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  data?: GetCustomsFeedbackDto[];
  withOrganization: boolean;
  onClick?: (key: string) => void;
}

export const customsFeedbackForGrid = (
  customsFeedback: GetCustomsFeedbackDto,
) => {
  return {
    key: customsFeedback.id,
    values: {
      'organizationalUnit.name': (
        <OrganizationalUnitBadge
          organizationalUnit={customsFeedback?.organizationalUnit}
        />
      ),
      'feedback.name': customsFeedback.name,
      'feedback.number': customsFeedback.number,
      'feedback.receiptDate': momencik(customsFeedback.receiptDate),
      'feedback.releaseDate': momencik(customsFeedback.releaseDate),
      'feedback.usage': customsFeedback.usage,
      'producer.name': customsFeedback.producer?.name,
      'feedbacks.link': (
        <CustomFeedbacksOrganizationLink
          producerId={customsFeedback.producer.id}
        />
      ),
    },
    orderByValues: {
      'feedback.receiptDate': momencik(customsFeedback.receiptDate, ''),
      'feedback.releaseDate': momencik(customsFeedback.releaseDate, ''),
      'organizationalUnit.name': customsFeedback.organizationalUnit.shortname,
    },
  };
};

export const CustomsFeedbacks: React.FC<CustomsFeedbacksProps> = props => {
  let columns = undefined;
  if (props.withOrganization) {
    columns = [
      { header: 'Organ', property: 'organizationalUnit.name' },
      { header: 'Data wpływu', property: 'feedback.receiptDate' },
      { header: 'Producent', property: 'producer.name' },
      { header: 'Numer opinii', property: 'feedback.number' },
      { header: 'Data wydania', property: 'feedback.releaseDate' },
      { header: 'Nazwa i typ wyrobu', property: 'feedback.name' },
      { header: '', property: 'feedbacks.link' },
    ];
  } else {
    columns = [
      { header: 'Organ', property: 'organizationalUnit.name' },
      { header: 'Data wpływu', property: 'feedback.receiptDate' },
      { header: 'Numer opinii', property: 'feedback.number' },
      { header: 'Data wydania', property: 'feedback.releaseDate' },
      { header: 'Nazwa i typ wyrobu', property: 'feedback.name' },
    ];
  }

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      data={props.data && props.data.map(d => customsFeedbackForGrid(d))}
      columns={columns}
      pageSize={10}
      availablePageSizes={[10, 20, 50]}
      showFilter={true}
      initialOrderColumn="feedback.receiptDate"
      initialOrderDirection="DESC"
      onRowClick={props.onClick}
      emptyGridMessage={
        props.withOrganization
          ? 'Brak opinii celnych'
          : 'Brak opinii celnych dla tego podmiotu'
      }
    />
  );
};
