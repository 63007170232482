export default (stringHtml: string | null = null): string | null => {
  if (!stringHtml) {
    return null;
  }
  let string = stringHtml.replaceAll(/<\/?p[^>]*>/g, '');
  if (string?.includes('<sub>')) {
    string = string.replace('<sub>', '_').replace('</sub>', '');
  }
  if (string?.includes('<sup>')) {
    string = string.replace('<sup>', '^').replace('</sup>', '');
  }
  return string;
};
