import {
  ApiResponse,
  GetProtocolDuringInspectionDto,
} from '../autogenerated/snrwbApiClient';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';

export interface PrintoutContextInterface {
  inspectionProtocol: (id: string) => Promise<void>;
  protocolDuringInspection: (
    protocol: GetProtocolDuringInspectionDto,
  ) => Promise<void>;
  protocolDuringInspectionOnTheFly: (
    protocol: GetProtocolDuringInspectionDto,
  ) => Promise<void>;
  sampleExamProtocol: (id: string) => Promise<void>;
  examActivityProtocol: (id: string) => Promise<void>;
  kwzCard: (id: string) => Promise<void>;
  resultDocument: (id: string) => Promise<void>;
}

export const PrintoutContext = (api: DefaultApi) => {
  const handleResponse = async (response: ApiResponse<void>) => {
    const file = await response.raw.blob();
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return {
    inspectionProtocol: async (id: string) =>
      api
        .pdfPrinterControllerGetInspectionProtocolRaw({ id })
        .then(handleResponse),
    protocolDuringInspection: async (
      protocol: GetProtocolDuringInspectionDto,
    ) => {
      if (protocol.type.value === 'inwentaryzacja') {
        api
          .pdfPrinterControllerGetInventoryProtocolRaw({ id: protocol.id })
          .then(handleResponse);
      } else if (protocol.type.value === 'oględziny') {
        api
          .pdfPrinterControllerGetExaminationProtocolRaw({ id: protocol.id })
          .then(handleResponse);
      } else {
        throw new Error('Unknown protocol type!');
      }
    },
    protocolDuringInspectionOnTheFly: async (
      protocol: GetProtocolDuringInspectionDto,
    ) => {
      if (protocol.type.value === 'inwentaryzacja') {
        api
          .pdfPrinterControllerGetInventoryProtocolOnTheFlyRaw({
            getProtocolDuringInspectionDto: protocol,
          })
          .then(handleResponse);
      } else if (protocol.type.value === 'oględziny') {
        api
          .pdfPrinterControllerGetExaminationProtocolOnTheFlyRaw({
            getProtocolDuringInspectionDto: protocol,
          })
          .then(handleResponse);
      } else {
        throw new Error('Unknown protocol type!');
      }
    },
    sampleExamProtocol: async (id: string) =>
      api
        .pdfPrinterControllerGetSampleExamProtocolRaw({ id })
        .then(handleResponse),
    examActivityProtocol: async (id: string) =>
      api
        .pdfPrinterControllerGetExamActivityProtocolRaw({ id })
        .then(handleResponse),
    kwzCard: async (id: string) =>
      api.pdfPrinterControllerGetKwzwbRaw({ id }).then(handleResponse),
    resultDocument: async (id: string) =>
      api
        .pdfPrinterControllerGetExamResultRaw({ sampleId: id })
        .then(handleResponse),
  };
};
