import React from 'react';
import { Alert } from 'react-bootstrap';

type Props = {
  hasData: boolean;
  filterApplied?: boolean;
};

export const ReportAlert: React.FC<Props> = ({ hasData, filterApplied }) => {
  return (
    <>
      {!hasData && (
        <Alert variant="primary">
          {filterApplied ? 'Brak danych do pokazania.' : 'Wyszukaj dane.'}
        </Alert>
      )}
    </>
  );
};
