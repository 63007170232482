import React from 'react';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import {
  GetProceedingDto,
  GetProductTypeDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ProductTypeNotApproved from '../ProductTypes/ProductTypeNotApproved';

export const ProceedingType: React.FC<{
  proceeding: GetProceedingDto;
  productType: GetProductTypeDto;
}> = props => {
  if (!props.proceeding) {
    return <></>;
  }

  return (
    <>
      {!props.productType.approved && (
        <ProductTypeNotApproved className="me-1" />
      )}
      {!props.proceeding.approved && props.proceeding.endDate && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              Postępowanie ma już datę końca, ale nie zostało zakończone
            </Tooltip>
          }
        >
          <Badge bg="danger" className="me-1">
            !
          </Badge>
        </OverlayTrigger>
      )}
      {props.proceeding.approved && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Postępowanie zakończone</Tooltip>}
        >
          <Badge bg="success" className="me-1">
            ✓
          </Badge>
        </OverlayTrigger>
      )}
    </>
  );
};
