/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSampleExamDto,
    GetSampleExamDtoFromJSON,
    GetSampleExamDtoFromJSONTyped,
    GetSampleExamDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetEssentialFeatureDto
 */
export interface GetEssentialFeatureDto {
    /**
     * 
     * @type {string}
     * @memberof GetEssentialFeatureDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetEssentialFeatureDto
     */
    feature: string;
    /**
     * 
     * @type {string}
     * @memberof GetEssentialFeatureDto
     */
    functionalProperty: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEssentialFeatureDto
     */
    publish: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetEssentialFeatureDto
     */
    examMethod: string;
    /**
     * 
     * @type {string}
     * @memberof GetEssentialFeatureDto
     */
    expectedResult: string;
    /**
     * 
     * @type {string}
     * @memberof GetEssentialFeatureDto
     */
    result: string;
    /**
     * 
     * @type {string}
     * @memberof GetEssentialFeatureDto
     */
    resultUncertainty: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEssentialFeatureDto
     */
    fullfilled: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetEssentialFeatureDto
     */
    controlResult: string;
    /**
     * 
     * @type {string}
     * @memberof GetEssentialFeatureDto
     */
    controlResultUncertainty: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetEssentialFeatureDto
     */
    controlFullfilled: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetEssentialFeatureDto
     */
    orderNo: number;
    /**
     * 
     * @type {GetSampleExamDto}
     * @memberof GetEssentialFeatureDto
     */
    sampleExam: GetSampleExamDto;
}

export function GetEssentialFeatureDtoFromJSON(json: any): GetEssentialFeatureDto {
    return GetEssentialFeatureDtoFromJSONTyped(json, false);
}

export function GetEssentialFeatureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEssentialFeatureDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'feature': json['feature'],
        'functionalProperty': json['functionalProperty'],
        'publish': json['publish'],
        'examMethod': json['examMethod'],
        'expectedResult': json['expectedResult'],
        'result': json['result'],
        'resultUncertainty': json['resultUncertainty'],
        'fullfilled': json['fullfilled'],
        'controlResult': json['controlResult'],
        'controlResultUncertainty': json['controlResultUncertainty'],
        'controlFullfilled': json['controlFullfilled'],
        'orderNo': json['orderNo'],
        'sampleExam': GetSampleExamDtoFromJSON(json['sampleExam']),
    };
}

export function GetEssentialFeatureDtoToJSON(value?: GetEssentialFeatureDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'feature': value.feature,
        'functionalProperty': value.functionalProperty,
        'publish': value.publish,
        'examMethod': value.examMethod,
        'expectedResult': value.expectedResult,
        'result': value.result,
        'resultUncertainty': value.resultUncertainty,
        'fullfilled': value.fullfilled,
        'controlResult': value.controlResult,
        'controlResultUncertainty': value.controlResultUncertainty,
        'controlFullfilled': value.controlFullfilled,
        'orderNo': value.orderNo,
        'sampleExam': GetSampleExamDtoToJSON(value.sampleExam),
    };
}


