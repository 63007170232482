import React from 'react';
import Form from 'react-bootstrap/Form';

import DynamicTextarea from '../../../app/components/DynamicTextarea';
import FormRow from '../../../app/components/FormRow';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import { Option } from '../Selects/Selector';

export const ForeignOrganization = (props: {
  readOnly?: boolean;
  editMode?: boolean;
  presentedOrganization: OrganizationContext.Organization;
  propertyChange: (obj: Partial<OrganizationContext.Organization>) => void;
  presentedToSelected: () => void;
  selectOrganization: (
    target: keyof OrganizationContext.Organization,
  ) => (option: Option) => void;
  byNameProvider: (text: string) => Promise<Option[]>;
}) => {
  const emptyIfNull = (val: string) => val || '';

  return (
    <Form className="d-grid gap-3">
      <FormRow controlId="name" label="Nazwa">
        {props.editMode ? (
          <Form.Control
            type="text"
            readOnly={props.readOnly}
            value={emptyIfNull(props.presentedOrganization.name)}
            onChange={e => props.propertyChange({ name: e.target.value })}
            onBlur={props.presentedToSelected}
          />
        ) : (
          <CreatableClearableSelector
            value={props.presentedOrganization.name}
            onChange={props.selectOrganization('name')}
            readOnly={props.readOnly}
            provider={props.byNameProvider}
          />
        )}
      </FormRow>
      <FormRow controlId="addressEx" label="Adres">
        <DynamicTextarea
          readOnly={props.readOnly}
          value={emptyIfNull(props.presentedOrganization.addressEx)}
          onChange={e => props.propertyChange({ addressEx: e.target.value })}
          onBlur={props.presentedToSelected}
        />
      </FormRow>
      <FormRow controlId="nip" label="NIP">
        <Form.Control
          type="text"
          readOnly={props.readOnly}
          value={emptyIfNull(props.presentedOrganization.nip)}
          onChange={e => props.propertyChange({ nip: e.target.value })}
          onBlur={props.presentedToSelected}
        />
      </FormRow>
    </Form>
  );
};
