import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import * as JudgmentContext from '../../../common/snrwbCore/contexts/JudgmentContext';
import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  GetJudgmentDto,
  CreateUpdateJudgmentDto,
  CreateUpdateJudgmentDtoFromJSON,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import DictionaryMultiSelector from '../Selects/DictionaryMultiSelector';
import FormRow from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import BooleanDropdown from '../../../app/components/BooleanDropdown';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useCookies } from '../../../common/hooks/useCookies';

import { JudgmentNoEdit } from './JudgmentNoEdit';

export const JudgmentToBeApproved = (props: {
  readonly: boolean;
  editMode: boolean;
  value: CreateUpdateJudgmentDto;
  valueOfGet: GetJudgmentDto;
  firstLevelShortname?: string;
  showAdditionalOrders?: boolean;
  onChange: (dto: CreateUpdateJudgmentDto) => void;
  onApprove: (dto: CreateUpdateJudgmentDto) => void;
}) => {
  const cookies = useCookies();
  const [judgment, setJudgment] = useState<CreateUpdateJudgmentDto>(
    JudgmentContext.newJudgment(cookies.organizationalUnit?.id),
  );
  const [presentedJudgment, setPresentedJudgment] =
    useState<CreateUpdateJudgmentDto>(judgment);

  const setProperty = (obj: Partial<CreateUpdateJudgmentDto>) => {
    const dto = { ...judgment, ...obj };
    setJudgment(dto);
    props.onChange(dto);
  };
  const setPresented = (obj: Partial<CreateUpdateJudgmentDto>) => {
    setPresentedJudgment({ ...presentedJudgment, ...obj });
  };

  const presentedToSelected = () => {
    setJudgment(presentedJudgment);
    props.onChange(presentedJudgment);
  };

  useEffect(() => {
    if (props.value) {
      setPresentedJudgment(props.value);
      setJudgment(props.value);
    }
  }, [props.value]);

  return (
    <>
      {props.editMode && !props.readonly && (
        <Alert variant="danger">
          <Alert.Heading>Orzeczenie nie zostało zatwierdzone</Alert.Heading>
          <hr />
          <div className="d-flex justify-content-end">
            <ConfirmationButton
              variant="outline-primary"
              onOK={() => props.onApprove(judgment)}
              confirmation="Czy na pewno zatwierdzić orzeczenie?"
            >
              Zatwierdź
            </ConfirmationButton>
          </div>
        </Alert>
      )}
      <Form className="d-grid gap-3">
        <FormRow controlId="type" label="Rodzaj">
          <DictionarySelector
            dictionary="orzeczenie - typ"
            value={presentedJudgment.kindId}
            firstLevelShortname={props.firstLevelShortname}
            onChange={option => setProperty({ kindId: option.value })}
          />
        </FormRow>
        <FormRow controlId="publishDate" label="Data wydania">
          <DatePicker
            value={presentedJudgment.publishDate}
            onChange={date => setProperty({ publishDate: date })}
          />
        </FormRow>
        <FormRow controlId="key" label="Znak">
          <Form.Control
            type="text"
            value={presentedJudgment.key || ''}
            onChange={e => setPresented({ key: e.target.value })}
            onBlur={presentedToSelected}
          />
        </FormRow>
        {props.showAdditionalOrders && (
          <FormRow controlId="type" label="Dodatkowe nakazy">
            <DictionaryMultiSelector
              dictionaryName="dodatkowy nakaz"
              value={presentedJudgment.additionalOrders}
              onChange={orders => setProperty({ additionalOrders: orders })}
            />
          </FormRow>
        )}
        <FormRow controlId="information" label="Informacja">
          <DynamicTextarea
            value={presentedJudgment.information || ''}
            onChange={e => setPresented({ information: e.target.value })}
            onBlur={presentedToSelected}
          />
        </FormRow>
        <FormRow controlId="kindOfSettlement" label="Rodzaj rozstrzygnięcia">
          <DynamicTextarea
            value={presentedJudgment.kindOfSettlement || ''}
            onChange={e => setPresented({ kindOfSettlement: e.target.value })}
            onBlur={presentedToSelected}
          />
        </FormRow>
      </Form>
    </>
  );
};

export const JudgmentAppeal = (props: {
  value: CreateUpdateJudgmentDto;
  onChange: (dto: CreateUpdateJudgmentDto) => void;
  firstLevelShortname?: string;
  kindShortname?: string;
}) => {
  const cookies = useCookies();
  const [judgment, setJudgment] = useState<CreateUpdateJudgmentDto>(
    JudgmentContext.newJudgment(cookies.organizationalUnit?.id),
  );
  const [presentedJudgment, setPresentedJudgment] =
    useState<CreateUpdateJudgmentDto>(judgment);

  const setProperty = (obj: Partial<CreateUpdateJudgmentDto>) => {
    const dto = { ...judgment, ...obj };
    setJudgment(dto);
    props.onChange(dto);
  };
  const setPresented = (obj: Partial<CreateUpdateJudgmentDto>) => {
    setPresentedJudgment({ ...presentedJudgment, ...obj });
  };

  const presentedToSelected = () => {
    setJudgment(presentedJudgment);
    props.onChange(presentedJudgment);
  };

  let judgementAppealTitle = '';
  let judgementResultOfAppealTitle = '';
  if (
    props.firstLevelShortname?.includes('decyzja') ||
    props.firstLevelShortname?.includes('kara')
  ) {
    judgementAppealTitle = 'Odwołanie';
    judgementResultOfAppealTitle = 'Wynik odwołania';
  } else if (
    props.firstLevelShortname?.includes('kontrolne') ||
    props.firstLevelShortname?.includes('naprawcze') ||
    props.firstLevelShortname?.includes('koszty')
  ) {
    judgementAppealTitle = 'Zażalenie';
    judgementResultOfAppealTitle = 'Wynik zażalenia';
  } else if (props.firstLevelShortname?.includes('kodeks')) {
    if (props.kindShortname?.includes('kpa')) {
      judgementAppealTitle = 'Odwołanie';
      judgementResultOfAppealTitle = 'Wynik odwołania';
    } else if (props.kindShortname?.includes('inne')) {
      judgementAppealTitle = 'Zażalenie';
      judgementResultOfAppealTitle = 'Wynik zażalenia';
    }
  }

  useEffect(() => {
    if (props.value) {
      setPresentedJudgment(props.value);
      setJudgment(props.value);
    }
  }, [props.value]);

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="finalDate" label="Data ostateczności">
          <DatePicker
            value={presentedJudgment.finalDate}
            onChange={date => setProperty({ finalDate: date })}
          />
        </FormRow>
        <FormRow controlId="appeal" label={judgementAppealTitle}>
          <BooleanDropdown
            value={presentedJudgment.appeal}
            onChange={value => setProperty({ appeal: value })}
          />
        </FormRow>
        <FormRow
          controlId="resultOfAppeal"
          label={judgementResultOfAppealTitle}
        >
          <DynamicTextarea
            value={presentedJudgment.resultOfAppeal || ''}
            onChange={e => setPresented({ resultOfAppeal: e.target.value })}
            onBlur={presentedToSelected}
          />
        </FormRow>
      </Form>
    </>
  );
};

const JudgmentButtons = (props: {
  addNew: boolean;
  readonly: boolean;
  showAppeal: boolean;
  document: GetJudgmentDto;
  deleteRole: Role;
  save: () => void;
  addApproved: () => void;
  onDelete: (id: string) => void;
  onClose: () => void;
}) => (
  <>
    <Button variant="outline-secondary" onClick={props.onClose}>
      Zamknij
    </Button>
    {!props.readonly && (
      <>
        {props.addNew ? (
          <Button variant="outline-warning" onClick={props.addApproved}>
            Dodaj i Zatwierdź
          </Button>
        ) : (
          <ConfirmationButton
            variant="outline-danger"
            roleAnyOf={[props.deleteRole]}
            onOK={() => props.onDelete(props.document.id)}
            confirmation="Czy na pewno usunąć orzeczenie?"
          >
            Usuń
          </ConfirmationButton>
        )}
      </>
    )}
    {(!props.readonly || props.showAppeal) && (
      <Button variant="outline-primary" onClick={props.save}>
        {props.addNew ? 'Dodaj' : 'Zapisz'}
      </Button>
    )}
  </>
);

export const Judgment = (props: {
  show: boolean;
  readonly: boolean;
  document: GetJudgmentDto;
  data?: string;
  onCreate: (dto: CreateUpdateJudgmentDto) => void;
  onUpdate: (id: string, dto: CreateUpdateJudgmentDto) => void;
  onGet: (doc: GetJudgmentDto) => void;
  onApprove: (id: string, dto?: CreateUpdateJudgmentDto) => void;
  onRevertApprove: (id: string) => void;
  onDelete: (id: string) => void;
  onClose: () => void;
}) => {
  const cookies = useCookies();
  const [judgment, setJudgment] = useState(
    JudgmentContext.newJudgment(cookies.organizationalUnit?.id),
  );
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [firstLevelShortname, setFirstLevelShortname] = useState<string>();
  const [showAdditionalOrders, setShowAdditionalOrders] = useState(false);
  const [showAppeal, setShowAppeal] = useState(false);
  const [deleteRole, setDeleteRole] = useState(Role.InspectionsEdit);
  const addNew = props.document === undefined;

  const tryToSave = (judgment: CreateUpdateJudgmentDto) => {
    JudgmentContext.validate(judgment).then(result => {
      if (result.valid) {
        if (addNew) {
          props.onCreate(judgment);
        } else {
          props.onUpdate(props.document.id, judgment);
        }
        props.onClose();
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  const addApproved = () => {
    tryToSave({ ...judgment, approved: true });
  };

  const save = () => {
    tryToSave(judgment);
  };

  useEffect(() => {
    const createUpdateDto = props.document
      ? CreateUpdateJudgmentDtoFromJSON(props.document)
      : JudgmentContext.newJudgment(cookies.organizationalUnit?.id);

    if (props.document) {
      createUpdateDto.kindId = props.document.kind.id;
      createUpdateDto.organizationalUnitId =
        props.document.organizationalUnit.id;
    }

    setJudgment(createUpdateDto);
    setSaveErrors([]);
    setShowSaveErrors(false);
  }, [props.show, props.document, cookies.organizationalUnit]);

  useEffect(() => {
    if (!props.data) {
      return;
    }
    const data = JSON.parse(props.data);
    if (data.firstLevelShortname) {
      setFirstLevelShortname(data.firstLevelShortname);
    }
    if (data.showAdditionalOrders) {
      setShowAdditionalOrders(true);
    }
    if (data.showAppeal) {
      setShowAppeal(true);
    }
    if (data.deleteRole) {
      setDeleteRole(data.deleteRole as Role);
    }
  }, [props.data]);

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      size="xl"
      backdrop="static"
    >
      {addNew && (
        <Modal.Header>
          <Modal.Title>Nowe orzeczenie</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {props.document?.approved || (props.readonly && props.document) ? (
          <>
            <JudgmentNoEdit
              readonly={props.readonly}
              value={props.document}
              inAppeal={showAppeal}
              deleteRole={deleteRole}
              onRevertApprove={() => props.onRevertApprove(props.document.id)}
            />
            {showAppeal && (
              <JudgmentAppeal
                value={judgment}
                onChange={setJudgment}
                firstLevelShortname={firstLevelShortname}
                kindShortname={props.document?.kind?.shortname || ''}
              />
            )}
          </>
        ) : (
          <>
            <JudgmentToBeApproved
              editMode={props.document !== undefined}
              readonly={props.readonly}
              value={judgment}
              valueOfGet={props.document}
              onApprove={dto => props.onApprove(props.document.id, dto)}
              onChange={setJudgment}
              firstLevelShortname={firstLevelShortname}
              showAdditionalOrders={showAdditionalOrders}
            />
            <ValidationAlert
              show={showSaveErrors}
              errors={saveErrors}
              className="mt-3"
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <JudgmentButtons
          {...props}
          {...{ addNew, showAppeal, deleteRole, save, addApproved }}
        />
      </Modal.Footer>
    </Modal>
  );
};
