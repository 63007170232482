import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import {
  GetProceedingDto,
  CreateUpdateProceedingDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import * as ProceedingContext from '../../../common/snrwbCore/contexts/ProceedingContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import FormSkeleton from '../../../app/components/FormSkeleton';
import DictionarySelector from '../Selects/DictionarySelector';
import momencik from '../../../common/momencik';

export const ProceedingDetails: React.FC<{
  value: GetProceedingDto;
  editAuthorized: boolean;
  onChange: (dto: CreateUpdateProceedingDto) => void;
}> = props => {
  const [proceeding, setProceeding] = useState(
    ProceedingContext.toCuDto(props.value),
  );
  const [presentedProceeding, setPresentedSampleExam] = useState(proceeding);

  useEffect(() => {
    setProceeding(ProceedingContext.toCuDto(props.value));
  }, [props.value]);
  useEffect(() => {
    setPresentedSampleExam(proceeding);
  }, [proceeding]);

  if (!props.value.id) {
    return <FormSkeleton />;
  } else if (props.value.approved || !props.editAuthorized) {
    const presentation = ProceedingContext.detailsPresent(props.value);
    return (
      <>
        <ItemPresentation item={presentation} className="pt-3" />
      </>
    );
  }

  const setProperty = (obj: Partial<GetProceedingDto>) => {
    setPresentedSampleExam({ ...presentedProceeding, ...obj });
  };
  const saveIfChanged = (obj: CreateUpdateProceedingDto) => {
    if (_.isEqual(proceeding, obj)) {
      return;
    }
    props.onChange(obj);
  };
  const proceedingChange = (obj: Partial<CreateUpdateProceedingDto>) => {
    const dto = { ...proceeding, ...obj };
    if (momencik(dto.startDate) === momencik(dto.endDate)) {
      dto.startDate = dto.endDate;
    }
    setProceeding(dto);
    saveIfChanged(dto);
  };
  const presentedToProceeding = () => {
    setProceeding(presentedProceeding);
    saveIfChanged(presentedProceeding);
  };

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="legalBasisId" label="Podstawa prawna">
          <DictionarySelector
            dictionary="postępowanie - podstawa prawna"
            value={presentedProceeding.legalBasisId}
            onChange={option =>
              proceedingChange({ legalBasisId: option.value })
            }
          />
        </FormRow>
        <FormRow controlId="actNumber" label="Numer akt">
          <Form.Control
            type="text"
            value={presentedProceeding.actNumber || ''}
            onChange={e => setProperty({ actNumber: e.target.value })}
            onBlur={presentedToProceeding}
          />
        </FormRow>
        <FormRow controlId="quantity" label="Ilość wyrobu">
          <Form.Control
            type="text"
            value={presentedProceeding.quantity || ''}
            onChange={e => setProperty({ quantity: e.target.value })}
            onBlur={presentedToProceeding}
          />
        </FormRow>
        <FormRow controlId="startDate" label="Data wszczęcia">
          <DatePicker
            value={presentedProceeding.startDate}
            onChange={date => proceedingChange({ startDate: date })}
          />
        </FormRow>
        <FormRow controlId="endDate" label="Data zakończenia">
          <DatePicker
            value={presentedProceeding.endDate}
            onChange={date => proceedingChange({ endDate: date })}
          />
        </FormRow>
      </Form>
    </>
  );
};
