import React, { useEffect, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { CreateUpdateEmployeeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { GetEmployeeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetEmployeeDto';
import * as EmployeeContext from '../../../common/snrwbCore/contexts/EmployeeContext';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';
import { AddEditEmployee } from '../../components/Employees/AddEditEmployee';
import { Employees } from '../../components/Employees/Employees';
import { employeeData } from '../../../common/snrwbCore/contexts/EmployeeContext';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useAuth } from '../../../common/hooks/useAuth';

export const EmployeesView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const notifications = useNotifications();
  const auth = useAuth();

  const [employees, setEmployees] = useState<GetEmployeeDto[]>([]);
  const [show, setShow] = useState(false);
  const [employee, setEmployee] = useState<GetEmployeeDto>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const addEditEmployee = async (
    employeeCU: CreateUpdateEmployeeDto,
    id?: string,
  ) => {
    const status = await EmployeeContext.validate(employeeCU);
    if (status.valid) {
      try {
        if (id) {
          await snrwbApi.employees.update(id, employeeCU);
          notifications.saveCompleted();
        } else {
          await snrwbApi.employees.create(employeeCU);
          notifications.saveCompleted();
        }
        setRefresh(true);
        setShow(false);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleClick = async (id: string) => {
    const getEmployee = await snrwbApi.employees.get(id);
    setEmployee(getEmployee);
    setShow(true);
  };

  const handleAdd = () => {
    if (!auth.check(Role.ConfigurationEdit)) {
      notifications.unauthorized();
      return;
    }
    setShow(true);
    setEmployee(undefined);
  };

  const handleClose = () => {
    setShow(false);
    setRefresh(true);
  };

  useEffect(() => {
    snrwbApi.employees.getActive().then(setEmployees);
    setRefresh(false);
  }, [snrwbApi.employees, refresh]);

  return (
    <Card>
      <Tabs defaultActiveKey="my" id="inspections" className="mb-3">
        <Tab eventKey="my" title="Pracownicy" className="m-3">
          <Employees data={employees} onRowClick={handleClick} />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy pracownik
          </Button>
          <AddEditEmployee
            employee={employeeData(employee)}
            id={employee?.id}
            onCompleted={addEditEmployee}
            show={show}
            handleClose={handleClose}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
