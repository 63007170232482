import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useInView } from 'react-cool-inview';

import { useMemosProvider } from '../../../common/hooks/useMemosProvider';
import { GetEmployeeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { firstSubject } from '../../../common/snrwbCore/contexts/MemoContext';

import MemosLoadingSpinner from './MemosLoadingSpinner';
import ThreadCard from './ThreadCard';
import { ThreadContent } from './ThreadContent';
import { ThreadsFilter } from './ThreadsFilter';

export const Threads = (props: {
  activeThread: string;
  loggedIn: GetEmployeeDto;
  loading: boolean;
  onChangeActiveThread: (threadId: string) => void;
  onQuickMemo: (content: string) => void;
  onClose: () => void;
}) => {
  const memosProvider = useMemosProvider();
  const { observe } = useInView({
    onEnter: ({ unobserve }) => {
      unobserve();
      memosProvider.loadMoreThreads();
      observe();
    },
  });
  const thereIsMore =
    memosProvider.threads &&
    memosProvider.threads.length > 0 &&
    !memosProvider.threads[memosProvider.threads.length - 1].last;

  return (
    <Row className="memos-threads-body flex-fill">
      <Col lg={4} className="mh-lg-100 mh-md-33 d-flex flex-column">
        <div className="d-flex mb-2">
          <ThreadsFilter />
        </div>
        <div className="mh-100 overflow-auto">
          {memosProvider.threads.map(thread => (
            <ThreadCard
              key={thread.id}
              thread={thread}
              active={thread.id === props.activeThread}
              loggedIn={props.loggedIn}
              onClick={() => {
                props.onChangeActiveThread(thread.id);
              }}
            />
          ))}
          {thereIsMore && (
            <div className="d-flex loading-spinner-height" ref={observe}>
              <MemosLoadingSpinner variant="success" />
            </div>
          )}
        </div>
      </Col>
      <Col lg={8} className="mh-lg-100 mh-md-66 d-flex flex-column">
        {props.loading ? (
          <MemosLoadingSpinner />
        ) : (
          <ThreadContent
            variant="userMemos"
            threadId={props.activeThread}
            subject={firstSubject(memosProvider.threadMemos)}
            onQuickMemo={props.onQuickMemo}
            onClose={props.onClose}
          />
        )}
      </Col>
    </Row>
  );
};
