import React, { useEffect, useRef } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Chart,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Stack } from 'react-bootstrap';

import { InspectionPlanProgressDataForChartType } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

interface InspectionPlanBar2ChartProps {
  data: InspectionPlanProgressDataForChartType | undefined;
  reportName: string;
}

export const InspectionPlanBar2Chart: React.FC<InspectionPlanBar2ChartProps> =
  props => {
    const myChart = useRef(null);

    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      Title,
      Tooltip,
      Legend,
    );

    const options = {
      plugins: {
        title: {
          display: true,
          text: props.reportName,
        },
        legend: {
          position: 'left' as const,
        },
      },
      responsive: true,
      interaction: {
        mode: 'index' as const,
        intersect: false,
      },
    };

    const data = {
      labels: props.data?.labels,
      datasets: [
        {
          label: 'ZAPLANOWANE',
          backgroundColor: '#1E2D68',
          data: props.data?.planned,
        },
        {
          label: 'ZREALIZOWANE',
          backgroundColor: '#FCA311',
          data: props.data?.completed,
        },
      ],
    };

    useEffect(() => {
      if (props.data && myChart && myChart.current) {
        (myChart.current as unknown as Chart).update();
      }
    }, [props.data]);

    return (
      <>
        <Stack className="col-md-9 mx-auto">
          {props.data && <Bar options={options} data={data} ref={myChart} />}
        </Stack>
      </>
    );
  };
