import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, Row, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useCookies } from '../../../common/hooks/useCookies';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNavigation } from '../../../common/navigation';
import { GetCertificateDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  Certificates,
  CertificatesVariant,
} from '../../components/Certificates/Certificates';
import { CreateUpdateCertificate } from '../../components/Certificates/CreateUpdateCertificate';

export const CertificatesView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const nav = useNavigation();
  const cookies = useCookies();
  const [certificate, setCertificate] = useState<GetCertificateDto>();
  const [gridKey, setGridKey] = useState(Date.now());
  const mountedRef = useRef(false);

  const handleClick = (key: string) => {
    snrwb.certificates.getById(key).then(cert => {
      if (mountedRef.current) {
        setCertificate(cert);
      }
    });
  };

  const refresh = () => {
    setCertificate(undefined);
    setGridKey(Date.now());
  };

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  return (
    <Card>
      <Tabs defaultActiveKey="main" id="certificates">
        <Tab eventKey="main" title="Certyfikaty" className="m-3">
          <Certificates
            key={gridKey}
            getData={snrwb.certificates.getAllByPortion}
            getCount={snrwb.certificates.getAllCount}
            variant={
              cookies.organizationalUnit?.notified
                ? CertificatesVariant.forNotifiedUnit
                : CertificatesVariant.full
            }
            onClick={handleClick}
          />
          <Row xs={1} md={4} className="g-4">
            <Col>
              <CreateUpdateCertificate
                organization={certificate?.producer}
                certificate={certificate}
                onAddNew={producerId => {
                  if (cookies.organizationalUnit?.notified) {
                    refresh();
                  } else {
                    nav.organization(producerId, false, 'certificates');
                  }
                }}
                onChange={refresh}
                onClose={refresh}
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Card>
  );
};
