import React from 'react';
import { Table } from 'react-bootstrap';

import {
  ReportCustomsFeedbacksDetails,
  ReportCustomsFeedbacksType,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

export type DetailsType = {
  [orgUnitShortname: string]: ReportCustomsFeedbacksDetails;
};

type Props = {
  data: ReportCustomsFeedbacksType | undefined;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportCustomsFeedback: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = data !== undefined && Object.keys(data.details).length > 0;

  const getDetail = (data: ReportCustomsFeedbacksType, key: string) => {
    return (data?.details as DetailsType)?.[
      key
    ] as ReportCustomsFeedbacksDetails;
  };

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={17}>{reportName}</th>
            </tr>
            <tr>
              <th rowSpan={2}>Organ</th>
              <th colSpan={2}>
                Liczba wniosków organów celnych o opinię w sprawie spełniania
                przez wyroby wymagań
              </th>
              <th colSpan={2}>
                Liczba opinii celnych wydanych dla organów celnych dotyczących
                wyrobów budowlanych
              </th>
            </tr>
            <tr>
              <th>ogółem</th>
              <th>w tym dot. wyrobów niebędących wyrobami budowlanymi</th>
              <th>liczba pozytywnych opinii</th>
              <th>liczba negatywnych opinii</th>
            </tr>
          </thead>

          {hasData && (
            <tbody>
              {Object.keys(data?.details)
                .sort()
                .map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    <td className="numbers-column">
                      {getDetail(data, key).applicationCount || 0}
                    </td>
                    <td className="numbers-column">
                      {getDetail(data, key).applicationConstrProductCount || 0}
                    </td>
                    <td className="numbers-column">
                      {getDetail(data, key).releasedCount || 0}
                    </td>
                    <td className="numbers-column">
                      {getDetail(data, key).releasedNegativeCount || 0}
                    </td>
                  </tr>
                ))}
              <tr key={'sum'}>
                <td>Suma:</td>
                <td className="numbers-column">
                  {data?.sum?.applicationCount || 0}
                </td>
                <td className="numbers-column">
                  {data?.sum?.applicationConstrProductCount || 0}
                </td>
                <td className="numbers-column">
                  {data?.sum?.releasedCount || 0}
                </td>
                <td className="numbers-column">
                  {data?.sum?.releasedNegativeCount || 0}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};
