import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

import {
  CreateUpdateSampleCollectDto,
  GetOrganizationDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

import { SampleCollectCreateForm } from './SampleCollectCreateForm';

export const SampleCollectForInspectedProduct = (props: {
  buttonVariant?: string;
  buttonClassName?: string;
  initialValues: CreateUpdateSampleCollectDto;
  organization: GetOrganizationDto;
  onOK: (dto: CreateUpdateSampleCollectDto) => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(true);
  const [showError, setShowError] = useState(false);
  const [sampleCollect, setSampleCollect] = useState(props.initialValues);

  const changeSampleCollect = (
    dto: CreateUpdateSampleCollectDto,
    valid: boolean,
  ) => {
    setSampleCollect(dto);
    setError(!valid);
    setShowError(false);
  };

  useEffect(() => setSampleCollect(props.initialValues), [props.initialValues]);

  return (
    <>
      <Button
        variant={props.buttonVariant || 'outline-primary'}
        className={props.buttonClassName}
        onClick={() => setShowModal(true)}
      >
        Nowe badanie
      </Button>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered={true}
        size="xl"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Badanie próbek wyrobu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SampleCollectCreateForm
            value={sampleCollect}
            organization={props.organization}
            showValidationErrors={showError}
            onChange={changeSampleCollect}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowModal(false)}
            className="me-1"
          >
            Zamknij
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              if (error) {
                setShowError(true);
                return;
              }
              setShowModal(false);
              props.onOK(sampleCollect);
            }}
          >
            Utwórz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
