import React, { useState, useEffect, ReactNode } from 'react';

import * as TechAssessmentContext from '../../../common/snrwbCore/contexts/TechAssessmentContext';
import { CreateUpdateTechAssessmentDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import ItemPresentationHistory from '../../../app/components/ItemPresentationHistory';

interface ObjectHistoryDetailsForTechAssessmentProps {
  value: CreateUpdateTechAssessmentDto & { id: string };
  prevValue: CreateUpdateTechAssessmentDto;
}

export const ObjectHistoryDetailsForTechAssessment: React.FC<ObjectHistoryDetailsForTechAssessmentProps> =
  props => {
    const [techAssessmentPresentation, setTechAssessmentPresentation] =
      useState<
        {
          name: string;
          value: ReactNode;
          prevValue: ReactNode;
          equal: boolean;
        }[]
      >();

    const snrwb = useSnrwbCore();

    useEffect(() => {
      if (props.value !== undefined) {
        TechAssessmentContext.forPresentationHistory(
          snrwb,
          props.value,
          props.prevValue,
        ).then(presentInfo => {
          setTechAssessmentPresentation(presentInfo);
        });
      }
    }, [props.prevValue, props.value, snrwb]);

    return (
      <>
        {techAssessmentPresentation && (
          <ItemPresentationHistory item={techAssessmentPresentation} />
        )}
      </>
    );
  };
