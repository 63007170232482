import React from 'react';
import { Spinner } from 'react-bootstrap';

export default (props: { variant?: string }) => (
  <Spinner
    animation="border"
    role="status"
    variant={props.variant || 'primary'}
    className="m-auto"
  >
    <span className="visually-hidden">Trwa ładowanie notatek...</span>
  </Spinner>
);
