import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateInspectionPlanGroupDto,
  CreateUpdateInspectionPlanGroupDtoFromJSON,
  GetInspectionPlanGroupDto,
} from '../autogenerated/snrwbApiClient';
import { validateAgainst } from '../validation/validateAgainst';
import { InspectionPlanGroupSchema } from '../validation/schemas';

export interface InspectionPlanGroupInterface {
  getByPlanId: (planId: string) => Promise<GetInspectionPlanGroupDto[]>;
  getById: (id: string) => Promise<GetInspectionPlanGroupDto>;
  delete: (id: string) => Promise<void>;
  create: (
    dto: CreateUpdateInspectionPlanGroupDto,
  ) => Promise<GetInspectionPlanGroupDto>;
  update: (
    id: string,
    dto: CreateUpdateInspectionPlanGroupDto,
  ) => Promise<void>;
}

export const InspectionPlanGroupContext = (api: DefaultApi) => {
  return {
    getByPlanId: (planId: string) =>
      api.inspectionPlanGroupControllerGetByPlan(planId),
    getById: (id: string) => api.inspectionPlanGroupControllerGet(id),
    delete: (id: string) => api.inspectionPlanGroupControllerDelete(id),
    create: (dto: CreateUpdateInspectionPlanGroupDto) =>
      api.inspectionPlanGroupControllerCreate(dto),
    update: (id: string, dto: CreateUpdateInspectionPlanGroupDto) =>
      api.inspectionPlanGroupControllerUpdate(id, dto),
  };
};

export const validate = (plan: CreateUpdateInspectionPlanGroupDto) =>
  validateAgainst(InspectionPlanGroupSchema, plan);

export const inspectionPlanGroupData = (
  group?: GetInspectionPlanGroupDto,
): CreateUpdateInspectionPlanGroupDto => {
  return CreateUpdateInspectionPlanGroupDtoFromJSON({
    ...group,
    inspectionPlan: group?.inspectionPlan?.id,
    productGroupId: group?.productGroup?.id,
  });
};
