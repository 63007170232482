import React from 'react';

import { useNavigation } from '../../../common/navigation';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';

export const ReportsProceedingView: React.FC = () => {
  const nav = useNavigation();

  return (
    <>
      <ReportCardMenu
        title="Liczba postępowań administracyjnych"
        text="(16a) Prowadzone postępowania administracyjne"
        footer="Postępowania, Postępowania w sprawie kar"
        onClick={nav.report16a}
      />
      <ReportCardMenu
        title="Liczba postępowań administracyjnych dot. wyrobów CPR"
        text="(16b) Prowadzone postępowania administracyjne - wyroby CPR"
        footer="Postępowania, Postępowania w sprawie kar"
        cpr={true}
        onClick={nav.report16b}
      />
      <ReportCardMenu
        title="Wydane orzeczenia"
        text="(17) Wydane orzeczenia w postępowaniach administracyjnych wszczętych na podstawie uowb"
        footer="Postępowania, Postępowania w sprawie kar"
        cpr={true}
        onClick={nav.report17}
      />
    </>
  );
};
