import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';
import { GetNarrowedKwzDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetNarrowedKwzDto';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';
import { calculateMomencikRemoveDate } from '../../../common/snrwbCore/contexts/KwzContext';
import ProductTypeNotApproved from '../ProductTypes/ProductTypeNotApproved';

import { DecisionCode } from './DecisionCode';
import { KwzLink } from './KwzLink';

interface KwzsProps {
  data: GetNarrowedKwzDto[];
  showfilter: boolean;
  forRemove?: boolean;
  onRowClick: (id: string) => void;
}

export const Kwzs: React.FC<KwzsProps> = props => {
  const columns = [
    { header: 'Nr karty', property: 'publicationNumber' },
    { header: 'Organ', property: 'organizationalUnit' },
    { header: 'Typ wyrobu', property: 'productTypeName' },
    { header: 'Producent', property: 'producer' },
    props.forRemove
      ? { header: 'Możliwy termin usunięcia', property: 'date' }
      : { header: 'Data decyzji', property: 'date' },
    { header: 'Kod decyzji', property: 'decision' },
    { header: '', property: 'link', className: 'text-center', noSorting: true },
  ];

  const data = props.data.map(kwz => {
    return {
      key: kwz.id,
      values: {
        publicationNumber: <PublicationNumberWithInfo kwz={kwz} />,
        productTypeName: kwz.productTypeName,
        producer: kwz.producerName,
        organizationalUnit: (
          <OrganizationalUnitBadge
            organizationalUnit={kwz.organizationalUnit}
          />
        ),
        date: props.forRemove
          ? calculateMomencikRemoveDate(kwz.judgmentFinalDate)
          : momencik(kwz.judgmentPublishDate),
        decision: (
          <DecisionCode
            decisionSign={kwz.decisionSign}
            decisionIndex={kwz.decisionIndex}
          />
        ),
        link: <KwzLink kwzId={kwz.id} />,
      },
      orderByValues: {
        publicationNumber: String(kwz.publicationNumber).padStart(10, '0'),
        date: momencik(kwz.judgmentPublishDate, ''),
        organizationalUnit: kwz.organizationalUnit.shortname,
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      initialOrderColumn="publicationNumber"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      showFilter={props.showfilter}
      onRowClick={props.onRowClick}
    />
  );
};

interface PublicationNumberWithInfoProps {
  kwz: GetNarrowedKwzDto;
}

const PublicationNumberWithInfo = (props: PublicationNumberWithInfoProps) => {
  return (
    <div>
      <KwzInfoIcon {...props} /> <KwzRequestIcon {...props} />{' '}
      {!props.kwz.productTypeApproved && (
        <ProductTypeNotApproved
          className="me-1"
          tooltip="W KWZ jest niezatwierdzony typ wyrobu"
        />
      )}{' '}
      <Badge bg="danger" text="dark">
        {props.kwz.publicationNumber}
      </Badge>
    </div>
  );
};

const KwzInfoIcon = (props: PublicationNumberWithInfoProps) => {
  const kwzInfo =
    props.kwz.publicationDate && props.kwz.removePublishDate
      ? { tooltip: 'Usunięty upublikowany wpis', icon: <Icon.Trash /> }
      : props.kwz.publicationDate && !props.kwz.removePublishDate
      ? { tooltip: 'Opublikowana KWZ', icon: <Icon.Globe /> }
      : props.kwz.approved
      ? { tooltip: 'Zatwierdzona KWZ', icon: <Icon.LockFill /> }
      : { tooltip: 'Niezatwierdzona KWZ', icon: <Icon.Unlock /> };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>{kwzInfo.tooltip}</Tooltip>}
    >
      {kwzInfo.icon}
    </OverlayTrigger>
  );
};

const KwzRequestIcon = (props: PublicationNumberWithInfoProps) => {
  const requestInfo = props.kwz.removePublishDate
    ? { tooltip: 'Usunięty wpis', icon: <Icon.Trash /> }
    : props.kwz.requestApproved
    ? {
        tooltip: 'Zatwierdzony wniosek o usunięcie wpisu',
        icon: <Icon.FolderCheck />,
      }
    : {
        tooltip: 'Utworzony niezatwierdzony wniosek o usunięcie wpisu KWZ',
        icon: <Icon.FolderSymlink />,
      };

  return (
    <>
      {props.kwz.request && !props.kwz.removePublishDate && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{requestInfo.tooltip}</Tooltip>}
        >
          {requestInfo.icon}
        </OverlayTrigger>
      )}
    </>
  );
};
