import { GetDictionaryDto } from '../autogenerated/snrwbApiClient/models/GetDictionaryDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateDictionaryDto,
  CreateUpdateDictionaryDtoFromJSON,
} from '../autogenerated/snrwbApiClient/models';
import { validateAgainst } from '../validation/validateAgainst';
import { DictionarySchema } from '../validation/schemas';

export interface DictionaryContextInterface {
  getById: (id: string) => Promise<GetDictionaryDto>;
  getActive: () => Promise<GetDictionaryDto[]>;
  update: (id: string, dto: CreateUpdateDictionaryDto) => Promise<void>;
  create: (dto: CreateUpdateDictionaryDto) => Promise<GetDictionaryDto>;
  changeToInactive: (id: string) => Promise<void>;
  getByDictionaryType: (typeName: string) => Promise<GetDictionaryDto[]>;
  getByTypeAndValueAndFirstLevel: (
    typeName: string,
    value: string,
  ) => Promise<GetDictionaryDto[]>;
}

export const DictionaryContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.dictionaryControllerGet(id),
    getActive: () => api.dictionaryControllerGetActive(),
    update: (id: string, dto: CreateUpdateDictionaryDto) =>
      api.dictionaryControllerUpdate(id, dto),
    create: (dto: CreateUpdateDictionaryDto) =>
      api.dictionaryControllerCreate(dto),
    changeToInactive: (id: string) =>
      api.dictionaryControllerChangeToInactive(id),
    getByDictionaryType: (typeName: string) =>
      api.dictionaryControllerGetByType(typeName),
    getByTypeAndValueAndFirstLevel: (typeName: string, value: string) =>
      api.dictionaryControllerGetByTypeAndValueAndFirstLevel(typeName, value),
  };
};

export const newDictionary = (parentId?: string, dictionaryTypeId?: string) => {
  return CreateUpdateDictionaryDtoFromJSON({
    parentId: parentId,
    dictionaryTypeId: dictionaryTypeId,
  });
};

export const validate = (dictionary: CreateUpdateDictionaryDto) =>
  validateAgainst(DictionarySchema, dictionary);

export const convert = (dictionary: GetDictionaryDto) => {
  if (!dictionary) {
    return newDictionary();
  }
  const converted = CreateUpdateDictionaryDtoFromJSON(dictionary);
  converted.dictionaryTypeId = dictionary.dictionaryTypeId;
  converted.parentId = dictionary.parentId;
  converted.shortname = dictionary.shortname;
  converted.value = dictionary.value;

  return converted;
};
