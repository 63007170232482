import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateInspectorDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as InspectorContext from '../../../common/snrwbCore/contexts/InspectorContext';
import EmployeeSelector from '../Selects/EmployeeSelector';
import { AddAssociateModalProps } from '../AssociatedDocuments/AssociateDocumentsTypes';
import FormRow from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';

export const AddInspector = (
  props: AddAssociateModalProps<CreateUpdateInspectorDto>,
) => {
  const [inspector, setInspector] = useState(InspectorContext.newInspector());
  const [inspectorName, setInspectorName] = useState<string>();
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const setProperty = (obj: Partial<CreateUpdateInspectorDto>) => {
    setInspector({ ...inspector, ...obj });
  };

  const onAdd = () => {
    const template = props.newAssociate();
    inspector.sampleExamId = template.sampleExamId;
    inspector.inspectionId = template.inspectionId;

    props.validate(inspector).then(result => {
      if (result.valid) {
        props.onCreate(inspector);
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  useEffect(() => {
    setInspector(InspectorContext.newInspector());
    setInspectorName(undefined);
    setSaveErrors([]);
    setShowSaveErrors(false);
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Nowy kontroler</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="employee" label="Pracownik">
            <EmployeeSelector
              value={inspectorName}
              onChange={option => {
                setProperty({ employeeId: option.value });
                setInspectorName(option.label);
              }}
            />
          </FormRow>
          <FormRow controlId="authorizationDate" label="Data upoważ.">
            <DatePicker
              value={inspector.authorizationDate}
              onChange={date => setProperty({ authorizationDate: date })}
            />
          </FormRow>
          <FormRow controlId="authorization" label="Upoważnienie">
            <Form.Control
              type="text"
              value={inspector.authorization || ''}
              onChange={e => setProperty({ authorization: e.target.value })}
            />
          </FormRow>
        </Form>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        <Button variant="outline-primary" onClick={onAdd}>
          Dodaj
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
