/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportNegativeCharacteristic
 */
export interface ReportNegativeCharacteristic {
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeCharacteristic
     */
    functionalProperties: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeCharacteristic
     */
    declaredValue: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeCharacteristic
     */
    testResult: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeCharacteristic
     */
    declaredCheckValue: string;
}

export function ReportNegativeCharacteristicFromJSON(json: any): ReportNegativeCharacteristic {
    return ReportNegativeCharacteristicFromJSONTyped(json, false);
}

export function ReportNegativeCharacteristicFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportNegativeCharacteristic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'functionalProperties': json['functionalProperties'],
        'declaredValue': json['declaredValue'],
        'testResult': json['testResult'],
        'declaredCheckValue': json['declaredCheckValue'],
    };
}

export function ReportNegativeCharacteristicToJSON(value?: ReportNegativeCharacteristic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'functionalProperties': value.functionalProperties,
        'declaredValue': value.declaredValue,
        'testResult': value.testResult,
        'declaredCheckValue': value.declaredCheckValue,
    };
}


