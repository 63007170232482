import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import {
  GetPartyToProceedingDto,
  ValidationStatus,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import { OrganizationSelector } from '../Organizations/OrganizationSelector';
import FormRow from '../../../app/components/FormRow';
import ValidationAlert from '../../../app/components/ValidationAlert';

export const PartyToProceeding = (props: {
  show: boolean;
  value?: GetPartyToProceedingDto;
  onHide: () => void;
  onAdd: (organization: OrganizationContext.Organization, type: string) => void;
  validateNew: (
    organization: OrganizationContext.Organization,
    type: string,
  ) => Promise<ValidationStatus>;
}) => {
  const [organization, setOrganization] =
    useState<OrganizationContext.Organization>(
      props.value?.organization || OrganizationContext.newOrganization(),
    );
  const [type, setType] = useState(props.value?.organizationType?.id);
  const [showValidation, setShowValidation] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isValid, setIsValid] = useState(false);

  const validation = (status: boolean) => {
    setIsValid(status);
    if (status) {
      setShowValidation(false);
    }
  };

  const save = () => {
    if (!isValid) {
      setShowValidation(true);
      return;
    }

    const typeId = type as unknown as string;
    props.validateNew(organization, typeId).then(status => {
      if (status.state !== 'ok') {
        setShowErrors(true);
        setErrors(status.errors);
      } else {
        props.onAdd(organization, typeId);
      }
    });
  };

  useEffect(() => {
    setOrganization(
      props.value?.organization || OrganizationContext.newOrganization(),
    );
    setType(props.value?.organizationType?.id);
    setShowValidation(false);
    setShowErrors(false);
    setErrors([]);
  }, [props.value, props.show]);

  return (
    <>
      <Modal show={props.show} centered={true} size="lg" backdrop="static">
        <Modal.Header>
          <Modal.Title>Dodawanie strony postępowania</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <FormRow controlId="type" label="Rodzaj">
              <DictionarySelector
                dictionary="strona postępowania - rodzaj"
                value={type}
                onChange={option => setType(option.value)}
              />
            </FormRow>
          </div>
          <OrganizationSelector
            value={organization}
            showValidationErrors={showValidation}
            onSelect={setOrganization}
            onValidation={validation}
            onClear={() => {
              setOrganization(
                props.value?.organization ||
                  OrganizationContext.newOrganization(),
              );
              setShowErrors(false);
              setIsValid(false);
            }}
          />
          <ValidationAlert show={showErrors} errors={errors} className="mt-3" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.onHide}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={save}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
