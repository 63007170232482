/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetExamResultDto,
    GetExamResultDtoFromJSON,
    GetExamResultDtoFromJSONTyped,
    GetExamResultDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetExamResultHistoryDto
 */
export interface GetExamResultHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof GetExamResultHistoryDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetExamResultHistoryDto
     */
    ts: Date;
    /**
     * 
     * @type {GetExamResultDto}
     * @memberof GetExamResultHistoryDto
     */
    examResult: GetExamResultDto;
    /**
     * 
     * @type {string}
     * @memberof GetExamResultHistoryDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetExamResultHistoryDto
     */
    activity: string;
    /**
     * 
     * @type {Date}
     * @memberof GetExamResultHistoryDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GetExamResultHistoryDto
     */
    sampleId: string;
}

export function GetExamResultHistoryDtoFromJSON(json: any): GetExamResultHistoryDto {
    return GetExamResultHistoryDtoFromJSONTyped(json, false);
}

export function GetExamResultHistoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetExamResultHistoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ts': (new Date(json['ts'])),
        'examResult': GetExamResultDtoFromJSON(json['examResult']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'activity': json['activity'],
        'date': (new Date(json['date'])),
        'sampleId': json['sampleId'],
    };
}

export function GetExamResultHistoryDtoToJSON(value?: GetExamResultHistoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ts': (value.ts.toISOString()),
        'examResult': GetExamResultDtoToJSON(value.examResult),
        'description': value.description,
        'activity': value.activity,
        'date': (value.date.toISOString()),
        'sampleId': value.sampleId,
    };
}


