import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { ReportByProceeding } from '../../../components/ReportByProceeding/ReportByProceeding';
import { ReportProceedingType } from '../../../../common/types/ReportTypes';
import abbreviationLegend from '../../../../common/abbreviationLegend';

export const ReportProceedingCprView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportProceedingType>({
    details: {},
    sum: {},
  });

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportProceeding(true, filter).then(dataReports => {
      setData(dataReports);
    });
  };

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName="Liczba postępowań administracyjnych dot. wyrobów CPR"
      />
      <ReportByProceeding
        data={data}
        reportName="Prowadzone postępowania administracyjne - wyroby CPR"
        headerName={['16.1a CPR', '32a CPR', 'KP CPR', 'Suma CPR']}
        filterApplied={filterApplied}
        usedFilter={usedFilter}
        legend={abbreviationLegend('proceeding')}
      />
    </>
  );
};
