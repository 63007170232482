import React, { useState, useEffect, useRef } from 'react';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { App } from 'react-bootstrap-icons';

import { routesInMenu, useNavigation } from '../../../common/navigation';
import SavingToast from '../SavingToast';
import ValidationAlert from '../ValidationAlert';
import { SavingResult } from '../../../common/types/SavingResult';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';
import { useNotifications } from '../../../common/hooks/useNotifications';
import ConflictsToast from '../ConflictsToast';
import ConnectionIssues from '../ConnectionIssues';
import { useCookies } from '../../../common/hooks/useCookies';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useAuth } from '../../../common/hooks/useAuth';

import SideNavSection from './SideNavSection';

interface SideNavProps {
  location: string;
  className: string;
}

const noAction = () => {
  // to be set later
};

const SideNav: React.FC<SideNavProps> = props => {
  const [collapsed, setCollapsed] = useState(false);
  const [savingResult, setSavingResult] = useState<SavingResult>();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [conflictText, setConflictText] = useState<React.ReactNode>();
  const [timestamp, setTimestamp] = useState(Date.now());
  const [kwz, setKwz] = useState('');
  const actionCallback = useRef(noAction);
  const notifications = useNotifications();
  const nav = useNavigation();
  const cookies = useCookies();
  const snrwb = useSnrwbCore();
  const auth = useAuth();

  useEffect(() => {
    notifications.onPromise = (
      promise: Promise<void>,
      action?: () => void,
      actionIfFailed?: () => void,
    ) => {
      promise
        .then(() => {
          setSavingResult({ saved: true });
          action && action();
        })
        .catch(async (response: Response) => {
          const errors = await responseErrors(response);
          setValidationErrors(errors);
          setShowValidationErrors(true);
          const a = actionIfFailed || action;
          if (a) {
            actionCallback.current = a;
          }
        });
    };

    notifications.saveCompleted = () => {
      setSavingResult({ saved: true });
    };

    notifications.unauthorized = () => {
      setTimeout(() =>
        setSavingResult({
          saved: false,
          errors: ['Brak wymaganych uprawnień'],
        }),
      );
    };

    notifications.badLink = (reason?: string, redirectLocation?: string) => {
      setTimeout(() => {
        setSavingResult({
          saved: false,
          errors: [reason || 'Strona nie może zostać załadowana'],
        });
        nav.menuItem(redirectLocation || '/');
      });
    };

    notifications.conflict = (text: React.ReactNode) => {
      setConflictText(text);
      setTimestamp(Date.now());
    };

    if (auth.check(Role.KwzPublishing) && auth.check(Role.KwzView)) {
      snrwb.kwzs
        .getWaitingForApproval()
        .then(result =>
          result.length > 0
            ? setKwz(` (${result.length.toString()})`)
            : setKwz(''),
        );
    }
  }, [notifications, nav, snrwb, auth]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={props.className + ' sidepanel-forsidenav d-flex flex-column'}
    >
      <div className="d-md-none py-3 d-flex align-items-center">
        <Button
          className="menubutton p-0 ms-3"
          onClick={handleCollapse}
          variant="link"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30"
            height="30"
            focusable="false"
          >
            <title>Menu</title>
            <path
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              d="M4 7h22M4 15h22M4 23h22"
            />
          </svg>
        </Button>
      </div>
      <Collapse in={collapsed}>
        <div className="overflow-wrapper">
          <nav className="table-of-contents pt-2 pb-4" role="complementary">
            {routesInMenu
              .filter(
                r =>
                  (!cookies.organizationalUnit?.notified &&
                    !cookies.organizationalUnit
                      ?.nationalTechnicalAssessmentUnit) ||
                  (cookies.organizationalUnit?.notified &&
                    r.certificatesOnly) ||
                  (cookies.organizationalUnit
                    ?.nationalTechnicalAssessmentUnit &&
                    r.techAssessmentsOnly),
              )
              .map(route =>
                route.heading === 'Zakwestionowane wyroby' ? (
                  <SideNavSection
                    key={route.path}
                    heading={`Zakwestionowane wyroby${kwz}`}
                    path={route.path}
                    location={props.location}
                    items={route.submenu}
                    icon={route.icon || App}
                    submenuAlwaysVisible={!!route.submenuAlwaysVisible}
                  />
                ) : (
                  <SideNavSection
                    key={route.path}
                    heading={route.heading}
                    path={route.path}
                    location={props.location}
                    items={route.submenu}
                    icon={route.icon || App}
                    submenuAlwaysVisible={route.submenuAlwaysVisible}
                  />
                ),
              )}
          </nav>
        </div>
      </Collapse>
      <SavingToast result={savingResult} />
      <ConflictsToast text={conflictText} timestamp={timestamp} />
      <ConnectionIssues />
      <ValidationAlert
        show={showValidationErrors}
        modal={true}
        onHide={() => {
          setShowValidationErrors(false);
          actionCallback.current();
        }}
        errors={validationErrors}
        className="mt-3"
        buttonText="Przywróć dane"
      />
    </div>
  );
};

export default SideNav;
