import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import { CompanyDto } from '../autogenerated/snrwbApiClient/models';

export interface GusApiContextInterface {
  getByNip: (nip: string) => Promise<CompanyDto>;
}

export const GusApiContext = (api: DefaultApi) => {
  return {
    getByNip: (nip: string) => api.gusApiControllerGetByNip(nip),
  };
};
