/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetPdfTemplateDto
 */
export interface GetPdfTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    template: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    header: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    headerFirstPage: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    footer: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    footerFirstPage: string;
    /**
     * 
     * @type {string}
     * @memberof GetPdfTemplateDto
     */
    margins: string;
}

export function GetPdfTemplateDtoFromJSON(json: any): GetPdfTemplateDto {
    return GetPdfTemplateDtoFromJSONTyped(json, false);
}

export function GetPdfTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPdfTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'template': json['template'],
        'header': json['header'],
        'headerFirstPage': json['headerFirstPage'],
        'footer': json['footer'],
        'footerFirstPage': json['footerFirstPage'],
        'margins': json['margins'],
    };
}

export function GetPdfTemplateDtoToJSON(value?: GetPdfTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'template': value.template,
        'header': value.header,
        'headerFirstPage': value.headerFirstPage,
        'footer': value.footer,
        'footerFirstPage': value.footerFirstPage,
        'margins': value.margins,
    };
}


