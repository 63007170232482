import React, { useEffect, useState } from 'react';
import { Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

type TabHistoryProps = React.PropsWithChildren<{
  activeKey: string;
  id: string;
  className?: string;
}>;

export type TabLocationState = {
  tabKey?: string;
};

export const TabsHistory = (props: TabHistoryProps) => {
  const history = useHistory<TabLocationState>();
  const [activeTab, setActiveTab] = useState(
    history.location.state?.tabKey || props.activeKey,
  );

  useEffect(() => {
    setActiveTab(history.location.state?.tabKey || props.activeKey);
  }, [history.location.state, props.activeKey]);

  const handleTabChange = (tabKey: string | null) => {
    history.push(history.location.pathname, {
      tabKey: tabKey || '',
    });
  };

  return (
    <Tabs
      activeKey={activeTab}
      id={props.id}
      className={props.className}
      onSelect={handleTabChange}
    >
      {props.children}
    </Tabs>
  );
};
