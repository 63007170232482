import React, { useState, useEffect, ReactNode } from 'react';

import * as ProductTypeContext from '../../../common/snrwbCore/contexts/ProductTypeContext';
import { GetProductTypeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import ItemPresentationHistory from '../../../app/components/ItemPresentationHistory';

interface ObjectHistoryDetailsForProductTypeProps {
  value: GetProductTypeDto;
  prevValue: GetProductTypeDto;
}

export const ObjectHistoryDetailsForProductType: React.FC<ObjectHistoryDetailsForProductTypeProps> =
  props => {
    const [productTypePresentation, setProductTypePresentation] = useState<
      {
        name: string;
        value: ReactNode;
        prevValue: ReactNode;
        equal: boolean;
      }[]
    >();

    const snrwb = useSnrwbCore();

    useEffect(() => {
      if (props.value !== undefined) {
        ProductTypeContext.ProductTypeToPresentHistoryWithPrev(
          snrwb,
          props.value,
          props.prevValue,
        ).then(presentInfo => {
          setProductTypePresentation(presentInfo);
        });
      }
    }, [props.prevValue, props.value, snrwb]);

    return (
      <>
        {productTypePresentation && (
          <ItemPresentationHistory item={productTypePresentation} />
        )}
      </>
    );
  };
