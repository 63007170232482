import React from 'react';

import { GridPagination } from './GridPagination';
import { GridSizing } from './GridSizing';
import { GridRecord } from './GridDataTypes';

export const GridFooter = (props: {
  availablePageSizes?: number[];
  hidePagination?: boolean;
  pageData: GridRecord[];
  pageNumber: number;
  totalPages?: number;
  pageSize: number;
  setPageNumber: (v: number) => void;
  countAllRows: () => Promise<number>;
  setPageSize: (v: number) => void;
}) => {
  return (
    <>
      {!props.hidePagination && props.pageData.length > 0 && (
        <div className="d-flex flex-row-reverse">
          <GridPagination
            pageNo={props.pageNumber}
            setPage={props.setPageNumber}
            totalPages={props.totalPages}
            allRowsCount={props.countAllRows}
          />
          {props.availablePageSizes && (
            <GridSizing
              availableValues={props.availablePageSizes}
              currentSize={props.pageSize}
              setValue={size => {
                props.setPageSize(size);
                props.setPageNumber(1);
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
