import React, { useState, useEffect } from 'react';
import { Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  GetInspectedProductDto,
  CreateUpdateInspectedProductDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';

interface IConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: React.ReactElement) => JSX.Element;
  children: JSX.Element;
}

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: React.PropsWithChildren<IConditionalWrapperProps>) =>
  condition ? wrapper(children) : children;

export default (props: {
  readonly: boolean;
  checkingInspection: boolean;
  product: GetInspectedProductDto;
  presentedProduct: CreateUpdateInspectedProductDto;
  productChange: (obj: Partial<CreateUpdateInspectedProductDto>) => void;
}) => {
  const readonly = props.readonly || props.checkingInspection;
  const [markDisabled, setMarkDisabled] = useState(
    readonly || props.product.productMissing,
  );

  const noProductThere = (checked: boolean) => {
    setMarkDisabled(checked);
    if (checked) {
      props.productChange({
        productMissing: checked,
        checkMarking: false,
        checkDeclaration: false,
        checkDocumentation: false,
        checkManual: false,
        checkVolatileOrganicCompounds: false,
      });
    } else {
      props.productChange({ productMissing: checked });
    }
  };

  const f = (b: boolean | undefined) => b || false;

  useEffect(() => {
    setMarkDisabled(
      props.readonly ||
        props.checkingInspection ||
        props.product.productMissing,
    );
  }, [props]);

  return (
    <ConditionalWrapper
      condition={!props.readonly && props.checkingInspection}
      wrapper={children => (
        <OverlayTrigger
          overlay={
            <Tooltip>
              Nie można ustalać zakresu kontroli w toku kontroli sprawdzającej
            </Tooltip>
          }
          placement="left"
        >
          {children}
        </OverlayTrigger>
      )}
    >
      <>
        <Row className="mt-3">
          <Col sm={3} className="col-form-label">
            Kontrola
          </Col>
          <Col sm={6}>
            <Row>
              <Col>
                <Form.Check
                  id={props.product.id + '-marking'}
                  type="checkbox"
                  label="oznakowania"
                  disabled={markDisabled}
                  checked={f(props.presentedProduct.checkMarking)}
                  onChange={e =>
                    props.productChange({ checkMarking: e.target.checked })
                  }
                />
                <Form.Check
                  id={props.product.id + '-declaration'}
                  type="checkbox"
                  label="deklaracji"
                  disabled={markDisabled}
                  checked={f(props.presentedProduct.checkDeclaration)}
                  onChange={e =>
                    props.productChange({ checkDeclaration: e.target.checked })
                  }
                />
              </Col>
              <Col>
                <Form.Check
                  id={props.product.id + '-documentation'}
                  type="checkbox"
                  label="dokumentacji"
                  disabled={markDisabled}
                  checked={f(props.presentedProduct.checkDocumentation)}
                  onChange={e =>
                    props.productChange({
                      checkDocumentation: e.target.checked,
                    })
                  }
                />
                <Form.Check
                  id={props.product.id + '-manual'}
                  type="checkbox"
                  label="instrukcji"
                  disabled={markDisabled}
                  checked={f(props.presentedProduct.checkManual)}
                  onChange={e =>
                    props.productChange({ checkManual: e.target.checked })
                  }
                />
              </Col>
              <Col>
                <Form.Check
                  id={props.product.id + '-volatile'}
                  type="checkbox"
                  label="lotnych związków organicznych"
                  disabled={markDisabled}
                  checked={f(
                    props.presentedProduct.checkVolatileOrganicCompounds,
                  )}
                  onChange={e =>
                    props.productChange({
                      checkVolatileOrganicCompounds: e.target.checked,
                    })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Form.Check
              id={props.product.id + '-product-missing'}
              type="checkbox"
              label="podczas kontroli nie stwierdzono wyrobu"
              disabled={readonly}
              checked={f(props.presentedProduct.productMissing)}
              onChange={e => noProductThere(e.target.checked)}
            />
          </Col>
        </Row>
      </>
    </ConditionalWrapper>
  );
};
