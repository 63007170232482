/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetAttachmentDto,
    GetAttachmentDtoFromJSON,
    GetAttachmentDtoFromJSONTyped,
    GetAttachmentDtoToJSON,
    GetSampleCollectDto,
    GetSampleCollectDtoFromJSON,
    GetSampleCollectDtoFromJSONTyped,
    GetSampleCollectDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSampleCollectWithExamsDto
 */
export interface GetSampleCollectWithExamsDto {
    /**
     * 
     * @type {GetSampleCollectDto}
     * @memberof GetSampleCollectWithExamsDto
     */
    sampleCollect: GetSampleCollectDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleCollectWithExamsDto
     */
    sampleExams: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleCollectWithExamsDto
     */
    samples: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleCollectWithExamsDto
     */
    inspectors: Array<string>;
    /**
     * 
     * @type {Array<GetAttachmentDto>}
     * @memberof GetSampleCollectWithExamsDto
     */
    files: Array<GetAttachmentDto>;
}

export function GetSampleCollectWithExamsDtoFromJSON(json: any): GetSampleCollectWithExamsDto {
    return GetSampleCollectWithExamsDtoFromJSONTyped(json, false);
}

export function GetSampleCollectWithExamsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSampleCollectWithExamsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sampleCollect': GetSampleCollectDtoFromJSON(json['sampleCollect']),
        'sampleExams': json['sampleExams'],
        'samples': json['samples'],
        'inspectors': json['inspectors'],
        'files': ((json['files'] as Array<any>).map(GetAttachmentDtoFromJSON)),
    };
}

export function GetSampleCollectWithExamsDtoToJSON(value?: GetSampleCollectWithExamsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sampleCollect': GetSampleCollectDtoToJSON(value.sampleCollect),
        'sampleExams': value.sampleExams,
        'samples': value.samples,
        'inspectors': value.inspectors,
        'files': ((value.files as Array<any>).map(GetAttachmentDtoToJSON)),
    };
}


