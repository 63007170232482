import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { ReportInspectedOrganizations } from '../../../../common/types/ReportTypes';
import { ReportByOrganizationType } from '../../../components/ReportByOrganizationType/ReportByOrganizationType';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import abbreviationLegend from '../../../../common/abbreviationLegend';

export const ReportInspectedOrganizationCPRView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportInspectedOrganizations>({
    details: {},
    sum: {},
  });

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports
      .reportInspectedOrganizations(true, filter)
      .then(dataReports => {
        setData(dataReports);
      });
  };

  return (
    <>
      <Filter onSearch={onSearch} reportName="Kontrole CPR u podmiotów" />
      <ReportByOrganizationType
        data={data}
        reportName1="kontrole, podczas których kontrolowano wyroby CPR, ogółem (suma poszczególnych)"
        reportName2="liczba kontroli u producentów - podczas których kontrolowano wyroby CPR"
        reportName3="liczba kontroli u importerów - podczas których kontrolowano wyroby CPR"
        reportName4="liczba kontroli u sprzedawców - podczas których kontrolowano wyroby CPR"
        reportName5="liczba kontroli u upoważnionych przedstawicieli producentów - podczas których kontrolowano wyroby CPR"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
        reportName="Kontrole CPR u podmiotów"
        legend={abbreviationLegend('inspectedProducts')}
      />
    </>
  );
};
