import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icons from 'react-bootstrap-icons';

import { useMemosProvider } from '../../../common/hooks/useMemosProvider';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetSubjectThreadStatsDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  sameStats,
  subjectType,
  SubjectType,
} from '../../../common/snrwbCore/contexts/MemoContext';

import MemosLoadingSpinner from './MemosLoadingSpinner';
import { ThreadContent } from './ThreadContent';

export const SubjectMemos = (props: { subject: SubjectType }) => {
  const snrwb = useSnrwbCore();
  const memosProvider = useMemosProvider();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<GetSubjectThreadStatsDto>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const queryingRef = useRef(false);
  const mountedRef = useRef(false);
  const memosNumber = stats?.messagesCount || 0;
  const pulsingNumber =
    stats?.participated && stats?.newMessagesCount ? stats.newMessagesCount : 0;
  const subjectThreadId = stats?.threadId || '';

  const quickMemo = async (content: string) => {
    await snrwb.memos.createPublicMemo({
      enSubjectType: subjectType(props.subject),
      subjectId: props.subject.id,
      content,
    });
  };

  const open = () => {
    if (stats) {
      setLoading(true);
      memosProvider.refreshMemos(stats.threadId, 'initial', () => {
        memosProvider.refreshSubjectThreadStats(props.subject, stats => {
          if (mountedRef.current) {
            setStats(stats);
          }
        });
        if (mountedRef.current) {
          setLoading(false);
        }
      });
    }
    setShow(true);
    buttonRef.current?.blur();
  };

  const close = () => {
    setShow(false);
  };

  useEffect(() => {
    mountedRef.current = true;
    if (props.subject?.id && !queryingRef.current) {
      queryingRef.current = true;
      memosProvider.refreshSubjectThreadStats(props.subject, stats => {
        if (mountedRef.current) {
          setStats(stats);
        }
        queryingRef.current = false;
      });
    }

    return () => {
      mountedRef.current = false;
    };
  }, [props.subject, memosProvider]);

  useEffect(() => {
    if (!stats || !sameStats(memosProvider.subjectThreadStats, stats)) {
      if (mountedRef.current) {
        setStats(memosProvider.subjectThreadStats);
      }
    }
  }, [memosProvider.subjectThreadStats, stats]);

  return (
    <>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip>
            {memosNumber ? `Liczba notatek: ${memosNumber}` : 'Brak notatek'}
          </Tooltip>
        }
      >
        <Button
          ref={buttonRef}
          className="h-100 py-1"
          variant="outline-dark"
          onClick={open}
        >
          {pulsingNumber > 0 ? (
            <div className="spinner-grow text-success align-items-center">
              <span className="badge text-white memos-pulsing-circle">
                {pulsingNumber}
              </span>
            </div>
          ) : memosNumber ? (
            <Icons.ChatLeftFill />
          ) : (
            <Icons.ChatLeft />
          )}
        </Button>
      </OverlayTrigger>
      <Modal show={show} centered backdrop="static" size="lg" onHide={close}>
        <Modal.Body className="d-flex flex-column memos-threads-body">
          {loading ? (
            <MemosLoadingSpinner />
          ) : (
            <ThreadContent
              variant="subjectMemos"
              threadId={subjectThreadId}
              subject={props.subject}
              onQuickMemo={quickMemo}
              onClose={close}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
