import React from 'react';
import { Button } from 'react-bootstrap';
import { Chrono } from 'react-chrono';

import momencik from '../../../common/momencik';
import { useNavigation } from '../../../common/navigation';
import {
  TimelineEventType,
  TimelineEventTypeObjectTypeEnum,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
/*eslint complexity: ["error", 15]*/

interface TimelineEventProps {
  events: TimelineEventType[];
}

export const TimelineEvent: React.FC<TimelineEventProps> = props => {
  const nav = useNavigation();
  const items = props.events.map(p => ({
    title: p.dateOfEvent ? momencik(p.dateOfEvent) : 'niepodana',
    cardTitle: p.event,
    cardSubtitle: p.organizationalUnit,
    objectId: p.objectId,
    objectType: p.objectType,
    rootId: p.rootId,
    middleId: p.middleId,
  }));

  const onClick = async (
    id: string,
    objectType: TimelineEventTypeObjectTypeEnum,
    rootId: string,
    middleId: string,
    newWindow?: boolean,
  ) => {
    switch (objectType) {
      case TimelineEventTypeObjectTypeEnum.SampleExam:
        nav.sampleExam(id, newWindow);
        break;
      case TimelineEventTypeObjectTypeEnum.Proceeding:
        nav.proceeding(id, newWindow);
        break;
      case TimelineEventTypeObjectTypeEnum.Metric:
        nav.inspection(id, newWindow);
        break;
      case TimelineEventTypeObjectTypeEnum.Inspection:
        nav.inspection(id, newWindow);
        break;
      case TimelineEventTypeObjectTypeEnum.Pak:
        nav.pak(id), newWindow;
        break;
      case TimelineEventTypeObjectTypeEnum.JudgmentFromInspection:
        nav.inspection(rootId, newWindow, middleId, id);
        break;
      case TimelineEventTypeObjectTypeEnum.JudgmentFromProceeding:
        nav.proceeding(rootId, newWindow, id);
        break;
      case TimelineEventTypeObjectTypeEnum.JudgmentFromPak:
        nav.pak(rootId, newWindow, id);
        break;
      case TimelineEventTypeObjectTypeEnum.JudgmentFromSampleExam:
        nav.sampleExam(rootId, newWindow, middleId, id);
        break;
      case TimelineEventTypeObjectTypeEnum.CorrectiveActionFromInspection:
        nav.inspection(rootId, newWindow, middleId, id);
        break;
      case TimelineEventTypeObjectTypeEnum.CorrectiveActionFromProceeding:
        nav.proceeding(rootId, newWindow, id);
        break;
      case TimelineEventTypeObjectTypeEnum.Sample:
        nav.sample(rootId, id, newWindow);
        break;
      case TimelineEventTypeObjectTypeEnum.ExamResult:
        nav.sample(rootId, id, newWindow);
        break;
    }
  };

  const handleMouseDown = (
    event: React.MouseEvent,
    id: string,
    objectType: TimelineEventTypeObjectTypeEnum,
    rootId: string,
    middleId: string,
  ) => {
    if (event.button === 1) {
      event.preventDefault();
      event.stopPropagation();

      onClick && onClick(id, objectType, rootId, middleId, true);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Chrono
        items={items}
        mode="VERTICAL"
        cardHeight={30}
        hideControls={true}
        useReadMore={false}
      >
        {items.map((i, idx) => (
          <div key={idx} style={{ width: '100%' }}>
            <div className="d-flex justify-content-end">
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() =>
                  onClick(i.objectId, i.objectType, i.rootId, i.middleId)
                }
                onMouseDown={event =>
                  handleMouseDown(
                    event,
                    i.objectId,
                    i.objectType,
                    i.rootId,
                    i.middleId,
                  )
                }
              >
                {'Przejdź'}
              </Button>
            </div>
          </div>
        ))}
      </Chrono>
    </div>
  );
};
