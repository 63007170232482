import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';

import Tabs from '../../../app/components/Tabs';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { FilterYear } from '../../components/Reports/FilterYear';
import { FilterReportYearData } from '../../components/Reports/Filter';
import { InspectionPlanProgressDataForChartType } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { InspectionPlanBar2Chart } from '../../components/InspectionPlans/InspectionPlanBar2Chart';
import { InspectionPlanBar1Chart } from '../../components/InspectionPlans/InspectionPlanBar1Chart';

export const InspectionPlanProgressView: React.FC = () => {
  const snrwbApi = useSnrwbCore();

  const [progressByOrganizationData, setProgressByOrganizationData] =
    useState<InspectionPlanProgressDataForChartType>();
  const [progressByProductsData, setProgressByProductsData] =
    useState<InspectionPlanProgressDataForChartType>();
  const [progressByProductsCprData, setProgressByProductsCprData] =
    useState<InspectionPlanProgressDataForChartType>();
  const [progressByProductGroupsData, setProgressByProductGroupsData] =
    useState<InspectionPlanProgressDataForChartType>();

  const findProgressByOrganization = (filter?: FilterReportYearData) => {
    snrwbApi.reports
      .reportInspectionPlanProgressByOrganization(filter)
      .then(dataReports => {
        setProgressByOrganizationData(dataReports);
      });
  };

  const findProgressByProducts = (filter?: FilterReportYearData) => {
    snrwbApi.reports
      .reportInspectionPlanProgressByInspectedProducts(filter)
      .then(dataReports => {
        setProgressByProductsData(dataReports);
      });
  };

  const findProgressByProductsCpr = (filter?: FilterReportYearData) => {
    snrwbApi.reports
      .reportInspectionPlanProgressByInspectedProductsCpr(filter)
      .then(dataReports => {
        setProgressByProductsCprData(dataReports);
      });
  };

  const findProgressByProductGroups = (filter?: FilterReportYearData) => {
    snrwbApi.reports
      .reportInspectionPlanProgressByInspectedProductGroups(filter)
      .then(dataReports => {
        setProgressByProductGroupsData(dataReports);
      });
  };

  return (
    <Card>
      <Tabs defaultActiveKey="main" id="inspectionPlansProgress">
        <Tab
          eventKey="main"
          title={'Realizacja planów kontroli u podmiotów'}
          className="m-3"
        >
          <FilterYear
            onSearch={findProgressByOrganization}
            reportName="Wykonanie planu - liczba kontroli u podmiotow ogółem"
          />
          <InspectionPlanBar2Chart
            data={progressByOrganizationData}
            reportName="Liczba kontroli u podmiotow ogółem"
          />
        </Tab>
        <Tab
          eventKey="productsProgress"
          title={'Realizacja planów kontroli wyrobów'}
          className="m-3"
        >
          <FilterYear
            onSearch={findProgressByProducts}
            reportName="Wykonanie planu - liczba wyrobów objętych kontrolami"
          />
          <InspectionPlanBar2Chart
            data={progressByProductsData}
            reportName="Liczba wyrobów objętych kontrolami"
          />
        </Tab>
        <Tab
          eventKey="cprProductsProgress"
          title={'Realizacja planów kontroli wyrobów CPR'}
          className="m-3"
        >
          <FilterYear
            onSearch={findProgressByProductsCpr}
            reportName="Wykonanie planu - liczba wyrobów CPR objętych kontrolami"
          />
          <InspectionPlanBar2Chart
            data={progressByProductsCprData}
            reportName="Liczba wyrobów CPR objętych kontrolami"
          />
        </Tab>
        <Tab
          eventKey="productsGroupProgress"
          title={'Realizacja planów kontroli dla grup wyrobów'}
          className="m-3"
        >
          <FilterYear
            onSearch={findProgressByProductGroups}
            reportName="Wykonanie planu - % wykonania kontroli wg grup wyrobów"
          />
          <InspectionPlanBar1Chart
            data={progressByProductGroupsData}
            reportName="% wykonania kontroli wg grup wyrobów"
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
