import { GetObjectHistoryDto } from '../autogenerated/snrwbApiClient/models/GetObjectHistoryDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';

export type ObjectHistoryDto = GetObjectHistoryDto;

export interface ObjectHistoryContextInterface {
  getById: (id: string) => Promise<GetObjectHistoryDto>;
  getHistory: (
    objectId: string,
    objectType: string,
  ) => Promise<GetObjectHistoryDto[]>;
  getPreviousEvent: (id: string) => Promise<GetObjectHistoryDto[]>;
}

export const ObjectHistoryContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.objectHistoryControllerGet(id),
    getHistory: (objectId: string, objectType: string) => {
      return api.objectHistoryControllerGetHistoryForObjectAndType(
        objectId,
        objectType,
      );
    },
    getPreviousEvent: (id: string) =>
      api.objectHistoryControllerGetPreviousEvent(id),
  };
};
