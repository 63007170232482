import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css'; // datepicker
import 'react-loading-skeleton/dist/skeleton.css';
import '../theme/theme.scss';
import '../theme/contrastDefault.scss';
import 'moment/locale/pl';

import { CookiesProvider } from '../common/cookies';
import { AuthProvider } from '../common/auth';
import { SnrwbCoreProvider } from '../common/snrwbCore';
import { SocketsProvider } from '../common/sockets';
import { MemosProvider } from '../common/memos';

import { Routes } from './containers/Routes';

function App() {
  return (
    <AuthProvider>
      <SnrwbCoreProvider>
        <CookiesProvider>
          <SocketsProvider>
            <MemosProvider>
              <Routes />
            </MemosProvider>
          </SocketsProvider>
        </CookiesProvider>
      </SnrwbCoreProvider>
    </AuthProvider>
  );
}

export default App;
