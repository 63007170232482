/*eslint max-lines-per-function: ["error", 350]*/
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { GetOrganizationalUnitDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetOrganizationalUnitDto';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import DatePicker from '../../../app/components/DatePicker';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';
import DictionarySelector from '../Selects/DictionarySelector';
import { Selector } from '../Selects/Selector';

export enum ReportSourceDocumentType {
  Inspections = 'inspections',
  Exams = 'exams',
  Proceedings = 'proceedings',
}

export type FilterDatesProductTypeReportData = {
  nkitw?: string;
  producerId?: string;
  signId?: string;
  dateFrom?: Date;
  dateTo?: Date;
  groupProductTypeId?: string;
  organizationalUnitId?: string;
  documentsType?: ReportSourceDocumentType;
};

interface FilterDatesProductTypeProps {
  onSearch: (filter?: FilterDatesProductTypeReportData) => void;
  reportName: string;
}

export const FilterDatesProductType: React.FC<FilterDatesProductTypeProps> =
  props => {
    const snrwbApi = useSnrwbCore();

    const [organizationalUnits, setOrganizationalUnits] = useState<
      GetOrganizationalUnitDto[]
    >([]);

    const [showNewAlert, setShowNewAlert] = useState(false);
    const [formData, setFormData] = useState<FilterDatesProductTypeReportData>({
      dateFrom: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
      documentsType: ReportSourceDocumentType.Inspections,
    });
    const [orgName, setOrgName] = useState('');
    const [nkitwName, setNkitwName] = useState('');

    useEffect(() => {
      snrwbApi.organizationalUnits
        .getMany(OrganizationalUnitsGetOptions.notNotified)
        .then(orgUnit => {
          setOrganizationalUnits(orgUnit);
        });
    }, [snrwbApi]);

    const handleClear = () => {
      setFormData({
        organizationalUnitId: '',
        documentsType: ReportSourceDocumentType.Inspections,
      });
      setOrgName('');
      setNkitwName('');
      setShowNewAlert(false);
    };

    const handleSearch = () => {
      if (
        formData.dateFrom &&
        formData.dateTo &&
        formData.dateFrom > formData.dateTo
      ) {
        setShowNewAlert(true);
        return;
      }
      setShowNewAlert(false);
      if (formData.organizationalUnitId === '') {
        formData.organizationalUnitId = undefined;
      }
      props.onSearch(formData);
    };

    const propertyChange = (obj: Partial<FilterDatesProductTypeReportData>) => {
      setFormData({ ...formData, ...obj });
    };

    return (
      <Card className="mb-2">
        <Card.Header>{props.reportName}</Card.Header>
        <Card.Body>
          <Form className="d-grid gap-3">
            <Row>
              <Form.Group as={Row} controlId="nkitw">
                <Form.Label column sm="3">
                  NKITW/TW/Opis
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    value={nkitwName}
                    onChange={e => {
                      setNkitwName(e.target.value || '');
                      propertyChange({ nkitw: e.target.value });
                    }}
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Row} controlId="producer">
                <Form.Label column sm="3">
                  Producent
                </Form.Label>
                <Col sm="9">
                  <Selector
                    value={orgName}
                    clearable
                    onChange={option => {
                      setOrgName(option.label || '');
                      propertyChange({ producerId: option.value });
                    }}
                    provider={snrwbApi.organizations.getOptionsByName}
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Row} controlId="productGroup">
                <Form.Label column sm="3">
                  Grupa
                </Form.Label>
                <Col sm="9">
                  <DictionarySelector
                    dictionary="typ wyrobu - grupa"
                    value={formData.groupProductTypeId}
                    maxDepth={2}
                    clearable
                    onChange={option =>
                      propertyChange({ groupProductTypeId: option.value })
                    }
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Row} controlId="sign">
                <Form.Label column sm="3">
                  Oznakowanie
                </Form.Label>
                <Col sm="9">
                  <DictionarySelector
                    dictionary="typ wyrobu - oznakowanie"
                    value={formData.signId}
                    maxDepth={2}
                    clearable
                    onChange={option => {
                      propertyChange({ signId: option.value });
                    }}
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Row} controlId="dateFrom">
                <Form.Label column sm="3">
                  Data rozpoczęcia od
                </Form.Label>
                <Col sm="9">
                  <DatePicker
                    value={formData.dateFrom}
                    midnight={true}
                    onChange={date =>
                      propertyChange({ dateFrom: date || undefined })
                    }
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Row} controlId="dateTo">
                <Form.Label column sm="3">
                  Data rozpoczęcia do
                </Form.Label>
                <Col sm="9">
                  <DatePicker
                    value={formData.dateTo}
                    midnight={true}
                    onChange={date =>
                      propertyChange({ dateTo: date || undefined })
                    }
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Row} controlId="organ">
                <Form.Label column sm="3">
                  Organ
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    className="form-select"
                    as="select"
                    value={formData.organizationalUnitId}
                    onChange={e =>
                      propertyChange({ organizationalUnitId: e.target.value })
                    }
                  >
                    <option value={''}>Wszystkie</option>
                    {organizationalUnits.map((o, idx) => (
                      <option value={o.id} key={idx}>
                        {o.shortname}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Row} controlId="chk-control">
                <Form.Label column sm="3">
                  Przeszukuj
                </Form.Label>
                <Col sm="9">
                  <Form.Check
                    id={'dok-type-kontrole'}
                    type="radio"
                    label="Kontrole"
                    value={ReportSourceDocumentType.Inspections}
                    checked={
                      formData.documentsType ===
                      ReportSourceDocumentType.Inspections
                    }
                    onChange={e =>
                      propertyChange({
                        documentsType: e.target
                          .value as ReportSourceDocumentType,
                      })
                    }
                  />
                  <Form.Check
                    id={'dok-type-badania'}
                    type="radio"
                    label="Badania"
                    value={ReportSourceDocumentType.Exams}
                    checked={
                      formData.documentsType === ReportSourceDocumentType.Exams
                    }
                    onChange={e =>
                      propertyChange({
                        documentsType: e.target
                          .value as ReportSourceDocumentType,
                      })
                    }
                  />
                  <Form.Check
                    id={'dok-type-postepowania'}
                    type="radio"
                    label="Postępowania"
                    value={ReportSourceDocumentType.Proceedings}
                    checked={
                      formData.documentsType ===
                      ReportSourceDocumentType.Proceedings
                    }
                    onChange={e =>
                      propertyChange({
                        documentsType: e.target
                          .value as ReportSourceDocumentType,
                      })
                    }
                  />
                </Col>
              </Form.Group>
            </Row>
          </Form>

          {showNewAlert && (
            <Alert variant="danger" className="mt-3">
              Proszę podać prawidłowy zakres dat.
            </Alert>
          )}

          <div className="d-flex justify-content-end mt-3">
            <Button
              className="me-2"
              variant="outline-dark"
              onClick={handleClear}
            >
              Wyczyść
            </Button>
            <Button variant="outline-primary" onClick={handleSearch}>
              Generuj XLSX
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  };
