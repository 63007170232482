import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import { Button, Card } from 'react-bootstrap';

import {
  Organization,
  newOrganization,
} from '../../../../common/snrwbCore/contexts/OrganizationContext';
import {
  CustomsFeedbackDto,
  convert,
  newCustomsFeedback,
  validate,
} from '../../../../common/snrwbCore/contexts/CustomsFeedbackContext';
import { OrgDocumentsModal } from '../../OrgDocumentsModal/OrgDocumentsModal';
import { OrgFormCard } from '../../OrgDocumentsModal/OrgFormCard';
import { useAuth } from '../../../../common/hooks/useAuth';
import { useNotifications } from '../../../../common/hooks/useNotifications';
import { Role } from '../../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

import { CustFeedFormCard } from './CustFeedFormCard';

const getOrganization = (org?: Organization) => org || newOrganization();
const getCustomsFeedback = (cusFeed?: CustomsFeedbackDto) =>
  cusFeed || newCustomsFeedback();

interface OrgCustFeedActionProps {
  organization?: Organization;
  customsFeedback?: CustomsFeedbackDto;
  onCompleted: (
    organization: Organization,
    customsFeedback: CustomsFeedbackDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  onDelete: (() => void) | undefined;
  onClose?: () => void;
}

export const OrgCustFeedAction: React.FC<OrgCustFeedActionProps> = props => {
  const [show, setShow] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [organization, setOrganization] = useState<Organization>(
    getOrganization(props.organization),
  );
  const [producer, setProducer] = useState<string>();
  const [customsFeedback, setCustomsFeedback] = useState(
    getCustomsFeedback(props.customsFeedback),
  );
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [orgValid, setOrgValid] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const mountedRef = useRef(false);
  const auth = useAuth();
  const notifications = useNotifications();
  const editAuthorized = auth.check(Role.CustomsFeedbacksEdit);

  const createCustomsFeedback = () => {
    const dto =
      'id' in customsFeedback ? convert(customsFeedback) : customsFeedback;

    validate(dto).then(status => {
      if (!status.valid || (!props.organization && !orgValid)) {
        setShowFormErrors(true);
        setFormErrors(status.errors);
        return;
      }
      props
        .onCompleted(
          organization,
          customsFeedback,
          props.customsFeedback && 'id' in props.customsFeedback
            ? props.customsFeedback.id
            : undefined,
        )
        .then(saveStatus => {
          if (mountedRef.current) {
            if (!saveStatus.saved) {
              setSaveErrors(saveStatus.errors);
              return;
            }
            setShow(false);
            setAddNew(false);
            setCustomsFeedback(newCustomsFeedback());
          }
        });
    });
  };

  const onChangeCustomsFeedback = (customsFeedback: CustomsFeedbackDto) => {
    setCustomsFeedback(customsFeedback);
  };

  useEffect(() => {
    setOrganization(getOrganization(props.organization));
    setSaveErrors([]);
  }, [props.organization]);

  useEffect(() => {
    mountedRef.current = true;
    setProducer(undefined);
    setCustomsFeedback(getCustomsFeedback(props.customsFeedback));
    setShowFormErrors(false);
    setFormErrors([]);
    setSaveErrors([]);

    if (props.customsFeedback) {
      if ('producer' in props.customsFeedback) {
        setProducer(props.customsFeedback.producer.name);
      }
      setShow(true);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [props.customsFeedback]);

  const handleAddNew = () => {
    if (!editAuthorized) {
      notifications.unauthorized();
      return;
    }
    setOrganization(getOrganization(props.organization));
    setShowFormErrors(false);
    setOrgValid(false);
    setShow(true);
    setAddNew(true);
  };

  const clear = () => {
    setOrganization(newOrganization());
    setOrgValid(false);
  };

  const handleClose = () => {
    setShow(false);
    setAddNew(false);
    setOrganization(newOrganization());
    setCustomsFeedback(newCustomsFeedback());
    props.onClose && props.onClose();
  };

  const handleDelete = () => {
    setShow(false);
    if (props.onDelete) {
      props.onDelete();
    }
  };

  return (
    <>
      <Button variant="outline-primary" onClick={handleAddNew}>
        Nowa opinia celna
      </Button>

      <OrgDocumentsModal
        title={
          addNew ? 'Tworzenie nowej opinii celnej' : 'Edycja opinii celnej'
        }
        errors={saveErrors}
        onClose={handleClose}
        onCompleted={editAuthorized ? createCustomsFeedback : undefined}
        onDelete={!addNew && editAuthorized ? handleDelete : undefined}
        deleteRole={Role.CustomsFeedbacksRevoke}
        show={show}
        size={!props.organization ? 'large' : 'small'}
        saveBtnLabel={addNew ? 'Dodaj' : 'Zapisz'}
        deleteBtnText="Czy na pewno usunąć opinię celną?"
      >
        {!props.organization && (
          <Col className="col-lg-6 col-12 mb-1">
            <OrgFormCard
              organization={organization}
              onChange={setOrganization}
              onValidation={setOrgValid}
              onClear={clear}
              showErrors={!orgValid && showFormErrors}
            />
          </Col>
        )}

        <Col
          className={
            !props.organization
              ? 'col-lg-6 col-12 mb-1'
              : 'col-lg-12 col-12 mb-1'
          }
        >
          <Card>
            <Card.Header>Opinia celna</Card.Header>
            <Card.Body>
              <CustFeedFormCard
                customsFeedback={customsFeedback}
                editAuthorized={editAuthorized}
                producer={producer}
                onChange={onChangeCustomsFeedback}
                showErrors={showFormErrors}
                errors={formErrors}
              />
            </Card.Body>
          </Card>
        </Col>
      </OrgDocumentsModal>
    </>
  );
};
