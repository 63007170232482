import React from 'react';
import Badge from 'react-bootstrap/Badge';

import { GetMisstatementDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';

export default (props: { misstatement: GetMisstatementDto }) => {
  let shortname = '';
  let n = props.misstatement.variance;
  while (n) {
    if (n.shortname) {
      shortname = n.shortname + (shortname ? ' ' + shortname : '');
    }
    n = n.parent;
  }

  return (
    <div>
      {shortname && (
        <Badge bg="warning" text="dark">
          {shortname}
        </Badge>
      )}{' '}
      {props.misstatement.variance.id === 'be8a5a53-cedc-4108-b5ed-07a32cb97ef9'
        ? props.misstatement.other
        : props.misstatement.variance.value}
    </div>
  );
};
