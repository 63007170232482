/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SourceIdWithDateDto
 */
export interface SourceIdWithDateDto {
    /**
     * 
     * @type {string}
     * @memberof SourceIdWithDateDto
     */
    allSourceId: string;
    /**
     * 
     * @type {Date}
     * @memberof SourceIdWithDateDto
     */
    startDate: Date;
    /**
     * 
     * @type {string}
     * @memberof SourceIdWithDateDto
     */
    entityName: string;
}

export function SourceIdWithDateDtoFromJSON(json: any): SourceIdWithDateDto {
    return SourceIdWithDateDtoFromJSONTyped(json, false);
}

export function SourceIdWithDateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SourceIdWithDateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allSourceId': json['allSourceId'],
        'startDate': (new Date(json['startDate'])),
        'entityName': json['entityName'],
    };
}

export function SourceIdWithDateDtoToJSON(value?: SourceIdWithDateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allSourceId': value.allSourceId,
        'startDate': (value.startDate.toISOString()),
        'entityName': value.entityName,
    };
}


