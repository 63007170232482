/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateInspectionDto
 */
export interface CreateUpdateInspectionDto {
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectionDto
     */
    cpr: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    witnesses: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    findingsBeginning: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    findingsEnd: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateInspectionDto
     */
    endDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    notes: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    protocols: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    attachments: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    repairMethod: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    placeOfSignature: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateInspectionDto
     */
    signatureDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    refusalReason: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    cautionText: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateInspectionDto
     */
    cautionIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectionDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectionDto
     */
    metricId: string;
}

export function CreateUpdateInspectionDtoFromJSON(json: any): CreateUpdateInspectionDto {
    return CreateUpdateInspectionDtoFromJSONTyped(json, false);
}

export function CreateUpdateInspectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateInspectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cpr': json['cpr'],
        'witnesses': json['witnesses'],
        'findingsBeginning': json['findingsBeginning'],
        'findingsEnd': json['findingsEnd'],
        'endDate': !exists(json, 'endDate') ? undefined : (json['endDate'] === null ? null : new Date(json['endDate'])),
        'notes': json['notes'],
        'protocols': json['protocols'],
        'attachments': json['attachments'],
        'repairMethod': json['repairMethod'],
        'placeOfSignature': json['placeOfSignature'],
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (json['signatureDate'] === null ? null : new Date(json['signatureDate'])),
        'refusalReason': json['refusalReason'],
        'cautionText': json['cautionText'],
        'cautionIds': json['cautionIds'],
        'approved': json['approved'],
        'metricId': json['metricId'],
    };
}

export function CreateUpdateInspectionDtoToJSON(value?: CreateUpdateInspectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cpr': value.cpr,
        'witnesses': value.witnesses,
        'findingsBeginning': value.findingsBeginning,
        'findingsEnd': value.findingsEnd,
        'endDate': value.endDate === undefined ? undefined : (value.endDate === null ? null : value.endDate.toISOString()),
        'notes': value.notes,
        'protocols': value.protocols,
        'attachments': value.attachments,
        'repairMethod': value.repairMethod,
        'placeOfSignature': value.placeOfSignature,
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate === null ? null : value.signatureDate.toISOString()),
        'refusalReason': value.refusalReason,
        'cautionText': value.cautionText,
        'cautionIds': value.cautionIds,
        'approved': value.approved,
        'metricId': value.metricId,
    };
}


