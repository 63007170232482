import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import {
  GetSampleExamDto,
  CreateUpdateSampleExamDto,
  ValidationStatus,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import DictionarySelector from '../Selects/DictionarySelector';
import * as SampleExamContext from '../../../common/snrwbCore/contexts/SampleExamContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import ValidationAlert from '../../../app/components/ValidationAlert';
import ItemPresentation from '../../../app/components/ItemPresentation';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

export const SampleExamSigning: React.FC<{
  editAuthorized: boolean;
  value: GetSampleExamDto;
  onChange: (inspection: CreateUpdateSampleExamDto) => void;
  onSign: () => void;
  onRevertSign: () => void;
  mayRevertSigning: (id: string) => Promise<ValidationStatus>;
  readyToSign: (id: string) => Promise<ValidationStatus>;
}> = props => {
  const [sampleExam, setSampleExam] = useState(
    SampleExamContext.toCuDto(props.value),
  );
  const [presentedSampleExam, setPresentedSampleExam] = useState(sampleExam);
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    setSampleExam(SampleExamContext.toCuDto(props.value));
  }, [props.value]);
  useEffect(() => {
    setPresentedSampleExam(sampleExam);
  }, [sampleExam]);

  const presentation = SampleExamContext.signingPresent(props.value);
  const setProperty = (obj: Partial<GetSampleExamDto>) => {
    setPresentedSampleExam({ ...presentedSampleExam, ...obj });
  };
  const saveIfChanged = (obj: CreateUpdateSampleExamDto) => {
    if (!_.isEqual(sampleExam, obj)) {
      props.onChange(obj);
    }
  };
  const examChange = (obj: Partial<CreateUpdateSampleExamDto>) => {
    const dto = { ...sampleExam, ...obj };
    setSampleExam(dto);
    saveIfChanged(dto);
  };
  const presentedToSampleExam = () => {
    setSampleExam(presentedSampleExam);
    saveIfChanged(presentedSampleExam);
  };

  const validate = async () => {
    const check = props.value.approved
      ? props.mayRevertSigning
      : props.readyToSign;

    const status = await check(props.value.id);

    if (status.state === 'ok') {
      setShowErrors(false);
      return true;
    }

    setShowErrors(true);
    setErrors(status.errors.length > 0 ? status.errors : [status.message]);
    return false;
  };

  const emptyIfNull = (value: string | undefined | null) => value || '';

  return (
    <>
      {props.value.approved || !props.editAuthorized ? (
        <ItemPresentation item={presentation} className="pt-3" />
      ) : (
        <>
          <Form className="d-grid gap-3">
            <FormRow controlId="protocolNumber" label="Numer protokołu">
              <Form.Control
                type="text"
                value={emptyIfNull(presentedSampleExam.protocolNumber)}
                onChange={e => setProperty({ protocolNumber: e.target.value })}
                onBlur={presentedToSampleExam}
              />
            </FormRow>
            <FormRow controlId="repairMethod" label="Omówienie poprawek">
              <DynamicTextarea
                value={emptyIfNull(presentedSampleExam.repairMethod)}
                onChange={e => setProperty({ repairMethod: e.target.value })}
                onBlur={presentedToSampleExam}
              />
            </FormRow>
            <FormRow
              controlId="reasonNotTakingControlSample"
              label="Przyczyna niepobrania próbki kontrolnej"
            >
              <Form.Control
                type="text"
                value={emptyIfNull(
                  presentedSampleExam.reasonNotTakingControlSample,
                )}
                onChange={e =>
                  setProperty({ reasonNotTakingControlSample: e.target.value })
                }
                onBlur={presentedToSampleExam}
              />
            </FormRow>
            <FormRow
              controlId="protocolPrintModeId"
              label="Tryb sporządzenia protokołu"
            >
              <DictionarySelector
                dictionary="badanie próbek - tryb sporządzenia protokołu"
                value={presentedSampleExam.protocolPrintModeId}
                onChange={opt => examChange({ protocolPrintModeId: opt.value })}
              />
            </FormRow>
            <FormRow
              controlId="sampleSignatureDate"
              label="Data podpisania protokołu"
            >
              <DatePicker
                value={presentedSampleExam.sampleSignatureDate}
                onChange={date => examChange({ sampleSignatureDate: date })}
              />
            </FormRow>
            <FormRow controlId="refusalReason" label="Powód odmowy">
              <DynamicTextarea
                value={emptyIfNull(presentedSampleExam.refusalReason)}
                onChange={e => setProperty({ refusalReason: e.target.value })}
                onBlur={presentedToSampleExam}
              />
            </FormRow>
          </Form>
        </>
      )}
      <ValidationAlert show={showErrors} errors={errors} className="mt-3" />
      {props.editAuthorized && (
        <div className="d-flex justify-content-end mt-3">
          {props.value.approved ? (
            <ConfirmationButton
              variant="outline-danger"
              roleAnyOf={[Role.ExamsRevoke]}
              onValidation={validate}
              onOK={props.onRevertSign}
              confirmation="Czy na pewno cofnąć podpisanie?"
            >
              Cofnij
            </ConfirmationButton>
          ) : (
            <ConfirmationButton
              variant="outline-success"
              onValidation={validate}
              onOK={props.onSign}
              confirmation="Czy na pewno protokół został podpisany?"
            >
              Podpisz
            </ConfirmationButton>
          )}
        </div>
      )}
    </>
  );
};
