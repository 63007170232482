import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { CreateUpdateCertificate } from '../../components/Certificates/CreateUpdateCertificate';
import { CreateUpdateCustomsFeedback } from '../../components/CustomsFeedbacks/CreateUpdateCustomsFeedback';
import { OrganizationsSearch } from '../../components/Organizations/OrganizationSearch';

export const OrganizationsView: React.FC = () => {
  const snrwb = useSnrwbCore();

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="organizations" className="mb-3">
        <Tab eventKey="search" title="Podmioty" className="m-3">
          <OrganizationsSearch
            getData={snrwb.organizations.getAllByPortion}
            getCount={snrwb.organizations.getAllCount}
          />
          <div className="d-flex justify-content-start mt-3">
            <CreateUpdateCertificate />
            <div className="mx-1">
              <CreateUpdateCustomsFeedback />
            </div>
          </div>
        </Tab>
      </Tabs>
    </Card>
  );
};
