import React from 'react';

import { GetInspectionExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/';
import { Grid } from '../../../common/components/Grid/Grid';
import { useNavigation } from '../../../common/navigation';
import momencik from '../../../common/momencik';

import { InspectionLink } from './InspectionLink';
import { InspectionType } from './InspectionType';

interface MyInspectionsProps {
  data: GetInspectionExtendedDto[];
}

export const MyInspections: React.FC<MyInspectionsProps> = props => {
  const nav = useNavigation();
  const columns = [
    { header: '', property: 'type', noSorting: true },
    { header: 'Podmiot', property: 'organizationName' },
    { header: 'Miejsce', property: 'place' },
    { header: 'Data rozpoczęcia', property: 'startDate' },
    { header: 'Nr akt', property: 'fileNumber' },
    { header: '', property: 'link', className: 'text-center', noSorting: true },
  ];

  const data = props.data.map(inspection => {
    return {
      key: inspection.id,
      values: {
        type: <InspectionType inspection={inspection} />,
        organizationName: inspection.metric.organization.name,
        place: inspection.metric.inspectionPlace,
        startDate: momencik(inspection.metric.startDate),
        fileNumber: inspection.metric.fileNumber,
        link: <InspectionLink inspectionId={inspection.id} />,
      },
      orderByValues: {
        startDate: momencik(inspection.metric.startDate, ''),
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={false}
      initialOrderColumn="startDate"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={nav.inspection}
      emptyGridMessage="Brak aktualnie toczących się kontroli w tej jednostce organizacyjnej"
    />
  );
};
