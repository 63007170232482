import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { ReportJudgmentsCount } from '../../../components/ReportJudgment/ReportJudgmentsCount';
import { ReportJudgmentsCountType } from '../../../../common/types/ReportTypes';
import abbreviationLegend from '../../../../common/abbreviationLegend';

export const ReportJudgmentsCountView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportJudgmentsCountType>({
    details: {},
    sum: {},
  });

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportJudgmentsCount(false, filter).then(dataReports => {
      setData(dataReports);
    });
  };

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName="Wydane postanowienia dot. kontroli i kosztów badań"
      />
      <ReportJudgmentsCount
        data={data}
        reportName="Wydane postanowienia dotyczące kontroli i kosztów badań"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
        legend={abbreviationLegend('other')}
      />
    </>
  );
};
