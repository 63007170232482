import React, { useState, useEffect, useRef } from 'react';
import { Alert, Button, Card, Tab } from 'react-bootstrap';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { Kwzs } from '../../components/Kwzs/Kwzs';
import { useNavigation } from '../../../common/navigation';
import { GetNarrowedKwzDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetNarrowedKwzDto';
import { TabsHistory } from '../../../app/components/TabsHistory';

export const KwzsView: React.FC = () => {
  const nav = useNavigation();
  const snrwbApi = useSnrwbCore();

  const [myKwzs, setMyKwzs] = useState<GetNarrowedKwzDto[]>([]);
  const [forPublishKwzs, setForPublishKwzs] = useState<GetNarrowedKwzDto[]>([]);
  const [publishedKwzs, setPublishedKwzs] = useState<GetNarrowedKwzDto[]>([]);
  const [forRemoveKwzs, setForRemoveKwzs] = useState<GetNarrowedKwzDto[]>([]);
  const [allKwzs, setAllKwzs] = useState<GetNarrowedKwzDto[]>([]);
  const mountedRef = useRef(false);

  const safe = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  useEffect(() => {
    mountedRef.current = true;

    snrwbApi.kwzs.getPendingForUser().then(data => safe(() => setMyKwzs(data)));
    snrwbApi.kwzs
      .getAllReadyToPublish()
      .then(data => safe(() => setForPublishKwzs(data)));
    snrwbApi.kwzs
      .getAllPublished()
      .then(data => safe(() => setPublishedKwzs(data)));
    snrwbApi.kwzs
      .getAllRequestToRemovePublish()
      .then(data => safe(() => setForRemoveKwzs(data)));
    snrwbApi.kwzs.getAll().then(data => safe(() => setAllKwzs(data)));

    return () => {
      mountedRef.current = false;
    };
  }, [snrwbApi.kwzs]);

  return (
    <Card>
      <TabsHistory activeKey="main" id="kwzs" className="m-3">
        <Tab
          eventKey="main"
          title="Moje karty zakwestionowanych wyrobów"
          className="m-3"
        >
          <Kwzs data={myKwzs} showfilter={false} onRowClick={nav.kwz} />
          <Alert variant="dark">
            W celu utworzenia <strong>Karty zakwestionowanego wyrobu</strong>{' '}
            należy odszukać wyrób w{' '}
            <Button variant="outline-dark" onClick={nav.myProceedings}>
              Postępowaniach
            </Button>{' '}
            i wprowadzić decyzję dla danego kontrolowanego wyrobu.
          </Alert>
        </Tab>
        <Tab eventKey="forPublish" title="Gotowe do publikacji" className="m-3">
          <Kwzs data={forPublishKwzs} showfilter={true} onRowClick={nav.kwz} />
        </Tab>
        <Tab eventKey="published" title="Opublikowane" className="m-3">
          <Kwzs data={publishedKwzs} showfilter={true} onRowClick={nav.kwz} />
        </Tab>
        <Tab eventKey="forRemove" title="Do usunięcia" className="m-3">
          <Kwzs
            data={forRemoveKwzs}
            showfilter={false}
            onRowClick={nav.kwz}
            forRemove={true}
          />
        </Tab>
        <Tab eventKey="search" title="Wyszukiwanie" className="m-3">
          <Kwzs data={allKwzs} showfilter={true} onRowClick={nav.kwz} />
        </Tab>
      </TabsHistory>
    </Card>
  );
};
