import React from 'react';
import { Spinner } from 'react-bootstrap';

export const Waiting: React.FC = () => {
  return (
    <div className="loading">
      <Spinner animation="grow" variant="secondary" />
      <Spinner animation="grow" variant="dark" />
      <Spinner animation="grow" variant="secondary" />
    </div>
  );
};
