import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

export const CustomFeedbacksOrganizationLink = (props: {
  producerId: string;
}) => {
  const nav = useNavigation();

  return (
    <OverlayTrigger
      overlay={<Tooltip>Pokaż wszystkie opinie tego producenta</Tooltip>}
    >
      <Button
        onClick={() =>
          nav.organization(props.producerId, false, 'customsFeedbacks')
        }
        variant="outline-primary"
      >
        <Icon.ListUl />
      </Button>
    </OverlayTrigger>
  );
};
