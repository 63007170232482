/*eslint max-lines-per-function: ["error", 180]*/
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table } from 'react-bootstrap';

import momencik from '../../../common/momencik';
import { ReportPakAnalysisMoneyPenaltiesType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportYearData } from '../Reports/Filter';

type Props = {
  data: ReportPakAnalysisMoneyPenaltiesType[] | undefined;
  dataCSV: unknown[];
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportYearData;
};

export const ReportByPakAnalysisMoneyPenaltiesType: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = (data && data.length > 0) || false;

  const headers1 = [
    {
      key: 'entityName',
      label: 'Strona (podmiot)',
    },
    {
      key: 'productDescription',
      label: 'Wyrób',
    },
    {
      key: 'unitSymbol',
      label: 'Organ',
    },
  ];
  const headers2 = [
    {
      key: 'judgmentType',
      label: 'Podstawa',
    },
    {
      key: 'judgmentOrganizationalUnit',
      label: 'Organ',
    },
    {
      key: 'judgmentPublishDate',
      label: 'Data wydania',
    },
    {
      key: 'judgmentSign',
      label: 'Znak',
    },
    {
      key: 'judgmentAppeal',
      label: 'Odwołanie',
    },
    {
      key: 'judgmentAppealResult',
      label: 'Wynik odwołania',
    },
  ];

  const headers3 = [
    {
      key: 'penaltyAmount',
      label: 'Wysokość kary',
    },
  ];

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table
          id="table-to-export"
          className="table-with-fix-head"
          striped
          bordered
          hover
        >
          <thead className="text-center">
            <tr>
              {headers1.map(header => (
                <th rowSpan={2} key={uuidv4()}>
                  {header.label}
                </th>
              ))}
              <th colSpan={6}>Decyzja</th>
              {headers3.map(header => (
                <th rowSpan={2} key={uuidv4()}>
                  {header.label}
                </th>
              ))}
            </tr>
            <tr>
              {headers2.map(pub => (
                <th rowSpan={1} key={uuidv4()}>
                  {pub.label}
                </th>
              ))}
            </tr>
          </thead>
          {hasData && data && (
            <tbody>
              {data.map(pub => (
                <GetDataRow key={uuidv4()} pub={pub} />
              ))}
              <tr></tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

const GetDataRow: React.FC<{
  pub: ReportPakAnalysisMoneyPenaltiesType;
}> = ({ pub }) => {
  if (!pub) {
    return <React.Fragment key={uuidv4()}></React.Fragment>;
  }

  const judLength =
    pub.judgments?.length && pub.judgments?.length > 0
      ? pub.judgments?.length
      : 1;
  return (
    <React.Fragment key={uuidv4()}>
      <tr>
        <td rowSpan={judLength}>{pub.entityName}</td>
        <td rowSpan={judLength}>{pub.productDescription}</td>
        <td rowSpan={judLength}>{pub.unitSymbol}</td>
        {pub.judgments && (
          <>
            <td>{pub.judgments[0]?.judgmentType}</td>
            <td>{pub.judgments[0]?.judgmentOrganizationalUnit}</td>
            <td>
              {pub.judgments[0]?.judgmentPublishDate?.toString() !==
              'Invalid Date'
                ? momencik(pub.judgments[0]?.judgmentPublishDate)
                : ''}
            </td>
            <td>
              {pub.judgments[0]?.judgmentSign &&
                pub.judgments[0]?.judgementPakId && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      '/postepowania/pak/podglad/' +
                      pub.judgments[0].judgementPakId
                    }
                  >
                    {pub.judgments[0].judgmentSign}
                  </a>
                )}
            </td>
            <td>
              {pub.judgments[0]?.judgmentAppeal === true
                ? 'Tak'
                : pub.judgments[0]?.judgmentAppeal === false
                ? 'Nie'
                : ''}
            </td>
            <td>{pub.judgments[0]?.judgmentAppealResult}</td>
          </>
        )}
        <td rowSpan={judLength}>{pub.penaltyAmount}</td>
      </tr>
      {pub.judgments &&
        pub.judgments.map((pub, index) => (
          <React.Fragment key={uuidv4()}>
            {index > 0 && (
              <tr>
                <td>{pub.judgmentType}</td>
                <td>{pub.judgmentOrganizationalUnit}</td>
                <td>{momencik(pub.judgmentPublishDate)}</td>
                <td>
                  {pub.judgmentSign && pub.judgementPakId && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={'/postepowania/pak/podglad/' + pub.judgementPakId}
                    >
                      {pub.judgmentSign}
                    </a>
                  )}
                </td>
                <td>
                  {pub.judgmentAppeal === true
                    ? 'Tak'
                    : pub.judgmentAppeal === false
                    ? 'Nie'
                    : ''}
                </td>
                <td>{pub.judgmentAppealResult}</td>
              </tr>
            )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};
