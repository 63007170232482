import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

import {
  CreateUpdateEssentialFeatureDto,
  GetEssentialFeatureDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { SampleWithResult } from '../../../common/snrwbCore/contexts/SampleExamContext';
import { EssentialFeatureRow } from '../EssentialFeatures/EssentialFeatureRow';
import { EssentialFeatureModal } from '../EssentialFeatures/EssentialFeatureModal';

export const SampleExamFeatures = (props: {
  examApproved: boolean;
  editAuthorized: boolean;
  publishAuthorized: boolean;
  features: GetEssentialFeatureDto[];
  samples: SampleWithResult[];
  addFeature: (dto: CreateUpdateEssentialFeatureDto) => void;
  updateFeature: (id: string, dto: CreateUpdateEssentialFeatureDto) => void;
  deleteFeature: (id: string) => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [editedFeature, setEditedFeature] = useState<GetEssentialFeatureDto>();

  const save = (dto: CreateUpdateEssentialFeatureDto) => {
    setShowModal(false);

    if (!editedFeature) {
      props.addFeature(dto);
    } else {
      props.updateFeature(editedFeature.id, dto);
    }
  };

  useEffect(() => {
    if (!showModal) {
      setEditedFeature(undefined);
    }
  }, [showModal]);

  return (
    <>
      {props.features.length === 0 ? (
        <>
          <Alert variant="warning">
            Nie zdefiniowano jeszcze żadnej charakterystyki do badania.
          </Alert>
        </>
      ) : (
        <Table striped>
          <thead className="text-center">
            <tr>
              <th></th>
              <th className="col-2">Zasadnicza charakterystyka</th>
              <th className="col-2">Właściwość użytkowa</th>
              <th className="col-2">Metoda badania</th>
              <th className="col-1">Deklarowana wartość</th>
              <th className="col-1">Wynik próbki</th>
              <th className="col-1">Niepewność wyniku badania</th>
              <th></th>
              <th className="col-1">Wynik próbki kontrolnej</th>
              <th className="col-1">
                Niepewność wyniku badania próbki kontrolnej
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.features.map(essentialFeature => (
              <EssentialFeatureRow
                key={essentialFeature.id}
                readonly={!props.editAuthorized && !props.publishAuthorized}
                value={essentialFeature}
                outerUpdate={() => {
                  setEditedFeature(essentialFeature);
                  setShowModal(true);
                }}
              />
            ))}
          </tbody>
        </Table>
      )}
      {!props.examApproved && props.editAuthorized && (
        <div className="d-flex justify-content-end">
          <Button variant="outline-primary" onClick={() => setShowModal(true)}>
            Dodaj
          </Button>
        </div>
      )}
      <EssentialFeatureModal
        examApproved={props.examApproved}
        editAuthorized={props.editAuthorized}
        publishAuthorized={props.publishAuthorized}
        show={showModal}
        value={editedFeature}
        samples={props.samples}
        onHide={() => setShowModal(false)}
        onSave={save}
        onDelete={() => {
          if (editedFeature) {
            setShowModal(false);
            props.deleteFeature(editedFeature.id);
          }
        }}
      />
    </>
  );
};
