import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  GetProductTypeDto,
  GetSampleExamExtendedDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import nkitwDesc from '../../../common/nkitwDesc';

import { SampleExamsList } from './SampleExamsList';

export const SampleExamModalSelector = (props: {
  buttonText?: string;
  buttonVariant?: string;
  buttonClassName?: string;
  productType: GetProductTypeDto;
  onSelect: (sampleExamId: string) => void;
}) => {
  const snrwb = useSnrwbCore();
  const [showModal, setShowModal] = useState(false);
  const [exams, setExams] = useState<GetSampleExamExtendedDto[]>([]);

  useEffect(() => {
    let mounted = true;
    if (showModal) {
      snrwb.sampleExams
        .getNotInspectedForUser(props.productType.id)
        .then(sampleExams => {
          if (mounted) {
            setExams(sampleExams);
          }
        });
    }
    return () => {
      mounted = false;
    };
  }, [showModal, snrwb, props.productType.id]);

  return (
    <>
      <Button
        variant={props.buttonVariant || 'outline-primary'}
        className={props.buttonClassName}
        onClick={() => setShowModal(true)}
      >
        {props.buttonText || 'Szukaj badania'}
      </Button>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered={true}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Wybór badania</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SampleExamsList
            showFilter={true}
            sampleExams={exams}
            onSelect={props.onSelect}
            emptyGridMessage={`W tej jednostce organizacyjnej nie ma badań, które można przypisać do wyrobu o typie ${nkitwDesc(
              props.productType,
            )}.`}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setShowModal(false)}
          >
            Zamknij
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
