import React from 'react';
import * as rstm from 'react-simple-tree-menu';

import { DictionaryDeleteModal } from '../../../../snrwb/components/Dictionary/DictionaryDeleteModal';
import { DictionaryModal } from '../../../../snrwb/components/Dictionary/DictionaryModal';
import { OrganizationalUnitModal } from '../../../../snrwb/components/OrganizationalUnit/OrganizationalUnitModal';
import { useAuth } from '../../../hooks/useAuth';
import { useNotifications } from '../../../hooks/useNotifications';
import { Role } from '../../../snrwbCore/authorization/snrwbAuthorizationRoles';

interface TreeActionModalPropos {
  variantTree: string;
  variantOperation: 'delete' | 'edit' | 'add';
  item: rstm.TreeMenuItem;
  onAction: (id?: string) => void;
  show?: boolean;
  onClose?: () => void;
}

export const TreeActionModal: React.FC<TreeActionModalPropos> = ({
  variantTree,
  variantOperation,
  item,
  onAction,
  show,
  onClose,
}) => {
  const auth = useAuth();
  const notifications = useNotifications();

  if (!auth.check(Role.ConfigurationEdit)) {
    notifications.unauthorized();
    return <></>;
  }

  return (
    <>
      {variantTree === 'dictionary' && variantOperation === 'delete' && (
        <DictionaryDeleteModal
          item={item}
          onAction={onAction}
          show={show}
          onClose={onClose}
        />
      )}
      {variantTree === 'dictionary' && variantOperation !== 'delete' && (
        <DictionaryModal
          variant={variantOperation}
          item={item}
          onAction={onAction}
          show={show}
          onClose={onClose}
        />
      )}
      {variantTree === 'organizationalUnit' && (
        <OrganizationalUnitModal
          variant={variantOperation}
          item={item}
          onAction={onAction}
          show={show}
          onClose={onClose}
        />
      )}
    </>
  );
};
