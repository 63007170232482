/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUpdateCustomsFeedbackDto,
    CreateUpdateCustomsFeedbackDtoFromJSON,
    CreateUpdateCustomsFeedbackDtoFromJSONTyped,
    CreateUpdateCustomsFeedbackDtoToJSON,
    CreateUpdateOrganizationDto,
    CreateUpdateOrganizationDtoFromJSON,
    CreateUpdateOrganizationDtoFromJSONTyped,
    CreateUpdateOrganizationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUpdateCustomsFeedbackWithOrganizationDto
 */
export interface CreateUpdateCustomsFeedbackWithOrganizationDto {
    /**
     * 
     * @type {CreateUpdateCustomsFeedbackDto}
     * @memberof CreateUpdateCustomsFeedbackWithOrganizationDto
     */
    customsFeedback: CreateUpdateCustomsFeedbackDto;
    /**
     * 
     * @type {CreateUpdateOrganizationDto}
     * @memberof CreateUpdateCustomsFeedbackWithOrganizationDto
     */
    organization: CreateUpdateOrganizationDto;
}

export function CreateUpdateCustomsFeedbackWithOrganizationDtoFromJSON(json: any): CreateUpdateCustomsFeedbackWithOrganizationDto {
    return CreateUpdateCustomsFeedbackWithOrganizationDtoFromJSONTyped(json, false);
}

export function CreateUpdateCustomsFeedbackWithOrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateCustomsFeedbackWithOrganizationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customsFeedback': CreateUpdateCustomsFeedbackDtoFromJSON(json['customsFeedback']),
        'organization': CreateUpdateOrganizationDtoFromJSON(json['organization']),
    };
}

export function CreateUpdateCustomsFeedbackWithOrganizationDtoToJSON(value?: CreateUpdateCustomsFeedbackWithOrganizationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customsFeedback': CreateUpdateCustomsFeedbackDtoToJSON(value.customsFeedback),
        'organization': CreateUpdateOrganizationDtoToJSON(value.organization),
    };
}


