import React from 'react';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { GetSampleCollectExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

export const SampleCollectProducts: React.FC<{
  sampleCollect: GetSampleCollectExtendedDto;
}> = props => {
  if (!props.sampleCollect) {
    return <></>;
  }

  return (
    <>
      {props.sampleCollect.examsCount > 1 && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Liczba badań w ramach jednego pobrania</Tooltip>}
        >
          <Badge bg="info" className="me-1">
            {props.sampleCollect.examsCount}
          </Badge>
        </OverlayTrigger>
      )}
      {props.sampleCollect.products}
    </>
  );
};
