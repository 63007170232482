import React from 'react';

import { ReportJudgmentsCountType } from '../../../common/types/ReportTypes';

export const JudgDetails: React.FC<{
  data: ReportJudgmentsCountType | undefined;
  type: string[];
  par?: string;
}> = ({ data, type, par, children }) => {
  if (!data || Object.keys(data?.details).length <= 0) {
    return <></>;
  }

  return (
    <tr>
      <td>
        {type.length === 1 && (
          <>
            <b>{par ? par : type[0]}</b>
            <br />
          </>
        )}
        {children}
      </td>
      {Object.keys(data?.details)
        .sort()
        .map((key, idx) => (
          <React.Fragment key={idx}>
            <td className="numbers-column">
              {type.reduce(
                (sum, current) =>
                  sum + (data?.details?.[key]?.[current]?.all || 0),
                0,
              ) || 0}
            </td>
            <td className="numbers-column">
              {type.reduce(
                (sum, current) =>
                  sum + (data?.details?.[key]?.[current]?.cpr || 0),
                0,
              ) || 0}
            </td>
          </React.Fragment>
        ))}
      <td className="numbers-column">
        {type.reduce(
          (sum, current) => sum + (data?.sum?.[current]?.all || 0),
          0,
        ) || 0}
      </td>
      <td className="numbers-column">
        {type.reduce(
          (sum, current) => sum + (data?.sum?.[current]?.cpr || 0),
          0,
        ) || 0}
      </td>
    </tr>
  );
};
