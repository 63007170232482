import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  GetCertificateDto,
  GetTechAssessmentDto,
  GetCustomsFeedbackDto,
  GetObjectHistoryDto,
  GetOrganizationDto,
  GetOrganizationDtoFromJSON,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { EditOrganization } from '../../components/Organizations/EditOrganization';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useAuth } from '../../../common/hooks/useAuth';

interface ParamTypes {
  id: string;
  active?: string;
}

export const OrganizationView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const { id, active } = useParams<ParamTypes>();
  const mountedRef = useRef(false);
  const [organization, setOrganization] = useState<GetOrganizationDto>(
    GetOrganizationDtoFromJSON({}),
  );
  const [certificates, setCertificates] = useState<GetCertificateDto[]>([]);
  const [techAssessments, setTechAssessments] = useState<
    GetTechAssessmentDto[]
  >([]);
  const [customsFeedbacks, setCustomsFeedbacks] = useState<
    GetCustomsFeedbackDto[]
  >([]);
  const [objectHistory, setObjectHistory] = useState<GetObjectHistoryDto[]>([]);
  const auth = useAuth();

  const safeSet = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    if (!mountedRef.current) {
      return;
    }
    snrwb.organizations.getById(id).then(org => {
      safeSet(() => setOrganization(org));
      auth.check(Role.CertificatesView) &&
        snrwb.certificates.getByOrganization(org.id).then(cert => {
          safeSet(() => setCertificates(cert));
        });
      auth.check(Role.TechAssessmentsView) &&
        snrwb.techAssessments.getByOrganization(org.id).then(assessment => {
          safeSet(() => setTechAssessments(assessment));
        });
      auth.check(Role.CustomsFeedbacksView) &&
        snrwb.customsFeedbacks.getByOrganization(org.id).then(custFeed => {
          safeSet(() => setCustomsFeedbacks(custFeed));
        });
      snrwb.objectHistory
        .getHistory(org.id, 'GetOrganizationDto')
        .then(objectHistory => {
          safeSet(() => setObjectHistory(objectHistory));
        });
    });
  }, [snrwb, id, auth]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [id, snrwb.organizations, refresh]);

  return (
    <EditOrganization
      organization={organization}
      certificateData={certificates}
      techAssessmentData={techAssessments}
      customsFeedbackData={customsFeedbacks}
      objectHistoryData={objectHistory}
      handleRefresh={refresh}
      active={active}
    />
  );
};
