import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import {
  GetInspectedProductDto,
  GetPakDto,
  GetProceedingDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNavigation } from '../../../common/navigation';
import * as ProceedingContext from '../../../common/snrwbCore/contexts/ProceedingContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import AlertModal from '../../../app/components/AlertModal';

export const InspectedProductInProceeding: React.FC<{
  inspectedProduct: GetInspectedProductDto;
  proceeding?: GetProceedingDto;
  pak?: GetPakDto;
  readonly: boolean;
  addProceeding: () => void;
  addPak: () => void;
}> = props => {
  const nav = useNavigation();
  const [showProblem, setShowProblem] = useState(false);
  const presentation = props.proceeding
    ? ProceedingContext.detailsPresent(props.proceeding)
    : [];

  const checkMetric = async () => {
    const result =
      props.inspectedProduct?.inspection?.metric?.approved || false;
    if (!result) {
      setShowProblem(true);
    }
    return result;
  };

  return (
    <>
      {!props.proceeding ? (
        <Alert variant="success">
          <Alert.Heading>
            Na podstawie tego wyrobu nie utworzono postępowania
            administracyjnego.
          </Alert.Heading>
          <hr />
          {!props.readonly && (
            <div className="d-flex justify-content-end">
              <ConfirmationButton
                variant="outline-primary"
                confirmation="Czy na pewno wszcząć postępowanie administracyjne?"
                onValidation={checkMetric}
                onOK={() => props.addProceeding()}
              >
                Nowe postępowanie
              </ConfirmationButton>
            </div>
          )}
        </Alert>
      ) : (
        <>
          {props.proceeding.inspectedProductId === props.inspectedProduct.id ? (
            <ItemPresentation item={presentation} className="pt-3" />
          ) : (
            <Alert variant="dark">
              Kontrola tego wyrobu to kontrola sprawdzająca zlecona w ramach
              postępowania administracyjnego.
            </Alert>
          )}
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-primary"
              onClick={() => {
                if (props.proceeding) {
                  nav.proceeding(props.proceeding.id);
                }
              }}
            >
              Przejdź do postępowania
            </Button>
          </div>
        </>
      )}
      {!props.pak ? (
        <Alert variant="warning" className="mt-3">
          <Alert.Heading>
            Na podstawie tego wyrobu nie utworzono PAK.
          </Alert.Heading>
          <hr />
          {!props.readonly && (
            <div className="d-flex justify-content-end">
              <ConfirmationButton
                variant="outline-primary"
                roleAnyOf={[Role.PakEdit]}
                confirmation="Czy na pewno wszcząć postępowanie w sprawie nałożenia kary?"
                onValidation={checkMetric}
                onOK={() => props.addPak()}
              >
                Nowe PAK
              </ConfirmationButton>
            </div>
          )}
        </Alert>
      ) : (
        <Alert variant="warning" className="mt-3">
          <Alert.Heading>Na podstawie tego wyrobu utworzono PAK.</Alert.Heading>
          <div className="d-flex justify-content-end">
            <Button
              variant="outline-primary"
              onClick={() => {
                if (props.pak) {
                  nav.pak(props.pak.id);
                }
              }}
            >
              Przejdź do PAK
            </Button>
          </div>
        </Alert>
      )}
      <AlertModal
        show={showProblem}
        onHide={() => setShowProblem(false)}
        variant="danger"
      >
        Metryka kontroli musi być zatwierdzona.
      </AlertModal>
    </>
  );
};
