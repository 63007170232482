import React from 'react';
// eslint-disable-next-line import/named
import { Button, Modal, ModalProps, Form } from 'react-bootstrap';

import FormRow from './FormRow';
import { readImageAsDataUrl } from './RichTextEdit/ImageHelpers';

interface FilePickerModalProps extends ModalProps {
  accept?: string;
  onFile: (base64: string | ArrayBuffer | null) => void;
}

export default (props: FilePickerModalProps) => {
  const { accept, onFile, ...modalProps } = props;
  const loadFile = (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }
    const f: File = files[0];
    readImageAsDataUrl(f, base64 => onFile(base64));
  };

  return (
    <Modal {...modalProps} centered={true} backdrop="static">
      <Modal.Header>
        <Modal.Title>Plik do załadowania</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="file" label="Plik">
            <Form.Control
              type="file"
              accept={accept}
              onChange={event =>
                loadFile((event.currentTarget as HTMLInputElement).files)
              }
            />
          </FormRow>
        </Form>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={props.onHide} variant="outline-secondary">
            Zamknij
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
