/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetEmployeeDto,
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetThreadDto
 */
export interface GetThreadDto {
    /**
     * 
     * @type {string}
     * @memberof GetThreadDto
     */
    id: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetThreadDto
     */
    sender: GetEmployeeDto;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetThreadDto
     */
    recipient: GetEmployeeDto;
    /**
     * 
     * @type {object}
     * @memberof GetThreadDto
     */
    subject: object;
    /**
     * 
     * @type {Date}
     * @memberof GetThreadDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GetThreadDto
     */
    content: string;
    /**
     * 
     * @type {number}
     * @memberof GetThreadDto
     */
    messagesCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetThreadDto
     */
    newMessagesCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetThreadDto
     */
    participatedCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetThreadDto
     */
    last: boolean;
}

export function GetThreadDtoFromJSON(json: any): GetThreadDto {
    return GetThreadDtoFromJSONTyped(json, false);
}

export function GetThreadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetThreadDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sender': GetEmployeeDtoFromJSON(json['sender']),
        'recipient': GetEmployeeDtoFromJSON(json['recipient']),
        'subject': json['subject'],
        'date': (new Date(json['date'])),
        'content': json['content'],
        'messagesCount': json['messagesCount'],
        'newMessagesCount': json['newMessagesCount'],
        'participatedCount': json['participatedCount'],
        'last': json['last'],
    };
}

export function GetThreadDtoToJSON(value?: GetThreadDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sender': GetEmployeeDtoToJSON(value.sender),
        'recipient': GetEmployeeDtoToJSON(value.recipient),
        'subject': value.subject,
        'date': (value.date.toISOString()),
        'content': value.content,
        'messagesCount': value.messagesCount,
        'newMessagesCount': value.newMessagesCount,
        'participatedCount': value.participatedCount,
        'last': value.last,
    };
}


