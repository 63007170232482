import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import { Organization } from '../../../common/snrwbCore/contexts/OrganizationContext';
import { ProductType } from '../../../common/snrwbCore/contexts/ProductTypeContext';
import { OrganizationSelector } from '../Organizations/OrganizationSelector';

import { ProductTypeSelector } from './ProductTypeSelector';
import { ProductTypeLinkControl } from './ProductTypeLinkControl';

export default (props: {
  organization: Organization;
  showProducerValidation: boolean;
  productType: ProductType;
  showProductTypeValidation: boolean;
  sampleCollectId?: string;
  linkControl?: boolean;
  setOrganization: (organization: Organization) => void;
  isProducerValid: (status: boolean) => void;
  clearOrganization: () => void;
  selectProductType: (productType: ProductType) => void;
  selectProductInspectionId: (productInspectionId: string) => void;
  isProductTypeValid: (status: boolean) => void;
}) => {
  const [displayLinkControlForm, setDisplayLinkControlForm] = useState(false);
  const [isTypeSelectorClear, setIsTypeSelectorClear] = useState(true);

  const handleProductTypeSelectorClear = () => {
    setIsTypeSelectorClear(true);
    props.selectProductInspectionId('');
  };

  useEffect(() => {
    if (!props.linkControl) {
      return;
    }
    if (props.productType) {
      setIsTypeSelectorClear(false);
    }
  }, [props.linkControl, props.productType]);

  useEffect(() => {
    if (!props.linkControl || !props.sampleCollectId) {
      setDisplayLinkControlForm(false);
      return;
    }
    if ('id' in props.productType && !isTypeSelectorClear) {
      setDisplayLinkControlForm(true);
    } else {
      setDisplayLinkControlForm(false);
    }
  }, [
    props.linkControl,
    props.sampleCollectId,
    props.productType,
    isTypeSelectorClear,
  ]);

  return (
    <Row>
      <Col className="col-lg-6 col-12 mb-1">
        <Card>
          <Card.Header>Producent</Card.Header>
          <Card.Body>
            <OrganizationSelector
              onSelect={props.setOrganization}
              onValidation={props.isProducerValid}
              value={props.organization}
              showValidationErrors={props.showProducerValidation}
              onClear={props.clearOrganization}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col className="col-lg-6 col-12 mb-1">
        <Card>
          <Card.Header>Typ wyrobu</Card.Header>
          <Card.Body aria-disabled="true">
            <ProductTypeSelector
              onSelect={props.selectProductType}
              onValidation={props.isProductTypeValid}
              onClear={handleProductTypeSelectorClear}
              producer={props.organization}
              productType={props.productType}
              showValidationErrors={props.showProductTypeValidation}
            />
          </Card.Body>
        </Card>
        {displayLinkControlForm && (
          <Card className="mt-3">
            <Card.Header>Powiązanie próbki wyrobu z kontrolą</Card.Header>
            <Card.Body aria-disabled="true">
              <ProductTypeLinkControl
                onSelect={props.selectProductInspectionId}
                onValidation={props.isProductTypeValid}
                producer={props.organization}
                productType={props.productType}
                sampleCollectId={props.sampleCollectId}
                showValidationErrors={props.showProductTypeValidation}
              />
            </Card.Body>
          </Card>
        )}
      </Col>
    </Row>
  );
};
