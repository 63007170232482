import React from 'react';
import * as Icons from 'react-bootstrap-icons';

import oneliner from '../../../common/oneliner';
import {
  CreatePublicMemoDtoEnSubjectTypeEnum,
  GetInspectionDto,
  GetKwzDto,
  GetPakDto,
  GetProceedingDto,
  GetSampleCollectDto,
  GetSampleExamDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  subjectType,
  SubjectType,
} from '../../../common/snrwbCore/contexts/MemoContext';
import nkitwDesc from '../../../common/nkitwDesc';

export default (props: { subject: SubjectType; maxlen: number }) => {
  const type = subjectType(props.subject);

  const icon = () => {
    switch (type) {
      case CreatePublicMemoDtoEnSubjectTypeEnum.BadanieProbek:
        return <Icons.CardChecklist />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.PobranieProbek:
        return <Icons.Search />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.Kontrola:
        return <Icons.CardList />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.Postepowanie:
        return <Icons.Archive />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.Pak:
        return <Icons.ArchiveFill />;
      case CreatePublicMemoDtoEnSubjectTypeEnum.Kwz:
        return <Icons.ExclamationDiamond />;
    }
  };

  const identity = () => {
    switch (type) {
      case CreatePublicMemoDtoEnSubjectTypeEnum.BadanieProbek: {
        const exam = props.subject as GetSampleExamDto;
        return `Badanie próbek ${exam.fileNumber}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.PobranieProbek: {
        const collect = props.subject as GetSampleCollectDto;
        return `Pobranie próbek ${collect.fileNumber}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.Kontrola: {
        const inspection = props.subject as GetInspectionDto;
        return `Kontrola ${inspection.metric.fileNumber}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.Postepowanie: {
        const proceeding = props.subject as GetProceedingDto;
        const proceedingProduct = `${nkitwDesc(
          proceeding.inspectedProduct.productType,
        )}`;
        return `Postępowanie ws. ${proceedingProduct}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.Pak: {
        const pak = props.subject as GetPakDto;
        const pakProduct = `${nkitwDesc(pak.productType)}`;
        return `PAK ws.  ${pakProduct}`;
      }
      case CreatePublicMemoDtoEnSubjectTypeEnum.Kwz: {
        const kwz = props.subject as GetKwzDto;
        return `KWZ ${kwz.publicationNumber}`;
      }
    }
  };

  return (
    <div className="d-flex text-start align-items-center">
      {icon()}
      <span className="ms-1 flex-fill">
        {oneliner(identity(), props.maxlen)}
      </span>
    </div>
  );
};
