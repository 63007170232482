import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

export const TechAssessmentsOrganizationLink = (props: {
  producerId: string;
}) => {
  const nav = useNavigation();

  return (
    <OverlayTrigger
      overlay={
        <Tooltip>Pokaż wszystkie oceny techniczne tego wnioskodawcy</Tooltip>
      }
    >
      <Button
        onClick={() => nav.organization(props.producerId, false, 'assessments')}
        variant="outline-primary"
      >
        <Icon.ListUl />
      </Button>
    </OverlayTrigger>
  );
};
