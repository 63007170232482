/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateSampleCollectDto
 */
export interface CreateUpdateSampleCollectDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    fileNumber: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    collectBasisId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    collectBasisShort: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    collectPlace: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSampleCollectDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    examOrganizationalUnitId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    cautionText: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateSampleCollectDto
     */
    cautionIds: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateSampleCollectDto
     */
    signatureDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    placeOfSignature: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    refusalReason: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    attachments: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    protocols: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateSampleCollectDto
     */
    actionDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSampleCollectDto
     */
    witness: string;
}

export function CreateUpdateSampleCollectDtoFromJSON(json: any): CreateUpdateSampleCollectDto {
    return CreateUpdateSampleCollectDtoFromJSONTyped(json, false);
}

export function CreateUpdateSampleCollectDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateSampleCollectDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileNumber': json['fileNumber'],
        'collectBasisId': json['collectBasisId'],
        'collectBasisShort': json['collectBasisShort'],
        'collectPlace': json['collectPlace'],
        'approved': json['approved'],
        'organizationId': json['organizationId'],
        'examOrganizationalUnitId': json['examOrganizationalUnitId'],
        'cautionText': json['cautionText'],
        'cautionIds': json['cautionIds'],
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (json['signatureDate'] === null ? null : new Date(json['signatureDate'])),
        'placeOfSignature': json['placeOfSignature'],
        'refusalReason': json['refusalReason'],
        'attachments': json['attachments'],
        'protocols': json['protocols'],
        'actionDate': !exists(json, 'actionDate') ? undefined : (json['actionDate'] === null ? null : new Date(json['actionDate'])),
        'witness': json['witness'],
    };
}

export function CreateUpdateSampleCollectDtoToJSON(value?: CreateUpdateSampleCollectDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileNumber': value.fileNumber,
        'collectBasisId': value.collectBasisId,
        'collectBasisShort': value.collectBasisShort,
        'collectPlace': value.collectPlace,
        'approved': value.approved,
        'organizationId': value.organizationId,
        'examOrganizationalUnitId': value.examOrganizationalUnitId,
        'cautionText': value.cautionText,
        'cautionIds': value.cautionIds,
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate === null ? null : value.signatureDate.toISOString()),
        'placeOfSignature': value.placeOfSignature,
        'refusalReason': value.refusalReason,
        'attachments': value.attachments,
        'protocols': value.protocols,
        'actionDate': value.actionDate === undefined ? undefined : (value.actionDate === null ? null : value.actionDate.toISOString()),
        'witness': value.witness,
    };
}


