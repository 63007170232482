import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import momencik from '../../../common/momencik';
import { GetSampleExamExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import nkitwDesc from '../../../common/nkitwDesc';

import { SampleExamLink } from './SampleExamLink';
import { SampleExamType } from './SampleExamType';

export const SampleExamsList = (props: {
  sampleExams: GetSampleExamExtendedDto[];
  showFilter?: boolean;
  emptyGridMessage: string;
  onSelect: (sampleExamId: string) => void;
}) => {
  const columns = [
    { header: '', property: 'type', noSorting: true },
    { header: 'Numer akt', property: 'fileNumber' },
    { header: 'Numer', property: 'sysNumber' },
    { header: 'Podmiot', property: 'organizationName' },
    { header: 'Typ wyrobu', property: 'productTypeName' },
    { header: 'Data pobrania', property: 'collectDate' },
    { header: '', property: 'link', className: 'text-center', noSorting: true },
  ];

  const data = props.sampleExams.map(sampleExam => {
    return {
      key: sampleExam.id,
      values: {
        type: <SampleExamType exam={sampleExam} />,
        fileNumber: sampleExam.fileNumber,
        sysNumber: sampleExam.sysNumber,
        organizationName: sampleExam.sampleCollect?.organization?.name,
        productTypeName: `[${
          sampleExam.productType?.organization?.name
        }] ${nkitwDesc(sampleExam.productType)}`,
        collectDate: momencik(sampleExam?.collectDate),
        link: <SampleExamLink sampleExamId={sampleExam.id} />,
      },
      orderByValues: {
        sysNumber: String(sampleExam.sysNumber).padStart(10, '0'),
        collectDate: momencik(sampleExam?.collectDate, ''),
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={props.showFilter || false}
      initialOrderColumn="sysNumber"
      initialOrderDirection="DESC"
      availablePageSizes={[10, 20, 50]}
      onRowClick={props.onSelect}
      emptyGridMessage={props.emptyGridMessage}
    />
  );
};
