import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';

import {
  Organization,
  newOrganization,
} from '../../../../common/snrwbCore/contexts/OrganizationContext';
import {
  CertificateDto,
  convert,
  newCertificate,
  validate,
} from '../../../../common/snrwbCore/contexts/CertificateContext';
import { OrgDocumentsModal } from '../../OrgDocumentsModal/OrgDocumentsModal';
import { OrgFormCard } from '../../OrgDocumentsModal/OrgFormCard';
import { useAuth } from '../../../../common/hooks/useAuth';
import { useNotifications } from '../../../../common/hooks/useNotifications';
import { Role } from '../../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

import { CertFormCard } from './CertFormCard';

const getOrganization = (org?: Organization) => org || newOrganization();

interface CertificateFormModalProps {
  organization?: Organization;
  certificate?: CertificateDto;
  onCompleted: (
    organization: Organization,
    certificate: CertificateDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  onDelete: (() => void) | undefined;
  onClose?: () => void;
}

export const OrgCertAction: React.FC<CertificateFormModalProps> = props => {
  const [show, setShow] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [organization, setOrganization] = useState<Organization>(
    getOrganization(props.organization),
  );
  const [certificate, setCertificate] = useState(
    props.certificate ? props.certificate : newCertificate(),
  );
  const [producer, setProducer] = useState<string>();
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [orgValid, setOrgValid] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const mountedRef = useRef(false);
  const auth = useAuth();
  const notifications = useNotifications();
  const editAuthorized = auth.check(Role.CertificatesEdit);

  const createCertificate = () => {
    const dto = 'id' in certificate ? convert(certificate) : certificate;

    validate(dto).then(status => {
      if (!status.valid || (!props.organization && !orgValid)) {
        setShowFormErrors(true);
        setFormErrors(status.errors);
        return;
      }

      props
        .onCompleted(
          organization,
          certificate,
          props.certificate && 'id' in props.certificate
            ? props.certificate.id
            : undefined,
        )
        .then(saveStatus => {
          if (mountedRef.current) {
            if (!saveStatus.saved) {
              setSaveErrors(saveStatus.errors);
              return;
            }
            setShow(false);
            setAddNew(false);
            setCertificate(newCertificate());
          }
        });
    });
  };

  const onChangeCertificate = (certificate: CertificateDto) => {
    setCertificate(certificate);
  };

  useEffect(() => {
    setOrganization(getOrganization(props.organization));
    setSaveErrors([]);
  }, [props.organization]);

  useEffect(() => {
    mountedRef.current = true;
    setProducer(undefined);
    setCertificate(props.certificate ? props.certificate : newCertificate());
    setShowFormErrors(false);
    setFormErrors([]);
    setSaveErrors([]);

    if (props.certificate) {
      if ('producer' in props.certificate) {
        setProducer(props.certificate.producer.name);
      }
      setShow(true);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [props.certificate]);

  const handleAddNew = () => {
    if (!editAuthorized) {
      notifications.unauthorized();
      return;
    }
    setOrganization(getOrganization(props.organization));
    setCertificate(newCertificate());
    setShowFormErrors(false);
    setShow(true);
    setAddNew(true);
  };

  const clear = () => {
    setOrganization(newOrganization());
    setOrgValid(false);
  };

  const handleClose = () => {
    setShow(false);
    setAddNew(false);
    setOrganization(newOrganization());
    setCertificate(newCertificate());
    props.onClose && props.onClose();
  };

  const handleDelete = () => {
    setShow(false);
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const variant = (condition: boolean, iftrue: string, iffalse: string) =>
    condition ? iftrue : iffalse;

  return (
    <>
      <Button variant="outline-primary" onClick={handleAddNew}>
        Nowy certyfikat
      </Button>

      <OrgDocumentsModal
        title={variant(
          addNew,
          'Tworzenie nowego certyfikatu',
          'Edycja certyfikatu',
        )}
        errors={saveErrors}
        onClose={handleClose}
        onCompleted={editAuthorized ? createCertificate : undefined}
        onDelete={!addNew && editAuthorized ? handleDelete : undefined}
        deleteRole={Role.CertificatesRevoke}
        show={show}
        size={!props.organization ? 'large' : 'small'}
        saveBtnLabel={variant(addNew, 'Dodaj', 'Zapisz')}
        deleteBtnText="Czy na pewno usunąć certyfikat?"
      >
        {!props.organization && (
          <Col className="col-lg-6 col-12 mb-1">
            <OrgFormCard
              organization={organization}
              onChange={setOrganization}
              onValidation={setOrgValid}
              onClear={clear}
              showErrors={!orgValid && showFormErrors}
            />
          </Col>
        )}

        <Col
          className={
            !props.organization
              ? 'col-lg-6 col-12 mb-1'
              : 'col-lg-12 col-12 mb-1'
          }
        >
          <CertFormCard
            certificate={certificate}
            editAuthorized={editAuthorized}
            producer={producer}
            onChange={onChangeCertificate}
            showErrors={showFormErrors}
            errors={formErrors}
          />
        </Col>
      </OrgDocumentsModal>
    </>
  );
};
