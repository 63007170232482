import { GetExamResultHistoryDto } from '../autogenerated/snrwbApiClient/models/GetExamResultHistoryDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateExamResultHistoryDto,
  CreateUpdateExamResultHistoryDtoFromJSON,
  GetLastApprovalDateDto,
} from '../autogenerated/snrwbApiClient/models';

export interface ExamResultHistoryContextInterface {
  create: (
    dto: CreateUpdateExamResultHistoryDto,
  ) => Promise<GetExamResultHistoryDto>;
  getBySample: (sampleId: string) => Promise<GetExamResultHistoryDto[]>;
  getLastApprovalDate: (
    examResultId: string,
  ) => Promise<GetLastApprovalDateDto>;
}

export const ExamResultHistoryContext = (api: DefaultApi) => {
  return {
    create: (dto: CreateUpdateExamResultHistoryDto) =>
      api.examResultHistoryControllerCreate(dto),
    getBySample: (sampleId: string) =>
      api.examResultHistoryControllerGetBySample(sampleId),
    getLastApprovalDate: (examResultId: string) =>
      api.examResultHistoryControllerGetLastApprovalDate(examResultId),
  };
};

export const toCuDto = (getDto: GetExamResultHistoryDto) => {
  const dto = CreateUpdateExamResultHistoryDtoFromJSON(getDto);
  return dto;
};
