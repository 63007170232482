import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { GetSampleCollectDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { SampleCollectApi } from '../../../common/snrwbCore/contexts/SampleCollectContext';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import * as SampleCollectContext from '../../../common/snrwbCore/contexts/SampleCollectContext';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';

export const SampleCollectButtons = (props: {
  sampleCollect: GetSampleCollectDto;
  api: SampleCollectApi;
}) => {
  const [showErrors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [alertHeading, setAlertHeading] = useState<string>();
  const [showSign, setShowSign] = useState(true);
  const snrwb = useSnrwbCore();

  useEffect(() => {
    snrwb.sampleCollects.setDuringInspectionDto().then(() => {
      setShowSign(
        props.sampleCollect?.collectBasis?.id !==
          SampleCollectContext.duringInspectionBasisId(),
      );
    });
  }, [props.sampleCollect?.collectBasis, snrwb.sampleCollects]);

  const mayBeDeleted = async () => {
    if (!props.sampleCollect.id) {
      return false;
    }

    const status = await props.api.mayDelete(props.sampleCollect.id);
    if (status.state === 'ok') {
      setShowErrors(false);
      return true;
    }

    setErrors(status.message ? [status.message] : status.errors);
    setShowErrors(true);
    setAlertHeading('Nie można usunąć tego pobrania!');
    return false;
  };

  useEffect(() => {
    setShowErrors(false);
    setErrors([]);
    setAlertHeading(undefined);
  }, [props.sampleCollect]);

  return (
    <>
      <ValidationAlert
        show={showErrors}
        errors={errors}
        modal={true}
        onHide={() => setShowErrors(false)}
        heading={alertHeading}
      />
      <div className="d-flex justify-content-end m-3">
        {!props.sampleCollect.approved && (
          <ConfirmationButton
            onOK={props.api.delete}
            onValidation={mayBeDeleted}
            variant="outline-danger"
            confirmation="Czy na pewno usunąć pobranie?"
            roleAnyOf={[Role.ExamsEdit]}
          >
            Usuń
          </ConfirmationButton>
        )}
        {showSign &&
          (props.sampleCollect?.approved ? (
            <Button
              onClick={props.api.generateProtocol}
              variant="outline-secondary"
              className="ms-1"
            >
              Protokół z czynności
            </Button>
          ) : (
            <ConfirmationButton
              onOK={props.api.generateProtocol}
              variant="outline-secondary"
              confirmation="Protokół z czynności nie jest jeszcze podpisany, czy na pewno generować wydruk?"
              className="ms-1"
            >
              Protokół z czynności
            </ConfirmationButton>
          ))}
      </div>
    </>
  );
};
