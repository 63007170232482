/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoFromJSONTyped,
    GetOrganizationDtoToJSON,
    GetPakDto,
    GetPakDtoFromJSON,
    GetPakDtoFromJSONTyped,
    GetPakDtoToJSON,
    GetProceedingDto,
    GetProceedingDtoFromJSON,
    GetProceedingDtoFromJSONTyped,
    GetProceedingDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetPartyToProceedingDto
 */
export interface GetPartyToProceedingDto {
    /**
     * 
     * @type {string}
     * @memberof GetPartyToProceedingDto
     */
    id: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetPartyToProceedingDto
     */
    organizationType: GetDictionaryDto;
    /**
     * 
     * @type {GetOrganizationDto}
     * @memberof GetPartyToProceedingDto
     */
    organization: GetOrganizationDto;
    /**
     * 
     * @type {GetProceedingDto}
     * @memberof GetPartyToProceedingDto
     */
    proceeding: GetProceedingDto;
    /**
     * 
     * @type {GetPakDto}
     * @memberof GetPartyToProceedingDto
     */
    pak: GetPakDto;
    /**
     * 
     * @type {number}
     * @memberof GetPartyToProceedingDto
     */
    orderNo: number;
}

export function GetPartyToProceedingDtoFromJSON(json: any): GetPartyToProceedingDto {
    return GetPartyToProceedingDtoFromJSONTyped(json, false);
}

export function GetPartyToProceedingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPartyToProceedingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organizationType': GetDictionaryDtoFromJSON(json['organizationType']),
        'organization': GetOrganizationDtoFromJSON(json['organization']),
        'proceeding': GetProceedingDtoFromJSON(json['proceeding']),
        'pak': GetPakDtoFromJSON(json['pak']),
        'orderNo': json['orderNo'],
    };
}

export function GetPartyToProceedingDtoToJSON(value?: GetPartyToProceedingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organizationType': GetDictionaryDtoToJSON(value.organizationType),
        'organization': GetOrganizationDtoToJSON(value.organization),
        'proceeding': GetProceedingDtoToJSON(value.proceeding),
        'pak': GetPakDtoToJSON(value.pak),
        'orderNo': value.orderNo,
    };
}


