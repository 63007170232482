import { GetKwzDto } from '../autogenerated/snrwbApiClient/models/GetKwzDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateKwzDto,
  CreateUpdateKwzDtoFromJSON,
} from '../autogenerated/snrwbApiClient/models/CreateUpdateKwzDto';
import { SnrwbCoreContextType } from '..';
import { NotificationsProviderType } from '../../notifications';
import { GetNarrowedKwzDto } from '../autogenerated/snrwbApiClient/models/GetNarrowedKwzDto';
import { GetKwzWithPartiesDto } from '../autogenerated/snrwbApiClient/models/GetKwzWithPartiesDto';
import { KwzSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import momencik from '../../momencik';

export interface KwzContextInterface {
  getByIdWithParties: (id: string) => Promise<GetKwzWithPartiesDto>;
  getPendingForUser: () => Promise<GetNarrowedKwzDto[]>;
  getAllReadyToPublish: () => Promise<GetNarrowedKwzDto[]>;
  getAllPublished: () => Promise<GetNarrowedKwzDto[]>;
  getAllRequestToRemovePublish: () => Promise<GetNarrowedKwzDto[]>;
  getWaitingForApproval: () => Promise<GetNarrowedKwzDto[]>;
  getAll: () => Promise<GetNarrowedKwzDto[]>;
  update: (id: string, dto: CreateUpdateKwzDto) => Promise<void>;
  createWithJudgment: (judgmentId: string) => Promise<GetKwzDto>;
  deleteWithJudgment: (judgmentId: string) => Promise<void>;
  approve: (id: string) => Promise<void>;
  revertApprove: (id: string) => Promise<void>;
  addRequestForWithdrawal: (id: string) => Promise<void>;
  removeRequestForWithdrawal: (id: string) => Promise<void>;
  approveRequestForWithdrawal: (id: string) => Promise<void>;
  revertRequestForWithdrawal: (id: string) => Promise<void>;
  publish: (id: string) => Promise<void>;
  revertPublish: (id: string) => Promise<void>;
  removePublish: (id: string) => Promise<void>;
  revertRemovePublish: (id: string) => Promise<void>;
}

export const KwzContext = (api: DefaultApi) => {
  return {
    getByIdWithParties: (id: string) => api.kwzControllerGetWithParties(id),
    getPendingForUser: () =>
      api
        .kwzControllerGetPendingForUser()
        .then((kwz: GetNarrowedKwzDto[]) =>
          kwz.sort((a, b) => a.publicationNumber - b.publicationNumber),
        ),
    getAllReadyToPublish: () =>
      api
        .kwzControllerGetAllReadyToPublish()
        .then((kwz: GetNarrowedKwzDto[]) =>
          kwz.sort((a, b) => a.publicationNumber - b.publicationNumber),
        ),
    getAllPublished: () =>
      api
        .kwzControllerGetAllPublished()
        .then((kwz: GetNarrowedKwzDto[]) =>
          kwz.sort((a, b) => a.publicationNumber - b.publicationNumber),
        ),
    getAllRequestToRemovePublish: () =>
      api
        .kwzControllerGetAllRequestToRemovePublish()
        .then((kwz: GetNarrowedKwzDto[]) =>
          kwz.sort((a, b) => a.publicationNumber - b.publicationNumber),
        ),
    getWaitingForApproval: () =>
      api
        .kwzControllerGetWaitingForApproval()
        .then((kwz: GetNarrowedKwzDto[]) =>
          kwz.sort((a, b) => a.publicationNumber - b.publicationNumber),
        ),
    getAll: () =>
      api
        .kwzControllerGetAllWithJudgment()
        .then((kwz: GetNarrowedKwzDto[]) =>
          kwz.sort((a, b) => a.publicationNumber - b.publicationNumber),
        ),
    update: (id: string, dto: CreateUpdateKwzDto) =>
      api.kwzControllerUpdate(id, dto),
    createWithJudgment: (judgmentId: string) =>
      api.kwzControllerCreateWithJudgment({ judgmentId: judgmentId }),
    deleteWithJudgment: (judgmentId: string) =>
      api.kwzControllerDeleteWithJudgment(judgmentId),
    approve: (id: string) => api.kwzControllerApprove(id),
    revertApprove: (id: string) => api.kwzControllerRevertApprove(id),
    addRequestForWithdrawal: (id: string) =>
      api.kwzControllerAddRequestForWithdrawal(id),
    removeRequestForWithdrawal: (id: string) =>
      api.kwzControllerRemoveRequestForWithdrawal(id),
    approveRequestForWithdrawal: (id: string) =>
      api.kwzControllerApproveRequestForWithdrawal(id),
    revertRequestForWithdrawal: (id: string) =>
      api.kwzControllerRevertRequestForWithdrawal(id),
    publish: (id: string) => api.kwzControllerPublish(id),
    revertPublish: (id: string) => api.kwzControllerRevertPublish(id),
    removePublish: (id: string) => api.kwzControllerRemovePublish(id),
    revertRemovePublish: (id: string) =>
      api.kwzControllerRevertRemovePublish(id),
  };
};

export const kwzData = (kwz?: GetKwzDto): CreateUpdateKwzDto => {
  if (!kwz) {
    return CreateUpdateKwzDtoFromJSON({});
  }
  const kwzCU = CreateUpdateKwzDtoFromJSON({
    ...kwz,
  });

  kwzCU.decisionSignId = kwz.decisionSign?.id;
  kwzCU.decisionIndexId = kwz.decisionIndex?.id;
  return kwzCU;
};

export const validate = (kwz: CreateUpdateKwzDto) =>
  validateAgainst(KwzSchema, kwz);

export const validateOnApprove = async (kwz: CreateUpdateKwzDto) => {
  const status = await validateAgainst(KwzSchema, { ...kwz, approved: true });
  //we have to simulate the approved KWZ made
  return status;
};

export interface KwzApi {
  editKwz: (id: string, kwz: CreateUpdateKwzDto) => void;
  deleteKwz: (judgmentId: string) => void;
  approveKwz: (id: string) => void;
  revertApproveKwz: (id: string) => void;
  addRequestForWithdrawal: (id: string) => void;
  removeRequestForWithdrawal: (id: string, actionOnSuccess: () => void) => void;
  approveRequestForWithdrawal: (id: string) => void;
  revertRequestForWithdrawal: (id: string) => void;
  publish: (id: string) => void;
  revertPublish: (id: string) => void;
  removePublish: (id: string) => void;
  revertRemovePublish: (id: string) => void;
  kwzPrintCard: (id: string) => void;
  refresh: () => void;
}

export const handlersForKwzDetailsView = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  refreshAction: () => void,
  navigationAction: () => void,
) => {
  return {
    editKwz: (id: string, kwz: CreateUpdateKwzDto) =>
      notifications.onPromise(snrwb.kwzs.update(id, kwz), refreshAction),
    deleteKwz: (judgmentId: string) =>
      notifications.onPromise(
        snrwb.kwzs.deleteWithJudgment(judgmentId),
        navigationAction,
      ),
    approveKwz: (id: string) =>
      notifications.onPromise(snrwb.kwzs.approve(id), refreshAction),
    revertApproveKwz: (id: string) =>
      notifications.onPromise(snrwb.kwzs.revertApprove(id), refreshAction),
    addRequestForWithdrawal: (id: string) =>
      notifications.onPromise(
        snrwb.kwzs.addRequestForWithdrawal(id),
        refreshAction,
      ),
    removeRequestForWithdrawal: (id: string, actionOnSuccess: () => void) =>
      notifications.onPromise(
        snrwb.kwzs.removeRequestForWithdrawal(id),
        () => {
          actionOnSuccess();
          refreshAction();
        },
        refreshAction,
      ),
    approveRequestForWithdrawal: (id: string) =>
      notifications.onPromise(
        snrwb.kwzs.approveRequestForWithdrawal(id),
        refreshAction,
      ),
    revertRequestForWithdrawal: (id: string) =>
      notifications.onPromise(
        snrwb.kwzs.revertRequestForWithdrawal(id),
        refreshAction,
      ),
    publish: (id: string) =>
      notifications.onPromise(snrwb.kwzs.publish(id), refreshAction),
    revertPublish: (id: string) =>
      notifications.onPromise(snrwb.kwzs.revertPublish(id), refreshAction),
    removePublish: (id: string) =>
      notifications.onPromise(snrwb.kwzs.removePublish(id), refreshAction),
    revertRemovePublish: (id: string) =>
      notifications.onPromise(
        snrwb.kwzs.revertRemovePublish(id),
        refreshAction,
      ),
    kwzPrintCard: (id: string) =>
      notifications.onPromise(snrwb.printouts.kwzCard(id)),
    refresh: refreshAction,
  };
};

export const calculateMomencikRemoveDate = (finalDate?: Date) => {
  let removeDate = 'Brak daty';
  if (finalDate) {
    const copyFinalDate = new Date(finalDate.getTime());
    removeDate = momencik(
      new Date(copyFinalDate.setMonth(copyFinalDate.getMonth() + 6)),
    );
  }
  return removeDate;
};
