import React from 'react';

import { useNavigation } from '../../../common/navigation';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';

export const ReportsOthersView: React.FC = () => {
  const nav = useNavigation();

  return (
    <>
      <ReportCardMenu
        title="Wydane postanowienia dot. kontroli i kosztów badań"
        text="(15) Liczba wydanych postanowień dotyczących kontroli wyrobów budowlanych oraz kosztów badań"
        footer="Kontrolowane wyroby, Badania próbek"
        cpr={true}
        onClick={nav.report15}
      />
      <ReportCardMenu
        title="Działania naprawcze, wykonanie nakazów"
        text="(18) Podejmowane działania naprawcze, wykonanie nakazów"
        footer="Kontrole, Postępowania"
        cpr={true}
        onClick={nav.report18}
      />
      <ReportCardMenu
        title="Współpraca z organami celnymi"
        text="(19) Współpraca z organami celnymi wg złożonych i wydanych opinii celnych"
        footer="Opinie celne"
        onClick={nav.report19}
      />
    </>
  );
};
