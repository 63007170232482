import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, Row, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { CustomsFeedbacks } from '../../components/CustomsFeedbacks/CustomsFeedbacks';
import { CreateUpdateCustomsFeedback } from '../../components/CustomsFeedbacks/CreateUpdateCustomsFeedback';
import { GetCustomsFeedbackDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { useNavigation } from '../../../common/navigation';

export const CustomsFeedbacksView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const nav = useNavigation();
  const [feedback, setFeedback] = useState<GetCustomsFeedbackDto>();
  const mountedRef = useRef(false);

  const handleClick = (key: string) => {
    snrwb.customsFeedbacks.getById(key).then(custFeed => {
      if (mountedRef.current) {
        setFeedback(custFeed);
      }
    });
  };

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  });

  // setting CustomsFeedbacks.data is a hack for grid rerender only
  return (
    <Card>
      <Tabs defaultActiveKey="main" id="customsFeedbacks">
        <Tab eventKey="main" title="Opinie celne" className="m-3">
          <CustomsFeedbacks
            getData={snrwb.customsFeedbacks.getAllByPortion}
            getCount={snrwb.customsFeedbacks.getAllCount}
            data={feedback && [feedback]}
            withOrganization={true}
            onClick={handleClick}
          />
          <Row xs={1} md={4} className="g-4">
            <Col>
              <CreateUpdateCustomsFeedback
                organization={feedback?.producer}
                customsFeedback={feedback}
                onAddNew={producerId =>
                  nav.organization(producerId, false, 'customsFeedbacks')
                }
                onChange={() => setFeedback(undefined)}
                onClose={() => setFeedback(undefined)}
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </Card>
  );
};
