import React, { useState, useEffect, ReactNode } from 'react';

import * as CertificateContext from '../../../common/snrwbCore/contexts/CertificateContext';
import { CreateUpdateCertificateDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import ItemPresentationHistory from '../../../app/components/ItemPresentationHistory';

interface ObjectHistoryDetailsForCertificateProps {
  value: CreateUpdateCertificateDto;
  prevValue: CreateUpdateCertificateDto;
}

export const ObjectHistoryDetailsForCertificate: React.FC<ObjectHistoryDetailsForCertificateProps> =
  props => {
    const [certificatePresentation, setCertificatePresentation] = useState<
      {
        name: string;
        value: ReactNode;
        prevValue: ReactNode;
        equal: boolean;
      }[]
    >();

    const snrwb = useSnrwbCore();

    useEffect(() => {
      if (props.value !== undefined) {
        CertificateContext.forPresentationHistory(
          snrwb,
          props.value,
          props.prevValue,
        ).then(presentInfo => {
          setCertificatePresentation(presentInfo);
        });
      }
    }, [props.prevValue, props.value, snrwb]);

    return (
      <>
        {certificatePresentation && (
          <ItemPresentationHistory item={certificatePresentation} />
        )}
      </>
    );
  };
