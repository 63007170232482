import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Skeleton from 'react-loading-skeleton';
import * as Icon from 'react-bootstrap-icons';

import {
  GetSampleExamDto,
  GetInspectedProductDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { useNavigation } from '../../../common/navigation';
import { AddInspection } from '../Inspections/AddInspection';
import { SampleExamApi } from '../../../common/snrwbCore/contexts/SampleExamContext';
import oneliner from '../../../common/oneliner';
import { SubjectMemos } from '../Memos/SubjectMemos';
import nkitwDesc from '../../../common/nkitwDesc';

export const SampleExamHeader = (props: {
  editAuthorized: boolean;
  sampleExam: GetSampleExamDto;
  inspectedProducts: GetInspectedProductDto[];
  api: SampleExamApi;
}) => {
  const [header, setHeader] = useState('Badanie próbek');
  const nav = useNavigation();

  useEffect(() => {
    const productDescription =
      props.sampleExam &&
      props.sampleExam.productType &&
      oneliner(nkitwDesc(props.sampleExam.productType), 60);
    setHeader(
      `Badanie próbki dla ${productDescription} nr ${props.sampleExam.sysNumber}`,
    );
  }, [props.sampleExam]);

  if (!props.sampleExam.id) {
    return <Skeleton className="mb-3" />;
  }

  return (
    <div className="mb-2">
      <div className="d-flex mb-2">
        <Alert variant="primary" className="pt-2 pb-2 mb-0 me-1 flex-fill">
          <Icon.Search /> {header}
        </Alert>
        <Alert
          variant={props.sampleExam?.approved ? 'success' : 'danger'}
          className="pt-2 pb-2 mb-0 me-1"
        >
          {props.sampleExam?.approved
            ? 'Podpisany protokół'
            : 'Protokół pobrania próbek nie został jeszcze podpisany'}
        </Alert>
        <div className="me-1">
          <SubjectMemos subject={props.sampleExam} />
        </div>
        {props.editAuthorized && (
          <AddInspection
            onCompleted={(organization, metric) =>
              props.api.addInspection(organization, metric, props.sampleExam.id)
            }
            inspectionPlace={props.sampleExam.sampleCollect.collectPlace}
            organization={props.sampleExam.sampleCollect.organization}
          />
        )}
      </div>
      <div className="d-flex justify-content-end flex-wrap">
        {props.inspectedProducts &&
          props.inspectedProducts.map(product => (
            <Button
              key={product.id}
              variant="outline-primary"
              onClick={() => nav.inspection(product.inspection.id)}
              className="ms-1 mb-1"
            >
              {`Kontrola nr ${product.inspection.metric.fileNumber}`}
            </Button>
          ))}
      </div>
    </div>
  );
};
