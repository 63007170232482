/**
 * Copy of https://github.com/icelab/draft-js-single-line-plugin
 * Typed by Codifive (JS -> TS)
 */
import { Map, List } from 'immutable';
import {
  ContentBlock,
  ContentState,
  DraftHandleValue,
  EditorState,
  genKey,
} from 'draft-js';

const NEWLINE_REGEX = /\n/g;

function replaceNewlines(str: string, replacement = ' ') {
  return str.replace(NEWLINE_REGEX, replacement);
}

function condenseBlocks(editorState: EditorState, blocks: ContentBlock[]) {
  blocks = blocks || editorState.getCurrentContent().getBlocksAsArray();
  let text = List();
  let characterList = List<ContentBlock>();

  blocks.forEach(block => {
    if (block.getType() !== 'atomic') {
      text = text.push(replaceNewlines(block.getText()));
      characterList = List(characterList.concat(block.getCharacterList()));
    }
  });

  const contentBlock = new ContentBlock({
    key: genKey(),
    text: text.join(''),
    type: 'unstyled',
    characterList: characterList,
    depth: 0,
  });

  const newContentState = ContentState.createFromBlockArray([contentBlock]);
  editorState = EditorState.push(editorState, newContentState, 'remove-range');
  return EditorState.moveFocusToEnd(editorState);
}

function singleLinePlugin() {
  return {
    blockRenderMap: Map({
      unstyled: {
        element: 'div',
      },
    }),

    onChange(editorState: EditorState) {
      const blocks = editorState.getCurrentContent().getBlocksAsArray();

      if (blocks.length > 1) {
        editorState = condenseBlocks(editorState, blocks);
      } else {
        let contentBlock = blocks[0];
        let text = contentBlock.getText();
        const characterList = contentBlock.getCharacterList();

        if (NEWLINE_REGEX.test(text)) {
          text = replaceNewlines(text);

          contentBlock = new ContentBlock({
            key: genKey(),
            text: text,
            type: 'unstyled',
            characterList: characterList,
            depth: 0,
          });

          const newContentState = ContentState.createFromBlockArray([
            contentBlock,
          ]);

          editorState = EditorState.push(
            editorState,
            newContentState,
            'insert-characters',
          );
        }
      }

      return editorState;
    },

    handleReturn(): DraftHandleValue {
      return 'handled';
    },
  };
}

export default singleLinePlugin;
