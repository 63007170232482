import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import {
  GetSampleExamDto,
  CreateUpdateSampleExamDtoFromJSON,
  CreateUpdateSampleExamDto,
  GetInspectorDto,
  CreateUpdateInspectorDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow from '../../../app/components/FormRow';
import * as SampleExamContext from '../../../common/snrwbCore/contexts/SampleExamContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import RichTextEdit from '../../../app/components/RichTextEdit';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { Inspectors } from '../Inspectors/Inspectors';

export const SampleExamForProtocol: React.FC<{
  editAuthorized: boolean;
  value: GetSampleExamDto;
  inspectors?: AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>;
  onChange: (sampleCollectDto: CreateUpdateSampleExamDto) => void;
}> = props => {
  const [sampleExam, setSampleExam] = useState(
    SampleExamContext.toCuDto(props.value),
  );
  const [presentedSampleExam, setPresentedSampleExam] = useState(sampleExam);

  useEffect(() => {
    setSampleExam(SampleExamContext.toCuDto(props.value));
  }, [props.value]);
  useEffect(() => {
    setPresentedSampleExam(sampleExam);
  }, [sampleExam]);

  const presentation = SampleExamContext.protocolPresent(props.value);
  const setProperty = (obj: Partial<GetSampleExamDto>) => {
    setPresentedSampleExam({ ...presentedSampleExam, ...obj });
  };
  const saveIfChanged = (obj: CreateUpdateSampleExamDto) => {
    if (!_.isEqual(sampleExam, obj)) {
      props.onChange(obj);
    }
  };
  const presentedToSampleExam = () => {
    setSampleExam(presentedSampleExam);
    saveIfChanged(presentedSampleExam);
  };
  const changeRichText = (obj: Partial<CreateUpdateSampleExamDto>) => {
    const dto = CreateUpdateSampleExamDtoFromJSON({
      ...SampleExamContext.toCuDto(props.value),
      ...obj,
    });

    if (dto.findings) {
      dto.findings = dto.findings.replaceAll('>\n', '>');
    }
    props.onChange(dto);
  };

  return (
    <>
      {props.value.approved || !props.editAuthorized ? (
        <ItemPresentation item={presentation} className="pt-3" />
      ) : (
        <Form className="d-grid gap-3">
          <FormRow controlId="findings" label="Ustalenia">
            <RichTextEdit
              className="narrow30"
              readonly={props.value.approved || !props.editAuthorized}
              value={props.value.findings}
              onChange={value => changeRichText({ findings: value })}
            />
          </FormRow>
          <FormRow
            controlId="reasonNotTakingControlSample"
            label="Przyczyna niepobrania próbki kontrolnej"
          >
            <Form.Control
              type="text"
              value={presentedSampleExam.reasonNotTakingControlSample || ''}
              onChange={e =>
                setProperty({ reasonNotTakingControlSample: e.target.value })
              }
              onBlur={presentedToSampleExam}
            />
          </FormRow>
          <FormRow controlId="protocolNumber" label="Numer protokołu">
            <Form.Control
              type="text"
              value={presentedSampleExam.protocolNumber || ''}
              onChange={e => setProperty({ protocolNumber: e.target.value })}
              onBlur={presentedToSampleExam}
            />
          </FormRow>
        </Form>
      )}
      {props.inspectors && (
        <Form className="d-grid mt-3">
          <FormRow controlId="inspectors" label="Kontrolerzy">
            <Inspectors
              inspectors={props.inspectors}
              readonly={props.value.approved || !props.editAuthorized}
            />
          </FormRow>
        </Form>
      )}
    </>
  );
};
