import React from 'react';

import { GetOrganizationDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

export const PartiesGridColumn: React.FC<{
  parties: GetOrganizationDto[];
}> = props => {
  if (!props.parties) {
    return <></>;
  }

  return <>{props.parties.map(organization => organization.name).join(', ')}</>;
};
