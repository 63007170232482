/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetAttachmentDto
 */
export interface GetAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    id: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetAttachmentDto
     */
    type: GetDictionaryDto;
    /**
     * 
     * @type {Date}
     * @memberof GetAttachmentDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    enObjectType: GetAttachmentDtoEnObjectTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetAttachmentDto
     */
    objectId: string;
}

export function GetAttachmentDtoFromJSON(json: any): GetAttachmentDto {
    return GetAttachmentDtoFromJSONTyped(json, false);
}

export function GetAttachmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAttachmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': GetDictionaryDtoFromJSON(json['type']),
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'description': json['description'],
        'name': json['name'],
        'enObjectType': json['enObjectType'],
        'objectId': json['objectId'],
    };
}

export function GetAttachmentDtoToJSON(value?: GetAttachmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': GetDictionaryDtoToJSON(value.type),
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'description': value.description,
        'name': value.name,
        'enObjectType': value.enObjectType,
        'objectId': value.objectId,
    };
}

/**
* @export
* @enum {string}
*/
export enum GetAttachmentDtoEnObjectTypeEnum {
    KontrolowanyWyrob = 'kontrolowany_wyrob',
    ProtokolWTrakcieKontroli = 'protokol_w_trakcie_kontroli',
    PobranieProbek = 'pobranie_probek',
    Probka = 'probka',
    Kwz = 'kwz',
    WynikBadania = 'wynik_badania',
    Orzeczenie = 'orzeczenie',
    Postepowanie = 'postepowanie',
    PostepowanieKarne = 'postepowanie_karne',
    OcenaTechniczna = 'ocena_techniczna'
}


