import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import momencik from '../../../common/momencik';
import { GetCertificateDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';

import { CertificatesOrganizationLink } from './CertificatesOrganizationLink';

export enum CertificatesVariant {
  full,
  forNotifiedUnit,
  withoutOrganization,
}

interface CertificatesProps {
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  data?: GetCertificateDto[];
  variant: CertificatesVariant;
  onClick?: (key: string) => void;
}

export const certificateForGrid = (certificate: GetCertificateDto) => {
  return {
    key: certificate.id,
    values: {
      'information.value': certificate.information?.value,
      'certificate.date': momencik(certificate.date),
      'certificate.number': certificate.number,
      'organizationalUnit.name': (
        <OrganizationalUnitBadge
          organizationalUnit={certificate.organizationalUnit}
        />
      ),
      organizationalUnitName: certificate.organizationalUnit.name,
      'groupProductType.value': certificate.groupProductType?.value,
      'producer.name': certificate.producer?.name,
      'producer.link': (
        <CertificatesOrganizationLink producerId={certificate.producer.id} />
      ),
    },
    orderByValues: {
      'certificate.date': momencik(certificate.date, ''),
    },
  };
};

export const Certificates: React.FC<CertificatesProps> = props => {
  let columns = undefined;
  if (props.variant === CertificatesVariant.full) {
    columns = [
      { header: 'Producent', property: 'producer.name' },
      {
        header: 'Jednostka notyfikowana',
        property: 'organizationalUnit.name',
      },
      { header: 'Numer', property: 'certificate.number' },
      { header: 'Typ informacji', property: 'information.value' },
      { header: 'Data', property: 'certificate.date' },
      { header: 'Grupa', property: 'groupProductType.value' },
      { header: '', property: 'producer.link' },
    ];
  } else if (props.variant === CertificatesVariant.forNotifiedUnit) {
    columns = [
      { header: 'Producent', property: 'producer.name' },
      { header: 'Numer', property: 'certificate.number' },
      { header: 'Typ informacji', property: 'information.value' },
      { header: 'Data', property: 'certificate.date' },
      { header: 'Grupa', property: 'groupProductType.value' },
    ];
  } else {
    columns = [
      {
        header: 'Jednostka notyfikowana',
        property: 'organizationalUnit.name',
        noSorting: true,
      },
      { header: 'Numer', property: 'certificate.number' },
      { header: 'Typ informacji', property: 'information.value' },
      { header: 'Data', property: 'certificate.date' },
      { header: 'Grupa', property: 'groupProductType.value' },
    ];
  }

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      data={props.data && props.data.map(d => certificateForGrid(d))}
      columns={columns}
      pageSize={10}
      availablePageSizes={[10, 20, 50]}
      showFilter={true}
      initialOrderColumn="certificate.date"
      initialOrderDirection="DESC"
      onRowClick={props.onClick}
      emptyGridMessage={
        props.variant === CertificatesVariant.withoutOrganization
          ? 'Brak certyfikatów dla tego podmiotu'
          : 'Brak certyfikatów'
      }
    />
  );
};
