import React from 'react';

import { useNavigation } from '../../../common/navigation';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';

export const ReportsSampleView: React.FC = () => {
  const nav = useNavigation();

  return (
    <>
      <ReportCardMenu
        title="Badania próbek wyrobów budowlanych"
        text="(14a) Zlecone badania próbek wyrobów budowlanych - bez badania próbek kontrolnych"
        footer="Badania próbek"
        onClick={nav.report14a}
      />
      <ReportCardMenu
        title="Pobrane próbki kontrolne"
        text="(14c) Zlecone badania próbek wyrobów budowlanych - bez badania próbek kontrolnych"
        footer="Badania próbek"
        onClick={nav.report14c}
      />
      <ReportCardMenu
        title="Badania próbek wyrobów budowlanych CPR"
        text="(14b) Zlecone badania próbek wyrobów budowlanych CPR - bez badania próbek kontrolnych"
        footer="Badania próbek"
        cpr={true}
        onClick={nav.report14b}
      />
      <ReportCardMenu
        title="Budżet, koszty"
        text="(22) Koszty badania próbek zleconych"
        footer="Badania próbek"
        onClick={nav.report22}
      />
      <ReportCardMenu
        title="Opublikowane próbki"
        text="Opublikowane próbki"
        footer="Badania próbek"
        onClick={nav.reportPublications}
      />
    </>
  );
};
