/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetEmployeeDto,
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetMemoDto
 */
export interface GetMemoDto {
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    threadId: string;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetMemoDto
     */
    sender: GetEmployeeDto;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetMemoDto
     */
    recipient: GetEmployeeDto;
    /**
     * 
     * @type {object}
     * @memberof GetMemoDto
     */
    subject: object;
    /**
     * 
     * @type {Date}
     * @memberof GetMemoDto
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof GetMemoDto
     */
    content: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMemoDto
     */
    unread: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMemoDto
     */
    sent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMemoDto
     */
    first: boolean;
}

export function GetMemoDtoFromJSON(json: any): GetMemoDto {
    return GetMemoDtoFromJSONTyped(json, false);
}

export function GetMemoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMemoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'threadId': json['threadId'],
        'sender': GetEmployeeDtoFromJSON(json['sender']),
        'recipient': GetEmployeeDtoFromJSON(json['recipient']),
        'subject': json['subject'],
        'date': (new Date(json['date'])),
        'content': json['content'],
        'unread': json['unread'],
        'sent': json['sent'],
        'first': json['first'],
    };
}

export function GetMemoDtoToJSON(value?: GetMemoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'threadId': value.threadId,
        'sender': GetEmployeeDtoToJSON(value.sender),
        'recipient': GetEmployeeDtoToJSON(value.recipient),
        'subject': value.subject,
        'date': (value.date.toISOString()),
        'content': value.content,
        'unread': value.unread,
        'sent': value.sent,
        'first': value.first,
    };
}


