import React from 'react';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

export const ProceedingLink: React.FC<{ proceedingId: string }> = props => {
  const nav = useNavigation();

  return (
    <span
      className="link-primary"
      role="button"
      tabIndex={0}
      onClick={() => nav.proceeding(props.proceedingId)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          nav.proceeding(props.proceedingId);
        }
      }}
    >
      <Icon.BoxArrowRight />
    </span>
  );
};
