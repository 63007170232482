/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetSampleExamDto,
    GetSampleExamDtoFromJSON,
    GetSampleExamDtoFromJSONTyped,
    GetSampleExamDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetSampleExamViewModel
 */
export interface GetSampleExamViewModel {
    /**
     * 
     * @type {GetSampleExamDto}
     * @memberof GetSampleExamViewModel
     */
    sampleExam: GetSampleExamDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetSampleExamViewModel
     */
    approvedExams: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleExamViewModel
     */
    samples: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleExamViewModel
     */
    inspectors: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleExamViewModel
     */
    attachments: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleExamViewModel
     */
    judgments: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleExamViewModel
     */
    essentialFeatures: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleExamViewModel
     */
    inspectedProducts: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSampleExamViewModel
     */
    paks: Array<string>;
}

export function GetSampleExamViewModelFromJSON(json: any): GetSampleExamViewModel {
    return GetSampleExamViewModelFromJSONTyped(json, false);
}

export function GetSampleExamViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSampleExamViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sampleExam': GetSampleExamDtoFromJSON(json['sampleExam']),
        'approvedExams': json['approvedExams'],
        'samples': json['samples'],
        'inspectors': json['inspectors'],
        'attachments': json['attachments'],
        'judgments': json['judgments'],
        'essentialFeatures': json['essentialFeatures'],
        'inspectedProducts': json['inspectedProducts'],
        'paks': json['paks'],
    };
}

export function GetSampleExamViewModelToJSON(value?: GetSampleExamViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sampleExam': GetSampleExamDtoToJSON(value.sampleExam),
        'approvedExams': value.approvedExams,
        'samples': value.samples,
        'inspectors': value.inspectors,
        'attachments': value.attachments,
        'judgments': value.judgments,
        'essentialFeatures': value.essentialFeatures,
        'inspectedProducts': value.inspectedProducts,
        'paks': value.paks,
    };
}


