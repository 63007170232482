/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateEmployeeDto
 */
export interface CreateUpdateEmployeeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeDto
     */
    position: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeDto
     */
    cardNo: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeDto
     */
    sub?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeDto
     */
    organizationalUnitId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateEmployeeDto
     */
    login?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateEmployeeDto
     */
    active: boolean;
}

export function CreateUpdateEmployeeDtoFromJSON(json: any): CreateUpdateEmployeeDto {
    return CreateUpdateEmployeeDtoFromJSONTyped(json, false);
}

export function CreateUpdateEmployeeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateEmployeeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'position': json['position'],
        'cardNo': json['cardNo'],
        'sub': !exists(json, 'sub') ? undefined : json['sub'],
        'organizationalUnitId': json['organizationalUnitId'],
        'login': !exists(json, 'login') ? undefined : json['login'],
        'active': json['active'],
    };
}

export function CreateUpdateEmployeeDtoToJSON(value?: CreateUpdateEmployeeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'position': value.position,
        'cardNo': value.cardNo,
        'sub': value.sub,
        'organizationalUnitId': value.organizationalUnitId,
        'login': value.login,
        'active': value.active,
    };
}


