import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card } from 'react-bootstrap';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  CreateUpdateInspectionPlanGroupDto,
  GetInspectionPlanGroupDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as InspectionPlanGroupContext from '../../../common/snrwbCore/contexts/InspectionPlanGroupContext';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useAuth } from '../../../common/hooks/useAuth';

import { AddEditInspectionPlanGroup } from './AddEditInspectionPlanGroup';
import { InspectionPlanGroupList } from './InspectionPlanGroupList';

interface InspectionPlanGroupDetailsProps {
  planId: string;
  readonly: boolean;
}

export const InspectionPlanGroupDetails: React.FC<InspectionPlanGroupDetailsProps> =
  props => {
    const snrwb = useSnrwbCore();
    const auth = useAuth();
    const notifications = useNotifications();

    const [inspectionPlanGroups, setInspectionPlanGroups] = useState<
      GetInspectionPlanGroupDto[]
    >([]);
    const [inspectionPlanGroup, setInspectionPlanGroup] =
      useState<GetInspectionPlanGroupDto>();
    const [show, setShow] = useState(false);
    const [showSaveErrors, setShowSaveErrors] = useState(false);
    const [saveErrors, setSaveErrors] = useState<string[]>([]);
    const [addNew, setAddNew] = useState(false);

    const refresh = useCallback(() => {
      snrwb.inspectionPlanGroups
        .getByPlanId(props.planId)
        .then(setInspectionPlanGroups);
    }, [props.planId, snrwb.inspectionPlanGroups]);

    useEffect(() => {
      setShowSaveErrors(false);
      setSaveErrors([]);
    }, [inspectionPlanGroups, inspectionPlanGroup]);

    useEffect(() => {
      refresh();
    }, [refresh]);

    const showEditGroupModal = async (id: string) => {
      const currentInspectionPlanGroup =
        await snrwb.inspectionPlanGroups.getById(id);
      setInspectionPlanGroup(currentInspectionPlanGroup);
      setAddNew(false);
      setShow(true);
    };

    const showAddGroupModal = () => {
      if (!auth.check(Role.InspectionsPlansEdit)) {
        notifications.unauthorized();
        return;
      }
      setInspectionPlanGroup(undefined);
      setAddNew(true);
      setShow(true);
    };

    const deleteInspectionPlanGroup = async (planGroupId: string) => {
      try {
        await snrwb.inspectionPlanGroups.delete(planGroupId);
        notifications.saveCompleted();
        refresh();
        setShow(false);
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    };

    const addEditInspectionPlanGroup = async (
      planGroupCU: CreateUpdateInspectionPlanGroupDto,
      id?: string,
    ) => {
      const status = await InspectionPlanGroupContext.validate(planGroupCU);
      if (status.valid) {
        try {
          if (id) {
            await snrwb.inspectionPlanGroups.update(id, planGroupCU);
            notifications.saveCompleted();
          } else {
            planGroupCU.inspectionPlanId = props.planId;
            await snrwb.inspectionPlanGroups.create(planGroupCU);
            notifications.saveCompleted();
          }
          refresh();
          setShow(false);
          return {
            saved: true,
            errors: [],
          };
        } catch (response) {
          return {
            saved: false,
            errors: await responseErrors(response as Response),
          };
        }
      } else {
        return {
          saved: false,
          errors: status.errors,
        };
      }
    };

    const closeAddEditModal = () => {
      setShow(false);
      setAddNew(false);
    };

    return (
      <>
        <Card>
          <Card.Header>
            Grupy wyrobów budowlanych, które mają być kontrolowane
          </Card.Header>
          <Card.Body>
            <InspectionPlanGroupList
              inspectionPlanGroups={inspectionPlanGroups}
              onClick={showEditGroupModal}
              readonly={props.readonly}
            />
            <AddEditInspectionPlanGroup
              inspectionPlanGroup={InspectionPlanGroupContext.inspectionPlanGroupData(
                inspectionPlanGroup,
              )}
              id={inspectionPlanGroup?.id}
              onCompleted={addEditInspectionPlanGroup}
              onDelete={deleteInspectionPlanGroup}
              show={show}
              addNew={addNew}
              handleClose={closeAddEditModal}
            />
            {!props.readonly && (
              <Button variant="outline-primary" onClick={showAddGroupModal}>
                Dodaj grupę wyrobów
              </Button>
            )}
            <ValidationAlert
              show={showSaveErrors}
              errors={saveErrors}
              className="mt-3"
            />
          </Card.Body>
        </Card>
      </>
    );
  };
