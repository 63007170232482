import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateInspectionPlanDto,
  GetOrganizationalUnitDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ValidationAlert from '../../../app/components/ValidationAlert';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';

interface InspectionPlanDetailsProps {
  plan: CreateUpdateInspectionPlanDto;
  planId: string;
  readonly: boolean;
  organizationalUnit: GetOrganizationalUnitDto | undefined;
  onCompleted: (plan: CreateUpdateInspectionPlanDto) => Promise<{
    saved: boolean;
    errors: string[];
    action?: () => void;
  }>;
}

export const InspectionPlanDetails: React.FC<InspectionPlanDetailsProps> =
  props => {
    const [plan, setPlan] = useState(props.plan);
    const [showSaveErrors, setShowSaveErrors] = useState(false);
    const [saveErrors, setSaveErrors] = useState<string[]>([]);

    useEffect(() => {
      setPlan(props.plan);
      setShowSaveErrors(false);
      setSaveErrors([]);
    }, [props.plan]);

    const propertyChange = (obj: Partial<CreateUpdateInspectionPlanDto>) => {
      const dto = { ...plan, ...obj };
      setPlan(dto);
    };

    const handleAddInspectionPlan = async () => {
      props.onCompleted(plan).then(saveStatus => {
        if (!saveStatus.saved) {
          setSaveErrors(saveStatus.errors);
          setShowSaveErrors(true);
          return;
        } else {
          if (saveStatus.action) {
            saveStatus.action();
          }
        }
      });
    };

    const isProperNumber = (value: number) => {
      return value === 0 || !(value === null || isNaN(value));
    };

    return (
      <>
        <Form className="d-grid gap-3">
          <FormRow controlId="organizationalUnit" label="Organ">
            {props.organizationalUnit && (
              <OrganizationalUnitBadge
                organizationalUnit={props.organizationalUnit}
              />
            )}
          </FormRow>
          <FormRow controlId="year" label="Plan kontroli na rok">
            <Form.Control
              type="number"
              value={plan?.year || ''}
              readOnly
              onChange={e => propertyChange({ year: parseInt(e.target.value) })}
            />
          </FormRow>
          <FormRow
            controlId="numberOfProducts"
            label="Liczba planowanych kontroli wyrobów ogółem"
          >
            <Form.Control
              type="text"
              value={
                isProperNumber(plan?.numberOfProducts)
                  ? plan?.numberOfProducts
                  : ''
              }
              readOnly={props.readonly}
              onChange={e =>
                propertyChange({
                  numberOfProducts: parseInt(e.target.value),
                })
              }
            />
          </FormRow>
          <FormRow
            controlId="numberOfProductsCpr"
            label="W tym kontroli wyrobów CPR"
          >
            <Form.Control
              type="text"
              value={
                isProperNumber(plan?.numberOfProductsCpr)
                  ? plan?.numberOfProductsCpr
                  : ''
              }
              readOnly={props.readonly}
              onChange={e =>
                propertyChange({
                  numberOfProductsCpr: parseInt(e.target.value),
                })
              }
            />
          </FormRow>
          <FormRow
            controlId="numberOfInspections"
            label="Liczba planowanych kontroli"
          >
            <Form.Control
              type="text"
              value={
                isProperNumber(plan?.numberOfInspections)
                  ? plan?.numberOfInspections
                  : ''
              }
              readOnly={props.readonly}
              onChange={e =>
                propertyChange({
                  numberOfInspections: parseInt(e.target.value),
                })
              }
            />
          </FormRow>
          <FormRow
            controlId="numberOfInspectionsCpr"
            label="W tym kontroli CPR"
          >
            <Form.Control
              type="text"
              value={
                isProperNumber(plan?.numberOfInspectionsCpr)
                  ? plan?.numberOfInspectionsCpr
                  : ''
              }
              readOnly={props.readonly}
              onChange={e =>
                propertyChange({
                  numberOfInspectionsCpr: parseInt(e.target.value),
                })
              }
            />
          </FormRow>
          <FormRow controlId="numberOfStaffs" label="Liczba etatów">
            <Form.Control
              type="text"
              value={
                isProperNumber(plan?.numberOfStaffs) ? plan?.numberOfStaffs : ''
              }
              readOnly={props.readonly}
              onChange={e =>
                propertyChange({
                  numberOfStaffs: parseInt(e.target.value),
                })
              }
            />
          </FormRow>
        </Form>
        <br />
        <br />
        {!props.readonly && (
          <Button variant="outline-primary" onClick={handleAddInspectionPlan}>
            Zapisz plan kontroli
          </Button>
        )}
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </>
    );
  };
