/*eslint max-lines-per-function: ["error", 500]*/
import React, { useState } from 'react';
import { Button, Collapse, Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import DatePicker from '../../../app/components/DatePicker';
import FormRow from '../../../app/components/FormRow';
import OrganizationalUnitSelector from '../../../snrwb/components/Selects/OrganizationalUnitSelector';

import { GridFilters } from './GridFilters';
import { GridFilterLabels } from './GridFilterLabels';
interface GridAdvancedFilterProps {
  filter: (obj: Partial<GridFilters>) => void;
  defaultAdditionalFilters: GridFilters;
  filterLabels?: GridFilterLabels;
  clearTextFilter: () => void;
}

export const GridAdvancedFilter: React.FC<GridAdvancedFilterProps> = props => {
  const [expanded, setExpanded] = useState(false);

  const [filterValues, setFilterValues] = useState<GridFilters>(
    props.defaultAdditionalFilters,
  );

  const handleCollapse = () => {
    if (!expanded) {
      props.clearTextFilter();
    }
    setExpanded(!expanded);
  };

  const submit = () => {
    props.filter(filterValues);
  };

  const setProperty = (obj: Partial<GridFilters>) => {
    setFilterValues({ ...filterValues, ...obj });
  };

  const clear = () => {
    setFilterValues(props.defaultAdditionalFilters);
    props.filter(props.defaultAdditionalFilters);
  };

  const filtersKeysArray = Object.keys(props.defaultAdditionalFilters);

  return (
    <Form.Group>
      <Button onClick={handleCollapse}>Filtrowanie</Button>
      <Collapse in={expanded}>
        <Container className="py-3" fluid>
          <Row className="py-1">
            {'organizationalUnit' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order:
                    1 +
                    filtersKeysArray.findIndex(
                      item => item === 'organizationalUnit',
                    ),
                }}
              >
                <FormRow controlId="organizationalUnit" label="Organ">
                  <OrganizationalUnitSelector
                    readOnly={false}
                    value={filterValues.organizationalUnit}
                    clearable={true}
                    onChange={option =>
                      setProperty({ organizationalUnit: option.value })
                    }
                  />
                </FormRow>
              </Col>
            )}
            {'organization' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order:
                    1 +
                    filtersKeysArray.findIndex(item => item === 'organization'),
                }}
              >
                <FormRow
                  controlId="organization"
                  label={
                    props.filterLabels && props.filterLabels.organization
                      ? props.filterLabels.organization
                      : 'Podmiot'
                  }
                >
                  <Form.Control
                    type="text"
                    value={filterValues.organization}
                    onChange={e =>
                      setProperty({ organization: e.target.value })
                    }
                  />
                </FormRow>
              </Col>
            )}
            {'inspectionPlace' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 5,
                }}
              >
                <FormRow controlId="inspectionPlace" label="Miejsce kontroli">
                  <Form.Control
                    type="text"
                    value={filterValues.inspectionPlace}
                    onChange={e =>
                      setProperty({ inspectionPlace: e.target.value })
                    }
                  />
                </FormRow>
              </Col>
            )}
            {'actionDateFrom' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 5,
                }}
              >
                <FormRow controlId="actionDateFrom" label="Data rozpoczęcia od">
                  <DatePicker
                    value={filterValues.actionDateFrom}
                    onChange={e => setProperty({ actionDateFrom: e })}
                  />
                </FormRow>
              </Col>
            )}
            {'actionDateTo' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 5,
                }}
              >
                <FormRow controlId="actionDateTo" label="Data rozpoczęcia do">
                  <DatePicker
                    value={filterValues.actionDateTo}
                    onChange={e => setProperty({ actionDateTo: e })}
                  />
                </FormRow>
              </Col>
            )}
            {'fileNumber' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order:
                    1 +
                    filtersKeysArray.findIndex(item => item === 'fileNumber'),
                }}
              >
                <FormRow controlId="fileNumber" label="Numer akt">
                  <Form.Control
                    type="text"
                    value={filterValues.fileNumber}
                    onChange={e => setProperty({ fileNumber: e.target.value })}
                  />
                </FormRow>
              </Col>
            )}
            {'productType' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 2,
                }}
              >
                <FormRow controlId="productType" label="Typ wyrobu">
                  <Form.Control
                    type="text"
                    value={filterValues.productType}
                    onChange={e => setProperty({ productType: e.target.value })}
                  />
                </FormRow>
              </Col>
            )}
            {'collectDateFrom' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 5,
                }}
              >
                <FormRow controlId="collectDateFrom" label="Data pobrania od">
                  <DatePicker
                    value={filterValues.collectDateFrom}
                    onChange={e => setProperty({ collectDateFrom: e })}
                  />
                </FormRow>
              </Col>
            )}
            {'collectDateTo' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 5,
                }}
              >
                <FormRow controlId="collectDateTo" label="Data pobrania do">
                  <DatePicker
                    value={filterValues.collectDateTo}
                    onChange={e => setProperty({ collectDateTo: e })}
                  />
                </FormRow>
              </Col>
            )}
            {'startDateFrom' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 5,
                }}
              >
                <FormRow controlId="startDateFrom" label="Data wszczęcia od">
                  <DatePicker
                    value={filterValues.startDateFrom}
                    onChange={e => setProperty({ startDateFrom: e })}
                  />
                </FormRow>
              </Col>
            )}
            {'startDateTo' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 5,
                }}
              >
                <FormRow controlId="startDateTo" label="Data wszczęcia do">
                  <DatePicker
                    value={filterValues.startDateTo}
                    onChange={e => setProperty({ startDateTo: e })}
                  />
                </FormRow>
              </Col>
            )}
            {'endDateFrom' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 5,
                }}
              >
                <FormRow controlId="endDateFrom" label="Data zakończenia od">
                  <DatePicker
                    value={filterValues.endDateFrom}
                    onChange={e => setProperty({ endDateFrom: e })}
                  />
                </FormRow>
              </Col>
            )}
            {'endDateTo' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 5,
                }}
              >
                <FormRow controlId="endDateTo" label="Data zakończenia do">
                  <DatePicker
                    value={filterValues.endDateTo}
                    onChange={e => setProperty({ endDateTo: e })}
                  />
                </FormRow>
              </Col>
            )}
            {'sysNumber' in filterValues && (
              <Col
                className="py-2"
                md={{
                  span: 6,
                  order: 5,
                }}
              >
                <FormRow controlId="sysNumber" label="Numer">
                  <Form.Control
                    type="text"
                    value={filterValues.sysNumber}
                    onChange={e => setProperty({ sysNumber: e.target.value })}
                  />
                </FormRow>
              </Col>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <Button
                className="mx-1"
                style={{ float: 'inline-end' }}
                onClick={submit}
              >
                Filtruj
              </Button>
              <Button
                variant="outline-dark"
                className="mx-1"
                style={{ float: 'inline-end' }}
                onClick={clear}
              >
                Wyczyść
              </Button>
            </Col>
          </Row>
        </Container>
      </Collapse>
    </Form.Group>
  );
};
