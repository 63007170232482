/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
    GetProceedingDto,
    GetProceedingDtoFromJSON,
    GetProceedingDtoFromJSONTyped,
    GetProceedingDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetJudgmentDto
 */
export interface GetJudgmentDto {
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    id: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetJudgmentDto
     */
    kind: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    information: string;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    kindOfSettlement: string;
    /**
     * 
     * @type {Date}
     * @memberof GetJudgmentDto
     */
    publishDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    key: string;
    /**
     * 
     * @type {Date}
     * @memberof GetJudgmentDto
     */
    finalDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetJudgmentDto
     */
    appeal: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    resultOfAppeal: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetJudgmentDto
     */
    approved: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetJudgmentDto
     */
    additionalOrders: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetJudgmentDto
     */
    additionalOrdersValues: Array<string>;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetJudgmentDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    pakId: string;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    proceedingId: string;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    sampleId: string;
    /**
     * 
     * @type {GetProceedingDto}
     * @memberof GetJudgmentDto
     */
    proceeding: GetProceedingDto;
    /**
     * 
     * @type {boolean}
     * @memberof GetJudgmentDto
     */
    shouldCreateKwz: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetJudgmentDto
     */
    kwzId: string;
}

export function GetJudgmentDtoFromJSON(json: any): GetJudgmentDto {
    return GetJudgmentDtoFromJSONTyped(json, false);
}

export function GetJudgmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetJudgmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'kind': GetDictionaryDtoFromJSON(json['kind']),
        'information': json['information'],
        'kindOfSettlement': json['kindOfSettlement'],
        'publishDate': !exists(json, 'publishDate') ? undefined : (new Date(json['publishDate'])),
        'key': json['key'],
        'finalDate': !exists(json, 'finalDate') ? undefined : (new Date(json['finalDate'])),
        'appeal': json['appeal'],
        'resultOfAppeal': json['resultOfAppeal'],
        'approved': json['approved'],
        'additionalOrders': json['additionalOrders'],
        'additionalOrdersValues': json['additionalOrdersValues'],
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
        'inspectedProductId': json['inspectedProductId'],
        'pakId': json['pakId'],
        'proceedingId': json['proceedingId'],
        'sampleId': json['sampleId'],
        'proceeding': GetProceedingDtoFromJSON(json['proceeding']),
        'shouldCreateKwz': json['shouldCreateKwz'],
        'kwzId': json['kwzId'],
    };
}

export function GetJudgmentDtoToJSON(value?: GetJudgmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'kind': GetDictionaryDtoToJSON(value.kind),
        'information': value.information,
        'kindOfSettlement': value.kindOfSettlement,
        'publishDate': value.publishDate === undefined ? undefined : (value.publishDate.toISOString()),
        'key': value.key,
        'finalDate': value.finalDate === undefined ? undefined : (value.finalDate.toISOString()),
        'appeal': value.appeal,
        'resultOfAppeal': value.resultOfAppeal,
        'approved': value.approved,
        'additionalOrders': value.additionalOrders,
        'additionalOrdersValues': value.additionalOrdersValues,
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
        'inspectedProductId': value.inspectedProductId,
        'pakId': value.pakId,
        'proceedingId': value.proceedingId,
        'sampleId': value.sampleId,
        'proceeding': GetProceedingDtoToJSON(value.proceeding),
        'shouldCreateKwz': value.shouldCreateKwz,
        'kwzId': value.kwzId,
    };
}


