/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUpdateInspectionMetricDto,
    CreateUpdateInspectionMetricDtoFromJSON,
    CreateUpdateInspectionMetricDtoFromJSONTyped,
    CreateUpdateInspectionMetricDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateInspectionBasedOnMetric
 */
export interface CreateInspectionBasedOnMetric {
    /**
     * 
     * @type {CreateUpdateInspectionMetricDto}
     * @memberof CreateInspectionBasedOnMetric
     */
    metric: CreateUpdateInspectionMetricDto;
    /**
     * 
     * @type {string}
     * @memberof CreateInspectionBasedOnMetric
     */
    sampleExamId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateInspectionBasedOnMetric
     */
    proceedingId?: string;
}

export function CreateInspectionBasedOnMetricFromJSON(json: any): CreateInspectionBasedOnMetric {
    return CreateInspectionBasedOnMetricFromJSONTyped(json, false);
}

export function CreateInspectionBasedOnMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateInspectionBasedOnMetric {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metric': CreateUpdateInspectionMetricDtoFromJSON(json['metric']),
        'sampleExamId': !exists(json, 'sampleExamId') ? undefined : json['sampleExamId'],
        'proceedingId': !exists(json, 'proceedingId') ? undefined : json['proceedingId'],
    };
}

export function CreateInspectionBasedOnMetricToJSON(value?: CreateInspectionBasedOnMetric | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metric': CreateUpdateInspectionMetricDtoToJSON(value.metric),
        'sampleExamId': value.sampleExamId,
        'proceedingId': value.proceedingId,
    };
}


