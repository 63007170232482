import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GetEmployeeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';

interface EmployeesProps {
  data: GetEmployeeDto[];
  onRowClick: (id: string) => void;
}

export const Employees: React.FC<EmployeesProps> = props => {
  const columns = [
    { header: 'Organ', property: 'organizationalUnit' },
    { header: 'Imię', property: 'firstName' },
    { header: 'Nazwisko', property: 'lastName' },
    { header: 'Nr legitymacji', property: 'cardNo' },
    { header: 'Stanowisko', property: 'position' },
  ];

  const data = props.data.map(employee => {
    return {
      key: employee.id,
      values: {
        organizationalUnit: (
          <OrganizationalUnitBadge
            organizationalUnit={employee.organizationalUnit}
          />
        ),
        firstName: employee.firstName,
        lastName: employee.lastName,
        cardNo: employee.cardNo,
        position: employee.position,
      },
      orderByValues: {
        organizationalUnit: employee.organizationalUnit.shortname,
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      availablePageSizes={[10, 20, 50]}
      showFilter={true}
      onRowClick={props.onRowClick}
    />
  );
};
