import React from 'react';

import { ValidationResult } from '../../../common/snrwbCore/validation/validateAgainst';

export interface AddAssociateModalProps<CreateUpdateDto> {
  show: boolean;
  newAssociate: () => CreateUpdateDto;
  validate: (dto: CreateUpdateDto) => Promise<ValidationResult>;
  onCreate: (dto: CreateUpdateDto, cache?: Record<string, string>) => void;
  onClose: () => void;
  limitToValues: string[];
}

export interface AssociateDocumentModalProps<GetDto, CreateUpdateDto> {
  document: GetDto;
  show: boolean;
  readonly?: boolean;
  onGet?: (doc: GetDto, method: string) => void;
  onApprove?: (id: string) => void;
  onRevertApprove?: (id: string) => void;
  onUpdate?: (id: string, cuDto: CreateUpdateDto) => void;
  onDelete: (id: string) => void;
  onClose: () => void;
}

export interface AssociateDocuments<GetDto, CreateUpdateDto> {
  documents: GetDto[];
  labelGenerator: (dto: GetDto) => React.ReactNode;
  new: () => CreateUpdateDto;
  validate: (cuDto: CreateUpdateDto) => Promise<ValidationResult>;
  add: (cuDto: CreateUpdateDto) => void;
  update?: (id: string, cuDto: CreateUpdateDto) => void;
  delete: (id: string) => void;
  get?: (dto: GetDto, method: string) => void;
  approve?: (id: string, cuDto?: CreateUpdateDto) => void;
  revertApprove?: (id: string) => void;
  filter: (
    filterFunc: (dto: GetDto) => boolean,
    createWrapper: (cuDto: CreateUpdateDto) => void,
  ) => AssociateDocuments<GetDto, CreateUpdateDto>;
}

interface AssociateDocumentsWithoutFilter<GetDto, CreateUpdateDto> {
  documents: GetDto[];
  labelGenerator: (dto: GetDto) => React.ReactNode;
  new: () => CreateUpdateDto;
  validate: (cuDto: CreateUpdateDto) => Promise<ValidationResult>;
  add: (cuDto: CreateUpdateDto) => void;
  update?: (id: string, cuDto: CreateUpdateDto) => void;
  delete: (id: string) => void;
  get?: (dto: GetDto, method: string) => void;
  approve?: (id: string, cuDto?: CreateUpdateDto) => void;
  revertApprove?: (id: string) => void;
}

export const AssociateDocumentsWithFilter = <GetDto, CreateUpdateDto>(
  associate: AssociateDocumentsWithoutFilter<GetDto, CreateUpdateDto>,
) => {
  const filterMethod = (
    filterFunc: (dto: GetDto) => boolean,
    createWrapper: (dto: CreateUpdateDto) => void,
  ) => {
    return {
      ...associate,
      documents: associate.documents.filter(filterFunc),
      new: () => {
        const dto = associate.new();
        createWrapper(dto);
        return dto;
      },
      add: (dto: CreateUpdateDto) => {
        createWrapper(dto);
        associate.add(dto);
      },
      filter: filterMethod,
    };
  };

  return {
    ...associate,
    filter: filterMethod,
  };
};
