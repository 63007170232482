import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { ReportInspectedProductsByRangeType } from '../../../../common/types/ReportTypes';
import { ReportInspectedProductsByRange } from '../../../components/ReportInspectedProducts/ReportInspectedProductsByRange';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import abbreviationLegend from '../../../../common/abbreviationLegend';

export const ReportInspectedProductsByRangeView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportInspectedProductsByRangeType>({
    details: {},
  });

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportProductsByRange(true, filter).then(dataReports => {
      setData(dataReports);
    });
  };

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName="Zakres przeprowadzonych kontroli"
      />
      <ReportInspectedProductsByRange
        data={data}
        reportName="Zakres przeprowadzonych kontroli"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
        legend={abbreviationLegend('inspectedProductsByRange')}
      />
    </>
  );
};
