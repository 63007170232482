/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
    GetOrganizationDto,
    GetOrganizationDtoFromJSON,
    GetOrganizationDtoFromJSONTyped,
    GetOrganizationDtoToJSON,
    GetOrganizationalUnitDto,
    GetOrganizationalUnitDtoFromJSON,
    GetOrganizationalUnitDtoFromJSONTyped,
    GetOrganizationalUnitDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetTechAssessmentDto
 */
export interface GetTechAssessmentDto {
    /**
     * 
     * @type {string}
     * @memberof GetTechAssessmentDto
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof GetTechAssessmentDto
     */
    date: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetTechAssessmentDto
     */
    preparationDate: Date;
    /**
     * 
     * @type {GetOrganizationalUnitDto}
     * @memberof GetTechAssessmentDto
     */
    organizationalUnit: GetOrganizationalUnitDto;
    /**
     * 
     * @type {string}
     * @memberof GetTechAssessmentDto
     */
    number: string;
    /**
     * 
     * @type {string}
     * @memberof GetTechAssessmentDto
     */
    productName: string;
    /**
     * 
     * @type {GetOrganizationDto}
     * @memberof GetTechAssessmentDto
     */
    producer: GetOrganizationDto;
    /**
     * 
     * @type {string}
     * @memberof GetTechAssessmentDto
     */
    productType: string;
    /**
     * 
     * @type {string}
     * @memberof GetTechAssessmentDto
     */
    productPurpose: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetTechAssessmentDto
     */
    groupProductType: GetDictionaryDto;
    /**
     * 
     * @type {Date}
     * @memberof GetTechAssessmentDto
     */
    expDateStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetTechAssessmentDto
     */
    expDateEnd: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetTechAssessmentDto
     */
    repealDate: Date | null;
    /**
     * 
     * @type {string}
     * @memberof GetTechAssessmentDto
     */
    note: string;
    /**
     * 
     * @type {string}
     * @memberof GetTechAssessmentDto
     */
    groupProductTypeOther: string;
}

export function GetTechAssessmentDtoFromJSON(json: any): GetTechAssessmentDto {
    return GetTechAssessmentDtoFromJSONTyped(json, false);
}

export function GetTechAssessmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTechAssessmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'preparationDate': (new Date(json['preparationDate'])),
        'organizationalUnit': GetOrganizationalUnitDtoFromJSON(json['organizationalUnit']),
        'number': json['number'],
        'productName': json['productName'],
        'producer': GetOrganizationDtoFromJSON(json['producer']),
        'productType': json['productType'],
        'productPurpose': json['productPurpose'],
        'groupProductType': GetDictionaryDtoFromJSON(json['groupProductType']),
        'expDateStart': (new Date(json['expDateStart'])),
        'expDateEnd': (new Date(json['expDateEnd'])),
        'repealDate': (json['repealDate'] === null ? null : new Date(json['repealDate'])),
        'note': json['note'],
        'groupProductTypeOther': json['groupProductTypeOther'],
    };
}

export function GetTechAssessmentDtoToJSON(value?: GetTechAssessmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString()),
        'preparationDate': (value.preparationDate.toISOString()),
        'organizationalUnit': GetOrganizationalUnitDtoToJSON(value.organizationalUnit),
        'number': value.number,
        'productName': value.productName,
        'producer': GetOrganizationDtoToJSON(value.producer),
        'productType': value.productType,
        'productPurpose': value.productPurpose,
        'groupProductType': GetDictionaryDtoToJSON(value.groupProductType),
        'expDateStart': (value.expDateStart.toISOString()),
        'expDateEnd': (value.expDateEnd.toISOString()),
        'repealDate': (value.repealDate === null ? null : value.repealDate.toISOString()),
        'note': value.note,
        'groupProductTypeOther': value.groupProductTypeOther,
    };
}


