import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';

import { useSnrwbCore } from './hooks/useSnrwbCore';
import { GetOrganizationalUnitDto } from './snrwbCore/autogenerated/snrwbApiClient';

export interface CookiesProviderType {
  organizationalUnit: GetOrganizationalUnitDto | undefined;
  setOrganizationalUnit: (unit: GetOrganizationalUnitDto) => void;
  activeContrast: string | undefined;
  setActiveContrast: (value: string) => void;
  soundEnabled: boolean;
  setSoundEnabled: (value: boolean) => void;
}

const emptyHandler = () => {
  // typing purpose
};

export const CookiesContext = React.createContext({
  organizationalUnit: undefined,
  setOrganizationalUnit: emptyHandler,
  activeContrast: undefined,
  setActiveContrast: emptyHandler,
  soundEnabled: true,
  setSoundEnabled: emptyHandler,
} as CookiesProviderType);

const OrganizationalUnit = 'organizational-unit-object';
const ActiveContrast = 'active-constrast';
const SoundEnabled = 'sound-enabled';

export const onLogout = () => {
  Cookies.remove(OrganizationalUnit);
  Cookies.remove(ActiveContrast);
  Cookies.remove(SoundEnabled);
};

export const CookiesProvider: React.FC = ({ children }) => {
  const snrwb = useSnrwbCore();
  const [cookiesState, setCookiesState] = useState(
    new Map<string, string | undefined>(),
  );
  const loadingRef = useRef(false);
  const rawOrganizationUnit = cookiesState.get(OrganizationalUnit);

  const rawset = (name: string, value: string) => {
    Cookies.set(name, value, {
      sameSite: 'None',
      secure: true,
      path: '/',
    });
  };

  const set = (name: string, value: string) => {
    rawset(name, value);
    const newState = new Map(cookiesState);
    newState.set(name, value);
    setCookiesState(newState);
  };

  useEffect(() => {
    if (loadingRef.current) {
      return;
    }
    const knownUnit = Cookies.get(OrganizationalUnit);

    const map = new Map<string, string | undefined>();
    map.set(OrganizationalUnit, knownUnit);
    map.set(ActiveContrast, Cookies.get(ActiveContrast));
    map.set(SoundEnabled, Cookies.get(SoundEnabled));

    if (!knownUnit) {
      loadingRef.current = true;
      snrwb.employees.getCurrent().then(employee => {
        if (employee.organizationalUnit) {
          const unit = JSON.stringify(employee.organizationalUnit);
          rawset(OrganizationalUnit, unit);
          map.set(OrganizationalUnit, unit);
          setCookiesState(map);
          snrwb.setOrganizationalUnitId(employee.organizationalUnit.id);
          loadingRef.current = false;
        }
      });
    } else {
      setCookiesState(map);
      snrwb.setOrganizationalUnitId(JSON.parse(knownUnit)['id']);
    }
  }, [snrwb]);

  return (
    <CookiesContext.Provider
      value={{
        organizationalUnit: rawOrganizationUnit
          ? (JSON.parse(rawOrganizationUnit) as GetOrganizationalUnitDto)
          : undefined,
        setOrganizationalUnit: (value: GetOrganizationalUnitDto) =>
          set(OrganizationalUnit, JSON.stringify(value)),
        activeContrast: cookiesState.get(ActiveContrast),
        setActiveContrast: (value: string) => set(ActiveContrast, value),
        soundEnabled: cookiesState.get(SoundEnabled) !== 'false',
        setSoundEnabled: (value: boolean) =>
          set(SoundEnabled, value.toString()),
      }}
    >
      {children}
    </CookiesContext.Provider>
  );
};
