import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { AssociateDocumentModalProps } from '../AssociatedDocuments/AssociateDocumentsTypes';
import ItemPresentation from '../../../app/components/ItemPresentation';
import * as InspectorContext from '../../../common/snrwbCore/contexts/InspectorContext';
import {
  CreateUpdateInspectorDto,
  GetInspectorDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

export const Inspector = (
  props: AssociateDocumentModalProps<GetInspectorDto, CreateUpdateInspectorDto>,
) => {
  if (!props.document) {
    return <></>;
  }

  const presentation = InspectorContext.toPresent(props.document);

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Kontroler</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ItemPresentation item={presentation} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        {!props.readonly && (
          <ConfirmationButton
            variant="outline-danger"
            roleAnyOf={[Role.InspectionsEdit, Role.ExamsEdit]}
            onOK={() => props.onDelete(props.document.id)}
            confirmation="Czy na pewno usunąć kontrolera?"
          >
            Usuń
          </ConfirmationButton>
        )}
      </Modal.Footer>
    </Modal>
  );
};
