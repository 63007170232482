import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import {
  Organization,
  newOrganization,
} from '../../../common/snrwbCore/contexts/OrganizationContext';
import {
  ProductType,
  newProductType,
  AddProductTypeFunc,
} from '../../../common/snrwbCore/contexts/ProductTypeContext';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useAuth } from '../../../common/hooks/useAuth';
import { useNotifications } from '../../../common/hooks/useNotifications';

import SelectProductType from './SelectProductType';

export const SelectProductTypeModal = (props: {
  title: string;
  buttonText: string;
  errors: string[];
  role?: Role;
  sampleCollectId?: string;
  linkControl?: boolean;
  add: AddProductTypeFunc;
}) => {
  const [show, setShow] = useState(false);
  const [organization, setOrganization] = useState<Organization>(
    newOrganization(),
  );
  const [productType, setProductType] = useState<ProductType>(newProductType());
  const [productInspectionId, setProductInspectionId] = useState<string>('');
  const [showProducerValidation, setShowProducerValidation] = useState(false);
  const [producerValid, setProducerValid] = useState(false);
  const [showProductTypeValidation, setShowProductTypeValidation] =
    useState(false);
  const [productTypeValid, setProductTypeValid] = useState(true);
  const [showErrors, setShowErrors] = useState(false);
  const auth = useAuth();
  const notifications = useNotifications();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (props.role && !auth.check(props.role)) {
      notifications.unauthorized();
      return;
    }
    setShow(true);
  };

  const isProducerValid = (status: boolean) => {
    setProducerValid(status);
    if (status) {
      setShowProducerValidation(false);
    }
  };

  const selectProductType = (productType: ProductType) => {
    setProductType(productType);
    if ('organization' in productType) {
      if (
        !('id' in organization) ||
        productType.organization.id !== organization.id
      ) {
        setOrganization(productType.organization);
      }
    }
  };

  const selectProductInspectionId = (productInspectionId: string) => {
    setProductInspectionId(productInspectionId);
  };

  const isProductTypeValid = (status: boolean) => {
    setProductTypeValid(status);
    if (status) {
      setShowProductTypeValidation(false);
    }
  };

  const addProduct = () => {
    if (!producerValid) {
      setShowProducerValidation(true);
      return;
    }
    if (!productTypeValid) {
      setShowProductTypeValidation(true);
      return;
    }
    props.add(organization, productType, productInspectionId).then(success => {
      if (success) {
        setShow(false);
      }
    });
  };

  const clear = () => {
    setOrganization(newOrganization());
    setProductType(newProductType());
    setShowProducerValidation(false);
    setProducerValid(false);
    setShowProductTypeValidation(false);
    setProductTypeValid(true);
    setShowErrors(false);
  };

  useEffect(() => {
    clear();
  }, [show]);

  useEffect(() => {
    setShowErrors(props.errors.length > 0);
  }, [props.errors]);

  return (
    <>
      <Button variant="outline-primary" onClick={handleShow}>
        {props.buttonText}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        centered={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectProductType
            organization={organization}
            showProducerValidation={showProducerValidation}
            productType={productType}
            showProductTypeValidation={showProductTypeValidation}
            sampleCollectId={props.sampleCollectId}
            linkControl={props.linkControl}
            setOrganization={setOrganization}
            isProducerValid={isProducerValid}
            clearOrganization={clear}
            selectProductType={selectProductType}
            selectProductInspectionId={selectProductInspectionId}
            isProductTypeValid={isProductTypeValid}
          />
          <ValidationAlert show={showErrors} errors={props.errors} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={addProduct}>
            Dodaj
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
