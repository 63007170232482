import {
  GetPartyToProceedingDto,
  CreateUpdatePartyToProceedingDto,
  CreateUpdatePartyToProceedingDtoFromJSON,
  CreateUpdateOrganizationDto,
  ValidationStatus,
} from '../autogenerated/snrwbApiClient';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';

export interface PartyToProceedingContextInterface {
  getById: (id: string) => Promise<GetPartyToProceedingDto>;
  getByProceeding: (proceedingId: string) => Promise<GetPartyToProceedingDto[]>;
  getByPak: (pakId: string) => Promise<GetPartyToProceedingDto[]>;
  create: (
    dto: CreateUpdatePartyToProceedingDto,
  ) => Promise<GetPartyToProceedingDto>;
  createWithOrganization: (
    organizationDto: CreateUpdateOrganizationDto,
    dto: CreateUpdatePartyToProceedingDto,
  ) => Promise<GetPartyToProceedingDto>;
  validateNew: (
    dto: CreateUpdatePartyToProceedingDto,
  ) => Promise<ValidationStatus>;
  update: (id: string, dto: CreateUpdatePartyToProceedingDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export const PartyToProceedingContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.partyToProceedingControllerGet(id),
    getByProceeding: async (proceedingId: string) => {
      const list = await api.partyToProceedingControllerGetByProceeding(
        proceedingId,
      );
      list.sort((ptp1, ptp2) => ptp1.orderNo - ptp2.orderNo);
      return list;
    },
    getByPak: async (pakId: string) => {
      const list = await api.partyToProceedingControllerGetByPak(pakId);
      list.sort((ptp1, ptp2) => ptp1.orderNo - ptp2.orderNo);
      return list;
    },
    create: (dto: CreateUpdatePartyToProceedingDto) =>
      api.partyToProceedingControllerCreate(dto),
    createWithOrganization: (
      organizationDto: CreateUpdateOrganizationDto,
      dto: CreateUpdatePartyToProceedingDto,
    ) =>
      api.partyToProceedingControllerCreateWithOrganization({
        organization: organizationDto,
        party: dto,
      }),
    validateNew: (dto: CreateUpdatePartyToProceedingDto) =>
      api.partyToProceedingControllerValidateNew(dto),
    update: (id: string, dto: CreateUpdatePartyToProceedingDto) =>
      api.partyToProceedingControllerUpdate(id, dto),
    delete: (id: string) => api.partyToProceedingControllerDelete(id),
  };
};

export const toCuDto = (getDto: GetPartyToProceedingDto) => {
  const cuDto = CreateUpdatePartyToProceedingDtoFromJSON(getDto);
  if (getDto.organization) {
    cuDto.organizationId = getDto.organization.id;
  }
  if (getDto.organizationType) {
    cuDto.organizationTypeId = getDto.organizationType.id;
  }
  if (getDto.proceeding) {
    cuDto.proceedingId = getDto.proceeding.id;
  }
  return cuDto;
};
