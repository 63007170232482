import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import { GetOrganizationDto } from '../autogenerated/snrwbApiClient/models/GetOrganizationDto';
import {
  CreateUpdateOrganizationDto,
  CreateUpdateOrganizationDtoFromJSON,
} from '../autogenerated/snrwbApiClient/models/CreateUpdateOrganizationDto';
import {
  Option,
  defaultSelectorLimit,
} from '../../../snrwb/components/Selects/Selector';
import { validateAgainst } from '../validation/validateAgainst';
import { OrganizationSchema } from '../validation/schemas';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { organizationForGrid } from '../../../snrwb/components/Organizations/OrganizationSearch';
import { ValidationStatus } from '../autogenerated/snrwbApiClient';
import DictionaryStatic from '../../../snrwb/components/Dictionary/DictionaryStatic';
import { SnrwbCoreContextType } from '..';

export type Organization = GetOrganizationDto | CreateUpdateOrganizationDto;

export const newOrganization = () => {
  return CreateUpdateOrganizationDtoFromJSON({
    country: 'Polska',
  });
};

export interface OrganizationContextInterface {
  getById: (id: string) => Promise<GetOrganizationDto>;
  getOptionsByName: (text: string) => Promise<Option[]>;
  getOptionsByNip: (text: string) => Promise<Option[]>;
  getPostcodeOptionsByText: (text: string) => Promise<Option[]>;
  getPlaceOptionsByText: (text: string) => Promise<Option[]>;
  getCountryOptionsByText: (text: string) => Promise<Option[]>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  create: (dto: CreateUpdateOrganizationDto) => Promise<GetOrganizationDto>;
  update: (id: string, dto: CreateUpdateOrganizationDto) => Promise<void>;
  validateNew: (dto: CreateUpdateOrganizationDto) => Promise<ValidationStatus>;
  mergeOrganizations: (orgDeleteId: string, orgStayId: string) => Promise<void>;
}

export const OrganizationContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.organizationControllerGet(id),

    getOptionsByName: async (text: string) => {
      const data = await api.organizationControllerGetByPattern(
        text,
        defaultSelectorLimit,
      );

      return Array.from(data, organization => {
        return {
          value: organization.id,
          label:
            organization.name +
            ' ' +
            (organization.nip ? organization.nip + ' ' : '') +
            (organization.place || organization.addressEx || ''),
        };
      });
    },

    getOptionsByNip: async (text: string) => {
      const data = await api.organizationControllerGetByNip(
        text,
        defaultSelectorLimit,
      );

      return Array.from(data, organization => {
        return {
          value: organization.id,
          label: organization.nip + ' ' + organization.name,
        };
      });
    },

    getPostcodeOptionsByText: async (text: string) => {
      const data = await api.organizationControllerGetDistinctPostcodes(
        text,
        defaultSelectorLimit,
      );
      return Array.from(data, postcode => {
        return {
          value: postcode,
          label: postcode,
        };
      });
    },

    getPlaceOptionsByText: async (text: string) => {
      const data = await api.organizationControllerGetDistinctPlaces(
        text,
        defaultSelectorLimit,
      );
      return Array.from(data, place => {
        return {
          value: place,
          label: place,
        };
      });
    },

    getCountryOptionsByText: async (text: string) => {
      const data = await api.organizationControllerGetDistinctCountries(
        text,
        defaultSelectorLimit,
      );
      return Array.from(data, country => {
        return {
          value: country,
          label: country,
        };
      });
    },

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      additionalId?: string,
    ) => {
      let data = await api.organizationControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      if (additionalId) {
        data = data.map(o => {
          return { ...o, headId: additionalId };
        });
      }

      return data.map(organizationForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.organizationControllerGetAllCount(filterText || ''),

    create: (organization: CreateUpdateOrganizationDto) =>
      api.organizationControllerCreate(organization),

    update: (id: string, dto: CreateUpdateOrganizationDto) =>
      api.organizationControllerUpdate(id, dto),

    validateNew: (dto: CreateUpdateOrganizationDto) =>
      api.organizationControllerValidateNew(dto),

    mergeOrganizations: (orgDeleteId: string, orgStayId: string) =>
      api.organizationControllerMergeOrganizations(orgDeleteId, orgStayId),
  };
};

export const validate = (organization: CreateUpdateOrganizationDto) =>
  validateAgainst(OrganizationSchema, organization);

export const partial = (property: keyof Organization, value: string) => {
  const obj: Partial<Organization> = {};
  obj[property] = value;
  return obj;
};

/**
 * Copied out of snrwb-core
 */
export const address = (organization: Organization) => {
  if (!organization) {
    return null;
  }

  if (organization.country !== 'Polska') {
    if (!organization.addressEx) {
      return null;
    }
    return organization.addressEx + ', ' + organization.country;
  }

  let description = '';

  const addIfNotEmpty = (prefix: string, what: string) => {
    if (what) {
      description += (description ? prefix : '') + what;
    }
  };

  const placeShouldBeIncluded = (organization: Organization) => {
    return (
      !organization.street ||
      (organization.place &&
        (!organization.postcode ||
          !organization.postcode.endsWith(organization.place)))
    );
  };

  const includePlace = placeShouldBeIncluded(organization);
  if (includePlace && !organization.street) {
    description = organization.place;
  }

  addIfNotEmpty(' ', organization.street);
  addIfNotEmpty(' ', organization.numbers);
  addIfNotEmpty(' m. ', organization.flat);
  if (includePlace && organization.street) {
    addIfNotEmpty(' ', organization.place);
  }
  addIfNotEmpty(', ', organization.postcode);

  return description;
};

export const stringify = (organization: Organization) => {
  const addr = address(organization);
  return `${organization.name ? organization.name : ''}${
    organization.name && addr ? ', ' : ''
  }${addr ? addr : ''}`;
};

export const toCuDto = (organization: Organization) => {
  const cuDto = CreateUpdateOrganizationDtoFromJSON(organization);
  if ('activityType' in organization && organization.activityType) {
    if (cuDto.activityTypeId === undefined) {
      cuDto.activityTypeId = organization.activityType.id;
    }
    cuDto.activityTypeShort = organization.activityType.shortname;
  }
  return cuDto;
};

export const toPresent = (organization: GetOrganizationDto) => {
  if (organization.country !== 'Polska') {
    return [
      {
        name: 'Państwo',
        value: organization.country,
      },
      {
        name: 'Nazwa',
        value: organization.name,
      },
      {
        name: 'Adres',
        value: organization.addressEx,
      },
      {
        name: 'NIP',
        value: organization.nip,
      },
    ];
  }
  const name = (shortname?: string) => {
    switch (shortname) {
      case 'IDG':
        return 'Nazwa firmy';
      case 'SC':
        return 'Nazwa spółki cywilnej';
      default:
        return 'Nazwa';
    }
  };
  const nip = (shortname?: string) => {
    switch (shortname) {
      case 'SC':
        return 'NIP spółki cywilnej';
      default:
        return 'NIP';
    }
  };
  const form = [
    {
      name: 'Państwo',
      value: organization.country,
    },
    {
      name: 'Typ działalności',
      value: organization.activityType
        ? DictionaryStatic({ value: organization.activityType })
        : '',
    },
    {
      name: 'Imię',
      value: organization.firstName,
    },
    {
      name: 'Nazwisko',
      value: organization.lastName,
    },
    {
      name: name(organization.activityType?.shortname),
      value: organization.name,
    },
    {
      name: nip(organization.activityType?.shortname),
      value: organization.nip,
    },
    {
      name: 'Poczta',
      value: organization.postcode,
    },
    {
      name: 'Miejscowość',
      value: organization.place,
    },
    {
      name: 'Ulica',
      value: organization.street,
    },
    {
      name: 'Numer',
      value: organization.numbers,
    },
    {
      name: 'Mieszkanie',
      value: organization.flat,
    },
    {
      name: 'Uwagi dodatkowe',
      value: organization.addressEx,
    },
  ];

  return form.filter(field => {
    if (!field.value) {
      return false;
    }

    if (organization.activityType?.shortname !== 'IDG') {
      if (field.name === 'Imię' || field.name === 'Nazwisko') {
        return false;
      }
    }

    return true;
  });
};

export const OrganizationToPresentHistoryWithPrev = async (
  snrwb: SnrwbCoreContextType,
  organization: CreateUpdateOrganizationDto,
  prevOrganization: CreateUpdateOrganizationDto,
) => {
  const presentation: {
    name: string;
    value: string | number | JSX.Element | undefined;
    prevValue: string | number | JSX.Element | undefined;
    equal: boolean;
  }[] = [
    {
      name: 'Nazwa',
      value: organization.name,
      prevValue: prevOrganization?.name,
      equal: organization.name === prevOrganization?.name,
    },
    {
      name: 'Państwo',
      value: organization.country,
      prevValue: prevOrganization?.country,
      equal: organization.country === prevOrganization?.country,
    },
  ];

  let typeDict = undefined;
  if (organization?.activityTypeId) {
    typeDict = DictionaryStatic({
      value: await snrwb.dictionaries.getById(organization.activityTypeId),
    });
  }

  let prevTypeDict = undefined;
  if (prevOrganization?.activityTypeId) {
    const v2 = await snrwb.dictionaries.getById(
      prevOrganization?.activityTypeId,
    );
    prevTypeDict = DictionaryStatic({ value: v2 });
  }

  presentation.push({
    name: 'Typ działalności',
    value: typeDict,
    prevValue: prevTypeDict,
    equal: organization?.activityTypeId === prevOrganization?.activityTypeId,
  });
  presentation.push({
    name: 'Imię',
    value: organization.firstName,
    prevValue: prevOrganization?.firstName,
    equal: organization.firstName === prevOrganization?.firstName,
  });
  presentation.push({
    name: 'Nazwisko',
    value: organization.lastName,
    prevValue: prevOrganization?.lastName,
    equal: organization.lastName === prevOrganization?.lastName,
  });

  presentation.push({
    name: 'NIP',
    value: organization.nip,
    prevValue: prevOrganization?.nip,
    equal: organization.nip === prevOrganization?.nip,
  });

  presentation.push({
    name: 'Poczta',
    value: organization.postcode,
    prevValue: prevOrganization?.postcode,
    equal: organization.postcode === prevOrganization?.postcode,
  });

  presentation.push({
    name: 'Miejscowość',
    value: organization.place,
    prevValue: prevOrganization?.place,
    equal: organization.place === prevOrganization?.place,
  });

  presentation.push({
    name: 'Ulica',
    value: organization.street,
    prevValue: prevOrganization?.street,
    equal: organization.street === prevOrganization?.street,
  });

  presentation.push({
    name: 'Numer',
    value: organization.numbers,
    prevValue: prevOrganization?.numbers,
    equal: organization.numbers === prevOrganization?.numbers,
  });

  presentation.push({
    name: 'Mieszkanie',
    value: organization.flat,
    prevValue: prevOrganization?.flat,
    equal: organization.flat === prevOrganization?.flat,
  });

  presentation.push({
    name: 'Adres / Uwagi',
    value: organization.addressEx,
    prevValue: prevOrganization?.addressEx,
    equal: organization.addressEx === prevOrganization?.addressEx,
  });

  return presentation;
};
