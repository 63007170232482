import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { ReportByInspectionType } from '../../../components/ReportByInspectionType/ReportByInspectionType';
import { ReportInspected } from '../../../../common/types/ReportTypes';

export const ReportConductedInspectionsView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportInspected>({
    details: {},
    sum: {},
  });

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports
      .reportConductedInspections(false, filter)
      .then(dataReports => {
        setData(dataReports);
      });
  };

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName="Liczba przeprowadzonych kontroli"
      />
      <ReportByInspectionType
        data={data}
        reportName="Liczba przeprowadzonych kontroli"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
