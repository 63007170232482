import React, { useState, useEffect } from 'react';
import { Alert, Button, Modal, Row } from 'react-bootstrap';

import ConfirmationButton from '../../../app/components/ConfirmationButton';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

interface ModalProps {
  show: boolean;
  title: string;
  saveBtnLabel: string;
  deleteBtnText: string;
  errors: string[];
  deleteRole?: Role;
  size: 'large' | 'small';
  onClose: () => void;
  onCompleted: (() => void) | undefined;
  onDelete: (() => void) | undefined;
}

export const OrgDocumentsModal: React.FC<ModalProps> = props => {
  const notifications = useNotifications();
  const [noAuth, setNoAuth] = useState(false);

  useEffect(() => {
    const mainHandler = notifications.unauthorized;

    if (props.show) {
      notifications.unauthorized = () => {
        setNoAuth(true);
      };
    } else {
      setNoAuth(false);
    }

    return () => {
      notifications.unauthorized = mainHandler;
    };
  }, [notifications, props.show]);

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      size={props.size === 'large' ? 'xl' : 'lg'}
      centered={true}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>{props.children}</Row>
        <ValidationAlert
          show={props.errors.length > 0}
          errors={props.errors}
          className="mt-3"
        />
        {noAuth && (
          <Alert variant="danger" className="mt-3 mb-0">
            Brak wymaganych uprawnień.
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={props.onClose}
          className="me-1"
        >
          Zamknij
        </Button>
        {props.onDelete && (
          <ConfirmationButton
            className="me-1"
            roleAnyOf={[props.deleteRole || Role.OrganizationsEdit]}
            confirmation={props.deleteBtnText}
            variant="outline-danger"
            onOK={props.onDelete}
          >
            Usuń
          </ConfirmationButton>
        )}
        {props.onCompleted && (
          <Button variant="outline-primary" onClick={props.onCompleted}>
            {props.saveBtnLabel}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
