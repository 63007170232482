import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icons from 'react-bootstrap-icons';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useMemosProvider } from '../../../common/hooks/useMemosProvider';
import {
  CreatePrivateMemoDto,
  SendPrivateMemoToManyDto,
  GetEmployeeDto,
  GetEmployeeDtoFromJSON,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import LoadingScreen from '../../../app/components/LoadingScreen';
import { ThreadsFilterEnum } from '../../../common/snrwbCore/contexts/MemoContext';

import MemosLoadingSpinner from './MemosLoadingSpinner';
import { NewPrivateMemo } from './NewPrivateMemo';
import { Threads } from './Threads';
import { UserMemosHeader } from './UserMemosHeader';

export const UserMemos = () => {
  const snrwb = useSnrwbCore();
  const memosProvider = useMemosProvider();
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [currentThread, setCurrentThread] = useState<string>();
  const [sender, setSender] = useState<GetEmployeeDto>(
    GetEmployeeDtoFromJSON({}),
  );
  const [loading, setLoading] = useState(false);
  const [sendingInProgress, setSendingInProgress] = useState(false);
  const mountedRef = useRef(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const onboarding = memosProvider.threads.length === 0;
  const newMemo = onboarding || showNew;
  const pulsingNumber =
    memosProvider.threadsStats?.newParticipatedThreadsCount || 0;

  const quickMemo = (content: string) => {
    const thread = memosProvider.threads.find(th => th.id === currentThread);
    if (thread) {
      snrwb.memos.quickMemo(content, sender, thread).then(() => {
        memosProvider.refreshMemos(thread.id, 'current', () =>
          memosProvider.refreshThreads(),
        );
      });
    }
  };

  const sendPrivateMemo = (dto: CreatePrivateMemoDto) => {
    setSendingInProgress(true);
    snrwb.memos.createPrivateMemo(dto).then(memo => {
      if (mountedRef.current) {
        setShowNew(false);
      }
      memosProvider.refreshThreads().then(() => {
        setCurrentThread(memo.threadId);
        setSendingInProgress(false);
      });
    });
  };

  const sendPrivateMemoToMany = (dto: SendPrivateMemoToManyDto) => {
    setSendingInProgress(true);
    snrwb.memos.sendPrivateMemoToMany(dto).then(memo => {
      if (mountedRef.current) {
        setShowNew(false);
      }
      memosProvider
        .refreshThreads(false, undefined, undefined, true)
        .then(() => {
          setCurrentThread(memo.threadId);
          setSendingInProgress(false);
        });
    });
  };

  const changeActiveThread = useCallback(
    (threadId: string) => {
      setCurrentThread(threadId);
      setLoading(true);
      memosProvider.refreshMemos(threadId, 'initial', () => setLoading(false));
    },
    [memosProvider],
  );

  const setFirstThreadAsActive = useCallback(() => {
    if (memosProvider.threads.length) {
      const firstThreadId = memosProvider.threads[0].id;
      changeActiveThread(firstThreadId);
    }
  }, [changeActiveThread, memosProvider.threads]);

  const open = () => {
    setFirstThreadAsActive();
    setShowNew(false);
    setShow(true);
    buttonRef.current?.blur();
  };

  const close = () => {
    setShow(false);
    memosProvider.resetThreads();
  };

  const checkIfThreadExists = useCallback(
    async threadId => {
      if (threadId) {
        const threads = await snrwb.memos.getThreadsForUser(
          memosProvider.threadsCount,
          ThreadsFilterEnum.all,
        );
        if (!threads.some(t => t.id === threadId)) {
          setFirstThreadAsActive();
        }
      }
    },
    [snrwb.memos, memosProvider.threadsCount, setFirstThreadAsActive],
  );

  const [memorisedThreadId, setMemorizedThreadId] = useState<string>('');
  useEffect(() => {
    if (currentThread && currentThread !== memorisedThreadId) {
      setMemorizedThreadId(currentThread);
      checkIfThreadExists(currentThread);
    }
  }, [currentThread, memorisedThreadId, checkIfThreadExists]);

  useEffect(() => {
    mountedRef.current = true;

    snrwb.employees.getCurrent().then(employee => {
      if (!employee) {
        throw new Error('Cannot find out current user employee');
      }
      if (mountedRef.current) {
        setSender(employee);
      }
    });

    return () => {
      mountedRef.current = false;
    };
  }, [snrwb.employees]);

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip>
            {memosProvider.threadsStats?.threadsCount ? (
              <>
                <p>
                  Liczba wszystkich wątków:
                  {memosProvider.threadsStats?.threadsCount}
                </p>
                {pulsingNumber > 0 && (
                  <p>Liczba wątków z nowymi wiadomościami: {pulsingNumber}</p>
                )}
              </>
            ) : (
              <p>Brak notatek</p>
            )}
          </Tooltip>
        }
      >
        <Button
          ref={buttonRef}
          className="py-1"
          variant="outline-light"
          onClick={open}
        >
          {pulsingNumber > 0 ? (
            <div className="spinner-grow text-success align-items-center">
              <span className="badge text-white memos-pulsing-circle">
                {pulsingNumber}
              </span>
            </div>
          ) : (
            <Icons.ChatLeft />
          )}
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        centered
        backdrop="static"
        size={newMemo ? 'lg' : 'xl'}
        onHide={close}
      >
        <UserMemosHeader
          onboarding={onboarding}
          showNew={showNew}
          onNewMemo={() => setShowNew(true)}
          onCloseMemo={() => setShowNew(false)}
        />
        <Modal.Body className="d-flex">
          {newMemo ? (
            <NewPrivateMemo
              onSend={sendPrivateMemo}
              onSendToMany={sendPrivateMemoToMany}
            />
          ) : currentThread ? (
            <Threads
              activeThread={currentThread}
              loading={loading}
              loggedIn={sender}
              onChangeActiveThread={changeActiveThread}
              onQuickMemo={quickMemo}
              onClose={close}
            />
          ) : (
            <MemosLoadingSpinner />
          )}
        </Modal.Body>
      </Modal>

      <LoadingScreen
        loading={sendingInProgress}
        text="Wysyłanie wiadomości w toku"
      />
    </>
  );
};
