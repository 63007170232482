/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JudgmentSampleDetails,
    JudgmentSampleDetailsFromJSON,
    JudgmentSampleDetailsFromJSONTyped,
    JudgmentSampleDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ControlSampleAnalysisType
 */
export interface ControlSampleAnalysisType {
    /**
     * 
     * @type {string}
     * @memberof ControlSampleAnalysisType
     */
    sampleCollectId: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSampleAnalysisType
     */
    entityName: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSampleAnalysisType
     */
    productDescription: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSampleAnalysisType
     */
    activityDate: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSampleAnalysisType
     */
    protocolSigningDate: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSampleAnalysisType
     */
    unitSymbol: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSampleAnalysisType
     */
    recordNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ControlSampleAnalysisType
     */
    storageCondition: string;
    /**
     * List of judgments associated with the sample
     * @type {Array<JudgmentSampleDetails>}
     * @memberof ControlSampleAnalysisType
     */
    judgments: Array<JudgmentSampleDetails>;
}

export function ControlSampleAnalysisTypeFromJSON(json: any): ControlSampleAnalysisType {
    return ControlSampleAnalysisTypeFromJSONTyped(json, false);
}

export function ControlSampleAnalysisTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControlSampleAnalysisType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sampleCollectId': json['sampleCollectId'],
        'entityName': json['entityName'],
        'productDescription': json['productDescription'],
        'activityDate': json['activityDate'],
        'protocolSigningDate': json['protocolSigningDate'],
        'unitSymbol': json['unitSymbol'],
        'recordNumber': json['recordNumber'],
        'storageCondition': json['storageCondition'],
        'judgments': ((json['judgments'] as Array<any>).map(JudgmentSampleDetailsFromJSON)),
    };
}

export function ControlSampleAnalysisTypeToJSON(value?: ControlSampleAnalysisType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sampleCollectId': value.sampleCollectId,
        'entityName': value.entityName,
        'productDescription': value.productDescription,
        'activityDate': value.activityDate,
        'protocolSigningDate': value.protocolSigningDate,
        'unitSymbol': value.unitSymbol,
        'recordNumber': value.recordNumber,
        'storageCondition': value.storageCondition,
        'judgments': ((value.judgments as Array<any>).map(JudgmentSampleDetailsToJSON)),
    };
}


