import {
  DefaultApi,
  MemoControllerGetSubjectThreadStatsSubjectTypeEnum,
} from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateBpNotificationMemoDto,
  CreateBpNotificationMemoDtoEnSubjectTypeEnum,
  CreatePrivateMemoDto,
  CreatePublicMemoDto,
  CreatePublicMemoDtoEnSubjectTypeEnum,
  GetEmployeeDto,
  GetInspectionDto,
  GetKwzDto,
  GetMemoDto,
  GetPakDto,
  GetProceedingDto,
  GetSampleCollectDto,
  GetSampleExamDto,
  GetSubjectThreadStatsDto,
  GetThreadDto,
  SendPrivateMemoToManyDto,
} from '../autogenerated/snrwbApiClient/models';
import { ThreadChangedArgs } from '../sockets/socket.datatypes';
import { PrivateMemoSchema, PublicMemoSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export type SubjectType =
  | GetInspectionDto
  | GetSampleCollectDto
  | GetSampleExamDto
  | GetProceedingDto
  | GetPakDto
  | GetKwzDto;

export enum ThreadsFilterEnum {
  all,
  privates,
  subjects,
}

export const MemoContext = (api: DefaultApi) => ({
  createPrivateMemo: (dto: CreatePrivateMemoDto) =>
    api.memoControllerCreatePrivateMemo(dto),
  sendPrivateMemoToMany: (dto: SendPrivateMemoToManyDto) =>
    api.memoControllerSendPrivateMemoToMany(dto),
  createPublicMemo: (dto: CreatePublicMemoDto) =>
    api.memoControllerCreatePublicMemo(dto),
  createBpNotificationMemo: (dto: CreateBpNotificationMemoDto) =>
    api.memoControllerCreateBpNotificationMemo(dto),
  getThreadsForUser: (count: number, filter: ThreadsFilterEnum) =>
    api.memoControllerGetThreadsForUser(count, filter),
  getThreadsForUserStats: () => api.memoControllerGetThreadsForUserStats(),
  getThreadMemos: (threadId: string, count: number) =>
    api.memoControllerGetThreadMemos(threadId, count),
  getSubjectThreadStats: (subject: SubjectType) =>
    api.memoControllerGetSubjectThreadStats(
      subjectType(
        subject,
      ) as unknown as MemoControllerGetSubjectThreadStatsSubjectTypeEnum,
      subject.id,
    ),
  quickMemo: async (
    content: string,
    sender: GetEmployeeDto,
    thread: GetThreadDto,
  ) => {
    if (thread.recipient) {
      await api.memoControllerCreatePrivateMemo({
        recipientId: getRecipient(sender, thread).id,
        content,
      });
    } else {
      const subject = thread.subject as SubjectType;
      if (!subject) {
        console.error('Current thread has neither recipient nor subject');
        return;
      }
      await api.memoControllerCreatePublicMemo({
        enSubjectType: subjectType(subject),
        subjectId: subject.id,
        content,
      });
    }
  },
});

export const validatePrivateMemo = (dto: CreatePrivateMemoDto) =>
  validateAgainst(PrivateMemoSchema, dto);

export const validatePublicMemo = (dto: CreatePublicMemoDto) =>
  validateAgainst(PublicMemoSchema, dto);

export const subjectType = (subject: SubjectType) => {
  if ('metric' in subject) {
    return CreatePublicMemoDtoEnSubjectTypeEnum.Kontrola;
  } else if ('collectBasis' in subject) {
    return CreatePublicMemoDtoEnSubjectTypeEnum.PobranieProbek;
  } else if ('sampleCollect' in subject) {
    return CreatePublicMemoDtoEnSubjectTypeEnum.BadanieProbek;
  } else if ('legalBasis' in subject) {
    return CreatePublicMemoDtoEnSubjectTypeEnum.Postepowanie;
  } else if ('proceedingId' in subject) {
    return CreatePublicMemoDtoEnSubjectTypeEnum.Pak;
  } else if ('publicationNumber' in subject) {
    return CreatePublicMemoDtoEnSubjectTypeEnum.Kwz;
  }

  throw new Error('Cannot find out subject type');
};

export const subjectTypeMB = (subject: SubjectType) => {
  if ('metric' in subject) {
    return CreateBpNotificationMemoDtoEnSubjectTypeEnum.Kontrola;
  } else if ('collectBasis' in subject) {
    return CreateBpNotificationMemoDtoEnSubjectTypeEnum.PobranieProbek;
  } else if ('sampleCollect' in subject) {
    return CreateBpNotificationMemoDtoEnSubjectTypeEnum.BadanieProbek;
  } else if ('legalBasis' in subject) {
    return CreateBpNotificationMemoDtoEnSubjectTypeEnum.Postepowanie;
  } else if ('proceedingId' in subject) {
    return CreateBpNotificationMemoDtoEnSubjectTypeEnum.Pak;
  } else if ('publicationNumber' in subject) {
    return CreateBpNotificationMemoDtoEnSubjectTypeEnum.Kwz;
  }
  throw new Error('Cannot find out subject type');
};

export const polishAblative = (subject?: SubjectType) => {
  if (!subject) {
    return '';
  }

  const type = subjectType(subject);
  switch (type) {
    case CreatePublicMemoDtoEnSubjectTypeEnum.BadanieProbek:
      return `tym badaniem próbek`;
    case CreatePublicMemoDtoEnSubjectTypeEnum.PobranieProbek:
      return `tym pobraniem próbek`;
    case CreatePublicMemoDtoEnSubjectTypeEnum.Kontrola:
      return `tą kontrolą`;
    case CreatePublicMemoDtoEnSubjectTypeEnum.Postepowanie:
      return `tym postępowaniem`;
    case CreatePublicMemoDtoEnSubjectTypeEnum.Pak:
      return `PAK`;
    case CreatePublicMemoDtoEnSubjectTypeEnum.Kwz:
      return `KWZ`;
  }
};

export const navigateTo = (
  nav: {
    inspection: (id: string) => void;
    sampleCollect: (id: string) => void;
    sampleExam: (id: string) => void;
    proceeding: (id: string) => void;
    pak: (id: string) => void;
    kwz: (id: string) => void;
  },
  subject?: SubjectType,
) => {
  if (!subject) {
    return;
  }
  const type = subjectType(subject);
  switch (type) {
    case CreatePublicMemoDtoEnSubjectTypeEnum.BadanieProbek:
      nav.sampleExam(subject.id);
      break;
    case CreatePublicMemoDtoEnSubjectTypeEnum.PobranieProbek:
      nav.sampleCollect(subject.id);
      break;
    case CreatePublicMemoDtoEnSubjectTypeEnum.Kontrola:
      nav.inspection(subject.id);
      break;
    case CreatePublicMemoDtoEnSubjectTypeEnum.Postepowanie:
      nav.proceeding(subject.id);
      break;
    case CreatePublicMemoDtoEnSubjectTypeEnum.Pak:
      nav.pak(subject.id);
      break;
    case CreatePublicMemoDtoEnSubjectTypeEnum.Kwz:
      nav.kwz(subject.id);
      break;
  }
};

export const getRecipient = (sender: GetEmployeeDto, thread: GetThreadDto) => {
  if (thread.subject || thread.sender.id !== sender.id) {
    return thread.sender;
  }
  if (thread.recipient?.id !== sender.id) {
    return thread.recipient;
  }
  return thread.sender; // memo to yourself
};

export const unreadThread = (th?: GetThreadDto) => {
  return th && th.newMessagesCount > 0 && th.participatedCount > 0;
};

export const sameStats = (
  s1?: GetSubjectThreadStatsDto,
  s2?: GetSubjectThreadStatsDto,
) => {
  return (
    s1?.threadId === s2?.threadId &&
    s1?.messagesCount === s2?.messagesCount &&
    s1?.newMessagesCount === s2?.newMessagesCount
  );
};

export const setThreadAsRead = (threads: GetThreadDto[], threadId: string) => {
  return threads.map(th => {
    if (th.id === threadId) {
      th.newMessagesCount = 0;
    }
    return th;
  });
};

export const subjectMatch = (subject: SubjectType, args: ThreadChangedArgs) => {
  return (
    args.subjectId === subject.id &&
    (args.subjectType as unknown as CreatePublicMemoDtoEnSubjectTypeEnum) ===
      subjectType(subject)
  );
};

export const firstSubject = (memos: GetMemoDto[]) => {
  if (memos.length === 0) {
    return undefined;
  }
  return memos[0].subject as SubjectType;
};
