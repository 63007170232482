import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import { currentMenuItems } from '../../common/navigation';
import { useAuth } from '../../common/hooks/useAuth';

import SideNav from './SideNav/SideNav';

interface MainProps {
  children: React.ReactNode;
  location: string;
}

const Main: React.FC<MainProps> = ({ children, location }: MainProps) => {
  const auth = useAuth();
  const items = currentMenuItems(location);
  let authorized = true;

  for (const item of items) {
    const authItem =
      !item.requiresAnyOf ||
      item.requiresAnyOf.filter(role => auth.check(role)).length > 0;
    authorized = authorized && authItem;
  }

  return (
    <Container fluid>
      <Row className="flex-xl-nowrap">
        <Col as={SideNav} xs={12} md={3} xl={2} location={location} />
        <Col
          xs={12}
          md={9}
          xl={10}
          as="main"
          id="main-content"
          className="main"
        >
          {authorized ? (
            children
          ) : (
            <Alert variant="danger">
              <Alert.Heading>Brak wymaganych uprawnień</Alert.Heading>
              <hr />
              <p>
                W celu podwyższenia uprawnień prosimy o kontakt z administracją.
              </p>
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Main;
