import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import ValidationAlert from '../../../app/components/ValidationAlert';
import FormRow from '../../../app/components/FormRow';
import { CreateUpdateEmployeeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/CreateUpdateEmployeeDto';
import OrganizationalUnitSelector from '../Selects/OrganizationalUnitSelector';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';

interface AddEditEmployeeProps {
  id?: string;
  employee: CreateUpdateEmployeeDto;
  handleClose?: () => void;
  show?: boolean;
  onCompleted: (
    employee: CreateUpdateEmployeeDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
}

export const AddEditEmployee: React.FC<AddEditEmployeeProps> = props => {
  const [show, setShow] = useState(props.show);
  const [employee, setEmployee] = useState(props.employee);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const notifications = useNotifications();
  const snrwb = useSnrwbCore();

  useEffect(() => {
    setShow(props.show);
    setSaveErrors([]);
    setShowSaveErrors(false);
    setEmployee(props.employee);
  }, [props.show, show, props.employee]);

  const createEmployee = async () => {
    props.onCompleted(employee, props.id).then(saveStatus => {
      if (!saveStatus.saved) {
        setSaveErrors(saveStatus.errors);
        setShowSaveErrors(true);
        return;
      }
    });
  };

  const deleteEmployee = async () => {
    if (props.id) {
      notifications.onPromise(
        snrwb.employees.changeToInactive(props.id),
        props.handleClose,
      );
    }
  };

  const propertyChange = (obj: Partial<CreateUpdateEmployeeDto>) => {
    setEmployee({ ...employee, ...obj });
  };

  return (
    <>
      <Modal
        onHide={props.handleClose}
        show={show}
        centered={true}
        keyboard={true}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.id ? 'Edycja pracownika' : 'Dodawanie nowego pracownika'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2">
            <Card.Header>Pracownik</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="employee" label="Imię">
                  <Form.Control
                    type="text"
                    value={employee?.firstName || ''}
                    onChange={e =>
                      propertyChange({ firstName: e.target.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="employee" label="Nazwisko">
                  <Form.Control
                    type="text"
                    value={employee?.lastName || ''}
                    onChange={e => propertyChange({ lastName: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="organizationalUnit" label="Organ">
                  <OrganizationalUnitSelector
                    value={employee.organizationalUnitId}
                    onChange={e =>
                      propertyChange({ organizationalUnitId: e.value })
                    }
                  />
                </FormRow>
                <FormRow controlId="employee" label="Nr legitymacji">
                  <Form.Control
                    type="text"
                    value={employee?.cardNo || ''}
                    onChange={e => propertyChange({ cardNo: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="employee" label="Stanowisko">
                  <Form.Control
                    type="text"
                    value={employee?.position || ''}
                    onChange={e => propertyChange({ position: e.target.value })}
                  />
                </FormRow>
                <FormRow controlId="employee" label="Login użytkownika">
                  <Form.Control
                    type="text"
                    value={employee?.login || ''}
                    onChange={e =>
                      propertyChange({
                        login: e.target.value === '' ? null : e.target.value,
                      })
                    }
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <ValidationAlert
            show={showSaveErrors}
            errors={saveErrors}
            className="mt-3"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          {props.id && (
            <ConfirmationButton
              className="mx-1"
              roleAnyOf={[Role.ConfigurationEdit]}
              confirmation="Czy na pewno usunąć pracownika?"
              variant="outline-danger"
              onOK={deleteEmployee}
            >
              Usuń
            </ConfirmationButton>
          )}
          <Button variant="outline-primary" onClick={createEmployee}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
