/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateWithJudgmentDto
 */
export interface CreateWithJudgmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWithJudgmentDto
     */
    judgmentId: string;
}

export function CreateWithJudgmentDtoFromJSON(json: any): CreateWithJudgmentDto {
    return CreateWithJudgmentDtoFromJSONTyped(json, false);
}

export function CreateWithJudgmentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWithJudgmentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'judgmentId': json['judgmentId'],
    };
}

export function CreateWithJudgmentDtoToJSON(value?: CreateWithJudgmentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'judgmentId': value.judgmentId,
    };
}


