import {
  CreateUpdateInspectionMetricDto,
  CreateUpdateInspectionDto,
  CreateUpdateOrganizationDto,
  GetInspectionDto,
  GetInspectionExtendedDto,
  DefaultApi,
  CreateUpdateInspectedProductDto,
  CreateUpdateProductTypeDto,
  ValidationStatus,
  GetProductTypeDto,
  GetInspectedProductDto,
  GetProceedingDto,
  GetAttachmentDto,
  GetMisstatementDto,
  GetCorrectiveActionDto,
  GetJudgmentDto,
  GetInspectorDto,
  GetPakDto,
  GetInspectionWithDetailsDto,
  CreateUpdateSampleCollectDto,
} from '../autogenerated/snrwbApiClient';
import { InspectionSigningSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { SnrwbCoreContextType } from '..';
import { NotificationsProviderType } from '../../notifications';
import { responseErrors } from '../validation/responseErrors';
import momencik from '../../momencik';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { inspectionForGrid } from '../../../snrwb/components/Inspections/InspectionsSearch';
import { GetProtocolDuringInspectionDto } from '../autogenerated/snrwbApiClient';
import UnorderedList from '../../../app/components/UnorderedList';
import { GridFilters } from '../../components/Grid/GridFilters';

import { AddProductTypeFunc, ProductType } from './ProductTypeContext';
import { Organization } from './OrganizationContext';
import { ProtocolWithAttachments } from './ProtocolDuringInspectionContext';

export interface InspectionContextInterface {
  getById: (id: string) => Promise<GetInspectionDto>;
  getWithDetails: (id: string) => Promise<GetInspectionWithDetailsDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    additionalId?: string,
    additionalFilters?: GridFilters,
  ) => Promise<GridRecord[]>;
  getAllCount: (
    filterText?: string,
    additionalFilters?: GridFilters,
  ) => Promise<number>;
  getSignedByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getSignedCount: (filterText?: string) => Promise<number>;
  getByMetric: (metricId: string) => Promise<GetInspectionDto>;
  createBasedOnMetric: (
    metric: CreateUpdateInspectionMetricDto,
    sampleExamId?: string,
    proceedingId?: string,
  ) => Promise<GetInspectionDto>;
  createBasedOnMetricWithOrganization: (
    metric: CreateUpdateInspectionMetricDto,
    organization: CreateUpdateOrganizationDto,
    sampleExamId?: string,
    proceedingId?: string,
  ) => Promise<GetInspectionDto>;
  update: (id: string, inspection: CreateUpdateInspectionDto) => Promise<void>;
  getPendingForUser: () => Promise<GetInspectionExtendedDto[]>;
  mayDelete: (id: string) => Promise<ValidationStatus>;
  delete: (id: string) => Promise<void>;
  readyToSign: (id: string) => Promise<ValidationStatus>;
  sign: (id: string) => Promise<void>;
  revertSign: (id: string) => Promise<void>;
  getByPak: (pakId: string) => Promise<GetInspectionDto[]>;
}

export const InspectionContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.inspectionControllerGet(id),
    getWithDetails: (id: string) => api.inspectionControllerGetWithDetails(id),
    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      additionalId?: string,
      additionalFilters?: GridFilters,
    ) => {
      const data = await api.inspectionControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        additionalFilters?.organizationalUnit || '',
        additionalFilters?.organization || '',
        additionalFilters?.inspectionPlace || '',
        additionalFilters && additionalFilters.actionDateFrom
          ? additionalFilters.actionDateFrom.toISOString().substring(0, 10)
          : '',
        additionalFilters && additionalFilters.actionDateTo
          ? additionalFilters.actionDateTo.toISOString().substring(0, 10)
          : '',
        additionalFilters?.fileNumber || '',
      );
      return data.map(inspectionForGrid);
    },
    getAllCount: (filterText?: string) =>
      api.inspectionControllerGetAllCount(filterText || ''),
    getSignedByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
    ) => {
      const data = await api.inspectionControllerGetSignedByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      return data.map(inspectionForGrid);
    },
    getSignedCount: (filterText?: string) =>
      api.inspectionControllerGetSignedCount(filterText || ''),
    getByMetric: (metricId: string) =>
      api.inspectionControllerGetByMetric(metricId),
    createBasedOnMetric: (
      metric: CreateUpdateInspectionMetricDto,
      sampleExamId?: string,
      proceedingId?: string,
    ) =>
      api.inspectionControllerCreateBasedOnMetric({
        metric,
        sampleExamId,
        proceedingId,
      }),
    createBasedOnMetricWithOrganization: (
      metric: CreateUpdateInspectionMetricDto,
      organization: CreateUpdateOrganizationDto,
      sampleExamId?: string,
      proceedingId?: string,
    ) =>
      api.inspectionControllerCreateBasedOnMetricWithOrganization({
        metric,
        organization,
        sampleExamId,
        proceedingId,
      }),
    update: (id: string, inspection: CreateUpdateInspectionDto) =>
      api.inspectionControllerUpdate(id, inspection),
    getPendingForUser: () => api.inspectionControllerGetPendingForUser(),
    mayDelete: (id: string) => api.inspectionControllerMayDelete(id),
    delete: (id: string) => api.inspectionControllerDelete(id),
    readyToSign: (id: string) => api.inspectionControllerReadyToSign(id),
    sign: (id: string) => api.inspectionControllerSign(id),
    revertSign: (id: string) => api.inspectionControllerRevertSign(id),
    getByPak: (pakId: string) => api.inspectionControllerGetByPak(pakId),
  };
};

export type ModelGeneratorFunc = (
  snrwb: SnrwbCoreContextType,
  id: string,
) => Promise<
  Partial<{
    inspection: GetInspectionDto;
    products: GetInspectedProductDto[];
    proceedings: GetProceedingDto[];
    attachments: GetAttachmentDto[];
    misstatements: GetMisstatementDto[];
    correctiveActions: GetCorrectiveActionDto[];
    judgments: GetJudgmentDto[];
    protocols: ProtocolWithAttachments[];
    inspectors: GetInspectorDto[];
    paks: GetPakDto[];
  }>
>;

const getInspection = async (
  snrwb: SnrwbCoreContextType,
  inspectionId: string,
) => {
  return {
    inspection: await snrwb.inspections.getById(inspectionId),
  };
};

const getProducts = async (
  snrwb: SnrwbCoreContextType,
  inspectionId: string,
) => {
  return {
    products: await snrwb.inspectedProducts.getByInspection(inspectionId),
  };
};

const getInspectionAndProducts = async (
  snrwb: SnrwbCoreContextType,
  inspectionId: string,
) => {
  const partial1 = await getInspection(snrwb, inspectionId);
  const partial2 = await getProducts(snrwb, inspectionId);
  return { ...partial1, ...partial2 };
};

export const forInspectionView = async (
  snrwb: SnrwbCoreContextType,
  inspectionId: string,
) => {
  const model = await snrwb.inspections.getWithDetails(inspectionId);

  if (!model.inspection) {
    throw new Error(`No inspection ${inspectionId} in database`);
  }

  // properties are casted from string[] to SpecificDto[]
  // because proper types at snrwb-core causes cycle dependencies
  return {
    inspection: model.inspection,
    products: model.products as unknown as GetInspectedProductDto[],
    proceedings: model.proceedings as unknown as GetProceedingDto[],
    attachments: model.attachments as unknown as GetAttachmentDto[],
    misstatements: model.misstatements as unknown as GetMisstatementDto[],
    correctiveActions:
      model.correctiveActions as unknown as GetCorrectiveActionDto[],
    judgments: model.judgments as unknown as GetJudgmentDto[],
    protocols: snrwb.protocolsDuringInspection.getAllForCollection(
      model.protocols as unknown as GetProtocolDuringInspectionDto[],
      model.protocolsAttachments as unknown as GetAttachmentDto[],
    ),
    inspectors: model.inspectors as unknown as GetInspectorDto[],
    paks: model.paks as unknown as GetPakDto[],
  };
};

export interface InspectionApi {
  addInspectedProduct: AddProductTypeFunc;
  editMetric: (metric: CreateUpdateInspectionMetricDto) => void;
  approveMetric: () => void;
  revertApproveMetric: () => void;
  mayDeleteInspection: () => Promise<ValidationStatus>;
  deleteInspection: () => void;
  editProduct: (id: string, product: CreateUpdateInspectedProductDto) => void;
  deleteProduct: (id: string) => void;
  editProductType: (id: string, proType: CreateUpdateProductTypeDto) => void;
  mayProductTypeBeApproved: (id: string) => Promise<ValidationStatus>;
  approveProductType: (id: string, status: ValidationStatus) => void;
  revertApproveProductType: (getDto: GetProductTypeDto) => void;
  editInspection: (id: string, inspection: CreateUpdateInspectionDto) => void;
  inspectionProtocol: () => void;
  readyToSign: () => Promise<ValidationStatus>;
  sign: () => void;
  revertSign: () => void;
  createExam: (
    dto: CreateUpdateSampleCollectDto,
    inspectedProductId: string,
  ) => void;
  createProceeding: (inspectedProductId: string) => void;
  addPak: (inspectedProductId: string) => void;
  refresh: () => void;
}

export const handlersForInspectionView = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  inspection: GetInspectionDto,
  refreshAction: (generator?: ModelGeneratorFunc) => void,
  navigationAction: () => void,
  newProductAction: (id: string) => void,
  newSampleExamAction: (id: string) => void,
  newProceedingAction: (id: string) => void,
  newPakAction: (id: string) => void,
  errorsAction: (errors: string[]) => void,
) => {
  return {
    addInspectedProduct: async (
      organization: Organization,
      productType: ProductType,
    ) => {
      try {
        const product = await snrwb.inspectedProducts.create(
          inspection.id,
          organization,
          productType,
        );
        newProductAction(product.id);
        refreshAction(getProducts);
        return true;
      } catch (response) {
        errorsAction(await responseErrors(response as Response));
      }
      return false;
    },

    editMetric: (metric: CreateUpdateInspectionMetricDto) =>
      notifications.onPromise(
        snrwb.inspectionMetrics.update(inspection.metric.id, metric),
        () => refreshAction(getInspectionAndProducts),
      ),

    approveMetric: async () => {
      notifications.onPromise(
        snrwb.inspectionMetrics.approve(inspection.metric.id),
        () => refreshAction(getInspectionAndProducts),
      );
    },

    revertApproveMetric: async () => {
      notifications.onPromise(
        snrwb.inspectionMetrics.revertApprove(inspection.metric.id),
        () => refreshAction(getInspectionAndProducts),
      );
    },

    mayDeleteInspection: () => snrwb.inspections.mayDelete(inspection.id),

    deleteInspection: () =>
      notifications.onPromise(
        snrwb.inspections.delete(inspection.id),
        navigationAction,
      ),

    inspectionProtocol: () => snrwb.printouts.inspectionProtocol(inspection.id),

    editProduct: (id: string, product: CreateUpdateInspectedProductDto) =>
      notifications.onPromise(snrwb.inspectedProducts.update(id, product), () =>
        refreshAction(getProducts),
      ),

    deleteProduct: (id: string) =>
      notifications.onPromise(
        snrwb.inspectedProducts.delete(id),
        refreshAction,
      ),

    editProductType: (id: string, type: CreateUpdateProductTypeDto) => {
      notifications.onPromise(snrwb.productTypes.update(id, type), () =>
        refreshAction(getProducts),
      );
    },

    mayProductTypeBeApproved: async (id: string) =>
      snrwb.productTypes.mayBeApproved(id),

    approveProductType: (id: string, status: ValidationStatus) =>
      notifications.onPromise(snrwb.productTypes.approve(id, status), () =>
        refreshAction(getProducts),
      ),

    revertApproveProductType: (getDto: GetProductTypeDto) => {
      notifications.onPromise(snrwb.productTypes.revertApprove(getDto.id), () =>
        refreshAction(getProducts),
      );
    },

    editInspection: (id: string, inspection: CreateUpdateInspectionDto) =>
      notifications.onPromise(snrwb.inspections.update(id, inspection), () =>
        refreshAction(getInspection),
      ),

    readyToSign: () => snrwb.inspections.readyToSign(inspection.id),

    sign: () =>
      notifications.onPromise(snrwb.inspections.sign(inspection.id), () =>
        refreshAction(getInspection),
      ),

    revertSign: () =>
      notifications.onPromise(snrwb.inspections.revertSign(inspection.id), () =>
        refreshAction(getInspection),
      ),

    createExam: (
      dto: CreateUpdateSampleCollectDto,
      inspectedProductId: string,
    ) =>
      snrwb.sampleExams
        .createBasedOnInspectedProduct(dto, inspectedProductId)
        .then(exam => newSampleExamAction(exam.id)),

    createProceeding: (inspectedProductId: string) =>
      snrwb.proceedings
        .createBasedOnInspectedProduct(inspectedProductId)
        .then(proceeding => newProceedingAction(proceeding.id)),

    addPak: (inspectedProductId: string) =>
      notifications.onPromise(
        snrwb.paks
          .createBasedOnInspectedProduct(inspectedProductId)
          .then(pak => newPakAction(pak.id)),
      ),

    refresh: () => refreshAction(forInspectionView),
  };
};

export type AddInspectionFunc = (
  organization: Organization,
  metric: CreateUpdateInspectionMetricDto,
  sampleExamId?: string,
  proceedingId?: string,
) => Promise<{
  saved: boolean;
  errors: string[];
  action?: () => void;
}>;

export const handlerForAddInspection = (
  snrwb: SnrwbCoreContextType,
  inspectionAction: (id: string) => void,
) => {
  return async (
    organization: Organization,
    metric: CreateUpdateInspectionMetricDto,
    sampleExamId?: string,
    proceedingId?: string,
  ) => {
    let createInspection = undefined;

    if ('id' in organization) {
      metric.organizationId = organization.id;
      createInspection = async () =>
        await snrwb.inspections.createBasedOnMetric(
          metric,
          sampleExamId,
          proceedingId,
        );
    } else {
      const organizationDto = organization as CreateUpdateOrganizationDto;
      createInspection = async () =>
        await snrwb.inspections.createBasedOnMetricWithOrganization(
          metric,
          organizationDto,
          sampleExamId,
          proceedingId,
        );
    }

    try {
      const inspection = await createInspection();
      return {
        saved: true,
        errors: [],
        action: () => inspectionAction(inspection.id),
      };
    } catch (response) {
      return {
        saved: false,
        errors: await responseErrors(response as Response),
      };
    }
  };
};

export const validateSigning = (inspection: CreateUpdateInspectionDto) =>
  validateAgainst(InspectionSigningSchema, inspection);

export const signingPresent = (inspection: GetInspectionDto) => {
  const form = [];
  if (inspection.witnesses) {
    form.push({
      name: 'W obecności',
      value: inspection.witnesses,
    });
  }
  if (inspection.notes) {
    form.push({
      name: 'Uwagi kontrolowanego',
      value: inspection.notes,
    });
  }
  if (inspection.signatureDate) {
    form.push({
      name: 'Data podpisania',
      value: momencik(inspection.signatureDate),
    });
  }
  if (inspection.placeOfSignature) {
    form.push({
      name: 'Miejsce podpisania',
      value: inspection.placeOfSignature,
    });
  }
  if (inspection.refusalReason) {
    form.push({
      name: 'Powód odmowy podpisania',
      value: inspection.refusalReason,
    });
  }
  if (inspection.repairMethod) {
    form.push({
      name: 'Omówienie poprawek',
      value: inspection.repairMethod,
    });
  }
  if (inspection.cautionValues?.length || inspection.cautionText) {
    form.push({
      name: 'Pouczenie',
      value: UnorderedList({
        values: inspection.cautionValues,
        freeValue: inspection.cautionText,
      }),
    });
  }

  return form;
};
