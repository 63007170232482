import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import _ from 'lodash';

import {
  GetInspectionDto,
  CreateUpdateInspectionMetricDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as InspectionMetricContext from '../../../common/snrwbCore/contexts/InspectionMetricContext';
import { stringify } from '../../../common/snrwbCore/contexts/OrganizationContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { InspectionApi } from '../../../common/snrwbCore/contexts/InspectionContext';
import ValidationAlert from '../../../app/components/ValidationAlert';
import FormSkeleton from '../../../app/components/FormSkeleton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

import { InspectionMetric } from './InspectionMetric';

const MetricNoEdit: React.FC<{
  editAuthorized: boolean;
  inspection: GetInspectionDto;
  api: InspectionApi;
}> = props => {
  const metricPresentation = props.inspection.metric
    ? InspectionMetricContext.MetricToPresent(props.inspection.metric)
    : [];

  const variant = props.inspection.metric.approved ? 'success' : 'danger';
  const heading = props.inspection.metric.approved
    ? 'Metryka została zatwierdzona'
    : 'Metryka nie jest zatwierdzona';

  return (
    <>
      <Alert variant={variant}>
        <Alert.Heading>{heading}</Alert.Heading>
        <hr />
        {props.editAuthorized && !props.inspection.approved && (
          <div className="d-flex justify-content-end">
            <ConfirmationButton
              variant="outline-success"
              roleAnyOf={[Role.InspectionsEdit]}
              onOK={props.api.revertApproveMetric}
              confirmation="Czy na pewno cofnąć zatwierdzenie metryki?"
            >
              Cofnij
            </ConfirmationButton>
          </div>
        )}
      </Alert>
      <ItemPresentation item={metricPresentation} />
    </>
  );
};

const MetricToBeApproved: React.FC<{
  inspection: GetInspectionDto;
  api: InspectionApi;
}> = props => {
  const [showValidation, setShowValidation] = useState(false);
  const [value, setValue] = useState<CreateUpdateInspectionMetricDto>();
  const [showBlock, setShowBlock] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const checkDeletingPossibility = async () => {
    const status = await props.api.mayDeleteInspection();
    const possible = status.state === 'ok';
    setShowBlock(!possible);
    if (status.errors) {
      setErrors(status.errors);
    }
    return possible;
  };

  const onChange = (
    metric: CreateUpdateInspectionMetricDto,
    valid: boolean,
  ) => {
    if (!value || _.isEqual(value, metric)) {
      return;
    }

    setValue(metric);
    if (valid) {
      setShowValidation(false);
      props.api.editMetric(metric);
    } else {
      setShowValidation(true);
    }
  };

  const handleAction = (
    action: (metric: CreateUpdateInspectionMetricDto) => void,
  ) => {
    if (!value) {
      return;
    }
    action(value);
  };

  useEffect(() => {
    setValue(InspectionMetricContext.convert(props.inspection.metric));
  }, [props.inspection]);

  return (
    <>
      <Alert variant="danger" className="mt-3">
        <Alert.Heading>Metryka nie jest zatwierdzona</Alert.Heading>
        <hr />
        <div className="d-flex justify-content-end">
          <ConfirmationButton
            className="me-1"
            variant="outline-danger"
            onOK={() => handleAction(props.api.deleteInspection)}
            roleAnyOf={[Role.InspectionsEdit]}
            onValidation={checkDeletingPossibility}
            confirmation="Czy na pewno usunąć kontrolę?"
          >
            Usuń
          </ConfirmationButton>
          <ConfirmationButton
            variant="outline-primary"
            onOK={() => handleAction(props.api.approveMetric)}
            confirmation="Czy na pewno zatwierdzić metrykę?"
          >
            Zatwierdź
          </ConfirmationButton>
        </div>
      </Alert>
      <InspectionMetric
        showValidationErrors={showValidation}
        savingMode="background"
        value={props.inspection.metric}
        getAddress={() => stringify(props.inspection.metric.organization)}
        onChange={onChange}
      />
      <ValidationAlert
        modal={true}
        show={showBlock}
        onHide={() => setShowBlock(false)}
        errors={errors}
        variant="danger"
      />
    </>
  );
};

export const InspectionMetricEdit: React.FC<{
  editAuthorized: boolean;
  inspection: GetInspectionDto;
  api: InspectionApi;
}> = props => {
  if (!props.inspection.metric) {
    return <FormSkeleton />;
  }

  return props.inspection.metric.approved || !props.editAuthorized ? (
    <MetricNoEdit {...props} />
  ) : (
    <MetricToBeApproved {...props} />
  );
};
