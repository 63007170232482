import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';

import { OrganizationCheckOrForm } from './OrganizationCheckOrForm';

interface OrganizationSelectorProps {
  value: OrganizationContext.Organization;
  readOnly?: boolean;
  showValidationErrors: boolean;
  onSelect: (organization: OrganizationContext.Organization) => void;
  onValidation: (status: boolean) => void;
  onClear?: () => void;
  hideClearButton?: boolean;
}

export const OrganizationSelector: React.FC<OrganizationSelectorProps> =
  props => {
    const [showNewAlert, setShowNewAlert] = useState(false);
    const [selectedExisting, setSelectedExisting] = useState(false);
    const [value, setValue] = useState(props.value);
    const [checkByName, setCheckByName] = useState(0);

    const decision = (newOrganization: boolean) => {
      if (newOrganization) {
        setShowNewAlert(true);
      } else {
        setSelectedExisting(true);
        setShowNewAlert(false);
      }
    };

    const clear = () => {
      setSelectedExisting(false);
      setShowNewAlert(false);
      setValue(OrganizationContext.newOrganization());
      setCheckByName(Date.now());
      if (props.onClear) {
        props.onClear();
      }
    };

    useEffect(() => {
      setValue(props.value);
      setSelectedExisting('id' in props.value);
    }, [props.value]);

    return (
      <>
        <OrganizationCheckOrForm
          showValidationErrors={props.showValidationErrors}
          onSelect={props.onSelect}
          onValidation={props.onValidation}
          onDecision={decision}
          readOnly={selectedExisting}
          value={value}
          checkNow={checkByName}
        />
        {showNewAlert && (
          <Alert variant="warning" className="mt-3">
            <Alert.Heading>Uwaga!</Alert.Heading>
            <p>
              Nie znaleziono podmiotu o takich danych, więc zostanie utworzony
              nowy
            </p>
          </Alert>
        )}
        {!props.hideClearButton && selectedExisting && (
          <div className="d-flex justify-content-end mt-3">
            <Button variant="outline-dark" onClick={clear}>
              Wyczyść
            </Button>
          </div>
        )}
      </>
    );
  };
