import { GridRecord } from './GridDataTypes';
import { nvl } from './nvl';

export default (
  data: GridRecord[],
  size: number,
  pageNo: number,
  orderBy?: string,
  orderDirection?: string,
  filterText?: string,
) => {
  let pageData = data;

  if (orderBy) {
    const property = orderBy;
    const direction = nvl(orderDirection, 'ASC');
    pageData.sort((o1: GridRecord, o2: GridRecord) => {
      const o1vp =
        (o1.orderByValues && o1.orderByValues[property]) ||
        o1.values[property]?.toLocaleString() ||
        '';
      const o2vp =
        (o2.orderByValues && o2.orderByValues[property]) ||
        o2.values[property]?.toLocaleString() ||
        '';

      const comparement = o1vp.localeCompare(o2vp);
      return direction === 'ASC' ? comparement : -comparement;
    });
  }

  if (filterText) {
    pageData = pageData.filter(record => {
      for (const p in record.values) {
        let val = record.values[p]?.toString();
        if (val === '[object Object]' && record.orderByValues) {
          val = record.orderByValues[p];
        }
        // escape special regular expression characters (like . * ?)
        const escaped = filterText
          .toLocaleLowerCase()
          .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        // treat % sign as a wildcard
        const pattern = new RegExp(`.*${escaped.replaceAll('%', '.*')}.*`);

        if (val && pattern.test(val.toLocaleLowerCase())) {
          return true;
        }
      }
      return false;
    });
  }

  const startElement = size * (pageNo - 1);
  const endElement = Math.min(size * pageNo, pageData.length);

  return pageData.slice(startElement, endElement);
};
