import React, { useState, useEffect } from 'react';

import { GetOrganizationDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useAuth } from '../../../common/hooks/useAuth';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import { OrgDocumentsModal } from '../OrgDocumentsModal/OrgDocumentsModal';
import ButtonWithRoleCheck from '../../../app/components/ButtonWithRoleCheck';

import { Organization } from './Organization';

export const OrganizationEditor = (props: {
  organization: GetOrganizationDto;
  onRefresh: () => void;
}) => {
  const [show, setShow] = useState(false);
  const [organization, setOrganization] =
    useState<OrganizationContext.Organization>(props.organization);
  const [valid, setValid] = useState(true);
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const notifications = useNotifications();
  const snrwb = useSnrwbCore();
  const auth = useAuth();

  const handleClose = () => {
    setShow(false);
    props.onRefresh();
  };

  const handleSave = () => {
    if (!valid) {
      setShowValidationErrors(true);
      return;
    }

    const dto = OrganizationContext.toCuDto(organization);
    notifications.onPromise(
      snrwb.organizations.update(props.organization.id, dto),
      handleClose,
    );
  };

  const handleSelect = (organization: OrganizationContext.Organization) => {
    let org = organization;
    if ('activityTypeId' in organization) {
      org = {
        ...organization,
        activityTypeShort: organization.activityTypeShort,
        activityType: {
          ...props.organization.activityType,
          shortname: organization.activityTypeShort,
          id: organization.activityTypeId,
        },
      };
    }
    setOrganization(org);
  };

  useEffect(() => setOrganization(props.organization), [props.organization]);
  useEffect(() => {
    if (show) {
      setValid(true);
      setShowValidationErrors(false);
    }
  }, [show]);

  return (
    <>
      <div className="d-flex justify-content-end">
        <ButtonWithRoleCheck
          onClick={() => setShow(true)}
          variant="outline-success"
          roleAnyOf={[Role.OrganizationsEdit]}
        >
          Edycja
        </ButtonWithRoleCheck>
      </div>
      <OrgDocumentsModal
        title="Edycja danych podmiotu"
        show={show}
        size="small"
        saveBtnLabel="Zapisz"
        deleteBtnText="No delete"
        errors={[]}
        onCompleted={handleSave}
        onDelete={undefined}
        onClose={handleClose}
      >
        <div className="ps-3 pe-3">
          <Organization
            editMode
            value={organization}
            readOnly={!auth.check(Role.OrganizationsEdit)}
            showValidationErrors={showValidationErrors}
            onSelect={handleSelect}
            onValidation={setValid}
          />
        </div>
      </OrgDocumentsModal>
    </>
  );
};
