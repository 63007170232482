import React from 'react';
import { Table } from 'react-bootstrap';

import { ReportJudgmentsCountType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

import { AdditionalOrdersKind } from './AdditionalOrdersKind';
import { ReportJudgmentsKindBody } from './ReportJudgmentsKindBody';

type Props = {
  data: ReportJudgmentsCountType;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportJudgmentsKind: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = Object.keys(data.details).length > 0;

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th />
              {data &&
                hasData &&
                Object.keys(data?.details)
                  .sort()
                  .map((key, idx) => (
                    <th key={idx} colSpan={2}>
                      {key}
                    </th>
                  ))}
              {data && <th colSpan={2}>Suma</th>}
            </tr>
            <tr>
              <th>{reportName}</th>
              {data &&
                hasData &&
                Object.keys(data?.details)
                  .sort()
                  .map(idx => (
                    <React.Fragment key={idx}>
                      <th>ogółem</th>
                      <th>w tym CPR</th>
                    </React.Fragment>
                  ))}
              {data && <th>ogółem</th>}
              {data && <th>w tym CPR</th>}
            </tr>
          </thead>
          <tbody>
            <ReportJudgmentsKindBody data={data} />
          </tbody>
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
      <div className="mb-3"></div>
      <AdditionalOrdersKind
        data={data}
        usedFilter={usedFilter}
        filterApplied={filterApplied}
      />
    </>
  );
};
