import React from 'react';

export default (
  props: React.PropsWithChildren<{ readonly: boolean; value: string }>,
) => (
  <>
    {props.readonly ? (
      <div
        className="col-form-static"
        dangerouslySetInnerHTML={{ __html: props.value }}
      ></div>
    ) : (
      props.children
    )}
  </>
);
