import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import * as CorrectiveActionContext from '../../../common/snrwbCore/contexts/CorrectiveActionContext';
import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  GetCorrectiveActionDto,
  CreateUpdateCorrectiveActionDto,
  CreateUpdateCorrectiveActionDtoFromJSON,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import FormRow from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import BooleanDropdown from '../../../app/components/BooleanDropdown';
import ItemPresentation from '../../../app/components/ItemPresentation';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { ValidationResult } from '../../../common/snrwbCore/validation/validateAgainst';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

const CorrectiveActionNoEdit = (props: {
  readonly: boolean;
  value: GetCorrectiveActionDto;
  role: Role;
  onRevertApprove: () => void;
}) => {
  const presentation = props.value
    ? CorrectiveActionContext.toPresent(props.value)
    : [];

  return (
    <>
      <Alert variant={props.value.approved ? 'success' : 'danger'}>
        <Alert.Heading>
          Działanie naprawcze{' '}
          {props.value.approved ? 'zatwierdzone' : 'niezatwierdzone'}
        </Alert.Heading>
        <hr />
        {!props.readonly && (
          <div className="d-flex justify-content-end">
            <ConfirmationButton
              variant="outline-success"
              onOK={props.onRevertApprove}
              roleAnyOf={[props.role]}
              confirmation="Czy na pewno cofnąć zatwierdzenie działania?"
            >
              Cofnij
            </ConfirmationButton>
          </div>
        )}
      </Alert>
      <ItemPresentation item={presentation} />
    </>
  );
};

export const CorrectiveActionToBeApproved = (props: {
  editMode: boolean;
  readonly: boolean;
  value: CreateUpdateCorrectiveActionDto;
  firstLevelShortname?: string;
  onChange: (dto: CreateUpdateCorrectiveActionDto) => void;
  onApprove: (dto: CreateUpdateCorrectiveActionDto) => void;
}) => {
  const [correctiveAction, setCorrectiveAction] = useState(props.value);
  const [presentedCorrectiveAction, setPresentedCorrectiveAction] =
    useState<CreateUpdateCorrectiveActionDto>(correctiveAction);

  const setProperty = (obj: Partial<CreateUpdateCorrectiveActionDto>) => {
    const dto = { ...correctiveAction, ...obj };
    setCorrectiveAction(dto);
    props.onChange(dto);
  };
  const setPresented = (obj: Partial<CreateUpdateCorrectiveActionDto>) => {
    setPresentedCorrectiveAction({ ...presentedCorrectiveAction, ...obj });
  };

  const presentedToSelected = () => {
    setCorrectiveAction(presentedCorrectiveAction);
    props.onChange(presentedCorrectiveAction);
  };

  useEffect(() => {
    if (props.value) {
      setPresentedCorrectiveAction(props.value);
      setCorrectiveAction(props.value);
    }
  }, [props.value]);

  return (
    <>
      {props.editMode && !props.readonly && (
        <Alert variant="danger" className="mt-3">
          <Alert.Heading>
            Działanie naprawcze nie zostało zatwierdzone
          </Alert.Heading>
          <hr />
          <div className="d-flex justify-content-end">
            <ConfirmationButton
              variant="outline-primary"
              onOK={() => props.onApprove(correctiveAction)}
              confirmation="Czy na pewno zatwierdzić działanie?"
            >
              Zatwierdź
            </ConfirmationButton>
          </div>
        </Alert>
      )}
      <Form className="d-grid gap-3">
        <FormRow controlId="type" label="Rodzaj">
          <DictionarySelector
            dictionary="działanie naprawcze - rodzaj"
            value={presentedCorrectiveAction.typeId}
            firstLevelShortname={props.firstLevelShortname}
            hideBadges
            onChange={option => setProperty({ typeId: option.value })}
          />
        </FormRow>
        <FormRow controlId="date" label="Data">
          <DatePicker
            value={presentedCorrectiveAction.date}
            onChange={date => setProperty({ date: date })}
          />
        </FormRow>
        <FormRow controlId="effective" label="Działanie skuteczne">
          <BooleanDropdown
            value={presentedCorrectiveAction.effective}
            onChange={value => setProperty({ effective: value })}
          />
        </FormRow>
        <FormRow controlId="description" label="Opis">
          <DynamicTextarea
            value={presentedCorrectiveAction.description || ''}
            onChange={e => setPresented({ description: e.target.value })}
            onBlur={presentedToSelected}
          />
        </FormRow>
      </Form>
    </>
  );
};

export const CorrectiveAction = (props: {
  show: boolean;
  readonly: boolean;
  document: GetCorrectiveActionDto;
  data?: string;
  validate: (dto: CreateUpdateCorrectiveActionDto) => Promise<ValidationResult>;
  onCreate: (dto: CreateUpdateCorrectiveActionDto) => void;
  onUpdate: (id: string, dto: CreateUpdateCorrectiveActionDto) => void;
  onApprove: (id: string, dto?: CreateUpdateCorrectiveActionDto) => void;
  onRevertApprove: (id: string) => void;
  onGet: (doc: GetCorrectiveActionDto) => void;
  onDelete: (id: string) => void;
  onClose: () => void;
}) => {
  const [correctiveAction, setCorrectiveAction] = useState(
    CorrectiveActionContext.newCorrectiveAction(),
  );
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);
  const [firstLevelShortname, setFirstLevelShortname] = useState<string>();
  const [deleteRole, setDeleteRole] = useState(Role.InspectionsEdit);
  const addNew = props.document === undefined;

  const tryToSave = (action: CreateUpdateCorrectiveActionDto) => {
    props.validate(action).then(result => {
      if (result.valid) {
        if (addNew) {
          props.onCreate(action);
        } else {
          props.onUpdate(props.document.id, action);
        }
        props.onClose();
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  const addApproved = () => {
    tryToSave({ ...correctiveAction, approved: true });
  };

  const save = () => {
    tryToSave(correctiveAction);
  };

  useEffect(() => {
    const createUpdateDto = props.document
      ? CreateUpdateCorrectiveActionDtoFromJSON(props.document)
      : CorrectiveActionContext.newCorrectiveAction();

    if (props.document) {
      createUpdateDto.typeId = props.document.type.id;
    }

    setCorrectiveAction(createUpdateDto);
    setSaveErrors([]);
    setShowSaveErrors(false);
  }, [props.show, props.document]);

  useEffect(() => {
    if (!props.data) {
      return;
    }
    const data = JSON.parse(props.data);
    if (data.firstLevelShortname) {
      setFirstLevelShortname(data.firstLevelShortname);
    }
    if (data.deleteRole) {
      setDeleteRole(data.deleteRole as Role);
    }
  }, [props.data]);

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      size="xl"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {addNew ? 'Nowe działanie naprawcze' : 'Działanie naprawcze'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.document?.approved || (props.readonly && props.document) ? (
          <CorrectiveActionNoEdit
            readonly={props.readonly}
            value={props.document}
            role={deleteRole}
            onRevertApprove={() => props.onRevertApprove(props.document.id)}
          />
        ) : (
          <>
            <CorrectiveActionToBeApproved
              editMode={props.document !== undefined}
              readonly={props.readonly}
              value={correctiveAction}
              firstLevelShortname={firstLevelShortname}
              onApprove={dto => {
                if (props.document) {
                  props.onApprove(props.document.id, dto);
                }
              }}
              onChange={setCorrectiveAction}
            />
            <ValidationAlert
              show={showSaveErrors}
              errors={saveErrors}
              className="mt-3"
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        {!props.readonly && (
          <>
            {addNew ? (
              <Button variant="outline-warning" onClick={addApproved}>
                Dodaj i Zatwierdź
              </Button>
            ) : (
              <ConfirmationButton
                variant="outline-danger"
                roleAnyOf={[deleteRole]}
                onOK={() => props.onDelete(props.document.id)}
                confirmation="Czy na pewno usunąć działanie naprawcze?"
              >
                Usuń
              </ConfirmationButton>
            )}
            <Button variant="outline-primary" onClick={save}>
              {addNew ? 'Dodaj' : 'Zapisz'}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
