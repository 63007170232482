import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as Icon from 'react-bootstrap-icons';

export default ({
  className,
  tooltip,
}: {
  className?: string;
  tooltip?: string;
}) => (
  <OverlayTrigger
    placement="bottom"
    overlay={<Tooltip>{tooltip || 'Typ wyrobu nie jest zatwierdzony'}</Tooltip>}
  >
    <Icon.Bricks className={(className || '') + ' text-danger'} />
  </OverlayTrigger>
);
