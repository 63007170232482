/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryTypeDto,
    GetDictionaryTypeDtoFromJSON,
    GetDictionaryTypeDtoFromJSONTyped,
    GetDictionaryTypeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetDictionaryDto
 */
export interface GetDictionaryDto {
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryDto
     */
    shortname: string;
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryDto
     */
    value: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetDictionaryDto
     */
    parent: GetDictionaryDto;
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryDto
     */
    parentId: string;
    /**
     * 
     * @type {GetDictionaryTypeDto}
     * @memberof GetDictionaryDto
     */
    dictionaryType: GetDictionaryTypeDto;
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryDto
     */
    dictionaryTypeId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDictionaryDto
     */
    active: boolean;
}

export function GetDictionaryDtoFromJSON(json: any): GetDictionaryDto {
    return GetDictionaryDtoFromJSONTyped(json, false);
}

export function GetDictionaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDictionaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'shortname': json['shortname'],
        'value': json['value'],
        'parent': GetDictionaryDtoFromJSON(json['parent']),
        'parentId': json['parentId'],
        'dictionaryType': GetDictionaryTypeDtoFromJSON(json['dictionaryType']),
        'dictionaryTypeId': json['dictionaryTypeId'],
        'active': json['active'],
    };
}

export function GetDictionaryDtoToJSON(value?: GetDictionaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shortname': value.shortname,
        'value': value.value,
        'parent': GetDictionaryDtoToJSON(value.parent),
        'parentId': value.parentId,
        'dictionaryType': GetDictionaryTypeDtoToJSON(value.dictionaryType),
        'dictionaryTypeId': value.dictionaryTypeId,
        'active': value.active,
    };
}


