import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import FormRow from '../../../../app/components/FormRow';
import DatePicker from '../../../../app/components/DatePicker';
import ValidationAlert from '../../../../app/components/ValidationAlert';
import { CreateUpdateCustomsFeedbackDto } from '../../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as CustomsFeedbackContext from '../../../../common/snrwbCore/contexts/CustomsFeedbackContext';
import BooleanDropdown from '../../../../app/components/BooleanDropdown';
import DynamicTextarea from '../../../../app/components/DynamicTextarea';
import momencik from '../../../../common/momencik';

export const CustomsFeedback: React.FC<{
  onChange: (customsFeedback: CreateUpdateCustomsFeedbackDto) => void;
  showValidationErrors: boolean;
  validationErrors: string[];
  value?: CreateUpdateCustomsFeedbackDto;
  producer?: string;
}> = props => {
  const [customsFeedback, setCustomsFeedback] = useState(
    CustomsFeedbackContext.newCustomsFeedback(),
  );

  const propertyChange = (obj: Partial<CreateUpdateCustomsFeedbackDto>) => {
    const dto = { ...customsFeedback, ...obj };
    if (momencik(dto.receiptDate) === momencik(dto.releaseDate)) {
      dto.receiptDate = dto.releaseDate;
    }
    setCustomsFeedback(dto);
    return dto;
  };

  const propagate = (obj?: CreateUpdateCustomsFeedbackDto) => {
    props.onChange(obj || customsFeedback);
  };

  useEffect(() => {
    const feedback = props.value || CustomsFeedbackContext.newCustomsFeedback();
    setCustomsFeedback(feedback);
  }, [props.value]);

  return (
    <>
      <Form className="d-grid gap-3">
        {props.producer && (
          <FormRow controlId="producer" label="Producent" static>
            {props.producer}
          </FormRow>
        )}
        <FormRow controlId="date" label="Data wpływu">
          <DatePicker
            value={customsFeedback.receiptDate}
            onChange={dt => propagate(propertyChange({ receiptDate: dt }))}
          />
        </FormRow>
        <FormRow controlId="note" label="Nazwa i typ wyrobu">
          <DynamicTextarea
            value={customsFeedback.name || ''}
            onChange={e => propertyChange({ name: e.target.value })}
            onBlur={() => propagate()}
          />
        </FormRow>
        <FormRow controlId="note" label="Zastosowanie">
          <DynamicTextarea
            value={customsFeedback.usage || ''}
            onChange={e => propertyChange({ usage: e.target.value })}
            onBlur={() => propagate()}
          />
        </FormRow>
        <FormRow controlId="importer" label="Importer">
          <DynamicTextarea
            value={customsFeedback.importer || ''}
            onChange={e => propertyChange({ importer: e.target.value })}
            onBlur={() => propagate()}
          />
        </FormRow>
        <FormRow controlId="constructionProduct" label="Wyr. budowlany">
          <BooleanDropdown
            value={customsFeedback.constructionProduct}
            canBeUndefined={true}
            onChange={value =>
              propagate(propertyChange({ constructionProduct: value }))
            }
          />
        </FormRow>
        <FormRow controlId="number" label="Numer opinii">
          <Form.Control
            type="text"
            value={customsFeedback.number || ''}
            onChange={e => propertyChange({ number: e.target.value })}
            onBlur={() => propagate()}
          />
        </FormRow>
        <FormRow controlId="date" label="Data wydania">
          <DatePicker
            value={customsFeedback.releaseDate}
            onChange={dt => propagate(propertyChange({ releaseDate: dt }))}
          />
        </FormRow>
        <FormRow controlId="note" label="Organ celny">
          <DynamicTextarea
            value={customsFeedback.customsAuthority || ''}
            onChange={e => propertyChange({ customsAuthority: e.target.value })}
            onBlur={() => propagate()}
          />
        </FormRow>
        <FormRow controlId="positive" label="Opinia pozytywna">
          <BooleanDropdown
            value={customsFeedback.positive}
            canBeUndefined={true}
            onChange={value => propagate(propertyChange({ positive: value }))}
          />
        </FormRow>
      </Form>
      {props.showValidationErrors && (
        <ValidationAlert
          show={props.validationErrors.length > 0}
          errors={props.validationErrors}
          className="mt-3"
        />
      )}
    </>
  );
};
