import React from 'react';
import { EditorState } from 'draft-js';
import * as staticToolbar from '@draft-js-plugins/static-toolbar';

import { LambdaButton, SubscriptButton, SuperscriptButton } from './Buttons';

export const toolbarConfig = {
  theme: {
    buttonStyles: {
      active: 'active',
      button: 'btn btn-outline-success',
    },
    toolbarStyles: { toolbar: 'rte-inline-toolbar' },
  },
};

export default (props: {
  value: EditorState;
  readonly?: boolean;
  toolbarPlugin: staticToolbar.StaticToolBarPlugin;
  setValue: (value: EditorState) => void;
  focus: () => void;
  onlyIndexes?: boolean;
}) => {
  const { Toolbar } = props.toolbarPlugin;

  return (
    <Toolbar>
      {externalProps => (
        <>
          {!props.onlyIndexes && (
            <LambdaButton
              className="ms-1"
              variant="green"
              editorState={props.value}
              setState={props.setValue}
              focus={props.focus}
            />
          )}
          <SubscriptButton {...externalProps} className="mx-1" />
          <SuperscriptButton {...externalProps} />
        </>
      )}
    </Toolbar>
  );
};
