/*eslint max-lines-per-function: ["error", 240]*/

import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { GetOrganizationalUnitDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetOrganizationalUnitDto';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import DatePicker from '../../../app/components/DatePicker';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';

export type FilterReportDataExtended = {
  dateFrom?: Date;
  dateTo?: Date;
  organizationalUnitId?: string;
  productDescription?: string;
  product?: string;
  dateFromProtocol?: Date;
  dateToProtocol?: Date;
};

export type FilterReportYearData = {
  newYear?: number;
  organizationalUnitId?: string;
};

type FilterType =
  | 'dateFrom'
  | 'dateTo'
  | 'organizationalUnit'
  | 'productDescription'
  | 'product'
  | 'dateToProtocol';

interface FilterProps {
  initialValues?: FilterReportDataExtended;
  onSearch: (filter?: FilterReportDataExtended) => void;
  reportName: string;
  dateFromName?: string;
  dateToName?: string;
  reportOrganizationalUnitName?: string;
  productDescription?: string;
  filterList?: FilterType[];
}

export const FilterExtended: React.FC<FilterProps> = props => {
  const snrwbApi = useSnrwbCore();

  const [organizationalUnits, setOrganizationalUnits] = useState<
    GetOrganizationalUnitDto[]
  >([]);

  const [showNewAlert, setShowNewAlert] = useState(false);
  const [formData, setFormData] = useState<FilterReportDataExtended>(
    props.initialValues || {},
  );

  const filterList = props.filterList || [
    'dateFrom',
    'dateTo',
    'organizationalUnit',
    'productDescription',
  ];

  useEffect(() => {
    snrwbApi.organizationalUnits
      .getMany(OrganizationalUnitsGetOptions.notNotified)
      .then(orgUnit => {
        setOrganizationalUnits(orgUnit);
      });
  }, [snrwbApi]);

  const handleClear = () => {
    setFormData({ organizationalUnitId: '' });
    setShowNewAlert(false);
  };

  const handleSearch = () => {
    if (
      formData.dateFrom &&
      formData.dateTo &&
      formData.dateFrom > formData.dateTo
    ) {
      setShowNewAlert(true);
      return;
    }
    setShowNewAlert(false);
    if (formData.organizationalUnitId === '') {
      formData.organizationalUnitId = undefined;
    }
    props.onSearch(formData);
  };

  const propertyChange = (obj: Partial<FilterReportDataExtended>) => {
    setFormData({ ...formData, ...obj });
  };

  return (
    <Card className="mb-2">
      <Card.Header>{props.reportName}</Card.Header>
      <Card.Body>
        <Form className="d-grid gap-3">
          {filterList.includes('dateFrom') && (
            <Row>
              <Form.Group as={Row} controlId="dateFrom">
                <Form.Label column sm="3">
                  {props.dateFromName || 'Data wydania od'}
                </Form.Label>
                <Col sm="9">
                  <DatePicker
                    value={formData.dateFrom}
                    midnight={true}
                    onChange={date =>
                      propertyChange({ dateFrom: date || undefined })
                    }
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {filterList.includes('dateTo') && (
            <Row>
              <Form.Group as={Row} controlId="dateTo">
                <Form.Label column sm="3">
                  {props.dateToName || 'Data wydania do'}
                </Form.Label>
                <Col sm="9">
                  <DatePicker
                    value={formData.dateTo}
                    midnight={true}
                    onChange={date =>
                      propertyChange({ dateTo: date || undefined })
                    }
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {filterList.includes('organizationalUnit') && (
            <Row>
              <Form.Group as={Row} controlId="organ">
                <Form.Label column sm="3">
                  {props.reportOrganizationalUnitName || 'Organ'}
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    className="form-select"
                    as="select"
                    value={formData.organizationalUnitId}
                    onChange={e =>
                      propertyChange({ organizationalUnitId: e.target.value })
                    }
                  >
                    <option value={''}>Wszystkie</option>
                    {organizationalUnits.map((o, idx) => (
                      <option value={o.id} key={idx}>
                        {o.shortname}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
            </Row>
          )}
          {filterList.includes('productDescription') && (
            <Row>
              <Form.Group as={Row} controlId="productDescription">
                <Form.Label column sm="3">
                  {props.productDescription || 'Opis wyrobu'}
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    value={formData.productDescription || ''}
                    onChange={e =>
                      propertyChange({ productDescription: e.target.value })
                    }
                    placeholder="Wprowadź nazwę wyrobu"
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {filterList.includes('product') && (
            <Row>
              <Form.Group as={Row} controlId="product">
                <Form.Label column sm="3">
                  {'Strona (podmiot)'}
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    value={formData.product || ''}
                    onChange={e => propertyChange({ product: e.target.value })}
                    placeholder="Wprowadź podmiot"
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
          {filterList.includes('dateToProtocol') && (
            <Row>
              <Form.Group as={Row} controlId="dateToProtocol">
                <Form.Label column sm="3">
                  {'Data podpisania protokołu'}
                </Form.Label>
                <Col sm="9">
                  <DatePicker
                    value={formData.dateToProtocol}
                    midnight={true}
                    onChange={date =>
                      propertyChange({ dateToProtocol: date || undefined })
                    }
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
        </Form>

        {showNewAlert && (
          <Alert variant="danger" className="mt-3">
            Zły zakres dat
          </Alert>
        )}

        <div className="d-flex justify-content-end mt-3">
          <Button className="me-2" variant="outline-dark" onClick={handleClear}>
            Wyczyść
          </Button>
          <Button variant="outline-primary" onClick={handleSearch}>
            Szukaj
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
