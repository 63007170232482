import React, { useState, useEffect } from 'react';
import Select from 'react-select';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import oneliner from '../../../common/oneliner';

import { Option } from './Selector';

export default (props: {
  dictionaryName: string;
  value?: string[];
  onChange: (orderId: string[]) => void;
}) => {
  const snrwb = useSnrwbCore();
  const [values, setValues] = useState<Option[]>();
  const [selected, setSelected] = useState<Option[]>([]);

  useEffect(() => {
    if (!snrwb.dictionaries || !props.dictionaryName) {
      return;
    }
    let mounted = true;
    snrwb.dictionaries
      .getByDictionaryType(props.dictionaryName)
      .then(orders => {
        if (mounted) {
          setValues(
            orders.map(o => {
              return {
                value: o.id,
                label: oneliner(o.value, 140),
              };
            }),
          );
        }
      });
    return () => {
      mounted = false;
    };
  }, [snrwb.dictionaries, props.dictionaryName]);

  useEffect(() => {
    if (!values || !props.value) {
      return;
    }
    const selection = [];
    for (const v of props.value) {
      const pos = values.filter(o => o.value === v)[0];
      selection.push(pos);
    }
    setSelected(selection);
  }, [props.value, values]);

  return (
    <Select
      isMulti
      name={props.dictionaryName}
      options={values}
      placeholder="Wskaż..."
      value={selected}
      onChange={options => props.onChange(options.map(o => o.value))}
    />
  );
};
