import React from 'react';
import { Table } from 'react-bootstrap';

import { ReportProductsByMisstatement } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';
import { ReportLegend } from '../Reports/ReportLegend';
import { Abbreviation } from '../../../common/abbreviationLegend';

type Props = {
  data: ReportProductsByMisstatement;
  reportName: string;
  reportCols: string[];
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
  legend?: Abbreviation[];
};

export const ReportInspectedProductsByMisstatement: React.FC<Props> = ({
  data,
  reportName,
  reportCols,
  filterApplied,
  usedFilter,
  legend,
}) => {
  const hasData = Object.keys(data.details).length > 0;

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter} legend={legend}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={21}>{reportName}</th>
            </tr>
            <tr>
              <th rowSpan={2}>Organ</th>
              {reportCols.map(rn => (
                <th key={rn} colSpan={2}>
                  {rn}
                </th>
              ))}
            </tr>
            <tr>
              {reportCols.map(rn => (
                <DetailsNcpr key={rn} rn={rn} />
              ))}
            </tr>
          </thead>

          {hasData && (
            <tbody>
              {Object.keys(data.details)
                .sort()
                .map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    {reportCols.map(rn => (
                      <GetCountType
                        key={rn}
                        data={data}
                        orU={key}
                        shortname={rn}
                      />
                    ))}
                  </tr>
                ))}
              <tr>
                <td>Suma:</td>
                {reportCols.map(rn => (
                  <SumNcpr key={rn} data={data} rn={rn} />
                ))}
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
      {legend && <ReportLegend legend={legend} />}
    </>
  );
};

const DetailsNcpr: React.FC<{ rn: string }> = ({ rn }) => {
  return (
    <>
      <th key={rn + 'N'} rowSpan={1}>
        N
      </th>
      <th key={rn + 'NCPR'} rowSpan={1}>
        NCPR
      </th>
    </>
  );
};

const SumNcpr: React.FC<{ data: ReportProductsByMisstatement; rn: string }> = ({
  data,
  rn,
}) => {
  return (
    <>
      <td key={rn} className="numbers-column">
        {data.sum[rn] ? data.sum[rn]['N'] || 0 : 0}
      </td>
      <td key={`${rn}1`} className="numbers-column">
        {data.sum[rn] ? data.sum[rn]['NCPR'] || 0 : 0}
      </td>
    </>
  );
};

type GetCountProps = {
  data: ReportProductsByMisstatement;
  orU: string;
  shortname: string;
};
const GetCountType: React.FC<GetCountProps> = ({ data, orU, shortname }) => {
  const countCprn = data.details[orU][shortname]
    ? data.details[orU][shortname]['NCPR'] || 0
    : 0;
  const countN = data.details[orU][shortname]
    ? data.details[orU][shortname]['N'] || 0
    : 0;
  return (
    <>
      <td className="numbers-column">{countN}</td>
      <td className="numbers-column">{countCprn}</td>
    </>
  );
};
