import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import Tooltip from 'react-bootstrap/Tooltip';

import { GetDictionaryDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

interface DecisionProps {
  decisionSign: GetDictionaryDto;
  decisionIndex: GetDictionaryDto;
}

export const DecisionCode: React.FC<DecisionProps> = props => {
  return (
    <>
      {props.decisionSign ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              {'Symbol kodu decyzji: '}
              <br />
              {props.decisionSign.value}
            </Tooltip>
          }
        >
          <Badge bg="info">{props.decisionSign.shortname}</Badge>
        </OverlayTrigger>
      ) : (
        <></>
      )}{' '}
      {props.decisionIndex ? (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              {'Indeks kodu decyzji: '}
              <br />
              {props.decisionIndex.value}
            </Tooltip>
          }
        >
          <Badge bg="warning" text="dark">
            {props.decisionIndex.shortname}
          </Badge>
        </OverlayTrigger>
      ) : (
        <></>
      )}
    </>
  );
};
