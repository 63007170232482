import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';

import {
  Organization,
  newOrganization,
} from '../../../common/snrwbCore/contexts/OrganizationContext';
import {
  TechAssessmentDto,
  convert,
  newTechAssessment,
  validate,
} from '../../../common/snrwbCore/contexts/TechAssessmentContext';
import { OrgDocumentsModal } from '../OrgDocumentsModal/OrgDocumentsModal';
import { OrgFormCard } from '../OrgDocumentsModal/OrgFormCard';
import { useAuth } from '../../../common/hooks/useAuth';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import {
  GetAttachmentDto,
  CreateUpdateAttachmentDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';

import { TechAssessmentFormCard } from './TechAssessmentFormCard';

const getOrganization = (org?: Organization) => org || newOrganization();

interface TechAssessmentFormModalProps {
  organization?: Organization;
  techAssessment?: TechAssessmentDto;
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  onCompleted: (
    organization: Organization,
    techAssessment: TechAssessmentDto,
    id?: string,
  ) => Promise<{
    saved: boolean;
    errors: string[];
  }>;
  onDelete: (() => void) | undefined;
  onClose?: () => void;
  onUpdateAttachments?: () => void;
}

export const TechAssessmentAction: React.FC<TechAssessmentFormModalProps> =
  props => {
    const [show, setShow] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [organization, setOrganization] = useState<Organization>(
      getOrganization(props.organization),
    );
    const [techAssessment, setTechAssessment] = useState(
      props.techAssessment ? props.techAssessment : newTechAssessment(),
    );
    const [producer, setProducer] = useState<string>();
    const [saveErrors, setSaveErrors] = useState<string[]>([]);
    const [orgValid, setOrgValid] = useState(false);
    const [showFormErrors, setShowFormErrors] = useState(false);
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const mountedRef = useRef(false);
    const auth = useAuth();
    const notifications = useNotifications();
    const editAuthorized = auth.check(Role.TechAssessmentsEdit);

    const createTechAssessment = () => {
      const dto =
        'id' in techAssessment ? convert(techAssessment) : techAssessment;

      validate(dto).then(status => {
        if (!status.valid || (!props.organization && !orgValid)) {
          setShowFormErrors(true);
          setFormErrors(status.errors);
          return;
        }

        props
          .onCompleted(
            organization,
            techAssessment,
            props.techAssessment && 'id' in props.techAssessment
              ? props.techAssessment.id
              : undefined,
          )
          .then(saveStatus => {
            if (mountedRef.current) {
              if (!saveStatus.saved) {
                setSaveErrors(saveStatus.errors);
                return;
              }
              setShow(false);
              setAddNew(false);
              setTechAssessment(newTechAssessment());
            }
          });
      });
    };

    const onChangeTechAssessment = (techAssessment: TechAssessmentDto) => {
      setTechAssessment(techAssessment);
    };

    useEffect(() => {
      setOrganization(getOrganization(props.organization));
      setSaveErrors([]);
    }, [props.organization]);

    useEffect(() => {
      mountedRef.current = true;
      setProducer(undefined);
      setTechAssessment(
        props.techAssessment ? props.techAssessment : newTechAssessment(),
      );
      setShowFormErrors(false);
      setFormErrors([]);
      setSaveErrors([]);

      if (props.techAssessment) {
        if ('producer' in props.techAssessment) {
          setProducer(props.techAssessment.producer.name);
        }
        setShow(true);
      }

      return () => {
        mountedRef.current = false;
      };
    }, [props.techAssessment]);

    const handleAddNew = () => {
      if (!editAuthorized) {
        notifications.unauthorized();
        return;
      }
      setOrganization(getOrganization(props.organization));
      setTechAssessment(newTechAssessment());
      setShowFormErrors(false);
      setShow(true);
      setAddNew(true);
    };

    const clear = () => {
      setOrganization(newOrganization());
      setOrgValid(false);
    };

    const handleClose = () => {
      setShow(false);
      setAddNew(false);
      setOrganization(newOrganization());
      setTechAssessment(newTechAssessment());
      props.onClose && props.onClose();
    };

    const handleDelete = () => {
      setShow(false);
      if (props.onDelete) {
        props.onDelete();
      }
    };

    const variant = (condition: boolean, iftrue: string, iffalse: string) =>
      condition ? iftrue : iffalse;

    return (
      <>
        <Button variant="outline-primary" onClick={handleAddNew}>
          Nowa ocena techniczna
        </Button>

        <OrgDocumentsModal
          title={variant(
            addNew,
            'Tworzenie nowej oceny technicznej',
            'Edycja oceny technicznej',
          )}
          errors={saveErrors}
          onClose={handleClose}
          onCompleted={editAuthorized ? createTechAssessment : undefined}
          onDelete={!addNew && editAuthorized ? handleDelete : undefined}
          deleteRole={Role.TechAssessmentsRevoke}
          show={show}
          size={!props.organization ? 'large' : 'small'}
          saveBtnLabel={variant(addNew, 'Dodaj', 'Zapisz')}
          deleteBtnText="Czy na pewno usunąć ocenę techniczną?"
        >
          {!props.organization && (
            <Col className="col-lg-6 col-12 mb-1">
              <OrgFormCard
                organization={organization}
                onChange={setOrganization}
                onValidation={setOrgValid}
                onClear={clear}
                showErrors={!orgValid && showFormErrors}
              />
            </Col>
          )}

          <Col
            className={
              !props.organization
                ? 'col-lg-6 col-12 mb-1'
                : 'col-lg-12 col-12 mb-1'
            }
          >
            <TechAssessmentFormCard
              techAssessment={techAssessment}
              attachments={props.attachments}
              editAuthorized={editAuthorized}
              producer={producer}
              onChange={onChangeTechAssessment}
              showErrors={showFormErrors}
              errors={formErrors}
              onUpdateAttachments={props.onUpdateAttachments}
            />
          </Col>
        </OrgDocumentsModal>
      </>
    );
  };
