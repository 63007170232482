import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateMisstatementDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import DictionarySelector from '../Selects/DictionarySelector';
import { AddAssociateModalProps } from '../AssociatedDocuments/AssociateDocumentsTypes';
import FormRow from '../../../app/components/FormRow';
import TextOrControl from '../../../app/components/TextOrControl';
import DynamicTextarea from '../../../app/components/DynamicTextarea';

export const AddMisstatement = (
  props: AddAssociateModalProps<CreateUpdateMisstatementDto>,
) => {
  const [misstatement, setMisstatement] = useState(props.newAssociate());
  const [misstatementText, setMisstatementText] = useState<string>('');
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const setVariance = (variance: string) => {
    setMisstatement({ ...misstatement, varianceId: variance });
  };

  const setOther = (other: string) => {
    setMisstatement({ ...misstatement, other });
  };

  const onAdd = () => {
    props.validate(misstatement).then(result => {
      if (result.valid) {
        props.onCreate(misstatement);
        setMisstatementText('');
        return;
      }
      setShowSaveErrors(true);
      setSaveErrors(result.errors);
    });
  };

  useEffect(() => {
    setMisstatement(props.newAssociate());
    setSaveErrors([]);
    setShowSaveErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      size="xl"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Nowa nieprawidłowość</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="type" label="Typ">
            <DictionarySelector
              dictionary="nieprawidłowość"
              value={misstatement.varianceId}
              onChange={option => {
                setVariance(option.value);
              }}
            />
          </FormRow>
          {/* const id for inne nieprawidłowości dict value */}
          {misstatement.varianceId ===
            'be8a5a53-cedc-4108-b5ed-07a32cb97ef9' && (
            <FormRow controlId="typeText" label="Opis nieprawidłowości">
              <TextOrControl readonly={false} value={misstatementText}>
                <DynamicTextarea
                  value={misstatementText}
                  onChange={e => setMisstatementText(e.target.value)}
                  onBlur={e => setOther(e.target.value)}
                />
              </TextOrControl>
            </FormRow>
          )}
        </Form>
        <ValidationAlert
          show={showSaveErrors}
          errors={saveErrors}
          className="mt-3"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => {
            props.onClose();
            setMisstatementText('');
          }}
        >
          Zamknij
        </Button>
        <Button variant="outline-primary" onClick={onAdd}>
          Dodaj
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
