import { GetProductTypeDto } from '../autogenerated/snrwbApiClient/models/GetProductTypeDto';
import {
  CreateUpdateProductTypeDto,
  CreateUpdateProductTypeDtoFromJSON,
  ValidationStatus,
} from '../autogenerated/snrwbApiClient/models';
import {
  Option,
  defaultSelectorLimit,
} from '../../../snrwb/components/Selects/Selector';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import { ProductTypeSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';
import { GridRecord } from '../../components/Grid/GridDataTypes';
import { productTypeForGrid } from '../../../snrwb/components/ProductTypes/ProductTypeSearch';
import DictionaryStatic from '../../../snrwb/components/Dictionary/DictionaryStatic';
import { SnrwbCoreContextType } from '..';
import nkitwDesc from '../../nkitwDesc';

import { Organization, address } from './OrganizationContext';

export type ProductType = GetProductTypeDto | CreateUpdateProductTypeDto;

export const newProductType = () =>
  CreateUpdateProductTypeDtoFromJSON({
    cpr: false,
    constructionProduct: true,
    approved: false,
  });

export const convert = (dto: ProductType) => {
  const cuDto = CreateUpdateProductTypeDtoFromJSON(dto);
  if ('organization' in dto && dto.organization) {
    cuDto.organizationId = dto.organization.id;
  }
  if ('group' in dto && dto.group) {
    cuDto.groupId = dto.group.id;
  }
  if ('sign' in dto && dto.sign) {
    cuDto.signId = dto.sign.id;
  }
  return cuDto;
};

export type AddProductTypeFunc = (
  organization: Organization,
  productType: ProductType,
  fileNumber?: string,
) => Promise<boolean>;

export interface ProductTypeContextInterface {
  getById: (id: string) => Promise<GetProductTypeDto>;
  getAllByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
  ) => Promise<GridRecord[]>;
  getAllCount: (filterText?: string) => Promise<number>;
  getByOrganizationByPortion: (
    pageSize: number,
    pageNumber: number,
    orderBy?: string,
    orderDirection?: string,
    filterText?: string,
    organizationId?: string,
  ) => Promise<GridRecord[]>;
  getByOrganizationCount: (
    filterText?: string,
    organizationId?: string,
  ) => Promise<number>;
  getOptionsByNkitw: (text: string) => Promise<Option[]>;
  getOptionsByProducerNkitw: (
    producerId: string,
    text: string,
  ) => Promise<Option[]>;
  getOptionsByExamAndType: (
    examId: string,
    typeId: string,
    text: string,
  ) => Promise<Option[]>;
  validateNew: (dto: CreateUpdateProductTypeDto) => Promise<ValidationStatus>;
  update: (id: string, dto: CreateUpdateProductTypeDto) => Promise<void>;
  mayBeApproved: (id: string) => Promise<ValidationStatus>;
  approve: (id: string, status: ValidationStatus) => Promise<void>;
  revertApprove: (id: string) => Promise<void>;
  mergeProductTypes: (
    prodDeleteId: string,
    prodStayId: string,
  ) => Promise<void>;
}

export const ProductTypeContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.productTypeControllerGet(id),

    getAllByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      additionalId?: string,
    ) => {
      let data = await api.productTypeControllerGetAllByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
      );
      if (additionalId) {
        data = data.map(o => {
          return { ...o, headId: additionalId };
        });
      }
      return data.map(productTypeForGrid);
    },

    getAllCount: (filterText?: string) =>
      api.productTypeControllerGetAllCount(filterText || ''),

    getByOrganizationByPortion: async (
      pageSize: number,
      pageNumber: number,
      orderBy?: string,
      orderDirection?: string,
      filterText?: string,
      organizationId?: string,
    ) => {
      const data = await api.productTypeControllerGetByOrganizationByPortion(
        pageSize,
        pageNumber,
        orderBy || '',
        orderDirection || '',
        filterText || '',
        organizationId || '',
      );
      return data.map(productTypeForGrid);
    },

    getByOrganizationCount: (filterText?: string, organizationId?: string) =>
      api.productTypeControllerGetByOrganizationCount(
        filterText || '',
        organizationId || '',
      ),

    getOptionsByNkitw: async (text: string) => {
      const list = await api.productTypeControllerGetByPattern(
        text,
        defaultSelectorLimit,
      );
      return list.map(productType => {
        return {
          value: productType.id,
          label: nkitwDesc(productType),
        };
      });
    },

    getOptionsByProducerNkitw: async (producer: string, text: string) => {
      const list = await api.productTypeControllerGetByProducerAndPattern(
        producer,
        text,
        defaultSelectorLimit,
      );
      return list.map(productType => {
        return {
          value: productType.id,
          label: nkitwDesc(productType),
        };
      });
    },

    getOptionsByExamAndType: async (
      examId: string,
      typeId: string,
      text: string,
    ) => {
      const list = await api.productTypeControllerGetLinkedToExamSample(
        examId,
        typeId,
        text,
        defaultSelectorLimit,
      );
      return list.map(item => {
        return {
          value: item.inspectionId,
          label: item.label,
        };
      });
    },

    validateNew: (dto: CreateUpdateProductTypeDto) =>
      api.productTypeControllerValidateNew(dto),

    update: (id: string, dto: CreateUpdateProductTypeDto) =>
      api.productTypeControllerUpdate(id, dto),

    mayBeApproved: async (id: string) =>
      api.productTypeControllerMayBeApproved(id),

    approve: (id: string, status: ValidationStatus) =>
      api.productTypeControllerApprove(id, status),

    revertApprove: (id: string) => api.productTypeControllerRevertApprove(id),

    mergeProductTypes: (prodDeleteId: string, prodStayId: string) =>
      api.productTypeControllerMergeProductTypes(prodDeleteId, prodStayId),
  };
};

export const validate = (productType: ProductType) =>
  validateAgainst(ProductTypeSchema, productType);

export const ProductTypeToPresent = (productType: GetProductTypeDto) => {
  const presentation: { name: string; value: string | number | JSX.Element }[] =
    [
      {
        name: 'Producent',
        value:
          productType.organization.name +
          ', ' +
          address(productType.organization),
      },
      {
        name: productType.nkitw ? 'NKITW/TW' : 'Opis',
        value: productType.nkitw ? productType.nkitw : productType.description,
      },
      {
        name: 'Numer systemowy',
        value: productType.sysnumber,
      },
      {
        name: 'Grupa',
        value: productType.group.value,
      },
    ];

  if (productType.sign) {
    presentation.push({
      name: 'Oznakowanie',
      value: DictionaryStatic({ value: productType.sign }),
    });
  }
  if (productType.nkitw && productType.description) {
    presentation.push({
      name: 'Opis',
      value: productType.description,
    });
  }
  presentation.push({
    name: 'Wyrób budowlany',
    value: productType.constructionProduct ? 'Tak' : 'Nie',
  });

  return presentation;
};

const GetGroupValue = async (snrwb: SnrwbCoreContextType, groupId: string) => {
  let groupValue = '';
  if (groupId) {
    groupValue = (await snrwb.dictionaries.getById(groupId)).value;
  }
  return groupValue;
};

const TakNie = (field: boolean): string => {
  return field === true ? 'Tak' : 'Nie';
};

export const ProductTypeToPresentHistoryWithPrev = async (
  snrwb: SnrwbCoreContextType,
  productType: GetProductTypeDto,
  prevProductType: GetProductTypeDto,
) => {
  const groupValue = { value: await GetGroupValue(snrwb, productType.groupId) };
  let prevGroupValue = undefined;
  if (prevProductType) {
    prevGroupValue = {
      value: await GetGroupValue(snrwb, prevProductType?.groupId),
    };
  }

  const presentation: {
    name: string;
    value: string | number | JSX.Element;
    prevValue: string | number | JSX.Element | undefined;
    equal: boolean;
  }[] = [
    {
      name: 'NKITW/TW',
      value: productType.nkitw,
      prevValue: prevProductType?.nkitw,
      equal: productType.nkitw === prevProductType?.nkitw,
    },
    {
      name: 'Numer systemowy',
      value: productType.sysnumber,
      prevValue: prevProductType?.sysnumber,
      equal: productType.sysnumber === prevProductType?.sysnumber,
    },
    {
      name: 'Grupa',
      value: groupValue.value,
      prevValue: prevGroupValue?.value,
      equal: groupValue.value === prevGroupValue?.value,
    },
  ];

  if (productType.signId || prevProductType?.signId) {
    const signDict = DictionaryStatic({
      value: await snrwb.dictionaries.getById(productType.signId),
    });

    let prevSignDict = undefined;

    if (prevProductType) {
      prevSignDict = DictionaryStatic({
        value: await snrwb.dictionaries.getById(prevProductType.signId),
      });
    }

    presentation.push({
      name: 'Oznakowanie',
      value: signDict,
      prevValue: prevSignDict,
      equal: productType?.signId === prevProductType?.signId,
    });
  }
  presentation.push({
    name: 'Opis',
    value: productType.description,
    prevValue: prevProductType?.description,
    equal: productType.description === prevProductType?.description,
  });

  presentation.push({
    name: 'Wyrób budowlany',
    value: TakNie(productType.constructionProduct),
    prevValue: prevProductType
      ? TakNie(prevProductType.constructionProduct)
      : undefined,
    equal:
      productType.constructionProduct === prevProductType?.constructionProduct,
  });

  presentation.push({
    name: 'Wyświetlać opis z NKITW/TW',
    value: productType.displayDesc ? 'Tak' : 'Nie',
    prevValue: prevProductType
      ? TakNie(prevProductType.displayDesc)
      : undefined,
    equal: productType.displayDesc === prevProductType?.displayDesc,
  });

  presentation.push({
    name: 'Zatwierdzony',
    value: TakNie(productType.approved),
    prevValue: prevProductType ? TakNie(prevProductType.approved) : undefined,
    equal: productType.approved === prevProductType?.approved,
  });

  return presentation;
};

export const ProductTypeCreationToPresentHistory = async (
  snrwb: SnrwbCoreContextType,
  productType: GetProductTypeDto,
) => {
  const groupValue = { value: await GetGroupValue(snrwb, productType.groupId) };

  const presentation: {
    name: string;
    value: string | number | JSX.Element;
  }[] = [
    {
      name: 'NKITW/TW',
      value: productType.nkitw,
    },
    {
      name: 'Numer systemowy',
      value: productType.sysnumber,
    },
    {
      name: 'Grupa',
      value: groupValue.value,
    },
  ];

  if (productType.signId) {
    const signDict = DictionaryStatic({
      value: await snrwb.dictionaries.getById(productType.signId),
    });

    presentation.push({
      name: 'Oznakowanie',
      value: signDict,
    });
  }
  presentation.push({
    name: 'Opis',
    value: productType.description,
  });
  presentation.push({
    name: 'Wyrób budowlany',
    value: productType.constructionProduct ? 'Tak' : 'Nie',
  });

  return presentation;
};
