export function getCategoryText(category: string): string {
  let text = '';
  switch (category) {
    case '0':
      text = 'stworzenie obiektu';
      break;
    case '1':
      text = 'modyfikacja obiektu';
      break;
    case '2':
      text = 'usunięcie obiektu';
      break;
    case '3':
      text = 'sklejenie obiektów';
      break;
    default:
      text = 'nieznane zdarzenie';
  }
  return text;
}
