import React from 'react';
import { Table } from 'react-bootstrap';

import {
  CorrectiveActionsDetails,
  ReportCorrectiveActionsType,
} from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: ReportCorrectiveActionsType | undefined;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportByCorrectiveAction: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = data ? Object.keys(data.details).length > 0 : false;

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" key={'table'} striped bordered hover>
          <thead className="text-center">
            <tr>
              <th />
              {data &&
                hasData &&
                Object.keys(data?.details)
                  .sort()
                  .map((key, idx) => (
                    <th key={idx} colSpan={2}>
                      {key}
                    </th>
                  ))}
              {data && <th colSpan={2}>Suma</th>}
            </tr>
            <tr>
              <th>{reportName}</th>
              {data &&
                hasData &&
                Object.keys(data?.details)
                  .sort()
                  .map(idx => (
                    <React.Fragment key={idx}>
                      <th>ogółem</th>
                      <th>w tym CPR</th>
                    </React.Fragment>
                  ))}
              {data && <th>ogółem</th>}
              {data && <th>w tym CPR</th>}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>w trakcie kontroli</td>
              <CADetails
                data={data}
                type={'countInInspection'}
                typeCpr={'countInInspectionCpr'}
              />
            </tr>
            <tr>
              <td>
                po zakończeniu kontroli a przed wszczęciem postępowania
                administracyjnego (w związku z ustaleniami kontroli)
              </td>
              <CADetails
                data={data}
                type={'countBetweenInspProc'}
                typeCpr={'countBetweenInspProcCpr'}
              />
            </tr>
            <tr>
              <td>
                po wszczęciu postępowania administracyjnego (a przed wydaniem
                pierwszego orzeczenia - postanowienia naprawczego lub decyzji)
              </td>
              <CADetails
                data={data}
                type={'countAfterProceeding'}
                typeCpr={'countAfterProceedingCpr'}
              />
            </tr>
            <tr>
              <td>ogółem działania naprawcze przed wydaniem orzeczeń</td>
              <CADetails data={data} type={'count'} typeCpr={'countCpr'} />
            </tr>
            <tr>
              <td>
                usunięcie nieprawidłowości w wyniku postanowień wydanych na
                podstawie art. 30 ust. 1 pkt 1 albo art. 31 ust. 1 pkt 1 uowb
              </td>
              <CADetails
                data={data}
                type={'countArt3011'}
                typeCpr={'countArt3011Cpr'}
              />
            </tr>
            <tr>
              <td>
                wycofanie z obrotu po wydaniu decyzji, o których mowa w art. 30
                ust. 1 i 2 oraz art. 31 ust. 1 i 2 ww. ustawy
              </td>
              <CADetails
                data={data}
                type={'countArt3012'}
                typeCpr={'countArt3012Cpr'}
              />
            </tr>
          </tbody>
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

const CADetails: React.FC<{
  data: ReportCorrectiveActionsType | undefined;
  type: keyof CorrectiveActionsDetails;
  typeCpr: keyof CorrectiveActionsDetails;
}> = ({ data, type, typeCpr }) => {
  if (!data || Object.keys(data?.details).length <= 0) {
    return <></>;
  }

  return (
    <>
      {Object.keys(data?.details)
        .sort()
        .map((key, idx) => (
          <React.Fragment key={idx}>
            <td className="numbers-column">
              {data?.details?.[key]?.[type] || 0}
            </td>
            <td className="numbers-column">
              {data?.details?.[key]?.[typeCpr] || 0}
            </td>
          </React.Fragment>
        ))}
      <td className="numbers-column">{data?.sum?.[type] || 0}</td>
      <td className="numbers-column">{data?.sum?.[typeCpr] || 0}</td>
    </>
  );
};
