// DatePicker from antd with two changes:
// 1) pl_PL locale hardcoded
// 2) value type changed from moment to Date

import React from 'react';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pl_PL';
import {
  DatePicker as AntdDatePicker,
  /* eslint-disable-next-line  import/named */
  DatePickerProps as AntdDatePickerProps,
} from 'antd';

type AntdDatePickerPropsWithoutValue = Omit<
  Omit<AntdDatePickerProps, 'value'>,
  'onChange'
>;
interface DatePickerProps extends AntdDatePickerPropsWithoutValue {
  value?: Date | null;
  midnight?: boolean;
  onChange?: (value: Date | null) => void;
}

export default (props: DatePickerProps) => {
  const onChange = (value: moment.Moment | null) => {
    if (props.onChange) {
      if (!value) {
        return props.onChange(null);
      }
      const now = new Date();

      /// AntdDatePicker works differently in keyboard mode and in mouse mode...
      const finalValue = value.toDate();
      if (!props.midnight) {
        finalValue.setHours(
          now.getHours(),
          now.getMinutes(),
          now.getSeconds(),
          now.getMilliseconds(),
        );
      } else {
        finalValue.setHours(0, 0, 0, 0);
      }

      props.onChange(finalValue);
    }
  };

  if (props.value) {
    return (
      <AntdDatePicker
        {...props}
        locale={locale}
        value={moment(props.value)}
        onChange={onChange}
        getPopupContainer={triggerNode => {
          return (triggerNode.parentNode as HTMLElement) || triggerNode;
        }}
      />
    );
  }

  return (
    <AntdDatePicker
      {...(props as AntdDatePickerPropsWithoutValue)}
      locale={locale}
      onChange={onChange}
      getPopupContainer={triggerNode => {
        return (triggerNode.parentNode as HTMLElement) || triggerNode;
      }}
    />
  );
};
