/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JudgmentDetails,
    JudgmentDetailsFromJSON,
    JudgmentDetailsFromJSONTyped,
    JudgmentDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface PakAnalysisMoneyPenaltiesType
 */
export interface PakAnalysisMoneyPenaltiesType {
    /**
     * 
     * @type {number}
     * @memberof PakAnalysisMoneyPenaltiesType
     */
    pakId: number;
    /**
     * 
     * @type {string}
     * @memberof PakAnalysisMoneyPenaltiesType
     */
    entityName: string;
    /**
     * 
     * @type {string}
     * @memberof PakAnalysisMoneyPenaltiesType
     */
    productDescription: string;
    /**
     * 
     * @type {string}
     * @memberof PakAnalysisMoneyPenaltiesType
     */
    unitSymbol: string;
    /**
     * 
     * @type {number}
     * @memberof PakAnalysisMoneyPenaltiesType
     */
    penaltyAmount: number;
    /**
     * List of judgments related to the proceeding
     * @type {Array<JudgmentDetails>}
     * @memberof PakAnalysisMoneyPenaltiesType
     */
    judgments: Array<JudgmentDetails>;
}

export function PakAnalysisMoneyPenaltiesTypeFromJSON(json: any): PakAnalysisMoneyPenaltiesType {
    return PakAnalysisMoneyPenaltiesTypeFromJSONTyped(json, false);
}

export function PakAnalysisMoneyPenaltiesTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PakAnalysisMoneyPenaltiesType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pakId': json['pakId'],
        'entityName': json['entityName'],
        'productDescription': json['productDescription'],
        'unitSymbol': json['unitSymbol'],
        'penaltyAmount': json['penaltyAmount'],
        'judgments': ((json['judgments'] as Array<any>).map(JudgmentDetailsFromJSON)),
    };
}

export function PakAnalysisMoneyPenaltiesTypeToJSON(value?: PakAnalysisMoneyPenaltiesType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pakId': value.pakId,
        'entityName': value.entityName,
        'productDescription': value.productDescription,
        'unitSymbol': value.unitSymbol,
        'penaltyAmount': value.penaltyAmount,
        'judgments': ((value.judgments as Array<any>).map(JudgmentDetailsToJSON)),
    };
}


