/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateInspectedProductDto
 */
export interface CreateUpdateInspectedProductDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    subtype: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    quantity: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    technicalSpecification: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectedProductDto
     */
    checkMarking: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectedProductDto
     */
    checkDeclaration: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectedProductDto
     */
    checkDocumentation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectedProductDto
     */
    checkManual: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectedProductDto
     */
    checkVolatileOrganicCompounds: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectedProductDto
     */
    analyzisInProgress: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectedProductDto
     */
    misstatementFound: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    findings: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    cautionText: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUpdateInspectedProductDto
     */
    cautionIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    sampleExamId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    productTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    inspectionId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectedProductDto
     */
    productMissing: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    purpose: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateInspectedProductDto
     */
    placeOfProduction: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectedProductDto
     */
    placeOfProductionNA: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateInspectedProductDto
     */
    yearOfProduction: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateInspectedProductDto
     */
    yearOfProductionNA: boolean;
}

export function CreateUpdateInspectedProductDtoFromJSON(json: any): CreateUpdateInspectedProductDto {
    return CreateUpdateInspectedProductDtoFromJSONTyped(json, false);
}

export function CreateUpdateInspectedProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateInspectedProductDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subtype': json['subtype'],
        'quantity': json['quantity'],
        'technicalSpecification': json['technicalSpecification'],
        'checkMarking': json['checkMarking'],
        'checkDeclaration': json['checkDeclaration'],
        'checkDocumentation': json['checkDocumentation'],
        'checkManual': json['checkManual'],
        'checkVolatileOrganicCompounds': json['checkVolatileOrganicCompounds'],
        'analyzisInProgress': json['analyzisInProgress'],
        'misstatementFound': json['misstatementFound'],
        'description': json['description'],
        'findings': json['findings'],
        'cautionText': json['cautionText'],
        'cautionIds': json['cautionIds'],
        'sampleExamId': json['sampleExamId'],
        'productTypeId': json['productTypeId'],
        'inspectionId': json['inspectionId'],
        'productMissing': json['productMissing'],
        'purpose': json['purpose'],
        'placeOfProduction': json['placeOfProduction'],
        'placeOfProductionNA': json['placeOfProductionNA'],
        'yearOfProduction': json['yearOfProduction'],
        'yearOfProductionNA': json['yearOfProductionNA'],
    };
}

export function CreateUpdateInspectedProductDtoToJSON(value?: CreateUpdateInspectedProductDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subtype': value.subtype,
        'quantity': value.quantity,
        'technicalSpecification': value.technicalSpecification,
        'checkMarking': value.checkMarking,
        'checkDeclaration': value.checkDeclaration,
        'checkDocumentation': value.checkDocumentation,
        'checkManual': value.checkManual,
        'checkVolatileOrganicCompounds': value.checkVolatileOrganicCompounds,
        'analyzisInProgress': value.analyzisInProgress,
        'misstatementFound': value.misstatementFound,
        'description': value.description,
        'findings': value.findings,
        'cautionText': value.cautionText,
        'cautionIds': value.cautionIds,
        'sampleExamId': value.sampleExamId,
        'productTypeId': value.productTypeId,
        'inspectionId': value.inspectionId,
        'productMissing': value.productMissing,
        'purpose': value.purpose,
        'placeOfProduction': value.placeOfProduction,
        'placeOfProductionNA': value.placeOfProductionNA,
        'yearOfProduction': value.yearOfProduction,
        'yearOfProductionNA': value.yearOfProductionNA,
    };
}


