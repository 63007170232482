import React from 'react';
import * as Icon from 'react-bootstrap-icons';

import { useNavigation } from '../../../common/navigation';

export const InspectionLink: React.FC<{ inspectionId: string }> = props => {
  const nav = useNavigation();

  return (
    <span
      className="link-primary"
      role="button"
      tabIndex={0}
      onClick={() => nav.inspection(props.inspectionId)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          nav.inspection(props.inspectionId);
        }
      }}
    >
      <Icon.BoxArrowRight />
    </span>
  );
};
