import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import FormRow from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import ValidationAlert from '../../../app/components/ValidationAlert';
import {
  CreateUpdateTechAssessmentDto,
  GetAttachmentDto,
  CreateUpdateAttachmentDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as TechAssessmentContext from '../../../common/snrwbCore/contexts/TechAssessmentContext';
import DictionarySelector from '../Selects/DictionarySelector';
import OrganizationalUnitSelector from '../Selects/OrganizationalUnitSelector';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { AddAttachment } from '../Attachments/AddAttachment';
import { Attachment } from '../Attachments/Attachment';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useCookies } from '../../../common/hooks/useCookies';

export const TechAssessment: React.FC<{
  value?: CreateUpdateTechAssessmentDto;
  onChange: (techAssessment: CreateUpdateTechAssessmentDto) => void;
  showValidationErrors: boolean;
  validationErrors: string[];
  producer?: string;
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  assessmentId: string | null;
  onUpdateAttachments?: () => void;
}> = props => {
  const snrwb = useSnrwbCore();
  const cookies = useCookies();
  const [techAssessment, setTechAssessment] = useState(
    TechAssessmentContext.newTechAssessment(),
  );

  const propertyChange = (obj: Partial<CreateUpdateTechAssessmentDto>) => {
    const dto = { ...techAssessment, ...obj };
    setTechAssessment(dto);
    return dto;
  };

  const propagate = (obj?: CreateUpdateTechAssessmentDto) => {
    props.onChange(obj || techAssessment);
  };

  useEffect(() => {
    const techAssessment =
      props.value || TechAssessmentContext.newTechAssessment();
    setTechAssessment(techAssessment);
  }, [props.value]);

  const [dictionaryId, setDictionaryId] = useState<string>('');
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted) {
      snrwb.dictionaries
        .getByTypeAndValueAndFirstLevel('typ wyrobu - grupa', 'Inne')
        .then(values => {
          setDictionaryId(values[0].id);
          setMounted(true);
        });
    }
  }, [mounted, setMounted, snrwb.dictionaries]);

  return (
    <>
      <Form className="d-grid gap-3">
        {props.producer && (
          <FormRow controlId="producer" label="Wnioskodawca" static>
            {props.producer}
          </FormRow>
        )}
        <FormRow
          controlId="organizationalUnit"
          label="Jednostka ocen technicznych"
        >
          <OrganizationalUnitSelector
            readOnly={
              cookies.organizationalUnit?.nationalTechnicalAssessmentUnit
            }
            option={OrganizationalUnitsGetOptions.assessmentUnit}
            value={techAssessment.organizationalUnitId}
            onChange={option =>
              propagate(propertyChange({ organizationalUnitId: option.value }))
            }
          />
        </FormRow>
        <FormRow controlId="preparationDate" label="Data przygotowania oceny">
          <DatePicker
            value={techAssessment.preparationDate}
            onChange={dt => propagate(propertyChange({ preparationDate: dt }))}
          />
        </FormRow>
        <FormRow controlId="number" label="Numer">
          <Form.Control
            type="text"
            value={techAssessment.number || ''}
            onChange={e =>
              propagate(propertyChange({ number: e.target.value }))
            }
          />
        </FormRow>
        <FormRow controlId="productName" label="Nazwa wyrobu budowlanego">
          <Form.Control
            type="text"
            value={techAssessment.productName || ''}
            onChange={e =>
              propagate(propertyChange({ productName: e.target.value }))
            }
          />
        </FormRow>
        <FormRow controlId="productType" label="Rodzaju wyrobu budowlanego">
          <Form.Control
            type="text"
            value={techAssessment.productType || ''}
            onChange={e =>
              propagate(propertyChange({ productType: e.target.value }))
            }
          />
        </FormRow>
        <FormRow
          controlId="productPurpose"
          label="Zamierzone zastosowanie wyrobu budowlanego"
        >
          <Form.Control
            type="text"
            value={techAssessment.productPurpose || ''}
            onChange={e =>
              propagate(propertyChange({ productPurpose: e.target.value }))
            }
          />
        </FormRow>
        <FormRow controlId="groupProductType" label="Grupa wyrobu">
          <DictionarySelector
            dictionary="typ wyrobu - grupa"
            value={techAssessment.groupProductTypeId}
            maxDepth={1}
            onChange={option => {
              propagate(
                propertyChange({
                  groupProductTypeId: option.value,
                }),
              );
            }}
          />
        </FormRow>
        {techAssessment.groupProductTypeId === dictionaryId && (
          <FormRow
            controlId="groupProductTypeOther"
            label="Nazwa innej grupy wyrobu"
          >
            <Form.Control
              type="text"
              value={techAssessment.groupProductTypeOther || ''}
              onChange={e =>
                propagate(
                  propertyChange({ groupProductTypeOther: e.target.value }),
                )
              }
            />
          </FormRow>
        )}
        <FormRow
          controlId="expDateStart"
          label="Okres ważności Krajowej Oceny Technicznej od"
        >
          <DatePicker
            value={techAssessment.expDateStart}
            onChange={dt => propagate(propertyChange({ expDateStart: dt }))}
          />
        </FormRow>
        <FormRow
          controlId="expDateEnd"
          label="Okres ważności Krajowej Oceny Technicznej do"
        >
          <DatePicker
            value={techAssessment.expDateEnd}
            onChange={dt => propagate(propertyChange({ expDateEnd: dt }))}
          />
        </FormRow>
        <FormRow
          controlId="repealDate"
          label="Data  uchylenia krajowej oceny technicznej"
        >
          <DatePicker
            value={techAssessment.repealDate}
            onChange={dt => propagate(propertyChange({ repealDate: dt }))}
          />
        </FormRow>
        <FormRow controlId="note" label="Uwagi">
          <Form.Control
            type="text"
            value={techAssessment.note || ''}
            onChange={e => propertyChange({ note: e.target.value })}
            onBlur={() => propagate()}
          />
        </FormRow>
        {props.assessmentId && (
          <FormRow controlId="kot" label="KOT">
            <AssociatedDocuments
              name="Załączniki"
              readonly={false}
              createModalType={AddAttachment}
              presentModalType={Attachment}
              docs={props.attachments}
              onUpdateAttachments={props.onUpdateAttachments}
            />
          </FormRow>
        )}
      </Form>
      {props.showValidationErrors && (
        <ValidationAlert
          show={props.validationErrors.length > 0}
          errors={props.validationErrors}
          className="mt-3"
        />
      )}
    </>
  );
};
