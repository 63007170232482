import {
  CreateUpdateSampleDto,
  GetSampleDto,
  GetSampleExtendedDto,
  DefaultApi,
  CreateUpdateSampleDtoFromJSON,
} from '../autogenerated/snrwbApiClient';
import { SampleSchema } from '../validation/schemas';
import { validateAgainst } from '../validation/validateAgainst';

export interface SampleContextInterface {
  getById: (id: string) => Promise<GetSampleDto>;
  getBySampleExamId: (sampleExamId: string) => Promise<GetSampleExtendedDto[]>;
  create: (dto: CreateUpdateSampleDto) => Promise<GetSampleDto>;
  createVoid: (dto: CreateUpdateSampleDto) => Promise<void>;
  update: (id: string, Sample: CreateUpdateSampleDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
  getControlsSampleByDateOrgUnit: (
    dateFrom?: Date,
    dateTo?: Date,
    organizationalUnitId?: string,
  ) => Promise<GetSampleDto[]>;
}

export const SampleContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.sampleControllerGet(id),
    getBySampleExamId: async (sampleExamId: string) => {
      const samples = await api.sampleControllerGetBySampleExam(sampleExamId);
      samples.sort((s1, s2) => {
        if (!s1.sample.controlSample) {
          return -1;
        }
        if (!s2.sample.controlSample) {
          return 1;
        }
        return s1.sample.id < s2.sample.id ? -1 : 1;
      });
      return samples;
    },
    create: (sample: CreateUpdateSampleDto) =>
      api.sampleControllerCreate(sample),
    createVoid: async (sample: CreateUpdateSampleDto) => {
      await api.sampleControllerCreate(sample);
    },
    update: (id: string, sample: CreateUpdateSampleDto) =>
      api.sampleControllerUpdate(id, sample),
    delete: (id: string) => api.sampleControllerDelete(id),
    getControlsSampleByDateOrgUnit: (
      dateFrom?: Date,
      dateTo?: Date,
      organizationalUnitId?: string,
    ) => {
      return api.sampleControllerGetControlsSampleByDateOrgUnit(
        dateFrom,
        dateTo,
        organizationalUnitId,
      );
    },
  };
};

export const toCuDto = (getDto: GetSampleDto) => {
  const cuDto = CreateUpdateSampleDtoFromJSON(getDto);
  if (getDto.operateOrganizationalUnit) {
    cuDto.operateOrganizationalUnitId = getDto.operateOrganizationalUnit.id;
  }
  return cuDto;
};

export const validate = (dto: CreateUpdateSampleDto) =>
  validateAgainst(SampleSchema, dto);
