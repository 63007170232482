import React from 'react';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  CreateUpdateOrganizationDto,
  GetCustomsFeedbackDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  CustomsFeedbackDto,
  convert,
} from '../../../common/snrwbCore/contexts/CustomsFeedbackContext';
import { Organization } from '../../../common/snrwbCore/contexts/OrganizationContext';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';

import { OrgCustFeedAction } from './OrgCustFeed/OrgCustFeedAction';

interface CreateUpdateCustomsFeedbackProps {
  organization?: Organization;
  customsFeedback?: GetCustomsFeedbackDto;
  onAddNew?: (producerId: string) => void;
  onChange?: () => void;
  onClose?: () => void;
}

export const CreateUpdateCustomsFeedback: React.FC<CreateUpdateCustomsFeedbackProps> =
  props => {
    const snrwb = useSnrwbCore();
    const notifications = useNotifications();

    const addCustomsFeedback = async (
      organization: Organization,
      customsFeedback: CustomsFeedbackDto,
      id?: string,
    ) => {
      let createCustomsFeedback: () => Promise<GetCustomsFeedbackDto | void>;

      const cuCustomsFeedback =
        'id' in customsFeedback ? convert(customsFeedback) : customsFeedback;
      if ('id' in organization) {
        cuCustomsFeedback.producerId = organization.id;
        createCustomsFeedback = async () =>
          id
            ? await snrwb.customsFeedbacks.update(id, cuCustomsFeedback)
            : await snrwb.customsFeedbacks.create(cuCustomsFeedback);
      } else {
        createCustomsFeedback = async () =>
          await snrwb.customsFeedbacks.createWithOrganization(
            cuCustomsFeedback,
            organization as CreateUpdateOrganizationDto,
          );
      }

      try {
        const result = await createCustomsFeedback();
        if ((result as GetCustomsFeedbackDto)?.producer) {
          cuCustomsFeedback.producerId = (
            result as GetCustomsFeedbackDto
          ).producer.id;
        }
        if (id) {
          props.onChange && props.onChange();
        } else if (cuCustomsFeedback.producerId) {
          props.onAddNew && props.onAddNew(cuCustomsFeedback.producerId);
        }
        return {
          saved: true,
          errors: [],
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    };

    const deleteCustomFeedback = () => {
      if (props.customsFeedback) {
        notifications.onPromise(
          snrwb.customsFeedbacks.delete(props.customsFeedback.id),
          props.onChange,
        );
      }
    };

    return (
      <OrgCustFeedAction
        organization={props.organization}
        customsFeedback={props.customsFeedback}
        onCompleted={addCustomsFeedback}
        onDelete={props.customsFeedback && deleteCustomFeedback}
        onClose={props.onClose}
      />
    );
  };
