import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { GetSampleDto } from '../../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { SampleExamsList } from '../../../components/ReportBySampleExam/SampleExamsList';

export const ReportSampleExamsListView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<GetSampleDto[]>([]);

  const onSearch = (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.samples
      .getControlsSampleByDateOrgUnit(
        filter?.dateFrom,
        filter?.dateTo,
        filter?.organizationalUnitId,
      )
      .then(dataReports => {
        setData(dataReports);
      });
  };

  return (
    <>
      <Filter onSearch={onSearch} reportName="Zlecone próbki kontrolne" />
      <SampleExamsList
        data={data}
        reportName="Próbki kontrolne zlecone w danym okresie – lista wg nr systemowych"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
