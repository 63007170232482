import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default () => (
  <>
    <Skeleton width="25%" />
    <Skeleton width="25%" />
    <Skeleton width="50%" />
    <Skeleton count={10} />
  </>
);
