/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportNegativeJudgmentSampleDetails
 */
export interface ReportNegativeJudgmentSampleDetails {
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeJudgmentSampleDetails
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeJudgmentSampleDetails
     */
    organizationalUnit: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeJudgmentSampleDetails
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeJudgmentSampleDetails
     */
    sign: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeJudgmentSampleDetails
     */
    posId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportNegativeJudgmentSampleDetails
     */
    appeal: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportNegativeJudgmentSampleDetails
     */
    appealResult: string;
}

export function ReportNegativeJudgmentSampleDetailsFromJSON(json: any): ReportNegativeJudgmentSampleDetails {
    return ReportNegativeJudgmentSampleDetailsFromJSONTyped(json, false);
}

export function ReportNegativeJudgmentSampleDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportNegativeJudgmentSampleDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'organizationalUnit': json['organizationalUnit'],
        'description': json['description'],
        'sign': json['sign'],
        'posId': json['posId'],
        'appeal': json['appeal'],
        'appealResult': json['appealResult'],
    };
}

export function ReportNegativeJudgmentSampleDetailsToJSON(value?: ReportNegativeJudgmentSampleDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'organizationalUnit': value.organizationalUnit,
        'description': value.description,
        'sign': value.sign,
        'posId': value.posId,
        'appeal': value.appeal,
        'appealResult': value.appealResult,
    };
}


