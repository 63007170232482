import React from 'react';
import Badge from 'react-bootstrap/Badge';

import { GetDictionaryDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';

export default (props: { value: GetDictionaryDto }) => {
  return (
    <>
      {props.value.shortname && (
        <>
          <Badge bg="warning" text="dark">
            {props.value.shortname}
          </Badge>{' '}
        </>
      )}
      {props.value.value}
    </>
  );
};
