import { GetInspectionPlanDto } from '../autogenerated/snrwbApiClient/models/GetInspectionPlanDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateInspectionPlanDto,
  CreateUpdateInspectionPlanDtoFromJSON,
} from '../autogenerated/snrwbApiClient/models/CreateUpdateInspectionPlanDto';
import { validateAgainst } from '../validation/validateAgainst';
import { InspectionPlanSchema } from '../validation/schemas';

export interface InspectionPlanInterface {
  getAll: () => Promise<GetInspectionPlanDto[]>;
  getById: (id: string) => Promise<GetInspectionPlanDto>;
  create: (dto: CreateUpdateInspectionPlanDto) => Promise<GetInspectionPlanDto>;
  update: (id: string, dto: CreateUpdateInspectionPlanDto) => Promise<void>;
}

export const InspectionPlanContext = (api: DefaultApi) => {
  return {
    getAll: () => api.inspectionPlanControllerGetAll(),
    getById: (id: string) => api.inspectionPlanControllerGet(id),
    create: (dto: CreateUpdateInspectionPlanDto) =>
      api.inspectionPlanControllerCreate(dto),
    update: (id: string, dto: CreateUpdateInspectionPlanDto) =>
      api.inspectionPlanControllerUpdate(id, dto),
  };
};

export const validate = (plan: CreateUpdateInspectionPlanDto) =>
  validateAgainst(InspectionPlanSchema, plan);

export const newInspectionPlan = () => {
  const plan = CreateUpdateInspectionPlanDtoFromJSON({});
  return plan;
};

export const inspectionPlanData = (
  user?: GetInspectionPlanDto,
): CreateUpdateInspectionPlanDto => {
  return CreateUpdateInspectionPlanDtoFromJSON({
    ...user,
    organizationalUnitId: user?.organizationalUnit?.id,
  });
};
