import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import {
  Filter,
  FilterReportYearData,
} from '../../../components/Reports/Filter';
import { ReportPublicationsType } from '../../../../common/types/ReportTypes';
import { ReportByPublications } from '../../../components/ReportByPublications/ReportByPublications';
import momencik from '../../../../common/momencik';
import parseParagraphData from '../../../../common/parseParagraphData';

export const ReportPublicationsView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<
    FilterReportYearData | undefined
  >({});
  const [data, setData] = useState<ReportPublicationsType[]>();
  const [dataCSV, setDataCSV] = useState<unknown[]>();

  const escapeQuotes = (val: string | null) =>
    val ? val.replace(/"/g, '""').replace(/(<([^>]+)>)/gi, '') : null;

  const escapeDots = (val: string | null) =>
    val ? val.replace('.', ',') : null;

  /*eslint complexity: ["error", 15]*/
  const onSearch = (filter?: FilterReportYearData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportPublications(filter).then(dataReports => {
      setData(dataReports);
      const result = [];
      for (const p of dataReports) {
        result.push({
          publicationNumber: `=""${p.publicationNumber}""`,
          operateOrgUnitShortname: p.operateOrgUnitShortname,
          examOrgUnitShortname: p.examOrgUnitShortname,
          collectDate: p.collectDate,
          collectDateString: momencik(p.collectDate, 'YYYY-MM-DD'),
          collectPlace: escapeQuotes(p.collectPlace),
          groupShortname: escapeQuotes(p.groupShortname),
          organization: escapeQuotes(p.organization),
          nkitw: escapeQuotes(p.nkitw),
          description: escapeQuotes(p.description),
          collectBasis: escapeQuotes(p.collectBasis),
          subGroupShortname: escapeQuotes(p.subGroupShortname),
          examCostForGoverment: escapeDots(p.examCostForGoverment),
          transportCostForGoverment: escapeDots(p.transportCostForGoverment),
          storageCostForGoverment: escapeDots(p.storageCostForGoverment),
          examCostForInspected: escapeDots(p.examCostForInspected),
          transportCostForInspected: escapeDots(p.transportCostForInspected),
          storageCostForInspected: escapeDots(p.storageCostForInspected),
          technicalSpecification: escapeQuotes(p.technicalSpecification),
          negative: p.negative ? 'nie' : 'tak',
          controlResult: p.controlSample ? 'tak' : 'nie',
          feature: escapeQuotes(
            p.essentialFuture ? p.essentialFuture[0]?.feature : '',
          ),
          functionalProperty: escapeQuotes(
            p.essentialFuture ? p.essentialFuture[0]?.functionalProperty : '',
          ),
          examMethod: escapeQuotes(
            p.essentialFuture ? p.essentialFuture[0]?.examMethod : '',
          ),
          result: !p.essentialFuture
            ? ''
            : (
                !p.controlSample
                  ? p.essentialFuture[0]?.result
                  : p.essentialFuture[0]?.controlResult
              )
            ? 'zgodny'
            : (!p.controlSample
                ? p.essentialFuture[0]?.result
                : p.essentialFuture[0]?.controlResult) === false
            ? 'niezgodny'
            : '',
          expectedResultValue: p.essentialFuture
            ? parseParagraphData(p.essentialFuture[0]?.expectedResultValue)
            : '',
          resultValue: p.essentialFuture
            ? parseParagraphData(p.essentialFuture[0]?.resultValue)
            : '',
          resultUncertainty: p.essentialFuture
            ? parseParagraphData(p.essentialFuture[0]?.resultUncertainty)
            : '',
        });
        p.essentialFuture?.map((ess, index) => {
          if (index > 0) {
            result.push({
              feature: escapeQuotes(ess?.feature || ''),
              functionalProperty: escapeQuotes(ess?.functionalProperty || ''),
              examMethod: escapeQuotes(ess?.examMethod || ''),
              result: !p.essentialFuture
                ? ''
                : (
                    !p.controlSample
                      ? p.essentialFuture[0]?.result
                      : p.essentialFuture[0]?.controlResult
                  )
                ? 'zgodny'
                : (!p.controlSample
                    ? p.essentialFuture[0]?.result
                    : p.essentialFuture[0]?.controlResult) === false
                ? 'niezgodny'
                : '',
            });
          }
        });
      }
      setDataCSV(result);
    });
  };

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName="Opublikowane próbki"
        dateFromName="Data pobrania od"
        dateToName="Data pobrania do"
        reportOrganizationalUnitName="Organ zlecający"
      />
      <ReportByPublications
        data={data}
        dataCSV={dataCSV || []}
        reportName="Opublikowane próbki"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
