import React from 'react';
import { Card, Tab } from 'react-bootstrap';

import Tabs from '../../../app/components/Tabs';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { ProductTypeSearch } from '../../components/ProductTypes/ProductTypeSearch';

export const ProductTypesView: React.FC = () => {
  const snrwb = useSnrwbCore();

  return (
    <Card>
      <Tabs defaultActiveKey="search" id="productTypes" className="mb-3">
        <Tab eventKey="search" title="Typy wyrobów" className="m-3">
          <ProductTypeSearch
            getData={snrwb.productTypes.getAllByPortion}
            getCount={snrwb.productTypes.getAllCount}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
