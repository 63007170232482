import React, { useState, useEffect } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

import Layout from '../components/Layout';
import { useAuth } from '../../common/hooks/useAuth';
import { Role } from '../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import {
  routesInMenu,
  routesOutsideMenu,
  MenuItem,
} from '../../common/navigation';
import ErrorBoundary from '../../common/components/ErrorBoundary/ErrorBoundary';
import { Waiting } from '../../common/components/Waiting/Waiting';
import { useSnrwbCore } from '../../common/hooks/useSnrwbCore';
import { responseErrors } from '../../common/snrwbCore/validation/responseErrors';
import AlertModal from '../components/AlertModal';
import { useCookies } from '../../common/hooks/useCookies';
import { GetOrganizationalUnitDto } from '../../common/snrwbCore/autogenerated/snrwbApiClient';

const allMenuItemRoutes = (menuitem: MenuItem) => {
  const routesInsideMenuItem: JSX.Element[] | undefined = [];
  menuitem.submenu?.forEach(subitem => {
    routesInsideMenuItem.push(
      <Route
        key={subitem.path}
        path={subitem.path}
        exact={subitem.exact}
        component={subitem.view}
      />,
    );

    if (subitem.submenu) {
      subitem.submenu.forEach(subsubitem => {
        routesInsideMenuItem.push(
          <Route
            key={subsubitem.path}
            path={subsubitem.path}
            exact={subsubitem.exact}
            component={subsubitem.view}
          />,
        );
      });
    }
  });

  const item = (
    <Route
      key={menuitem.path}
      path={menuitem.path}
      exact={menuitem.exact}
      component={menuitem.view}
    />
  );

  return [...routesInsideMenuItem, item];
};

const goodRoute = (
  route: MenuItem,
  organizationalUnit?: GetOrganizationalUnitDto,
) => {
  return (
    (!organizationalUnit?.notified &&
      !organizationalUnit?.nationalTechnicalAssessmentUnit) ||
    (organizationalUnit?.notified && route.certificatesOnly) ||
    (organizationalUnit?.nationalTechnicalAssessmentUnit &&
      route.techAssessmentsOnly)
  );
};

export const Routes: React.FC = () => {
  const auth = useAuth();
  const snrwb = useSnrwbCore();
  const cookies = useCookies();
  const [changingUnitForbidden, setChangingUnitForbidden] = useState(false);
  const routing = [
    ...routesInMenu
      .filter(route => goodRoute(route, cookies.organizationalUnit))
      .map(route => allMenuItemRoutes(route)),
    ...routesOutsideMenu
      .filter(route => goodRoute(route, cookies.organizationalUnit))
      .map(route => allMenuItemRoutes(route)),
  ];
  const [showProblem, setShowProblem] = useState(false);
  const [problem, setProblem] = useState<string>();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const logoutAfterAWhile = () =>
      setTimeout(() => auth.logout && auth.logout(), 5000);

    if (!auth.check(Role.Login)) {
      setProblem(
        'Brak uprawnień do korzystania z SNRWB. Prosimy o kontakt z administracją!',
      );
      setShowProblem(true);
      setAuthorized(false);
      logoutAfterAWhile();

      return;
    }
    setChangingUnitForbidden(!auth.check(Role.CurrentOrganizationalUnitChange));

    const user = sessionStorage.getItem('user');
    if (!user) {
      snrwb.employees
        .getCurrent()
        .then(employee => {
          sessionStorage.setItem('user', employee.id);
          setAuthorized(true);
        })
        .catch(async response => {
          const errors = await responseErrors(response as Response);
          for (const error of errors) {
            setProblem(error);
          }
          setShowProblem(true);
          setAuthorized(false);
          logoutAfterAWhile();
        });
    }

    setAuthorized(true);
  }, [snrwb.employees, auth]);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        {authorized && auth.currentUser && (
          <Route
            render={({ location }) => (
              <Layout
                location={location.pathname}
                changingUnitForbidden={changingUnitForbidden}
              >
                <Switch>{routing}</Switch>
              </Layout>
            )}
          />
        )}
        {!auth.currentUser && <Route path="/" component={Waiting} />}
        <AlertModal variant="danger" show={showProblem}>
          {problem}
        </AlertModal>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
