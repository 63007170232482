import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import {
  NegativeTestFilterExtended,
  FilterReportDataExtended,
} from '../../../components/Reports/NegativeTestFilterExtended';
import { ReportByControlSampleAnalysisType } from '../../../components/ReportByControlSampleAnalysisType/ReportByControlSampleAnalysisType';
import { ReportControlSampleAnalysisType } from '../../../../common/types/ReportTypes';

export const ReportControlSamlesView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<
    FilterReportDataExtended | undefined
  >({});

  const [data, setData] = useState<ReportControlSampleAnalysisType[]>();

  const onSearch = (filter?: FilterReportDataExtended) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportControlSamles(filter).then(dataReports => {
      setData(dataReports);
    });
  };

  return (
    <>
      <NegativeTestFilterExtended
        onSearch={onSearch}
        reportName="Przechowywanie próbki kontrolnej"
        dateFromName="Data rozpoczęcia od"
        dateToName="Data rozpoczęcia do"
        productDescription="Wyrób"
      />
      <ReportByControlSampleAnalysisType
        data={data || []}
        dataCSV={data || []}
        reportName="Przechowywanie próbki kontrolnej"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
