import React from 'react';

export default (props: { values: string[]; freeValue?: string }) => {
  const freeValues = props.freeValue ? props.freeValue.split('\n') : [];
  const values = [...props.values, ...freeValues].map(v =>
    v.trim().replace(/^-/, '').replace(/^•/, '').replace(/^\*/, '').trim(),
  );

  if (values.length === 0) {
    return <></>;
  }

  if (values.length === 1) {
    return <span>{props.values[0]}</span>;
  }

  return (
    <ul>
      {values.map(v => (
        <li key={v + Date.now()}>{v}</li>
      ))}
    </ul>
  );
};
