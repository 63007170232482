/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetThreadsStatsDto
 */
export interface GetThreadsStatsDto {
    /**
     * 
     * @type {number}
     * @memberof GetThreadsStatsDto
     */
    threadsCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetThreadsStatsDto
     */
    subjectThreadsCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetThreadsStatsDto
     */
    newThreadsCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetThreadsStatsDto
     */
    newParticipatedThreadsCount: number;
}

export function GetThreadsStatsDtoFromJSON(json: any): GetThreadsStatsDto {
    return GetThreadsStatsDtoFromJSONTyped(json, false);
}

export function GetThreadsStatsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetThreadsStatsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'threadsCount': json['threadsCount'],
        'subjectThreadsCount': json['subjectThreadsCount'],
        'newThreadsCount': json['newThreadsCount'],
        'newParticipatedThreadsCount': json['newParticipatedThreadsCount'],
    };
}

export function GetThreadsStatsDtoToJSON(value?: GetThreadsStatsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'threadsCount': value.threadsCount,
        'subjectThreadsCount': value.subjectThreadsCount,
        'newThreadsCount': value.newThreadsCount,
        'newParticipatedThreadsCount': value.newParticipatedThreadsCount,
    };
}


