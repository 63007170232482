import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNavigation } from '../../../common/navigation';
import {
  CreateUpdateProductTypeDto,
  GetObjectHistoryDto,
  GetProductTypeDto,
  ValidationStatus,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { ProductTypeEditView } from '../../components/ProductTypes/ProductTypeEditView';

interface ParamTypes {
  id: string;
}

export const ProductTypeView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const { id } = useParams<ParamTypes>();
  const mountedRef = useRef(false);
  const [productType, setProductType] = useState<GetProductTypeDto>();
  const [objectHistory, setObjectHistory] = useState<GetObjectHistoryDto[]>([]);
  const notifications = useNotifications();
  const nav = useNavigation();

  const safeSet = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    if (!mountedRef.current) {
      return;
    }
    snrwb.productTypes
      .getById(id)
      .then(pr => {
        safeSet(() => setProductType(pr));
      })
      .catch(() => nav.productTypes());
    snrwb.objectHistory
      .getHistory(id, 'GetProductTypeDto')
      .then(objectHistory => {
        safeSet(() => setObjectHistory(objectHistory));
      });

    // nav.productTypes does not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snrwb.productTypes, snrwb.objectHistory, id]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [refresh]);

  const handleRevertApprove = () => {
    if (productType) {
      notifications.onPromise(
        snrwb.productTypes.revertApprove(productType.id),
        refresh,
      );
    }
  };

  const handleApprove = (status: ValidationStatus) => {
    if (productType) {
      notifications.onPromise(
        snrwb.productTypes.approve(productType.id, status),
        refresh,
      );
    }
  };

  const handleChange = (dto: CreateUpdateProductTypeDto) => {
    if (productType) {
      notifications.onPromise(
        snrwb.productTypes.update(productType.id, dto),
        refresh,
      );
    }
  };

  return (
    <>
      {!productType && <> </>}
      {productType && (
        <ProductTypeEditView
          productType={productType}
          objectHistoryData={objectHistory}
          readonly={false} //TODO Roles
          onChange={handleChange}
          onApprove={handleApprove}
          onRevertApprove={handleRevertApprove}
          mayBeApproved={() => snrwb.productTypes.mayBeApproved(productType.id)}
          refresh={refresh}
        />
      )}
    </>
  );
};
