import React from 'react';
import { Table } from 'react-bootstrap';

import { ReportInspected } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: ReportInspected;
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportByInspectionType: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const O = ['P', 'WG', 'WPU', '30.4', 'NB16.2a', 'I', 'R'];
  const BR = ['P', 'WG', 'WPU', '30.4', 'NB16.2a', 'I'];
  const cols = ['P', 'WG', 'WPU', '30.4', 'NB16.2a', 'I', 'R'];
  const hasData = Object.keys(data.details).length > 0;

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" striped bordered hover>
          <thead className="text-center">
            <tr>
              <th colSpan={10}>{reportName}</th>
            </tr>
            <tr>
              <th rowSpan={2}>Organ</th>
              <th rowSpan={2}>Planowe</th>
              <th colSpan={6}>Doraźne</th>
              <th rowSpan={2}>Ogółem</th>
              <th rowSpan={2}>Ogółem bez rekontroli</th>
            </tr>
            <tr>
              <th>na wezwanie GINB</th>
              <th>na wniosek Prezesa UOKiK</th>
              <th>po wycofaniu u sprzedawcy</th>
              <th>po wynikach próbki z art. 16 ust. 2a</th>
              <th>inne</th>
              <th>sprawdzające (rekontrole)</th>
            </tr>
          </thead>

          {hasData && (
            <tbody>
              {Object.keys(data.details)
                .sort()
                .map((key, idx) => (
                  <tr key={idx}>
                    <td>{key}</td>
                    {cols.map((c, idx) => (
                      <td key={idx} className="numbers-column">
                        {' '}
                        {data.details[key][c] || 0}
                      </td>
                    ))}
                    <td className="numbers-column">
                      {O.reduce(
                        (acc, oCol) => acc + (data.details[key][oCol] || 0),
                        0,
                      )}
                    </td>
                    <td className="numbers-column">
                      {BR.reduce(
                        (acc, oCol) => acc + (data.details[key][oCol] || 0),
                        0,
                      )}
                    </td>
                  </tr>
                ))}
              <tr>
                <td>Suma:</td>
                {cols.map((c, idx) => (
                  <td key={idx} className="numbers-column">
                    {' '}
                    {data.sum[c] || 0}
                  </td>
                ))}
                <td className="numbers-column">
                  {O.reduce((acc, oCol) => acc + (data.sum[oCol] || 0), 0)}
                </td>
                <td className="numbers-column">
                  {BR.reduce((acc, oCol) => acc + (data.sum[oCol] || 0), 0)}
                </td>
              </tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};
