import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';

export const MergingProductType: React.FC<{
  productTypeId: string;
  productTypeHeadId?: string;
}> = props => {
  const nav = useNavigation();

  const mergeProductType = async () => {
    nav.merginProductTypes(
      props.productTypeId,
      undefined,
      props.productTypeHeadId,
    );
  };

  return (
    <>
      {props.productTypeId !== props.productTypeHeadId && (
        <OverlayTrigger
          overlay={<Tooltip>{'Zastępowanie wybranego typu wyrobu'}</Tooltip>}
        >
          <Button
            variant="link-primary"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              mergeProductType();
            }}
          >
            <Icon.Diagram2 />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
};
