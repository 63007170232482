import {
  GetJudgmentDto,
  CreateUpdateJudgmentDto,
  CreateUpdateJudgmentDtoFromJSON,
  GetInspectedProductDto,
  GetSampleDto,
  GetDictionaryDto,
} from '../autogenerated/snrwbApiClient';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import { validateAgainst } from '../validation/validateAgainst';
import { JudgmentSchema } from '../validation/schemas';
import { AssociateDocumentsWithFilter } from '../../../snrwb/components/AssociatedDocuments/AssociateDocumentsTypes';
import { SnrwbCoreContextType } from '..';
import { NotificationsProviderType } from '../../notifications';
import DictionaryStatic from '../../../snrwb/components/Dictionary/DictionaryStatic';
import momencik from '../../momencik';

export interface JudgmentContextInterface {
  getById: (id: string) => Promise<GetJudgmentDto>;
  update: (id: string, dto: CreateUpdateJudgmentDto) => Promise<void>;
  create: (dto: CreateUpdateJudgmentDto) => Promise<GetJudgmentDto>;
  createVoid: (dto: CreateUpdateJudgmentDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
  getByInspectedProduct: (
    inspectedProductId: string,
  ) => Promise<GetJudgmentDto[]>;
  getAllForInspectedProducts: (
    products: GetInspectedProductDto[],
  ) => Promise<GetJudgmentDto[]>;
  getBySample: (sampleId: string) => Promise<GetJudgmentDto[]>;
  getAllForSamples: (samples: GetSampleDto[]) => Promise<GetJudgmentDto[]>;
  getByProceeding: (id: string) => Promise<GetJudgmentDto[]>;
  getByPak: (id: string) => Promise<GetJudgmentDto[]>;
  approve: (id: string) => Promise<void>;
  revertApprove: (id: string) => Promise<void>;
}

export const JudgmentContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.judgmentControllerGet(id),
    update: (id: string, dto: CreateUpdateJudgmentDto) =>
      api.judgmentControllerUpdate(id, dto),
    create: (dto: CreateUpdateJudgmentDto) => api.judgmentControllerCreate(dto),
    createVoid: async (dto: CreateUpdateJudgmentDto) => {
      await api.judgmentControllerCreate(dto);
    },
    delete: (id: string) => api.judgmentControllerDelete(id),
    getByInspectedProduct: (inspectedProductId: string) =>
      api.judgmentControllerGetByInspectedProduct(inspectedProductId),
    getAllForInspectedProducts: async (products: GetInspectedProductDto[]) => {
      const judgments = [];
      for (const product of products) {
        judgments.push(
          ...(await api.judgmentControllerGetByInspectedProduct(product.id)),
        );
      }
      return judgments;
    },
    getBySample: (sampleId: string) =>
      api.judgmentControllerGetBySample(sampleId),
    getAllForSamples: async (samples: GetSampleDto[]) => {
      const judgments = [];
      for (const sample of samples) {
        judgments.push(...(await api.judgmentControllerGetBySample(sample.id)));
      }
      return judgments;
    },
    getByProceeding: (id: string) => api.judgmentControllerGetByProceeding(id),
    getByPak: (id: string) => api.judgmentControllerGetByPak(id),
    approve: (id: string) => api.judgmentControllerApprove(id),
    revertApprove: (id: string) => api.judgmentControllerRevertApprove(id),
  };
};

export const newJudgment = (organizationalUnitId?: string) => {
  return CreateUpdateJudgmentDtoFromJSON({
    appeal: false,
    approved: false,
    organizationalUnitId,
  });
};

const add = (
  form: { name: string; value: React.ReactNode }[],
  object: unknown,
  label: string,
  value: () => React.ReactNode,
) => {
  if (object) {
    form.push({ name: label, value: value() });
  }
};

export const toPresent = (judgment: GetJudgmentDto, inAppeal?: boolean) => {
  const judgmentPresentation = [
    {
      name: 'Rodzaj',
      value: DictionaryStatic({ value: judgment.kind }),
    },
  ];
  add(judgmentPresentation, judgment.publishDate, 'Data wydania', () =>
    momencik(judgment.publishDate),
  );
  add(judgmentPresentation, judgment.key, 'Znak', () => judgment.key);
  if (judgment.additionalOrdersValues?.length > 0) {
    let first = true;
    for (const order of judgment.additionalOrdersValues) {
      add(
        judgmentPresentation,
        judgment.additionalOrdersValues,
        first ? 'Dodatkowe nakazy' : '',
        () => DictionaryStatic({ value: order as unknown as GetDictionaryDto }),
      );
      first = false;
    }
  }
  add(
    judgmentPresentation,
    judgment.information,
    'Informacja',
    () => judgment.information,
  );
  add(
    judgmentPresentation,
    judgment.kindOfSettlement,
    'Rodzaj rozstrzygnięcia',
    () => judgment.kindOfSettlement,
  );
  if (!inAppeal) {
    add(judgmentPresentation, judgment.finalDate, 'Data ostateczności', () =>
      momencik(judgment.finalDate),
    );
    add(judgmentPresentation, judgment.appeal, 'Odwołanie', () =>
      judgment.appeal ? 'Tak' : 'Nie',
    );
    add(
      judgmentPresentation,
      judgment.resultOfAppeal,
      'Wynik odwołania',
      () => judgment.resultOfAppeal,
    );
  }

  return judgmentPresentation;
};

export const validate = (dto: CreateUpdateJudgmentDto) =>
  validateAgainst(JudgmentSchema, dto);

export const forAssociatedDocuments = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  judgments: GetJudgmentDto[],
  action?: () => void,
) => {
  const label = (judgment: GetJudgmentDto) => `${judgment.kind.value}`;

  const addMethod = (createUpdateDto: CreateUpdateJudgmentDto) =>
    notifications.onPromise(
      snrwb.judgments.createVoid(createUpdateDto),
      action,
    );

  const deleteMethod = (id: string) =>
    notifications.onPromise(snrwb.judgments.delete(id), action);

  const updateMethod = (id: string, createUpdateDto: CreateUpdateJudgmentDto) =>
    notifications.onPromise(
      snrwb.judgments.update(id, createUpdateDto),
      action,
    );

  const approveMethod = (
    id: string,
    createUpdateDto?: CreateUpdateJudgmentDto,
  ) =>
    notifications.onPromise(
      (async () => {
        if (createUpdateDto) {
          await snrwb.judgments.update(id, createUpdateDto);
        }
        await snrwb.judgments.approve(id);
      })(),
      action,
    );

  const revertApproveMethod = (id: string) =>
    notifications.onPromise(snrwb.judgments.revertApprove(id), action);

  return AssociateDocumentsWithFilter({
    documents: judgments,
    new: newJudgment,
    labelGenerator: label,
    validate: validate,
    add: addMethod,
    update: updateMethod,
    delete: deleteMethod,
    approve: approveMethod,
    revertApprove: revertApproveMethod,
  });
};
