import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import { GetInspectionPlanDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetInspectionPlanDto';
import { InspectionLink } from '../Inspections/InspectionLink';
import { useNavigation } from '../../../common/navigation';
import OrganizationalUnitBadge from '../OrganizationalUnit/OrganizationalUnitBadge';

import { InspectionPlanStatusInfo } from './InspectionPlanStatusInfo';

interface MyInspectionPlansProps {
  data: GetInspectionPlanDto[];
}

export const MyInspectionPlans: React.FC<MyInspectionPlansProps> = props => {
  const nav = useNavigation();
  const columns = [
    { header: 'Rok', property: 'year' },
    {
      header: 'Status',
      property: 'status',
    },
    {
      header: 'Organ',
      property: 'organizationName',
    },
    {
      header: 'Liczba planowanych kontroli wyrobów ogółem',
      property: 'numberOfProducts',
    },
    {
      header: 'W tym kontroli wyrobów CPR',
      property: 'numberOfProductsCpr',
    },
    {
      header: 'Liczba planowanych kontroli',
      property: 'numberOfInspections',
    },
    {
      header: 'W tym kontroli CPR',
      property: 'numberOfInspectionsCpr',
    },
    {
      header: 'Liczba etatów w komórce',
      property: 'numberOfStaffs',
    },
    { header: '', property: 'link', className: 'text-center', noSorting: true },
  ];

  props.data.sort(
    (a, b) =>
      b.year - a.year ||
      a.organizationalUnit.shortname.localeCompare(
        b.organizationalUnit.shortname,
        'pl',
      ),
  );
  const data = props.data.map(plan => {
    return {
      key: plan.id,
      values: {
        year: plan.year,
        status: <InspectionPlanStatusInfo status={plan.status} />,
        organizationName: (
          <OrganizationalUnitBadge
            organizationalUnit={plan.organizationalUnit}
          />
        ),
        numberOfProducts: plan.numberOfProducts,
        numberOfProductsCpr: plan.numberOfProductsCpr,
        numberOfInspections: plan.numberOfInspections,
        numberOfInspectionsCpr: plan.numberOfInspectionsCpr,
        numberOfStaffs: plan.numberOfStaffs,
        link: <InspectionLink inspectionId={plan.id} />,
      },
      orderByValues: {
        organizationName: plan.organizationalUnit.shortname,
        numberOfProducts: String(plan.numberOfProducts).padStart(10, '0'),
        numberOfProductsCpr: String(plan.numberOfProductsCpr).padStart(10, '0'),
        numberOfInspections: String(plan.numberOfInspections).padStart(10, '0'),
        numberOfInspectionsCpr: String(plan.numberOfInspectionsCpr).padStart(
          10,
          '0',
        ),
        numberOfStaffs: String(plan.numberOfStaffs).padStart(10, '0'),
      },
    };
  });
  const summaryData = {
    key: 'summary',
    values: {
      year: 'SUMA',
      numberOfProducts: 0,
      numberOfProductsCpr: 0,
      numberOfInspections: 0,
      numberOfInspectionsCpr: 0,
      numberOfStaffs: 0,
    },
  };

  data.map(rec => {
    summaryData.values.numberOfProducts += rec.values.numberOfProducts;
    summaryData.values.numberOfProductsCpr += rec.values.numberOfProductsCpr;
    summaryData.values.numberOfInspections += rec.values.numberOfInspections;
    summaryData.values.numberOfInspectionsCpr +=
      rec.values.numberOfInspectionsCpr;
    summaryData.values.numberOfStaffs += rec.values.numberOfStaffs;
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={false}
      onRowClick={nav.inspectionPlan}
      pageSize={20}
      emptyGridMessage="Brak zdefiniowanego planu kontroli"
      summaryData={summaryData}
    />
  );
};
