import React, { useState, useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Skeleton from 'react-loading-skeleton';
import Button from 'react-bootstrap/Button';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetProceedingExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/';
import { MyProceedings } from '../../components/Proceedings/MyProceedings';
import { MyProceedingsFinished } from '../../components/Proceedings/MyProceedingsFinished';
import { ProceedingsSearch } from '../../components/Proceedings/ProceedingsSearch';
import { useNavigation } from '../../../common/navigation';
import { TabsHistory } from '../../../app/components/TabsHistory';

export const ProceedingsView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const [proceedings, setProceedings] = useState<GetProceedingExtendedDto[]>();
  const [proceedingsFinished, setProceedingsFinished] =
    useState<GetProceedingExtendedDto[]>();
  const nav = useNavigation();

  useEffect(() => {
    let mounted = true;
    const safe = (set: () => void) => {
      if (mounted) {
        set();
      }
    };
    snrwb.proceedings
      .getPendingForUser()
      .then(data => safe(() => setProceedings(data)));
    snrwb.proceedings
      .getFinishedForUser()
      .then(data => safe(() => setProceedingsFinished(data)));

    return () => {
      mounted = false;
    };
  }, [snrwb.proceedings]);

  return (
    <Card>
      <TabsHistory activeKey="my" id="proceedings" className="mb-3">
        <Tab eventKey="my" title="Moje postępowania" className="mx-3">
          {proceedings ? (
            <>
              <MyProceedings data={proceedings} />
              <Alert variant="dark">
                W celu utworzenia nowego postępowania należy odszukać wyrób w{' '}
                <Button variant="outline-dark" onClick={nav.myInspections}>
                  Kontrolach
                </Button>
              </Alert>
            </>
          ) : (
            <Skeleton count={10} />
          )}
        </Tab>
        <Tab
          eventKey="myFinished"
          title="Moje zakończone postępowania"
          className="mx-3"
        >
          {proceedingsFinished ? (
            <>
              <MyProceedingsFinished data={proceedingsFinished} />
            </>
          ) : (
            <Skeleton count={10} />
          )}
        </Tab>
        <Tab eventKey="search" title="Wyszukiwanie" className="mx-3">
          <ProceedingsSearch
            getData={snrwb.proceedings.getAllByPortion}
            getCount={snrwb.proceedings.getAllCount}
          />
        </Tab>
      </TabsHistory>
    </Card>
  );
};
