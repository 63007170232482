/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportCustomsFeedbacksDetails,
    ReportCustomsFeedbacksDetailsFromJSON,
    ReportCustomsFeedbacksDetailsFromJSONTyped,
    ReportCustomsFeedbacksDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportCustomsFeedbacksType
 */
export interface ReportCustomsFeedbacksType {
    /**
     * 
     * @type {object}
     * @memberof ReportCustomsFeedbacksType
     */
    details: object;
    /**
     * 
     * @type {ReportCustomsFeedbacksDetails}
     * @memberof ReportCustomsFeedbacksType
     */
    sum: ReportCustomsFeedbacksDetails;
}

export function ReportCustomsFeedbacksTypeFromJSON(json: any): ReportCustomsFeedbacksType {
    return ReportCustomsFeedbacksTypeFromJSONTyped(json, false);
}

export function ReportCustomsFeedbacksTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCustomsFeedbacksType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': json['details'],
        'sum': ReportCustomsFeedbacksDetailsFromJSON(json['sum']),
    };
}

export function ReportCustomsFeedbacksTypeToJSON(value?: ReportCustomsFeedbacksType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': value.details,
        'sum': ReportCustomsFeedbacksDetailsToJSON(value.sum),
    };
}


