import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import oneliner from '../../../common/oneliner';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';

export interface SampleCollectPlacesProps {
  organization: OrganizationContext.Organization;
  className?: string;
  onSelect: (place: string) => void;
}

export default (props: SampleCollectPlacesProps) => {
  const snrwb = useSnrwbCore();
  const [values, setValues] = useState<string[]>([]);

  const action = (handler: () => void) => {
    return {
      onClick: handler,
      onKeyPress: (event: { key: string }) => {
        if (event.key === ' ' || event.key === 'Enter') {
          handler();
        }
      },
    };
  };

  useEffect(() => {
    if (!props.organization) {
      setValues([]);
      return;
    }

    const known = new Set<string>();

    const generatedAddress = OrganizationContext.address(props.organization);
    if (generatedAddress) {
      known.add(generatedAddress);
    }

    let mounted = true;

    if ('id' in props.organization) {
      snrwb.sampleCollects
        .getPlacesForOrganization(props.organization.id)
        .then(places => {
          if (mounted) {
            places.forEach(p => p && known.add(p.trim()));
            setValues(Array.from(known));
          }
        });
    } else {
      setValues(Array.from(known));
    }

    return () => {
      mounted = false;
    };
  }, [snrwb.sampleCollects, props.organization]);

  if (values.length === 0) {
    return <></>;
  }

  return (
    <Dropdown align="end" className={props.className}>
      <Dropdown.Toggle variant="outline-secondary">
        Znane miejsca
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {values.map(v => (
          <Dropdown.Item key={v} {...action(() => props.onSelect(v))}>
            {oneliner(v, 70)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
