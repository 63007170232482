import React, { useEffect, useRef } from 'react';
import * as chartJs from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Stack } from 'react-bootstrap';

import { InspectionPlanProgressDataForChartType } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

interface InspectionPlanBar1ChartProps {
  data: InspectionPlanProgressDataForChartType | undefined;
  reportName: string;
}

export const InspectionPlanBar1Chart: React.FC<InspectionPlanBar1ChartProps> =
  props => {
    const myChart = useRef(null);

    chartJs.Chart.register(
      chartJs.CategoryScale,
      chartJs.LinearScale,
      chartJs.BarElement,
      chartJs.Title,
      chartJs.Tooltip,
      chartJs.Legend,
    );

    const options = {
      scales: {
        x: {
          suggestedMax: 100,
          text: '% wykonania',
          display: true,
        },
      },
      indexAxis: 'y' as const,
      plugins: {
        title: {
          display: true,
          text: props.reportName,
        },
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: function () {
              return '';
            },
            labelTextColor: function () {
              return 'white';
            },
            label: (context: chartJs.TooltipItem<'bar'>) => {
              if (context.parsed.x !== null) {
                return context.parsed.x + ' %';
              }
              return ' %';
            },
          },
        },
      },
      responsive: true,
      interaction: {
        mode: 'index' as const,
        intersect: false,
      },
    };

    const data = {
      labels: props.data?.labels,
      datasets: [
        {
          backgroundColor: '#1E2D68',
          data: props.data?.percentages,
        },
      ],
    };

    useEffect(() => {
      if (props.data && myChart && myChart.current) {
        (myChart.current as unknown as chartJs.Chart).update();
      }
    }, [props.data]);

    return (
      <>
        <Stack className="col-md-12 mx-auto">
          {props.data && <Bar options={options} data={data} ref={myChart} />}
        </Stack>
      </>
    );
  };
