/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetSubjectThreadStatsDto
 */
export interface GetSubjectThreadStatsDto {
    /**
     * 
     * @type {string}
     * @memberof GetSubjectThreadStatsDto
     */
    threadId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSubjectThreadStatsDto
     */
    participated: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetSubjectThreadStatsDto
     */
    messagesCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetSubjectThreadStatsDto
     */
    newMessagesCount: number;
}

export function GetSubjectThreadStatsDtoFromJSON(json: any): GetSubjectThreadStatsDto {
    return GetSubjectThreadStatsDtoFromJSONTyped(json, false);
}

export function GetSubjectThreadStatsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSubjectThreadStatsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'threadId': json['threadId'],
        'participated': json['participated'],
        'messagesCount': json['messagesCount'],
        'newMessagesCount': json['newMessagesCount'],
    };
}

export function GetSubjectThreadStatsDtoToJSON(value?: GetSubjectThreadStatsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'threadId': value.threadId,
        'participated': value.participated,
        'messagesCount': value.messagesCount,
        'newMessagesCount': value.newMessagesCount,
    };
}


