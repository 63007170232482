import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import _ from 'lodash';

import {
  ValidationStatus,
  GetSampleCollectDto,
  CreateUpdateSampleCollectDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow from '../../../app/components/FormRow';
import DatePicker from '../../../app/components/DatePicker';
import * as SampleCollectContext from '../../../common/snrwbCore/contexts/SampleCollectContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import ValidationAlert from '../../../app/components/ValidationAlert';
import ItemPresentation from '../../../app/components/ItemPresentation';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import Cautions from '../Cautions/Cautions';

export const SampleCollectSigning: React.FC<{
  editAuthorized: boolean;
  value: GetSampleCollectDto;
  onChange: (inspection: CreateUpdateSampleCollectDto) => void;
  onSign: () => void;
  onRevertSign: () => void;
  readyToSign: (id: string) => Promise<ValidationStatus>;
}> = props => {
  const [sampleCollect, setSampleCollect] = useState(
    SampleCollectContext.toCuDto(props.value),
  );
  const [presentedSampleCollect, setPresentedSampleCollect] =
    useState(sampleCollect);
  const [errors, setErrors] = useState<string[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    setSampleCollect(SampleCollectContext.toCuDto(props.value));
  }, [props.value]);
  useEffect(() => {
    setPresentedSampleCollect(sampleCollect);
  }, [sampleCollect]);

  const presentation = SampleCollectContext.signingPresent(props.value);
  const setProperty = (obj: Partial<GetSampleCollectDto>) => {
    setPresentedSampleCollect({ ...presentedSampleCollect, ...obj });
  };
  const saveIfChanged = (obj: CreateUpdateSampleCollectDto) => {
    if (!_.isEqual(sampleCollect, obj)) {
      props.onChange(obj);
    }
  };
  const sampleCollectChange = (obj: Partial<CreateUpdateSampleCollectDto>) => {
    const dto = { ...sampleCollect, ...obj };
    setSampleCollect(dto);
    saveIfChanged(dto);
  };
  const presentedToInspection = () => {
    setSampleCollect(presentedSampleCollect);
    saveIfChanged(presentedSampleCollect);
  };

  const validate = async () => {
    const status = await props.readyToSign(props.value.id);

    if (status.state === 'ok') {
      setShowErrors(false);
      return true;
    }

    setShowErrors(true);
    setErrors(status.errors.length > 0 ? status.errors : [status.message]);
    return false;
  };

  const emptyIfNull = (value: string | undefined | null) => value || '';

  return (
    <>
      {props.value.approved || !props.editAuthorized ? (
        <ItemPresentation item={presentation} className="pt-3" />
      ) : (
        <Form className="d-grid gap-3">
          <FormRow controlId="placeOfSignature" label="Miejsce podpisania">
            <Form.Control
              type="text"
              value={emptyIfNull(presentedSampleCollect.placeOfSignature)}
              onChange={e => setProperty({ placeOfSignature: e.target.value })}
              onBlur={presentedToInspection}
            />
          </FormRow>
          <FormRow
            controlId="signatureDate"
            label="Data podpisania protokołu z czynności"
          >
            <DatePicker
              value={presentedSampleCollect.signatureDate}
              onChange={date => sampleCollectChange({ signatureDate: date })}
            />
          </FormRow>
          <FormRow controlId="protocols" label="Protokoły">
            <DynamicTextarea
              value={emptyIfNull(presentedSampleCollect.protocols)}
              onChange={e => setProperty({ protocols: e.target.value })}
              onBlur={presentedToInspection}
            />
          </FormRow>
          <FormRow
            controlId="witness"
            label="Osoba, w obecności której sporządzono protokół"
          >
            <DynamicTextarea
              value={emptyIfNull(presentedSampleCollect.witness)}
              onChange={e => setProperty({ witness: e.target.value })}
              onBlur={presentedToInspection}
            />
          </FormRow>
          <FormRow controlId="refusalReason" label="Powód odmowy podpisania">
            <DynamicTextarea
              value={emptyIfNull(presentedSampleCollect.refusalReason)}
              onChange={e => setProperty({ refusalReason: e.target.value })}
              onBlur={presentedToInspection}
            />
          </FormRow>
          <Cautions
            editAuthorized={props.editAuthorized}
            value={props.value}
            onChange={value => sampleCollectChange(value)}
          />
        </Form>
      )}
      <ValidationAlert show={showErrors} errors={errors} className="mt-3" />
      {props.editAuthorized && (
        <div className="d-flex justify-content-end mt-3">
          {props.value.approved ? (
            <ConfirmationButton
              variant="outline-danger"
              roleAnyOf={[Role.ExamsRevoke]}
              onOK={props.onRevertSign}
              confirmation="Czy na pewno cofnąć podpisanie?"
            >
              Cofnij podpisanie
            </ConfirmationButton>
          ) : (
            <ConfirmationButton
              variant="outline-success"
              onValidation={validate}
              onOK={props.onSign}
              confirmation="Czy na pewno protokół został podpisany?"
            >
              Podpisz
            </ConfirmationButton>
          )}
        </div>
      )}
    </>
  );
};
