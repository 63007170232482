/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportPakAmountDetails,
    ReportPakAmountDetailsFromJSON,
    ReportPakAmountDetailsFromJSONTyped,
    ReportPakAmountDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportPakAmountType
 */
export interface ReportPakAmountType {
    /**
     * 
     * @type {object}
     * @memberof ReportPakAmountType
     */
    details: object;
    /**
     * 
     * @type {ReportPakAmountDetails}
     * @memberof ReportPakAmountType
     */
    sum: ReportPakAmountDetails;
}

export function ReportPakAmountTypeFromJSON(json: any): ReportPakAmountType {
    return ReportPakAmountTypeFromJSONTyped(json, false);
}

export function ReportPakAmountTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportPakAmountType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': json['details'],
        'sum': ReportPakAmountDetailsFromJSON(json['sum']),
    };
}

export function ReportPakAmountTypeToJSON(value?: ReportPakAmountType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': value.details,
        'sum': ReportPakAmountDetailsToJSON(value.sum),
    };
}


