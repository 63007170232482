import React from 'react';
import { Badge, Card, Col } from 'react-bootstrap';

type Props = {
  title: string;
  text: string;
  footer: string;
  onClick?: (newWindow?: boolean) => void;
  cpr?: boolean;
};

export const ReportCardMenu: React.FC<Props> = ({
  title,
  text,
  footer,
  onClick,
  cpr,
}) => {
  const handleMouseDown = (event: React.MouseEvent) => {
    if (event.button === 1) {
      event.preventDefault();
      event.stopPropagation();

      onClick && onClick(true);
    }
  };

  return (
    <Col style={{ display: 'flex' }}>
      <Card
        onClick={() => onClick && onClick()}
        onMouseDown={event => handleMouseDown(event)}
        style={
          onClick ? { cursor: 'pointer', width: '100%' } : { width: '100%' }
        }
      >
        <Card.Body>
          <Card.Title>{title}</Card.Title>

          <Card.Text>{text}</Card.Text>
        </Card.Body>
        <Card.Footer>
          {cpr && (
            <Badge bg="warning" text="dark">
              CPR
            </Badge>
          )}{' '}
          <small className="text-muted">{footer}</small>
        </Card.Footer>
      </Card>
    </Col>
  );
};
