/*eslint max-lines-per-function: ["error", 200]*/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useNotifications } from '../../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetProductTypeDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow from '../../../app/components/FormRow';
import { useNavigation } from '../../../common/navigation';
import AlertModal from '../../../app/components/AlertModal';
import nkitwDesc from '../../../common/nkitwDesc';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

interface ParamTypes {
  prodDeleteId: string;
  prodStayId: string;
}

export const MerginProductTypesView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const nav = useNavigation();
  const notifications = useNotifications();
  const [showProblem, setShowProblem] = useState(false);
  const { prodDeleteId, prodStayId } = useParams<ParamTypes>();
  const mountedRef = useRef(false);
  const [productTypeDelete, setProductTypeDelete] = useState<
    GetProductTypeDto | undefined
  >(undefined);
  const [productTypeStay, setProductTypeStay] = useState<
    GetProductTypeDto | undefined
  >(undefined);

  const safeSet = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(() => {
    if (!mountedRef.current) {
      return;
    }
    snrwb.productTypes
      .getById(prodDeleteId)
      .then(prod => {
        safeSet(() => setProductTypeDelete(prod));
      })
      .catch(() => notifications.badLink());
    snrwb.productTypes
      .getById(prodStayId)
      .then(prod => {
        safeSet(() => setProductTypeStay(prod));
      })
      .catch(() => notifications.badLink());
  }, [snrwb, prodDeleteId, prodStayId, notifications]);

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [refresh]);

  const mergeProductTypes = async () => {
    if (productTypeDelete && productTypeStay) {
      await snrwb.productTypes
        .mergeProductTypes(productTypeDelete.id, productTypeStay.id)
        .then(() => {
          setShowProblem(true);
        });
    }
  };

  return (
    <Card>
      <Card.Header>Sklejanie typów wyrobu</Card.Header>
      <Card.Body>
        <>
          <Card>
            <Card.Header>Typ wyrobu główny</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow
                  controlId="productTypeStayNkitw"
                  label="NKITW / TW / Opis"
                >
                  <Form.Control
                    type="text"
                    value={nkitwDesc(productTypeStay)}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="productTypeStayProducer" label="Producent">
                  <Form.Control
                    type="text"
                    value={productTypeStay?.organization.name}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow
                  controlId="productTypeStaySysNumber"
                  label="Numer systemowy"
                >
                  <Form.Control
                    type="text"
                    value={productTypeStay?.sysnumber}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="productTypeStayGroup" label="Grupa">
                  <Form.Control
                    type="text"
                    value={productTypeStay?.group?.value}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="productTypeStaySign" label="Oznakowanie">
                  <Form.Control
                    type="text"
                    value={productTypeStay?.sign?.value}
                    readOnly={true}
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <br />
          <br />
          <Card>
            <Card.Header>Typ wyrobu zastępowany</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow
                  controlId="productTypealUnit"
                  label="NKITW / TW / Opis"
                >
                  <Form.Control
                    type="text"
                    value={nkitwDesc(productTypeDelete)}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow
                  controlId="productTypeDeleteProducer"
                  label="Producent"
                >
                  <Form.Control
                    type="text"
                    value={productTypeDelete?.organization.name}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow
                  controlId="productTypeDelSysNumber"
                  label="Numer systemowy"
                >
                  <Form.Control
                    type="text"
                    value={productTypeDelete?.sysnumber}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="productTypeDelGroup" label="Grupa">
                  <Form.Control
                    type="text"
                    value={productTypeDelete?.group?.value}
                    readOnly={true}
                  />
                </FormRow>
                <FormRow controlId="productTypeDelSign" label="Oznakowanie">
                  <Form.Control
                    type="text"
                    value={productTypeDelete?.sign?.value}
                    readOnly={true}
                  />
                </FormRow>
              </Form>
            </Card.Body>
          </Card>
          <br />
          <br />
          {productTypeStay?.id !== productTypeDelete?.id && (
            <ConfirmationButton
              variant="outline-primary"
              onOK={mergeProductTypes}
              className="mx-2"
              roleAnyOf={[Role.ProductTypesMerging]}
              confirmation="Uwaga: operacja jest nieodwracalna. Czy na pewno wykonać sklejanie?"
            >
              Sklej typy wyrobów
            </ConfirmationButton>
          )}
          <Button
            variant="outline-secondary"
            onClick={() =>
              productTypeStay && nav.productType(productTypeStay.id)
            }
          >
            Rezygnuj
          </Button>
          <AlertModal
            show={showProblem}
            onHide={() => {
              setShowProblem(false);
              productTypeStay && nav.productType(productTypeStay.id);
            }}
            variant="info"
          >
            Typy wyrobów zostały sklejone.
          </AlertModal>
        </>
      </Card.Body>
    </Card>
  );
};
