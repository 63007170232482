import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import {
  CreateUpdateMisstatementDto,
  CreateUpdateMisstatementDtoFromJSON,
  GetInspectedProductDto,
  GetMisstatementDto,
  ValidationStatus,
} from '../autogenerated/snrwbApiClient/models';
import { validateAgainst } from '../validation/validateAgainst';
import { MisstatementSchema } from '../validation/schemas';
import { AssociateDocumentsWithFilter } from '../../../snrwb/components/AssociatedDocuments/AssociateDocumentsTypes';
import { SnrwbCoreContextType } from '..';
import { NotificationsProviderType } from '../../notifications';
import MisstatementLabel from '../../../snrwb/components/Misstatements/MisstatementLabel';

export interface MisstatementContextInterface {
  getById: (id: string) => Promise<GetMisstatementDto>;
  update: (id: string, dto: CreateUpdateMisstatementDto) => Promise<void>;
  create: (dto: CreateUpdateMisstatementDto) => Promise<GetMisstatementDto>;
  createVoid: (dto: CreateUpdateMisstatementDto) => Promise<void>;
  validateNew: (dto: CreateUpdateMisstatementDto) => Promise<ValidationStatus>;
  delete: (id: string) => Promise<void>;
  getByInspectedProduct: (
    inspectedProductId: string,
  ) => Promise<GetMisstatementDto[]>;
  getAllForCollection: (
    products: GetInspectedProductDto[],
  ) => Promise<GetMisstatementDto[]>;
}

export const MisstatementContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.misstatementControllerGet(id),
    create: (dto: CreateUpdateMisstatementDto) =>
      api.misstatementControllerCreate(dto),
    createVoid: async (dto: CreateUpdateMisstatementDto) => {
      await api.misstatementControllerCreate(dto);
    },
    validateNew: (dto: CreateUpdateMisstatementDto) =>
      api.misstatementControllerValidateNew(dto),
    update: (id: string, dto: CreateUpdateMisstatementDto) =>
      api.misstatementControllerUpdate(id, dto),
    delete: (id: string) => api.misstatementControllerDelete(id),
    getByInspectedProduct: (inspectedProductId: string) =>
      api.misstatementControllerGetByInspectedProduct(inspectedProductId),
    getAllForCollection: async (products: GetInspectedProductDto[]) => {
      const misstatements = [];
      for (const product of products) {
        misstatements.push(
          ...(await api.misstatementControllerGetByInspectedProduct(
            product.id,
          )),
        );
      }
      return misstatements;
    },
  };
};

export const newMisstatement = () => CreateUpdateMisstatementDtoFromJSON({});

export const validate = (dto: CreateUpdateMisstatementDto) =>
  validateAgainst(MisstatementSchema, dto);

export const forAssociatedDocuments = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  misstatements: GetMisstatementDto[],
  action?: () => void,
) => {
  const label = (misstatement: GetMisstatementDto) =>
    MisstatementLabel({ misstatement });

  const addMethod = (misstatement: CreateUpdateMisstatementDto) =>
    notifications.onPromise(
      snrwb.misstatements.createVoid(misstatement),
      action,
    );

  const deleteMethod = (id: string) =>
    notifications.onPromise(snrwb.misstatements.delete(id), action);

  const validateMethod = async (cuDto: CreateUpdateMisstatementDto) => {
    const status = await validate({ ...cuDto });
    if (!status.valid) {
      return status;
    }

    const coreStatus = await snrwb.misstatements.validateNew(cuDto);
    return {
      valid: coreStatus.state === 'ok',
      errors: coreStatus.message ? [coreStatus.message] : coreStatus.errors,
    };
  };

  return AssociateDocumentsWithFilter({
    documents: misstatements,
    labelGenerator: label,
    validate: validateMethod,
    new: newMisstatement,
    add: addMethod,
    delete: deleteMethod,
  });
};
