/*eslint max-lines-per-function: ["error", 200]*/
import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Option } from '../Selects/Selector';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';

import { OrganizationForm } from './OrganizationForm';

interface OrganizationProps {
  value: OrganizationContext.Organization;
  readOnly?: boolean;
  editMode?: boolean;
  showValidationErrors: boolean;
  onSelect: (organization: OrganizationContext.Organization) => void;
  onChange?: (organization: OrganizationContext.Organization) => void;
  onValidation: (status: boolean) => void;
  onDecision?: (newOrganization: boolean) => void;
}

// eslint-disable-next-line max-lines-per-function
export const Organization: React.FC<OrganizationProps> = props => {
  const snrwbCore = useSnrwbCore();
  const [readOnly, setReadOnly] = useState(props.readOnly || false);
  const [selectedOrganization, setSelectedOrganization] = useState(props.value);
  const [presentedOrganization, setPresentedOrganization] =
    useState(selectedOrganization);
  const [showValidationErrors, setShowValidationErrors] = useState(
    props.showValidationErrors,
  );
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const onValidation = props.onValidation;
  const mountedRef = useRef(false);

  const propertyChange = (obj: Partial<OrganizationContext.Organization>) => {
    setPresentedOrganization({ ...presentedOrganization, ...obj });
  };
  const selectedChange = (obj: Partial<OrganizationContext.Organization>) => {
    const organization = { ...presentedOrganization, ...obj };
    setSelectedOrganization(organization);
    validate(organization);
    props.onSelect(organization);
    if (props.onChange) {
      props.onChange(organization);
    }
  };
  const presentedToSelected = () => {
    if (!props.readOnly) {
      setSelectedOrganization(presentedOrganization);
      validate(presentedOrganization);
      props.onSelect(presentedOrganization);
      if (props.onChange) {
        props.onChange(presentedOrganization);
      }
    }
  };

  const selectOrganization = (
    nameOrNip: keyof OrganizationContext.Organization,
  ) => {
    return (chosen: Option) => {
      if (chosen.new) {
        selectedChange(OrganizationContext.partial(nameOrNip, chosen.label));
      } else {
        snrwbCore.organizations.getById(chosen.value).then(organization => {
          setSelectedOrganization(organization);
          setReadOnly(true);
          props.onSelect(organization);
        });
      }
      if (props.onDecision) {
        props.onDecision(chosen.new || false);
      }
    };
  };

  const setPost = (postcode: string) => {
    selectedChange({
      postcode: postcode,
      ...(postcode &&
        !presentedOrganization.place &&
        presentedOrganization.country === 'Polska' && {
          place: postcode.slice(7),
        }),
    });
  };

  const validate = useCallback(
    (org: OrganizationContext.Organization) => {
      const dto = OrganizationContext.toCuDto(org);
      OrganizationContext.validate(dto).then(status => {
        if (mountedRef.current) {
          if (status.valid && !('id' in org)) {
            snrwbCore.organizations.validateNew(org).then(result => {
              if (mountedRef.current) {
                setValidationErrors(result.errors);
                onValidation(result.state === 'ok');
                if (result.state === 'ok') {
                  setShowValidationErrors(false);
                }
              }
            });
          } else {
            const forceValid = readOnly && 'id' in org;
            setValidationErrors(status.errors);
            onValidation(forceValid || status.valid);
            if (forceValid || status.valid) {
              setShowValidationErrors(false);
            }
          }
        }
      });
    },
    [onValidation, readOnly, snrwbCore.organizations],
  );

  useEffect(() => {
    setPresentedOrganization(selectedOrganization);
  }, [selectedOrganization]);

  useEffect(() => {
    if (mountedRef.current) {
      setShowValidationErrors(props.showValidationErrors);
    }
  }, [props.showValidationErrors]);
  useEffect(() => setReadOnly(props.readOnly || false), [props.readOnly]);
  useEffect(() => {
    if (mountedRef.current) {
      setSelectedOrganization(props.value);
      validate(props.value);
    }
  }, [props.value, validate]);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // eslint-disable-next-line complexity
  const findByNip = async (nip?: string) => {
    if (!nip) {
      setValidationErrors(['Proszę wprowadzić numer NIP.']);
      setShowValidationErrors(true);
      return;
    }
    try {
      const company = await snrwbCore.gusApi.getByNip(nip.trim());
      if (company) {
        setShowValidationErrors(false);
        let organization = {
          country: 'Polska',
          flat: company.flat || '',
          name: company.name || '',
          nip: company.nip || '',
          addressEx: '',
          postcode: company.postcode + ' ' + company.postPlace || '',
          place: company.place || '',
          street: company.street || '',
          numbers: company.numbers || '',
          firstName: '',
          lastName: '',
          activityTypeId: company.activityTypeId || '',
          activityTypeShort: company.activityTypeShort || '',
        };
        if (props.editMode && 'id' in props.value) {
          const organizationWithId = { ...organization, id: props.value.id };
          organization = organizationWithId;
        }
        props.onSelect(organization);
      }
    } catch (response) {
      const errors = await responseErrors(response as Response);
      setValidationErrors(errors);
      setShowValidationErrors(true);
    }
  };

  return (
    <OrganizationForm
      readOnly={readOnly}
      editMode={props.editMode}
      presentedOrganization={presentedOrganization}
      showValidationErrors={showValidationErrors}
      validationErrors={validationErrors}
      propertyChange={propertyChange}
      presentedToSelected={presentedToSelected}
      selectedChange={selectedChange}
      selectOrganization={selectOrganization}
      setPost={setPost}
      byNameProvider={snrwbCore.organizations.getOptionsByName}
      byNipProvider={snrwbCore.organizations.getOptionsByNip}
      countriesProvider={snrwbCore.organizations.getCountryOptionsByText}
      postcodesProvider={snrwbCore.organizations.getPostcodeOptionsByText}
      placesProvider={snrwbCore.organizations.getPlaceOptionsByText}
      findByNip={findByNip}
    />
  );
};
