import React, { useState } from 'react';
import { Button, Card, Tab, TabPane } from 'react-bootstrap';

import { useNavigation } from '../../../common/navigation';
import {
  CreateUpdateProductTypeDto,
  GetObjectHistoryDto,
  GetProductTypeDto,
  ValidationStatus,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { ObjectHistory } from '../ObjectHistory/ObjectHistory';
import { ObjectHistoryDetails } from '../ObjectHistory/ObjectHistoryDetails';
import nkitwDesc from '../../../common/nkitwDesc';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { useAuth } from '../../../common/hooks/useAuth';
import { TabsHistory } from '../../../app/components/TabsHistory';

import { ProductTypeEdit } from './ProductTypeEdit';
import { ProductTypeSearch } from './ProductTypeSearch';

interface ProductTypeViewProps {
  readonly: boolean;
  productType: GetProductTypeDto;
  objectHistoryData: GetObjectHistoryDto[];
  onChange: (productType: CreateUpdateProductTypeDto) => void;
  onApprove: (status: ValidationStatus) => void;
  onRevertApprove: () => void;
  mayBeApproved: () => Promise<ValidationStatus>;
  refresh: () => void;
}

export const ProductTypeEditView: React.FC<ProductTypeViewProps> = props => {
  const nav = useNavigation();
  const [objectHistory, setObjectHistory] = useState<GetObjectHistoryDto>();
  const [showHistoryDetails, setShowHistoryDetails] = useState(false);
  const snrwb = useSnrwbCore();
  const auth = useAuth();

  const handleOnClickObjectHistory = (key: string) => {
    snrwb.objectHistory.getById(key).then(objectHistory => {
      setObjectHistory(objectHistory);
      setShowHistoryDetails(true);
    });
  };

  const handleObjectHistoryClose = () => {
    setShowHistoryDetails(false);
  };

  return (
    <Card>
      <Card.Header>Typ wyrobu {nkitwDesc(props.productType)}</Card.Header>
      <Card.Body>
        <Card>
          <TabsHistory activeKey="type" id="type" className="mb-3">
            <TabPane
              eventKey="type"
              title="Typ wyrobu"
              className="m-3"
              mountOnEnter={false}
              unmountOnExit={false}
            >
              <ProductTypeEdit {...props} />
              <div className="d-flex justify-content-end m-3">
                <Button
                  variant="outline-primary"
                  onClick={() => nav.timeline(props.productType.id)}
                >
                  Oś czasu
                </Button>
              </div>
            </TabPane>
            <Tab eventKey="historyFeedbacks" title="Historia" className="m-3">
              <ObjectHistory
                data={props.objectHistoryData}
                onClick={handleOnClickObjectHistory}
              />
              <ObjectHistoryDetails
                show={showHistoryDetails}
                handleClose={handleObjectHistoryClose}
                objectHistory={objectHistory}
                detailsType={'ObjectHistoryDetailsForProductType'}
              />
            </Tab>
            {auth.check(Role.OrganizationsMerging) && (
              <Tab
                eventKey="mergingDuplicates"
                title="Sklejanie"
                className="m-3"
              >
                <ProductTypeSearch
                  getData={snrwb.productTypes.getAllByPortion}
                  getCount={snrwb.productTypes.getAllCount}
                  withMerging={true}
                  productTypeHeadId={props.productType.id}
                />
              </Tab>
            )}
          </TabsHistory>
        </Card>
      </Card.Body>
    </Card>
  );
};
