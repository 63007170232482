import React, { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetOrganizationalUnitDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { OrganizationalUnitsGetOptions } from '../../../common/snrwbCore/contexts/OrganizationalUnitContext';

import { Selector, Option } from './Selector';

export interface OrganizationalUnitSelectorProps {
  readOnly?: boolean;
  option?: OrganizationalUnitsGetOptions;
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (option: Option) => void;
  clearable?: boolean;
}

export default (props: OrganizationalUnitSelectorProps) => {
  const snrwb = useSnrwbCore();
  const [organizationalUnits, setOrganizationalUnits] = useState<
    GetOrganizationalUnitDto[]
  >([]);
  const [name, setName] = useState<string>();
  const [badge, setBadge] = useState<string>();

  const setNameAndBadge = useCallback(() => {
    if (!props.value) {
      setName('');
      setBadge('');
      return;
    }
    const unit = organizationalUnits?.find(u => u.id === props.value);
    if (unit && badge !== unit.shortname) {
      setName(
        unit.notified || unit.nationalTechnicalAssessmentUnit ? unit.name : '',
      );
      setBadge(unit.shortname);
    }
  }, [organizationalUnits, badge, props.value]);

  useEffect(() => {
    let mounted = true;
    if (!organizationalUnits.length) {
      snrwb.organizationalUnits
        .getMany(props.option || OrganizationalUnitsGetOptions.all)
        .then(data => {
          if (mounted) {
            setOrganizationalUnits(data);
            setNameAndBadge();
          }
        });
    } else {
      setNameAndBadge();
    }

    return () => {
      mounted = false;
    };
  }, [
    snrwb.organizationalUnits,
    props.option,
    organizationalUnits,
    setNameAndBadge,
  ]);

  const provider = async (text: string) => {
    const values = organizationalUnits.filter(
      v =>
        v.shortname?.toLocaleLowerCase().includes(text.toLocaleLowerCase()) ||
        v.name.toLocaleLowerCase().includes(text.toLocaleLowerCase()),
    );

    return values.map(v => {
      return {
        badge: v.shortname,
        label:
          v.notified || v.nationalTechnicalAssessmentUnit || !v.shortname
            ? v.name
            : '',
        value: v.id,
      };
    });
  };

  if (!organizationalUnits.length) {
    return <Skeleton />;
  }

  return (
    <Selector
      uniqueKey={'units-' + organizationalUnits.length}
      className={props.className}
      readOnly={props.readOnly}
      creatable={false}
      clearable={props.clearable || false}
      placeholder={props.placeholder || 'Wskaż...'}
      provider={provider}
      value={name}
      valueBadge={badge}
      onChange={props.onChange}
    />
  );
};
