import React from 'react';
import Button from 'react-bootstrap/Button';

import logo from '../../../assets/SNRWB_logo_czarne.png';
import { useNavigation } from '../../../common/navigation';

export const Landing: React.FC = () => {
  const nav = useNavigation();

  return (
    <div className="bg-light p-5 rounded-lg m-3">
      <div>
        <img
          className="d-flex justify-content-start"
          src={logo}
          alt="SNRWB"
          height={80}
        />
      </div>
      <p className="lead d-flex justify-content-start">
        System Nadzoru Rynku Wyrobów Budowlanych
      </p>
      <hr className="my-4" />
      <div className="text-center">
        <img
          className="img-logotype-POWER"
          alt="Logotypy: Fundusze Europejskie Wiedza Edukacja Rozwój, Rzeczpospolita Polska, Unia Europejska Europejski Fundusz Społeczny"
        />
      </div>
      <hr className="my-4" />
      <Button variant="outline-primary" onClick={nav.help}>
        Pomoc
      </Button>
    </div>
  );
};
