import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import * as rstm from 'react-simple-tree-menu';

import Tabs from '../../../app/components/Tabs';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetDictionaryDto as Dictionary } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetDictionaryDto';
import { GetDictionaryTypeDto as DictionaryType } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetDictionaryTypeDto';
import { Tree } from '../../../common/components/Tree/Tree';

const mapTreeItem: (
  key?: string,
  id?: string,
  dictionaryTypeId?: string,
  label?: string,
  shortname?: string,
  editable?: boolean,
) => rstm.TreeNodeInArray = (
  key = '',
  id = undefined,
  dictionaryTypeId = undefined,
  label = '',
  shortname = '',
  editable = false,
) => ({
  key: key,
  id: id,
  dictionaryTypeId: dictionaryTypeId,
  label: label,
  shortname: shortname,
  editable: editable,
  nodes: undefined,
});

const mapDictChildren: (
  id: string | undefined,
  rootLevel: boolean,
  data: Dictionary[],
) => rstm.TreeNodeInArray[] = (id, rootLevel, data) => {
  let dicChildren;

  if (rootLevel) {
    dicChildren = data.filter(
      dict => dict.dictionaryTypeId === id && !dict.parentId,
    );
  } else {
    dicChildren = data.filter(dict => dict.parentId === id);
  }

  return dicChildren.map(dic => ({
    ...mapTreeItem(
      dic.id,
      dic.id,
      dic.dictionaryTypeId,
      dic.shortname?.length > 0 ? ` - ${dic.value}` : dic.value,
      dic.shortname,
      true,
    ),
    nodes: mapDictChildren(dic.id, false, data),
  }));
};

export const DictionariesTreeView: React.FC = () => {
  const snrwbApi = useSnrwbCore();

  const [dataDict, setDataDict] = useState<Dictionary[]>([]);
  const [dataDictType, setDataDictType] = useState<DictionaryType[]>([]);
  const [treeData, setTreeData] = useState<rstm.TreeNodeInArray[]>([]);

  useEffect(() => {
    snrwbApi.dictionaries.getActive().then(dataDict => {
      setDataDict(dataDict);
    });
    snrwbApi.dictionariesTypes.getAll().then(dataDictType => {
      setDataDictType(dataDictType);
    });
  }, [snrwbApi]);

  useEffect(() => {
    setTreeData(
      dataDictType.map(dict => ({
        ...mapTreeItem(dict.id, undefined, dict.id, dict.name, '', false),
        nodes: mapDictChildren(dict.id, true, dataDict),
      })),
    );
  }, [dataDict, dataDictType]);

  const handleRefresh = () => {
    snrwbApi.dictionaries.getActive().then(dataDict => {
      setDataDict(dataDict);
    });
  };

  return (
    <Card>
      <Tabs defaultActiveKey="main" id="dictionaries">
        <Tab eventKey="main" title="Słowniki systemowe" className="m-3">
          <Tree
            treeData={treeData}
            variant="dictionary"
            onUpdate={handleRefresh}
          />
        </Tab>
      </Tabs>
    </Card>
  );
};
