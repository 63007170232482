/*eslint max-lines-per-function: ["error", 180]*/
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Table } from 'react-bootstrap';

import { ReportNegativeTest } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportYearData } from '../Reports/Filter';

type Props = {
  data: ReportNegativeTest[] | undefined;
  dataCSV: unknown[];
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportYearData;
};

export const ReportByNegativeTestResults: React.FC<Props> = ({
  data,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = (data && data.length > 0) || false;

  const header = [
    {
      key: 'entityName',
      label: 'Strona (podmiot)',
    },
    {
      key: 'productDescription',
      label: 'Wyrób',
    },
    {
      key: 'productDescription',
      label: 'Data pobrania',
      child: [
        {
          key: 'productDescription',
          label: 'Data rozpoczęcia',
        },
        {
          key: 'productDescription',
          label: 'Data podpisania protokołu pobrania',
        },
      ],
    },
    {
      key: 'entityName',
      label: 'Organ',
    },
    {
      key: 'entityName',
      label: 'Nr akt',
    },
    {
      key: 'entityName',
      label: 'Badanie próbek',
      child: [
        {
          key: 'productDescription',
          label: 'Właściwości użytkowe',
        },
        {
          key: 'productDescription',
          label: 'Wartość deklarowana',
        },
        {
          key: 'productDescription',
          label: 'Wynik badania',
        },
        {
          key: 'productDescription',
          label: 'Wynik badania próbki kontrolnej',
        },
      ],
    },
    {
      key: 'decyzja',
      label: 'Decyzja',
      child: [
        {
          key: 'judgmentType',
          label: 'Podstawa',
        },
        {
          key: 'judgmentOrganizationalUnit',
          label: 'Organ',
        },
        {
          key: 'judgmentPublishDate',
          label: 'Data wydania',
        },
        {
          key: 'judgmentSign',
          label: 'Znak',
        },
        {
          key: 'judgmentAppeal',
          label: 'Odwołanie',
        },
        {
          key: 'judgmentAppealResult',
          label: 'Wynik odwołania',
        },
      ],
    },
  ];
  const children = header
    .filter(h => !!h.child)
    .flatMap(item => {
      return item.child;
    });
  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table
          id="table-to-export"
          className="table-with-fix-head"
          striped
          bordered
          hover
        >
          <thead className="text-center">
            <tr>
              {header.map(head => (
                <th
                  colSpan={head.child ? head.child.length : 1}
                  rowSpan={head.child ? 1 : 2}
                  key={uuidv4()}
                >
                  {head.label}
                </th>
              ))}
            </tr>
            <tr>
              {children.map(h => (
                <th colSpan={1} key={uuidv4()}>
                  {h?.label}
                </th>
              ))}
            </tr>
          </thead>
          {hasData && data && (
            <tbody>
              {data.map(pub => (
                <GetDataRow key={uuidv4()} pub={pub} />
              ))}
              <tr></tr>
            </tbody>
          )}
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

const GetDataRow: React.FC<{
  pub: ReportNegativeTest;
}> = ({ pub }) => {
  if (!pub) {
    return <React.Fragment key={uuidv4()}></React.Fragment>;
  }
  const characteristicLength =
    pub.characteristics?.length && pub.characteristics?.length > 0
      ? pub.characteristics?.length
      : 0;
  const judLength =
    pub.judgments?.length && pub.judgments?.length > 0
      ? pub.judgments?.length
      : 0;
  const rowSpan = Math.max(judLength, characteristicLength);
  const rowSpanExtra = characteristicLength % judLength;
  const rowSpanJud = Math.floor(characteristicLength / judLength);
  const rowSpanFirst = rowSpanExtra + rowSpanJud;
  let judIndex = 0;
  return (
    <React.Fragment key={uuidv4()}>
      <tr>
        <td rowSpan={rowSpan}>{pub.entityName}</td>
        <td rowSpan={rowSpan}>{pub.product}</td>
        <td rowSpan={rowSpan}>{pub.pprDateActivity}</td>
        <td rowSpan={rowSpan}>{pub.pprDateSigningProtocol}</td>
        <td rowSpan={rowSpan}>{pub.jorSymbol}</td>
        <td rowSpan={rowSpan}>
          {pub.pprId && (
            <a
              target="_blank"
              rel="noreferrer"
              href={'/badania/pobranie/' + pub.pprId}
            >
              {pub.pprFileNo}
            </a>
          )}
        </td>
        {pub.characteristics && (
          <>
            <td>{pub.characteristics[0]?.functionalProperties}</td>
            <td
              dangerouslySetInnerHTML={{
                __html: pub.characteristics[0].declaredValue,
              }}
            ></td>
            <td
              dangerouslySetInnerHTML={{
                __html: pub.characteristics[0].testResult,
              }}
            ></td>
            <td
              dangerouslySetInnerHTML={{
                __html: pub.characteristics[0].declaredCheckValue,
              }}
            ></td>
          </>
        )}
        {pub.judgments && judLength > 0 && (
          <>
            <td rowSpan={rowSpanFirst}>{pub.judgments[0]?.type}</td>
            <td rowSpan={rowSpanFirst}>
              {pub.judgments[0]?.organizationalUnit}
            </td>
            <td rowSpan={rowSpanFirst}>{pub.judgments[0]?.description}</td>
            <td rowSpan={rowSpanFirst}>
              {pub.judgments[0].sign && pub.judgments[0].posId && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={'/postepowania/podglad/' + pub.judgments[0].posId}
                >
                  {pub.judgments[0].sign}
                </a>
              )}
            </td>
            <td rowSpan={rowSpanFirst}>
              {pub.judgments[0]?.appeal === true
                ? 'TAK'
                : pub.judgments[0]?.appeal === false
                ? 'Nie'
                : ''}
            </td>
            <td rowSpan={rowSpanFirst}>{pub.judgments[0]?.appealResult}</td>
          </>
        )}
      </tr>
      {pub.characteristics &&
        pub.characteristics.map((char, index) => (
          <React.Fragment key={uuidv4()}>
            {index > 0 && (
              <tr>
                <td
                  dangerouslySetInnerHTML={{
                    __html: char.functionalProperties,
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: char.declaredValue,
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: char.testResult,
                  }}
                ></td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: char.declaredCheckValue,
                  }}
                ></td>
                {index === rowSpanFirst + judIndex * rowSpanJud && (
                  <>
                    <td rowSpan={rowSpanJud}>
                      {pub.judgments[++judIndex]?.type}
                    </td>
                    <td rowSpan={rowSpanJud}>
                      {pub.judgments[judIndex]?.organizationalUnit}
                    </td>
                    <td rowSpan={rowSpanJud}>
                      {pub.judgments[judIndex]?.description}
                    </td>
                    <td rowSpan={rowSpanJud}>
                      {pub.judgments[judIndex]?.sign &&
                        pub.judgments[judIndex]?.posId && (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                              '/postepowania/podglad/' +
                              pub.judgments[judIndex].posId
                            }
                          >
                            {pub.judgments[judIndex].sign}
                          </a>
                        )}
                    </td>
                    <td rowSpan={rowSpanJud}>
                      {pub.judgments[judIndex]?.appeal === true
                        ? 'TAK'
                        : pub.judgments[judIndex]?.appeal === false
                        ? 'Nie'
                        : ''}
                    </td>
                    <td rowSpan={rowSpanJud}>
                      {pub.judgments[judIndex]?.appealResult}
                    </td>
                  </>
                )}
              </tr>
            )}
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};
