import React from 'react';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { GetInspectionExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ProductTypeNotApproved from '../ProductTypes/ProductTypeNotApproved';

export const InspectionType: React.FC<{
  inspection: GetInspectionExtendedDto;
}> = props => {
  if (!props.inspection) {
    return <></>;
  }
  return (
    <>
      {props.inspection.hasNotApprovedProductType && (
        <ProductTypeNotApproved
          className="me-1"
          tooltip="W kontroli jest niezatwierdzony typ wyrobu"
        />
      )}
      {!props.inspection.metric.approved && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Metryka jest niezatwierdzona</Tooltip>}
        >
          <Badge bg="danger" className="me-1">
            !
          </Badge>
        </OverlayTrigger>
      )}
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip>{props.inspection.metric.inspectionType.value}</Tooltip>
        }
      >
        <Badge bg="warning" text="dark" className="me-1">
          {props.inspection.metric.inspectionType.shortname}
        </Badge>
      </OverlayTrigger>
      {props.inspection.approved && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Kontrola podpisana</Tooltip>}
        >
          <Badge bg="success" className="me-1">
            ✓
          </Badge>
        </OverlayTrigger>
      )}
    </>
  );
};
