import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import FormRow from '../../../app/components/FormRow';
import {
  CreateUpdateAttachmentDto,
  GetAttachmentDto,
  GetAttachmentDtoFromJSON,
  GetDictionaryDtoFromJSON,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { AssociateDocumentModalProps } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { MemoizedAttachment } from '../../../common/snrwbCore/contexts/AttachmentContext';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import momencik from '../../../common/momencik';

export const Attachment = (
  props: AssociateDocumentModalProps<
    GetAttachmentDto,
    CreateUpdateAttachmentDto
  >,
) => {
  if (!props.document) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onClose}
      centered={true}
      className="modal-on-modal"
      backdropClassName="modal-on-modal-backdrop"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Załącznik{' '}
          {props.document.date ? 'dodany ' + momencik(props.document.date) : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="d-grid gap-3">
          <FormRow controlId="type" label="Typ" static={true}>
            {props.document.type.value}
          </FormRow>
          <FormRow controlId="file" label="Nazwa">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip>Otwórz w nowym oknie</Tooltip>}
            >
              <Button
                variant="link"
                className="px-0"
                onClick={() =>
                  props.onGet && props.onGet(props.document, 'open')
                }
              >
                {props.document.name}
              </Button>
            </OverlayTrigger>
          </FormRow>
          <FormRow controlId="description" label="Opis" static={true}>
            {props.document.description}
          </FormRow>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onClose}>
          Zamknij
        </Button>
        {!props.readonly && (
          <ConfirmationButton
            variant="outline-danger"
            onOK={() => props.onDelete(props.document.id)}
            confirmation="Czy na pewno usunąć załącznik?"
          >
            Usuń
          </ConfirmationButton>
        )}
        <Button
          variant="outline-primary"
          onClick={() => props.onGet && props.onGet(props.document, 'download')}
        >
          Pobierz
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const AttachmentMemoized = (
  props: AssociateDocumentModalProps<MemoizedAttachment, MemoizedAttachment>,
) => {
  if (!props.document) {
    return <></>;
  }

  const doc = () => {
    if (props.document.existing) {
      return props.document.existing;
    }
    if (props.document.newOne) {
      const dto = GetAttachmentDtoFromJSON(props.document.newOne);
      dto.type = GetDictionaryDtoFromJSON({ value: props.document.type || '' });
      dto.id = props.document.id;
      return dto;
    }
    return GetAttachmentDtoFromJSON({});
  };

  const get = (_doc: GetAttachmentDto, method: string) => {
    props.onGet && props.onGet(props.document, method);
  };
  return (
    <Attachment
      {...props}
      document={doc()}
      onGet={get}
      onUpdate={() => {
        // never used
      }}
    />
  );
};
