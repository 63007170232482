import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import FormRow from '../../../../app/components/FormRow';
import DatePicker from '../../../../app/components/DatePicker';
import ValidationAlert from '../../../../app/components/ValidationAlert';
import { CreateUpdateCertificateDto } from '../../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as CertificateContext from '../../../../common/snrwbCore/contexts/CertificateContext';
import DictionarySelector from '../../Selects/DictionarySelector';
import OrganizationalUnitSelector from '../../Selects/OrganizationalUnitSelector';
import { OrganizationalUnitsGetOptions } from '../../../../common/snrwbCore/contexts/OrganizationalUnitContext';
import { useCookies } from '../../../../common/hooks/useCookies';

export const Certificate: React.FC<{
  value?: CreateUpdateCertificateDto;
  onChange: (certificate: CreateUpdateCertificateDto) => void;
  showValidationErrors: boolean;
  validationErrors: string[];
  producer?: string;
}> = props => {
  const cookies = useCookies();
  const [certificate, setCertificate] = useState(
    CertificateContext.newCertificate(),
  );

  const propertyChange = (obj: Partial<CreateUpdateCertificateDto>) => {
    const dto = { ...certificate, ...obj };
    setCertificate(dto);
    return dto;
  };

  const propagate = (obj?: CreateUpdateCertificateDto) => {
    props.onChange(obj || certificate);
  };

  useEffect(() => {
    const cert = props.value || CertificateContext.newCertificate();
    setCertificate(cert);
  }, [props.value]);

  return (
    <>
      <Form className="d-grid gap-3">
        {props.producer && (
          <FormRow controlId="producer" label="Producent" static>
            {props.producer}
          </FormRow>
        )}
        <FormRow controlId="date" label="Data">
          <DatePicker
            value={certificate.date}
            onChange={dt => propagate(propertyChange({ date: dt }))}
          />
        </FormRow>
        <FormRow controlId="note" label="Numer">
          <Form.Control
            type="text"
            value={certificate.number || ''}
            onChange={e =>
              propagate(propertyChange({ number: e.target.value }))
            }
          />
        </FormRow>
        <FormRow controlId="description" label="Typ informacji">
          <DictionarySelector
            dictionary="certyfikat - typ informacji"
            value={certificate.informationId}
            onChange={option =>
              propagate(propertyChange({ informationId: option.value }))
            }
          />
        </FormRow>
        <FormRow controlId="description" label="Grupa wyrobu">
          <DictionarySelector
            dictionary="typ wyrobu - grupa"
            value={certificate.groupProductTypeId}
            maxDepth={1}
            onChange={option =>
              propagate(
                propertyChange({
                  groupProductTypeId: option.value,
                }),
              )
            }
          />
        </FormRow>
        <FormRow controlId="description" label="Jednostka notyfikowana">
          <OrganizationalUnitSelector
            readOnly={cookies.organizationalUnit?.notified}
            option={OrganizationalUnitsGetOptions.notified}
            value={certificate.organizationalUnitId}
            onChange={option =>
              propagate(propertyChange({ organizationalUnitId: option.value }))
            }
          />
        </FormRow>
        <FormRow controlId="note" label="Uwagi">
          <Form.Control
            type="text"
            value={certificate.note || ''}
            onChange={e => propertyChange({ note: e.target.value })}
            onBlur={() => propagate()}
          />
        </FormRow>
      </Form>
      {props.showValidationErrors && (
        <ValidationAlert
          show={props.validationErrors.length > 0}
          errors={props.validationErrors}
          className="mt-3"
        />
      )}
    </>
  );
};
