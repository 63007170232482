import React from 'react';
import { Table } from 'react-bootstrap';

import { ReportJudgmentsCountType } from '../../../common/types/ReportTypes';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';
import { ReportAlert } from '../Reports/ReportAlert';

import { JudgDetails } from './JudgDetails';

export const AdditionalOrdersKind: React.FC<{
  data: ReportJudgmentsCountType;
  usedFilter?: FilterReportData;
  filterApplied?: boolean;
}> = ({ data, usedFilter, filterApplied }) => {
  const hasData = Object.keys(data.details).length > 0;

  return (
    <ExportToExcel
      fileName="Dodatkowe nakazy niesamodzielne"
      filter={usedFilter}
      idName="table-to-export2"
    >
      <Table id="table-to-export2" striped bordered hover>
        <thead className="text-center">
          <tr>
            <th />
            {data &&
              hasData &&
              Object.keys(data?.details)
                .sort()
                .map((key, idx) => (
                  <th key={idx} colSpan={2}>
                    {key}
                  </th>
                ))}
            {data && <th colSpan={2}>Suma</th>}
          </tr>
          <tr>
            <th>{'Dodatkowe nakazy niesamodzielne'}</th>
            {data &&
              hasData &&
              Object.keys(data?.details)
                .sort()
                .map(idx => (
                  <React.Fragment key={idx}>
                    <th>ogółem</th>
                    <th>w tym CPR</th>
                  </React.Fragment>
                ))}
            {data && <th>ogółem</th>}
            {data && <th>w tym CPR</th>}
          </tr>
        </thead>
        <tbody>
          <JudgDetails data={data} type={['art. 31a ust. 1']}>
            nakaz odkupienia wyrobu nałożony na producenta/importera/sprzedawcę
          </JudgDetails>

          <JudgDetails data={data} type={['art. 31a ust. 2']}>
            nakaz powiadomienia użytkowników o stwierdzonych niezgodnościach
            wyrobu
          </JudgDetails>
          <JudgDetails data={data} type={['art. 31a ust. 3']}>
            nakaz zniszczenia wyrobu
          </JudgDetails>
        </tbody>
      </Table>
      <ReportAlert hasData={hasData} filterApplied={filterApplied} />
    </ExportToExcel>
  );
};
