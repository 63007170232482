import React from 'react';
import { EditorState } from 'draft-js';
import * as staticToolbar from '@draft-js-plugins/static-toolbar';
import * as anchor from '@draft-js-plugins/anchor';

import {
  HeadersSelect,
  BoldButton,
  ItalicButton,
  UnderlineButton,
  StrikethroughButton,
  UnorderedListButton,
  OrderedListButton,
  AddImageButton,
  SubButton,
  SupButton,
  LambdaButton,
} from './Buttons';

export const toolbarConfig = {
  theme: {
    buttonStyles: {
      active: 'rte-toolbar-button-active',
      button: 'rte-toolbar-button',
    },
    toolbarStyles: { toolbar: 'rte-toolbar' },
  },
};

export default (props: {
  value: EditorState;
  readonly?: boolean;
  toolbarPlugin: staticToolbar.StaticToolBarPlugin;
  linkPlugin: anchor.AnchorPlugin;
  setValue: (value: EditorState) => void;
  focus: () => void;
}) => {
  const { Toolbar } = props.toolbarPlugin;
  const { LinkButton } = props.linkPlugin;
  const btnProps = { disabled: props.readonly };

  return (
    <Toolbar>
      {externalProps => (
        <div>
          <HeadersSelect
            readonly={props.readonly}
            editorState={props.value}
            setState={props.setValue}
            focus={props.focus}
          />
          <div className="rte-toolbar-separator" />
          <BoldButton {...externalProps} buttonProps={btnProps} />
          <ItalicButton {...externalProps} buttonProps={btnProps} />
          <UnderlineButton {...externalProps} buttonProps={btnProps} />
          <StrikethroughButton {...externalProps} buttonProps={btnProps} />
          <div className="rte-toolbar-separator" />
          <LambdaButton
            variant="black"
            editorState={props.value}
            setState={props.setValue}
            focus={props.focus}
          />
          <SubButton {...externalProps} buttonProps={btnProps} />
          <SupButton {...externalProps} buttonProps={btnProps} />
          <div className="rte-toolbar-separator" />
          <OrderedListButton {...externalProps} buttonProps={btnProps} />
          <UnorderedListButton {...externalProps} buttonProps={btnProps} />
          <div className="rte-toolbar-separator" />
          <LinkButton
            {...externalProps}
            theme={{ button: props.readonly ? 'disabled' : undefined }}
          />
          <div className="rte-toolbar-separator" />
          <AddImageButton
            readonly={props.readonly}
            editorState={props.value}
            setState={props.setValue}
            focus={props.focus}
          />
        </div>
      )}
    </Toolbar>
  );
};
