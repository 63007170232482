import React from 'react';

export default (props: {
  item: { name: string; value: React.ReactNode }[];
  className?: string;
}) => {
  let i = 0;

  const newLinesIfString = (value: React.ReactNode) => {
    if (typeof value !== 'string') {
      return value;
    }

    return value
      .toString()
      .replaceAll('/n', '\n')
      .split('\n')
      .map(s => (
        <div
          key={++i}
          dangerouslySetInnerHTML={{
            __html: s || '\u00A0',
          }}
        ></div>
      ));
  };

  return (
    <div className={props.className}>
      {props.item.map(row => (
        <dl className="row" key={row.name}>
          <dt className="col-sm-3">{row.name}</dt>
          <dd className="col-sm-9">{newLinesIfString(row.value)}</dd>
        </dl>
      ))}
    </div>
  );
};
