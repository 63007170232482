/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetDictionaryTypeDto
 */
export interface GetDictionaryTypeDto {
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryTypeDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetDictionaryTypeDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetDictionaryTypeDto
     */
    _private: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetDictionaryTypeDto
     */
    levelsCount: number;
}

export function GetDictionaryTypeDtoFromJSON(json: any): GetDictionaryTypeDto {
    return GetDictionaryTypeDtoFromJSONTyped(json, false);
}

export function GetDictionaryTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDictionaryTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        '_private': json['private'],
        'levelsCount': json['levelsCount'],
    };
}

export function GetDictionaryTypeDtoToJSON(value?: GetDictionaryTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'private': value._private,
        'levelsCount': value.levelsCount,
    };
}


