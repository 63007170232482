import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Form } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import ValidationAlert from '../../../app/components/ValidationAlert';
import { CreateUpdateInspectionPlanDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { useCookies } from '../../../common/hooks/useCookies';

interface AddPlanProps {
  handleClose?: () => void;
  show?: boolean;
  plan: CreateUpdateInspectionPlanDto;
  onCompleted: (plan: CreateUpdateInspectionPlanDto) => Promise<{
    saved: boolean;
    errors: string[];
    action?: () => void;
  }>;
}

export const AddPlan: React.FC<AddPlanProps> = props => {
  const [show, setShow] = useState(props.show);
  const [plan, setPlan] = useState(props.plan);
  const [showSaveErrors, setShowSaveErrors] = useState(false);
  const [saveErrors, setSaveErrors] = useState<string[]>([]);

  const cookies = useCookies();

  useEffect(() => {
    setShow(props.show);
    setPlan(props.plan);
    setSaveErrors([]);
    setShowSaveErrors(false);
  }, [props.show, props.plan, show]);

  const createPlan = async () => {
    if (!cookies.organizationalUnit) {
      setSaveErrors([
        'Brak informacji o jednostce organizacyjnej w ciasteczku',
      ]);
      setShowSaveErrors(true);
      return;
    }
    plan.organizationalUnitId = cookies.organizationalUnit.id;
    props.onCompleted(plan).then(saveStatus => {
      if (!saveStatus.saved) {
        setSaveErrors(saveStatus.errors);
        setShowSaveErrors(true);
        return;
      }
      setShow(false);
      if (saveStatus.action) {
        saveStatus.action();
      }
    });
  };

  const propertyChange = (obj: Partial<CreateUpdateInspectionPlanDto>) => {
    const dto = { ...plan, ...obj };
    setPlan(dto);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={props.handleClose}
        centered={true}
        keyboard={true}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Dodawanie nowego planu kontroli</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="mb-2">
            <Card.Header>Plan kontroli</Card.Header>
            <Card.Body>
              <Form className="d-grid gap-3">
                <FormRow controlId="year" label="Rok">
                  <Form.Control
                    type="number"
                    value={plan.year}
                    onChange={e =>
                      propertyChange({ year: parseInt(e.target.value) })
                    }
                  />
                </FormRow>
              </Form>
              <ValidationAlert
                show={showSaveErrors}
                errors={saveErrors}
                className="mt-3"
              />
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={props.handleClose}>
            Zamknij
          </Button>
          <Button variant="outline-primary" onClick={createPlan}>
            Zapisz
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
