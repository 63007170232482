import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import {
  GetPakExtendedDto,
  GetProceedingDto,
  GetPakIdsWithAnotherSourceDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { useNavigation } from '../../../common/navigation';
import momencik from '../../../common/momencik';

export const ProceedingPaks = (props: {
  value: GetProceedingDto;
  paks: GetPakExtendedDto[];
  paksWithAnotherSource?: GetPakIdsWithAnotherSourceDto;
}) => {
  const nav = useNavigation();
  const hasPakSources =
    props.paksWithAnotherSource &&
    props.paksWithAnotherSource.allSources.length > 0;
  const heading = hasPakSources
    ? `W wyniku postępowania utworzono PAK`
    : 'Nie ma PAK dla tego postępowania';
  return (
    <Alert variant="warning">
      <Alert.Heading>{heading}</Alert.Heading>
      {hasPakSources && (
        <>
          <hr />
          {props.paksWithAnotherSource &&
            props.paksWithAnotherSource.allSources.map(pe => (
              <div
                className="d-flex justify-content-between mt-1"
                key={pe.allSourceId}
              >
                <div className="align-self-center">{pe.entityName}</div>
                <div>
                  <Button
                    variant="outline-primary"
                    className="mx-1"
                    onClick={() => nav.pak(pe.allSourceId)}
                  >
                    Pokaż PAK{' '}
                    {pe.startDate ? 'z ' + momencik(pe.startDate) : ''}
                  </Button>
                </div>
              </div>
            ))}
        </>
      )}
    </Alert>
  );
};
