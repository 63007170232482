import React, { useState, useEffect } from 'react';
import { Alert, Tab } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

import Tabs from '../../../../app/components/Tabs';
import ItemPresentation from '../../../../app/components/ItemPresentation';
import {
  CreateUpdateCertificateDto,
  GetObjectHistoryDto,
} from '../../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  CertificateDto,
  convert,
  newCertificate,
  forPresentation,
} from '../../../../common/snrwbCore/contexts/CertificateContext';
import { ObjectHistory } from '../../ObjectHistory/ObjectHistory';
import { ObjectHistoryDetails } from '../../ObjectHistory/ObjectHistoryDetails';
import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { useCookies } from '../../../../common/hooks/useCookies';

import { Certificate } from './Certificate';

interface CertFormProps {
  showErrors: boolean;
  editAuthorized: boolean;
  certificate?: CertificateDto;
  producer?: string;
  errors: string[];
  onChange: (certificate: CertificateDto | CreateUpdateCertificateDto) => void;
}

export const CertFormCard: React.FC<CertFormProps> = props => {
  const [certificate, setCertificate] = useState(
    props.certificate ? props.certificate : newCertificate(),
  );
  const [objectHistory, setObjectHistory] = useState<GetObjectHistoryDto>();
  const [objectHistoryEntries, setObjectHistoryEntries] = useState<
    GetObjectHistoryDto[]
  >([]);
  const [showHistoryDetails, setShowHistoryDetails] = useState(false);
  const snrwb = useSnrwbCore();
  const cookies = useCookies();

  const onChangeCertificate = (certificate: CertificateDto) => {
    setCertificate(certificate);
    props.onChange(certificate);
  };

  const handleOnClickObjectHistory = (key: string) => {
    snrwb.objectHistory.getById(key).then(objectHistory => {
      setObjectHistory(objectHistory);
      setShowHistoryDetails(true);
    });
  };

  const handleObjectHistoryClose = () => {
    setShowHistoryDetails(false);
  };

  useEffect(() => {
    const cert = props.certificate ? props.certificate : newCertificate();

    if (
      cookies.organizationalUnit?.notified &&
      'organizationalUnitId' in cert &&
      !cert.organizationalUnitId
    ) {
      cert.organizationalUnitId = cookies.organizationalUnit?.id;
    }

    setCertificate(cert);

    if (props.certificate && 'id' in props.certificate) {
      snrwb.objectHistory
        .getHistory(props.certificate.id, 'GetCertificateDto')
        .then(elements => {
          setObjectHistoryEntries(elements);
        });
    }
  }, [
    cookies.organizationalUnit?.id,
    cookies.organizationalUnit?.notified,
    props.certificate,
    snrwb.objectHistory,
  ]);

  if (!props.editAuthorized && props.certificate && 'id' in props.certificate) {
    return (
      <>
        <ItemPresentation item={forPresentation(props.certificate)} />
        <Alert variant="warning" className="mt-3">
          Edycja jest zablokowana z powodu braku uprawnień.
        </Alert>
      </>
    );
  }

  return (
    <Card>
      <Tabs defaultActiveKey="cert" id="cert" className="mb-3">
        <Tab eventKey="cert" title="Certyfikat" className="m-3">
          <Certificate
            onChange={onChangeCertificate}
            showValidationErrors={props.showErrors}
            validationErrors={props.errors}
            producer={props.producer}
            value={'id' in certificate ? convert(certificate) : certificate}
          />
        </Tab>
        {objectHistoryEntries.length > 0 && (
          <Tab eventKey="historyFeedbacks" title="Historia" className="m-3">
            <ObjectHistory
              data={objectHistoryEntries}
              onClick={handleOnClickObjectHistory}
            />
            <ObjectHistoryDetails
              show={showHistoryDetails}
              handleClose={handleObjectHistoryClose}
              objectHistory={objectHistory}
              detailsType={'ObjectHistoryDetailsForCertificate'}
            />
          </Tab>
        )}
      </Tabs>
    </Card>
  );
};
