import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { GetInspectionPlanDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetInspectionPlanDto';
import { MyInspectionPlans } from '../../components/InspectionPlans/MyInspectionPlans';
import { useNavigation } from '../../../common/navigation';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';
import { AddPlan } from '../../components/InspectionPlans/AddPlan';
import { CreateUpdateInspectionPlanDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as InspectionPlanContext from '../../../common/snrwbCore/contexts/InspectionPlanContext';
import { newInspectionPlan } from '../../../common/snrwbCore/contexts/InspectionPlanContext';
import { useAuth } from '../../../common/hooks/useAuth';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { TabsHistory } from '../../../app/components/TabsHistory';

export const InspectionPlanView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const nav = useNavigation();
  const auth = useAuth();
  const notifications = useNotifications();
  const [plans, setPlans] = useState<GetInspectionPlanDto[]>([]);
  const [thisYearPlans, setThisYearPlans] = useState<GetInspectionPlanDto[]>(
    [],
  );
  const [nextYearPlans, setNextYearPlans] = useState<GetInspectionPlanDto[]>(
    [],
  );
  const [show, setShow] = useState(false);
  const [plan, setPlan] = useState(newInspectionPlan());

  const thisYear = new Date().getFullYear();
  const addPlan = async (planCU: CreateUpdateInspectionPlanDto) => {
    const status = await InspectionPlanContext.validate(planCU);
    if (status.valid) {
      try {
        const newPlan = await snrwb.inspectionPlans.create(planCU);
        setShow(false);
        return {
          saved: true,
          errors: [],
          action: () => nav.inspectionPlan(newPlan.id),
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleAdd = () => {
    if (!auth.check(Role.InspectionsPlansEdit)) {
      notifications.unauthorized();
      return;
    }
    setShow(true);
    plan.year = thisYear + 1;
    setPlan(plan);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    snrwb.inspectionPlans.getAll().then(setPlans);
  }, [snrwb.inspectionPlans]);

  useEffect(() => {
    setThisYearPlans(plans.filter(o => o.year === thisYear));
    setNextYearPlans(plans.filter(o => o.year === thisYear + 1));
  }, [plans, thisYear]);

  return (
    <Card>
      <TabsHistory activeKey="main" id="inspectionPlans">
        <Tab
          eventKey="main"
          title={'Plany kontroli na rok ' + thisYear}
          className="m-3"
        >
          <MyInspectionPlans data={thisYearPlans} />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy plan
          </Button>
          <AddPlan
            show={show}
            handleClose={handleClose}
            plan={plan}
            onCompleted={addPlan}
          />
        </Tab>
        <Tab
          eventKey="nextYear"
          title={'Plany kontroli na rok ' + (thisYear + 1)}
          className="m-3"
        >
          <MyInspectionPlans data={nextYearPlans} />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy plan
          </Button>
          <AddPlan
            show={show}
            handleClose={handleClose}
            plan={plan}
            onCompleted={addPlan}
          />
        </Tab>
        <Tab eventKey="all" title="Wszystkie plany kontroli" className="m-3">
          <MyInspectionPlans data={plans} />
          <Button variant="outline-primary" onClick={handleAdd}>
            Nowy plan
          </Button>
        </Tab>
      </TabsHistory>
    </Card>
  );
};
