import React from 'react';

export default (props: { variant: 'white' | 'green' }) => {
  const color =
    props.variant === 'green' ? 'rgb(25, 135, 84)' : 'rgb(255, 255, 255)';
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" fill="none" r="10" stroke={color} strokeWidth="2">
        <animate
          attributeName="r"
          from="8"
          to="20"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="1"
          to="0"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="20" cy="20" fill={color} r="10" />
    </svg>
  );
};
