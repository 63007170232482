import React from 'react';

import { Grid } from '../../../common/components/Grid/Grid';
import {
  GridCountFunction,
  GridGetterFunction,
} from '../../../common/components/Grid/GridDataTypes';
import momencik from '../../../common/momencik';
import { GetObjectHistoryDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';

import { getCategoryText } from './ObjectHistoryUtils';

interface ObjectHistoryProps {
  getData?: GridGetterFunction;
  getCount?: GridCountFunction;
  data?: GetObjectHistoryDto[];
  onClick?: (key: string) => void;
}

export const objectHistoryForGrid = (objectHistory: GetObjectHistoryDto) => {
  const event = Object(objectHistory.eventJson);
  return {
    key: objectHistory.id,
    values: {
      'event.type': getCategoryText(event.category),
      'event.date': momencik(event.ts, 'LLLL'),
      'event.user': event.user.value,
    },
    orderByValues: {
      'event.date': momencik(event.ts, 'YYYY.MM.DD HH:mm:ss.SSSS'),
    },
  };
};

export const ObjectHistory: React.FC<ObjectHistoryProps> = props => {
  const columns = [
    { header: 'Data', property: 'event.date' },
    { header: 'Użytkownik', property: 'event.user' },
    { header: 'Typ', property: 'event.type' },
  ];

  return (
    <Grid
      getData={props.getData}
      getDataCount={props.getCount}
      data={
        props.data ? props.data.map(d => objectHistoryForGrid(d)) : undefined
      }
      columns={columns}
      pageSize={10}
      availablePageSizes={[10, 20, 50]}
      showFilter={true}
      initialOrderColumn="event.date"
      initialOrderDirection="DESC"
      onRowClick={props.onClick}
      emptyGridMessage="Brak historii"
    />
  );
};
