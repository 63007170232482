import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { CreateUpdateSampleDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import CurrencyControl from '../../../app/components/CurrencyControl';

const nullIfEmpty = (value: string) => value || (null as unknown as string);

export default ({
  readonly,
  presentedSample,
  setProperty,
  presentedToSelected,
}: {
  readonly: boolean;
  presentedSample: CreateUpdateSampleDto;
  setProperty: (obj: Partial<CreateUpdateSampleDto>) => void;
  presentedToSelected: () => void;
}) => (
  <>
    <Row>
      <Col md={3}></Col>
      <Col md={3} className="col-form-label">
        Badanie
      </Col>
      <Col md={3} className="col-form-label">
        Transport
      </Col>
      <Col md={3} className="col-form-label">
        Przechowanie
      </Col>
    </Row>
    <Row>
      <Col md={3} className="col-form-label">
        Koszt Skarbu Państwa
      </Col>
      <Col md={3}>
        <CurrencyControl
          disabled={readonly}
          value={presentedSample.examCostForGoverment || ''}
          onChange={e =>
            setProperty({ examCostForGoverment: nullIfEmpty(e.target.value) })
          }
          onBlur={presentedToSelected}
        />
      </Col>
      <Col md={3}>
        <CurrencyControl
          disabled={readonly}
          value={presentedSample.transportCostForGoverment || ''}
          onChange={e =>
            setProperty({
              transportCostForGoverment: nullIfEmpty(e.target.value),
            })
          }
          onBlur={presentedToSelected}
        />
      </Col>
      <Col md={3}>
        <CurrencyControl
          disabled={readonly}
          value={presentedSample.storageCostForGoverment || ''}
          onChange={e =>
            setProperty({
              storageCostForGoverment: nullIfEmpty(e.target.value),
            })
          }
          onBlur={presentedToSelected}
        />
      </Col>
    </Row>
    <Row>
      <Col md={3} className="col-form-label">
        Koszt kontrolowanego
      </Col>
      <Col md={3}>
        <CurrencyControl
          disabled={readonly}
          value={presentedSample.examCostForInspected || ''}
          onChange={e =>
            setProperty({ examCostForInspected: nullIfEmpty(e.target.value) })
          }
          onBlur={presentedToSelected}
        />
      </Col>
      <Col md={3}>
        <CurrencyControl
          disabled={readonly}
          value={presentedSample.transportCostForInspected || ''}
          onChange={e =>
            setProperty({
              transportCostForInspected: nullIfEmpty(e.target.value),
            })
          }
          onBlur={presentedToSelected}
        />
      </Col>
      <Col md={3}>
        <CurrencyControl
          disabled={readonly}
          value={presentedSample.storageCostForInspected || ''}
          onChange={e =>
            setProperty({
              storageCostForInspected: nullIfEmpty(e.target.value),
            })
          }
          onBlur={presentedToSelected}
        />
      </Col>
    </Row>
  </>
);
