import React, { useState, useEffect, useCallback, useRef } from 'react';
import { EditorState, ContentBlock, CompositeDecorator } from 'draft-js';
import Editor, { composeDecorators } from '@draft-js-plugins/editor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createResizeablePlugin from '@draft-js-plugins/resizeable';
import createFocusPlugin from '@draft-js-plugins/focus';
import createImagePlugin from '@draft-js-plugins/image';
import createLinkPlugin from '@draft-js-plugins/anchor';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/image/lib/plugin.css';
import '@draft-js-plugins/focus/lib/plugin.css';
import '@draft-js-plugins/anchor/lib/plugin.css';

import { handlePastedFiles } from './RichTextEdit/ImageHelpers';
import Toolbar, { toolbarConfig } from './RichTextEdit/Toolbar';
import { normalizedHtml, callOnChange } from './RichTextEdit/ImportExport';
import { InsertLinkButton, keyHandler } from './RichTextEdit/Buttons';

export default (props: {
  id?: string;
  value: string;
  readonly?: boolean;
  className?: string;
  onChange: (value: string) => void;
}) => {
  const [value, setValue] = useState(EditorState.createEmpty());
  const valueRef = useRef(value);
  const [toolbarPlugin] = useState(createToolbarPlugin(toolbarConfig));
  const [focusPlugin] = useState(createFocusPlugin());
  const [resizeablePlugin] = useState(createResizeablePlugin());
  const [imagePlugin] = useState(
    createImagePlugin({
      decorator: composeDecorators(
        resizeablePlugin.decorator,
        focusPlugin.decorator,
      ),
    }),
  );
  const [linkPlugin] = useState(
    createLinkPlugin({
      placeholder: 'http://…',
      LinkButton: InsertLinkButton,
    }),
  );
  const editorRef = useRef<Editor | null>(null);

  const changeValue = (v: EditorState) => {
    valueRef.current = v;
    setValue(v);
  };

  const onBlur = useCallback(() => {
    if (props.readonly) {
      return;
    }
    callOnChange(value, props.value, props.onChange);
  }, [props, value]);

  const onKey = (event: React.KeyboardEvent<Element>) => {
    return keyHandler(event, value, changeValue);
  };

  const findLinks = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
  ) => {
    contentBlock.findEntityRanges(character => {
      const content = valueRef.current.getCurrentContent();
      const entityKey = character.getEntity();
      return (
        entityKey !== null && content.getEntity(entityKey).getType() === 'LINK'
      );
    }, callback);
  };

  const Link = (props: { entityKey: string; children: React.ReactNode }) => {
    const { url } = valueRef.current
      .getCurrentContent()
      .getEntity(props.entityKey)
      .getData();
    return (
      <a href={url} title={url}>
        {props.children}
      </a>
    );
  };

  useEffect(() => {
    if (!props.value) {
      return;
    }

    callOnChange(valueRef.current, props.value, () => {
      const html = normalizedHtml(props.value);
      changeValue(
        EditorState.createWithContent(
          html.content,
          new CompositeDecorator([{ strategy: findLinks, component: Link }]),
        ),
      );
    });
  }, [props.value]);

  useEffect(() => {
    const threeSeconds = 3 * 1000;
    const interval = setTimeout(onBlur, threeSeconds);

    return () => {
      clearTimeout(interval);
    };
  }, [props, onBlur]);

  return (
    <div className="rte-editor">
      <div
        className={'rte-inner' + (props.className ? ' ' + props.className : '')}
        onBlur={onBlur}
      >
        <div
          role="textbox"
          tabIndex={0}
          className="rte-textbox"
          onClick={() => editorRef.current?.focus()}
          onFocus={() => editorRef.current?.focus()}
          onKeyPress={() => editorRef.current?.focus()}
        >
          <Editor
            readOnly={props.readonly}
            spellCheck
            editorState={value}
            onChange={changeValue}
            keyBindingFn={onKey}
            plugins={[
              toolbarPlugin,
              focusPlugin,
              resizeablePlugin,
              imagePlugin,
              linkPlugin,
              {
                handlePastedFiles: files =>
                  handlePastedFiles(value, files, changeValue),
              },
            ]}
            customStyleMap={{
              STRIKETHROUGH: { textDecoration: 'line-through' },
              SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
              SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' },
            }}
            ref={editorRef}
          />
        </div>
        <Toolbar
          readonly={props.readonly}
          value={value}
          setValue={changeValue}
          linkPlugin={linkPlugin}
          toolbarPlugin={toolbarPlugin}
          focus={() => editorRef.current?.focus()}
        />
      </div>
    </div>
  );
};
