import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

export default (props: { loading: boolean; text?: string }) => {
  const [loadingText, setLoadingText] = useState<string>('Proces w toku...');
  useEffect(() => {
    props.text
      ? setLoadingText(props.text)
      : setLoadingText('Proces w toku...');
  }, [props.text]);
  return (
    <Modal
      centered={true}
      backdrop="static"
      show={props.loading}
      className="modal-on-modal"
      backdropClassName="modal-on-modal-backdrop"
    >
      <Modal.Header>
        <Modal.Title>{loadingText}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="warning" />
        <Spinner animation="grow" variant="info" />
      </Modal.Body>
    </Modal>
  );
};
