import React from 'react';
import { Table } from 'react-bootstrap';

import { GetDictionaryDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { ReportPakJudgmentType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: ReportPakJudgmentType | undefined;
  judgmentTypes: GetDictionaryDto[];
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportPak: React.FC<Props> = ({
  data,
  judgmentTypes,
  reportName,
  filterApplied,
  usedFilter,
}) => {
  const hasData = data ? Object.keys(data.details).length > 0 : false;

  return (
    <>
      <ExportToExcel fileName={reportName} filter={usedFilter}>
        <Table id="table-to-export" key={'table'} striped bordered hover>
          <thead className="text-center">
            <tr>
              <th>{reportName}</th>
              {data &&
                hasData &&
                Object.keys(data?.details)
                  .sort()
                  .map((key, idx) => (
                    <React.Fragment key={idx}>
                      <th>{key}</th>
                    </React.Fragment>
                  ))}
              {data && <th colSpan={2}>Suma</th>}
            </tr>
          </thead>
          <tbody>
            {data &&
              hasData &&
              judgmentTypes.map((key, idx) => (
                <CADetails
                  key={idx}
                  data={data}
                  type={key.shortname}
                  value={key.value}
                />
              ))}
          </tbody>
        </Table>
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

const CADetails: React.FC<{
  data: ReportPakJudgmentType | undefined;
  type: string;
  value: string;
}> = ({ data, type, value }) => {
  if (!data || Object.keys(data?.details).length <= 0) {
    return <></>;
  }

  return (
    <tr>
      <td>{value}</td>
      {Object.keys(data?.details)
        .sort()
        .map((key, idx) => (
          <td key={idx} className="numbers-column">
            {data?.details?.[key]?.[type] || 0}
          </td>
        ))}
      <td className="numbers-column">{data?.sum?.[type] || 0}</td>
    </tr>
  );
};
