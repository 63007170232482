/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetDictionaryDto,
    GetDictionaryDtoFromJSON,
    GetDictionaryDtoFromJSONTyped,
    GetDictionaryDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetCorrectiveActionDto
 */
export interface GetCorrectiveActionDto {
    /**
     * 
     * @type {string}
     * @memberof GetCorrectiveActionDto
     */
    id: string;
    /**
     * 
     * @type {GetDictionaryDto}
     * @memberof GetCorrectiveActionDto
     */
    type: GetDictionaryDto;
    /**
     * 
     * @type {Date}
     * @memberof GetCorrectiveActionDto
     */
    date?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof GetCorrectiveActionDto
     */
    effective: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCorrectiveActionDto
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetCorrectiveActionDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCorrectiveActionDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof GetCorrectiveActionDto
     */
    proceedingId: string;
}

export function GetCorrectiveActionDtoFromJSON(json: any): GetCorrectiveActionDto {
    return GetCorrectiveActionDtoFromJSONTyped(json, false);
}

export function GetCorrectiveActionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCorrectiveActionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': GetDictionaryDtoFromJSON(json['type']),
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'effective': json['effective'],
        'description': json['description'],
        'approved': json['approved'],
        'inspectedProductId': json['inspectedProductId'],
        'proceedingId': json['proceedingId'],
    };
}

export function GetCorrectiveActionDtoToJSON(value?: GetCorrectiveActionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': GetDictionaryDtoToJSON(value.type),
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'effective': value.effective,
        'description': value.description,
        'approved': value.approved,
        'inspectedProductId': value.inspectedProductId,
        'proceedingId': value.proceedingId,
    };
}


