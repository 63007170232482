import {
  GetCorrectiveActionDto,
  CreateUpdateCorrectiveActionDto,
  CreateUpdateCorrectiveActionDtoFromJSON,
  GetInspectedProductDto,
} from '../autogenerated/snrwbApiClient';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import { validateAgainst } from '../validation/validateAgainst';
import { CorrectiveActionSchema } from '../validation/schemas';
import { AssociateDocumentsWithFilter } from '../../../snrwb/components/AssociatedDocuments/AssociateDocumentsTypes';
import { SnrwbCoreContextType } from '..';
import { NotificationsProviderType } from '../../notifications';
import DictionaryStatic from '../../../snrwb/components/Dictionary/DictionaryStatic';
import momencik from '../../momencik';

export interface CorrectiveActionContextInterface {
  getById: (id: string) => Promise<GetCorrectiveActionDto>;
  update: (id: string, dto: CreateUpdateCorrectiveActionDto) => Promise<void>;
  create: (
    dto: CreateUpdateCorrectiveActionDto,
  ) => Promise<GetCorrectiveActionDto>;
  createVoid: (dto: CreateUpdateCorrectiveActionDto) => Promise<void>;
  delete: (id: string) => Promise<void>;
  getByInspectedProduct: (
    inspectedProductId: string,
  ) => Promise<GetCorrectiveActionDto[]>;
  getByProceeding: (proceedingId: string) => Promise<GetCorrectiveActionDto[]>;
  getAllForCollection: (
    products: GetInspectedProductDto[],
  ) => Promise<GetCorrectiveActionDto[]>;
  approve: (id: string) => Promise<void>;
  revertApprove: (id: string) => Promise<void>;
}

export const CorrectiveActionContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.correctiveActionControllerGet(id),
    update: (id: string, dto: CreateUpdateCorrectiveActionDto) =>
      api.correctiveActionControllerUpdate(id, dto),
    create: (dto: CreateUpdateCorrectiveActionDto) =>
      api.correctiveActionControllerCreate(dto),
    createVoid: async (dto: CreateUpdateCorrectiveActionDto) => {
      await api.correctiveActionControllerCreate(dto);
    },
    delete: (id: string) => api.correctiveActionControllerDelete(id),
    getByInspectedProduct: (inspectedProductId: string) =>
      api.correctiveActionControllerGetByInspectedProduct(inspectedProductId),
    getByProceeding: (proceedingId: string) =>
      api.correctiveActionControllerGetByProceeding(proceedingId),
    getAllForCollection: async (products: GetInspectedProductDto[]) => {
      const correctiveActions = [];
      for (const product of products) {
        correctiveActions.push(
          ...(await api.correctiveActionControllerGetByInspectedProduct(
            product.id,
          )),
        );
      }
      return correctiveActions;
    },
    approve: (id: string) => api.correctiveActionControllerApprove(id),
    revertApprove: (id: string) =>
      api.correctiveActionControllerRevertApprove(id),
  };
};

export const newCorrectiveAction = () => {
  return CreateUpdateCorrectiveActionDtoFromJSON({
    effective: false,
    approved: false,
  });
};

export const toPresent = (correctiveAction: GetCorrectiveActionDto) => {
  const actionPresentation = [
    {
      name: 'Rodzaj',
      value: DictionaryStatic({ value: correctiveAction.type }),
    },
    {
      name: 'Data',
      value: momencik(correctiveAction.date),
    },
    {
      name: 'Działanie skuteczne',
      value: correctiveAction.effective ? 'Tak' : 'Nie',
    },
  ];

  if (correctiveAction.description) {
    actionPresentation.push({
      name: 'Opis',
      value: correctiveAction.description,
    });
  }

  return actionPresentation;
};

export const validate = (dto: CreateUpdateCorrectiveActionDto) =>
  validateAgainst(CorrectiveActionSchema, dto);

export const forAssociatedDocuments = (
  snrwb: SnrwbCoreContextType,
  notifications: NotificationsProviderType,
  correctiveActions: GetCorrectiveActionDto[],
  action?: () => void,
) => {
  const label = (correctiveAction: GetCorrectiveActionDto) =>
    `${correctiveAction.type.value} z ${momencik(correctiveAction.date)}`;

  const addMethod = (createUpdateDto: CreateUpdateCorrectiveActionDto) => {
    notifications.onPromise(
      snrwb.correctiveActions.createVoid(createUpdateDto),
      action,
    );
  };

  const deleteMethod = (id: string) =>
    notifications.onPromise(snrwb.correctiveActions.delete(id), action);

  const updateMethod = (
    id: string,
    createUpdateDto: CreateUpdateCorrectiveActionDto,
  ) =>
    notifications.onPromise(
      snrwb.correctiveActions.update(id, createUpdateDto),
      action,
    );

  const approveMethod = (id: string, cuDto?: CreateUpdateCorrectiveActionDto) =>
    notifications.onPromise(
      (async () => {
        if (cuDto) {
          await snrwb.correctiveActions.update(id, cuDto);
        }
        await snrwb.correctiveActions.approve(id);
      })(),
      action,
    );

  const revertApproveMethod = (id: string) =>
    notifications.onPromise(snrwb.correctiveActions.revertApprove(id), action);

  return AssociateDocumentsWithFilter({
    documents: correctiveActions,
    new: newCorrectiveAction,
    labelGenerator: label,
    validate: validate,
    add: addMethod,
    update: updateMethod,
    delete: deleteMethod,
    approve: approveMethod,
    revertApprove: revertApproveMethod,
  });
};
