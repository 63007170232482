import { GetInspectionMetricDto } from '../autogenerated/snrwbApiClient/models/GetInspectionMetricDto';
import {
  CreateUpdateInspectionMetricDto,
  CreateUpdateInspectionMetricDtoFromJSON,
} from '../autogenerated/snrwbApiClient/models/CreateUpdateInspectionMetricDto';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';
import { validateAgainst } from '../validation/validateAgainst';
import { InspectionMetricSchema } from '../validation/schemas';
import OrganizationalUnitBadge from '../../../snrwb/components/OrganizationalUnit/OrganizationalUnitBadge';
import DictionaryStatic from '../../../snrwb/components/Dictionary/DictionaryStatic';
import momencik from '../../momencik';

export interface InspectionMetricContextInterface {
  getById: (id: string) => Promise<GetInspectionMetricDto>;
  update: (id: string, dto: CreateUpdateInspectionMetricDto) => Promise<void>;
  approve: (id: string) => Promise<void>;
  revertApprove: (id: string) => Promise<void>;
}

export const InspectionMetricContext = (api: DefaultApi) => {
  return {
    getById: (id: string) => api.inspectionMetricControllerGet(id),
    update: (id: string, dto: CreateUpdateInspectionMetricDto) =>
      api.inspectionMetricControllerUpdate(id, dto),
    approve: (id: string) => api.inspectionMetricControllerApprove(id),
    revertApprove: (id: string) =>
      api.inspectionMetricControllerRevertApprove(id),
  };
};

export const MetricToPresent = (metric: GetInspectionMetricDto) => [
  {
    name: 'Podmiot',
    value:
      metric.organization.name +
      (metric.organizationType
        ? ' (' + metric.organizationType.value + ')'
        : '') +
      (metric.organization.nip ? ' NIP: ' + metric.organization.nip : ''),
  },
  {
    name: 'Organ',
    value: OrganizationalUnitBadge({
      organizationalUnit: metric.organizationalUnit,
    }),
  },
  {
    name: 'Rodzaj',
    value: DictionaryStatic({ value: metric.inspectionType }),
  },
  {
    name: 'Data rozpoczęcia',
    value: momencik(metric.startDate),
  },
  {
    name: 'Miejsce kontroli',
    value: metric.inspectionPlace,
  },
  {
    name: 'Numer akt',
    value: metric.fileNumber,
  },
];

export const newInspectionMetric = () => {
  const metric = CreateUpdateInspectionMetricDtoFromJSON({});
  metric.startDate = new Date();
  return metric;
};

export const validate = (metric: CreateUpdateInspectionMetricDto) =>
  validateAgainst(InspectionMetricSchema, metric);

export const convert = (metric: GetInspectionMetricDto) => {
  if (!metric) {
    return newInspectionMetric();
  }
  const converted = CreateUpdateInspectionMetricDtoFromJSON(metric);
  converted.inspectionTypeId = metric.inspectionType?.id;
  converted.organizationId = metric.organization?.id;
  converted.organizationalUnitId = metric.organizationalUnit?.id;
  converted.organizationTypeId = metric.organizationType?.id;

  return converted;
};
