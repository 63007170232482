/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUpdateProtocolDuringInspectionDto
 */
export interface CreateUpdateProtocolDuringInspectionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    number: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    date?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    typeId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    witnesses: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    attachments: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    repairMethod: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    signatureDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    refusalReason: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    placeOfSignature: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    approved: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    inspectedProductId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateProtocolDuringInspectionDto
     */
    quantity: string;
}

export function CreateUpdateProtocolDuringInspectionDtoFromJSON(json: any): CreateUpdateProtocolDuringInspectionDto {
    return CreateUpdateProtocolDuringInspectionDtoFromJSONTyped(json, false);
}

export function CreateUpdateProtocolDuringInspectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUpdateProtocolDuringInspectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': json['number'],
        'date': !exists(json, 'date') ? undefined : (json['date'] === null ? null : new Date(json['date'])),
        'typeId': json['typeId'],
        'witnesses': json['witnesses'],
        'description': json['description'],
        'attachments': json['attachments'],
        'repairMethod': json['repairMethod'],
        'signatureDate': !exists(json, 'signatureDate') ? undefined : (json['signatureDate'] === null ? null : new Date(json['signatureDate'])),
        'refusalReason': json['refusalReason'],
        'placeOfSignature': json['placeOfSignature'],
        'approved': json['approved'],
        'inspectedProductId': json['inspectedProductId'],
        'quantity': json['quantity'],
    };
}

export function CreateUpdateProtocolDuringInspectionDtoToJSON(value?: CreateUpdateProtocolDuringInspectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'date': value.date === undefined ? undefined : (value.date === null ? null : value.date.toISOString()),
        'typeId': value.typeId,
        'witnesses': value.witnesses,
        'description': value.description,
        'attachments': value.attachments,
        'repairMethod': value.repairMethod,
        'signatureDate': value.signatureDate === undefined ? undefined : (value.signatureDate === null ? null : value.signatureDate.toISOString()),
        'refusalReason': value.refusalReason,
        'placeOfSignature': value.placeOfSignature,
        'approved': value.approved,
        'inspectedProductId': value.inspectedProductId,
        'quantity': value.quantity,
    };
}


