import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  CreateUpdateAttachmentDto,
  GetAttachmentDto,
  CreateUpdateJudgmentDto,
  GetJudgmentDto,
  GetPakDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import AssociatedDocuments from '../AssociatedDocuments/AssociatedDocuments';
import { Judgment } from '../Judgments/Judgment';
import { PakViewApi } from '../../../common/snrwbCore/contexts/PakContext';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { AddAttachment } from '../Attachments/AddAttachment';
import { Attachment } from '../Attachments/Attachment';

export const PakAssociates: React.FC<{
  value: GetPakDto;
  editAuthorized: boolean;
  bannerId?: string;
  penalties: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  codex: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  api: PakViewApi;
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  onBannerClosed: () => void;
}> = props => {
  const readonly = props.value.approved || !props.editAuthorized;

  return (
    <Row className="mt-3">
      <Col md={3}>
        <AssociatedDocuments
          name="Decyzje"
          readonly={readonly}
          createModalType={Judgment}
          presentModalType={Judgment}
          docs={props.penalties}
          initialBannerId={props.bannerId}
          onBannerClosed={props.onBannerClosed}
          data={JSON.stringify({
            firstLevelShortname: 'kara',
            showAppeal: props.editAuthorized,
            deleteRole: Role.PakEdit,
          })}
        />
      </Col>
      <Col md={3}>
        <AssociatedDocuments
          name="KPA"
          readonly={readonly}
          createModalType={Judgment}
          presentModalType={Judgment}
          docs={props.codex}
          initialBannerId={props.bannerId}
          onBannerClosed={props.onBannerClosed}
          data={JSON.stringify({
            firstLevelShortname: 'kodeks',
            showAppeal: props.editAuthorized,
            deleteRole: Role.PakEdit,
          })}
        />
      </Col>
      <Col className="col-xxl-20p col-xl-4">
        <AssociatedDocuments
          name="Załączniki"
          readonly={readonly}
          createModalType={AddAttachment}
          presentModalType={Attachment}
          docs={props.attachments}
          initialBannerId={props.bannerId}
          onBannerClosed={props.onBannerClosed}
        />
      </Col>
    </Row>
  );
};
