import React from 'react';

import { useNavigation } from '../../../common/navigation';
import { ReportCardMenu } from '../../components/Reports/ReportCardMenu';

export const ReportsInspectionPlanView: React.FC = () => {
  const nav = useNavigation();

  return (
    <>
      <ReportCardMenu
        title="Wykonanie planu - kontrole planowe u podmiotów"
        text="Wykonanie planu w zakresie liczby przeprowadzonych kontroli"
        footer="Plany kontroli"
        onClick={nav.reportInspectionPlanByOrganization}
      />
      <ReportCardMenu
        title="Wykonanie planu - liczba skontrolowanych wyrobów"
        text="Wykonanie planu w zakresie liczby skontrolowanych wyrobów"
        footer="Plany kontroli"
        onClick={nav.reportInspectionPlanByInspectedProduct}
      />
    </>
  );
};
