import React from 'react';
import { Table } from 'react-bootstrap';

import { GetDictionaryDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { ReportProductsGroupsType } from '../../../common/types/ReportTypes';
import { ReportAlert } from '../Reports/ReportAlert';
import { ExportToExcel } from '../../../common/components/Excel/ExportToExcel';
import { FilterReportData } from '../Reports/Filter';

type Props = {
  data: ReportProductsGroupsType | undefined;
  productsGroups: GetDictionaryDto[];
  reportName: string;
  filterApplied?: boolean;
  usedFilter?: FilterReportData;
};

export const ReportMisstatementByProductGroup: React.FC<Props> = ({
  data,
  productsGroups,
  filterApplied,
  usedFilter,
}) => {
  const hasData = data ? Object.keys(data.details).length > 0 : false;

  return (
    <>
      <ExportToExcel
        fileName="Nieprawidłowości w grupach wyrobów"
        filter={usedFilter}
      >
        {data && hasData && (
          <Table id="table-to-export" key={'table'} striped bordered hover>
            <thead className="text-center">
              <tr>
                <th />
                {Object.keys(data?.details)
                  .sort()
                  .map((key, idx) => (
                    <th key={idx} colSpan={4}>
                      {key}
                    </th>
                  ))}
                {data && <th colSpan={4}>Suma</th>}
              </tr>
              <tr>
                <th rowSpan={2}>GRUPA WYROBÓW BUDOWLANYCH</th>
                {Object.keys(data?.details)
                  .sort()
                  .map(idx => (
                    <React.Fragment key={idx}>
                      <th colSpan={2}>
                        Liczba skontrolowanych wyrobów z danej podgrupy - bez
                        rekontroli
                      </th>
                      <th colSpan={2}>
                        Liczba zleconych badań próbek wyrobów z danej podgrupy -
                        bez próbek kontrolnych
                      </th>
                    </React.Fragment>
                  ))}
                <th colSpan={2}>
                  Liczba skontrolowanych wyrobów z danej podgrupy - bez
                  rekontroli
                </th>
                <th colSpan={2}>
                  Liczba zleconych badań próbek wyrobów z danej podgrupy - bez
                  próbek kontrolnych
                </th>
              </tr>
              <tr>
                {Object.keys(data?.details)
                  .sort()
                  .map(idx => (
                    <React.Fragment key={idx}>
                      <th>ogółem</th>
                      <th>w tym wyroby niezgodne</th>
                      <th>ogółem</th>
                      <th>w tym wyniki negatywne</th>
                    </React.Fragment>
                  ))}
                <th>ogółem</th>
                <th>w tym wyroby niezgodne</th>
                <th>ogółem</th>
                <th>w tym wyniki negatywne</th>
              </tr>
            </thead>
            <tbody>
              {productsGroups.map((key, idx) => (
                <RowDetails key={idx} data={data} value={key.value} />
              ))}
            </tbody>
          </Table>
        )}
        <ReportAlert hasData={hasData} filterApplied={filterApplied} />
      </ExportToExcel>
    </>
  );
};

const RowDetails: React.FC<{
  data: ReportProductsGroupsType | undefined;
  value: string;
}> = ({ data, value }) => {
  if (!data || Object.keys(data?.details).length <= 0) {
    return <></>;
  }

  return (
    <tr>
      <td>{value}</td>
      {Object.keys(data?.details)
        .sort()
        .map((key, idx) => (
          <React.Fragment key={idx}>
            <td className="numbers-column">
              {data?.details?.[key]?.[value]?.countInspectedProducts || 0}
            </td>
            <td className="numbers-column">
              {data?.details?.[key]?.[value]?.countMisstatement || 0}
            </td>
            <td className="numbers-column">
              {data?.details?.[key]?.[value]?.countSampleCommision || 0}
            </td>
            <td className="numbers-column">
              {data?.details?.[key]?.[value]?.countSampleNegative || 0}
            </td>
          </React.Fragment>
        ))}
      <td className="numbers-column">
        {data?.sum?.[value]?.countInspectedProducts || 0}
      </td>
      <td className="numbers-column">
        {data?.sum?.[value]?.countMisstatement || 0}
      </td>
      <td className="numbers-column">
        {data?.sum?.[value]?.countSampleCommision || 0}
      </td>
      <td className="numbers-column">
        {data?.sum?.[value]?.countSampleNegative || 0}
      </td>
    </tr>
  );
};
