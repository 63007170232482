import React from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import {
  CreateUpdateJudgmentDto,
  GetInspectedProductDto,
  GetJudgmentDto,
  GetProceedingDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { ProceedingViewApi } from '../../../common/snrwbCore/contexts/ProceedingContext';
import { useNavigation } from '../../../common/navigation';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import DictionaryStatic from '../Dictionary/DictionaryStatic';

export const ProceedingKwz = (props: {
  value: GetProceedingDto;
  editAuthorized: boolean;
  inspectedProduct: GetInspectedProductDto;
  decisions: AssociateDocuments<GetJudgmentDto, CreateUpdateJudgmentDto>;
  api: ProceedingViewApi;
}) => {
  const nav = useNavigation();

  if (props.decisions.documents.length === 0) {
    return <></>;
  }

  const approvedDecisions = props.decisions.documents.filter(
    judgment => judgment.approved,
  );
  if (approvedDecisions.length === 0) {
    return <></>;
  }

  const shouldCreateKwz =
    props.decisions.documents.filter(judgment => judgment.shouldCreateKwz)
      .length > 0;
  const kwzIds = props.decisions.documents.filter(judgment => judgment.kwzId);
  const judgmentWithKwz = kwzIds.length > 0 ? kwzIds[0] : undefined;

  let kwzVariant = 'dark';
  let kwzHeading = 'Wyrób nie kwalifikuje się do utworzenia KWZ';

  if (shouldCreateKwz) {
    kwzVariant = 'danger';
    kwzHeading = 'Wyrób kwalifikuje się do utworzenia KWZ';
  }
  if (judgmentWithKwz) {
    kwzVariant = 'danger';
    kwzHeading = 'Utworzono kartę wyrobu zakwestionowanego';
  }

  return (
    <Alert variant={kwzVariant}>
      <div>
        <dl className="row">
          <dt className="col-sm-3">Oznakowanie typu wyrobu</dt>
          <dd className="col-sm-9">
            <DictionaryStatic value={props.inspectedProduct.productType.sign} />
          </dd>
        </dl>
        {approvedDecisions.map(judgment => (
          <dl className="row" key={judgment.id}>
            <dt className="col-sm-3">Zatwierdzona decyzja</dt>
            <dd className="col-sm-9 d-flex justify-content-between">
              <span>
                <DictionaryStatic value={judgment.kind} />
              </span>
              {!judgmentWithKwz && shouldCreateKwz && props.editAuthorized && (
                <ConfirmationButton
                  variant={`outline-${kwzVariant}`}
                  roleAnyOf={[Role.KwzEdit]}
                  onOK={() => props.api.createKwz(judgment.id)}
                  confirmation="Czy na pewno utworzyć kartę wyrobu zakwestionowanego?"
                >
                  Utwórz KWZ
                </ConfirmationButton>
              )}
            </dd>
          </dl>
        ))}
      </div>
      <hr />
      <Alert.Heading className="d-flex justify-content-between">
        <div>{kwzHeading}</div>
        {judgmentWithKwz && (
          <div>
            {props.editAuthorized && (
              <ConfirmationButton
                variant={`outline-${kwzVariant}`}
                roleAnyOf={[Role.KwzRevoke]}
                onOK={() => props.api.deleteKwz(judgmentWithKwz.id)}
                confirmation="Czy na pewno usunąć KWZ?"
                className="mx-1"
              >
                Cofnij
              </ConfirmationButton>
            )}
            <Button
              variant="outline-primary"
              onClick={() => nav.kwz(judgmentWithKwz.kwzId)}
            >
              Pokaż
            </Button>
          </div>
        )}
      </Alert.Heading>
    </Alert>
  );
};
