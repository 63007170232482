/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendPrivateMemoToManyDto
 */
export interface SendPrivateMemoToManyDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SendPrivateMemoToManyDto
     */
    recipientIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendPrivateMemoToManyDto
     */
    content: string;
}

export function SendPrivateMemoToManyDtoFromJSON(json: any): SendPrivateMemoToManyDto {
    return SendPrivateMemoToManyDtoFromJSONTyped(json, false);
}

export function SendPrivateMemoToManyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendPrivateMemoToManyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipientIds': json['recipientIds'],
        'content': json['content'],
    };
}

export function SendPrivateMemoToManyDtoToJSON(value?: SendPrivateMemoToManyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipientIds': value.recipientIds,
        'content': value.content,
    };
}


