import React from 'react';

import { useCookies } from '../../common/hooks/useCookies';

import NavMain from './NavMain';
import Main from './Main';

interface LayoutProps {
  location: string;
  changingUnitForbidden: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  location,
  changingUnitForbidden,
}) => {
  const cookies = useCookies();

  const changeActiveContrast = (className: string) => {
    if (
      cookies.activeContrast &&
      cookies.activeContrast !== '' &&
      cookies.activeContrast !== className
    ) {
      document.body.classList.remove(cookies.activeContrast);
    }
    cookies.setActiveContrast(className);
    if (className !== '') {
      document.body.classList.add(className);
    }
  };

  return (
    <div>
      <NavMain
        activePage={location}
        changingUnitForbidden={changingUnitForbidden}
        setContrast={className => changeActiveContrast(className)}
      />
      <Main location={location}>{children}</Main>
    </div>
  );
};
export default Layout;
