import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNotifications } from '../../../common/hooks/useNotifications';
import { useNavigation } from '../../../common/navigation';
import {
  GetEssentialFeatureDto,
  GetInspectedProductDto,
  GetPakDto,
  GetSampleExamDtoFromJSON,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as SampleExamContext from '../../../common/snrwbCore/contexts/SampleExamContext';
import * as AttachmentContext from '../../../common/snrwbCore/contexts/AttachmentContext';
import * as InspectorContext from '../../../common/snrwbCore/contexts/InspectorContext';
import * as JudgmentContext from '../../../common/snrwbCore/contexts/JudgmentContext';
import { SampleExam } from '../../components/SampleExams/SampleExam';
import { useCookies } from '../../../common/hooks/useCookies';
import { SampleWithResult } from '../../../common/snrwbCore/contexts/SampleExamContext';
import { useAuth } from '../../../common/hooks/useAuth';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

export const SampleExamView: React.FC = () => {
  const auth = useAuth();
  const snrwb = useSnrwbCore();
  const nav = useNavigation();
  const cookies = useCookies();
  const notifications = useNotifications();
  const { id, sampleId, bannerId, findSample } = useParams<{
    id: string;
    sampleId: string;
    bannerId: string;
    findSample: string;
  }>();
  const mountedRef = useRef(false);
  const [sampleExam, setSampleExam] = useState(GetSampleExamDtoFromJSON({}));
  const [approvedExams, setApprovedExams] = useState(false);
  const [samples, setSamples] = useState<SampleWithResult[]>([]);
  const [inspectedProducts, setInspectedProducts] = useState<
    GetInspectedProductDto[]
  >([]);
  const [inspectors, setInspectors] = useState(
    InspectorContext.forAssociatedDocuments(snrwb, notifications, []),
  );
  const [sampleAttachments, setSampleAttachments] = useState(
    AttachmentContext.forAssociatedDocuments(snrwb, notifications, []),
  );
  const [judgments, setJudgments] = useState(
    JudgmentContext.forAssociatedDocuments(snrwb, notifications, []),
  );
  const [features, setFeatures] = useState<GetEssentialFeatureDto[]>([]);
  const [paks, setPaks] = useState<GetPakDto[]>([]);

  const safe = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  const refresh = useCallback(
    (modelGenerator?: SampleExamContext.ModelGeneratorFunc) => {
      const generator = modelGenerator || SampleExamContext.forSampleExamView;
      generator(snrwb, notifications, id, refresh)
        .then(model => {
          safe(() => model.samples && setSamples(model.samples));
          safe(
            () =>
              model.approvedExams !== undefined &&
              setApprovedExams(model.approvedExams),
          );
          safe(
            () =>
              model.inspectedProducts &&
              setInspectedProducts(model.inspectedProducts),
          );
          safe(() => model.inspectors && setInspectors(model.inspectors));
          safe(
            () =>
              model.samplesAttachments &&
              setSampleAttachments(model.samplesAttachments),
          );
          safe(() => model.judgments && setJudgments(model.judgments));
          safe(() => model.features && setFeatures(model.features));
          safe(() => model.paks && setPaks(model.paks));
          safe(() => model.exam && setSampleExam(model.exam));
        })
        .catch(() => {
          notifications.badLink(
            'Brak badania próbek w bazie danych. Mogło zostać przed chwilą usunięte.',
            '/badania',
          );
        });
    },
    [snrwb, notifications, id],
  );

  useEffect(() => {
    mountedRef.current = true;
    refresh();
    return () => {
      mountedRef.current = false;
    };
  }, [id, snrwb.inspections, refresh]);

  const unitsMatch =
    sampleExam.sampleCollect?.examOrganizationalUnit?.id ===
      cookies.organizationalUnit?.id ||
    samples.find(
      s =>
        s.sample.operateOrganizationalUnit.id ===
        cookies.organizationalUnit?.id,
    ) !== undefined;
  const sample =
    samples.length > 0 &&
    (samples.find(s => !s.sample.controlSample) || samples[0]).sample;

  return (
    <SampleExam
      editAuthorized={auth.check(Role.ExamsEdit) && unitsMatch}
      publishAuthorized={auth.check(Role.ExamsPublishing)}
      validOrganizationalUnit={unitsMatch}
      sampleExam={sampleExam}
      approvedExams={approvedExams}
      samples={samples}
      openWithSampleId={findSample && sample ? sample.id : sampleId}
      openWithBannerId={bannerId}
      inspectedProducts={inspectedProducts}
      inspectors={inspectors}
      sampleAttachments={sampleAttachments}
      judgments={judgments}
      features={features}
      paks={paks}
      api={SampleExamContext.handlersForSampleExamView(
        snrwb,
        notifications,
        sampleExam,
        cookies.organizationalUnit?.id,
        refresh,
        nav.sampleCollect,
        nav.inspection,
        nav.pak,
      )}
    />
  );
};
