import React from 'react';

import { GetProceedingExtendedDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/';
import { Grid } from '../../../common/components/Grid/Grid';
import { useNavigation } from '../../../common/navigation';
import momencik from '../../../common/momencik';
import { PartiesGridColumn } from '../PartyToProceedings/PartiesGridColumn';
import nkitwDesc from '../../../common/nkitwDesc';

import { ProceedingLink } from './ProceedingLink';
import { ProceedingType } from './ProceedingType';

export const MyProceedings: React.FC<{
  data: GetProceedingExtendedDto[];
  showFilter?: boolean;
}> = props => {
  const nav = useNavigation();
  const columns = [
    { header: '', property: 'type', noSorting: true },
    { header: 'Data wszczęcia', property: 'startDate' },
    { header: 'Data zakończenia', property: 'endDate' },
    { header: 'Typ wyrobu', property: 'productType' },
    { header: 'Strony', property: 'parties' },
    { header: 'Numer akt', property: 'actNumber' },
    { header: '', property: 'link', className: 'text-center', noSorting: true },
  ];

  const data = props.data.map(pe => {
    return {
      key: pe.proceeding.id,
      values: {
        type: (
          <ProceedingType
            proceeding={pe.proceeding}
            productType={pe.inspectedProduct.productType}
          />
        ),
        startDate: momencik(pe.proceeding.startDate),
        endDate: momencik(pe.proceeding.endDate),
        productType: nkitwDesc(pe.inspectedProduct.productType),
        parties: <PartiesGridColumn parties={pe.parties} />,
        link: <ProceedingLink proceedingId={pe.proceeding.id} />,
        actNumber: pe.proceeding.actNumber,
      },
      orderByValues: {
        startDate: momencik(pe.proceeding.startDate, ''),
      },
    };
  });

  return (
    <Grid
      data={data}
      columns={columns}
      showFilter={props.showFilter}
      initialOrderColumn="startDate"
      initialOrderDirection="DESC"
      onRowClick={nav.proceeding}
      availablePageSizes={[10, 20, 50]}
      emptyGridMessage="Aktualnie brak wszczętych postępowań w tej jednostce organizacyjnej"
    />
  );
};
