import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import { useParams } from 'react-router-dom';

import Tabs from '../../../app/components/Tabs';
import { InspectionPlanDetails } from '../../components/InspectionPlans/InspectionPlanDetails';
import { responseErrors } from '../../../common/snrwbCore/validation/responseErrors';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useNavigation } from '../../../common/navigation';
import {
  GetInspectionPlanDto,
  GetInspectionPlanDtoStatusEnum,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models/GetInspectionPlanDto';
import {
  CreateUpdateInspectionPlanDto,
  CreateUpdateInspectionPlanDtoFromJSON,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as InspectionPlanContext from '../../../common/snrwbCore/contexts/InspectionPlanContext';
import { InspectionPlanGroupDetails } from '../../components/InspectionPlans/InspectionPlanGroupDetails';
import { useCookies } from '../../../common/hooks/useCookies';
import { InspectionPlanStatusAlert } from '../../components/InspectionPlans/InspectionPlanStatusAlert';
import { useNotifications } from '../../../common/hooks/useNotifications';

interface ParamTypes {
  id: string;
}

export const InspectionPlanDetailsView: React.FC = () => {
  const snrwb = useSnrwbCore();
  const nav = useNavigation();
  const cookies = useCookies();
  const notifications = useNotifications();

  const { id } = useParams<ParamTypes>();
  const [plan, setPlan] = useState<GetInspectionPlanDto>();
  const [refresh, setRefresh] = useState(false);
  const [readonly, setReadonly] = useState(true);
  const [planStatus, setPlanStatus] =
    useState<GetInspectionPlanDtoStatusEnum>();
  const [isGinb, setIsGinb] = useState(false);
  const [isRightWinb, setIsRightWinb] = useState(false);

  useEffect(() => {
    snrwb.inspectionPlans
      .getById(id)
      .then(setPlan)
      .catch(() => notifications.badLink());
    setRefresh(false);
  }, [snrwb.inspectionPlans, id, refresh, notifications]);

  useEffect(() => {
    if (plan) {
      setPlanStatus(plan.status);
    }
  }, [plan]);

  useEffect(() => {
    setIsGinb(
      plan?.organizationalUnit.parentId === cookies?.organizationalUnit?.id ||
        (plan !== undefined && plan?.organizationalUnit.parentId === null),
    );
    setIsRightWinb(
      plan?.organizationalUnit.id === cookies?.organizationalUnit?.id,
    );

    if (
      plan?.status === GetInspectionPlanDtoStatusEnum.Niezatwierdzony &&
      (plan?.organizationalUnit.id === cookies?.organizationalUnit?.id ||
        isGinb)
    ) {
      setReadonly(false);
    } else {
      setReadonly(true);
    }
  }, [cookies?.organizationalUnit, isGinb, isRightWinb, plan, planStatus]);

  const addEditInspectionPlan = async (
    planCU: CreateUpdateInspectionPlanDto,
  ) => {
    const status = await InspectionPlanContext.validate(planCU);
    if (status.valid) {
      try {
        if (!id) {
          await snrwb.inspectionPlans.create(planCU);
        } else {
          await snrwb.inspectionPlans.update(id, planCU);
        }
        return {
          saved: true,
          errors: [],
          action: () => nav.allInspectionPlans(),
        };
      } catch (response) {
        return {
          saved: false,
          errors: await responseErrors(response as Response),
        };
      }
    } else {
      return {
        saved: false,
        errors: status.errors,
      };
    }
  };

  const handleInspectionPlanStatus = async (
    status: GetInspectionPlanDtoStatusEnum,
  ) => {
    if (plan) {
      plan.status = status;
      await snrwb.inspectionPlans
        .update(id, InspectionPlanContext.inspectionPlanData(plan))
        .then(() => {
          setPlanStatus(status);
        });
    }
  };

  return (
    <Card>
      <Tabs defaultActiveKey="main" id="inspectionPlanDetails">
        <Tab eventKey="main" title="Szczegóły planu kontroli" className="m-3">
          {planStatus ===
            GetInspectionPlanDtoStatusEnum.ZatwierdzonyPrzezGINB && (
            <InspectionPlanStatusAlert
              title={'Plan kontroli zatwierdzony przez GINB'}
              statusAfterReject={
                GetInspectionPlanDtoStatusEnum.ZatwierdzonyPrzezWINB
              }
              showButtonReject={isGinb}
              showButtonAccept={false}
              handleInspectionPlanStatus={handleInspectionPlanStatus}
              variant={'success'}
            />
          )}
          {planStatus ===
            GetInspectionPlanDtoStatusEnum.ZatwierdzonyPrzezWINB && (
            <InspectionPlanStatusAlert
              title={'Plan kontroli zatwierdzony przez WINB'}
              statusAfterAccept={
                GetInspectionPlanDtoStatusEnum.ZatwierdzonyPrzezGINB
              }
              statusAfterReject={GetInspectionPlanDtoStatusEnum.Niezatwierdzony}
              showButtonReject={isRightWinb}
              showButtonAccept={isGinb}
              handleInspectionPlanStatus={handleInspectionPlanStatus}
              variant={'warning'}
            />
          )}
          {planStatus === GetInspectionPlanDtoStatusEnum.Niezatwierdzony && (
            <InspectionPlanStatusAlert
              title={'Plan kontroli nie jest zatwierdzony'}
              statusAfterAccept={
                GetInspectionPlanDtoStatusEnum.ZatwierdzonyPrzezWINB
              }
              showButtonReject={false}
              showButtonAccept={isRightWinb}
              handleInspectionPlanStatus={handleInspectionPlanStatus}
              variant={'danger'}
            />
          )}
          <InspectionPlanDetails
            planId={id}
            plan={CreateUpdateInspectionPlanDtoFromJSON(plan)}
            onCompleted={addEditInspectionPlan}
            organizationalUnit={plan?.organizationalUnit}
            readonly={readonly}
          />
        </Tab>
        <Tab
          eventKey="inspectionPlanGroup"
          title="Szczegóły grup planu kontroli"
          className="m-3"
        >
          <InspectionPlanGroupDetails planId={id} readonly={readonly} />
        </Tab>
      </Tabs>
    </Card>
  );
};
