import React from 'react';
import Button from 'react-bootstrap/Button';

import { useAuth } from '../../common/hooks/useAuth';
import { useNotifications } from '../../common/hooks/useNotifications';
import { Role } from '../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

export default (
  props: React.PropsWithChildren<{
    className?: string;
    variant?: string;
    roleAnyOf?: Role[];
    rolesAllOf?: Role[];
    onClick: () => void;
  }>,
) => {
  const auth = useAuth();
  const notifications = useNotifications();

  const validate = () => {
    if (
      (props.roleAnyOf && !props.roleAnyOf.find(role => auth.check(role))) ||
      (props.rolesAllOf && props.rolesAllOf.find(role => !auth.check(role)))
    ) {
      notifications.unauthorized();
      return false;
    }
    return true;
  };

  return (
    <Button
      className={props.className}
      variant={props.variant}
      onClick={() => {
        const valid = validate();
        if (!valid) {
          return;
        }
        props.onClick();
      }}
    >
      {props.children}
    </Button>
  );
};
