import React, { useState } from 'react';

import { useSnrwbCore } from '../../../../common/hooks/useSnrwbCore';
import { Filter, FilterReportData } from '../../../components/Reports/Filter';
import { ReportMisstatementByProductGroup } from '../../../components/ReportMisstatementByProductGroup/ReportMisstatementByProductGroup';
import { GetDictionaryDto } from '../../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { ReportProductsGroupsType } from '../../../../common/types/ReportTypes';

export const ReportMisstatementByProductGroupView: React.FC = () => {
  const snrwbApi = useSnrwbCore();
  const [filterApplied, setFilterApplied] = useState(false);
  const [usedFilter, setUsedFilter] = useState<FilterReportData | undefined>(
    {},
  );
  const [data, setData] = useState<ReportProductsGroupsType>();
  const [productsGroups, setProductsGroups] = useState<GetDictionaryDto[]>([]);

  const onSearch = async (filter?: FilterReportData) => {
    setFilterApplied(true);
    setUsedFilter(filter);
    snrwbApi.reports.reportProductsGroup(false, filter).then(dataReports => {
      setData(dataReports);
    });
    const productsGroups =
      snrwbApi.dictionaries.getByDictionaryType('typ wyrobu - grupa');

    setProductsGroups(
      await productsGroups.then(o =>
        o
          .filter(p => p.parentId === null)
          .sort((a, b) =>
            (a.shortname || a.value).localeCompare(
              b.shortname || b.value,
              'pl',
            ),
          ),
      ),
    );
  };

  return (
    <>
      <Filter
        onSearch={onSearch}
        reportName="Nieprawidłowości w grupach wyrobów"
      />
      <ReportMisstatementByProductGroup
        data={data}
        productsGroups={productsGroups}
        reportName="Nieprawidłowości w grupach wyrobów"
        filterApplied={filterApplied}
        usedFilter={usedFilter}
      />
    </>
  );
};
