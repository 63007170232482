/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InspectionPlanForOrganizationDetails,
    InspectionPlanForOrganizationDetailsFromJSON,
    InspectionPlanForOrganizationDetailsFromJSONTyped,
    InspectionPlanForOrganizationDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportInspectionPlanForOrganizationType
 */
export interface ReportInspectionPlanForOrganizationType {
    /**
     * 
     * @type {object}
     * @memberof ReportInspectionPlanForOrganizationType
     */
    details: object;
    /**
     * 
     * @type {InspectionPlanForOrganizationDetails}
     * @memberof ReportInspectionPlanForOrganizationType
     */
    sum: InspectionPlanForOrganizationDetails;
}

export function ReportInspectionPlanForOrganizationTypeFromJSON(json: any): ReportInspectionPlanForOrganizationType {
    return ReportInspectionPlanForOrganizationTypeFromJSONTyped(json, false);
}

export function ReportInspectionPlanForOrganizationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportInspectionPlanForOrganizationType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'details': json['details'],
        'sum': InspectionPlanForOrganizationDetailsFromJSON(json['sum']),
    };
}

export function ReportInspectionPlanForOrganizationTypeToJSON(value?: ReportInspectionPlanForOrganizationType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'details': value.details,
        'sum': InspectionPlanForOrganizationDetailsToJSON(value.sum),
    };
}


