import React, { useState, useEffect } from 'react';
import { Alert, Tab } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

import Tabs from '../../../app/components/Tabs';
import ItemPresentation from '../../../app/components/ItemPresentation';
import {
  CreateUpdateTechAssessmentDto,
  GetObjectHistoryDto,
  GetAttachmentDto,
  CreateUpdateAttachmentDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import {
  TechAssessmentDto,
  convert,
  newTechAssessment,
  forPresentation,
} from '../../../common/snrwbCore/contexts/TechAssessmentContext';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import { ObjectHistory } from '../ObjectHistory/ObjectHistory';
import { ObjectHistoryDetails } from '../ObjectHistory/ObjectHistoryDetails';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import { useCookies } from '../../../common/hooks/useCookies';

import { TechAssessment } from './TechAssessment';

interface TechAssessmentFormProps {
  showErrors: boolean;
  editAuthorized: boolean;
  techAssessment?: TechAssessmentDto;
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  producer?: string;
  errors: string[];
  onChange: (
    techAssessment: TechAssessmentDto | CreateUpdateTechAssessmentDto,
  ) => void;
  onUpdateAttachments?: () => void;
}

export const TechAssessmentFormCard: React.FC<TechAssessmentFormProps> =
  props => {
    const [techAssessment, setTechAssessment] = useState(
      props.techAssessment ? props.techAssessment : newTechAssessment(),
    );
    const [objectHistory, setObjectHistory] = useState<GetObjectHistoryDto>();
    const [objectHistoryEntries, setObjectHistoryEntries] = useState<
      GetObjectHistoryDto[]
    >([]);
    const [showHistoryDetails, setShowHistoryDetails] = useState(false);
    const snrwb = useSnrwbCore();
    const cookies = useCookies();

    const onChangeTechAssessment = (techAssessment: TechAssessmentDto) => {
      setTechAssessment(techAssessment);
      props.onChange(techAssessment);
    };

    const handleOnClickObjectHistory = (key: string) => {
      snrwb.objectHistory.getById(key).then(objectHistory => {
        setObjectHistory(objectHistory);
        setShowHistoryDetails(true);
      });
    };

    const handleObjectHistoryClose = () => {
      setShowHistoryDetails(false);
    };

    useEffect(() => {
      const techAssessment = props.techAssessment
        ? props.techAssessment
        : newTechAssessment();

      if (
        cookies.organizationalUnit?.nationalTechnicalAssessmentUnit &&
        'organizationalUnitId' in techAssessment &&
        !techAssessment.organizationalUnitId
      ) {
        techAssessment.organizationalUnitId = cookies.organizationalUnit?.id;
      }

      setTechAssessment(techAssessment);

      if (props.techAssessment && 'id' in props.techAssessment) {
        snrwb.objectHistory
          .getHistory(props.techAssessment.id, 'GetTechAssessmentDto')
          .then(elements => {
            setObjectHistoryEntries(elements);
          });
      }
    }, [
      cookies.organizationalUnit?.id,
      cookies.organizationalUnit?.nationalTechnicalAssessmentUnit,
      props.techAssessment,
      snrwb.objectHistory,
    ]);

    if (
      !props.editAuthorized &&
      props.techAssessment &&
      'id' in props.techAssessment
    ) {
      return (
        <>
          <ItemPresentation item={forPresentation(props.techAssessment)} />
          <Alert variant="warning" className="mt-3">
            Edycja jest zablokowana z powodu braku uprawnień.
          </Alert>
        </>
      );
    }

    return (
      <Card>
        <Tabs
          defaultActiveKey="techAssessment"
          id="techAssessment"
          className="mb-3"
        >
          <Tab
            eventKey="techAssessment"
            title="Ocena techniczna"
            className="m-3"
          >
            <TechAssessment
              onChange={onChangeTechAssessment}
              showValidationErrors={props.showErrors}
              validationErrors={props.errors}
              producer={props.producer}
              value={
                'id' in techAssessment
                  ? convert(techAssessment)
                  : techAssessment
              }
              attachments={props.attachments}
              assessmentId={'id' in techAssessment ? techAssessment.id : null}
              onUpdateAttachments={props.onUpdateAttachments}
            />
          </Tab>
          {objectHistoryEntries.length > 0 && (
            <Tab eventKey="historyFeedbacks" title="Historia" className="m-3">
              <ObjectHistory
                data={objectHistoryEntries}
                onClick={handleOnClickObjectHistory}
              />
              <ObjectHistoryDetails
                show={showHistoryDetails}
                handleClose={handleObjectHistoryClose}
                objectHistory={objectHistory}
                detailsType={'ObjectHistoryDetailsForTechAssessment'}
              />
            </Tab>
          )}
        </Tabs>
      </Card>
    );
  };
