import React, { useState, useEffect, useRef } from 'react';
import { Modal, Toast, ToastContainer, Spinner } from 'react-bootstrap';

import { useNotifications } from '../../common/hooks/useNotifications';
import { useSnrwbCore } from '../../common/hooks/useSnrwbCore';

export type StatusType = 'online' | 'offline';
type HangingPromise = () => void;

export default () => {
  const notifications = useNotifications();
  const snrwb = useSnrwbCore();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [autohide, setAutohide] = useState(false);
  const [connectionText, setConnectionText] = useState<string>();
  const hangingPromisesRef = useRef<HangingPromise[]>([]);
  const onlineRef = useRef(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
      setConnectionText(
        '⛈️ Kłopoty z połączeniem - dane mogą nie zostać zapisane!',
      );
      setAutohide(false);
      setShow(true);
    }

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  useEffect(() => {
    notifications.online = () => {
      setConnectionText('☀️ Połączenie przywrócone');
      setAutohide(true);
      setShow(true);
      onlineRef.current = true;

      if (hangingPromisesRef.current.length > 0) {
        setShowModal(false);

        for (const hangingResolve of hangingPromisesRef.current) {
          hangingResolve();
        }
        hangingPromisesRef.current = [];
      }
    };

    notifications.offline = () => {
      setConnectionText(
        '⛈️ Kłopoty z połączeniem - dane mogą nie zostać zapisane!',
      );
      setAutohide(false);
      setShow(true);
      onlineRef.current = false;
    };
  }, [notifications, snrwb]);

  useEffect(() => {
    snrwb.registerConnectionHandler(async () => {
      if (onlineRef.current && isOnline) {
        return;
      }
      setShowModal(true);
      return new Promise(resolve => {
        hangingPromisesRef.current.push(resolve);
      });
    });
  }, [snrwb, isOnline]);

  return (
    <>
      {show && (
        <ToastContainer className="p-3">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            autohide={autohide}
            delay={5000}
            bg="light"
          >
            <Toast.Body className="text-center">{connectionText}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <Modal
        centered={true}
        backdrop="static"
        show={showModal}
        className="modal-on-modal"
        backdropClassName="modal-on-modal-backdrop"
      >
        <Modal.Header>
          <Modal.Title>Czekamy na przywrócenie połączenia...</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Spinner animation="grow" variant="danger" />
          <Spinner animation="grow" variant="warning" />
          <Spinner animation="grow" variant="info" />
        </Modal.Body>
      </Modal>
    </>
  );
};
