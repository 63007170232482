/*eslint max-lines-per-function: ["error", 200]*/
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import * as ProductTypeContext from '../../../common/snrwbCore/contexts/ProductTypeContext';
import {
  Organization,
  stringify,
} from '../../../common/snrwbCore/contexts/OrganizationContext';
import { Selector, Option } from '../Selects/Selector';
import FormRow from '../../../app/components/FormRow';
import BooleanDropdown from '../../../app/components/BooleanDropdown';
import ValidationAlert from '../../../app/components/ValidationAlert';
import DictionarySelector from '../Selects/DictionarySelector';
import DynamicTextarea from '../../../app/components/DynamicTextarea';

const emptyIfNull = (val: string) => val || '';

export const ProductType: React.FC<{
  producer: Organization;
  value?: ProductTypeContext.ProductType;
  readOnly?: boolean;
  editMode?: boolean;
  showValidationErrors: boolean;
  selected: boolean;
  onChange: (productType: ProductTypeContext.ProductType) => void;
  onValidation?: (status: boolean) => void;
  onDecision?: (newProduct: boolean) => void;
}> = props => {
  const [readOnly, setReadOnly] = useState(props.readOnly || false);
  const [selectedProductType, setSelectedProductType] =
    useState<ProductTypeContext.ProductType>(
      ProductTypeContext.newProductType(),
    );
  const [presentedProductType, setPresentedProductType] =
    useState<ProductTypeContext.ProductType>(
      ProductTypeContext.newProductType(),
    );
  const [showErrors, setShowErrors] = useState(props.showValidationErrors);
  const [errors, setErrors] = useState<string[]>([]);
  const snrwb = useSnrwbCore();

  const selectProductType = (chosen: Option) => {
    if (chosen.new) {
      const type = { ...selectedProductType, nkitw: chosen.label };
      setSelectedProductType(type);
      props.onChange(type);
    } else {
      snrwb.productTypes.getById(chosen.value).then(productType => {
        setSelectedProductType(productType);
        if (!props.editMode) {
          setReadOnly(true);
        }
        props.onChange(productType);
      });
    }

    if (props.onDecision) {
      props.onDecision(chosen.new || false);
    }
  };

  const getOptionsByNkitw = (text: string) => {
    if (!('id' in props.producer)) {
      return snrwb.productTypes.getOptionsByNkitw(text);
    }
    const pid = props.producer.id;
    return snrwb.productTypes.getOptionsByProducerNkitw(pid, text);
  };

  const propertyChange = (obj: Partial<ProductTypeContext.ProductType>) => {
    setPresentedProductType({ ...presentedProductType, ...obj });
  };
  const selectedChange = (obj: Partial<ProductTypeContext.ProductType>) => {
    const type = { ...selectedProductType, ...obj };
    if (obj.groupId !== undefined && 'group' in type && type.group) {
      type.group.id = obj.groupId;
    }
    if (obj.signId !== undefined && 'sign' in type && type.sign) {
      type.sign.id = obj.signId;
    }
    setSelectedProductType(type);
    props.onChange(type);
  };
  const presentedToSelected = () => {
    setSelectedProductType(presentedProductType);
    props.onChange(presentedProductType);
  };

  useEffect(() => {
    let mounted = true;
    setPresentedProductType(selectedProductType);
    const prodType = { ...selectedProductType, organizationId: 'ok' };
    ProductTypeContext.validate(prodType).then(status => {
      if (mounted) {
        setErrors(status.errors);
        if (props.onValidation) {
          props.onValidation(status.valid || readOnly);
        }
        if (status.valid) {
          setShowErrors(false);
        }
      }
    });
    return () => {
      mounted = false;
    };
  }, [props, readOnly, selectedProductType]);
  useEffect(() => {
    setReadOnly(props.readOnly || false);
  }, [props.readOnly]);
  useEffect(() => {
    setShowErrors(props.showValidationErrors);
  }, [props.showValidationErrors]);
  useEffect(() => {
    if (props.value) {
      setSelectedProductType(props.value);
    }
  }, [props.value]);

  return (
    <>
      <Form className="d-grid gap-3">
        {props.editMode && 'id' in props.producer && (
          <FormRow
            controlId="producer"
            label="Producent"
            static={true}
            boldLabel
          >
            {stringify(props.producer)}
          </FormRow>
        )}
        <FormRow controlId="nkitw" label="NKITW/TW" boldLabel>
          {props.editMode || props.selected ? (
            <DynamicTextarea
              readOnly={readOnly}
              value={emptyIfNull(presentedProductType.nkitw)}
              onChange={e => propertyChange({ nkitw: e.target.value })}
              onBlur={presentedToSelected}
            />
          ) : (
            <Selector
              uniqueKey={
                'id' in props.producer ? props.producer.id : 'new-producer'
              }
              onChange={selectProductType}
              readOnly={readOnly}
              creatable={true}
              clearable={'id' in props.producer ? false : true}
              isValidNewOption={input => input !== '' && input !== undefined}
              value={emptyIfNull(
                presentedProductType.nkitw || presentedProductType.description,
              )}
              provider={getOptionsByNkitw}
            />
          )}
        </FormRow>
        <FormRow controlId="group" label="Grupa">
          <DictionarySelector
            dictionary="typ wyrobu - grupa"
            readOnly={readOnly}
            value={presentedProductType.groupId}
            onChange={option => selectedChange({ groupId: option.value })}
          />
        </FormRow>
        <FormRow controlId="sign" label="Oznakowanie">
          <DictionarySelector
            dictionary="typ wyrobu - oznakowanie"
            readOnly={readOnly}
            value={presentedProductType.signId}
            onChange={option => selectedChange({ signId: option.value })}
          />
        </FormRow>
        <FormRow controlId="description" label="Opis" boldLabel>
          <Form.Control
            type="text"
            readOnly={readOnly}
            value={emptyIfNull(presentedProductType.description)}
            onChange={e => propertyChange({ description: e.target.value })}
            onBlur={presentedToSelected}
          />
        </FormRow>
        <FormRow controlId="constructionProduct" label="Wyr. budowlany">
          <BooleanDropdown
            readOnly={readOnly}
            value={presentedProductType.constructionProduct}
            onChange={value => selectedChange({ constructionProduct: value })}
          />
        </FormRow>
        <FormRow controlId="displayDesc" label="Wyświetlać opis z NKITW/TW">
          <BooleanDropdown
            readOnly={readOnly}
            value={presentedProductType.displayDesc}
            onChange={value => selectedChange({ displayDesc: value })}
          />
        </FormRow>
      </Form>
      <ValidationAlert show={showErrors} errors={errors} className="mt-3" />
    </>
  );
};
