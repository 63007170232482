/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetEmployeeDto,
    GetEmployeeDtoFromJSON,
    GetEmployeeDtoFromJSONTyped,
    GetEmployeeDtoToJSON,
    GetInspectionDto,
    GetInspectionDtoFromJSON,
    GetInspectionDtoFromJSONTyped,
    GetInspectionDtoToJSON,
    GetSampleExamDto,
    GetSampleExamDtoFromJSON,
    GetSampleExamDtoFromJSONTyped,
    GetSampleExamDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetInspectorDto
 */
export interface GetInspectorDto {
    /**
     * 
     * @type {string}
     * @memberof GetInspectorDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetInspectorDto
     */
    authorization: string;
    /**
     * 
     * @type {Date}
     * @memberof GetInspectorDto
     */
    authorizationDate?: Date | null;
    /**
     * 
     * @type {GetInspectionDto}
     * @memberof GetInspectorDto
     */
    inspection: GetInspectionDto;
    /**
     * 
     * @type {GetEmployeeDto}
     * @memberof GetInspectorDto
     */
    employee: GetEmployeeDto;
    /**
     * 
     * @type {GetSampleExamDto}
     * @memberof GetInspectorDto
     */
    sampleExam: GetSampleExamDto;
    /**
     * 
     * @type {number}
     * @memberof GetInspectorDto
     */
    orderNo: number;
}

export function GetInspectorDtoFromJSON(json: any): GetInspectorDto {
    return GetInspectorDtoFromJSONTyped(json, false);
}

export function GetInspectorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetInspectorDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'authorization': json['authorization'],
        'authorizationDate': !exists(json, 'authorizationDate') ? undefined : (json['authorizationDate'] === null ? null : new Date(json['authorizationDate'])),
        'inspection': GetInspectionDtoFromJSON(json['inspection']),
        'employee': GetEmployeeDtoFromJSON(json['employee']),
        'sampleExam': GetSampleExamDtoFromJSON(json['sampleExam']),
        'orderNo': json['orderNo'],
    };
}

export function GetInspectorDtoToJSON(value?: GetInspectorDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'authorization': value.authorization,
        'authorizationDate': value.authorizationDate === undefined ? undefined : (value.authorizationDate === null ? null : value.authorizationDate.toISOString()),
        'inspection': GetInspectionDtoToJSON(value.inspection),
        'employee': GetEmployeeDtoToJSON(value.employee),
        'sampleExam': GetSampleExamDtoToJSON(value.sampleExam),
        'orderNo': value.orderNo,
    };
}


