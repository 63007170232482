/* tslint:disable */
/* eslint-disable */
/**
 * e-SNRWB
 * Opis api e-SNRWB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FormalIrregulationsType
 */
export interface FormalIrregulationsType {
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    inspectionId: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    entityName: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    productDescription: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    unitSymbol: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    fileNumber: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    signatureDate: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    formalIrregulations: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    procedureId: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    remedialProvisionBase: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    remedialProvisionUnit: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    remedialProvisionDate: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    remedialProvisionSign: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormalIrregulationsType
     */
    remedialProvisionComplaint: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    remedialProvisionComplaintResult: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    decisionBase: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    decisionUnit: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    decisionDate: string;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    decisionSign: string;
    /**
     * 
     * @type {boolean}
     * @memberof FormalIrregulationsType
     */
    decisionAppeal: boolean;
    /**
     * 
     * @type {string}
     * @memberof FormalIrregulationsType
     */
    decisionAppealResult: string;
}

export function FormalIrregulationsTypeFromJSON(json: any): FormalIrregulationsType {
    return FormalIrregulationsTypeFromJSONTyped(json, false);
}

export function FormalIrregulationsTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormalIrregulationsType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inspectionId': json['inspectionId'],
        'entityName': json['entityName'],
        'productDescription': json['productDescription'],
        'unitSymbol': json['unitSymbol'],
        'fileNumber': json['fileNumber'],
        'startDate': json['startDate'],
        'signatureDate': json['signatureDate'],
        'formalIrregulations': json['formalIrregulations'],
        'procedureId': json['procedureId'],
        'remedialProvisionBase': json['remedialProvisionBase'],
        'remedialProvisionUnit': json['remedialProvisionUnit'],
        'remedialProvisionDate': json['remedialProvisionDate'],
        'remedialProvisionSign': json['remedialProvisionSign'],
        'remedialProvisionComplaint': json['remedialProvisionComplaint'],
        'remedialProvisionComplaintResult': json['remedialProvisionComplaintResult'],
        'decisionBase': json['decisionBase'],
        'decisionUnit': json['decisionUnit'],
        'decisionDate': json['decisionDate'],
        'decisionSign': json['decisionSign'],
        'decisionAppeal': json['decisionAppeal'],
        'decisionAppealResult': json['decisionAppealResult'],
    };
}

export function FormalIrregulationsTypeToJSON(value?: FormalIrregulationsType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inspectionId': value.inspectionId,
        'entityName': value.entityName,
        'productDescription': value.productDescription,
        'unitSymbol': value.unitSymbol,
        'fileNumber': value.fileNumber,
        'startDate': value.startDate,
        'signatureDate': value.signatureDate,
        'formalIrregulations': value.formalIrregulations,
        'procedureId': value.procedureId,
        'remedialProvisionBase': value.remedialProvisionBase,
        'remedialProvisionUnit': value.remedialProvisionUnit,
        'remedialProvisionDate': value.remedialProvisionDate,
        'remedialProvisionSign': value.remedialProvisionSign,
        'remedialProvisionComplaint': value.remedialProvisionComplaint,
        'remedialProvisionComplaintResult': value.remedialProvisionComplaintResult,
        'decisionBase': value.decisionBase,
        'decisionUnit': value.decisionUnit,
        'decisionDate': value.decisionDate,
        'decisionSign': value.decisionSign,
        'decisionAppeal': value.decisionAppeal,
        'decisionAppealResult': value.decisionAppealResult,
    };
}


