import {
  GetDocumentationDto,
  GetVersionInfoDto,
} from '../autogenerated/snrwbApiClient/';
import { DefaultApi } from '../autogenerated/snrwbApiClient/apis/DefaultApi';

export interface DocumentationContextInterface {
  getForFilename: (filename: string) => Promise<GetDocumentationDto>;
  getVersionInfo: () => Promise<GetVersionInfoDto>;
}

export const DocumentationContext = (api: DefaultApi) => {
  return {
    getForFilename: (filename: string) =>
      api.documentationControllerGetForFilename(filename),
    getVersionInfo: () => api.documentationControllerGetVersionInfo(),
  };
};
