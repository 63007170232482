import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import _ from 'lodash';

import {
  CreateUpdateProductTypeDto,
  GetProductTypeDto,
  ValidationStatus,
  ValidationStatusFromJSON,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as ProductTypeContext from '../../../common/snrwbCore/contexts/ProductTypeContext';
import ItemPresentation from '../../../app/components/ItemPresentation';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import AlertModal from '../../../app/components/AlertModal';
import YesNoModal from '../../../app/components/YesNoModal';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';
import { ConcurrencySignaler } from '../../../app/components/ConcurrencySignaler';
import { DataChangeScope } from '../../../common/snrwbCore/sockets/socket.datatypes';
import nkitwDesc from '../../../common/nkitwDesc';

import { ProductType } from './ProductType';

const ProductTypeApproved: React.FC<{
  readonly: boolean;
  productType: GetProductTypeDto;
  onRevertApprove: () => void;
}> = props => {
  const productTypePresentation = ProductTypeContext.ProductTypeToPresent(
    props.productType,
  );

  return (
    <>
      <Alert variant="success">
        <Alert.Heading>Zatwierdzony typ wyrobu</Alert.Heading>
        <hr />
        {!props.readonly && (
          <div className="d-flex justify-content-end">
            <ConfirmationButton
              variant="outline-success"
              roleAnyOf={[Role.ProductTypesRevoke]}
              onOK={props.onRevertApprove}
              confirmation="Czy na pewno cofnąć zatwierdzenie typu wyrobu?"
            >
              Cofnij
            </ConfirmationButton>
          </div>
        )}
      </Alert>
      <ItemPresentation item={productTypePresentation} />
    </>
  );
};

const ProductTypeToBeApproved: React.FC<{
  readonly: boolean;
  productType: GetProductTypeDto;
  onChange: (productType: CreateUpdateProductTypeDto) => void;
  onApprove: (status: ValidationStatus) => void;
  mayBeApproved: () => Promise<ValidationStatus>;
}> = props => {
  const [isValid, setIsValid] = useState(false);
  const [value, setValue] = useState<CreateUpdateProductTypeDto>();
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState<string>();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [status, setStatus] = useState(ValidationStatusFromJSON({}));

  const changeProductType = (productType: ProductTypeContext.ProductType) => {
    const type = ProductTypeContext.convert(productType);

    if (!value || _.isEqual(value, type)) {
      return;
    }

    setValue(type);
    if (isValid) {
      props.onChange(type);
    }
  };

  const checkProductType = async () => {
    const status = await props.mayBeApproved();
    setStatus(status);
    setShowAlert(status.state === 'error');
    setShowConfirmation(status.state === 'confirm');
    setError(status.message);
    return status.state === 'ok' && isValid;
  };

  const confirmed = () => {
    setShowConfirmation(false);
    props.onApprove(status);
  };

  useEffect(() => {
    setValue(ProductTypeContext.convert(props.productType));
  }, [props.productType]);

  return (
    <>
      <Alert variant="danger" className="mt-3">
        <Alert.Heading>Niezatwierdzony typ wyrobu</Alert.Heading>
        <hr />
        {!props.readonly && (
          <div className="d-flex justify-content-end">
            <ConfirmationButton
              variant="outline-primary"
              onOK={() => props.onApprove(status)}
              onValidation={checkProductType}
              confirmation="Czy na pewno zatwierdzić typ wyrobu?"
            >
              Zatwierdź
            </ConfirmationButton>
          </div>
        )}
      </Alert>
      <AlertModal
        show={showAlert}
        variant="danger"
        onHide={() => setShowAlert(false)}
      >
        {error}
      </AlertModal>
      <YesNoModal
        header={error || 'Problem'}
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onOk={confirmed}
      >
        Użycie innego typu wyrobu (o tej samej nazwie) spowoduje usunięcie
        niezatwierdzonego, powielonego typu. Wszystkie kontrole i badania będą
        wskazywały na zatwierdzony typ wyrobu.
      </YesNoModal>
      <ProductType
        showValidationErrors={isValid}
        onChange={changeProductType}
        onValidation={setIsValid}
        readOnly={props.readonly}
        producer={props.productType.organization}
        value={props.productType}
        editMode={true}
        selected={true}
      />
    </>
  );
};

export const ProductTypeEdit: React.FC<{
  readonly: boolean;
  productType: GetProductTypeDto;
  onChange: (productType: CreateUpdateProductTypeDto) => void;
  onApprove: (status: ValidationStatus) => void;
  onRevertApprove: () => void;
  mayBeApproved: () => Promise<ValidationStatus>;
  refresh: () => void;
}> = props => {
  const [key, setKey] = useState({
    scope: DataChangeScope.productType,
    id: props.productType.id,
  });
  const [description, setDescription] = useState(
    'Typ wyrobu ' + nkitwDesc(props.productType),
  );

  useEffect(() => {
    setKey({
      scope: DataChangeScope.productType,
      id: props.productType.id,
    });
    setDescription('Typ wyrobu ' + nkitwDesc(props.productType));
  }, [props.productType.id, props.productType]);

  if (!props.productType) {
    return <></>;
  }

  return (
    <>
      {props.productType.approved ? (
        <ProductTypeApproved {...props} />
      ) : (
        <ProductTypeToBeApproved {...props} />
      )}
      <ConcurrencySignaler
        dataKey={key}
        objectDescription={description}
        editAllowed={!props.readonly}
        refreshAction={props.refresh}
      />
    </>
  );
};
