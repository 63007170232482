import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Alert from 'react-bootstrap/Alert';

import Tabs from '../../../app/components/Tabs';
import FormSkeleton from '../../../app/components/FormSkeleton';
import {
  CreateUpdateAttachmentDto,
  CreateUpdateSampleCollectDto,
  CreateUpdateSampleCollectDtoFromJSON,
  GetAttachmentDto,
  GetSampleExamDto,
  GetSampleCollectDto,
  GetInspectorDto,
  CreateUpdateInspectorDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import { AssociateDocuments } from '../AssociatedDocuments/AssociateDocumentsTypes';
import {
  SampleCollectApi,
  toCuDto,
} from '../../../common/snrwbCore/contexts/SampleCollectContext';
import { DataChangeScope } from '../../../common/snrwbCore/sockets/socket.datatypes';
import { ConcurrencySignaler } from '../../../app/components/ConcurrencySignaler';
import * as SampleCollectContext from '../../../common/snrwbCore/contexts/SampleCollectContext';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';

import { SampleCollectAttachments } from './SampleCollectAttachments';
import { SampleCollectHeader } from './SampleCollectHeader';
import { SampleCollectDetails } from './SampleCollectDetails';
import { SampleCollectSigning } from './SampleCollectSigning';
import { SampleCollectExams } from './SampleCollectExams';
import { SampleCollectButtons } from './SampleCollectButtons';

interface SampleCollectProps {
  editAuthorized: boolean;
  validOrganizationalUnit: boolean;
  defaultActiveKey?: string;
  sampleCollect: GetSampleCollectDto;
  sampleExams: GetSampleExamDto[];
  attachments: AssociateDocuments<GetAttachmentDto, CreateUpdateAttachmentDto>;
  inspectors: Map<
    string,
    AssociateDocuments<GetInspectorDto, CreateUpdateInspectorDto>
  >;
  api: SampleCollectApi;
}

export const SampleCollect = (props: SampleCollectProps) => {
  const [key, setKey] = useState({
    scope: DataChangeScope.sampleCollect,
    id: props.sampleCollect.id,
  });
  const [description, setDescription] = useState(
    'Pobranie próbek z numerem akt ' + props.sampleCollect.fileNumber,
  );
  const [showSign, setShowSign] = useState(true);
  const snrwb = useSnrwbCore();

  useEffect(() => {
    snrwb.sampleCollects.setDuringInspectionDto().then(() => {
      setShowSign(
        !props.sampleCollect?.collectBasis?.id ||
          !SampleCollectContext.duringInspectionBasisId() ||
          props.sampleCollect?.collectBasis?.id !==
            SampleCollectContext.duringInspectionBasisId(),
      );
    });
  }, [props.sampleCollect?.collectBasis, snrwb.sampleCollects]);

  const changeRichText = (obj: Partial<CreateUpdateSampleCollectDto>) => {
    const dto = CreateUpdateSampleCollectDtoFromJSON({
      ...toCuDto(props.sampleCollect),
      ...obj,
    });

    if (dto.attachments) {
      dto.attachments = dto.attachments.replaceAll('>\n', '>');
    }

    props.api.edit(dto);
  };

  const editAuthorized = props.editAuthorized && !props.sampleCollect.approved;
  const approvedExams = props.sampleExams.some(exam => exam.approved);

  const NoAuthorizationForEdit = () => (
    <>
      {props.sampleCollect.id && !props.editAuthorized && (
        <Alert variant="warning">
          Brak uprawnień do edycji
          {!props.validOrganizationalUnit && ' (niewłaściwy organ)'}
        </Alert>
      )}
    </>
  );

  useEffect(() => {
    setKey({
      scope: DataChangeScope.sampleCollect,
      id: props.sampleCollect.id,
    });
    setDescription(
      'Pobranie próbek z numerem akt ' + props.sampleCollect.fileNumber,
    );
  }, [props.sampleCollect.id, props.sampleCollect.fileNumber]);

  return (
    <Card>
      <Card.Body>
        <SampleCollectHeader {...props} />
        <Card>
          {!props.sampleCollect.id ? (
            <FormSkeleton />
          ) : (
            <Tabs
              defaultActiveKey={props.defaultActiveKey || 'details'}
              id="sampleExam"
            >
              <Tab eventKey="details" title="Pobranie" className="m-3">
                <NoAuthorizationForEdit />
                <SampleCollectDetails
                  value={props.sampleCollect}
                  editAuthorized={editAuthorized}
                  approvedExams={approvedExams}
                  onChange={props.api.edit}
                  onNewOrganization={props.api.addOrganization}
                />
              </Tab>
              <Tab eventKey="products" title="Wyroby" className="m-3">
                <SampleCollectExams {...props} />
              </Tab>
              {showSign && (
                <Tab eventKey="attachments" title="Załączniki" className="m-3">
                  <NoAuthorizationForEdit />
                  <SampleCollectAttachments
                    examApproved={props.sampleCollect.approved}
                    editAuthorized={editAuthorized}
                    summary={props.sampleCollect.attachments}
                    attachments={props.attachments}
                    onSummaryChange={value =>
                      changeRichText({ attachments: value })
                    }
                  />
                </Tab>
              )}
              {showSign && (
                <Tab eventKey="signing" title="Podpisanie" className="m-3">
                  <NoAuthorizationForEdit />
                  <SampleCollectSigning
                    value={props.sampleCollect}
                    editAuthorized={props.editAuthorized}
                    onChange={props.api.edit}
                    onSign={props.api.sign}
                    onRevertSign={props.api.revertSign}
                    readyToSign={props.api.readyToSign}
                  />
                </Tab>
              )}
            </Tabs>
          )}
          <ConcurrencySignaler
            dataKey={key}
            objectDescription={description}
            editAllowed={props.editAuthorized}
            refreshAction={props.api.refresh}
          />
        </Card>
      </Card.Body>
      {props.editAuthorized && <SampleCollectButtons {...props} />}
    </Card>
  );
};
