import React, { useState, useEffect, useRef, useCallback } from 'react';
import { EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import 'draft-js/dist/Draft.css';

import Toolbar, { toolbarConfig } from './RichTextEdit/InlineToolbar';
import createSingleLinePlugin from './RichTextEdit/SingleLinePluginTyped';
import { callOnChange, normalizedHtml } from './RichTextEdit/ImportExport';

export default (props: {
  value: string;
  readonly?: boolean;
  onChange: (value: string) => void;
  onlyIndexes?: boolean;
  changeValue?: boolean;
}) => {
  const [value, setValue] = useState(EditorState.createEmpty());
  const [loaded, setLoaded] = useState(false);
  const [toolbarPlugin] = useState(createToolbarPlugin(toolbarConfig));
  const [singleLinePlugin] = useState(createSingleLinePlugin());
  const editorRef = useRef<Editor | null>(null);

  const onBlur = useCallback(() => {
    if (props.readonly) {
      return;
    }
    callOnChange(value, props.value, props.onChange);
  }, [props, value]);

  useEffect(() => {
    if (!props.changeValue && (!props.value || loaded)) {
      return;
    }

    const html = normalizedHtml(props.value);
    setValue(EditorState.createWithContent(html.content));
    setLoaded(true);
  }, [props.value, loaded, props.changeValue]);

  return (
    <div className="rte-inline-editor" onBlur={onBlur}>
      <div className="form-control">
        <Editor
          readOnly={props.readonly}
          spellCheck
          editorState={value}
          onChange={setValue}
          blockRenderMap={singleLinePlugin.blockRenderMap}
          plugins={[toolbarPlugin, singleLinePlugin]}
          customStyleMap={{
            SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
            SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' },
          }}
          ref={editorRef}
        />
      </div>
      <Toolbar
        value={value}
        setValue={setValue}
        toolbarPlugin={toolbarPlugin}
        focus={() => editorRef.current?.focus()}
        onlyIndexes={props.onlyIndexes}
      />
    </div>
  );
};
