import React from 'react';
import Form from 'react-bootstrap/Form';

import FormRow from '../../../app/components/FormRow';
import ValidationAlert from '../../../app/components/ValidationAlert';
import * as OrganizationContext from '../../../common/snrwbCore/contexts/OrganizationContext';
import { CreatableClearableSelector } from '../Selects/CreatableClearableSelector';
import { Option } from '../Selects/Selector';

import { ForeignOrganization } from './ForeignOrganization';
import { PolishOrganization } from './PolishOrganization';

interface OrganizationFormProps {
  readOnly?: boolean;
  editMode?: boolean;
  presentedOrganization: OrganizationContext.Organization;
  showValidationErrors: boolean;
  validationErrors: string[];
  propertyChange: (obj: Partial<OrganizationContext.Organization>) => void;
  presentedToSelected: () => void;
  selectedChange: (obj: Partial<OrganizationContext.Organization>) => void;
  selectOrganization: (
    target: keyof OrganizationContext.Organization,
  ) => (option: Option) => void;
  setPost: (postcode: string) => void;
  byNameProvider: (text: string) => Promise<Option[]>;
  byNipProvider: (text: string) => Promise<Option[]>;
  countriesProvider: (text: string) => Promise<Option[]>;
  postcodesProvider: (text: string) => Promise<Option[]>;
  placesProvider: (text: string) => Promise<Option[]>;
  findByNip: (text: string) => void;
}

export const OrganizationForm = (props: OrganizationFormProps) => {
  const activityType =
    'activityType' in props.presentedOrganization
      ? props.presentedOrganization.activityType
      : undefined;
  const activityTypeShortname =
    activityType?.shortname ||
    ('activityTypeShort' in props.presentedOrganization &&
      props.presentedOrganization.activityTypeShort) ||
    undefined;
  const showNames = activityTypeShortname === 'IDG';
  const [nameLabel, nipLabel] = ((type?: string) => {
    switch (type) {
      case 'IDG':
        return ['Nazwa firmy', 'NIP'];
      case 'SC':
        return ['Nazwa spółki cywilnej', 'NIP spółki cywilnej'];
      default:
        return ['Nazwa', 'NIP'];
    }
  })(activityTypeShortname);
  const activityTypeId =
    activityType?.id ||
    ('activityTypeId' in props.presentedOrganization &&
      props.presentedOrganization.activityTypeId) ||
    undefined;

  return (
    <>
      <Form className="d-grid gap-3 mb-3">
        <FormRow controlId="country" label="Państwo">
          <CreatableClearableSelector
            value={props.presentedOrganization.country}
            readOnly={props.readOnly}
            onChange={option => props.selectedChange({ country: option.label })}
            provider={props.countriesProvider}
          />
        </FormRow>
      </Form>
      {props.presentedOrganization.country === 'Polska' ? (
        <PolishOrganization
          {...props}
          activityType={activityTypeId}
          showNames={showNames}
          nameLabel={nameLabel}
          nipLabel={nipLabel}
          findByNip={props.findByNip}
        />
      ) : (
        <ForeignOrganization {...props} />
      )}
      <ValidationAlert
        show={props.showValidationErrors}
        errors={props.validationErrors}
        className="mt-3"
      />
    </>
  );
};
