import React from 'react';
import Alert from 'react-bootstrap/Alert';

import * as JudgmentContext from '../../../common/snrwbCore/contexts/JudgmentContext';
import { GetJudgmentDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import ItemPresentation from '../../../app/components/ItemPresentation';
import ConfirmationButton from '../../../app/components/ConfirmationButton';
import { Role } from '../../../common/snrwbCore/authorization/snrwbAuthorizationRoles';

export const JudgmentNoEdit = (props: {
  readonly: boolean;
  value: GetJudgmentDto;
  inAppeal?: boolean;
  deleteRole?: Role;
  onRevertApprove?: () => void;
}) => {
  const presentation = JudgmentContext.toPresent(props.value, props.inAppeal);

  return (
    <>
      <Alert variant={props.value.approved ? 'success' : 'danger'}>
        <Alert.Heading>
          Orzeczenie {props.value.approved ? 'zatwierdzone' : 'niezatwierdzone'}
        </Alert.Heading>
        <hr />
        {!props.readonly && props.onRevertApprove && (
          <div className="d-flex justify-content-end">
            <ConfirmationButton
              variant="outline-success"
              roleAnyOf={
                props.deleteRole
                  ? [props.deleteRole]
                  : [
                      Role.InspectionsEdit,
                      Role.ExamsEdit,
                      Role.ProceedingsEdit,
                      Role.PakEdit,
                    ]
              }
              onOK={props.onRevertApprove}
              confirmation="Czy na pewno cofnąć zatwierdzenie orzeczenia?"
            >
              Cofnij
            </ConfirmationButton>
          </div>
        )}
      </Alert>
      <ItemPresentation item={presentation} />
    </>
  );
};
