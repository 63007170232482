import React, { useEffect, useRef } from 'react';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default (
  props: React.PropsWithChildren<{
    show: boolean;
    modal?: boolean;
    heading?: string;
    variant?: string;
    onHide?: () => void;
    errors: string[];
    className?: string;
    buttonText?: string;
  }>,
) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && props.show) {
        event.preventDefault();
        (buttonRef.current as HTMLButtonElement)?.click();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [props.show]);

  let i = 0;
  const message = props.errors.map(error => (
    <p key={'error' + ++i} className="m-0">
      {error === 'Forbidden resource'
        ? 'Brak wymaganych uprawnień'
        : error || 'Nieznany błąd!'}
    </p>
  ));

  return (
    <>
      {props.show &&
        props.errors.length > 0 &&
        (props.modal ? (
          <Modal
            show={props.show}
            centered={true}
            backdrop="static"
            backdropClassName="modal-on-modal-backdrop"
            className="modal-on-modal"
            size="lg"
          >
            <Modal.Body>
              <Alert variant={props.variant || 'danger'} className="m-0">
                <Alert.Heading>
                  {props.heading || 'Nie można wykonać tej operacji'}
                </Alert.Heading>
                {message}
                <hr />
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={props.onHide}
                    variant={'outline-' + (props.variant || 'danger')}
                    ref={buttonRef}
                  >
                    {props.buttonText || 'Zamknij'}
                  </Button>
                </div>
              </Alert>
            </Modal.Body>
          </Modal>
        ) : (
          <Alert
            variant={props.variant || 'danger'}
            className={props.className}
          >
            {message}
          </Alert>
        ))}
    </>
  );
};
