import React, { useState, useEffect, useCallback } from 'react';

import {
  CreateUpdateInspectionMetricDto,
  GetInspectionMetricDto,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import * as InspectionMetricContext from '../../../common/snrwbCore/contexts/InspectionMetricContext';
import ValidationAlert from '../../../app/components/ValidationAlert';

import { InspectionMetricForm } from './InspectionMetricForm';

export const InspectionMetric: React.FC<{
  showValidationErrors?: boolean;
  savingMode: 'outer' | 'background';
  inspectionPlace?: string;
  inspectionType?: string;
  value?: GetInspectionMetricDto;
  getAddress: () => string;
  onChange: (metric: CreateUpdateInspectionMetricDto, valid: boolean) => void;
}> = props => {
  const [metric, setMetric] = useState(
    InspectionMetricContext.newInspectionMetric(),
  );
  const [presentedMetric, setPresentedMetric] = useState(
    InspectionMetricContext.newInspectionMetric(),
  );
  const [showValidationErrors, setShowValidationErrors] = useState(
    props.showValidationErrors || false,
  );
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [organizationName, setOrganizationName] = useState<string>();
  const onChange = props.onChange;

  const propertyChange = (obj: Partial<CreateUpdateInspectionMetricDto>) => {
    setPresentedMetric({ ...presentedMetric, ...obj });
  };

  const presentedToSelected = () => setMetric(presentedMetric);

  const refresh = useCallback(() => {
    if (!props.value) {
      return;
    }
    setMetric(InspectionMetricContext.convert(props.value));
    setOrganizationName(props.value.organization.name);
  }, [props.value]);

  useEffect(() => {
    let mounted = true;
    setPresentedMetric(metric);
    InspectionMetricContext.validate(metric).then(status => {
      if (mounted) {
        setValidationErrors(status.errors);
        if (status.valid || props.savingMode === 'outer') {
          onChange(metric, status.valid);
        }
        if (props.savingMode === 'background') {
          setShowValidationErrors(!status.valid);
        }
      }
    });

    return () => {
      mounted = false;
    };
  }, [metric, onChange, props.savingMode]);

  useEffect(() => {
    setShowValidationErrors(props.showValidationErrors || false);
  }, [props.showValidationErrors]);

  useEffect(() => {
    if (!props.value) {
      const newMetric = InspectionMetricContext.newInspectionMetric();
      if (props.inspectionPlace) {
        newMetric.inspectionPlace = props.inspectionPlace;
      }
      setMetric(newMetric);
      setOrganizationName(undefined);
      return;
    }

    refresh();
  }, [props.value, props.inspectionPlace, refresh]);

  return (
    <>
      <InspectionMetricForm
        value={props.value}
        metric={metric}
        presentedMetric={presentedMetric}
        organizationName={organizationName}
        inspectionType={props.inspectionType}
        setMetric={setMetric}
        propertyChange={propertyChange}
        presentedToSelected={presentedToSelected}
        getAddress={props.getAddress}
      />
      <ValidationAlert
        show={showValidationErrors}
        modal={props.savingMode === 'background'}
        onHide={() => {
          setShowValidationErrors(false);
          refresh();
        }}
        errors={validationErrors}
        className="mt-3"
        buttonText="Przywróć dane"
      />
    </>
  );
};
