import React from 'react';
import { Card, Form, Modal } from 'react-bootstrap';

import FormRow from '../../../app/components/FormRow';
import { CreateUpdateOrganizationalUnitDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient/models';
import BooleanDropdown from '../../../app/components/BooleanDropdown';

interface OrganizationalUnitModalBodyProps {
  organizationalUnit: CreateUpdateOrganizationalUnitDto;
  propertyChange: (obj: Partial<CreateUpdateOrganizationalUnitDto>) => void;
}

export const OrganizationalUnitModalBody: React.FC<OrganizationalUnitModalBodyProps> =
  ({ organizationalUnit, propertyChange }) => {
    return (
      <Modal.Body>
        <Card className="mb-2">
          <Card.Header>Organ</Card.Header>
          <Card.Body>
            <Form className="d-grid gap-3">
              <FormRow controlId="inspectionPlace" label="Organ">
                <Form.Control
                  type="text"
                  value={organizationalUnit?.name || ''}
                  onChange={e => propertyChange({ name: e.target.value })}
                />
              </FormRow>
              <FormRow controlId="inspectionPlace" label="Jednostka">
                <Form.Control
                  type="text"
                  value={organizationalUnit?.unit || ''}
                  onChange={e => propertyChange({ unit: e.target.value })}
                />
              </FormRow>
              <FormRow controlId="inspectionPlace" label="Symbol">
                <Form.Control
                  type="text"
                  value={organizationalUnit?.shortname || ''}
                  onChange={e => propertyChange({ shortname: e.target.value })}
                />
              </FormRow>
              <FormRow controlId="inspectionPlace" label="Adres">
                <Form.Control
                  type="text"
                  value={organizationalUnit?.address || ''}
                  onChange={e => propertyChange({ address: e.target.value })}
                />
              </FormRow>

              <FormRow
                controlId="inspectionPlace"
                label="Miejscowość odmieniona"
              >
                <Form.Control
                  type="text"
                  value={organizationalUnit?.city || ''}
                  onChange={e => propertyChange({ city: e.target.value })}
                />
              </FormRow>
              <FormRow controlId="notified" label="Jednostka notyfikowana">
                <BooleanDropdown
                  value={organizationalUnit?.notified}
                  onChange={value => propertyChange({ notified: value })}
                />
              </FormRow>
              <FormRow
                controlId="nationalTechnicalAssessmentUnit"
                label="Krajowa jednostka oceny technicznej"
              >
                <BooleanDropdown
                  value={organizationalUnit?.nationalTechnicalAssessmentUnit}
                  onChange={value =>
                    propertyChange({ nationalTechnicalAssessmentUnit: value })
                  }
                />
              </FormRow>
            </Form>
          </Card.Body>
        </Card>
      </Modal.Body>
    );
  };
