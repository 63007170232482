import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import * as SampleCollectContext from '../../../common/snrwbCore/contexts/SampleCollectContext';
import { CreateUpdateSampleCollectDto } from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import FormRow from '../../../app/components/FormRow';
import DictionarySelector from '../Selects/DictionarySelector';
import ValidationAlert from '../../../app/components/ValidationAlert';
import DynamicTextarea from '../../../app/components/DynamicTextarea';
import { Organization } from '../../../common/snrwbCore/contexts/OrganizationContext';
import DatePicker from '../../../app/components/DatePicker';
import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';

import SampleCollectPlaces from './SampleCollectPlaces';

export const SampleCollectCreateForm: React.FC<{
  value: CreateUpdateSampleCollectDto;
  organization: Organization;
  showValidationErrors: boolean;
  onChange: (
    sampleCollect: CreateUpdateSampleCollectDto,
    valid: boolean,
  ) => void;
}> = props => {
  const [sampleCollect, setSampleCollect] = useState(props.value);
  const [presentedSampleCollect, setPresentedSampleCollect] =
    useState(sampleCollect);
  const [showErrors, setShowErrors] = useState(props.showValidationErrors);
  const [errors, setErrors] = useState<string[]>([]);
  const [showActionDate, setShowActionDate] = useState(
    props.value.collectBasisShort !== '25.1',
  );
  const snrwb = useSnrwbCore();

  useEffect(() => {
    snrwb.sampleCollects
      .setDuringInspectionDto()
      .then(() => setShowActionDate(props.value.collectBasisShort !== '25.1'));
  }, [snrwb.sampleCollects, props.value.collectBasisShort]);

  const setProperty = (obj: Partial<CreateUpdateSampleCollectDto>) => {
    let newValue = { ...presentedSampleCollect, ...obj };
    const showActionDateTemp =
      newValue?.collectBasisId !==
      SampleCollectContext.duringInspectionBasisId();
    setShowActionDate(showActionDateTemp);
    if (showActionDateTemp && newValue.collectBasisShort !== '') {
      newValue = {
        ...newValue,
        ...{ collectBasisShort: '' },
      };
    }
    if (!showActionDateTemp && newValue.collectBasisShort !== '25.1') {
      newValue = {
        ...newValue,
        ...{ collectBasisShort: '25.1' },
      };
    }
    if (!showActionDateTemp && newValue.actionDate) {
      newValue = { ...newValue, ...{ actionDate: null } };
    }
    setPresentedSampleCollect(newValue);
    return newValue;
  };

  const organized = (dto: CreateUpdateSampleCollectDto) => {
    return { ...dto, organizationId: 'set' };
  };

  const validate = (obj: CreateUpdateSampleCollectDto) => {
    SampleCollectContext.validate(organized(obj)).then(result => {
      setErrors(result.errors);
      props.onChange(obj, result.valid);
    });
  };

  useEffect(() => {
    let mounted = true;
    setSampleCollect(props.value);
    SampleCollectContext.validate(organized(props.value)).then(result => {
      if (mounted) {
        setErrors(result.errors);
      }
    });

    return () => {
      mounted = false;
    };
  }, [props]);

  useEffect(() => setPresentedSampleCollect(sampleCollect), [sampleCollect]);

  useEffect(
    () => setShowErrors(props.showValidationErrors),
    [props.showValidationErrors],
  );

  return (
    <>
      <Form className="d-grid gap-3">
        <FormRow controlId="fileNumber" label="Numer akt pobrania">
          <Form.Control
            type="text"
            value={presentedSampleCollect.fileNumber || ''}
            onChange={e => setProperty({ fileNumber: e.target.value })}
            onBlur={() => validate(presentedSampleCollect)}
          />
        </FormRow>
        <FormRow
          controlId="collectPlace"
          label="Nazwa i adres miejsca pobrania"
        >
          <DynamicTextarea
            value={presentedSampleCollect.collectPlace || ''}
            onChange={e => setProperty({ collectPlace: e.target.value })}
            onBlur={() => validate(presentedSampleCollect)}
          />
          <div className="mt-1 d-flex justify-content-end">
            <SampleCollectPlaces
              organization={props.organization}
              onSelect={place => validate(setProperty({ collectPlace: place }))}
            />
          </div>
        </FormRow>
        <FormRow controlId="collectBasisId" label="Podstawa pobrania">
          <DictionarySelector
            dictionary="badanie próbek - podstawa pobrania"
            value={presentedSampleCollect.collectBasisId}
            onChange={option =>
              validate(setProperty({ collectBasisId: option.value || '' }))
            }
          />
        </FormRow>
        {showActionDate && (
          <FormRow controlId="collectDate" label="Data rozpoczęcia czynności">
            <DatePicker
              value={presentedSampleCollect.actionDate}
              onChange={date => validate(setProperty({ actionDate: date }))}
            />
          </FormRow>
        )}
      </Form>
      <ValidationAlert show={showErrors} errors={errors} className="mt-3" />
    </>
  );
};
