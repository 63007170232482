import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';

import { useSnrwbCore } from '../../../common/hooks/useSnrwbCore';
import {
  TimelineEventType,
  TimelineProcessTypeObjectStartTypeEnum,
} from '../../../common/snrwbCore/autogenerated/snrwbApiClient';
import { TimelineEvent } from '../../components/ProductTypes/TimelineEvent';
import { useNavigation } from '../../../common/navigation';

interface TimelineEventViewParam {
  id: string;
  type: TimelineProcessTypeObjectStartTypeEnum;
  parentId: string;
}

export const TimelineEventView: React.FC<TimelineEventViewParam> = ({
  id,
  type,
  parentId,
}) => {
  const nav = useNavigation();
  const snrwb = useSnrwbCore();
  const mountedRef = useRef(false);
  const [events, setEvents] = useState<TimelineEventType[]>([]);
  const [visible, setVisible] = useState(false);

  const safeSet = (set: () => void) => {
    if (mountedRef.current) {
      set();
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const onClick = async () => {
    if (!visible) {
      await snrwb.reports.getTimelineByProcess(id, type).then(org => {
        safeSet(() => setEvents(org));
      });
    }
    safeSet(() => setVisible(!visible));
  };

  const onClickDetails = async (newWindow?: boolean) => {
    switch (type) {
      case TimelineProcessTypeObjectStartTypeEnum.SampleExam:
        nav.sampleExam(id, newWindow);
        break;
      case TimelineProcessTypeObjectStartTypeEnum.InspectedProduct:
        nav.inspection(parentId, newWindow);
        break;
    }
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if (event.button === 1) {
      event.preventDefault();
      event.stopPropagation();

      onClickDetails && onClickDetails(true);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end mt-3">
        <Button
          variant="outline-primary"
          size="sm"
          className="me-1 mb-2"
          onClick={onClick}
        >
          {visible ? 'Ukryj' : 'Rozwiń'}
        </Button>
        <Button
          variant="outline-secondary"
          size="sm"
          className="me-1 mb-2"
          onClick={() => onClickDetails()}
          onMouseDown={event => handleMouseDown(event)}
        >
          {'Przejdź'}
        </Button>
      </div>
      {visible && <TimelineEvent events={events} />}
    </>
  );
};
